import React, { Component } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faHand,
  faGear,
  faFile,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Swal from "sweetalert2";
import InputNumber from "../../../utilities/Form/InputNumber";
import withReactContent from "sweetalert2-react-content";
import FormTransfer from "./formTransfer";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import FormSupplier from "./formSupplier";
const MySwal = withReactContent(Swal);
const token = cookieManager.getUserToken();
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      status: 1,
      modalDetail: false,
      saveSupplier:false,
      arrayChoose:null,
      keyChoose:null,
      quoteChoose:null,
      requestChoose:null,
      modalSupplier:false,
      isErrorSupplier:false,
      errorSupplier:null,
      titleModal: i18n.t("Select Transfer"),
      itemChoose: null,
      arrayUnit: [],
      isUpdate: 0,
      helperSupplier:null,
    };
    (this.formTransfer = React.createRef()),
      (this.handler = this.handler.bind(this));
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.formSupplier = React.createRef()
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    const PurchaseReq = {
      status: this.state.status,
    };

    const PurchaseReqDetail = this.state.arrayUnit;
    let idSupplier = [];
    let idPR = [];
    let id = [];
    let qtyOrder = [];
    let ifReject = [];
    let idWarehouse = [];
    let idQuotation = [];
    let idSubWarehouse = [];
    let qtyMutation = [];
    let idBranchMutation = [];
    let idStoreMutation = [];
    let idSetupWH = [];
    let nameBranch = [];
    let nameStore = [];
    let stock = [];

    for (var i = 0; i < PurchaseReqDetail.length; i++) {
      idSupplier.push(
        PurchaseReqDetail[i].supplierValue == null
          ? ""
          : PurchaseReqDetail[i].supplierValue.value
      );
      idPR.push(PurchaseReqDetail[i].idPR);
      id.push(PurchaseReqDetail[i].id);
      idWarehouse.push(
        PurchaseReqDetail[i].idWarehouse == null
          ? ""
          : PurchaseReqDetail[i].idWarehouse
      );
      idQuotation.push(
        (PurchaseReqDetail[i].useQuote)?
          (PurchaseReqDetail[i].supplierValue == null
          ? ""
          : PurchaseReqDetail[i].supplierValue.idQuote):""
      );
      idBranchMutation.push(
        PurchaseReqDetail[i].idBranchMutation == null
          ? ""
          : PurchaseReqDetail[i].idBranchMutation
      );
      idStoreMutation.push(
        PurchaseReqDetail[i].idStoreMutation == null
          ? ""
          : PurchaseReqDetail[i].idStoreMutation
      );
      idSubWarehouse.push(
        PurchaseReqDetail[i].idSubWarehouse == null
          ? ""
          : PurchaseReqDetail[i].idSubWarehouse
      );
      idSetupWH.push(
        PurchaseReqDetail[i].idSetupWH == null
          ? ""
          : PurchaseReqDetail[i].idSetupWH
      );
      qtyOrder.push(
        PurchaseReqDetail[i].qtyOrder == null
          ? "0"
          : PurchaseReqDetail[i].qtyOrder
      );
      qtyMutation.push(
        PurchaseReqDetail[i].qtyMutation == null
          ? "0"
          : PurchaseReqDetail[i].qtyMutation
      );

      ifReject.push(
        PurchaseReqDetail[i].ifReject == null
          ? ""
          : PurchaseReqDetail[i].ifReject
      );

      nameBranch.push(
        PurchaseReqDetail[i].nameBranch ? PurchaseReqDetail[i].nameBranch : ""
      );
      nameStore.push(
        PurchaseReqDetail[i].nameStore ? PurchaseReqDetail[i].nameStore : ""
      );
      stock.push(PurchaseReqDetail[i].stock ? PurchaseReqDetail[i].stock : "");
    }

    this.state.form.PurchaseReq = PurchaseReq;
    this.state.form.PurchaseReqDetail = {
      idSupplier: idSupplier,
      idPR: idPR,
      id: id,
      qtyOrder: qtyOrder,
      ifReject: ifReject,
      idWarehouse: idWarehouse,
      idSetupWH: idSetupWH,
      idSubWarehouse: idSubWarehouse,
      idQuotation: idQuotation,
      qtyMutation: qtyMutation,
      idBranchMutation: idBranchMutation,
      idStoreMutation: idStoreMutation,
      nameBranch: nameBranch,
      nameStore: nameStore,
      stock: stock,
    };

    this.state.isUpdate = 1;
    return this.state.form;
  }

  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  saveNewSupplier(value){
    this.setState({modalSupplier:value,isErrorSupplier:false,errorSupplier:null,saveSupplier:value},()=>{
      if(value){
        let form=this.formSupplier.current.runSubmit();
        axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: process.env.REST_URL + "pembelian/purchaserecap/create-supplier",
          responseType: "json",
          data:form
        })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              const arr = [...this.state[this.state.arrayChoose]];
              const objectss = arr[this.state.keyChoose];
              objectss['idSupplier']=response.data.result.id
              this.setState(arr,()=>{
                this.setState({modalSupplier:false,saveSupplier:false},async()=>{
                 await this.useQuotation(this.state.arrayChoose,this.state.keyChoose,true,this.state.requestChoose,false,()=>{
                  const arrSupplier = [...this.state[this.state.arrayChoose]];
                  const objectss = arrSupplier[this.state.keyChoose];
                  let arrayMap=objectss.quotationList.filter((pp)=>pp.value==response.data.result.id);
                  objectss['supplierValue']=(arrayMap.length >0)?arrayMap[0]:null 
                  this.setState(arrSupplier,()=>{
                    // console.log(this.state)
                  })
                 })
                
                })
              })
            }
          })
          .catch((error) => {
            const data = error.response.data;
            this.setState({errorSupplier:data.error,isErrorSupplier:true,saveSupplier:false})
          });
      }
    })
  }

  setSupplier(value,i,arrayname,detail,request){
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "helper/get-supplier-recap-helper",
      responseType: "json"
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          this.setState({
            modalSupplier:value,
            helperSupplier:response.data.result,
            keyChoose:i,
            arrayChoose:arrayname,
            quoteChoose:detail,
            requestChoose:request
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlerSelectArrayTemp(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  //Setting Transfer
  setTransfer(
    detail,
    index,
    array,
    key,
    key2,
    key3,
    key4,
    key5,
    key6,
    key7,
    key8,
    key9
  ) {
    let choose = {
      branchList: this.state.branchList,
      // nameBranch:detail.nameBranch,
      // nameStore:detail.nameStore,
      branchValue: detail.idBranchMutation,
      storeValue: detail.idStoreMutation,
      detail: detail,
      index: index,
      array: array,
      // idItem:detail.idItem,
      key: key,
      key2: key2,
      key3: key3,
      key4: key4,
      key5: key5,
      key6: key6,
      key7: key7,
      key8: key8,
      key9: key9,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }

  saveTransfer(stat) {
    if (stat == true) {
      let result = this.formTransfer.current.runSubmit();
      console.log(result);
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] =
          result.idWarehouse == "" ? "" : result.idWarehouse;
        objectss[this.state.itemChoose.key2] =
          result.idSubWarehouse == "" ? "" : result.idSubWarehouse;
        objectss[this.state.itemChoose.key3] =
          result.branchValue == "" ? "" : result.warehouseValue.label;
        objectss[this.state.itemChoose.key4] =
          result.branchValue == "" ? "" : result.branchValue.value;
        objectss[this.state.itemChoose.key5] =
          result.storeValue == "" ? "" : result.storeValue.value;
        objectss[this.state.itemChoose.key6] =
          result.idSetupValue == "" ? "" : result.idSetupValue;
        objectss[this.state.itemChoose.key7] =
          result.branchValue == "" ? "" : result.branchValue.label;
        objectss[this.state.itemChoose.key8] =
          result.storeValue == "" ? "" : result.storeValue.label;
        objectss[this.state.itemChoose.key9] =
          result.stock == "" ? "" : result.stock;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  useQuotation(arrayname, i, value, detail,clear=true,extra=()=>{}) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    
    if(clear){
      objectss["useQuote"] = value;
      objectss["supplierValue"] = null;
    }
    
    this.setState(arr, () => {
      if (value) {
        let params = {
          id: detail.idPR,
          item: detail.idItem,
          unit: detail.idUnit,
        };
        axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: process.env.REST_URL + "helper/get-quotation-recap",
          responseType: "json",
          params: params,
        })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              objectss["quotationList"] = response.data.result;
              this.setState(arr,extra);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <>
          <tr>
            <td width={"150px"}>
              <input
                className="form-control  form-control-sm"
                type={"input"}
                name={"prNo"}
                title={i18n.t("No. Request")}
                required
                value={detail.prNo}
                onChange={(e) =>
                  {}
                }
                disabled={true}
              />
            </td>

            <td width={"150px"}>
              <input
                className="form-control  form-control-sm"
                disabled={true}
                type={"input"}
                name={"idItem"}
                title={i18n.t("Item")}
                value={detail.itemValue}
                onChange={()=>{}}
              />
            </td>

            <td width={"125px"}>
              <InputNumber
                id={"qtyRemaining" + i}
                name="qtyRemaining"
                placeholder={i18n.t("Qty Remaining")}
                defaultValue={0}
                value={detail.qtyRemaining}
                onValueChange={(value, name) => {}}
                className="form-control  form-control-sm text-end"
                labelfield={"false"}
                disabled
                suffix={" " + detail.unitValue}
              />
            </td>

            {/* <td width={"100px"}>
              <input
                className="form-control  form-control-sm"
                disabled={true}
                type={"input"}
                name={"idUnit"}
                title={i18n.t("Unit")}
                value={detail.unitValue}
              />
            </td> */}

            <td width={"200px"} className="border-left">
              <Select
                name={"idSupplier"}
                required={true}
                isClearable={true}
                onChange={async (e) => {
                  if (e !== detail.supplierValue) {
                    if (e !=null && e.newSupplier && detail.useQuote) {
                        this.setSupplier(true,i,'arrayUnit',e,detail)
                    } else {
                      await this.handlerSelectArray(
                        e,
                        "idSupplier",
                        i,
                        "arrayUnit",
                        "supplierValue"
                      );
                    }
                    this.handlerCurrencyArray(0, "qtyOrder", i, "arrayUnit");
                  }
                }}
                isDisabled={detail.ifReject == null ? false : true}
                options={
                  detail.useQuote ? detail.quotationList : detail.supplierList
                }
                title={i18n.t("Supplier")}
                value={detail.supplierValue}
                placeholder={this.state.supplierText}
              />
            </td>
            <td>
              {detail.quotationList.length > 0 ? (
                <div className="col p-0">
                  <Button
                    variant="info"
                    className="btn btn-sm"
                    onClick={(e) => {
                      this.useQuotation(
                        "arrayUnit",
                        i,
                        detail.useQuote ? false : true,
                        detail
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={detail.useQuote ? faRemove : faFile}
                    ></FontAwesomeIcon>
                  </Button>
                </div>
              ) : null}
            </td>
            <td width={"150px"} className="border-right">
              <InputNumber
                id={"qtyOrder" + i}
                name="qtyOrder"
                placeholder={i18n.t("qtyOrder")}
                defaultValue={0}
                value={detail.qtyOrder}
                disableGroupSeparators={true}
                onValueChange={async (value, name) => {
                  if (parseFloat(value) > parseFloat(detail.qtyRemaining)) {
                    await this.alertError(
                      "Info",
                      i18n.t("Quantity Order can't Exceed Quantity Request"),
                      "info"
                    );
                    await this.handlerCurrencyArray(
                      0,
                      "qtyOrder",
                      i,
                      "arrayUnit"
                    );
                  } else {
                    this.handlerCurrencyArray(
                      value,
                      "qtyOrder",
                      i,
                      "arrayUnit"
                    );
                  }
                }}
                className="form-control  form-control-sm text-end"
                labelfield={"false"}
                disabled={detail.ifReject == null ? false : true}
                // suffix={" "+detail.unitValue}
              />
            </td>

            <td className="border-left">
              <input
                className="form-control  form-control-sm"
                type={"input"}
                name={"nameWarehouse"}
                value={detail.nameWarehouse}
                disabled={detail.ifReject == null ? false : true}
                onChange={ (e)=>this.handlerInputArray(e, "nameWarehouse", i, "arrayUnit")}
              />
              <div
                className={
                  detail.stock > 0
                    ? "fv-plugins-message-container valid-feedback"
                    : "fv-plugins-message-container invalid-feedback"
                }
              >
                {detail.nameWarehouse
                  ? "Stock Available: " +
                    (detail.stock != 0
                      ? parseFloat(detail.stock)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                      : "0.00")
                  : ""}
              </div>

              <input
                name={"idWarehouse"}
                value={detail.idWarehouse}
                disabled={detail.ifReject == null ? false : true}
                onChange={(e) =>
                  this.handlerInputArray(e, "idWarehouse", i, "arrayUnit")
                }
                hidden={true}
                labelfield={"false"}
              />
              <input
                className="form-control  form-control-sm"
                type={"input"}
                name={"idSubWarehouse"}
                value={detail.idSubWarehouse}
                disabled={true}
                onChange={(e) =>
                  this.handlerInputArray(e, "idSubWarehouse", i, "arrayUnit")
                }
                hidden={true}
              />
              <input
                className="form-control  form-control-sm"
                type={"input"}
                name={"idBranchMutation"}
                value={detail.idBranchMutation}
                disabled={true}
                onChange={(e) =>
                  this.handlerInputArray(e, "idBranchMutation", i, "arrayUnit")
                }
                hidden={true}
              />
              <input
                className="form-control  form-control-sm"
                type={"input"}
                name={"idStoreMutation"}
                value={detail.idStoreMutation}
                onChange={(e) =>
                  this.handlerInputArray(e, "idStoreMutation", i, "arrayUnit")
                }
                disabled={true}
                hidden={true}
              />
            </td>
            <td >
              {detail.qtyRemaining ? (
                <div className="col p-0">
                  <Button
                    variant="info"
                    className="btn btn-sm"
                    onClick={(e) => {
                      this.setTransfer(
                        detail,
                        i,
                        "arrayUnit",
                        "idWarehouse",
                        "idSubWarehouse",
                        "nameWarehouse",
                        "idBranchMutation",
                        "idStoreMutation",
                        "idSetupWH",
                        "nameBranch",
                        "nameStore",
                        "stock"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faGear}></FontAwesomeIcon>
                  </Button>
                </div>
              ) : (
                ""
              )}
            </td>
            <td width={"150px"} className="border-right">
              <InputNumber
                id={"qtyMutation" + i}
                name="qtyMutation"
                placeholder={i18n.t("qtyMutation")}
                defaultValue={0}
                value={detail.qtyMutation}
                onValueChange={async (value, name) => {
                  await this.handlerCurrencyArray(
                    value,
                    "qtyMutation",
                    i,
                    "arrayUnit"
                  );
                  var a = detail.qtyRemaining;
                  var b = detail.qtyOrder;
                  var total = a - b;
                  if (
                    parseFloat(detail.qtyMutation) > parseFloat(detail.stock)
                  ) {
                    await this.handlerCurrencyArray(
                      value,
                      "qtyMutation",
                      i,
                      "arrayUnit"
                    );
                    await this.alertError(
                      "Info",
                      i18n.t(
                        "Quantity Mutation can't Exceed Quantity in Stock"
                      ),
                      "info"
                    );
                    await this.handlerCurrencyArray(
                      0,
                      "qtyMutation",
                      i,
                      "arrayUnit"
                    );
                  } else {
                    if (detail.qtyMutation > total) {
                      await this.alertError(
                        "Info",
                        i18n.t(
                          "Quantity Mutation can't Exceed Quantity Request"
                        ),
                        "info"
                      );
                      await this.handlerCurrencyArray(
                        0,
                        "qtyMutation",
                        i,
                        "arrayUnit"
                      );
                    } else {
                      await this.handlerCurrencyArray(
                        value,
                        "qtyMutation",
                        i,
                        "arrayUnit"
                      );
                    }
                  }
                }}
                className="form-control  form-control-sm text-end"
                labelfield={"false"}
                disabled={
                  detail.nameWarehouse == ""
                    ? true
                    : detail.ifReject == null
                    ? false
                    : true
                }
              />
            </td>

            <td>
                <Button
                  variant={detail.ifReject != null ? "info" : "danger"}
                  className="btn btn-sm"
                  onClick={(e) => {
                    if (detail.ifReject != null) {
                      const arr = [...this.state.arrayUnit];
                      const objectss = arr[i];
                      var value = null;
                      objectss.ifReject = value;
                      this.setState(arr);
                    } else {
                      this.rejectArray(i, "arrayUnit");
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={detail.ifReject == null ? faHand : faComment}
                  ></FontAwesomeIcon>
                </Button>
            </td>
          </tr>
        </>
      </React.Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  rejectArray(i, key) {
    const html = (
      <>
        <div>{i18n.t("Reject This PO")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={(e) => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "text",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                const arr = [...this.state.arrayUnit];
                const objectss = arr[i];
                objectss.ifReject = value;
                this.setState(arr);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  changeSelect(type, value = null) {
    const params = { id: value };
    switch (type) {
      case "idBranch":
        this.props.getStore(params);
        break;
      default:
        break;
    }
  }

  render() {
    const { data, isNewRecord, PurchaseRecapHelper, purchaserecapHelper } =
      this.props;
    this.state.branchList =
      purchaserecapHelper != null ? purchaserecapHelper.branch : null;
    this.state.branchValue = "";
    if (isNewRecord && PurchaseRecapHelper != null) {
      this.state.prNo = PurchaseRecapHelper.prNo;
    }

    console.log('STATE', this.state)
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.arrayUnit = [];
        for (var po = 0; po < data.length; po++) {
          this.state.arrayUnit.push({
            useQuote: data[po].useQuotation,
            quotation: data[po].idQuotation,
            quotationList: data[po].quotationList,
            prNo: data[po].no_pr,
            id: data[po].id,
            idPR: data[po].id_pr,
            idItem: data[po].id_item,
            idUnit: data[po].id_unit,
            qtyRemaining: data[po].qty,
            unitValue: data[po].name_unit,
            itemValue: data[po].name_item,
            supplierList: data[po].supplierList,
            supplierValue:
              data[po].id_supplier != null
                ? {
                    value: data[po].id_supplier,
                    label: data[po].name_supplier,
                    idQuote: data[po].idQuotation,
                    newSupplier:false
                  }
                : null,
            idWarehouse: data[po].warehouse == null ? "" : data[po].warehouse,
            idSubWarehouse:
              data[po].warehouse == null ? "" : data[po].id_subwarehouse,
            nameWarehouse:
              data[po].warehouse_data == null
                ? ""
                : data[po].warehouse_full.name,
            idBranchMutation:
              data[po].id_branch_mutation == null
                ? ""
                : data[po].id_branch_mutation,
            idStoreMutation:
              data[po].id_store_mutation == null
                ? ""
                : data[po].id_store_mutation,
            idSetupWH: data[po].id_setup_wh == null ? "" : data[po].id_setup_wh,
            nameBranch:
              data[po].branch_name == null ? "" : data[po].branch_name,
            nameStore: data[po].store_name == null ? "" : data[po].store_name,
            stock:
              data[po].stock == null ? "" : parseFloat(data[po].stock) || 0,

            qtyOrder: parseFloat(data[po].qtyOrder) || 0,
            qtyMutation:
              data[po].warehouse_full == null
                ? 0
                : parseFloat(data[po].qty_mutation) || 0,
          });
        }

        this.state.isUpdate = 1;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th rowSpan={2} className="text-left">{i18n.t("No. Request")}</th>
                    <th rowSpan={2}  className="text-left">{i18n.t("Item")}</th>
                    <th rowSpan={2}  className="text-left">{i18n.t("Qty Request")}</th>
                    <th   colSpan={3} className="text-center border-left">
                      {i18n.t("Pembelian")}
                    </th>
                    <th colSpan={3} className="text-center border-left border-right">
                      {i18n.t("Mutation Items")}
                    </th>
                    <th rowSpan={2}  className="text-left">{i18n.t("Action")}</th>
                  </tr>
                  <tr>
                    <th colSpan={2} className="text-center border-left">
                      {i18n.t("Supplier")}
                    </th>
                    <th className="text-center border-right">{i18n.t("Qty Order")}</th>
                    <th colSpan={2} className="text-center border-left">
                      {i18n.t("Warehouse")}
                    </th>
                    <th className="text-center border-right">{i18n.t("Qty Mutation")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayUnit.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </form>
        <Modal show={this.state.modalDetail} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormTransfer
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formTransfer}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveTransfer(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="secondary"
              onClick={(e) => this.saveTransfer(true)}
            >
              {i18n.t("Save")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalSupplier} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{i18n.t("New Supplier")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormSupplier
              data={this.state.helperSupplier}
              isError={this.state.isErrorSupplier}
              errorMessage={this.state.errorSupplier}
              isNewRecord={true}
              quote={this.state.quoteChoose}
              ref={this.formSupplier}
            />
          </Modal.Body>
          <Modal.Footer className={(!this.state.saveSupplier)?"justify-content-between":"justify-content-center"}>
            {this.state.saveSupplier ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.saveNewSupplier(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((this.state.saveSupplier)?false:this.saveNewSupplier(true))}>
              {this.state.saveSupplier ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
          
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;

import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import PermissionContainer from '../../../containers/modules/user/permission'
export const Permission = (props)=>{
    let params=useParams();
    let id=params.id;
    const navigate = useNavigate ();
   
    return (
        <PermissionContainer id={id} {...props} navigate={navigate}/>
    )
}

export default Permission;
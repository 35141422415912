import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import cookieManager from "../../../../utils/cookieManager";
import InputNumber from "../../../utilities/Form/InputNumber";
import Swal from "sweetalert2";
import i18n from "../../../../i18n";

const token = cookieManager.getUserToken();
export class FormConvertion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 0,
      qtyUse: 0,
      checkPR: false,
      qtyCon: 0,
      qtyRequest: 0,
      qtyValid: 0,
      currentUnit: null,
      suffixty: "",
      unit: false,
      convert: 0,
      unitList: [],
      unitText: "Select Unit",
      arrayDetail: [
     
      ],
      isUpdate: 0,
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  calculateAll() {
    const { data } = this.props;

    var a = 0;
    var b = 0;
    var total = 0;
    a = parseFloat(data.qty) || 0;
    if (this.state.checkPR == true) {
      b = parseFloat(data.convert) || 0;
    } else {
      b = 1;
    }
    total = Math.floor(a / b);
    let qty = parseInt(total);

    let qtyRequest = parseFloat(data.qtyRequest);
    let qtyValid = parseFloat(data.qtyValid);
    let qtyUse = 0;
    let currentConvertion = this.state.currentUnit.convertion == null? 1 : parseFloat(this.state.currentUnit.convertion) || 1;
    if(this.state.arrayDetail.length > 0){
      for (var i = 0; i < this.state.arrayDetail.length; i++) {
        var convertion =
          this.state.arrayDetail[i].unitValue != null
            ? this.state.arrayDetail[i].unitValue.convertion!= null ?  parseFloat(this.state.arrayDetail[i].unitValue.convertion) || 0 : 1 : 1;
        var qtydetail = parseFloat(this.state.arrayDetail[i].qty) || 0;
        qtyUse += qtydetail * convertion;
      }
    }

    qtyUse = qtyUse / currentConvertion;
    qty -= qtyUse;

    this.setState({
      qty: Math.ceil(qty),
      qtyUse: Math.floor(qtyUse),
      qtyRequest: Math.floor(qtyRequest),
      qtyValid: Math.floor(qtyValid),
    });
  }

  runSubmit() {
    if (this.state.qty < 0 || this.state.qtyUse > this.state.qtyValid) {
      this.alertError(i18n.t("Warning"), i18n.t("Out Of Qty Order"), "warning");
    } else {
      if (this.state.qtyUse > 0) {
        let detail = [];

        for (var i = 0; i < this.state.arrayDetail.length; i++) {
          if (this.state.arrayDetail[i].unitValue != null) {
            detail.push(this.state.arrayDetail[i]);
          }
        }
        this.state.result = {
          qty: this.state.qty,
          qtyUse: this.state.qtyUse,
          detail: detail,
        };
        return this.state.result;
      } else {
        this.alertError(i18n.t("Info"), i18n.t("Qty Receive must be more than 0"), "info");
      }
    }
    return null;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;

    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td>
            <Select
              isClearable={true}
              name={"unit"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectArray(e, "unitValue", i, "arrayDetail");
                if (e == null) {
                  await this.handlerCurrencyArray(0, "qty", i, "arrayDetail");
                }
                await this.calculateAll();
              }}
              options={this.state.unitList}
              value={detail.unitValue}
              placeholder={this.state.unitText}
            />
          </td>
          <td>
            <CurrencyInput
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty Receive")}
              defaultValue={0}
              value={detail.qty}
              disabled={detail.unitValue != null ? false : true}
              decimalsLimit={2}
              onValueChange={async (value, name) => {
                await this.handlerCurrencyArray(value, "qty", i, "arrayDetail");
                await this.calculateAll();
              }}
              className="form-control  form-control-sm text-end"
            />
          </td>

          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState(
      {
        ...this.state,
        [key]: arr,
      },
      async () => {
        await this.calculateAll();
      }
    );
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  render() {
    const { data, isNewRecord } = this.props;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.unitList = data.unitList;
        if (data.unitList.length > 0) {
          let indexUnit = data.unitList.findIndex((e) => e.current == true);
          this.state.unit = data.unitList[indexUnit].label;
          this.state.currentUnit = data.unitList[indexUnit];
        }
        this.state.checkPR = data.check;
        this.state.qty = (parseInt(data.qty));
        this.state.suffixty = data.suffixty;
        this.state.qtyRequest = Math.floor(data.qtyRequest);
        this.state.qtyValid = Math.floor(data.qtyValid);
        this.state.arrayDetail = data.convertionDetail;
        this.calculateAll();
        this.state.isUpdate = 1;
      }
    }
    

    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-4">
              <InputNumber
                id={"qty"}
                name="qty"
                title={i18n.t("Stock")}
                placeholder="Qty"
                defaultValue={0}
                value={this.state.qty}
                decimalsLimit={2}
                disabled
                // suffix={this.state.suffixty}
                onValueChange={(value, name) => {}}
                className="form-control  form-control-sm text-end"
              />
            </div>
            {data.check == true ? (
              <div className="col-4">
                <InputNumber
                  id={"qtyRequest"}
                  name="qtyRequest"
                  title={i18n.t("Qty Request")}
                  placeholder="qtyRequest"
                  defaultValue={0}
                  value={this.state.qtyValid}
                  decimalsLimit={2}
                  disabled
                  // suffix={this.state.suffixty}
                  onValueChange={(value, name) => {}}
                  className="form-control  form-control-sm text-end"
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-4">
              <InputNumber
                id={"qty"}
                name="qty"
                title={i18n.t("Qty Receive")}
                placeholder="Qty"
                defaultValue={0}
                value={this.state.qtyUse}
                decimalsLimit={2}
                disabled
                onValueChange={(value, name) => {}}
                className="form-control  form-control-sm text-end"
              />
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{i18n.t("Unit")}</th>
                    <th className="text-center">{i18n.t("Qty")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      unitValue: null,
                      qty: 0,
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Convertion")}
              </Button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default FormConvertion;

export default class TypeLogTx {
    static CHECKIN_EMPLOYEE = 'CHECKIN_EMPLOYEE';
    static CHECKIN_CUSTOMER = 'CHECKIN_CUSTOMER';
    static RECORD_STOCK_CUSTOMER = 'RECORD_STOCK_CUSTOMER';
    static BILLING_CUSTOMER = 'BILLING_CUSTOMER';
    static RETUR_CUSTOMER = 'RETUR_CUSTOMER';
    static SELLING_CUSTOMER = 'SELLING_CUSTOMER';
    static CHECKOUT_CUSTOMER = 'CHECKOUT_CUSTOMER';
    static CLOSED_CUSTOMER = 'CLOSED_CUSTOMER';
    static CHECKOUT_EMPLOYEE = 'CHECKOUT_EMPLOYEE';
    static REST_EMPLOYEE = 'REST_EMPLOYEE';
    static TROUBLE_EMPLOYEE = 'TROUBLE_EMPLOYEE';
}
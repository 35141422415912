import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
const MySwal = withReactContent(Swal);
export class IncomeStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      yearList: [],
      yearText: i18n.t("Select Year"),
      yearValue: null,
      month: null,
      year: null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Income Statement"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  setData() {
    var month = this.state.monthValue == null ? null : this.state.monthValue.value
    var year = this.state.yearValue == null ? null : this.state.yearValue.value
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;

    let params = {
      month: month,
      year: year,
      idBranch:idBranch
    }
    this.props.setData(params);
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var month = this.state.monthValue == null ? null : this.state.monthValue.value
    var mLabel = this.state.monthValue == null ? null : this.state.monthValue.label
    var year = this.state.yearValue == null ? null : this.state.yearValue.value
    var yLabel = this.state.yearValue == null ? null : this.state.yearValue.label
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;

    let params = {
      month: month,
      year: year,
      nameFile:nameFile,
      idBranch:idBranch,
      mLabel: i18n.t(mLabel)
    }
    // this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/income-statement/excel",
      method: "GET",
      params: params,
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", mLabel + '_' + yLabel + '_' + nameFile + '_Laporan Laba Rugi.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props
    var value = ''
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0]
      }
      Swal.close();
    }
    return value;
  }

  renderList() {
    const { isProcessing, dataList } = this.props;
    let html = [];
    let grandTotal = 0;
    let hasil = 0;
    let hasilSub = 0;
    if (dataList != null && dataList != '') {
      let row = dataList;
      for (var i = 0; i < row.length; i++) {
        let col = row[i].coa2;
        let subTotal = 0;
        html.push(
          <React.Fragment>
            <tr className="table-fill-bold" key={'header' + i}>
              <td></td>
              <td>{row[i].name_coa1}</td>
              <td></td>
            </tr>
          </React.Fragment>
        );
        if (col.length > 0) {
          for (var j = 0; j < col.length; j++) {
            subTotal += parseFloat(col[j].total_show_no) || 0;
            html.push(
              <React.Fragment>
                <tr className="table-fill" key={'fill' + j}>
                  <td style={{ textAlign: 'center' }}>{col[j].id_coa2}</td>
                  <td>{col[j].name_coa2}</td>
                  <td style={{ textAlign: 'right', paddingRight: '10px' }}>{col[j].total_show_format_no}</td>
                </tr>
              </React.Fragment>
            );
          }
          hasilSub = subTotal < 0 ? subTotal*(-1) : subTotal
          html.push(
            <React.Fragment>
              <tr className="table-fill-bold" key={'total' + row[i].id_coa1}>
                <td></td>
                <td>{'TOTAL ' + row[i].name_coa1}</td>
                <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                    {hasilSub < 0? 
                    '('+ hasilSub.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +')':
                         hasilSub.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    }
                </td>
              </tr>
            </React.Fragment>
          );
        }

        if(row[i].position == 'Debt'){
          grandTotal -= subTotal;
        }else{
          grandTotal += subTotal;
        }
      }
      hasil = grandTotal < 0 ? grandTotal*(-1) : grandTotal
      // let hasil = grandTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      html.push(
        <React.Fragment>
          <tr className="table-fill-bold" key={'grandTotal'} style={{backgroundColor:'#C0C0C0'}}>
            <td></td>
            <td>{'TOTAL INCOME STATEMENT'}</td>
            <td style={{ textAlign: 'right', paddingRight: '10px' }}>
              {grandTotal < 0? 
                '('+ hasil.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +')':
                     hasil.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              }
          </td>
          </tr>
        </React.Fragment>
      );
    }else{
      html.push(
        <React.Fragment>
          <tr className="table-fill-bold" key={'false'}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </React.Fragment>
      );
    }
    return html;

  }

  renderTab() {
    const { isProcessing, helperList } = this.props;
    this.state.yearList = helperList.rangeYear

    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="laporanIncomeStatement"
        id="laporan"
        className="mb-3"
      >
        <Tab eventKey="laporanIncomeStatement" title={i18n.t("Income Statement Report")}>
          <React.Fragment>
            
                  <Fragment>
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <Select2
                          isClearable={true}
                          name={"month"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            await this.handlerSelect(e, "month", "monthValue");
                          }}
                          options={this.state.monthList}
                          title={i18n.t("Month")}
                          value={this.state.monthValue}
                          placeholder={this.state.monthText}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                          {this.renderError('month')}
                        </div>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Select2
                          isClearable={true}
                          name={"year"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            await this.handlerSelect(e, "year", "yearValue");
                          }}
                          options={this.state.yearList}
                          title={i18n.t("Year")}
                          value={this.state.yearValue}
                          placeholder={this.state.yearText}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                          {this.renderError('year')}
                        </div>
                      </div>
                      <div className="col-md-4 mt-3">
                        <Select2
                          isClearable={true}
                          name={"idBranch"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            if (e == null) {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                            } else {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                            }
                          }}
                          options={this.state.branchList}
                          title={i18n.t("Branch")}
                          value={this.state.branchCurrent}
                          placeholder={this.state.branchText}
                          labelfield={"true"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <Button onClick={async (e) => {
                          await this.setState({ show: true }, () => this.setData())}}
                          variant="success" className="btn btn-md btn btn-primary m-1 ">
                          <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Display")} 
                        </Button>

                        <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton"
                         data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Convert
                        </button>
                        <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item"
                          href={"/accounting/income-statement/print/"+ (this.state.branchCurrent != null ? this.state.branchCurrent.value : 'all') +"/"+
                          (this.state.monthValue != null ? this.state.monthValue.value : 'all') +"/"+
                          (this.state.yearValue != null? this.state.yearValue.value: 'all') 
                          
                          } target="_blank">
                          <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  {i18n.t("PDF")}</a> 
                          <a className="dropdown-item " href="#" onClick={()=>this.convertExcel()}>
                            <FontAwesomeIcon icon={faFileExcel}>
                            </FontAwesomeIcon>  {i18n.t("Excel")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                  {this.state.show == true ?
                    <React.Fragment>
                      <div className={isProcessing ? " table-loading" : ""}>
                        {!isProcessing ? (
                          <Fragment>
                            <div>
                              <br />
                              <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5'}}>
                                <thead>
                                  <tr className="table-header">
                                    <th width={"10%"} style={{ textAlign: 'center', color:'white'}}>{i18n.t("Account")}</th>
                                    <th width={"40%"} style={{ textAlign: 'center', color:'white' }}>{i18n.t("Description")}</th>
                                    <th width={"30%"} style={{ textAlign: 'center', color:'white' }}>{i18n.t("This Month")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderList()}
                                </tbody>
                              </table>
                            </div>
                          </Fragment>

                        ) : (
                          <div className="table-loading-message">
                            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                            <div className="text-center">{i18n.t("Loading")}</div>
                          </div>
                        )}
                      </div>
                    </React.Fragment> :

                    ''}
              
          </React.Fragment>
        </Tab>

      </Tabs>
    );
  }
  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.coaList = helperList;
    this.state.listData = datalist;
    this.state.branchList = helperList.branch;
    
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default IncomeStatement;

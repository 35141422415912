import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { ReturnSelling } from "../../../../components/modules/sales/return-selling";
import ReturnSellingMiddleware from "../../../../middleware/modules/sales/return-selling";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ auth, returnSelling }) => ({
  isProcessing: returnSelling.isProcessing,
  isError: returnSelling.isError,
  errorMessage: returnSelling.errorMessage,
  data: returnSelling.data,
  showModal: returnSelling.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => ReturnSellingMiddleware.create(),
      newBackWarehouse: (id) => ReturnSellingMiddleware.newBackWarehouse(id),
      reset: () => ReturnSellingMiddleware.resetList(),
      approve: (id, value, search) => ReturnSellingMiddleware.beforeApprove(id, value, search),
      reject: (id, value, search) => ReturnSellingMiddleware.beforeReject(id, value, search),
      search: (data) => ReturnSellingMiddleware.list(data),
      update: (data) => ReturnSellingMiddleware.update(data),
      delete: (id, search) => ReturnSellingMiddleware.beforeDelete(id, search),
      getHelperFull: (params) =>
        ReturnSellingMiddleware.getHelperFull(params),
    },
    dispatch
  );
};
class ReturnSellingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
    this.backFunc = this.backFunc.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value, search) {
    this.props.reject(id, value, search);
  }
  backFunc(id) {
    this.props.newBackWarehouse(id);
    let params={
      idRetur:id
    }
    this.props.getHelperFull(params);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Sales Return List")}
          </title>
        </Helmet>
      <ReturnSelling
       {...this.props}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        approveItem={this.approveItem}
        rejectItem={this.rejectItem}
        backFunc={this.backFunc}

      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnSellingContainer);

export default class TargetAssignmentActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static SHOW_DATA_ASSIGN='SHOW_DATA_ASSIGN';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_TARGETASSIGNMENT='GET_HELPER_TARGETASSIGNMENT';
    static GET_HELPER_TARGETASSIGNMENT_FULL ='GET_HELPER_TARGETASSIGNMENT_FULL';
    static BEFORE_PRINT_TARGETASSIGNMENT ='BEFORE_PRINT_TARGETASSIGNMENT';
    static PRINT_TARGETASSIGNMENT ='PRINT_TARGETASSIGNMENT';
    static RESET_LIST="RESET_LIST";
    static NEW_DATA_TARGET="NEW_DATA_TARGET";
  
    static submit(){
      return {
        type: TargetAssignmentActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: TargetAssignmentActions.NEW_DATA,
        payload: data
      };
    }
    static createTarget(data) {
      return {
        type: TargetAssignmentActions.NEW_DATA_TARGET,
        payload: data
      };
    }
  
  
    static update(data) {
      return {
        type: TargetAssignmentActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: TargetAssignmentActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: TargetAssignmentActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: TargetAssignmentActions.SHOW_DATA,
        payload: data
      };
    }
    static listAssign(dataAssign) {
      return {
        type: TargetAssignmentActions.SHOW_DATA_ASSIGN,
        payload: dataAssign
      };
    }
    static error(error){
      return {
        type: TargetAssignmentActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: TargetAssignmentActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: TargetAssignmentActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: TargetAssignmentActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: TargetAssignmentActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: TargetAssignmentActions.GET_HELPER_TARGETASSIGNMENT_FULL,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  TargetAssignmentActions.GET_HELPER_TARGETASSIGNMENT,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: TargetAssignmentActions.BEFORE_PRINT_TARGETASSIGNMENT,
      };
    }
    static print(data){
      return {
        type: TargetAssignmentActions.PRINT_TARGETASSIGNMENT,
        payload: data
      };
    }
  }
  
  
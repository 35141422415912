import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Route } from '../../../../components/modules/user/route';
// middlewares
import RouteMiddleware from '../../../../middleware/modules/user/route';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,route }) => ({
  isProcessing: route.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  data:route.data,
  showModal:route.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>RouteMiddleware.resetList(),
    assign: (data) =>RouteMiddleware.beforeAssign(data),
    getData: () => RouteMiddleware.show(),
    revoke : (data) =>RouteMiddleware.beforeRevoke(data),
  }, dispatch);
};
class RouteContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    this.props.reset()
    this.props.getData()
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Route List")}
          </title>
        </Helmet>
      <Route
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Store from '../../../../components/modules/master/store';
import StoresMiddleware from '../../../../middleware/modules/master/store';
import BranchMiddleware from '../../../../middleware/modules/master/branch';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares


const mapStateToProps = ({ auth,stores }) => ({
  isProcessing: stores.isProcessing,
  isError: stores.isError,
  errorMessage: stores.errorMessage,
  datalist:stores.data,
  showModal:stores.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>StoresMiddleware.getHelper(),
    create: () =>StoresMiddleware.create(),
    reset: () =>StoresMiddleware.resetList(),
    search: data =>StoresMiddleware.list(data),
    update: data =>StoresMiddleware.update(data),
    delete: (id,search) =>StoresMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class StoreContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.getHelper();
    this.props.update(id)
  }
  createItem(){
    this.props.create()
    this.props.getHelper();
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Store List")}
          </title>
        </Helmet>
      <Store
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);

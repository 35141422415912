import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { WarehouseIn } from "../../../../components/modules/warehouse/warehouse-in";
// middlewares
import WarehouseInMiddleware from "../../../../middleware/modules/warehouse/warehouse-in";
import ItemMiddleware from "../../../../middleware/modules/master/item";
import UnitMiddleware from "../../../../middleware/modules/master/unit";
import SupplierMiddleware from "../../../../middleware/modules/master/supplier";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";

const mapStateToProps = ({ auth, warehousein }) => ({
  isProcessing: warehousein.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist: warehousein.data,
  showModal: warehousein.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      cancel: (id, value, search) =>
        WarehouseInMiddleware.beforeCancel(id, value, search),
      create: (search) => WarehouseInMiddleware.create(search),
      reset: () => WarehouseInMiddleware.resetList(),
      search: (data) => WarehouseInMiddleware.list(data),
      update: (data) => WarehouseInMiddleware.update(data),
      getHelperFull: (date) => WarehouseInMiddleware.getHelperFull(date),
      delete: (id, search) => WarehouseInMiddleware.beforeDelete(id, search),
      view: data =>WarehouseInMiddleware.getOne(data),
    },
    dispatch
  );
};

class WarehouseInContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {},
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.cancelItem = this.cancelItem.bind(this);
    this.viewItem = this.viewItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  cancelItem(id, value, search) {
    this.props.cancel(id, value, search);
  }
  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.update(id);
    // this.props.getHelperFull();
  }
  createItem(search = null) {
    this.props.create(search);
    this.props.getHelperFull();
  }
  viewItem(id){
    this.props.view(id)
    // this.props.getHelperFull()
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Warehouse In List")}
          </title>
        </Helmet>
      <WarehouseIn
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        {...this.props}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        cancelItem={this.cancelItem}
        viewItem={this.viewItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseInContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import SalesInvoiceReport from '../../../../components/modules/report/sales-report';
import SalesInvoiceReportMiddleware from '../../../../middleware/modules/report/sales-report';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,salesInvoiceReport }) => ({
  isProcessing: salesInvoiceReport.isProcessing,
  isError: salesInvoiceReport.isError,
  errorMessage: salesInvoiceReport.errorMessage,
  datalist:salesInvoiceReport.data,
  showModal:salesInvoiceReport.showModal,
  helperSalesInvoiceReport:salesInvoiceReport.helperSalesInvoiceReport
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>SalesInvoiceReportMiddleware.getHelper(),
    reset: () =>SalesInvoiceReportMiddleware.resetList(),
    excel: (data) =>SalesInvoiceReportMiddleware.showExcel(data),
  }, dispatch);
};
class SalesInvoiceReportContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Sales Invoice Report")}
          </title>
        </Helmet>
      <SalesInvoiceReport
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceReportContainer);

import { useParams } from "react-router-dom";
import React from "react";
import JournalContainer from '../../../containers/Print/default/Journal'
export const PrintJournal = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <JournalContainer id={id}/>
    )
}

export default PrintJournal;
import React, { Component } from "react";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import InputNumber from "../../../utilities/Form/InputNumber";
import i18n from "../../../../i18n";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ACC3ID: "",
      ACC3Name: "",
      parentACC2ID: null,
      coa2List: null,
      parentCurrent: null,
      parentText: i18n.t("Select COA 2"),
      dateCutOff: new Date(),
      beginningBalance: 0,

      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,

      [key]: value,
    });
  }
  handler2(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,

      [key]: value,
    });
  }
  runSubmit() {
    return this.state;
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,

      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerCurrencyArray(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  render() {
    const { data, isNewRecord, coa2List } = this.props;
    this.state.coa2List = coa2List;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.ACC3Name = data.name),
          (this.state.ACC3ID = data.code),
          (this.state.parentCurrent = {
            value: data.parent_id == null ? "" : data.parent_id,
            label: data.parent,
          }),
          (this.state.dateCutOff = new Date(data.dateCutOff)),
          (this.state.beginningBalance = data.beginning_balance),
          (this.state.isUpdate = 2);
      }
    }
    return (
      <form>
        <div className="row"></div>
        <div className="col-md-6">
          <Input
            type={"input"}
            name={"ACC3ID"}
            title={i18n.t("ACC3 ID")}
            required
            value={this.state.ACC3ID}
            handler={this.handler}
            error={this.renderError("ACC3ID")}
          />
        </div>
        <div className="col-md-12">
          <Input
            type={"input"}
            name={"ACC3Name"}
            title={i18n.t("ACC2 Name")}
            required
            value={this.state.ACC3Name}
            handler={this.handler}
            error={this.renderError("ACC3Name")}
          />
        </div>
        <div className="col-md-6">
          <Select2
            isClearable={true}
            name={"parentACC2ID"}
            title={i18n.t("ID COA 2")}
            required={true}
            onChange={(e) =>
              this.handlerSelect(e, "parentACC2ID", "parentCurrent")
            }
            options={this.state.coa2List}
            value={this.state.parentCurrent}
            placeholder={this.state.parentText}
            error={this.renderError("parentACC2ID")}
          />
        </div>
        <div className="row">
          <div className="col-md-3">
            <DatePickerCustom
              name={"dateCutOff"}
              title={i18n.t("Date")}
              required={true}
              selected={this.state.dateCutOff}
              onChange={(e) => this.handlerDateTime(e, "dateCutOff")}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              showYearDropdown
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-md-3">
            <InputNumber
              id={"beginningBalance"}
              name="beginningBalance"
              placeholder="Beginning Balance"
              title={i18n.t("Balance")}
              defaultValue={0}
              value={this.state.beginningBalance}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "beginningBalance")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

import { useParams } from "react-router-dom";
import React from "react";
import StockCardContainer from '../../../containers/Print/default/Stock-Card'
export const PrintStockCard = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <StockCardContainer data={data}/>
    )
}

export default PrintStockCard;
import GeneralLedgerActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperGeneralLedger:[],
  errorInternal:false,
  printGeneralLedger:null
};

function GeneralLedgerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GeneralLedgerActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case GeneralLedgerActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case GeneralLedgerActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case GeneralLedgerActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case GeneralLedgerActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case GeneralLedgerActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case GeneralLedgerActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case GeneralLedgerActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case GeneralLedgerActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case GeneralLedgerActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case GeneralLedgerActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case GeneralLedgerActions.GET_HELPER_GENERALLEDGER:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperGeneralLedger:action.payload};
    case GeneralLedgerActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case GeneralLedgerActions.BEFORE_PRINT_GENERALLEDGER:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printGeneralLedger:null,isProcessing:true};
    case GeneralLedgerActions.PRINT_GENERALLEDGER:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printGeneralLedger:action.payload,isProcessing:false};    
       
    default:
      return state;
    }
}

export default GeneralLedgerReducer;

import SubWarehouseActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSubWarehouse:null,
  errorInternal:false
};

function SubWarehouseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SubWarehouseActions.RESET_LIST:
      return INITIAL_STATE;
    case SubWarehouseActions.NEW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SubWarehouseActions.UPDATE_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case SubWarehouseActions.VIEW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SubWarehouseActions.RESET_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SubWarehouseActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SubWarehouseActions.ON_SUBMIT:
      return { ...state, errorInternal:false, onSubmit:true};
    case SubWarehouseActions.SHOW_DATA:
      return { ...state,  errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SubWarehouseActions.SUCCESS_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form };
    case SubWarehouseActions.PROCESS_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case SubWarehouseActions.GET_HELPER_SUBWAREHOUSE:
      return { ...state, errorInternal:false, onSubmit:false, isError: false,isSuccess:false,helperSubWarehouse:action.payload};
    case SubWarehouseActions.ERROR_SERVER:
      return { ...state, onSubmit:false,  isError: false, errorInternal:true,  errorMessage: action.payload, isProcessing:false };
    case SubWarehouseActions.ERROR_DATA:
      return { ...state,  errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
     
    default:
      return state;
    }
}

export default SubWarehouseReducer;

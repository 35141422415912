import PermissionActions from './actions';

const INITIAL_STATE = {
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:true,
  onSubmit:false,
  data:{},
  errorInternal:false,
  helperFull:null,
};

function PermissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PermissionActions.BEFORE_SHOW_ASSIGN:
      return { ...state,isError: false, isProcessing: true,onSubmit:false,data:{}};
    case PermissionActions.SHOW_ASSIGN:
      return { ...state,isError: false, isProcessing: false,onSubmit:false,data:action.payload};
    case PermissionActions.BEFORE_ASSIGN:
      return { ...state,isError: false, isProcessing: true,onSubmit:true};
    case PermissionActions.AFTER_ASSIGN:
      return { ...state, isProcessing: false,onSubmit:false,data:action.payload};
      case PermissionActions.BEFORE_REVOKE:
        return { ...state,isError: false, isProcessing: true,onSubmit:true};
      case PermissionActions.AFTER_REVOKE:
        return { ...state, isProcessing: false,onSubmit:false,data:action.payload};
    case PermissionActions.RESET_LIST:
      return { ...state, isProcessing: false,data:{}, form:INITIAL_STATE.form,isNewRecord:true};
    case PermissionActions.NEW_DATA:
      return { ...state, isProcessing: false,data:{}, form:INITIAL_STATE.form,isNewRecord:true};
    case PermissionActions.UPDATE_DATA:
      return { ...state, isProcessing: false, data:{},isNewRecord:false};
    case PermissionActions.VIEW_DATA:
      return { ...state, isProcessing: false, data:action.payload,isNewRecord:false};
    case PermissionActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case PermissionActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case PermissionActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case PermissionActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PermissionActions.SUCCESS_DATA:
      return { ...state, isError: false, form:INITIAL_STATE.form , onSubmit:false};
    case PermissionActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case PermissionActions.GET_HELPER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperFull:action.payload};  
    case PermissionActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PermissionActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
     
    default:
      return state;
    }
}


export default PermissionReducer;

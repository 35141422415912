import cookieManager from './../../utils/cookieManager';
import axios from 'axios';
import AuthActions from './actions';
import LocalStorageManager from '../../utils/LocalStorageManager';

export default class AuthMiddleware {
  static reset(){
    return (dispatch) => {
      dispatch(AuthActions.reset());
    };
  }

  static beforeRequest(credentials){
    return (dispatch) => {
      dispatch(AuthActions.beforeRequest());
      AuthMiddleware.request(dispatch, credentials);
    };
  }

  static request(dispatch, credentials){
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REST_URL +'reset-password',
      responseType: 'json',
      data:credentials
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        if (credentials) {
            dispatch(AuthActions.requestSuccess());
        } else {
            dispatch(AuthActions.requestFailed(["Invalid email entered !!"]));
        }
      }
    })
    .catch((error) => {
      switch(error.response.status){
        case 404:
          dispatch(AuthActions.requestFailed(error.response.data.error));
        break;
        case 500:
          dispatch(AuthActions.requestFailed(error.response.data.error));
        break;
        case 401:
          dispatch(AuthActions.requestFailed(error.response.data.error));
        break;
        case 403:
          dispatch(AuthActions.requestFailed(error.response.data.error));
        break;
        default:
          dispatch(AuthActions.requestFailed(['Internal Server Error !!']));
          break;
      }
     
    });
  }

  static beforeVerivication(credentials){
    return (dispatch) => {
      dispatch(AuthActions.beforeSetPassword());
      AuthMiddleware.verificationToken(dispatch, credentials);
    };
  }

  static verificationToken(dispatch, credentials){
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REST_URL +'verification-forgot',
      responseType: 'json',
      data: {
        token: credentials,
      }
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        if (credentials) {
            dispatch(AuthActions.verificationForgotSuccess());
        } else {
            dispatch(AuthActions.verificationForgotFailed(['Invalid Token']));
        }
      }
    })
    .catch((error) => {
      switch(error.response.status){
        case 404:
          dispatch(AuthActions.verificationForgotFailed(error.response.data.error));
        break;
        case 500:
          dispatch(AuthActions.verificationForgotFailed(error.response.data.error));
        break;
        case 401:
          dispatch(AuthActions.verificationForgotFailed(error.response.data.error));
        break;
        case 403:
          dispatch(AuthActions.verificationForgotFailed(error.response.data.error));
        break;
        default:
          dispatch(AuthActions.verificationForgotFailed(['Internal Server Error !!']));
          break;
      }
    });
  }

  static beforeSetPassword(data){
    return (dispatch) => {
      if(data.password != data.rePassword){
        dispatch(AuthActions.failedNew(['Password not match !!']))
      }else{
        dispatch(AuthActions.beforeSetPassword());
        AuthMiddleware.setPassword(dispatch, data);
      }
      
    };
  }

  static setPassword(dispatch, credentials){
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REST_URL +'new-password',
      responseType: 'json',
      data: {
        token: credentials.token,
        password: credentials.password,
      }
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        if (credentials) {
            dispatch(AuthActions.successNew());
        } else {
            dispatch(AuthActions.failedNew(['Invalid Token']));
        }
      }
    })
    .catch((error) => {
      switch(error.response.status){
        case 404:
          dispatch(AuthActions.failedNew(error.response.data.error));
        break;
        case 500:
          dispatch(AuthActions.failedNew(error.response.data.error));
        break;
        case 401:
          dispatch(AuthActions.failedNew(error.response.data.error));
        break;
        case 403:
          dispatch(AuthActions.failedNew(error.response.data.error));
        break;
        default:
          dispatch(AuthActions.failedNew(['Internal Server Error !!']));
          break;
      }
    });
  }


  // Signin Functions Starts
  static signin(credentials) {
    return (dispatch) => {
      dispatch(AuthActions.signin());
      AuthMiddleware.signinWithUserCredentials(dispatch, credentials);
    };
  }

  static signinWithUserCredentials(dispatch, credentials) {
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REST_URL +'login',
      responseType: 'json',
      data: {
        username: credentials.username,
        password: credentials.password,
        remember_me: credentials.remember_me,
      }
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        if (credentials) {
            var response=response.data;
            cookieManager.setUserToken(response.result.accessToken);
            cookieManager.setUserToken(response.result)
            dispatch(AuthActions.signinSuccessful(response.result.user));
        } else {
          dispatch(AuthActions.signinRejected('error no credentials'));
        }
      }
    })
    .catch((error) => {
      if(error.response !=undefined){
        const data=error.response.data
        dispatch(AuthActions.signinRejected(data.error));
      }else{
        dispatch(AuthActions.signinRejected(['Internal Server Error']));
      }
    });
  }
  // Signin Functions Ends

  // Logout Functions Starts
  static logout() {
    return (dispatch) => {
      dispatch(AuthActions.logout());
      AuthMiddleware.logoutFromAPI(dispatch);
    };
  }

  static logoutFromAPI(dispatch) {
    const token = cookieManager.getUserToken();
    cookieManager.removeUserToken();
    LocalStorageManager.clearLocalStorage();
    cookieManager.removeUserToken();
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      url: process.env.REST_URL +'logout',
      data:[],
      responseType: 'json',
    })
    .then((response) => {
    })
    .catch((error) => {
     });
     dispatch(AuthActions.logoutSuccessful());
  }
  // Logout Functions Ends


  static checkLogin() {
    return (dispatch) => {
      AuthMiddleware.isLoggedIn(dispatch);
    };
  }

  // isLoggedIn
  static isLoggedIn(dispatch) {
      // const token = cookieManager.getUserToken();
      const token = cookieManager.getCookie('accessToken');
      // AuthMiddleware.ensureAuthenticated(dispatch, token);
      if (token) {
        axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          url: process.env.REST_URL +'user',
          responseType: 'json',
        })
        .then((response) => {
          AuthMiddleware.ensureAuthenticated(dispatch, token,response.data.result.user);
        })
        .catch((error) => {
          dispatch(AuthActions.signinRejected(['token expired, please login again']));
         });
      } else {
        dispatch(AuthActions.isLoggedInFailure());
      }
  }

  // ensureAuthenticated
  static ensureAuthenticated(dispatch, token,data=null) {
    if (token) {
      dispatch(AuthActions.isLoggedInSuccess(data));
    } else {
      // never gonna happen
      // dispatch(AuthActions.signinRejected('no token'));
      dispatch(AuthActions.isLoggedInFailure());
    }
  }
}

export default class PaymentActions {
    static BEFORE_PAYMENT='BEFORE_PAYMENT'
    static AFTER_PAYMENT='AFTER_PAYMENT'
    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_PAYMENT='GET_HELPER_PAYMENT';
    static GET_HELPER_PAYMENT_FULL ='GET_HELPER_PAYMENT_FULL';
    static RESET_LIST ='RESET_LIST';
    
    static beforePayment(data){
      return {
        type: PaymentActions.BEFORE_PAYMENT,
        payload: data
      };
    }
    static afterPayment(data){
      return {
        type: PaymentActions.AFTER_PAYMENT,
        payload: data
      };
    }
    static submit(){
      return {
        type: PaymentActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: PaymentActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: PaymentActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: PaymentActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: PaymentActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: PaymentActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: PaymentActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: PaymentActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: PaymentActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: PaymentActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: PaymentActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: PaymentActions.GET_HELPER_PAYMENT,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  PaymentActions.GET_HELPER_PAYMENT_FULL,
        payload:data
      };
    }
  }
  
  
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select2 from "../../../utilities/Form/Select2";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      dateRange: new Date(),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      storeCurrent: null,
      storeText: i18n.t("Select Store"),
      storeList: [],
      itemCurrent: null,
      itemList: [],
      itemText: i18n.t("Select Item"),
      itemTypeCurrent: null,
      itemTypeList: [],
      itemTypeText: i18n.t("Select Item Type"),
      warehouseCurrent: null,
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      data: [],
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.show = this.show.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  changeData(stateName, type, result = "") {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;

      default:
        type = null;
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (result == "") {
              var arr = { [stateName]: response.data.result };
            } else {
              var arr = { [stateName]: response.data.result[result] };
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  show() {
    var idBranch =
      this.state.branchCurrent != null ? this.state.branchCurrent.value : null;
    var idStore =
      this.state.storeCurrent != null ? this.state.storeCurrent.value : null;
    var idItem =
      this.state.itemCurrent != null ? this.state.itemCurrent.value : null;
      var idType =
      this.state.itemTypeCurrent != null ? this.state.itemTypeCurrent.value : null;
    var idWarehouse =
      this.state.warehouseCurrent != null
        ? this.state.warehouseCurrent.value
        : null;
    var startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    var endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    let params = {
      branch: idBranch,
      store: idStore,
      startDate: startDate,
      endDate: endDate,
      idItem: idItem,
      idWarehouse: idWarehouse,
      idType:idType
    };
    this.props.getData(params);
  }
  render() {
    const { helperList,onChoose, mutationShow } =
      this.props;
    this.state.branchList = (helperList.branch != undefined)?helperList.branch:[];
    this.state.itemList = (helperList.item != undefined)?helperList.item:[];
    this.state.itemTypeList = (helperList.itemType != undefined)?helperList.itemType:[];
    if (onChoose) {
      this.state.data = mutationShow;
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-4">
              <DatePickerCustom
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                selectsRange={true}
                isClearable={true}
                name={"date"}
                title={i18n.t("Date Period")}
                required={true}
                onChange={(e) => {
                  const [start, end] = e;
                  this.setState({ startDate: start, endDate: end });
                }}
                showYearDropdown
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                peekNextMonth
                showMonthDropdown
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-4">
              <Select2
                isClearable={true}
                name={"idItem"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idItem", "itemCurrent");
                }}
                options={this.state.itemList}
                title={i18n.t("Item")}
                value={this.state.itemCurrent}
                placeholder={this.state.itemText}
              />
            </div>
            <div className="col-4">
              <Select2
                isClearable={true}
                name={"itemType"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "itemType", "itemTypeCurrent");
                }}
                options={this.state.itemTypeList}
                title={i18n.t("Item Type")}
                value={this.state.itemTypeCurrent}
                placeholder={this.state.itemTypeText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e != this.state.branchCurrent) {
                      
                      this.setState({storeList:[] },()=>{
                         this.handlerSelect(null, "idStore", "storeCurrent");
                      });
                        
                  }
                  await this.handlerSelect(e, "idBranch", "branchCurrent");
                  await this.changeData("storeList", "getStore", "");
                  await this.changeData("warehouseList", "getWarehouse", "");
                  
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
              />
            </div>
            <div className="col-4">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                  await this.changeData("warehouseList", "getWarehouse", "");
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
              />
            </div>

            <div className="col-4">
              <Select2
                isClearable={true}
                name={"idWarehouse"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(
                    e,
                    "idWarehouse",
                    "warehouseCurrent"
                  );
                }}
                options={this.state.warehouseList}
                title={i18n.t("Warehouse")}
                value={this.state.warehouseCurrent}
                placeholder={this.state.warehouseText}
              />
            </div>
          </div>
          <Button
            variant="success"
            className="btn btn-sm"
            onClick={async () => {
              await this.show();
            }}
          >
            {" "}
            {i18n.t("Show")}
          </Button>
          <hr />
        </form>
      </React.Fragment>
    );
  }
}

export default Form;

export default class BillingActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static CANCEL_DATA='CANCEL_DATA'
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static SUCCESS_DATA_PAYMENT='SUCCESS_DATA_PAYMENT';
    static GET_HELPER_BILLING='GET_HELPER_BILLING';
    static BEFORE_PRINT_BILLING ='BEFORE_PRINT_BILLING';
    static PRINT_BILLING ='PRINT_BILLING';
    static RESET_LIST="RESET_LIST";
    static BEFORE_SALESBILLING='BEFORE_SALESBILLING';
    static AFTER_SALESBILLING='AFTER_SALESBILLING';
    static LIST_REPAYMENT='LIST_REPAYMENT';
    static SHOW_DATA_PAYMENT = 'SHOW_DATA_PAYMENT';
    static SHOW_DATA_BILLING='SHOW_DATA_BILLING';
    static SHOW_DATA_INDEX='SHOW_DATA_INDEX';
    static SHOW_DATA_PAY_BILLING = 'SHOW_DATA_PAY_BILLING';
    static RESET_DATA_BILL = 'RESET_DATA_BILL';
    static NEW_PAYMENT = "NEW_PAYMENT";
    static CLOSING_DATA = "CLOSING_DATA";
    static VOID_DATA_PAYMENT = "VOID_DATA_PAYMENT";
  
    static submit(){
      return {
        type: BillingActions.ON_SUBMIT
      };
    }

    static beforeSalesBilling(data){
      return {
        type: BillingActions.BEFORE_SALESBILLING,
        payload: data
      };
    }

    static listRepayment(dataRepayment){
      return {
        type: BillingActions.LIST_REPAYMENT,
        payload: dataRepayment
      };
    }

    static afterSalesBilling(data){
      return {
        type: BillingActions.AFTER_SALESBILLING,
        payload: data
      };
    }
  
    static create(data) {
      return {
        type: BillingActions.NEW_DATA,
        payload: data
      };
    }

    static payment(data) {
      return {
        type: BillingActions.NEW_PAYMENT,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: BillingActions.UPDATE_DATA,
        payload: data
      };
      
    }

    static closing(data) {
      return {
        type: BillingActions.CLOSING_DATA,
        payload: data
      };
      
    }

    static void(data) {
      return {
        type: BillingActions.VOID_DATA_PAYMENT,
        payload: data
      };
      
    }

    static cancel() {
      return {
        type: BillingActions.CANCEL_DATA
      };
    }
  
    static view(data) {
      return {
        type: BillingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: BillingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: BillingActions.SHOW_DATA,
        payload: data
      };
    }

    static listPayment(dataPayment) {
      return {
        type: BillingActions.SHOW_DATA_PAYMENT,
        payload: dataPayment
      };
    }
    
    static error(error){
      return {
        type: BillingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: BillingActions.SUCCESS_DATA,
      };
    }

    static successPay(dataRepayment){
      return {
        type: BillingActions.SUCCESS_DATA_PAYMENT,
        payload:dataRepayment
      };
    }
    static process(){
      return {
        type: BillingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: BillingActions.RESET_DATA,
      };
    }
    static resetBill(){
      return {
        type: BillingActions.RESET_DATA_BILL,
      };
    }
    static resetList(){
      return {
        type: BillingActions.RESET_LIST,
      };
    }

    static helper(data){
      return {
        type:  BillingActions.GET_HELPER_BILLING,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: BillingActions.BEFORE_PRINT_BILLING,
      };
    }
    static listBill(data) {
      return {
        type: BillingActions.SHOW_DATA_BILLING,
        payload: data
      };
    }
    static listIndex(data) {
      return {
        type: BillingActions.SHOW_DATA_INDEX,
        payload: data
      };
    }
    static listPayBill(data) {
      return {
        type: BillingActions.SHOW_DATA_PAY_BILLING,
        payload: data
      };
    }
    static print(data){
      return {
        type: BillingActions.PRINT_BILLING,
        payload: data
      };
    }
  }
  
  
import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faFileExcel, faEye, faDisplay } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TableAccountReceiveable from "./table";
import genRandonString from "../../../utilities/Class/RandomString";
import { Link } from "react-router-dom";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";
const MySwal = withReactContent(Swal);
export class AccountReceiveable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      branchExcelCurrent: null,
      branchExcelList: [],
      branchExcelText: i18n.t('All'),
      customerList: [],
      customerCurrent: null,
      idCustomer: null,
      customerText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      periodCurrentExcel: null,
      periodTextExcel: i18n.t("Select Period"),
      periodListExcel: [],
      page: 1,
      type: 2,
      typeExcel: 2,
      show: 0,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Account Receiveable"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);

  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }
  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  setData() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);
    var idCustomer = (this.state.idCustomer == null) ? '' : this.state.idCustomer;
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var type = this.state.type;

    let params = {
      startDate: startDate,
      endDate: endDate,
      idCustomer: idCustomer,
      idBranch: idBranch,
      limit: limit,
      page: page,
      type: type,
    }
    this.props.setData(params);
  }

  renderList() {
    const { helperList, data } = this.props
    this.state.listData = data;
    if (data.data != undefined) {
      let row = data.data
      let html = [];
      let no = 1;
      let totalDebt = 0;
      let totalCredit = 0;
      if (data != undefined && data != null && row != null) {
        Swal.close();
        for (var p = 0; p < row.length; p++) {
          if (row[p].date != null) {
            html.push(
              <tr key={p} className="border">
                <td className="text-center">{no++}</td>
                <td>{row[p].date}</td>
                <td>{row[p].branch_name}</td>
                <td>{row[p].customer_name}</td>
                <td>
                  <Link
                    to={"/accounting/journal/detail/" + row[p].id_journal}
                  >
                    {row[p].no_journal}
                  </Link>
                </td>
                <td>{row[p].description}</td>
                <td style={{ textAlign: 'right' }}>{row[p].debt_format}</td>
                <td style={{ textAlign: 'right' }}> {row[p].credit_format}</td>
                <td style={{ textAlign: 'right' , paddingRight:'5px'}}> {(row[p].balance_format) || 0
                  // ||0
                  //   .toFixed(2)
                  //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }</td>
              </tr>)
          }
          totalDebt += parseFloat(row[p].debt) || 0;
          totalCredit += parseFloat(row[p].credit) || 0;

        }
        html.push(
          <tr className="border" style={{backgroundColor:'#DCDCDC'}} >
            <td colSpan={'6'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalDebt)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalCredit)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
          </tr>)

        return html;
      }
    }
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;
    var nameFile2 = (this.state.customerCurrent == null) ? 'All' : this.state.customerCurrent.label;
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);

    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/account-receiveable/excel",
      method: "GET",
      params: {
        startDate: startDate,
        endDate: endDate,
        idCustomer: this.state.idCustomer,
        idBranch: this.state.idBranch,
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
          moment(startDate).format("D/M/YYYY") + ' - ' +
          moment(endDate).format("D/M/YYYY") + ' ' +
          nameFile + '/' + nameFile2 +
          ' Laporan Piutang.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Fill Work Period', 'info')
    });
  }

  async convertExcelCard() {
    var nameFile = (this.state.branchExcelCurrent == null) ? 'All' : this.state.branchExcelCurrent.label;
    var startDateExcel = this.state.typeExcel == 2 ? moment(this.state.startDateExcel).format("YYYY-MM-DD") : (this.state.periodCurrentExcel == null ? '' : this.state.periodCurrentExcel.startDate);
    var endDateExcel = this.state.typeExcel == 2 ? moment(this.state.endDateExcel).format("YYYY-MM-DD") : (this.state.periodCurrentExcel == null ? '' : this.state.periodCurrentExcel.endDate);
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/account-receiveable/excel-card",
      method: "GET",
      params: {
        startDate: startDateExcel,
        endDate: endDateExcel,
        idBranch: this.state.idBranchExcel,
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
          moment(startDateExcel).format("D/M/YYYY") + ' - ' +
          moment(endDateExcel).format("D/M/YYYY") + ' ' +
          nameFile +
          'Laporan Kartu Piutang.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();

      } else {
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Fill Work Period', 'info')
    });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    Swal.close();
    return i18n.t(value);
  }

  
  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }, () => this.setData()
    )

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (event.target.value == 0) {
      this.setState({ page: (get - 1) }, () => this.setData());
    } else if (event.target.value == (count - 1)) {
      this.setState({ page: (get + 1) }, () => this.setData());
    } else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }


  renderPagination() {
    const { isProcessing, helperList, datalist, data } = this.props;
    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '10', label: '10' }
      this.state.show = 1;
    }
    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"] : ""}
                      {i == data.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderTab() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.listData = datalist;

    return (
      <Tabs
        unmountOnExit
        defaultActiveKey={"laporanPiutang"}
        id="laporan"
        className="mb-3"
      >
        <Tab key={"laporanPiutang"} id="laporanPiutang" eventKey={"laporanPiutang"} title={i18n.t("AR Report")} >
          <Fragment key={"laporanPiutang"} >
            <div className="row">
              <div className="col-mb-1">
                <Radio
                  list={Period}
                  name={"type"}
                  title={""}
                  inline={false}
                  value={this.state.type}
                  handler={this.handlerRadio}
                />
              </div>
            </div>
            <div className="row">
            {this.state.type == 1 ?
              <Fragment>
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"period"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "idPeriod", "periodCurrent");
                    }}
                    options={this.state.periodList}
                    title={i18n.t("Week Period")}
                    value={this.state.periodCurrent}
                    placeholder={this.state.periodText}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('startDate')}
                  </div>
                </div>
              </Fragment>
              :
              <Fragment>

                <div className="col-md-4 mt-3">
                  <DatePickerCustom
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    selectsRange={true}
                    isClearable={false}
                    name={"date"}
                    title={i18n.t("Date Period")}
                    required={true}
                    onChange={(e) => {
                      const [start, end] = e;
                      this.setState({ startDate: start, endDate: end });
                    }}
                    la
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    autoComplete={"off"}
                    dateFormat="dd MMMM yyyy"
                    maxDate={new Date()}
                    className="form-control form-control-md"
                  />
                </div>

              </Fragment>
            }
            <div className="col-md-4 mt-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  this.setState({ customerCurrent: null, customerList: [] });
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ customerCurrent: null, customerList: [] });
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData('customerList', 'getCustomer', '');
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                labelfield={"true"}
              />
            </div>
            <div className="col-md-4 mt-3">
              <Select2
                isClearable={true}
                name={"idCustomer"}
                labelfield={"true"}
                onChange={async (e) => {
                  if (e == null) {
                    this.handlerSelect(e, "idCustomer", "customerCurrent")
                  }
                  else {
                    this.handlerSelect(e, "idCustomer", "customerCurrent")
                  }
                }}
                options={this.state.customerList}
                title={i18n.t("Customer")}
                value={this.state.customerCurrent}
                placeholder={this.state.customerText}
              />
            </div>
            </div>
            <div className="row">
              <div className="col-md-9 mt-3 mb-3">
                <Button onClick={async (e) => {
                  await this.setState({ page: 1 }, () => this.setData())
                  await this.setState({ headeTable: true })
                }}
                variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>

              <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Convert
              </button>
              <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                {/* <a class="dropdown-item" href="#" onClick={()=>this.showItem()}><FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  PDF</a> */}
                <a className="dropdown-item " href="#" onClick={()=>this.convertExcel()}><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>  Excel </a>
              </div>
            </div>
             {/* {this.state.headeTable == true?
            <Fragment>
            <div className="col-md-3 mt-3 ">
              <input
                  type={"text"}
                  className="form-control form-control-md justify-content-right"
                  placeholder="Search"
                  name={"search"}
                  title={""}
                  value={this.state.search}
                  onChange = {(e)=>this.handlerSearch(e)}
              />
            </div>
            </Fragment>:null} */}
            </div>
            {this.state.headeTable == true ?
              <React.Fragment>
                <div className={isProcessing ? " table-loading" : ""}>
                {!isProcessing ?
                  <Fragment>
                    <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5'}}>
                      <thead>
                        <tr className="table-header">
                          <th width={"3%"} className="text-center">{i18n.t("No.")}</th>
                          <th width={"10%"}>{i18n.t("Date")}</th>
                          <th width={"10%"}>{i18n.t("Branch")}</th>
                          <th width={"12%"}>{i18n.t("Customer Name")}</th>
                          <th width={"10%"}>{i18n.t("No. Journal")}</th>
                          <th width={"19%"} >{i18n.t("Remark")}</th>
                          <th width={"12%"}>{i18n.t("Debt")}</th>
                          <th width={"12%"}>{i18n.t("Credit")}</th>
                          <th width={"12%"}>{i18n.t("Balance")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderList()}
                      </tbody>
                    </table>
                    {this.renderPagination()}
                  </Fragment>
                  : this.renderLoading()
                }
                </div>
              </React.Fragment> :
              ''}
          </Fragment>
        </Tab>
        <Tab key={"laporanKartu"} id="laporanKartu" eventKey={"laporanKartu"} title={i18n.t("AR Card")} >
          <Fragment key={"laporanKartu"}>
            <div className="row">
                <div className="col-mb-1">
                    <Radio
                      list={Period}
                      name={"typeExcel"}
                      title={""}
                      inline={false}
                      value={this.state.typeExcel}
                      handler={this.handlerRadio}
                    />
                  </div>
                </div>
                <div className="row">
                  {this.state.typeExcel == 1 ?
                    <Fragment>
                      <div className="col-md-4 mt-3">
                        <Select2
                          isClearable={true}
                          name={"periodExcel"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            await this.handlerSelect(e, "idPeriodExcel", "periodCurrentExcel");
                          }}
                          options={this.state.periodListExcel}
                          title={i18n.t("Week Period")}
                          value={this.state.periodCurrentExcel}
                          placeholder={this.state.periodTextExcel}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                          {this.renderError('startDateExcel')}
                        </div>
                      </div>
                    </Fragment>
                    :
                    <Fragment>
                      <div className="col-md-4 mt-3">
                        <DatePickerCustom
                          startDate={this.state.startDateExcel}
                          endDate={this.state.endDateExcel}
                          selectsRange={true}
                          isClearable={false}
                          name={"date"}
                          title={i18n.t("Date Period")}
                          required={true}
                          onChange={(e) => {
                            const [start, end] = e;
                            this.setState({ startDateExcel: start, endDateExcel: end });
                          }}
                          la
                          showYearDropdown
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          peekNextMonth
                          showMonthDropdown
                          autoComplete={"off"}
                          dateFormat="dd MMMM yyyy"
                          maxDate={new Date()}
                          className="form-control form-control-md"
                        />
                      </div>
                    </Fragment>
                  }
                  <div className="col-md-4 mt-3">
                    <Select2
                      isClearable={true}
                      name={"idBranchExcel"}
                      required={true}
                      size={4}
                      onChange={async (e) => {
                        // this.setState({customerCurrent:null,customerList:[]});
                        if (e == null) {
                          await this.handlerSelect(e, "idBranchExcel", "branchExcelCurrent");
                          // await this.setState({customerCurrent:null,customerList:[]});
                        } else {
                          await this.handlerSelect(e, "idBranchExcel", "branchExcelCurrent");
                          // await this.changeData('customerList','getCustomer','');
                        }
                      }}
                      options={this.state.branchExcelList}
                      title={i18n.t("Branch")}
                      value={this.state.branchExcelCurrent}
                      placeholder={this.state.branchExcelText}
                      labelfield={"true"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mt-3 mb-3">
                  <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Convert
                  </button>
                  <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                    {/* <a class="dropdown-item" href="#" onClick={()=>this.showItem()}><FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  PDF</a> */}
                    <a className="dropdown-item " href="#" onClick={()=>this.convertExcelCard()}><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon> {i18n.t(" Excel")} </a></div>
                    </div>
              </div>
            <br />
          </Fragment>
        </Tab>
      </Tabs>
    );
  }
  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.branchList = helperList.branch;
    this.state.branchExcelList = helperList.branch;
    this.state.periodList = helperList.workCalendar;
    this.state.periodListExcel = helperList.workCalendar;
    this.state.listData = datalist;
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountReceiveable;


const useScript = url => {
    return new Promise((resolve, reject) => {
        var script = document.createElement( "script" )
        script.type = "text/javascript";
        if(script.readyState) {  // only required for IE <9
          script.onreadystatechange = function() {
            if ( script.readyState === "loaded" || script.readyState === "complete" ) {
              script.onreadystatechange = null;
              resolve();
            }
          };
        } else {  //Others
          script.onload = function() {
            resolve();
          };
        }
      
        script.src = url;
        document.getElementsByTagName( "body" )[0].appendChild( script );
      })
};

export default useScript;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
import ListVisit from '../../../../components/modules/sales/visit-schedule/list';
import VisitScheduleMiddelware from '../../../../middleware/modules/sales/visit-schedule';
// middlewares
 

const mapStateToProps = ({ auth,branch, visitSchedule}) => ({
  authUser:auth.authUser,
  isProcessing: visitSchedule.isProcessing,
  isError: visitSchedule.isError,
  errorMessage: visitSchedule.errorMessage,
  data:visitSchedule?.data||[],
  showModal:visitSchedule.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>VisitScheduleMiddelware.create(),
    reset: () =>VisitScheduleMiddelware.resetList(),
    change: () =>VisitScheduleMiddelware.change(),
    search: data =>VisitScheduleMiddelware.list(data),
    update: data =>VisitScheduleMiddelware.update(data),
    delete: (id,search) =>VisitScheduleMiddelware.beforeDelete(id,search),
    getHelper: (id, search) =>VisitScheduleMiddelware.getHelper(id, search),
  }, dispatch);
};
class ListVisitContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Visit Schedule List")}
          </title>
        </Helmet>
        <ListVisit 
          {...this.props}
        />
        
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListVisitContainer);

import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import Coa3Actions from "../../../../reducers/modules/accounting/coa3/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import Coa1Middleware from "../coa1";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "accounting/coa3";

export default class Coa3Middleware {
  static create() {
    return (dispatch) => {
      dispatch(Coa3Actions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(Coa3Actions.update());
      Coa3Middleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(Coa3Actions.process());
      Coa3Middleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(Coa3Actions.reset());
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(Coa3Actions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(Coa3Actions.view());
      Coa3Middleware.showOne(dispatch, params);
    };
  }

  static getHelper(params) {
    return (dispatch) => {
      dispatch(Coa3Actions.view());
      Coa3Middleware.showHelper(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(Coa3Actions.submit());
      Coa3Middleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(Coa3Actions.submit());
      Coa3Middleware.saveUpdate(dispatch, params, post, search);
    };
  }
  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(Coa3Actions.delete());
      Coa3Middleware.delete(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      Coa3Middleware.showHelper(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(Coa3Actions.list(response.data.result));
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error);
      });
  }

  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-coa3",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(Coa3Actions.getHelper(response.data.result));
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error);
      });
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(Coa3Actions.view(response.data.result));
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            Coa3Actions.process(),
            Coa3Middleware.showlist(dispatch, params)
          );
          Coa3Middleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            Coa3Actions.process(),
            Coa3Middleware.showlist(dispatch, search)
          );
          dispatch(Coa3Actions.success());
          Coa3Middleware.alertHendler(
            dispatch,
            response,
            true,
            "Create Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Coa3Middleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            Coa3Actions.process(),
            Coa3Middleware.showlist(dispatch, search)
          );
          dispatch(Coa3Actions.success());
          Coa3Middleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        Coa3Middleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(Coa3Actions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(Coa3Actions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { MutationDeposit } from '../../../../components/modules/mutation/mutation-deposit';
import MutationDepositMiddleware from '../../../../middleware/modules/mutation/mutation-deposit';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
// middlewares
const mapStateToProps = ({ mutationDeposit, }) => ({
  isProcessing: mutationDeposit.isProcessing,
  isError: mutationDeposit.isError,
  errorMessage: mutationDeposit.errorMessage,
  helperList:mutationDeposit.helperMutationDeposit,
  onChoose: mutationDeposit.onChoose,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => MutationDepositMiddleware.getHelper(),
    },
    dispatch
  );
};
class MutationDepositContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("MutationDeposit")}
          </title>
        </Helmet>
      <MutationDeposit
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MutationDepositContainer);

import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
const token = cookieManager.getUserToken();

export class FormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
  }
}

  

  render() {
    const {dataUpdate}=this.props
    return (
      <form>
       {(dataUpdate!=null)? <React.Fragment>
        <div className="row">
            <div className="col-12">
            <table className="table table-striped table-bordered " >
                <thead>
                </thead>
                <tbody style={{border: '1px solid', borderColor:'#F1F1F4'}}>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Purchase Date")}</td>
                  <td>{(dataUpdate.purchase_date)?dataUpdate.purchase_date:'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Type")}</td>
                  <td>{(dataUpdate.type_name)?dataUpdate.type_name:'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Asset Name")}</td>
                  <td>{(dataUpdate.name)?dataUpdate.name:'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Branch Name")}</td>
                  <td>{(dataUpdate.branch_name)?dataUpdate.branch_name:'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Purchase Price")}</td>
                  <td>{(dataUpdate.purchase_price)?dataUpdate.purchase_price :'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Depreciation Price")}</td>
                  <td>{(dataUpdate.susut)?dataUpdate.susut :'-'}</td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                  <td style={{paddingLeft:'10px'}}>{i18n.t("Depreciation")}</td>
                  <td>{(dataUpdate.depreciation)?dataUpdate.depreciation :'-'}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-end">
              <div className="col-4">
              </div>
            </div>
          </div>
       </React.Fragment> :''}
      </form>
    );
  }
}

export default FormView;

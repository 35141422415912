import React, { Component, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import LabelActive from "../../../utilities/Status/active";
import i18n from "../../../../i18n";
import { StatusActive } from "../../../utilities/Const/StatusActive";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
export class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          className: " text-gray-600",
        },
        { link: null, title: i18n.t("Admin"), className: " text-gray-600" },
        { link: null, title: i18n.t("Assignment"), className: " text-gray-500" },
      ],
      column: [
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name User"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: StatusActive,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component: (data, column) => {
            return <LabelActive value={data[column.key]} />;
          },
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
                    <div className="menu-item px-3">
                      <Dropdown.Item
                        as={Link}
                        to={"/user/admin/assignment/" + data.id}
                        className="menu-link px-3"
                      >
                        {i18n.t("Set Assignment")}
                      </Dropdown.Item>
                    </div>
                    <div className="menu-item px-3">
                      <Dropdown.Item
                        href="#"
                        className="menu-link px-3"
                        onClick={() => this.updateUser(data)}
                      >
                        {i18n.t("Update User")}
                      </Dropdown.Item>
                    </div>
                    <div className="menu-item px-3">
                      <Dropdown.Item
                        href="#"
                        className="menu-link px-3"
                        onClick={() => this.deleteUser(data)}
                      >
                        {i18n.t("Delete User")}
                      </Dropdown.Item>
                    </div>
                    {data.block_at != null ? (
                      <div className="menu-item px-3">
                        <Dropdown.Item
                          href="#"
                          className="menu-link px-3"
                          onClick={() => this.ActiveUser(data)}
                        >
                          {i18n.t("Active User")}
                        </Dropdown.Item>
                      </div>
                    ) : (
                      <div className="menu-item px-3">
                        <Dropdown.Item
                          href="#"
                          className="menu-link px-3"
                          onClick={() => this.BlockUser(data)}
                        >
                          {i18n.t("Block User")}
                        </Dropdown.Item>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }
  componentWillUnmount(){
    this.props.reset()
  }
  componentDidMount(){
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Assignment");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="adminList" className="d-flex flex-column flex-column-fluid">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="d-flex flex-column flex-xl-row">
              <div className="w-100 flex-lg-row-auto w-lg-300px mb-7 me-7 me-lg-10">
                <div className="card card-flush py-4">
                  <div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                      <div className="menu-item mb-3">
                        <Link className="menu-link active" to={"/user/admin"}>
                          <span className="menu-title fw-bold">Assignment</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin/route"}>
                          <span className="menu-title fw-bold">Route</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link "
                          to={"/user/admin/permission"}
                        >
                          <span className="menu-title fw-bold">Permission</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link"
                          to={"/user/admin/role"}
                        >
                          <span className="menu-title fw-bold">Roles</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                <div className="card card-flush py-4">
                  <div className="card-header d-flex justify-content-end border-0 pt-6">
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        <Button
                          type="button"
                          className="btn btn-warning m-1"
                          onClick={(e) => this.runSearch(this.state.search)}
                        >
                          <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <Table
                      data={this.state.listData}
                      tools={false}
                      filterSearch={true}
                      column={this.state.column}
                      searchData={this.state.search}
                      runSearch={this.runSearch}
                      deleteData={() => {}}
                      updateData={() => {}}
                      isProcessing={isProcessing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;

import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class DatePickerCustom extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    error: PropTypes.any,
    labelfield: PropTypes.string,
  };

  static defaultProps = {
    startDay: 0,
    required: false,
    error: "",
    labelfield: "true",
  };
  constructor(props) {
    super(props);
  }

  render() {
    const { name, required, title, error, labelfield } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : null}
        <DatePicker {...this.props} />
        {error != "" ? (
          <div className="fv-plugins-message-container invalid-feedback">
            {error}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DatePickerCustom;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import {
  FormCash,
} from "../../../../components/modules/accounting/cash-bank/formCash";
import CashBankMiddleware from "../../../../middleware/modules/accounting/cash-bank";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, cashbank, journal }) => ({
  authUser:auth.authUser,
  isAuthenticated: auth.isAuthenticated,
  isProcessing: cashbank.isProcessingIn,
  isErrorIn: cashbank.isErrorIn,
  errorMessage: cashbank.errorMessage,
  dataUpdate: cashbank.single,
  isSuccess: cashbank.isSuccessIn,
  isReset: cashbank.isReset,
  isNewRecord: cashbank.isNewRecordIn,
  onSubmit: cashbank.onSubmitIn,
  getHelperData: cashbank.helperJournal,
  afterSave: cashbank.afterSaveIn
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => CashBankMiddleware.reset(),
      new: (data, search) => CashBankMiddleware.beforeSaveNewCash(data, search),
      search: (data) => CashBankMiddleware.list(data),
      reset: () => CashBankMiddleware.resetList(),
      getHelper: () => CashBankMiddleware.getHelper(),
      update: (id, type, search) =>
        CashBankMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormCashContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessingIn: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isErrorIn: PropTypes.bool,
    isSuccessIn: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmitIn: PropTypes.bool,
    afterSaveIn:PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessingIn: false,
    isRegistered: false,
    isSuccessIn: false,
    isErrorIn: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmitIn: false,
    afterSaveIn:false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.formCash = React.createRef();
    this.getData = this.getData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
    this.reset = this.reset.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.dataID);
  }

  close() {
    this.props.closeModal();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    // this.props.reset();
    // this.props.getHelper();
    // this.props.search(search);
  }

  reset(){
    this.props.reset();

  }

  submitForm() {
    const { search, isNewRecordIn, dataUpdate } = this.props;
    var form = this.formCash.current.runSubmit();
    if (form != null) {
      this.props.new(form, search);
    }
    //  else {
    //   this.props.update(dataUpdate.id, form, search);
    // }
  }

  render() {
    const { onSubmit, showModal, titleModal } = this.props;
    return (
      <Fragment>
        <FormCash ref={this.formCash} {...this.props} />
          <div className="d-flex flex-end">
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
            {/* <Button
              className="flex-end"
              variant="primary"
              onClick={() => this.submitForm()}
            >
              <span className="indicator-label align-left">{i18n.t("Save")}</span>
            </Button> */}
        </div> 
      </Fragment>
  );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCashContainer);

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../utils/cookieManager";
import i18n from "../../i18n";

class ButtonDownload extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: "",
    url: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      onDownload: false,
    };
  }

  async downloadAction() {
    const { title, url } = this.props;
    if (title != "" && url != "") {
      const token = cookieManager.getUserToken();
      axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: process.env.REST_URL + "download",
        method: "GET",
        params: {
          url: url,
        },
        responseType: "blob",
      }).then((response) => {
        if (response && response.status === 200 && response.data) {
          var urlCreator = window.URL || window.webkitURL;
          const blobUrl = urlCreator.createObjectURL(response.data);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = blobUrl;
          link.setAttribute("download", title);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.setState({ onDownload: !this.state.onDownload });
        } else {
          this.setState({ onDownload: !this.state.onDownload });
        }
      });
    }
  }

  render() {
    return (
      <Button
        variant={!this.state.onDownload ? "success" : "primary"}
        onClick={async () => {
          if (!this.state.onDownload) {
            this.setState({ onDownload: !this.state.onDownload });
            await this.downloadAction();
          }
        }}
      >
        {this.state.onDownload ? (
          <span className="indicator-progress show">
            {i18n.t("Please wait...")}
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        ) : (
          <Fragment>
            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>{" "}
            {i18n.t("Download")}
          </Fragment>
        )}
      </Button>
    );
  }
}
export default ButtonDownload;

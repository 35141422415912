export default class InventoryActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_INVENTORY='GET_HELPER_INVENTORY';
  static SUCCESS_DATA='SUCCESS_DATA';
  static RESET_LIST='RESET_LIST';

  static submit(){
    return {
      type: InventoryActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: InventoryActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: InventoryActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: InventoryActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: InventoryActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: InventoryActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: InventoryActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: InventoryActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: InventoryActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: InventoryActions.RESET_DATA,
    };
  }
  static resetList(){
    return {
      type: InventoryActions.RESET_LIST,
    };
  }
  static helper(data){
    return {
      type: InventoryActions.GET_HELPER_INVENTORY,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: InventoryActions.ERROR_SERVER,
      payload: error
    };
  }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Link, matchPath, useLocation, useNavigation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import SalesTargetMiddleware from "../../../../middleware/modules/sales/sales-target";
import Form from "../../../../components/modules/sales/sales-target/form";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, salesTarget }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: salesTarget.isProcessing,
  isError: salesTarget.isError,
  errorMessage: salesTarget.errorMessage,
  dataUpdate: salesTarget.single,
  isSuccess: salesTarget.isSuccess,
  isReset: salesTarget.isReset,
  isNewRecord: salesTarget.isNewRecord,
  onSubmit: salesTarget.onSubmit,
  helperSalesTarget: salesTarget.helperSalesTarget,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => SalesTargetMiddleware.reset(),
      
      new: (data, search) => SalesTargetMiddleware.beforeSaveNew(data, search),
      update: (id, type, search) =>
        SalesTargetMiddleware.beforeSaveUpdate(id, type, search),
      create: () => SalesTargetMiddleware.create(),
    },
    dispatch
  );
};
class FormContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  close() {
    this.props.closeModal();
  }
  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    if (isNewRecord) {
      this.props.new(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
  }

  render() {
    const {
      helperSalesTarget,
      isNewRecord,
      onSubmit,
      showModal,
      titleModal,
      isReset,
      dataUpdate,
      isProcessing,
      isError,
      errorMessage,
    } = this.props;

    return (
      <>
        {/* <Modal show={showModal} size="xl" enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body> */}
            <Form
              isNewRecord={isNewRecord}
              isReset={isReset}
              data={dataUpdate}
              isProcessing={isProcessing}
              isError={isError}
              errorMessage={errorMessage}
              ref={this.form}
              helperSalesTarget={helperSalesTarget}
            />
          {/* </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal> */}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import GeneralLedger from '../../../../components/modules/report/general-ledger';
import GeneralLedgerMiddleware from '../../../../middleware/modules/report/general-ledger';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,generalLedger }) => ({
  isProcessing: generalLedger.isProcessing,
  isError: generalLedger.isError,
  errorMessage: generalLedger.errorMessage,
  datalist:generalLedger.data,
  showModal:generalLedger.showModal,
  helperList:generalLedger.helperGeneralLedger
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>GeneralLedgerMiddleware.getHelper(),
    reset: () =>GeneralLedgerMiddleware.resetList(),
    setData: (data) =>GeneralLedgerMiddleware.list(data),
    excel: (data) =>GeneralLedgerMiddleware.showExcel(data),
  }, dispatch);
};
class GeneralLedgerContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
    // this.props.search(search);
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("General Ledger Report")}
          </title>
        </Helmet>
      <GeneralLedger
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedgerContainer);

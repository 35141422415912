import React, { Component } from "react";
import PropTypes from "prop-types";

class Radio extends Component {
  static propTypes = {
    error: PropTypes.string,
    required: PropTypes.bool,
    inline: PropTypes.bool,
    list: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    labelfield: PropTypes.string,
  };

  static defaultProps = {
    error: "",
    required: false,
    inline: false,
    value: "",
    labelfield: "true",
  };
  constructor(props) {
    super(props);
  }
  render() {
    const {
      required,
      title,
      name,
      error,
      list,
      inline,
      handler,
      value,
      labelfield,
      defaultChecked
    } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            key={title}
            className={"fw-semibold fs-6 mb-5" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : (
          ""
        )}
        <div className="d-flex fv-row">
          {Object.entries(list).map(([k, v], i) => (
            <div
              key={title + i+k + "div"}
              className={
                "form-check form-check-solid mb-5" + inline
                  ? " form-check-inline"
                  : ""
              }
            >
                <input
                  key={title + i + "input"}
                  className="form-check-input me-3"
                  name={name}
                  type="radio"
                  checked={(value==null && defaultChecked==k)?true:((value==k)?true:false)}
                  value={k}
                  id="kt_docs_formvalidation_radio_option_1"
                  onChange={handler}
                  onClick={handler}
                />
              <label
                key={title + i + "label"}
                className="form-check-label"
                htmlFor="kt_docs_formvalidation_radio_option_1"
              >
                <div className="fw-semibold text-gray-800">{v || ""}</div>
              </label>
            </div>
          ))}
        </div>
        <div className="fv-plugins-message-container invalid-feedback">
          {error}
        </div>
      </React.Fragment>
    );
  }
}

export default Radio;

import React, { Component, Fragment } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import TableBilling from "./table";
import { statusMemoIn } from "../../../utilities/Const/StatusJournal";
import LabelVoid from "../../../utilities/Status/void";
import { transactionTypeDP } from "../../../utilities/Const/TranscationCode";
const MySwal = withReactContent(Swal);
export class BillingIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPayment: {
        limit: 10,
      },
      titleModal: "",
      modal: 1,
      listDataPayment: [],

      column: [
        {
          key: "date",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "payment_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Payment No."),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "branch_name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "sales_name",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Sales"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: transactionTypeDP,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-100px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelVoid value={data[column.key]} />
          }
        },
        // {
        //   key: "status",
        //   filter: {
        //     active: false,
        //     type: "Select",
        //     data: statusMemoIn,
        //   },
        //   title: i18n.t("Status"),
        //   type: "component",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        //   component: (data, column) => {
        //     return <LabelVoid value={data[column.key]} />;
        //   },
        // },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
                    {/* Edit Button */}
                    {data.status == 1 ? (
                      <>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.voidData(data.payment_no)}
                          >
                            {i18n.t("Void")}
                          </Dropdown.Item>
                        </div>
                      </>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.voidData = this.voidData.bind(this);

  }
  runSearch(value) {
    this.state.submit = false;
    this.props.searchPayment(value);
  }


  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }


  voidData(id) {
    const html = (
      <>
        <div>{i18n.t("Void This Payment")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.voidItem(id, this.state.searchPayment);
            MySwal.update({
              title: i18n.t("Process Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Confirm")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, dataPayment } = this.props;
    this.state.listDataPayment = dataPayment;
    return (
      <div id="customer-type">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >

          <div className="card">
            <div className="card-header d-flex justify-content-end border-0 pt-6">
              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-customer-table-toolbar="base"
                >
                  <Button
                    type="button"
                    className="btn btn-warning m-1"
                    onClick={(e) => this.runSearch(this.state.searchPayment)}
                  >
                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <TableBilling
                numberAble={true}
                expandAble={true}
                theExpand={{
                  key: "detail",
                  columns: [
                    { label: i18n.t("Customer Name"), key: "customer_name" },
                    { label: i18n.t("Invoice No."), key: "no_invoice" },
                    { label: i18n.t("Total Invoice"), key: "total" },
                    { label: i18n.t("Remaining Invoice"), key: "total_invoice" },
                    { label: i18n.t("Down Payment"), key: "down_payment" },
                    { label: i18n.t("Cash"), key: "total_cash" },
                    { label: i18n.t("Total Payment"), key: "total_payment" },
                  ],
                }}
                data={this.state.listDataPayment}
                tools={false}
                filterSearch={true}
                column={this.state.column}
                searchData={this.state.searchPayment}
                runSearch={this.runSearch}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BillingIndex;

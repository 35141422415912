import React, { Component } from "react";
import PropTypes from "prop-types";

class SwitchButton extends Component {
  static propTypes = {
    error: PropTypes.string,
    required: PropTypes.bool,
    labelfield: PropTypes.string,
    className: PropTypes.string,
    position:PropTypes.string,
  };

  static defaultProps = {
    error: "",
    required: false,
    labelfield: "true",
    className: "",
    position:'justify-content-center',
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { required, label, name, labelfield, error, className,position } = this.props;
    return (
      <React.Fragment>
        <div className={"form-check form-switch form-check-custom form-check-solid "+position}>
          <input
            {...this.props}
            type="checkbox"
            className={"form-check-input " + className}
            id={name}
          />
          {labelfield == "true" ? (
            <label
              className={"form-check-label" + (required ? " required" : "")}
              htmlFor={name}
            >
              {label}
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="fv-plugins-message-container invalid-feedback">
          {error}
        </div>
      </React.Fragment>
    );
  }
}

export default SwitchButton;

import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Input from "../../../utilities/Form/input";
// import { MultipleContainers } from "../../../utilities/dnd/Presets/Sortable/MultipleContainers";
const MySwal = withReactContent(Swal);
export class LogActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropped:false,
      items:[1,2],
      itemsRoot:[3,4],
      draggableMarkup:<Draggable>Drag me</Draggable>,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Log"), class: " text-gray-600" },
        { link: null, title: i18n.t("Activity"), class: " text-gray-500" },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.approveData = this.approveData.bind(this);
    this.rejectData = this.rejectData.bind(this);
    this.viewData = this.viewData.bind(this);
    this.handler = this.handler.bind(this)
  }

  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  viewData(id) {
    this.state.titleModal = i18n.t("Customer");
    this.props.viewItem(id);
  }

  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve this Request")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            this.props.ApproveData(id, this.state.search);
            MySwal.update({
              title: i18n.t("Approve Request"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This Request")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Request"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.RejectData(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  handleDragEnd(event){
    if (event.over && event.over.id === 'droppable') {
      this.setState({isDropped:true,items:[1,2]});
    }
  }
  handler(e) {
    var key=e.target.name;
    var value=e.target.value
    this.setState({
        ...this.state, 
        form: {
            ...this.state.form,
            [key]:value
        } 
    })
}
renderEmployee(){
  // const {}
}
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body">
              
                <div className="row">
                  <div className="col-6">
                    <div class="border rounded p-2" style={{height:'70vh'}}>
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            value={""}
                            handler={this.handler}
                            error={null}
                            placeholder={i18n.t("Search Employee")}
                            labelfield="false"
                            addOns={
                              <button type="button" className="bg-none input-group-text" onClick={(e)=>this.togglePassword()}><FontAwesomeIcon icon={faRefresh}/></button>
                            }
                        />
                        {/* <DndContext onDragEnd={(e)=>this.handleDragEnd(e)}>
                          <Container id="root" items={this.state.itemsRoot} />
                          <Container id="dropcuy" items={this.state.items} />
                        </DndContext> */}
                       {/* <MultipleContainers
                          cancelDrop={confirmDrop ? cancelDrop : undefined}
                          trashable
                        /> */}
                        <div className="pt-1">
                          {this.renderEmployee()}
                        </div>
                      </div>
                  </div>
                  <div className="col-6">
                    <div class="border rounded p-2" style={{height:'70vh'}}>
                        
                        
                    
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }
}

export default LogActivity;

const dropbox={
  backgroundColor:'#f8f8f8',
  minHeight:'100%'
}
import React, { Component } from "react";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import TextArea from "../../../utilities/Form/TextArea";
import { Button, Table } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Radio from "../../../utilities/Form/radio";
import i18n from "../../../../i18n";
import { StatusCB } from "../../../utilities/Const/StatusActive";


export class FormCash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Journal: [],
        DetailJournal: [],
      },
      branchValue: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      storeValue: null,
      date: new Date(),
      storeText: i18n.t("Select Store"),
      storeList: [],
      storeListLoading: false,
      cashAccountList: [],
      cashAccountValue: null,
      cashAccount: null,
      cashAccountText: i18n.t("Select Account"),
      type: "1",
      codeLoading: false,
      description: '',
      journalNo: '',
      transactionSource: 1,
      idTransaction: 0,
      status: 1,
      isSystem: 0,
      isReconcile: 0,
      total: 0,
      save: 0,
      typeData: "CASH",
      arrayDetail: [
        {
          coa3List: [],
          coa3Text: i18n.t("Select Ledger"),
          coa3Value: null,
          coaHelperList: [],
          coaHelpertext: i18n.t("Select Helper Book"),
          coaHelperValue: null,
          debt: 0,
          nominal: 0,
          credit: 0,
          description: '',
        },
      ],

      newCash: 0,
      newData: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let nominal = parseFloat(this.state.arrayDetail[i].nominal) || 0;
    objectss["total"] = nominal.toFixed(2);
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    let Total = 0;
    const detail = this.state.arrayDetail;
    for (let i = 0; i < detail.length; i++) {
      let nominal = parseFloat(detail[i].nominal) || 0;
      Total += nominal;
    }
    this.setState({
      total: Total.toFixed(2),
    });
  }

  runSubmit() {
    const detail = this.state.arrayDetail;
    let coa3 = [];
    let coaHelper = [];
    let debt = [];
    let credit = [];
    let description = [];
    let nominal = [];

    if (this.state.type == "1") {
      const Journal = {
        journalNo: this.state.journalNo,
        date: moment(this.state.date).format("YYYY-MM-DD"),
        idBranch:
          this.state.branchValue == null ? "" : this.state.branchValue.value,
        idStore:
          this.state.storeValue == null ? "" : this.state.storeValue.value,
        description: this.state.description,
        transactionSource: 1,
        idTransaction: 0,
        status: 1,
        isSystem: 0,
        isReconcile: 0,
        type: this.state.type,
        debt: this.state.total,
        credit: 0,
        typeData: "CASH",
        cashAccountValue: this.state.cashAccountValue
          ? this.state.cashAccountValue.value
          : "",
      };
      for (var i = 0; i < detail.length; i++) {
        coa3.push(detail[i].coa3Value == null ? "" : detail[i].coa3Value.value);
        coaHelper.push(
          detail[i].coaHelper == null ? "" : detail[i].coaHelperValue.value
        );
        nominal.push(detail[i].nominal == null ? "" : detail[i].nominal);
        description.push(detail[i].description);
      }
      this.state.form.Journal = Journal;
      this.state.form.DetailJournal = {
        coa3: coa3,
        coaHelper: coaHelper,
        debt: nominal,
        credit: 0,
        description: description,
      };
    } else if (this.state.type == "2") {
      const Journal = {
        journalNo: this.state.journalNo,
        date: moment(this.state.date).format("YYYY-MM-DD"),
        idBranch:
          this.state.branchValue == null ? "" : this.state.branchValue.value,
        idStore:
          this.state.storeValue == null ? "" : this.state.storeValue.value,
        description: this.state.description,
        transactionSource: 1,
        idTransaction: 0,
        status: 1,
        isSystem: 0,
        isReconcile: 0,
        type: this.state.type,
        debt: 0,
        typeData: "CASH",
        credit: this.state.total,
        cashAccountValue: this.state.cashAccountValue
          ? this.state.cashAccountValue.value
          : "",
      };
      for (var i = 0; i < detail.length; i++) {
        coa3.push(detail[i].coa3Value == null ? "" : detail[i].coa3Value.value);
        coaHelper.push(
          detail[i].coaHelper == null ? "" : detail[i].coaHelperValue.value
        );
        nominal.push(detail[i].nominal == null ? "" : detail[i].nominal);
        description.push(detail[i].description);
      }
      this.state.form.Journal = Journal;
      this.state.form.DetailJournal = {
        coa3: coa3,
        coaHelper: coaHelper,
        debt: 0,
        credit: nominal,
        description: description,
      };
    }

    return this.state.form;
  }

  renderError(name) {
    const { isErrorIn, errorMessage } = this.props;
    var value = "";
    if (isErrorIn) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  renderDetail(detail, i) {
    return (
      <React.Fragment key={"CashDetail" + i}>
        <tr >
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"coa3"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "coa3",
                  i,
                  "arrayDetail",
                  "coa3Value"
                )
              }
              options={this.state.coa3List}
              title={i18n.t("General Ledger Acc")}
              value={detail.coa3Value}
              placeholder={this.state.coa3Text}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.coa3." + i)}
            </div>
          </td>
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"coaHelper"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "coaHelper",
                  i,
                  "arrayDetail",
                  "coaHelperValue"
                )
              }
              options={this.state.coaHelperList}
              title={i18n.t("Subsidiary Ledger Acc")}
              value={detail.coaHelperValue}
              placeholder={this.state.coaHelpertext}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.coaHelper." + i)}
            </div>
          </td>
          <td width={"300px"}>
            <InputNumber
              id={"nominal" + i}
              name="nominal"
              placeholder="Nominal"
              defaultValue={0}
              value={detail.nominal}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "nominal", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.debt." + i)}
            </div>
          </td>
          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(keyFill, type = null, key, array = null, arraykey = null) {
    const token = cookieManager.getUserToken();

    let params = null;
    switch (type) {
      case "getCode":
        this.state.codeLoading = true;
        var url = process.env.REST_URL + "helper/get-journal-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          branch: branch,
          date: date,
        };
        break;
      case "getStore":
        this.state.storeListLoading = true;
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          id: branch,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
          switch (type) {
            case "getStore":
              this.state.storeListLoading = false;
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reCalculate() {
    this.state.arrayDetail.map(async (detail, i) => {
      this.calculateRow(i, "arrayDetail");
    });
  }
  reset() {
    let code = true;
    if (code && this.state.save == 0) {
      this.setState({
        branchValue: null,
        branchText: i18n.t("Select Branch"),
        branchList: [],
        storeValue: null,
        date: new Date(),
        storeText: i18n.t("Select Store"),
        cashAccountText: i18n.t("Select Account"),
        storeList: [],
        storeListLoading: false,
        cashAccountList: [],
        cashAccountValue: null,
        cashAccount: null,
        typeData: "CASH",
        type: "1",
        codeLoading: false,
        description: '',
        journalNo: '',
        transactionSource: 1,
        idTransaction: 0,
        status: 1,
        isSystem: 0,
        isReconcile: 0,
        save: 1,
        total: 0,
        arrayDetail: [
          {
            coa3List: [],
            coa3Text: i18n.t("Select Ledger"),
            coa3Value: null,
            coaHelperList: [],
            coaHelpertext: i18n.t("Select Helper Book"),
            coaHelperValue: null,
            debt: 0,
            nominal: 0,
            credit: 0,
            description: '',
          },
        ],

        newCash: 0,
      });
    }
  }
  // componentDidUpdate(){
  //   const {afterSave}=this.props;
  //   if(afterSave){
  //     this.reset();
  //   }

  // }
  render() {
    const {
      getHelperData,
      afterSave,
    } = this.props;

    this.state.branchList = getHelperData != null ? getHelperData.branch : [];
    this.state.coa3List = getHelperData != null ? getHelperData.coa3Filter : [];
    this.state.coaHelperList = getHelperData != null ? getHelperData.coa4 : [];
    this.state.cashAccountList = getHelperData != null ? getHelperData.cash : [];

    if (this.state.newCash == 0 && getHelperData != null) {
      this.state.journalNo = getHelperData != null ? getHelperData.code : '';
      this.state.newCash = 1;
    }

    if (this.state.save == 1 && afterSave == false) {
      this.state.save = 0;
    }

    if (afterSave && this.state.save == 0) {
      this.reset();
      this.state = {
        form: {
          Journal: [],
          DetailJournal: [],
        },
        branchValue: null,
        branchText: i18n.t("Select Branch"),
        storeValue: null,
        date: new Date(),
        storeText: i18n.t("Select Store"),
        storeList: [],
        storeListLoading: false,
        cashAccountValue: null,
        cashAccount: null,
        type: "1",
        codeLoading: false,
        description: '',
        journalNo: '',
        transactionSource: 1,
        idTransaction: 0,
        status: 1,
        isSystem: 0,
        isReconcile: 0,
        total: 0,
        typeData: "CASH",
        arrayDetail: [
          {
            coa3Text: i18n.t("Select Ledger"),
            coa3Value: null,
            coaHelpertext: i18n.t("Select Helper Book"),
            coaHelperValue: null,
            debt: 0,
            nominal: 0,
            credit: 0,
            description: "",
          },
        ],

        newCash: 0,
        newData: 0,
      };

      this.state.save = 1;
    }

    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-mb-3">
              <Radio
                list={StatusCB}
                name={"type"}
                title={""}
                inline={false}
                value={this.state.type}
                handler={this.handlerRadio}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-2">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={false}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("journalNo", "getCode", "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                endDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                peekNextMonth
                showMonthDropdown
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-2">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.setState({ storeValue: null });
                    await this.setState({ journalNo: "" });
                    await this.setState({ storeList: [] });
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.changeData("journalNo", "getCode", "code");
                    await this.changeData("storeList", "getStore", "");
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchValue}
                placeholder={this.state.branchText}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.idBranch")}
              </div>
            </div>
            <div className="col-md-2">
              <Select2
                isClearable={true}
                isLoading={this.state.storeListLoading}
                name={"idStore"}
                onChange={(e) => this.handlerSelect(e, "idStore", "storeValue")}
                options={this.state.storeList}
                title={"Store"}
                value={this.state.storeValue}
                placeholder={this.state.storeText}

              />
            </div>
            <div className="col-md-2">
              <Input
                type={"input"}
                name={"journalNo"}
                title={i18n.t("No. Document")}
                value={this.state.journalNo}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.journalNo")}
              </div>
            </div>

            <div className="col-md-2">
              <TextArea
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.description")}
              </div>
            </div>
            <div className="col-md-2">
              <Select2
                isClearable={true}
                name={"cashAccount"}
                required={true}
                onChange={(e) =>
                  this.handlerSelect(e, "cashAccount", "cashAccountValue")
                }
                options={this.state.cashAccountList}
                title={i18n.t("Cash Account")}
                value={this.state.cashAccountValue}
                placeholder={this.state.cashAccountText}

              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.cashAccountValue")}
              </div>
            </div>
            <br />
            <hr />
            <div className="row">
              <div className="col-12">
                <Table striped bordered hover>
                  <thead>
                    <tr className="color-head">
                      <th className="text-center">{i18n.t("Ledger (Coa Level 3)")}</th>
                      <th className="text-center">{i18n.t("Helper Book (Coa Level 4)")}</th>
                      <th className="text-center">{i18n.t("Nominal")}</th>
                      <th className="text-center">{i18n.t("Description")}</th>
                      {!this.state.fromRequest ? (
                        <th className="text-right"></th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrayDetail.map((detail, i) => {
                      return this.renderDetail(detail, i);
                    })}
                  </tbody>
                </Table>
                {!this.state.fromRequest ? (
                  <Button
                    variant="success"
                    onClick={(e) =>
                      this.addArray(
                        {
                          coa3Value: null,
                          coaHelperValue: null,
                          debt: 0,
                          credit: 0,
                          description: null,
                        },
                        "arrayDetail"
                      )
                    }
                  >
                    {i18n.t("Add Item")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="d-flex flex-end">
                <div className="col-4">
                  <Table borderless responsive="sm">
                    <tbody>
                      <tr className="align-middle">
                        <td>{i18n.t("Total")}</td>
                        <td>:</td>
                        <td>
                          <InputNumber
                            id={"total"}
                            name="total"
                            placeholder="Total"
                            defaultValue={0}
                            value={this.state.total}
                            decimalsLimit={2}
                            onValueChange={(value, name) => { }}
                            className="p-0 form-control form-control-xs form-control-flush text-end"
                            prefix="Rp "
                            readOnly
                            labelfield={"false"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/*  */}
      </React.Fragment>
    );
  }
}

export default FormCash;

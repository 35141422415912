import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import MutationDepositMiddleware from '../../../../middleware/modules/mutation/mutation-deposit';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import DataCustomer from "../../../../components/modules/mutation/mutation-deposit/index-customer";
// middlewares
const mapStateToProps = ({ mutationDeposit, }) => ({
  isProcessing: mutationDeposit.isProcessingCustomer,
  isError: mutationDeposit.isErrorCustomer,
  errorMessage: mutationDeposit.errorMessageCustomer,
  helperList: mutationDeposit.helperMutationDeposit,
  onChoose: mutationDeposit.onChooseCustomer,
  data: mutationDeposit.dataCustomer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => MutationDepositMiddleware.getHelper(),
      getDataCustomer: (data) => MutationDepositMiddleware.getDataCustomer(data),
    },
    dispatch
  );
};
class DepositCustomer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.getDataCustomer = this.getDataCustomer.bind(this);
  }

  getDataCustomer() {
    this.props.getDataCustomer();
  }

  componentWillMount() {
    // this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Mutation Deposit Data")}
          </title>
        </Helmet>
        <DataCustomer
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositCustomer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Configuration } from "../../../../components/modules/accounting/configuration";
import ConfigurationMiddleware from "../../../../middleware/modules/accounting/configuration";
import Coa3Middleware from "../../../../middleware/modules/accounting/coa3";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, configuration }) => ({
  isProcessing: configuration.isProcessing,
  isError: configuration.isError,
  errorMessage: configuration.errorMessage,
  datalist: configuration.data,
  showModal: configuration.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getConfigHelper: () => ConfigurationMiddleware.getConfigHelper(),
      create: () => ConfigurationMiddleware.create(),
      reset: () => ConfigurationMiddleware.resetList(),
      search: (data) => ConfigurationMiddleware.list(data),
      update: (data) => ConfigurationMiddleware.update(data),
      delete: (id, search) => ConfigurationMiddleware.beforeDelete(id, search),
    },
    dispatch
  );
};
class ConfigurationContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getConfigHelper();
    this.props.update(id);
  }
  createItem() {
    this.props.getHelper();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Configuration")}</title>
        </Helmet>
        <Configuration
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationContainer);

import {  Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

// Font.register({ family: 'Inter', src: source });
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo:{
        width:80,
        height:80,
    },
    body: {
        padding: "20px",
    },
    row: {
        flex:1,
        flexDirection: 'row',
    },
    col:{
        flex:1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr:{
        flexDirection: 'row', 
    },
    td:{
        flexDirection: 'column',
    },

    //Header
    titleContainer:{
        flexDirection: 'row',
    },
    header:{
        fontSize:"18px",
        fontFamily: 'Times-Roman'
    },
    address:{
        fontSize:"12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx:{
        fontSize:"17px",
        fontWeight:700,
        fontFamily: 'Times-Roman',
        textAlign:'right'
    },
    tableTitle:{
        border:'1px solid black',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
    },
    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
       
    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    tableTotal:{
        fontSize:"12px",
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },
    //end Table

    //Footer
    footer:{
        position:'absolute',
        bottom:0
    }
    //end Footer
  });
const tableRowsCount = 11;
class PrintPR extends Component{
    constructor(props){
        super(props)
        this.state={
            title:'Purchase Request No : ',
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:{
                poNo:'',
                po_detail:[]
            }
        };
    }
    renderMessage(){
        const {isProcessing, requestPrint}=this.props
        switch(true){
            case isProcessing:
                return (
                <div style={loader}>
                    <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
                    <div style={{fontSize:36}}>Getting Data From Server</div>
                </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{fontSize:36}}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={()=>{this.props.getPrint(this.props.poid)}}>Reload Page</Button></div>
                    </div>)
        }
    }

    render(){
        let FillText="";
        const {dataPrint}=this.props
        let total=0
        let address=[]
        if(dataPrint!=null){
            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
           
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title+ ((dataPrint !=undefined)?this.state.data.pr_no:'')}>
                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map(a=>
                                                <Text style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={[styles.col,{width:'600px',paddingTop:'20px'}]} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Purchase Request")} (PR)</Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                            <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Request No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.pr_no}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Request Date")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.date}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Delivery Date")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.delivery_date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Branch")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}}>
                                                        <Text>{this.state.data.name_branch}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Supplier")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}}>
                                                        <Text>{(this.state.data.name_supplier)?this.state.data.name_supplier:'-'}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        </View>
                                </View>
                                  <Fragment>
                                <View style={styles.tableContainer}>
                                    
                                    <View style={styles.containerItem}>
                                        <Text style={{width:'5%'}}>{i18n.t("No")}</Text>
                                        <Text style={{width:'20%'}}>{i18n.t("Item")}</Text>
                                        <Text style={{width:'10%'}}>{i18n.t("Qty")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("Unit")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("Qty Receive")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("Qty Mutation")}</Text>
                                        <Text style={{width:'20%'}}>{i18n.t("Mutation")}</Text>
                                        
                                    </View>
                                  
                                    {this.state.data.req_detail.map( (item,i) => 
                                        <View style={styles.rowItem} key={i+'itemPo'}>
                                            <Text style={{width:'5%',padding:1}}>{i+1}</Text>
                                            <Text style={{width:'20%',padding:1}}>{item.name_item}</Text>
                                            <Text style={{width:'10%',padding:1}}>{item.qty_format}</Text>
                                            <Text style={{width:'15%',padding:1}}>{item.name_unit}</Text>
                                            <Text style={{width:'15%',padding:1}}>{item.qtyReceiving_format}</Text>
                                            <Text style={{width:'15%',padding:1}}>{item.qtyMutation_format}</Text>
                                            <Text style={{width:'20%',padding:1}}>{item.branch_name}</Text>
                                        </View>
                                    )}
                                     </View>
                                    </Fragment>
                                    <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'500px',paddingTop:'20px'}} >
                                        <View style={[styles.tableTotal,{textAlign:'left'}]}>
                                                <Text style={{fontFamily:'Times-Roman',fontWeight:'900'}}>{i18n.t("Note")} :</Text>
                                        </View>
                                        <View style={styles.tableTotal} >
                                                <Text>{this.state.data.description}</Text>
                                        </View>
                                        </View>
                                        <View style={styles.col,{width:'300px',paddingTop:'20px',position:'right'}} >
                                        </View>
                                    </View>
                                </View>
                                    <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                    <View style={styles.col,{width:'500px',paddingTop:'40px',paddingLeft:'100px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>{i18n.t("Disetujui Oleh")}</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    <View style={styles.col,{width:'200px',paddingTop:'40px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>{i18n.t("Dibuat Oleh")}</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    </View>
                                 </View>
                                 <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                    <View style={styles.col,{width:'500px',paddingTop:'50px',paddingLeft:'110px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>({this.state.data.created_by})</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    <View style={styles.col,{width:'180px',paddingTop:'50px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'320px'}} >
                                                <Text>({this.state.data.created_by})</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    </View>
                                 </View>
                            
                               </View>     

                        </Page>
                    </Document>
            </PDFViewer>:this.renderMessage()}
        </React.Fragment>   
        )
    }
}

export default PrintPR;

const loader={
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection:'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
import {  Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        marginTop: '10px'
    },
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        // paddingTop: "10px",
        // border:'1px',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
    rowHeader: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        borderBottom: '1px',
        flexDirection: 'row',
    },
    trHeader: {
        backgroundColor: 'rgba(255, 255, 128)',
        // borderBottom: '1px',
        flexDirection: 'row',
    },
    td: {
        // borderRight: '1px',
        borderLeft: '1px',
        flexDirection: 'column',
    },
    tdcol: {
        colspan: '2',
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    titleHeader: {
        flexDirection: 'row',
        marginBottom: '10px'
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },

    titleTranx: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'left',
        marginBottom: '5px'
    },
    title: {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        marginBottom: '5px'
    },

    div: {
        width: '100%',
        height: 400,
        border: '1 solid black'
        // fontSize: "15px",
        // fontWeight: 700,
        // fontFamily: 'Times-Bold',
        // textAlign: 'center',
        // marginBottom:'5px'
    },

    tableTitle: {
        borderTop: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        // padding: '5px',
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        border: '2px'
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },
    //Dalam Table
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "12px",
    },

    totalBottom: {
        textAlign: 'right',
        fontFamily: 'Times-Bold',
        paddingRight: '1px',
        paddingTop: '2px',
        fontSize: "8px",
    },


    fillText: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "10px",
        paddingTop: '1px'

    },

    fillTextType: {
        textAlign: 'center',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fill: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    

    fillNumber: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
    },

    fillNumberBold: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
        fontFamily: 'Times-Bold',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        top: 100,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintCashFLow extends Component{
    constructor(props){
        super(props)
        this.state={
            title:i18n.t("Laporan Arus kas"),
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:{
                poNo:'',
                po_detail:[]
            },
            in:[],
            out:[],
        };
    }
    renderMessage(){
        const {isProcessing, requestPrint}=this.props
        switch(true){
            case isProcessing:
                return (
                <div style={loader}>
                    <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
                    <div style={{fontSize:36}}>Getting Data From Server</div>
                </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{fontSize:36}}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={()=>{this.props.getPrint(this.props.id)}}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderMenu(){
        html.push(
           
        );
        return html;
    }
    render(){
        console.log('PROPS',this.props)
        const {dataPrint}=this.props
        let total=0
        let totalInB = 0;
        let totalInK = 0;
        let totalInAll = 0;
        let totalOutB = 0;
        let totalOutK = 0;
        let totalOutAll = 0;
        let totalSaldoAwal=0
        let saldoAwalB = 0;
        let saldoAwalK = 0;
        let saldoAkhirB = 0;
        let saldoAkhirK = 0;
        let totalSaldoAkhir = 0;
        let address=[]
        if(dataPrint!=null){
            if(dataPrint.saldo_awal != null){
                let sa = dataPrint.saldo_awal
                for(var i=0;i<sa.length;i++){
                    if(sa[i].position == 'Bank'){
                        saldoAwalB = parseFloat(sa[i].nominal)||0;
                    }else if(sa[i].position == 'Kas'){
                        saldoAwalK = parseFloat(sa[i].nominal)||0;
                    }
                    totalSaldoAwal = totalSaldoAwal + parseFloat(sa[i].nominal)||0;
                }
            }

            if(dataPrint.in != null){
                this.state.in = dataPrint.in
               
                for(var p = 0;p<dataPrint.in.length;p++){
                    totalInB = totalInB + parseFloat(dataPrint.in[p].nominal_bank)||0;
                    totalInK = totalInK + parseFloat(dataPrint.in[p].nominal_kas)||0;
                    totalInAll = totalInAll + parseFloat(dataPrint.in[p].nominal_total)||0;
                }
            }

            if(dataPrint.out != null){
                this.state.out = dataPrint.out
               
                for(var p = 0;p<dataPrint.out.length;p++){
                    totalOutB = totalOutB + parseFloat(dataPrint.out[p].nominal_bank)||0;
                    totalOutK = totalOutK + parseFloat(dataPrint.out[p].nominal_kas)||0;
                    totalOutAll = totalOutAll + parseFloat(dataPrint.out[p].nominal_total)||0;
                }
            }

            totalSaldoAkhir = totalSaldoAwal + totalInAll - totalOutAll;
            saldoAkhirB = saldoAwalB + totalInB - totalOutB;
            saldoAkhirK = saldoAwalK + totalInK - totalOutK;

            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
            // total = this.state.data.journal_detail.map(item => parseFloat(item.debt) + parseFloat(item.credit)).reduce((accumulator, currentValue) => accumulator + currentValue , 0)
        
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title}>
                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map((a,i)=>
                                                <Text key={'add'+i} style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col} >
                                            <View style={styles.title}>
                                                <Text>{i18n.t("Laporan Arus Kas")}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.month + ' ' + dataPrint.data.year}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.branch}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer,{marginTop:'10px'}}>
                                    <View fixed style={styles.tableTitle}  >
                                        <View style={styles.trHeader}>
                                            <Fragment key={'menuHeader'}>
                                                <View style={[styles.td,{width:'40%'}]}>
                                                    <Text style={styles.menuHeader}>{"Keterangan"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.menuHeader}>{"Bank"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.menuHeader}>{"Kas"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                    <Text style={styles.menuHeader}>{"Total"}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View  style={styles.tableTitle}  >
                                        <View style={styles.tr}>
                                            <Fragment key={'saldoAwal'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fillTextType}>{"SALDO AWAL"}</Text>
                                                </View>
                                                {dataPrint.saldo_awal.map((awal,i)=>
                                                 <Fragment key={'dataType' + i}>
                                                    <View style={[styles.td,{width:'20%'}]}>
                                                        <Text style={styles.fillNumberBold}>{awal.nominal_format }</Text>
                                                    </View>
                                                </Fragment>
                                                )}
                                                <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalSaldoAwal < 0 ?
                                                        '(' + (totalSaldoAwal * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalSaldoAwal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                        <View style={styles.tr}>
                                            <Fragment key={'pemasukan'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fill}>{"Pemasukan"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'60%',textAlign:'center',borderRight:'1px'}]}>
                                                    <Text style={styles.fill}>{""}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                        {this.state.in.length > 0 ? 
                                            this.state.in.map((masuk,m)=>
                                                <Fragment key={'data-pemasukan'+m}>
                                                    <View style={styles.tr}>
                                                        <View style={[styles.td,{width:'40%'}]}>
                                                            <Text style={styles.fillText}>{masuk.name}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%'}]}>
                                                            <Text style={styles.fillNumber}>{masuk.nominal_bank_format}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%'}]}>
                                                            <Text style={styles.fillNumber}>{masuk.nominal_kas_format}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                            <Text style={styles.fillNumber}>{masuk.nominal_total_format}</Text>
                                                        </View>
                                                    </View>
                                                </Fragment>
                                            )
                                        :null}
                                        <View style={styles.tr}>
                                            <Fragment key={'Tpemasukan'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fill}>{"Total Pemasukan"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalInB < 0 ?
                                                        '(' + (totalInB * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalInB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalInK < 0 ?
                                                        '(' + (totalInK * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalInK.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalInAll < 0 ?
                                                        '(' + (totalInAll * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalInAll.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                        <View style={styles.tr}>
                                            <Fragment key={'pengeluaran'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fill}>{"Pengeluaran"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'60%',textAlign:'center',borderRight:'1px'}]}>
                                                    <Text style={styles.fill}>{""}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                        {this.state.out.length > 0 ? 
                                            this.state.out.map((keluar,k)=>
                                                <Fragment key={'data-pengeluaran'+k}>
                                                    <View style={styles.tr}>
                                                        <View style={[styles.td,{width:'40%'}]}>
                                                            <Text style={styles.fillText}>{keluar.name}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%'}]}>
                                                            <Text style={styles.fillNumber}>{keluar.nominal_bank_format}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%'}]}>
                                                            <Text style={styles.fillNumber}>{keluar.nominal_kas_format}</Text>
                                                        </View>
                                                        <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                            <Text style={styles.fillNumber}>{keluar.nominal_total_format}</Text>
                                                        </View>
                                                    </View>
                                                </Fragment>
                                            )
                                        :null}
                                        <View style={styles.tr}>
                                            <Fragment key={'Tpengeluaran'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fill}>{"Total Pengeluaran"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalOutB < 0 ?
                                                        '(' + (totalOutB * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalOutB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalOutK < 0 ?
                                                        '(' + (totalOutK * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalOutK.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalOutAll < 0 ?
                                                        '(' + (totalOutAll * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalOutAll.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                        <View style={styles.tr}>
                                            <Fragment key={'saldoAkhir'}>
                                                <View style={[styles.td,{width:'40%',textAlign:'center'}]}>
                                                    <Text style={styles.fillTextType}>{"SALDO AKHIR"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{saldoAkhirB < 0 ?
                                                        '(' + (saldoAkhirB * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        saldoAkhirB.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillNumberBold}>{saldoAkhirK < 0 ?
                                                        '(' + (saldoAkhirK * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        saldoAkhirK.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%',borderRight:'1px'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalSaldoAkhir < 0 ?
                                                        '(' + (totalSaldoAkhir * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalSaldoAkhir.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                        </Page>
                    </Document>
            </PDFViewer>
            :
            this.renderMessage()}
            
        </React.Fragment>   
        
        )
    }
}

export default PrintCashFLow;

const loader={
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection:'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
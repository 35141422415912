import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Unit from '../../../../components/modules/master/unit';
import UnitMiddleware from '../../../../middleware/modules/master/unit';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
// middlewares


const mapStateToProps = ({ auth,unit }) => ({
  isProcessing: unit.isProcessing,
  isError: unit.isError,
  errorMessage: unit.errorMessage,
  datalist:unit.data,
  showModal:unit.showModal,
  unitList:unit.helperUnit
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getUnit:()=>UnitMiddleware.getHelper(),
    create: () =>UnitMiddleware.create(),
    reset: () =>UnitMiddleware.resetList(),
    search: data =>UnitMiddleware.list(data),
    update: data =>UnitMiddleware.update(data),
    delete: (id,search) =>UnitMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class UnitContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.object,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {},
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
    this.props.getUnit()
  }
  createItem(){
    this.props.create()
    this.props.getUnit()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Unit List")}
          </title>
        </Helmet>
      <Unit
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitContainer);

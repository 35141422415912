export default class SubDistrictActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ERROR_DATA='ERROR_DATA';
    static GET_HELPER_SUBDISTRICT='GET_HELPER_SUBDISTRICT'
    
    static create() {
      return {
        type: SubDistrictActions.NEW_DATA
      };
    }
  
    static update(data) {
      return {
        type: SubDistrictActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: SubDistrictActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: SubDistrictActions.DELETE_DATA,
        payload: data
      };
    }
  
    static list(data) {
      return {
        type: SubDistrictActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: SubDistrictActions.ERROR_DATA,
        payload: data
      };
    }
    static helper(data){
      return {
        type: SubDistrictActions.GET_HELPER_SUBDISTRICT,
        payload:data
      };
    }

  }


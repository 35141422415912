export default class SetupWarehouseActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_SETUPWH='GET_HELPER_SETUPWH';
  static SUCCESS_DATA='SUCCESS_DATA';
  static RESET_LIST='RESET_LIST';

  static submit(){
    return {
      type: SetupWarehouseActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: SetupWarehouseActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: SetupWarehouseActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: SetupWarehouseActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: SetupWarehouseActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: SetupWarehouseActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: SetupWarehouseActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: SetupWarehouseActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: SetupWarehouseActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: SetupWarehouseActions.RESET_DATA,
    };
  }
  static resetList(){
    return {
      type: SetupWarehouseActions.RESET_LIST,
    };
  }
  static helper(data){
    return {
      type: SetupWarehouseActions.GET_HELPER_SETUPWH,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: SetupWarehouseActions.ERROR_SERVER,
      payload: error
    };
  }
}
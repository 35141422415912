import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import MutationDepositActions from "../../../../reducers/modules/mutation/mutation-deposit/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "mutation-deposit";

export default class MutationDepositMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(MutationDepositActions.create());
    };
  }

  static getData(params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.beforeChoose());
      dispatch(MutationDepositActions.processChoose());
      MutationDepositMiddleware.showData(dispatch, params);
    };
  }

  static getDataCustomer(params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.beforeChooseCustomer());
      dispatch(MutationDepositActions.processChooseCustomer());
      MutationDepositMiddleware.showDataCustomer(dispatch, params);
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.update());
      MutationDepositMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.process());
      MutationDepositMiddleware.showlist(dispatch, params);
    };
  }

  static reset() {
    return (dispatch) => {
      dispatch(MutationDepositActions.reset());
    };
  }

  static resetList() {
    return (dispatch) => {
      dispatch(MutationDepositActions.resetList());
    };
  }

  static getOne(params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.view());
      MutationDepositMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(MutationDepositActions.submit());
      MutationDepositMiddleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(MutationDepositActions.submit());
      MutationDepositMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.delete());
      MutationDepositMiddleware.delete(dispatch, params, id);
    };
  }

  static beforeVoid(id, params) {
    return (dispatch) => {
      dispatch(MutationDepositActions.void());
      MutationDepositMiddleware.voidData(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      MutationDepositMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull() {
    return (dispatch) => {
      MutationDepositMiddleware.showHelperFull(dispatch);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MutationDepositActions.list(response.data.result));
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error);
      });
  }

  // static showHelperFull(dispatch) {
  //   axios({
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
  //     },
  //     url: process.env.REST_URL + "helper/get-helper-MutationDeposit-full",
  //     responseType: "json",
  //   })
  //     .then((response) => {
  //       if (response && response.status === 200 && response.data) {
  //         dispatch(MutationDepositActions.helperFull(response.data.result));
  //       }
  //     })
  //     .catch((error) => {
  //       MutationDepositMiddleware.alertHendler(dispatch, error);
  //     });
  // }

  static showData(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url + '/supplier',
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MutationDepositActions.afterChoose(response.data.result));
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler('supplier', dispatch, error);
      });
  }

  static showDataCustomer(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url + '/customer',
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MutationDepositActions.afterChooseCustomer(response.data.result));
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler('customer', dispatch, error);

      });
  }


  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-dp",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MutationDepositActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MutationDepositActions.process(),
            MutationDepositMiddleware.showlist(dispatch, params)
          );
          MutationDepositMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static voidData(dispatch, params, id,) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url + "/void/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MutationDepositActions.process(),
            MutationDepositMiddleware.showlist(dispatch, params)
          );
          MutationDepositMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MutationDepositActions.process(),
            MutationDepositMiddleware.showlist(dispatch, search)
          );
          dispatch(MutationDepositActions.success());
          MutationDepositMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Confirm Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${MutationDepositMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MutationDepositActions.process(),
            MutationDepositMiddleware.showlist(dispatch, search)
          );
          dispatch(MutationDepositActions.success());
          MutationDepositMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MutationDepositMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    choose = "",
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error",
  ) {

    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              console.log(data)
              MySwal.hideLoading();
              MySwal.update({
                title: "Error",
                html: i18n.t(data.message),
                icon: "error",
                showConfirmButton: true,
              })
              const search = { limit: 10 }
              dispatch(
                MutationDepositActions.process(),
                MutationDepositMiddleware.showlist(dispatch, search)
              );

            } else {
              const data = result.response.data;
              if (choose == 'customer') {
                dispatch(MutationDepositActions.errorCustomer(data.error));

              } else if (choose == 'supplier') {

                dispatch(MutationDepositActions.error(data.error));

              }
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(MutationDepositActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

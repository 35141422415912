import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Dropdown, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import cookieManager from "../../../../utils/cookieManager";

class TableCashBank extends Component {
  static propTypes = {
    data: PropTypes.any,
  };

  static defaultProps = {
    data: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      link: null,
      columns: [],
      search: {
        limit: 10,
      },
      fulldata: {
        branch: "",
      },
      page: null,
      currentPage: 1,
      totalData: 0,
      load: 0,
      isProcessing: false,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
    this.resetTable = this.resetTable.bind(this);
  }

  runSearch() {
    let data = this.state.search;
    let url = process.env.REST_URL + "CashBank/search";
    this.setState({ isProcessing: true });
    const token = cookieManager.getUserToken();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: url,
      responseType: "json",
      data: data,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          var detail = response.data.result;
          const arr = {
            link: detail.links,
            data: detail.data,
            currentPage: detail.current_page,
            totalData: detail.total,
            page: detail.current_page,
            isProcessing: false,
          };
          this.setState(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  tdNumber(dataRow, i) {
    const { data } = this.props;
    let page = 1;
    let limit = 10;
    if (data.current_page != undefined) {
      page = data.current_page;
    }
    if (this.state.search.limit != undefined) {
      if (this.state.search.limit != -1) {
        limit = this.state.search.limit;
      } else {
        limit = 1;
      }
    }
    let plus = 0;
    if (page > 1) {
      plus = limit * (page - 1);
    }
    return <td key={i.toString()}>{i + 1 + plus}</td>;
  }
  updateSearch(value, key) {
    if (value == "") {
      value = null;
    }
    this.state.search[key] = value;
  }

  _onBlur() {
    this.state.search["page"] = 1;
    this.runSearch();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.runSearch();
    }
  }
  changePage(event, url) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = urlParams.get("page");
      this.runSearch();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    this.runSearch();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    this.runSearch();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;

    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.setState({ [key]: e }, () => this.runSearch());
  }
  renderTd(column, data, i = 0) {
    var value = data[column.key];
    var type = column.type;
    switch (type) {
      case "text":
        return <td key={i.toString()}>{value}</td>;
      case "numberTd":
        return this.tdNumber(value, i);
      default:
        return <td key={i.toString()}>{value}</td>;
    }
  }
  renderFilter(column, filter) {
    if (filter.active) {
      switch (filter.type) {
        case "Text":
          return (
            <input
              onKeyPress={this._onEnter}
              onBlur={this._onBlur}
              onChange={(e) => {
                this.updateSearch(e.target.value, column.key);
              }}
              type="text"
              className="form-control form-control-solid form-control-sm"
              placeholder={column.title}
              name={column.key}
            />
          );
          break;
        case "Select":
          return (
            <select
              onChange={(e) => this.changeSelect(e, column.key)}
              className="form-select form-select-sm form-select-solid"
            >
              {filter.data.map((list, i) => (
                <option key={column.key + i} value={list.key}>
                  {list.label}
                </option>
              ))}
            </select>
          );
          break;
        case "Date":
          // this.state[column.key]='';
          return (
            <DatePickerCustom
              name={column.key}
              title={column.title}
              required={false}
              selected={this.state[column.key]}
              onChange={(e) => this.handlerDateTime(e, column.key)}
              startDay={0}
              isClearable={this.state[column.key] != null ? true : false}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm form-control-solid"
              labelfield={"false"}
            />
          );
          break;
      }
    } else {
      return "";
    }
  }
  renderNumber() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          #
        </div>
      </th>
    );
  }
  resetTable() {
    const { data, columns } = this.props;
    var detail = data.details;
    const arr = {
      link: detail.links,
      data: detail.data,
      fulldata: data,
      search: data.request,
      currentPage: detail.current_page,
      totalData: detail.total,
      page: detail.current_page,
      columns: columns,
    };
    this.setState(arr);
  }
  renderTitle() {
    return (
      <React.Fragment>
        <Table borderless>
          <thead></thead>
          <tbody>
            <tr>
              <td>Branch Name</td>
              <td> : </td>
              <td>
                {this.state.fulldata.branch != null &&
                this.state.fulldata.branch != undefined
                  ? this.state.fulldata.branch
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Store Name</td>
              <td> : </td>
              <td>
                {this.state.fulldata.store != null &&
                this.state.fulldata.store != undefined
                  ? this.state.fulldata.store
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Item Name</td>
              <td> : </td>
              <td>
                {this.state.fulldata.item != null &&
                this.state.fulldata.item != undefined
                  ? this.state.fulldata.item
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Item Type</td>
              <td> : </td>
              <td>
                {this.state.fulldata.itemType != null &&
                this.state.fulldata.itemType != undefined
                  ? this.state.fulldata.itemType
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Warehouse</td>
              <td> : </td>
              <td>
                {this.state.fulldata.warehouse != null &&
                this.state.fulldata.warehouse != undefined
                  ? this.state.fulldata.warehouse
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Sub Warehouse</td>
              <td> : </td>
              <td>
                {this.state.fulldata.subWarehouse != null &&
                this.state.fulldata.subWarehouse != undefined
                  ? this.state.fulldata.subWarehouse
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Period</td>
              <td> : </td>
              <td>
                {this.state.fulldata.period != null &&
                this.state.fulldata.period != undefined
                  ? this.state.fulldata.period
                  : ""}
              </td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
  render() {
    const { data, columns } = this.props;
    if (data != undefined && this.state.load == 0) {
      var detail = data.details;
      this.state.link = detail.links;
      this.state.data = detail.data;
      this.state.fulldata = data;
      this.state.search = data.request;
      this.state.currentPage = detail.current_page;
      this.state.totalData = detail.total;
      this.state.page = detail.current_page;
      this.state.columns = columns;
      this.state.search.limit = 10;
      this.state.load = 1;
    }
    return (
      <React.Fragment>
        <div className="card mt-4">
          <div className="card-header border-0 pt-3">
            <div className="d-flex align-items-center position-relative my-1">
              {this.renderTitle()}
            </div>
            <div className="card-toolbar align-items-end">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <Button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => this.resetTable()}
                >
                  <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                </Button>
                <Button type="button" className="btn btn-primary">
                  {i18n.t("Print")}
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className={this.state.isProcessing ? " table-loading" : ""}>
              {this.state.isProcessing ? (
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              ) : (
                ""
              )}

              <table className="table align-middle table-row-dashed fs-6 gy-5">
                <thead>
                  <tr
                    key={"head"}
                    className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
                  >
                    {this.renderNumber()}
                    {this.state.columns.map((column, i) => (
                      <th key={i.toString()} className={column.tHclass}>
                        {column.title}
                      </th>
                    ))}
                  </tr>
                  {
                    <tr key={"filter"}>
                      {<th></th>}
                      {this.state.columns.map((column, i) => (
                        <th key={i.toString()} className={column.tHclass}>
                          {this.renderFilter(column, column.filter)}
                        </th>
                      ))}
                    </tr>
                  }
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {this.state.data != undefined &&
                  this.state.data.length > 0 ? (
                    this.state.data.map((data, p) => (
                      <React.Fragment key={"empty" + p}>
                        <tr key={p}>
                          {this.renderTd({ type: "numberTd" }, data["id"], p)}
                          {this.state.columns.map((column, i) =>
                            this.renderTd(column, data, i)
                          )}
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr key={"notfound"}>
                      <td
                        className="text-center"
                        colSpan={
                          this.state.columns.length > 0
                            ? this.state.columns.length + 1
                            : 1
                        }
                      >
                       {i18n.t("No Data Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div className="dataTables_length">
                    <label>
                      <select
                        onChange={(e) => this.changeLimit(e)}
                        name="kt_customers_table_length"
                        aria-controls="kt_customers_table"
                        className="form-select form-select-sm form-select-solid"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="-1">{i18n.t("All")}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul className="pagination">
                      {this.state.link != null
                        ? this.state.link.map((data, i) => (
                            <li
                              className={
                                "paginate_button page-item" +
                                (i == 0 ? " previous" : "") +
                                (i == this.state.link.length - 1
                                  ? " next"
                                  : "") +
                                (data.url == null ? " disabled" : "") +
                                (data.active ? " active" : "")
                              }
                              key={i}
                            >
                              <button
                                key={i}
                                onClick={(e) => this.changePage(e, data.url)}
                                aria-controls="kt_customers_table"
                                data-dt-idx={i}
                                tabIndex="0"
                                className="page-link"
                              >
                                {i == 0 ? <i className="previous"></i> : ""}
                                {i != 0 && i != this.state.link.length - 1
                                  ? data["label"]
                                  : ""}
                                {i == this.state.link.length - 1 ? (
                                  <i className="next"></i>
                                ) : (
                                  ""
                                )}
                              </button>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TableCashBank;

import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from '../../../utilities/Form/Select2';
import Radio from '../../../utilities/Form/radio';
import { StatusForm,AllowanceType } from '../../../utilities/Const/StatusActive';
import InputNumber from '../../../utilities/Form/InputNumber';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                name:'',
                status:1,
                basic:0,
                type:'monthly',
                idAllowance:[],
                idCuts:[],
                idBranch:null,
            },
            branchList:[],
            branchCurrent:null,
            branchText:i18n.t('Select Branch'),
            cutsList:[],
            cutsCurrent:null,
            cutsText:i18n.t('Select Salary Cuts'),
            allowanceList:[],
            allowanceCurrent:null,
            allowanceText:i18n.t('Select Allowance'),
            isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerCurrency = this.handlerCurrency.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerCurrency(value, name) {
        var key = name;
        var value = value;
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            [key]: value,
          },
        });
      }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
        var allowance=[];
        if(this.state.allowanceCurrent !=null){
            var postAllowance=this.state.allowanceCurrent;
            for(var a=0;a <postAllowance.length;a++){
                allowance.push(postAllowance[a].value)
            }
        }

        var cuts=[];
        if(this.state.cutsCurrent !=null){
            var postCuts=this.state.cutsCurrent;
            for(var c=0;c <postCuts.length;c++){
                cuts.push(postCuts[c].value)
            }
        }
       
        this.state.form.idAllowance=allowance
        this.state.form.idCuts=cuts
        return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){ 
        const {dataUpdate,isNewRecord,helperList}=this.props
        this.state.allowanceList=(helperList.allowance !=undefined)?helperList.allowance:[] 
        this.state.cutsList=(helperList.cuts !=undefined)?helperList.cuts:[] 
        this.state.branchList=(helperList.branch !=undefined)?helperList.branch:[] 
       if(dataUpdate !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form.name=dataUpdate.name,
                this.state.form.status=dataUpdate.status
                this.state.form.basic=parseFloat(dataUpdate.basic)||0
                this.state.isUpdate=1
                this.state.form.type=dataUpdate.type
                var allowanceID=dataUpdate.allowanceId
                var currentAllowance=[];
                for(var a=0;a<this.state.allowanceList.length;a++){
                    var idsearcha=this.state.allowanceList[a].value
                    if(allowanceID.includes(idsearcha.toString())){
                        currentAllowance.push({value:this.state.allowanceList[a].value,label:this.state.allowanceList[a].label})
                    }
                }

                var cutsID=dataUpdate.cutsId
                var currentCuts=[];
                for(var c=0;c<this.state.cutsList.length;c++){
                    var idsearchc=this.state.cutsList[c].value
                    if(cutsID.includes(idsearchc.toString())){
                        currentCuts.push({value:this.state.cutsList[c].value,label:this.state.cutsList[c].label})
                    }
                }
                this.state.branchCurrent={value:dataUpdate.idBranch,label:dataUpdate.branch}
                this.state.form.idAllowance=dataUpdate.allowanceId
                this.state.allowanceCurrent=currentAllowance
                this.state.form.idCuts=dataUpdate.cutsId
                this.state.form.idBranch=dataUpdate.idBranch
                this.state.cutsCurrent=currentCuts
            }
        }
        return (
            <form>
                <div className='mb-4'>
                <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.form.name}
                    handler={this.handler}
                    error={this.renderError('name')}
                />
                </div>
                <div className='mb-4'>
                    <Select2
                        isClearable={true}
                        name={"idBranch"}
                        required={true}
                        onChange={(e) =>
                            this.handlerSelect(e, "idBranch", "branchCurrent")
                        }
                        options={this.state.branchList}
                        title={i18n.t("Branch")}
                        value={this.state.branchCurrent}
                        placeholder={this.state.branchText}
                        error={this.renderError("idBranch")}
                        />
                </div>
                <div className='mb-4'>
                    <InputNumber
                    id={"nominal"}
                    name={"basic"}
                    title={i18n.t("Basic")}
                    labelfield={"true"}
                    required={true}
                    value={this.state.form.basic}
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                        this.handlerCurrency(value, "basic")
                    }
                    prefix={"Rp "}
                    className="form-control  form-control-sm "
                    />
                </div>
                <div className='mb-4'>
                <Select2
                    isClearable={true}
                    name={"idAllowance"}
                    required={true}
                    onChange={(e) =>
                        this.handlerSelect(e, "idAllowance", "allowanceCurrent")
                    }
                    options={this.state.allowanceList}
                    title={i18n.t("Allowance")}
                    value={this.state.allowanceCurrent}
                    placeholder={this.state.allowanceText}
                    error={this.renderError("idAllowance")}
                    isMulti={true}
                    />
                </div>
                <div className='mb-4'>
                <Select2
                    isClearable={true}
                    name={"idCuts"}
                    required={true}
                    onChange={(e) =>
                        this.handlerSelect(e, "idCuts", "cutsCurrent")
                    }
                    options={this.state.cutsList}
                    title={i18n.t("Salary Cuts")}
                    value={this.state.cutsCurrent}
                    placeholder={this.state.cutsText}
                    error={this.renderError("idCuts")}
                    isMulti={true}
                    />
                </div>
                <div className='mb-4'>
                <Radio
                    list={AllowanceType}
                    name={"type"}
                    title={i18n.t("Type Salary")}
                    inline={false}
                    value={this.state.form.type}
                    handler={this.handler}
                    error={this.renderError("type")}
                />
                </div>
                
                <div className='mb-4'>
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.form.status}
                    handler={this.handler}
                />
                </div>
            </form>
        );
    }
}

export default Form;
export default class AuthActions {
  //Forgot Password
  static BEFORE_VERIFICATION_TOKEN='BEFORE_VERIFICATION_TOKEN'
  static VERIFICATION_TOKEN_SUCCESS='VERIFICATION_TOKEN_SUCCESS'
  static VERIFICATION_TOKEN_FAILED='VERIFICATION_TOKEN_FAILED'
  static ON_VERIFY='ON_VERIFY'
  static onVerifySet(data){
    return {
      type: AuthActions.ON_VERIFY,
      payload:data
    };
  }
  static beforeSetPassword(){
    return {
      type: AuthActions.BEFORE_VERIFICATION_TOKEN
    };
  }

  static verificationForgotSuccess(){
    return {
      type: AuthActions.VERIFICATION_TOKEN_SUCCESS
    };
  }
  static verificationForgotFailed(error){
    return {
      type: AuthActions.VERIFICATION_TOKEN_FAILED,
      payload:error
    };
  }

  //Request Forgot Password
  static BEFORE_REQUEST='BEFORE_REQUEST'
  static REQUEST_SUCCESS='REQUEST_SUCCESS'
  static REQUEST_FAILED='REQUEST_FAILED'

  static beforeRequest(){
    return {
      type: AuthActions.BEFORE_REQUEST
    };
  }

  static requestSuccess(){
    return {
      type: AuthActions.REQUEST_SUCCESS,
    };
  }
  static requestFailed(error){
    return {
      type: AuthActions.REQUEST_FAILED,
      payload:error
    };
  }

  static RESET = 'RESET';
  static reset() {
    return {
      type: AuthActions.RESET
    };
  }

  //Forgot Password
  static BEFORE_SET_PASSWORD='BEFORE_SET_PASSWORD'
  static SUCCESS_SET_PASSWORD='SUCCESS_SET_PASSWORD'
  static FAILED_SET_PASSWORD='FAILED_SET_PASSWORD'

  static beforeSetPassword(){
    return {
      type: AuthActions.BEFORE_SET_PASSWORD
    };
  }

  static successNew(){
    return {
      type: AuthActions.SUCCESS_SET_PASSWORD
    };
  }
  static failedNew(error){
    return {
      type: AuthActions.FAILED_SET_PASSWORD,
      payload:error
    };
  }


  static SIGNIN = 'SIGNIN';
  static SIGNIN_SUCCESSFUL = 'SIGNIN_SUCCESSFUL';
  static SIGNIN_REJECTED = 'SIGNIN_REJECTED';

  static LOGOUT = 'LOGOUT';
  static LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';

  static ISLOGGEDIN = 'ISLOGGEDIN';
  static ISLOGGEDOUT = 'ISLOGGEDOUT';
  static ERROR_SERVER='ERROR_SERVER'


  static signin() {
    return {
      type: AuthActions.SIGNIN
    };
  }

  static signinSuccessful(authUser) {
    return {
      type: AuthActions.SIGNIN_SUCCESSFUL,
      payload: authUser
    };
  }

  static signinRejected(error) {
    return {
      type: AuthActions.SIGNIN_REJECTED,
      payload: error
    };
  }

  static logout() {
    return {
      type: AuthActions.LOGOUT
    };
  }

  static logoutSuccessful() {
    return {
      type: AuthActions.LOGOUT_SUCCESSFUL
    };
  }

  static isLoggedInSuccess(user) {
    return {
      type: AuthActions.ISLOGGEDIN,
      payload: user
    };
  }

  static isLoggedInFailure() {
    return {
      type: AuthActions.ISLOGGEDOUT
    };
  }
  static errorInternal(error){
    return {
      type: AuthActions.ERROR_SERVER,
      payload: error
    };
  }
}


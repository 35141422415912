import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

class SelectMultiple extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    labelfield: PropTypes.string,
    options: PropTypes.array.isRequired,
  };

  static defaultProps = {
    error: "",
    placeholder: "",
    required: false,
    labelfield: "true",
    options: [],
  };
  constructor(props) {
    super(props);
  }
  renderClass() {
    const { style, size } = this.props;
    var classText = "form-control";
    switch (size) {
      case "sm":
        classText += " form-control-sm";
        break;
      case "md":
        classText += " form-control-md";
        break;
      case "lg":
        classText += " form-control-lg";
        break;
    }

    switch (style) {
      case "solid":
        classText += " form-control-solid";
        break;
      case "flush":
        classText += " form-control-flush";
        break;
    }
    return classText;
  }

  render() {
    const {
      required,
      title,
      name,
      error,
      labelfield,
      options,
      style,
      onChange,
      className,
      onClick,
    } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : (
          ""
        )}
        <Form.Select
          id={name}
          defaultValue={[]}
          multiple={true}
          onChange={onChange}
          style={style}
          className={className}
          name={name}
        >
          {options.map((items, i) => {
            if(items.child !=undefined){
              const child=items.child
              return <optgroup key={"child"+i} label={items.value}>
                {
                   child.map((childItem,o)=>(
                    <option key={"child"+i+"item"+o} value={childItem.value} onClick={onClick}>
                      {childItem.label}
                    </option>
                  ))
                }
              </optgroup>
            }else{
              return <option key={i} value={items.value} onClick={onClick}>
                        {items.label}
                      </option>
            }
          })}
        </Form.Select>
        <div className="fv-plugins-message-container invalid-feedback">
          {error}
        </div>
      </React.Fragment>
    );
  }
}

export default SelectMultiple;

/* function collectin */
export function formatCurrency(num,decimal=2,separator=",",decimalSymbol=".") {
    num = parseFloat(num);
    var p = num.toFixed(decimal).split(".");
    return p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num == "-" ? acc : num + (i && !(i % 3) ? separator : "") + acc;
    }, "") + ((decimal>0)?decimalSymbol + p[1]:"");
}

export function formatNumber(num) {
    num = parseFloat(num);
    var p = num.toFixed(0).split(".");
    return p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num == "-" ? acc : num + (i && !(i % 3) ? "." : "") + acc;
    }, "");
}
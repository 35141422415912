import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from "../../../utilities/Form/InputNumber";

import i18n from "../../../../i18n";
import TRComponent from "./TRComponent";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Return: [],
        Header: {},
      },
      date: new Date(),
      unit: [],
      convert: 0,
      update: 0,
      show: false,
      status: 1,
      set: 0,
      listItem:[],
      isUpdate: 0,
    };
    this.inputRefs=[]
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerInputArray = this.handlerInputArray.bind(this);

  }
  setRef = (ref) => {
    this.inputRefs.push(ref);
  };
  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSelectArray(e, id, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[id.name] = null;
    } else {
      objectss[id.name] = e;
    }
    this.setState(arr);
  }

  runSubmit() {
    const {data}=this.props
    const {listItem}=this.state
    console.log(data)
    const header = {
      date: moment(this.state.date).format('YYYY-MM-DD'),
      idBranch:data?.id_branch,
      idType:data?.type_item
    }

    let idItem=[]
    let idUnit=[]
    let qty=[]
    let bandNo=[]
    let warehouse=[]
    let price=[]
    let convertion=[]
    let message=[]
    let status=true    
    for(let i=0;i<listItem.length;i++){
      const currentState=this.inputRefs[i].state
      currentState.unitList.map((p)=>{
        if(p?.value||0 >0){
          idItem.push(listItem[i].idItem)
          idUnit.push(p.idUnit)
          qty.push(p.value)
          bandNo.push(listItem[i].bandNo)
          warehouse.push(currentState.warehouse?.value)
          price.push((p?.priceCurrent||0) * (p?.convertion || 0))
          convertion.push((p?.convertion || 0))
        }

        if(p?.value !=p.qtyCurrent){
          status=false
          message.push("Qty its not same on Row "+(i+1))
        }
       
      })
      if(currentState.warehouse ==null){
        status=false
        message.push("Warehouse must be fill Row "+(i+1))
      }
    }
    const detail={
      idItem:idItem,
      idUnit:idUnit,
      qty:qty,
      bandNo:bandNo,
      warehouse:warehouse,
      price:price,
      convertion:convertion,
    }
    if(!status){
      this.renderAlert(message);
    }
    
    return {ReturnSelling:header,ReturnSellingDet:detail,status:status};
    
  }
  renderAlert(message){
    let html= ""
    message.map(text=>{
      html +=`<p>`+text+`</p>`
    })
    Swal.fire({
      icon: "error",
      title: "Validate Input",
      html: html,
    });
  }
  //CONVERTION

  setConvertion(detail, index, array, key) {
    if (detail.itemValue != null) {
      let choose = {
        unitList: detail.itemValue.unit,
        qty: detail.itemValue.lastStok,
        convertionDetail: detail.convertionDetail,
        index: index,
        key: key,
        array: array,
      };
      this.setState({ itemChoose: choose, modalDetail: true });
    }

  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    })
  }

  handlerInputArray(e, name, i, arrayname, qty, p, id) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = parseInt(e);

    objectss['cons'][p] = value * parseInt(qty);
    objectss['return'][p][name] = value;
    objectss['return'][p]["qty"] = value;
    objectss['return'][p]['id'] = id;
    this.calculate(i, name, value, p);

  }

  calculate(i, name, value, p) {
    let total = 0;

    const detail = this.state.arrayDetail[i];

    const data = detail.cons
    if (data != null && data != '') {
      for (var a = 0; a < data.length; a++) {
        total += data[a] == null ? 0 : data[a];
      }
    }

    if (total <= (detail['convert'] - detail['total_sell'])) {
      detail['convertReturn'] = total;
    } else {
      this.alertError('INFO', 'Total Retun Harus Sama Dengan Total (Ambil - Penjualan)', 'info');
      detail['return'][p][name] = 0;
      detail['return'][p]["qty"] = 0;
      detail['convertReturn'] = 0;
    }


    this.setState(detail);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  render() {
    const { data,  helperReturnSelling } = this.props;
    let unit = [];
    let item = [];
    if((data?.retur_detail.length || 0)>0){
      for(var i=0;i<data.retur_detail.length; i++){
        const selectedDetail=data?.retur_detail[i]
        if((selectedDetail?.convertionDetail.length || 0)>0){
          
          const checkItem=item.filter((item,i)=>item.idItem== selectedDetail.id_item && item.bandNo== selectedDetail.band_no)
          
          if(checkItem.length >0){
            const currentConvertion=checkItem[0].unit.map((convertion,i)=>{
                if(convertion.idUnit == selectedDetail.id_unit){
                    convertion["qty"] +=selectedDetail.qty * convertion.convertion
                    convertion["qtyCurrent"] +=parseFloat(selectedDetail.qty)
                }
                return convertion;
            })
            checkItem["unit"]=currentConvertion
          }else{
            const currentConvertion=selectedDetail.convertionDetail.map((convertion,i)=>{
                if(convertion.idUnit == selectedDetail.id_unit){
                    convertion["qty"]=selectedDetail.qty * convertion.convertion
                    convertion["qtyCurrent"] =parseFloat(selectedDetail.qty)
                }else{
                    convertion["qty"]=0
                    convertion["qtyCurrent"] =0
                }
                return convertion;
            })
            item=[
              ...item,
              {
                idItem:selectedDetail.id_item,
                bandNo:selectedDetail.band_no,
                unit:currentConvertion,
                name:selectedDetail.name_item,
                code:selectedDetail.code_item
              }]
          }

          for(var a=0;a<selectedDetail.convertionDetail.length; a++){
            const check=unit.filter((obj)=>obj.idUnit==selectedDetail.convertionDetail[a].idUnit)
            if(check.length ==0){
              unit.push(selectedDetail.convertionDetail[a])
            }
          }
        }
      }
      this.state.listItem=item
    }

    return (
      <React.Fragment>
          <form>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
              <div className="flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body " >
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Date')} : {data?.date}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Branch')} : {data?.name_branch}
                      </div>
                      <div className="col-3 mt-3">
                        {i18n.t('Store')} : {data?.name_store}
                      </div>
                      <div className="col-3 mt-3">
                        {i18n.t('Type')} : {data?.type}
                      </div>
                    </div>
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Sales Return No.')} : {data?.transaction_no}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Sales Name')} : {data?.code_sales} {data?.name_sales}
                      </div>
                      <div className="col-3 mt-3">
                        {i18n.t('Customer')} : {data?.code_customer} {data?.name_customer}
                      </div>
                     
                    </div>
                    <hr />
                    
                    <br />
                    <div className="row" >
                      <div className="col-12">
                        <Table striped bordered hover className="border" style={{ fontWeight: 'bold' }}>
                          <thead>
                            <tr className="border" style={{ backgroundColor: 'burlywood' }}>
                              <th width={"3%"} className="text-center pb-10" rowSpan={2}>{i18n.t("No.")}</th>
                              <th width={"15%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Item")}</th>
                              <th width={"30%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Return Selling")}</th>
                              {/* <th width={"8%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Selling (Pack)")}</th> */}

                              <th width={"32%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Back")}</th>
                              <th width={"25%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Warehouse")}</th>
                            </tr>
                            <tr className="border">

                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"header" + i} width={"5%"} className="text-center " style={{ backgroundColor: 'burlywood' }} >{header.unitName}</th>
                                })
                                : null}
                              <th key={"header" + 10 + 1} width={"5%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{i18n.t("Convert (Pack)")}</th>


                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"header3" + i} width={"5%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{header.unitName}</th>
                                })
                                : null}
                              <th key={"header" + 10 + 2} width={"8%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{i18n.t("Convert (Pack)")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              item.map((itemData,i)=><TRComponent
                                key={"trItem"+i}
                                data={itemData}
                                unit={unit}
                                warehouseList={helperReturnSelling?.warehouse||[]}
                                no={i}
                                ref={this.setRef}
                              ></TRComponent>)
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
      </React.Fragment>
    );
  }
}

export default Form;

import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, FormLabel, Modal, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import InputNumber from "../../../utilities/Form/InputNumber";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        DeliveryOrder: {},
        DeliveryOrderDetail: [],
      },
      doNo: "",
      titleModal: i18n.t("Convertion"),
      date: new Date(),
      deliveryDate: new Date(),
      soNo:'',
      arrayDetail: [],
      type:0,
      detailSalesOrder:[],
      itemList: [],
      unitList: [],
      detailDeliveryOrder: [],
      itemText: i18n.t("Select Item"),
      unitText: i18n.t("Select Unit"),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      salesOrderCurrent: null,
      salesOrderText: i18n.t("Select Sales Order"),
      salesOrderList: [],
      customerCurrent: null,
      customerText: i18n.t("Select Name Customer"),
      customerList: [],
      isUpdate: 0,
      status: 1,
      totalPrice: 0,
      grandTotal: 0,
      limit:0,
      allTrans:0,
      addItem:0,
      paymentType:null,
    };

    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }

    let priceNett = price + priceTax - discount;
    let priceTotal = priceNett * qty;
    objectss["priceNett"] = priceNett;
    objectss["priceTax"] = priceTax;
    objectss["priceTotal"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    //total
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].price) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].priceTax) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }

    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });           
  }
  runSubmit() {
    let idSalesO = [];
    const detail = this.state.arrayDetail;

    if(detail.length > 0){

    for(var i=0;i<this.state.salesOrderCurrent.length;i++){
      idSalesO.push(this.state.salesOrderCurrent[i].value) 
    }

    const DeliveryOrder = {
      doNo: this.state.doNo,
      date: moment(this.state.date).format("YYYY-MM-DD"),
      deliveryDate: moment(this.state.deliveryDate).format("YYYY-MM-DD"),
      idBranch:
        this.state.branchCurrent != null ? this.state.branchCurrent.value : "",
      idStore:
        this.state.storeCurrent != null ? this.state.storeCurrent.value : "",
      idCustomer:
        this.state.customerCurrent != null ? this.state.customerCurrent.value : "",  
      status:1,
      idSalesOrder:idSalesO
      
    };
    
    let idItem = [];
    let idUnit = [];
    let qty = [];
    let idSO =[];
    let unitPrice = [];
    let status = [];

    
    for (var i = 0; i < detail.length; i++) {
      idItem.push(detail[i].idItem == null ? "" : detail[i].idItem.value);
      idUnit.push(detail[i].idUnit == null ? "" : detail[i].idUnit.value);
      unitPrice.push(detail[i].unitPrice == null ? "" : detail[i].unitPrice);
      idSO.push(detail[i].idSO == null ? "" : detail[i].idSO);
      qty.push(detail[i].qty);
      status.push(1)

    }
    this.state.form.DeliveryOrder = DeliveryOrder;
    this.state.form.DeliveryOrderDetail = {
      idItem: idItem,
      idUnit: idUnit,
      qty: qty,
      status: status,
      unitPrice:unitPrice,
      idSO:idSO,

    };
    return this.state.form;
  }else{
    return false;
  }
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  fetchSalesOrder() {
    const {isProcessing} = this.props
    let item = this.state.detailSalesOrder;
    let band =0;
    if(item !=null){
        var array=[];
        for(var o=0;o<item.length;o++){
          let row = item[o];
          console.log('row',row)
          for(var p=0;p<row.length;p++){
            array.push(
              {
                soNo: row[p].so_no,
                idSO: row[p].id_so,
                idItem: { value: row[p].id_item, label: row[p].name_item },
                idUnit: { value: row[p].id_unit, label: row[p].name_unit },
                unitPrice: parseFloat(row[p].price)||0,
                qty: row[p].qty,
              }
            )
          }
          
            
        }
        this.setState({arrayDetail:array})
    }
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    {(this.state.salesOrderCurrent!=null)?'':''}
    
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
        <td>
            <Input
              name={"soNo"}
              className={"form-control"}
              type={"text"}
              title={"Saler Order No."}
              onChange={(e) =>
                this.handlerSelectArray(e, "soNo", i, "arrayDetail")
              }
              disabled
              labelfield={"false"}
              value={detail.soNo}
            />
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idItem"}
              required={true}
              className={""}
              size={4}
              onChange={(e) =>
                this.handlerSelectArray(e, "idItem", i, "arrayDetail")
              }
              isDisabled
              options={this.state.itemList}
              value={detail.idItem}
              placeholder={this.state.itemText}
            />
          </td>
          <td>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qty}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "qty", i, "arrayDetail")
              }
              disabled
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
            <div
              className={
                detail.stock > 0
                  ? "fv-plugins-message-container valid-feedback"
                  : "fv-plugins-message-container invalid-feedback"
              }
            >
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idUnit"}
              required={true}
              className={""}
              size={4}
              isDisabled
              onChange={async (e) => {
                await this.handlerSelectArray(e, "idUnit", i, "arrayDetail")
                await this.changeData('unitPrice', 'getSellingPrice', 'price', 'arrayDetail', i)
              }}
              options={this.state.unitList}
              value={detail.idUnit}
              placeholder={this.state.unitText}
            />
          </td>
          <td>
            <InputNumber
              id={"unitPrice" + i}
              name="unitPrice"
              title={i18n.t("Unit Price")}
              placeholder={i18n.t("Unit Price")}
              defaultValue={0}
              value={detail.unitPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "unitPrice", i, "arrayDetail")
              }
              disabled
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(keyFill,type=null,key='',array=null,arraykey=null,extraFunction=null){
    const token=cookieManager.getUserToken()
    let params=null
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-delivery";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getSalesOrder":
        var url = process.env.REST_URL + "helper/get-sales-order";
        var type = this.state.type;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var customer =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          branch: branch,
          store: store,
          customer:customer,
          type:1,
          date:date
        };
        break;
        case "getDetailSalesOrder":
          var url = process.env.REST_URL + "helper/get-sales-order-detail";
          var id=[];
          for(var i=0;i < this.state.salesOrderCurrent.length; i++){
              id.push(this.state.salesOrderCurrent[i].value);
          }
          var type = this.state.type;
          if(id.length >0 ){
              params={
                  id:id,
                  type:type
              }
          }else{
              this.resetDetail()
              type=null
          }
        break;
        case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
    }

    if(type !=null){
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            url:url,
            responseType: 'json',
            params:params
          })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
                if(array!=null){
                    var arr = [...this.state[array]];
                    const objectss = arr[arraykey]
                    if(key==''){
                        objectss[keyFill] = response.data.result;
                    }else{
                        objectss[keyFill] = response.data.result[key];
                    }
                }else{
                    if(key==''){
                       var arr= {[keyFill]:response.data.result}
                    }else{
                        var arr= {[keyFill]:response.data.result[key]}
                    }
                }
                if(extraFunction!=null){
                    this.setState(arr,extraFunction)
                }else{
                    this.setState(arr)
                }
               
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }

  resetDetail(){
    this.setState({arrayDetail:[
        {
            idItem:'',
            idUnit:'',
            qty:'',
            unitPrice:'',
            itemValue:null,
            unitValue:'',
            soNo:'',
            idSO:''
        }
    ]})
}
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  render() {
    const { data, isNewRecord, helperDeliveryOrder,isProcessing } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    this.state.branchList =
      helperDeliveryOrder != null ? helperDeliveryOrder.branch : [];

    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        let list = [];
        this.state.doNo = data.do_no
        this.state.description = data.description
        this.state.date = new Date(data.date)
        this.state.branchCurrent = { value: data.id_branch, label: data.name_branch }
        this.state.storeCurrent = { value: data.id_store, label: data.name_store }
        this.state.customerCurrent = { value: data.id_customer, label: data.name_customer }
       this.changeData("customerList", 'getCustomer', "");

        for(var i=0;i< data.id_sales_order.length;i++){
          list.push({value: data.id_sales_order[i].value, label: data.id_sales_order[i].name })
        }
        this.state.type = 1;
        this.changeData("salesOrderList", 'getSalesOrder', "");
        this.setState({salesOrderCurrent:list},()=>this.changeData("detailSalesOrder", "getDetailSalesOrder","",null,null,
        async(e)=>{await this.fetchSalesOrder()}));
        this.state.arrayDetail = [];
        
        let row = data.sales_detail;
        // for(var p=0;p< row.length;p++){
        //   this.state.arrayDetail.push({
        //     soNo: row[p].so_no,
        //     idSO: row[p].id_so,
        //     idItem: { value: row[p].id_item, label: row[p].name_item },
        //     idUnit: { value: row[p].id_unit, label: row[p].name_unit },
        //     unitPrice: row[p].price,
        //     qty: row[p].qty,
        //   });
        // }
        
        this.state.isUpdate =1 ;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Input
                type={"input"}
                name={"doNo"}
                title={i18n.t("Document No")}
                required
                disabled
                value={this.state.doNo}
                handler={this.handler}
                error={this.renderError("DeliveryOrder.doNo")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("doNo", 'getCode', "code");
                  await this.changeData("salesOrderList", 'getSalesOrder', "");

                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
          <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"deliveryDate"}
                title={i18n.t("Delivery Date")}
                required={true}
                selected={this.state.deliveryDate}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "deliveryDate");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e != this.state.branchCurrent) {
                    await this.setState({
                      storeCurrent: null,
                      storeList: [],
                    });
                  }
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ doNo: '', storeCurrent: '', salesOrderCurrent: ''});
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData("doNo", 'getCode', "code");
                  
                    await this.changeData("customerList", 'getCustomer', "");
                  }

                  ;
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("DeliveryOrder.idBranch")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idCustomer"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if(e == null){
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.setState({salesOrderCurrent:null,salesOrderList:[]})
                  }else{
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.changeData("salesOrderList", 'getSalesOrder', "");
                  }
                 
                }}
                options={this.state.customerList}
                title={i18n.t("Customer")}
                value={this.state.customerCurrent}
                placeholder={this.state.customerText}
              />
               {/* <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("SalesOrder.idSales")}
               </div> */}
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                // isClearable={true}
                name={"idSalesOrder"}
                required={true}
                isMulti
                onChange={async (e) => {
                  if(e != null){
                    await this.handlerSelect(e, "idSalesOrder", "salesOrderCurrent");
                    await this.changeData("detailSalesOrder", "getDetailSalesOrder","",null,null,
                                          async(e)=>{await this.fetchSalesOrder()});
                  }else{
                    // await this.handlerSelect(e, "idSalesOrder", "salesOrderCurrent");
                    await this.handlerSelect([], "idSalesOrder","salesOrderCurrent")
                    await this.resetDetail();
                  }
                 

                }}
                options={this.state.salesOrderList}
                title={i18n.t("Sales Order")}
                value={this.state.salesOrderCurrent}
                placeholder={this.state.salesOrderText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("DeliveryOrder.idSalesOrder")}
               </div>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center">{i18n.t("Sales Order No.")}</th>
                    <th className="text-center">{i18n.t("Item")}</th>
                    <th className="text-center">{i18n.t("Qty")}</th>
                    <th className="text-center">{i18n.t("Unit")}</th>
                    <th className="text-center">{i18n.t("Price")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
              
                         {this.state.arrayDetail.map((detail, index) => {
                          return this.renderDetail(detail, index);
                        })}
                        
                  
                </tbody>
              </Table>
            </div>
          </div>
        </form>
       
      </React.Fragment>
    );
  }
}

export default Form;

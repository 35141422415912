import React, { useEffect, useState } from "react";
import {  Button, Image, Table,   } from "react-bootstrap";
import TypeLogTx from "../../../utilities/Const/typeLogTx";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PhotoGallery from "../../../utilities/PhotoGallery";

const MySwal = withReactContent(Swal)
export default function Content(props){
    const [showDetail,setShowDetails]=useState(false)
    const [details,setDetails]=useState(null)
    const token=cookieManager.getUserToken()
    const url=process.env.REST_URL
    function alertHendler(result,isSwal=false,extraMessage='',type='error'){
        if(type=='error'){
          if (result.response) {
            const status=result.response.status
            switch(status){
              case 500:
                if(isSwal){
                  const data=result.response.data
                  MySwal.hideLoading()
                  MySwal.update({
                    title:'Delete Error',
                    html:data.error,
                    icon:'error',
                    showConfirmButton:true,
                  })
                }else{
                  const data=result.response.data
                  alert(result.response.data)
                }
              break;  
              case 403:
                console.log('Error 403');
              break;     
              case 404:
                console.log('Error 404');
              break;   
              case 401:
                const data=result.response.data
                  MySwal.fire({
                    title:'Login Invalid',
                    html:data.error,
                    icon:'error',
                    showConfirmButton:true,
                  }).then((result)=>{
                      if(result.isConfirmed){
                        <Navigate to="/login"></Navigate>
                      }
                  })
              break;   
            }
          } else if (result.request) {
            MySwal.fire({
              title:'Internal Server Error',
              html:'Please info your Developer to fix this',
              icon:'error',
              showConfirmButton:true,
            })
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', result.message);
          }
        }else{
          MySwal.fire({
            title:'Success',
            html:extraMessage,
            icon:'success',
            showConfirmButton:true,
          })
        }
    }
    
    function detailsSelling(data){
        setShowDetails(true)
        axios({
            method: 'GET',
            headers: {
              'Content-Type': "multipart/form-data",
              Authorization: `Bearer ${token}`
            },
            url: url+'sales/selling/'+data.id,
            responseType: 'json'
          })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
                setDetails(response.data.result)
            }
          })
          .catch((error) => {
            alertHendler(error)
          });
    }

    return (
        <>
        {props.type==TypeLogTx.CHECKIN_CUSTOMER && 
            <div className="text-center">
                <Image
                style={{width:'50%',height:'50%',top: 0,left: 0,}} 
                src={props?.image}
            />
            </div>
        }

        {props.type==TypeLogTx.CLOSED_CUSTOMER && 
            <div className="text-center">
                <Image
                style={{width:'50%',height:'50%',top: 0,left: 0,}} 
                src={props?.image}
            />
            </div>
        }

        {props.type==TypeLogTx.SELLING_CUSTOMER && 
            <div >
                {!showDetail&&
                <Table
                    className="table-responsive bordered"
                >
                    <thead className="bg-success text-white text-center" >
                        <th>Invoice No</th>
                        <th>Date</th>
                        <th>Branch Name</th>
                        <th>Payment Type</th>
                        <th>Total</th>
                        <th>Detail</th>
                    </thead>
                    <tbody>
                        {props?.data.map((selling,index)=>
                        <tr key={'selling'+index}>
                            <td>
                                {selling?.transaction_no}
                            </td>
                            <td>
                                {moment(selling?.date).format('DD MMMM YYYY')}
                            </td>
                            <td>
                                {selling?.name_branch}
                            </td>
                            <td>
                                {selling?.payment_type}
                            </td>
                            <td>
                                {"Rp "+formatCurrency(selling?.total)}
                            </td>
                            <td>
                                <Button className="btn btn-sm" onClick={()=>{
                                    detailsSelling(selling)
                                }}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button>
                            </td>
                        </tr>
                        )}
                    </tbody>
               </Table>}
                {showDetail&&
                <div className='row'>
                    <div className="col-12 mb-10">
                        <Button className="btn btn-sm" onClick={()=>{
                            console.log('sini')
                            setShowDetails(false)
                            setDetails(null)
                        }}><FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon></Button>
                        {details?.transaction_no}
                    </div>
                    <div className="col-12">
                        <Table
                            className="table-responsive bordered"
                        >
                            <thead className="bg-success text-white text-center" >
                                <th>Item Code</th>
                                <th>Item Name</th>
                                <th>Qty</th>
                                <th>price</th>
                                <th>Total</th>
                            </thead>
                            <tbody>
                                {details && details?.selling_detail.map((item,index)=>
                                    <tr>
                                        <td>{item?.code_item}</td>        
                                        <td>{item?.name_item}</td>        
                                        <td>{formatCurrency(item?.qty,0)} {item?.name_unit}</td>        
                                        <td>{'Rp '+formatCurrency(item?.unit_price,0)}</td>        
                                        <td>{'Rp '+formatCurrency(item?.total)}</td>        
                                    </tr>)}
                            </tbody>
                        </Table>
                        <PhotoGallery images={details?.images||[]}></PhotoGallery>
                    </div>
                </div>}
            </div>
        }
        {props.type==TypeLogTx.BILLING_CUSTOMER && 
            <div className="text-center">
               <Table
                className="table-responsive bordered"
               >
                <thead className="bg-success text-white" >
                    <th>Invoice No</th>
                    <th>Date</th>
                    <th>Payment Type</th>
                    <th>Total</th>
                    <th>Payment</th>
                    <th>Remaining</th>
                </thead>
                <tbody>
                    {props?.data.map((billing,index)=>{
                        let remaining= ((billing?.total||0) - (billing?.payment||0) )
                        return (<tr key={'billing'+index}>
                            <td>
                                {billing?.invoice_no}
                            </td>
                            <td>
                                {moment(billing?.date).format('DD MMMM YYYY')}
                            </td>
                            <td>
                                {billing?.payment_type}
                            </td>
                            <td>
                                {"Rp "+formatCurrency(billing?.total)}
                            </td>
                            <td>
                                {"Rp "+formatCurrency( billing?.payment)}
                            </td>
                            <td>
                                {"Rp "+formatCurrency( ((remaining > 0)?remaining:0))}
                            </td>
                            
                        </tr>)
                    }
                    )}
                </tbody>
               </Table>
               {props?.data.length >0&&<PhotoGallery images={props?.data[0]?.images||[]}></PhotoGallery>}
               
            </div>
        }
        {props.type==TypeLogTx.RECORD_STOCK_CUSTOMER && 
            <div className="text-center">
               <Table
                className="table-responsive bordered"
               >
                <thead className="bg-success text-white" >
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Unit</th>
                    <th>Qty</th>
                </thead>
                <tbody>
                    {props?.data.record_detail.map((stock,index)=>{
                        return (<tr key={'stock'+index}>
                            <td>
                                {stock?.code_item}
                            </td>
                            <td>
                                {stock?.name_item}
                            </td>
                            <td>
                                {stock?.name_unit}
                            </td>
                            <td>
                                {formatCurrency(stock?.qty,0)}
                            </td>
                        </tr>)
                    }
                    )}
                </tbody>
               </Table>
               <PhotoGallery images={props?.data.images||[]}></PhotoGallery>
            </div>
        }
        {props.type==TypeLogTx.TROUBLE_EMPLOYEE && 
            <div className="text-center">
                 <Table
                className="table-responsive bordered"
               >
                <thead className="bg-success text-white" >
                    <th>Remark</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Done</th>
                    <th>Photo</th>
                </thead>
                <tbody>
                    {props?.data.map((trouble,index)=>{
                        return (<tr key={'trouble'+index}>
                            <td>
                                {trouble?.remark}
                            </td>
                            <td>
                                {moment(trouble?.date).format('DD MMMM YYYY')}
                            </td>
                            <td>
                                {trouble?.time}
                            </td>
                            <td>
                                {trouble?.completion_time}
                            </td>

                            <td>
                                <PhotoGallery images={trouble?.images||[]}></PhotoGallery>
                            </td>
                        </tr>)
                    }
                    )}
                </tbody>
               </Table>
              
               
            </div>
        }
        </>
    )
}
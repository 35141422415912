import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import PrintReceiving from "../../../components/utilities/print/default/receiving";
// middlewares
import ReceivingMiddleware from "../../../middleware/modules/pembelian/receiving";

const mapStateToProps = ({ auth, receiving }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: receiving.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  dataPrint: receiving.printReceiving,
  requestPrint:receiving.requestPrintReceiving
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (id) => ReceivingMiddleware.getPrint(id),
    },
    dispatch
  );
};

class ReceivingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.id);
  }

  render() {
    return <PrintReceiving {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingContainer);

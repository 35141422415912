import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import WorkSheetMiddleware from "../../../middleware/modules/report/work-sheet";
import PrintWorkSheet from "../../../components/utilities/print/default/work-sheet";

const mapStateToProps = ({ auth, workSheet }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: workSheet.isProcessing,
  dataPrint: workSheet.printWorkSheet,
  requestPrint:workSheet.requestPrintWorkSheet
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (data) => WorkSheetMiddleware.getPrint(data),
    },
    dispatch
  );
};

class WorkSheetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.data);
  }

  render() {
   
    return <PrintWorkSheet {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSheetContainer);

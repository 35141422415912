
import React,{ Component, Fragment } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from '../../../utilities/Form/InputNumber';
import Radio from '../../../utilities/Form/radio';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputCustom from '../../../utilities/Form/InputCustom';

const MySwal = withReactContent(Swal);

export class FormDP extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
              DownPayment:{},
              DownPaymentDetail:[],
            },
            arrayDetail:[{
                nominal:0,
                poCurrent:null,
                soCurrent:null,
                totalInvoice:0
            }],
            dataDetail:[],
            idBranch:null,
            branchCurrent: null,
            branchText: i18n.t("Select Branch"),
            branchList: [],
            idAccount:null,
            accountCurrent: null,
            accountText: i18n.t("Select Cash/Bank Account"),
            accountList: [],
            idSupplier:null,
            supplierCurrent: null,
            supplierText: i18n.t("Select Supplier"),
            supplierList: [],
            idCustomer:null,
            customerCurrent: null,
            customerText:i18n.t("Select Customer"),
            customerList: [],
            idPO:null,
            poCurrent: null,
            poText:i18n.t("Select PO"),
            poList: [],
            idSO:null,
            soCurrent: null,
            soText:i18n.t("Select SO"),
            soList: [],
            transactionNo:'',
            typeDPList: { 'Out': i18n.t('Purchasing'), 'In': i18n.t('Selling') },
            typeDP:'Out',
            customerType:null,
            date:new Date(),
            name:'',
            showDetail:false,
            loadingAccount:false,
            loadingCode:false,
            loadingCustomer:false,
            loadingSO:false,
            loadingPO:false,
            nominal:0,
            loadingCode:false,
        }
        this.handler = this.handler.bind(this)
        this.handlerRadio = this.handlerRadio.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    runSubmit(){
        const DownPayment = {
            idBranch:this.state.branchCurrent != null?this.state.branchCurrent.value:null,
            idAccount:this.state.accountCurrent != null?this.state.accountCurrent.value:null,
            type:this.state.typeDP,
            idCoa4:this.state.typeDP == 'Out' ? 
                    this.state.supplierCurrent != null ?
                    this.state.supplierCurrent.value:null
                    : this.state.customerCurrent != null ?
                    this.state.customerCurrent.value :null,
            nominal:this.state.nominal,
            transactionNo:this.state.transactionNo,
            date:moment(this.state.date).format('YYYY-MM-DD'),
        }

        this.state.form.DownPaymentDetail = [];

        if(this.state.showDetail == true){
        const detail = this.state.arrayDetail;
        let idTransaction = [];
        let nominal = [];
        
    
        for (var i = 0; i < detail.length; i++) {
            this.state.typeDP == 'Out' ? (
                idTransaction.push(detail[i].poCurrent == null ? null : detail[i].poCurrent.value)
            ):(
                idTransaction.push(detail[i].soCurrent == null ? null : detail[i].soCurrent.value)
            )
  
            nominal.push(detail[i].nominal == null ? "" : detail[i].nominal);
        }
        this.state.form.DownPaymentDetail = {
            idTransaction:idTransaction,
            nominal:nominal
        };
    }

        this.state.form.DownPayment = DownPayment;
     
        
        // this.state.form.Data = Data
        return this.state.form
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }
    
    handlerCurrency(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value 
        })
    }

    handlerRadio(e){
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value,
            supplierCurrent:null,
            customerCurrent:null,
            accountCurrent:null,
            soCurrent:null, poCurrent:null,
           
        })
    }

    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }

    handlerSelectArray(e, name, i, arrayname) {
        console.log(e,name)
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        const data  = [...this.state.dataDetail];
        if(i!=0){
            if (e == null) {
                objectss[name] = null;
                objectss["totalInvoice"] = 0;
                objectss["nominal"] = 0;

                data[i] = null;
            } else {
                if(data.includes(e.value) == false){
                    objectss[name] = e;
                    objectss["totalInvoice"] = parseFloat(e.helper)||0;
                    data[i] = e.value
                }else{
                    alert(i18n.t('Transaction No Cannot Be The Same'))
                }
            }     
        }else{
            if (e == null) {
                objectss[name] = null;
                objectss["totalInvoice"] = 0;
                objectss["nominal"] = 0;
                data[i] = null;
            } else {
                data[i] = e.value
                objectss[name] = e;
                objectss["totalInvoice"] = parseFloat(e.helper)||0;
            }
        }
        this.setState(arr)
        this.setState({dataDetail:data});
      }

    handlerCurrencyArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        // objectss[name] = e;
        console.log(arr)
        if(objectss['totalInvoice'] != null && objectss['totalInvoice'] < e ){
            alert(i18n.t('Nominal Down Payment Cant Bigger Than Total Invoice'))
            objectss[name] = 0;
        }else{
            objectss[name] = e;
        }
        this.setState(arr, () => this.calculateAll());
    }

    calculateAll(){
        let detail = this.state.arrayDetail
        // let nominal  = this.state.nominal
        let total = 0

        for(var i=0; i<detail.length; i++){
            let nominalDetail = parseFloat(detail[i].nominal) || 0

            total += nominalDetail;
        }

        this.setState({
            nominal : total
        });
    }
    
    addArray(value, key) {
        const newRow = value;
        const arr = [...this.state[key], newRow];
        this.setState({
            ...this.state,
            [key]: arr,
        });
    }

    removeArray(i, key) {
        const arr = this.state[key].filter((obj, index) => {
            return index !== i;
        });

        const data = this.state.dataDetail.filter((obj, index) => {
            return index !== i;
        });

        this.setState({
            ...this.state,
            [key]: arr,
            dataDetail:data
        }, () => this.calculateAll());
    }

    setDP(){
        var detail = !this.state.showDetail

        this.setState({
            nominal:0,
            showDetail:detail,
            arrayDetail:[{
                nominal:0,
                poCurrent:null,
                totalInvoice:0
            }],
        })
       
    }
    
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    

    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;

                // var type =
                //     this.state.typeDP == 'Out' 
                //         ? 'all' : this.state.customerType == 'Cash'
                       

                params = {
                    branch: branch,
                    // type: 'All',
                }
            break;
            case 'getCustomer':
                var url = process.env.REST_URL + 'helper/get-customer'
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;

                params = {
                    branch: branch,
                }
            break;
            case 'getPO':
                var url = process.env.REST_URL + 'helper/get-po-list-dp'
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var supplier =
                    this.state.supplierCurrent != null
                        ? this.state.supplierCurrent.value
                        : null;
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                params = {
                    branch: branch,
                    supplier: supplier,
                    date: date,
                }
            break;
            case 'getSO':
                var url = process.env.REST_URL + 'helper/get-so-list-dp'
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var customer =
                    this.state.customerCurrent != null
                        ? this.state.customerCurrent.value
                        : null;
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                params = {
                    branch: branch,
                    customer: customer,
                    date: date,
                }
            break;
            case "getCode":
                var url = process.env.REST_URL + "helper/get-code-dp";
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    date: date,
                    branch: branch,
                   
                };
                break;
           
        }

        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }

    renderDetail(detail,i){
        return <React.Fragment key={"detail" + i}>
        <>
            <tr>
            {this.state.typeDP == 'Out'? 
                <td>
                    <Select2
                        isClearable={true}
                        name={"idPO"}
                        size={4}
                        onChange={async (e) => {
                            if (e == null) {
                                await this.handlerSelectArray(e, "poCurrent", i , "arrayDetail");
                            } else {
                                await this.handlerSelectArray(e, "poCurrent", i , "arrayDetail");
                                
                            }
                        }}
                        isLoading={this.state.loadingPO}
                        options={this.state.poList}
                        title={i18n.t("PO List")}
                        value={detail.poCurrent}
                        placeholder={this.state.poText}
                        labelfield={"false"}
                    />
                    <div className="fv-plugins-message-container invalid-feedback">
                        {/* {this.renderError("idPO")} */}
                    </div>
           
            </td>
            :
            <td>
                <Select2
                isClearable={true}
                name={"idSO"}
                // required={true}
                size={4}
                onChange={async (e) => {
                    if (e == null) {
                        await this.handlerSelectArray(e, "soCurrent", i , "arrayDetail");
                    } else {
                        await this.handlerSelectArray(e, "soCurrent", i , "arrayDetail");
                        
                    }
                }}
                isLoading={this.state.loadingSO}
                options={this.state.soList}
                title={i18n.t("SO List")}
                value={detail.soCurrent}
                placeholder={this.state.soText}
                labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
                {/* {this.renderError("idPO")} */}
            </div>
            </td>
            }
            <td>
                <InputNumber
                    id={"totalInvoice"}
                    name="totalInvoice"
                    placeholder={i18n.t("Total Invoice")}
                    title={i18n.t("Total Invoice")}
                    defaultValue={0}
                    value={detail.totalInvoice}
                    decimalsLimit={2}
                    // onValueChange={(value, name) =>
                    //     // this.handlerCurrency(value, name)
                    // }
                    className="form-control  form-control-md text-end"
                    prefix="Rp "
                    disabled
                    labelfield={"false"}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                    {/* {this.renderError("nominal")} */}
                </div>
            </td>
            <td>
                <InputNumber
                    id={"nominal"}
                    name="nominal"
                    placeholder={i18n.t("Nominal")}
                    title={i18n.t("Nominal")}
                    defaultValue={0}
                    value={detail.nominal}
                    decimalsLimit={2}
                    onValueChange={async(e, name) =>{
                        if(e == null){
                            await this.handlerCurrencyArray(0, name, i, "arrayDetail")

                        }else{
                            await this.handlerCurrencyArray(e, name, i, "arrayDetail")
                        }
                    }}
                    // disabled={(this.state.isUpdate==0)?false:true}
                    className="form-control  form-control-md text-end"
                    prefix="Rp "
                    labelfield={"false"}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                    {/* {this.renderError("nominal")} */}
                </div>
            </td>
           {/* <td>
                <InputNumber
                id={"price" + i}
                name="price"
                placeholder={i18n.t("Price")}
                defaultValue={0}
                value={detail.price}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                    this.handlerCurrencyArray(value, "price", i, "arrayDetail")
                }
                className="form-control  form-control-md text-end"
                prefix="Rp "
                labelfield={"false"}
                required
                />
                <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("SellingPriceDet.price." + i)}
                </div>
            </td> */}
            {/* {this.state.isUpdate == 1 ? (
                <td>
                <InputCustom
                    type={"checkbox"}
                    className={
                    "form-check-input form-check form-check-custom form-check-solid"
                    }
                    name={"changePrice"}
                    labelfield={"false"}
                    value={detail.changePrice == 1 ? 0 : 1}
                    onChange={(value) =>
                    this.handlerInputArray(value, "changePrice", i, "arrayDetail")
                    }
                />
                </td>
            ) : (
                ""
            )} */}
            <td>
                <Button
                variant="danger"
                className="btn btn-sm"
                onClick={(e) => this.removeArray(i, "arrayDetail")}
                >
                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
            </td>
            </tr>
        </>
    </React.Fragment>
    }
    
    render(){
        const {helperDownPayment}=this.props
        console.log('PROPS',this.props)
        console.log('STATE',this.state)

        if(helperDownPayment!=null && helperDownPayment!=''){
            this.state.branchList=helperDownPayment.branch
            this.state.supplierList=helperDownPayment.supplier
        }
        
        return (
            <React.Fragment>
            <form>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <Input
                            type={"input"}
                            name={"transactionNo"}
                            title={i18n.t("Transaction No")}
                            required
                            value={this.state.transactionNo}
                            handler={this.handler}
                            error={this.renderError('transactionNo')}
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Date")}
                            required={true}
                            selected={this.state.date}
                            onSelect={async (e) => {
                                await this.handlerDateTime(e, 'date');
                                this.changeData({
                                    keyFill:"transactionNo",
                                    type:"getCode",
                                    key:"code"
                                  })
                            }
                            }
                            disabled={this.state.isUpdate == 1 ? true : false}
                            onChange={(e) => { }}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMMM yyyy"
                            maxDate={new Date()}
                            minDate={this.state.limitDate}
                            className="form-control form-control-md"
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <Select2
                            isClearable={true}
                            name={"idBranch"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                                await this.setState({
                                    transactionNo: '', supplierCurrent:null, supplierList:[],
                                    customerCurrent:null, customerList:[], accountCurrent:null, accountList:[],
                                    soCurrent:null, soList:[], poCurrent:null, poList:[]
                                });
                                if (e == null) {
                                    this.setState({
                                        transactionNo: '', supplierCurrent:null, supplierList:[],
                                        customerCurrent:null, customerList:[], accountCurrent:null, accountList:[],
                                        soCurrent:null, soList:[], poCurrent:null, poList:[]
                                    });
                                } else {
                                    await this.setState({
                                        loadingAccount:true, loadingCustomer:true, loadingCode:true
                                    });
                                    this.changeData({
                                        keyFill:"transactionNo",
                                        type:"getCode",
                                        key:"code",
                                        callback:()=>{this.setState({loadingCode:false})}
                                      })

                                    this.changeData({
                                        keyFill:"customerList",
                                        type:"getCustomer",
                                        key:"",
                                        callback:()=>{this.setState({loadingCustomer:false})}
                                      })

                                    this.changeData({
                                        keyFill:"accountList",
                                        type:"getBank",
                                        callback:()=>{this.setState({loadingAccount:false})}
                                      })
                                }      
                                      
                                
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <Radio
                            id={"typeDP"}
                            list={this.state.typeDPList}
                            name={"typeDP"}
                            title={i18n.t("Type")}
                            inline={true}
                            value={this.state.typeDP}
                            defaultValue={'Out'}
                            handler={this.handlerRadio}
                            labelfield={"false"}
                        />
                    </div>
                </div>
                <div className="row">
                {this.state.typeDP == 'Out'? 
                <Fragment>
                    <div className="col-md-3 mt-3">
                        <Select2
                            isClearable={true}
                            name={"supplier"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelect(e, "idSupplier", "supplierCurrent");
                                    await this.setState({
                                        soCurrent:null, soList:[], poCurrent:null, poList:[]
                                    });
                                } else {
                                    await this.handlerSelect(e, "idSupplier", "supplierCurrent");
                                    await this.setState({
                                       loadingPO:true
                                    });
                                    await this.changeData({
                                        keyFill:"poList",
                                        type:"getPO",
                                        callback:()=>{this.setState({loadingPO:false})}
                                      })
                                   
                                }
                            }}
                            options={this.state.supplierList}
                            title={i18n.t("Supplier")}
                            value={this.state.supplierCurrent}
                            placeholder={this.state.supplierText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("idCoa4")}
                        </div>
                    </div>
                    {/* <div className="col-md-3 mt-3">
                        <Select2
                            isClearable={true}
                            name={"idPO"}
                            // required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelect(e, "idPO", "poCurrent");

                                } else {
                                    await this.handlerSelect(e, "idPO", "poCurrent");
                                   
                                }
                            }}
                            isLoading={this.state.loadingPO}
                            options={this.state.poList}
                            title={i18n.t("PO List")}
                            value={this.state.poCurrent}
                            placeholder={this.state.poText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("idPO")}
                        </div>
                    </div> */}
                
                </Fragment>
                :
                <Fragment>
                    <div className="col-md-3 mt-3">
                        <Select2
                            isClearable={true}
                            name={"customer"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                                    await this.setState({
                                        soCurrent:null, soList:[], poCurrent:null, poList:[]
                                    });
                                } else {
                                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                                    await this.setState({
                                       loadingSO:true
                                    });
                                    await this.changeData({
                                        keyFill:"soList",
                                        type:"getSO",
                                        callback:()=>{this.setState({loadingSO:false})}
                                      })
                                   
                                }
                            }}
                            isLoading={this.state.loadingCustomer}
                            options={this.state.customerList}
                            title={i18n.t("Customer")}
                            value={this.state.customerCurrent}
                            placeholder={this.state.customerText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("idCoa4")}
                        </div>
                    </div>
                    {/* <div className="col-md-3 mt-3">
                        <Select2
                            isClearable={true}
                            name={"idSO"}
                            // required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelect(e, "idSO", "soCurrent");

                                } else {
                                    await this.handlerSelect(e, "idSO", "soCurrent");
                                   
                                }
                            }}
                            isLoading={this.state.loadingSO}
                            options={this.state.soList}
                            title={i18n.t("SO List")}
                            value={this.state.soCurrent}
                            placeholder={this.state.soText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("idPO")}
                        </div>
                    </div> */}
                </Fragment>
                }
                   <div className="col-md-4 mt-3">
                        <Select2
                            isClearable={true}
                            name={'idAccount'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idAccount", "accountCurrent");
                            }}
                            isLoading={this.state.loadingAccount}
                            options={this.state.accountList}
                            title={i18n.t('Cash/Bank Account')}
                            value={this.state.accountCurrent}
                            placeholder={this.state.accountText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idAccount')}
                        </div>
                   </div>
                   <div className="col-md-3 mt-3">
                        <InputNumber
                            id={"nominal"}
                            name="nominal"
                            placeholder={i18n.t("Nominal")}
                            title={i18n.t("Nominal")}
                            defaultValue={0}
                            value={this.state.nominal}
                            decimalsLimit={2}
                            onValueChange={(value, name) =>
                                this.handlerCurrency(value, name)
                            }
                            disabled={(this.state.showDetail==true)?true:false}
                            className="form-control  form-control-md text-end"
                            prefix="Rp "
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("nominal")}
                        </div>
                    </div>
                    <div className="col-md-2 mt-12">
                        <span className='get-inline '>  
                            <InputCustom
                                type={"checkbox"}
                                className={
                                    "form-check-input form-check form-check-custom form-check-solid border-check"
                                }
                                id={"showDetail"}
                                value={this.state.showDetail}
                                name={"showDetail"}
                                labelfield={"false"}
                                onChange={
                                    () => this.setDP()
                                }
                            />
                        </span>
                            &nbsp;&nbsp;
                        <span className='get-inline'>
                            <label className='label-control ' htmlFor='byDetail'>{i18n.t("By Detail")}</label>
                        </span>   
                    </div>
                </div>
                <hr/>
                {this.state.showDetail == true?
                <div className="row">
                    <div className="col-12">
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th className="text-center" width="35%">{i18n.t("Transaction No")}</th>
                            <th className="text-center" width="30%">{i18n.t("Total Invoice")}</th>
                            <th className="text-center" width="30%">{i18n.t("Nominal")}</th>
                            <th className="text-right"  width="5%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.arrayDetail.map((detail, index) => {
                            return this.renderDetail(detail, index);
                        })}
                        </tbody>
                    </Table>

                    <Button
                        variant="success"
                        onClick={(e) =>
                        this.addArray(
                            {
                            nominal: 0,
                            poCurrent: null,
                            totalInvoice:0
                            
                            },
                            "arrayDetail"
                        )
                        }
                    >
                        {i18n.t("Add Item")}
                    </Button>
                    
                    </div>
                </div>
                :null}
            </form>
           </React.Fragment>
        );
    }
}

export default FormDP;
import { useParams } from "react-router-dom";
import React from "react";
import PoContainer from '../../../containers/Print/default/Po'
export const PrintPo = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <PoContainer poid={id}/>
    )
}

export default PrintPo;
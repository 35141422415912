import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import ProvinceActions from '../../../../reducers/modules/master/province/actions';

const url=process.env.REST_URL +'master/province'

export default class ProvinceMiddleware {  

  static create(){
    return (dispatch) => {
      dispatch(ProvinceActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      dispatch(ProvinceActions.update());
      ProvinceMiddleware.showOne(dispatch, params);
    };
  }

  static list(params){
      return (dispatch) => {
          dispatch(ProvinceActions.process());
          ProvinceMiddleware.showlist(dispatch, params);
        };
  }
  static reset(){
    return (dispatch) => {
        dispatch(ProvinceActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(ProvinceActions.view());
        ProvinceMiddleware.showOne(dispatch, params);
      };
  }

  static beforeSaveNew(post,search){
    return (dispatch) => {
        dispatch(ProvinceActions.submit());
        ProvinceMiddleware.saveNew(dispatch, post,search);
      };
  }
  static beforeSaveUpdate(params,post,search){
    return (dispatch) => {
        dispatch(ProvinceActions.submit());
        ProvinceMiddleware.saveUpdate(dispatch, params, post,search);
      };
  }
  static beforeDelete(params,id){
    return (dispatch) => {
        dispatch(ProvinceActions.delete());
        ProvinceMiddleware.delete(dispatch,params,id);
      };
  }

  static getHelper(){
    return (dispatch) => {
      ProvinceMiddleware.showHelper(dispatch);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static showlist(dispatch, params){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
          },
          url: url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          
          if (response && response.status === 200 && response.data) {
            dispatch(ProvinceActions.list(response.data.result));
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }
  static showHelper(dispatch){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-province' ,
        responseType: 'json'
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(ProvinceActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ProvinceActions.view(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static delete(dispatch, params,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
        },
        url: url +'/'+id,
        responseType: 'json'
      })
      .then((response) => {
       
        if (response && response.status === 200 && response.data) {
          dispatch(ProvinceActions.process(),ProvinceMiddleware.showlist(dispatch, params));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static saveNew(dispatch, post,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ProvinceActions.process(),ProvinceMiddleware.showlist(dispatch,search));
          dispatch(ProvinceActions.success());
        }
      })
      .catch((error) => {
        dispatch(ProvinceActions.error(error.data.message));
      });
  }

  static saveUpdate(dispatch, params, post, search){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${ProvinceMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ProvinceActions.process(),ProvinceMiddleware.showlist(dispatch,search));
          dispatch(ProvinceActions.success());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

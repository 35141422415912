import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = cookieManager.getUserToken();

export class DetailMemoOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      change: [],
      check: 0,
    };
  }

  filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
        })
    }
    return data
  }
  render() {
    const { choose } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)

    if (choose != null && this.state.check == 0) {
      this.state.header = choose.log_value.head;
      this.state.detail = choose.log_value.detail;
    }
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-12">
          <Table rules="cols">
            <thead></thead>
            <tbody>
              <tr style={{ fontSize: "15px", fontWeight: "bold" , padding: "5", textAlign:'left'}}>
                <td></td>
                <td></td>
                <td>Journal Transaction - Memo Out</td>
              </tr>
             <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Branch Name")}</td>
                <td>:</td>
                <td>{this.state.header.branch? this.state.header.branch: "-"}</td>
              </tr>
              <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Journal No.")}</td>
                <td>:</td>
                <td>{this.state.header.no_journal? this.state.header.no_journal: "-"}</td>
              </tr>
              <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Journal Date")}</td>
                <td>:</td>
                <td>{this.state.header.date? this.state.header.date: "-"}</td>
              </tr>
              <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Debt")}</td>
                <td>:</td>
                <td>{this.state.header.debt? this.state.header.debt: "-"}</td>
              </tr>
              <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Credit")}</td>
                <td>:</td>
                <td>{this.state.header.credit? this.state.header.credit: "-"}</td>
              </tr>
              <tr style={{ fontSize: "13px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Description")}</td>
                <td>:</td>
                <td>{this.state.header.description? this.state.header.description: "-"}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <hr/>
      <div className="row">
      <h3 className="text-center" style={{ fontSize: "15px", fontWeight: "bold" , padding: "5", textAlign:'left'}}>{i18n.t('Detail')}</h3>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{i18n.t('Ledger')}</th>
            <th>{i18n.t('Helper Book')}</th>
            <th>{i18n.t('Debt')}</th>
            <th>{i18n.t('Credit')}</th>
            <th>{i18n.t('Note')}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.detail.map((log,i)=>{
              return (
                <tr key={'a'+i}>
                  <td>{i+1}</td>
                  <td>{log.coa3Name}</td>
                  <td>{log.coa4Name}</td>
                  <td>{log.debt}</td>
                  <td>{log.credit}</td>
                  <td>{log.description}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      </div>
    </React.Fragment>
    )
  }
}

export default DetailMemoOut;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}
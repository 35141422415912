export default class MarketingActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_MARKETING='GET_HELPER_MARKETING';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static submit(){
    return {
      type: MarketingActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: MarketingActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: MarketingActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: MarketingActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: MarketingActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: MarketingActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: MarketingActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: MarketingActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: MarketingActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: MarketingActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: MarketingActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: MarketingActions.GET_HELPER_MARKETING,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: MarketingActions.ERROR_SERVER,
      payload: error
    };
  }
}
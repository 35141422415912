import BillingActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataBilling: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  dataPayment:{},
  dataBilling:{},
  dataIndex:{},
  dataPayBilling:{},
  isNewRecord:false,
  showModal:false,
  showModalBilling:false,
  onSubmit:false,
  single:null,
  helperBilling:null,
  billingHelperFull:null,
  printBilling:null
};

function BillingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BillingActions.RESET_LIST:
      return INITIAL_STATE;
    case BillingActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModalBilling:true,dataBilling: [], errorMessage: []};
    case BillingActions.NEW_PAYMENT:
      return { ...state, isProcessing: false,single:null,isNewRecord:true,showModal:true,dataPayBilling: action.payload, errorMessage: []};
    case BillingActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModalBilling:false};
    case BillingActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModalBilling:true};
    case BillingActions.RESET_DATA:
      return { ...state, isProcessing: false,isError: false,errorMessage: [], form:INITIAL_STATE.form,showModal:false};
    case BillingActions.RESET_DATA_BILL:
      return { ...state, isProcessing: false,isError: false, errorMessage: [],form:INITIAL_STATE.form,showModalBilling:false};  
    case BillingActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case BillingActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case BillingActions.CANCEL_DATA:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    case BillingActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
   case BillingActions.SHOW_DATA_PAYMENT:
      return {...state, errorInternal:false,onSubmit:false, isError: false, dataPayment: action.payload, errorMessage: [],isProcessing:false };
    case BillingActions.SHOW_DATA_BILLING:
        return { ...state, errorInternal:false,onSubmit:false, isError: false, dataBilling: action.payload, errorMessage: [],isProcessing:false };      
    case BillingActions.SHOW_DATA_INDEX:
        return { ...state, errorInternal:false,onSubmit:false, isError: false, dataIndex: action.payload, errorMessage: [],isProcessing:false };      
    case BillingActions.SHOW_DATA_PAY_BILLING:
        return { ...state,isShow:true, errorInternal:false,onSubmit:false, isError: false,dataPayBilling: action.payload, errorMessage: [],isProcessing:false };      
    case BillingActions.SUCCESS_DATA_PAYMENT:
      return { ...state,dataPayBilling:{}, isError: false,form:INITIAL_STATE.form , errorMessage: [],onSubmit:false,showModal:false};
    case BillingActions.SUCCESS_DATA:
      return { ...state,isProcessing:false ,isError: false,showModal:false,showModalBilling:false,form:INITIAL_STATE.form , onSubmit:false};
    case BillingActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false,errorMessage: []};
    case BillingActions.ERROR_DATA:
      return { ...state, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case BillingActions.GET_HELPER_BILLING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperBilling:action.payload};
    case BillingActions.BEFORE_PRINT_BILLING:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printBilling:null,isProcessing:true};
    case BillingActions.PRINT_BILLING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printBilling:action.payload};
    case BillingActions.BEFORE_SALESBILLING:
      return { ...state, isProcessing: false,showModal:false,type:action.payload,dataBilling:[]};
    case BillingActions.AFTER_SALESBILLING:
      return { ...state, isProcessing: false,showModal:true,dataBilling:action.payload,isNewRecord:false,onSubmit:false};
    case BillingActions.LIST_REPAYMENT:
      return { ...state, isProcessing: false,showModal:true,dataRepayment:action.payload,isNewRecord:false,onSubmit:false};
    case BillingActions.CLOSING_DATA:
        return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModalBilling:false,showModal:false};
    case BillingActions.VOID_DATA_PAYMENT:
        return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form};
    default:
      return state;
    }
}


export default BillingReducer;

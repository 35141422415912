export default class  CashFlowActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_CASHFLOW='GET_HELPER_CASHFLOW';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static PRINT_CASHFLOW='PRINT_CASHFLOW';
  static BEFORE_PRINT_CASHFLOW='BEFORE_PRINT_CASHFLOW';

  static submit(){
    return {
      type:  CashFlowActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type:  CashFlowActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type:  CashFlowActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type:  CashFlowActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type:  CashFlowActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type:  CashFlowActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type:  CashFlowActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type:  CashFlowActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type:  CashFlowActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type:  CashFlowActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type:  CashFlowActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type:  CashFlowActions.GET_HELPER_CASHFLOW,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type:  CashFlowActions.ERROR_SERVER,
      payload: error
    };
  } 
  static beforePrint(){
    return {
      type: CashFlowActions.BEFORE_PRINT_CASHFLOW,
    };
  }
  static print(printCashFlow){
    return {
      type: CashFlowActions.PRINT_CASHFLOW,
      payload: printCashFlow
    };
  }
}
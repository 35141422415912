import React, { Component } from 'react';
import Table from './table';
import Breadcrumbs from '../../../utilities/breadcrumbs';
import { Button } from 'react-bootstrap';
import FormContainer from '../../../../containers/modules/sales/cash-sales/form'
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckToSlot, faPrint, faRemove, faSync } from '@fortawesome/free-solid-svg-icons';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../../i18n';

const MySwal = withReactContent(Swal)
export class CashSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                limit: 10
            },
            titleModal: '',
            listData: [],
            breadcrumbs: [
                { link: process.env.APP_URL, title: i18n.t('Home'), class: ' text-gray-600' },
                { link: null, title: i18n.t('Sales'), class: ' text-gray-600' },
                { link: null, title: i18n.t('Reconcile Cash Sales'), class: ' text-gray-500' },
            ],
            columns: [
                { key: 'date_format', filter: { active: true, type: 'Date', data: null }, title: i18n.t('Date'), type: 'date', tHclass: 'min-w-125px', tdClass: '' },
                { key: 'sales_name', filter: { active: true, type: 'Text', data: null }, title: i18n.t('Sales Name'), type: 'text', tHclass: 'min-w-125px', tdClass: '' },
                { key: 'journalNo', filter: { active: true, type: 'Text', data: null }, title: i18n.t('No Journal'), type: 'text', tHclass: 'min-w-125px', tdClass: '' },
                { key: 'nominal_format', filter: { active: false, type: 'Text', data: null }, title: i18n.t('Nominal'), type: 'text', tHclass: 'min-w-125px', tdClass: '' },
                {
                    key: 'status', filter: { active: true, type: 'Select', data: [{key:"",label:"Status"},{key:1,label:"Not Reconcile"},{key:2,label:"Reconcile"}] }, title: i18n.t('Status'), type: 'component', tHclass: 'min-w-125px', tdClass: '', renderFunc: (data,fulldata) =>
                        <React.Fragment>
                            {fulldata.status==1 && <div className="badge badge-light-warning">{i18n.t("Not Reconcile")}</div>}
                            {fulldata.status==2 && <div className="badge badge-light-success">{i18n.t("Reconciled")}</div>}
                            {fulldata.status==0 && <div className="badge badge-light-danger">{i18n.t("Void")}</div>}
                        </React.Fragment>
                },
                {
                    key: 'id', filter: { active: false, type: 'Text', data: null }, title: i18n.t('Action'), type: 'component', tHclass: 'min-w-125px', tdClass: '', renderFunc: (data,fulldata) =>
                        
                        <React.Fragment>
                            {fulldata.status==1 && <Button variant='success' className='btn btn-sm m-2' onClick={() => {
                                        this.props.Reconcile(fulldata)
                                    }}>
                                <FontAwesomeIcon icon={faCheckToSlot}></FontAwesomeIcon>
                                {i18n.t("Reconcile")}
                            </Button>}
                            {fulldata.status==2 && <Button variant='danger' className='btn btn-sm m-2' onClick={() => {
                                        
                                        const html = (
                                            <>
                                              <div>{i18n.t('You will void this transaction')}</div>
                                              <Button variant="warning" onClick={() => MySwal.close()}>
                                                {i18n.t('Cancel')}
                                              </Button>
                                              <Button
                                                variant="danger"
                                                onClick={() => {
                                                    this.props.void(data,this.state.search)
                                                  MySwal.update({
                                                    title: i18n.t("Void Data"),
                                                    html: i18n.t("Please Wait ...."),
                                                    icon: "info",
                                                  });
                                                  MySwal.showLoading();
                                                }}
                                              >
                                                {i18n.t("Void")}
                                              </Button>
                                            </>
                                          );
                                          MySwal.fire({
                                            title: i18n.t("Are you sure?"),
                                            html: html,
                                            icon: "question",
                                            showCancelButton: false,
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                          });
                                       
                                    }}>
                                <FontAwesomeIcon icon={faRemove}></FontAwesomeIcon>
                                {i18n.t("Void")}
                            </Button>}
                        </React.Fragment>
                },
            ],
            expandItem: {
                key: "details",
                columns: [
                    { label: i18n.t("No Nota"), key: "transaction_no" },
                    { label: i18n.t("Description"), key: "description" },
                    { label: i18n.t("Nominal"), key: "nominal_format" ,thClass: "text-end", tdClass: "text-end" },
                ],
            }
        };
        this.tablePaymentSelling = React.createRef()
    }
   
    runSearch() {
        let search = this.tablePaymentSelling.current.getStateSearch();
        this.props.search(search);
    }
    render() {
        const { showModal } = this.props
        return (
            <div id="invoice-page">
                <Breadcrumbs
                    url={this.state.breadcrumbs}
                    tools={false}
                />
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end border-0 pt-6">
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                        <Button type="button" className="btn btn-warning" onClick={(e) => this.runSearch()}><FontAwesomeIcon icon={faSync}></FontAwesomeIcon></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <Table {...this.props} runSearch={() => { this.runSearch() }} columns={this.state.columns} theExpand={this.state.expandItem} ref={this.tablePaymentSelling}></Table>
                            </div>
                        </div>
                    </div>
                </div>
                <FormContainer
                    showModal={showModal}
                    search={this.state.search}
                    titleModal={this.state.titleModal}
                />
            </div>
        );
    }
}

export default CashSales;

import {
  faTrash,faPlus,faPerson
} from "@fortawesome/free-solid-svg-icons";
import React, { Component, Fragment } from "react";
import Select2 from "../../../utilities/Form/Select2";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import "react-form-wizard-component/dist/style.css";
import InputNumber from "../../../utilities/Form/InputNumber";
import InputCustom from "../../../utilities/Form/InputCustom";
import Swal from "sweetalert2";
import { current } from "@reduxjs/toolkit";
import { Button } from "react-bootstrap";
import { arrayMove } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class FormAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        TargetAssignment: {},
        TargetAssignmentDetail: [],
      },
      arrayForm:[
        {
          targetCurrent:null,
          item:[],
          // dataTarget:[]
        }
      ],
      dataTarget:[],
      name:'',
      arrId:[],
      input:false,
      date: new Date(),
      startDateDaily: new Date(),
      endDateDaily: new Date(),
      startDateWeekly: new Date(),
      endDateWeekly: new Date(),
      dateDaily:new Date(),
      periodEndList : [],
      periodStartCurrent: null,
      periodEndCurrent: null,
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      arrayDetail: [],
      loadingSales:false,
      positionList : [],
      positionCurrent: null,
      positionText: i18n.t("Select Position"),
      salesList : [],
      salesCurrent: null,
      salesText: i18n.t("ALL"),
      branchTargetCurrent: null,
      branchTargetText: i18n.t("Select Branch"),
      branchTargetList: [],
      isUpdate: 0,
      status: 0,
    };

    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerNumber(value,name) {
    var key = name;
    var value = value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.state.arrId.splice(i,1);
    console.log(this.state.arrId)
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  runSubmit() {
    const TargetAssignment = {
        idBranch:
          this.state.branchTargetCurrent != null ? this.state.branchTargetCurrent.value : null,
        idPosition:
          this.state.positionCurrent != null ? this.state.positionCurrent.value : null,  
        idSales:
          this.state.salesCurrent != null ? this.state.salesCurrent.value :'', 
        // idTarget:
        //   this.state.targetCurrent != null ? this.state.targetCurrent.value : null  ,
        status: 1,
    };
      let array = []
      let item = [];
      let detail = this.state.arrayForm;
      for(var p = 0; p<detail.length;p++){
        let target = null;
        target = (detail[p].targetCurrent!=null?detail[p].targetCurrent.value : null);
        console.log('target',target)

        item = detail[p].item;
        let value = [];
        if(item != null){
          for(var h = 0; h<item.length;h++){
            for(var i = 0; i<item[h].detail.length;i++){
              let det = item[h].detail;
              value.push({
                itemValue: (det[i].idItem != null ? det[i].idItem : null), qty:(det[i].qty), nominal:(det[i].nominal) , idInfo : det[i].idCode
              })
            }
          }
          array.push({idTarget:target,item:value})
        }
      }
      this.state.form.TargetAssignment = TargetAssignment;
      this.state.form.TargetAssignmentDetail = {
        target:array
      };

    return this.state.form;
  }

  handlerSelectPeriod(e, name, currentState) {
    var key = name
    var value = null
    let list = [];
    let dataList = [];

    dataList =this.state.periodList;
   
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }
  
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  handlerSelectArray(e, name, i, arrayname) {
    let array =[...this.state.arrId]
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    
    if (e == null) {
      objectss[name] = null;
      objectss['id'] = null;
      objectss['dataTarget'] = null;
      objectss['item'] = [];
      this.state.arrId.splice(i,1);
    } else {
          this.state.arrId.splice(i,1);
    
      objectss[name] = e;
      objectss['id'] = e.value
        const filtered = this.state.arrId.indexOf(e.value);
        if(filtered !== -1){
          this.alertError('Info','Data Are Used, Please Chose another')
          objectss[name] = null;
          objectss['dataTarget'] = null;
          objectss['id'] = null;
          this.state.arrId.splice(i,1);

        }else{
          
          objectss['item'] = [];
          this.state.arrId.push(e.value)
          this.changeData({
                keyFill:"dataTarget",
                type:"getTargetSales",
                key:"",
                array:"arrayForm",
                arraykey:i,
                callback: () => { this.fetchDataTarget(i)}
             
              });
        }
    }
    console.log(this.state.arrId)
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i,p,k, arrayname) {
    console.log(i)
    console.log(k)
    console.log(p)
    var arr = this.state[arrayname][i]['item'];
    const objectss = arr[k]['detail'];
    const obj = objectss[p];
    obj[name] = e;
    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  
  
  renderDetail(detail,i) {
    let html = [];
    html.push(<h3>{detail.name}</h3>);
    if(detail != null ){
      let item = detail.item;
      html.push(
        <Fragment>
        <table>
          <thead>
              <tr style={{paddingLeft:'50px'}}>
                <th width={"20%"}>{i18n.t("Name Item")}</th>
                <th width={"20%"}>{i18n.t("Qty")}</th>
                <th width={"20%"}>{i18n.t("Nominal")}</th>
              </tr>
            </thead>
          <tbody>
          {this.dataTable(item,i)}
          </tbody>
        </table>
        <br/>
        </Fragment>
      );
  }
  return html;

}

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token=cookieManager.getUserToken()
    switch (type) {
      case "getSales":
        var url = process.env.REST_URL + "helper/get-position-sales";
        if(this.state.branchTargetCurrent==null){
          type=null;
        }
        params = {
          branch: this.state.branchTargetCurrent !=null?this.state.branchTargetCurrent.value:null,
          position: this.state.positionCurrent !=null?this.state.positionCurrent.value:null,
          type:1
        };
        break;

      case "getTarget":
        var url = process.env.REST_URL + "helper/get-target";
        if(this.state.branchTargetCurrent==null){
          type=null;
        }
        params = {
          branch: this.state.branchTargetCurrent !=null?this.state.branchTargetCurrent.value:null,
          startDate : this.state.periodStartCurrent == null?'':this.state.periodStartCurrent.value,
          endDate : this.state.periodEndCurrent == null?'':this.state.periodEndCurrent.value,
        };
        break;

      case "getTargetSales":
        var url = process.env.REST_URL + "helper/get-target-sales";
        if( this.state[array][arraykey] == null &&  this.state[array][arraykey] == []){
          type=null;
        }else{
          // for(var i=0;i<this.state.targetCurrent.length;i++){
          //   target.push(this.state.targetCurrent[i] !=null?this.state.targetCurrent[i].value:null);
          // }
          
        }
        
        params = {
          target : this.state[array][arraykey] != null ? this.state[array][arraykey].targetCurrent.value : null
        };
        break;
     
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  
  setInput(){
    this.setState({
      input : !this.state.input
    })
  }

  fetchDataTarget(i) {
   
    var data = [...this.state.arrayForm];
    const objectss = data[i];
    if(objectss.dataTarget != null && objectss.dataTarget != []){
      let det = objectss['dataTarget']
          for(var k=0; k<det.length;k++){
            let detail = det[k].detail;
            let arr = [];
            for(var p=0; p<detail.length;p++){
              arr.push({
                    idItem:detail[p].idItem,
                    nameItem:detail[p].item.name,
                    qty:parseFloat(detail[p].qty)||0,
                    nominal:parseFloat(detail[p].revenue)||0,
                    code:detail[p].info.code,
                    idCode:detail[p].info.id
              });
              
            }
            objectss['item'].push({
              nameItem : det[k].name_item,
              detail:arr
            })
          }
    this.setState(data)
  }
}

  dataTable(det,i){
    let html = [];
    console.log(det)
    if(det.item != null && det.item != []){
      let row = det.item;
      for(var k=0;k<row.length;k++){
        const objectss = row[k]
        objectss['index'] = k
        html.push(
        <Fragment key={"header"+k}>
          <h3>{i18n.t('Name Item : ') + row[k].nameItem}</h3>
        </Fragment>
        );
        for(var p=0; p<row[k].detail.length;p++){
          const obj = row[k].detail[p]
          obj['index'] = p; 
          html.push(
            <tr key={"detail"+k+p}>
            {/* <td>
                <input
                    type={"input"}
                    name={"nameItem"}
                    title={i18n.t("Name Item")}
                    required
                    labelfield={"false"}
                    value={det.item[p].nameItem}
                    className="form-control  form-control-sm "
                    disabled
                />
            </td> */}
            <td>
                <input
                    type={"input"}
                    name={"codeInfo"}
                    title={i18n.t("Code")}
                    required
                    labelfield={"false"}
                    value={row[k].detail[p].code}
                    className="form-control  form-control-sm "
                    disabled
                />
            </td>
            <td >
            <InputNumber
                id={"qty"}
                name="qty"
                placeholder={i18n.t("Qty")}
                defaultValue={0}
                value={row[k].detail[p].qty}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  this.handlerCurrencyArray(value,name,i,obj['index'],objectss['index'],'arrayForm')
                }
                className="form-control  form-control-sm text-end"
                labelfield={"false"}
              />
            </td>
            <td>
              <InputNumber
                id={"nominal"}
                name="nominal"
                title={i18n.t("Nominal")}
                placeholder={i18n.t("Nominal")}
                defaultValue={0}
                value={row[k].detail[p].nominal}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  this.handlerCurrencyArray(value,name,i,obj['index'],objectss['index'],'arrayForm')
                }
                className="form-control  form-control-sm text-end"
                labelfield={"false"}
                prefix="Rp "
            />
            </td>
          </tr>
          );
        }
      }      
      
    }
    return html;
  }
  

  detail(det,i){
    return(
      <Fragment key={"det"+i}>
        <div className="row">
            <div className="col-md-3 mt-3">
                <Select2
                  isClearable={true}
                  name={"idTarget"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                    if(e != null ){
                      await this.handlerSelectArray(e, "targetCurrent",i,"arrayForm");
                    }else{
                      await this.handlerSelectArray(null, "targetCurrent",i,"arrayForm");
                    }
                  }}
                  options={this.state.targetList}
                  title={i18n.t("Target")}
                  value={det.targetCurrent}
                  placeholder={this.state.targetText}
             
                />
                </div>
                
                <div className="col-md-2 mt-12">
                      {/* <span className='get-inline '>  
                          <InputCustom
                              type={"checkbox"}
                              className={
                                  "form-check-input form-check form-check-custom form-check-solid border-check"
                              }
                              id={"showDetail"}
                              value={this.state.showDetail}
                              name={"showDetail"}
                              labelfield={"false"}
                              onChange={
                                  () => this.setInput()
                              }
                          />
                      </span>
                          &nbsp;&nbsp;
                      <span className='get-inline'>
                          <label className='label-control ' htmlFor='byDetail'>{i18n.t("All")}</label>
                      </span>    */}
                    </div>
                    <div className="col-md-3 mt-3">
                      <Button
                        variant="danger"
                        className="btn btn-sm"
                        onClick={(e) => this.removeArray(i, "arrayForm")}
                      >
                      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                      </Button>
                    </div>
                    </div>
                    {det.targetCurrent!=null?
                    <div className="row">
                      <table style={{paddingLeft:'50px'}}>
                      <thead>
                          <tr>
                            <th width={"20%"}>{i18n.t("Code Distribution")}</th>
                            <th width={"20%"}>{i18n.t("Qty")}</th>
                            <th width={"20%"}>{i18n.t("Nominal")}</th>
                          </tr>
                        </thead>
                      <tbody>
                         {this.dataTable(det,i)}
                    </tbody>
                  </table>
              </div>
              :null}
              <br/>
              <hr/>
      </Fragment>
    );
  }

  render() {
    const { data, isNewRecord, helperSalesTarget } = this.props;
    console.log('STATE',this.state)
    if(helperSalesTarget!= null){
      this.state.branchList =
      helperSalesTarget != null ? helperSalesTarget.branch : [];
      this.state.positionList =
      helperSalesTarget != null ? helperSalesTarget.position : [];
      this.state.periodList = 
      helperSalesTarget != null ? helperSalesTarget.workCalendar : [];
    }

    return(
      <form>
          <div className="row">
            <div className="col-sm-2 mt-3">
              <Select2
                isClearable={true}
                name={"period"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                  await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
                }}
                options={this.state.periodList}
                title={i18n.t("Week Period")}
                value={this.state.periodStartCurrent}
                placeholder={this.state.periodText}
              />
            </div>
            <div style={{width:'35px'}} className="col-sm-1 mt-12">
              {i18n.t("To")}
            </div>
            <div className="col-sm-2 mt-3">
            <Select2
                isClearable={true}
                name={"periodEnd"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
                  if(this.state.branchTargetCurrent != null){
                    await this.setState({
                     targetCurrent:null,targetList:[],arrayDetail:[]
                    });
                    this.changeData({
                      keyFill:"targetList",
                      type:"getTarget",
                      key:"",
                    })
                  }
                }}
                options={this.state.periodList}
                title={i18n.t(" ")}
                value={this.state.periodEndCurrent}
                placeholder={this.state.periodText}
              />
            </div>
            <div className="col-md-2 mt-3">
              <Select2
                isClearable={true}
                name={"idBranchTarget"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idBranchTarget", "branchTargetCurrent");
                  await this.setState({
                    salesList:[],salesCurrent:null,positionCurrent:null,targetCurrent:null,targetList:[],arrayDetail:[]
                  });
                  this.changeData({
                    keyFill:"targetList",
                    type:"getTarget",
                    key:"",
                    // callback: () => { this.setState({ loadingSales: false }) }
                  })
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchTargetCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("idBranch")}
              />
            </div>
            <div className="col-md-2 mt-3">
              <Select2
                isClearable={true}
                name={"idPosition"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idPosition", "positionCurrent");
                  await this.setState({
                    loadingSales: true, salesList:[],salesCurrent:null
                  });
                  this.changeData({
                    keyFill:"salesList",
                    type:"getSales",
                    key:"",
                    callback: () => { this.setState({ loadingSales: false }) }
                  })
                }}
                options={this.state.positionList}
                title={i18n.t("Position")}
                value={this.state.positionCurrent}
                placeholder={this.state.positionText}
                error={this.renderError("idPosition")}

              />
            </div>
            <div className="col-md-2 mt-3">
              <Select2
                isClearable={true}
                name={"idSales"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }}
                options={this.state.salesList}
                title={i18n.t("Sales")}
                value={this.state.salesCurrent}
                placeholder={this.state.salesText}
                isLoading = {this.state.loadingSales}
                // error={this.renderError("SalesTarget.idBranch")}
              />
            </div>
          </div>
          <hr/>
          <div className="row">
                  {this.state.arrayForm.map((det, index) => {
                      return this.detail(det, index);
                  })}
          </div>
          <div className="row">
            <div className="col-md-5 mt-8 ">
            <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      targetCurrent:null,
                      item:[],
                      // dataTarget:[]
                    },
                    "arrayForm"
                  )
                }
              >
            <FontAwesomeIcon icon={faPlus}> </FontAwesomeIcon>
            {i18n.t(" Add Target")}
            </Button>
          </div>
        </div>
        </form>
    );
  }
}




export default FormAssign;

import ProvinceActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: {},
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperProvince:null
};

function ProvinceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ProvinceActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ProvinceActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case ProvinceActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case ProvinceActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ProvinceActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ProvinceActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case ProvinceActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: {},isProcessing:false };
    case ProvinceActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case ProvinceActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case ProvinceActions.GET_HELPER_PROVINCE:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperProvince:action.payload};
    default:
      return state;
    }
}

export default ProvinceReducer;

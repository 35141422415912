import React, { Component } from "react";
import Input from "../../../utilities/Form/input";
import { Button, Modal, Table } from "react-bootstrap";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import InputCustom from "../../../utilities/Form/InputCustom";
import FormPrice from "./formPrice";
import i18n from "../../../../i18n";
import TextArea from "../../../utilities/Form/TextArea";
import cookieManager from "../../../../utils/cookieManager";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ReturnPrice: {},
        ReturnPriceDet: [],
      },
      typeList:[],
      idBranch: null,
      idStore: null,
      typeCurrent: null,
      branchCurrent: null,
      storeCurrent: null,
      date: new Date(),
      saveDate: new Date(),
      saveBranch: null,
      saveStore: null,
      saveType: null,
      count: 0,
      arrayDetail: [
        {
          idItem: null,
          idUnit: null,
          price: null,
          itemValue: null,
          unitValue: null,
          changePrice: null,
        },
      ],
      description: '',
      branchText: i18n.t("Select Branch"),
      storeText: i18n.t("Select Store"),
      itemText: i18n.t("Select Item"),
      unitText: i18n.t("Select Unit"),
      typeCustomerText: i18n.t("Select Type Customer"),
      isUpdate: 0,
    };
    this.formPrice = React.createRef(),
      this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      [key]: value,
      [currentState]: e,
    });
  }

  runSubmit() {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let saveDate = (this.state.saveDate == null) ? '' : moment(this.state.saveDate).format("YYYY-MM-DD");
    const ReturnPrice = {
      idBranch: this.state.idBranch,
      idStore: (this.state.storeCurrent == null) ? '' : this.state.storeCurrent.value,
      idType: (this.state.typeCurrent == null) ? '' : this.state.typeCurrent.value,
      date: date,
      saveBranch: this.state.saveBranch,
      saveStore: this.state.saveStore,
      saveType: this.state.saveType,
      saveDate: saveDate,
      description: this.state.description
    };
    const ReturnPriceDet = this.state.arrayDetail;
    let idItem = [];
    let price = [];
    let idUnit = [];

    for (var i = 0; i < ReturnPriceDet.length; i++) {
      idItem.push((ReturnPriceDet[i].itemValue == null) ? '' : ReturnPriceDet[i].itemValue.value);
      idUnit.push((ReturnPriceDet[i].unitValue == null) ? '' : ReturnPriceDet[i].unitValue.value);
      price.push((ReturnPriceDet[i].price == null) ? '' : ReturnPriceDet[i].price);
    }
    this.state.form.ReturnPrice = ReturnPrice;
    this.state.form.ReturnPriceDet = {
      idItem: idItem,
      idUnit: idUnit,
      price: price,
    };
    // this.state.isUpdate = 0;
    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  setPrice(detail, key) {
    let choose = {
      key: key,
      array: detail,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }

  saveUpdatePrice(status) {
    if (status) {
      let result = this.formPrice.current.runSubmit();
      for (var i = 0; i < result.price.length; i++) {
        this.handlerCurrencyArray(
          result.price[i].toFixed(2),
          "price",
          i,
          "arrayDetail"
        );
      }

      this.setState({ modalDetail: false });
    } else {
      this.setState({ modalDetail: false });
    }
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props
    return <React.Fragment key={"detail" + i}>
      <>
        <tr>
          <td>
            <Select
              isClearable={true}
              name={"idItem"}
              required
              onChange={(e) =>
                this.handlerSelectArray(e, "idItem", i, "arrayDetail", "itemValue")
              }
              options={this.state.itemList}
              title={i18n.t("Item")}
              value={detail.itemValue}
              placeholder={this.state.itemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReturnPriceDet.idItem." + i)}
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idUnit"}
              required
              onChange={(e) =>
                this.handlerSelectArray(e, "idUnit", i, "arrayDetail", "unitValue")
              }
              options={this.state.unitList}
              title={i18n.t("Unit")}
              value={detail.unitValue}
              placeholder={this.state.unitText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReturnPriceDet.idUnit." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"price" + i}
              name="price"
              placeholder={i18n.t("Price")}
              defaultValue={0}
              value={detail.price}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "price", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
              required
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReturnPriceDet.price." + i)}
            </div>
          </td>
          {this.state.isUpdate == 1 ? (
            <td>
              <InputCustom
                type={"checkbox"}
                className={
                  "form-check-input form-check form-check-custom form-check-solid"
                }
                name={"changePrice"}
                labelfield={"false"}
                value={detail.changePrice == 1 ? 0 : 1}
                onChange={(value) =>
                  this.handlerInputArray(value, "changePrice", i, "arrayDetail")
                }
              />
            </td>
          ) : (
            ""
          )}
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </>
    </React.Fragment>
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;

    }
    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    const {
      data,
      isNewRecord,
      helperReturnPrice,
    } = this.props;
    this.state.branchList =
      helperReturnPrice != null ? helperReturnPrice.branch : "";
    this.state.typeList =
      helperReturnPrice != null ? helperReturnPrice.typeCustomer : "";
    this.state.itemList =
      helperReturnPrice != null ? helperReturnPrice.item : "";
    this.state.unitList =
      helperReturnPrice != null ? helperReturnPrice.unit : "";


    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.idBranch = data.id_branch
        this.state.saveBranch = (data.id_branch == null) ? '' : data.id_branch
        this.state.saveStore = (data.id_store == null) ? '' : data.id_store
        this.state.saveType = (data.id_type == null) ? '' : data.id_type
        this.state.saveDate = new Date(data.date)
        this.state.date = new Date(data.date)
        this.state.branchCurrent = {
          value: data.id_branch,
          label: data.name_branch
        },
        this.state.idStore = data.id_store
        this.state.storeCurrent = (data.id_store == null) ? null: { value: data.id_store, label: data.name_store }
        this.state.typeCurrent = (data.id_type == null) ? null: { value: data.id_type, label: data.type_customer }
        if (data.id_branch != null) {
          this.changeData('storeList', 'getStore', '');
        }
        this.state.description = (data.description == null) ? '' : data.description
        this.state.arrayDetail = [];
        for (var po = 0; po < data.details.length; po++) {
          this.state.arrayDetail.push({
            idItem: data.details[po].id_item,
            idUnit: data.details[po].id_unit,
            price: data.details[po].price,
            itemValue: {
              value: data.details[po].id_item,
              label: data.details[po].name_item,
            },
            unitValue: {
              value: data.details[po].id_unit,
              label: data.details[po].name_unit,
            },
          });
        }
        this.state.isUpdate = 1;
      }
    }

    return (
      <form>
        <React.Fragment>
          <div className="row">
            <div className="col-md-3">
              <Select2
                isClearable
                name={"idBranch"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ storeList: [], storeCurrent: null })

                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ storeList: [], storeCurrent: null })
                    await this.changeData("storeList", "getStore", '');
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                currentState={"branchCurrent"}
                error={this.renderError("ReturnPrice.idBranch")}
              />
            </div>
            <div className="col-md-3">
              <Select2
                isClearable
                name={"idStore"}
                onChange={(e) =>
                  this.handlerSelect(e, "idStore", "storeCurrent")
                }
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
                currentState={"storeCurrent"}

              />
            </div>
            <div className="col-md-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Effecticve Date")}
                required={true}
                selected={this.state.date}
                onChange={(e) => this.handlerDateTime(e, "date")}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                endDate={new Date()}
                showYearDropdown
                className="form-control form-control-sm"
                error={this.renderError("ReturnPrice.date")}
              />
            </div>
          </div>
            
            <div className="row">
              <div className="col-6">
                <TextArea
                  type={"input"}
                  name={"description"}
                  title={i18n.t("Description")}
                  value={this.state.description}
                  handler={this.handler}
                />
              </div>
              <div className="col-md-3">
                <Select2
                  isClearable
                  name={"idType"}
                  required={true}
                  onChange={async (e) => {
                      await this.handlerSelect(e, "idType", "typeCurrent");
                  }}
                  options={this.state.typeList}
                  title={i18n.t("Type Customer")}
                  value={this.state.typeCurrent}
                  placeholder={this.state.typeCustomerText}
                  currentState={"typeCurrent"}
                  error={this.renderError("ReturnPrice.idType")}
                />
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-left">{i18n.t("Item")}</th>
                    <th className="text-left">{i18n.t("Unit")}</th>
                    <th className="text-left">{i18n.t("Price")}</th>

                    <th className="text-left">{i18n.t("Action")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>

              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      idItem: null,
                      idUnit: null,
                      price: 0,
                      itemValue: null,
                      unitValue: null,
                      disabled: false,
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button>
              {this.state.isUpdate == 1 ? (
                <Button
                  variant="info flex-end m-1"
                  onClick={(e) => {
                    this.state.count = 0;
                    for (var i = 0; i < this.state.arrayDetail.length; i++) {
                      if (
                        this.state.arrayDetail[i].changePrice != null &&
                        this.state.arrayDetail[i].changePrice == 1
                      ) {
                        this.state.count += 1;
                      }
                    }

                    (this.state.count == 0)
                      ? this.alertError("Info", "Checklist Item First", "info")
                      : this.setPrice(this.state.arrayDetail, "price");
                  }}
                >
                  {i18n.t("Update Price")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Modal
            show={this.state.modalDetail}
            backdropClassName="next1"
            centered
          >
            <Modal.Header>
              <Modal.Title>{this.state.titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormPrice
                data={this.state.itemChoose}
                isNewRecord={false}
                ref={this.formPrice}
              />
            </Modal.Body>
            <Modal.Footer className={"flex-center"}>
              <Button
                variant="secondary"
                onClick={(e) => this.saveUpdatePrice(false)}
              >
                {i18n.t("Close")}
              </Button>
              <Button
                variant="primary"
                onClick={(e) => this.saveUpdatePrice(true)}
              >
                {i18n.t("Update Price")}
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../utilities/table';
import Breadcrumbs from '../../../utilities/breadcrumbs';

export class District extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      search:{
        limit:10
      },
      name:'',
      tools:true,
      listData:{},
      breadcrumbs:[
        {link:process.env.APP_URL,title:'Home',class:' text-gray-600'},
        {link:null,title:'Master',class:' text-gray-600'},
        {link:null,title:'District',class:' text-gray-500'},
      ],
      column:[
        {key:'name',filter:{active:true,type:'Text',data:null},title:'Name District',type:'text',tHclass:'min-w-125px',tdClass:''},
        {key:'city',filter:{active:true,type:'Text',data:null},title:'Name City',type:'text',tHclass:'min-w-125px',tdClass:''},
        {key:'active',filter:{active:true,type:'Select',data:[{key:1,label:'Active'},{key:0,label:'Non Active'}]},title:'Status',type:'active',tHclass:'min-w-125px',tdClass:''},
        {key:'id',filter:{active:false,type:'Text',data:null},title:'Action',type:'action',tHclass:'text-end min-w-70px',tdClass:''},
       
    
    ]
    };
    this.runSearch=this.runSearch.bind(this)
  }

  runSearch(value){
    this.props.search(value);
  }
  render() {
    this.state.listData=this.props.data
    return (
        <div>
        <Breadcrumbs 
            url={this.state.breadcrumbs}
            tools={false}
        />
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
            <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                    <div className="card-header d-flex justify-content-end border-0 pt-6">
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add Customer Type</button>
                            </div>
                        </div>
                    </div>
                    <Table 
                        data={this.state.listData}
                        tools={false}
                        filterSearch={true}
                        column={this.state.column}
                        searchData={this.state.search}
                        runSearch={this.runSearch}
                    />
                </div>
            </div>
        </div>
          <div className="modal fade show" id="kt_modal_add_customer" tabIndex="-1" aria-hidden="true">
                    
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        
                        <div className="modal-content">
                           
                            <form className="form" action="#" id="kt_modal_add_customer_form" data-kt-redirect="../../demo20/dist/apps/customers/list.html">
                                
                                <div className="modal-header" id="kt_modal_add_customer_header">
                                   
                                    <h2 className="fw-bold">Add District</h2>
                                   
                                    
                                    <div id="kt_modal_add_customer_close" className="btn btn-icon btn-sm btn-active-icon-primary">
                                        
                                        <span className="svg-icon svg-icon-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                            </svg>
                                        </span>
                                       
                                    </div>
                                   
                                </div>
                                
                                
                                <div className="modal-body py-10 px-lg-17">
                                   
                                    <div className="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                                        
                                        <div className="fv-row mb-7">
                                            
                                            <label className="required fs-6 fw-semibold mb-2">Name</label>
                                           
                                            
                                            <input  onChange={(e)=>{console.log(e)}}type="text" className="form-control form-control-solid" placeholder="" name="name" value="Sean Bean" />
                                            
                                        </div>
                                        
                                        
                                        <div className="fv-row mb-7">
                                            
                                            <label className="fs-6 fw-semibold mb-2">
                                                <span className="required">Email</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Email address must be active"></i>
                                            </label>
                                           
                                            
                                            <input  onChange={(e)=>{console.log(e)}}type="email" className="form-control form-control-solid" placeholder="" name="email" value="sean@dellito.com" />
                                            
                                        </div>
                                        
                                        
                                        <div className="fv-row mb-15">
                                            
                                            <label className="fs-6 fw-semibold mb-2">Description</label>
                                           
                                            
                                            <input  onChange={(e)=>{console.log(e)}}type="text" className="form-control form-control-solid" placeholder="" name="description" />
                                            
                                        </div>
                                        
                                        
                                        <div className="fw-bold fs-3 rotate collapsible mb-7" data-bs-toggle="collapse" href="#kt_modal_add_customer_billing_info" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">Shipping Information
                                        <span className="ms-2 rotate-180">
                                            
                                            <span className="svg-icon svg-icon-3">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                           
                                        </span></div>
                                       
                                        
                                        <div id="kt_modal_add_customer_billing_info" className="collapse show">
                                            
                                            <div className="d-flex flex-column mb-7 fv-row">
                                                
                                                <label className="required fs-6 fw-semibold mb-2">Address Line 1</label>
                                               
                                                
                                                <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="" name="address1" value="101, Collins Street" />
                                                
                                            </div>
                                            
                                            
                                            <div className="d-flex flex-column mb-7 fv-row">
                                                
                                                <label className="fs-6 fw-semibold mb-2">Address Line 2</label>
                                               
                                                
                                                <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="" name="address2" value="" />
                                                
                                            </div>
                                            
                                            
                                            <div className="d-flex flex-column mb-7 fv-row">
                                                
                                                <label className="required fs-6 fw-semibold mb-2">Town</label>
                                               
                                                
                                                <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="" name="city" value="Melbourne" />
                                                
                                            </div>
                                            
                                            
                                            <div className="row g-9 mb-7">
                                                
                                                <div className="col-md-6 fv-row">
                                                    
                                                    <label className="required fs-6 fw-semibold mb-2">State / District</label>
                                                   
                                                    
                                                    <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="" name="state" value="Victoria" />
                                                    
                                                </div>
                                               
                                                
                                                <div className="col-md-6 fv-row">
                                                    
                                                    <label className="required fs-6 fw-semibold mb-2">Post Code</label>
                                                   
                                                    
                                                    <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="" name="postcode" value="3000" />
                                                    
                                                </div>
                                               
                                            </div>
                                            
                                            
                                            <div className="d-flex flex-column mb-7 fv-row">
                                                
                                                <label className="fs-6 fw-semibold mb-2">
                                                    <span className="required">Country</span>
                                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Country of origination"></i>
                                                </label>
                                               
                                                
                                                <select value={""} onChange={(e)=>{console.log(e)}} name="country" aria-label="Select a Country" data-control="select2" data-placeholder="Select a Country..." data-dropdown-parent="#kt_modal_add_customer" className="form-select form-select-solid fw-bold">
                                                    <option value="">Select a Country...</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AX">Aland Islands</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AG">Antigua and Barbuda</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="AM">Armenia</option>
                                                    <option value="AW">Aruba</option>
                                                    <option value="AU">Australia</option>
                                                    <option value="AT">Austria</option>
                                                    <option value="AZ">Azerbaijan</option>
                                                    <option value="BS">Bahamas</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="BB">Barbados</option>
                                                    <option value="BY">Belarus</option>
                                                    <option value="BE">Belgium</option>
                                                    <option value="BZ">Belize</option>
                                                    <option value="BJ">Benin</option>
                                                    <option value="BM">Bermuda</option>
                                                    <option value="BT">Bhutan</option>
                                                    <option value="BO">Bolivia, Plurinational State of</option>
                                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                    <option value="BA">Bosnia and Herzegovina</option>
                                                    <option value="BW">Botswana</option>
                                                    <option value="BR">Brazil</option>
                                                    <option value="IO">British Indian Ocean Territory</option>
                                                    <option value="BN">Brunei Darussalam</option>
                                                    <option value="BG">Bulgaria</option>
                                                    <option value="BF">Burkina Faso</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="KH">Cambodia</option>
                                                    <option value="CM">Cameroon</option>
                                                    <option value="CA">Canada</option>
                                                    <option value="CV">Cape Verde</option>
                                                    <option value="KY">Cayman Islands</option>
                                                    <option value="CF">Central African Republic</option>
                                                    <option value="TD">Chad</option>
                                                    <option value="CL">Chile</option>
                                                    <option value="CN">China</option>
                                                    <option value="CX">Christmas Island</option>
                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                    <option value="CO">Colombia</option>
                                                    <option value="KM">Comoros</option>
                                                    <option value="CK">Cook Islands</option>
                                                    <option value="CR">Costa Rica</option>
                                                    <option value="CI">Côte d'Ivoire</option>
                                                    <option value="HR">Croatia</option>
                                                    <option value="CU">Cuba</option>
                                                    <option value="CW">Curaçao</option>
                                                    <option value="CZ">Czech Republic</option>
                                                    <option value="DK">Denmark</option>
                                                    <option value="DJ">Djibouti</option>
                                                    <option value="DM">Dominica</option>
                                                    <option value="DO">Dominican Republic</option>
                                                    <option value="EC">Ecuador</option>
                                                    <option value="EG">Egypt</option>
                                                    <option value="SV">El Salvador</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="ER">Eritrea</option>
                                                    <option value="EE">Estonia</option>
                                                    <option value="ET">Ethiopia</option>
                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                    <option value="FJ">Fiji</option>
                                                    <option value="FI">Finland</option>
                                                    <option value="FR">France</option>
                                                    <option value="PF">French Polynesia</option>
                                                    <option value="GA">Gabon</option>
                                                    <option value="GM">Gambia</option>
                                                    <option value="GE">Georgia</option>
                                                    <option value="DE">Germany</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="GI">Gibraltar</option>
                                                    <option value="GR">Greece</option>
                                                    <option value="GL">Greenland</option>
                                                    <option value="GD">Grenada</option>
                                                    <option value="GU">Guam</option>
                                                    <option value="GT">Guatemala</option>
                                                    <option value="GG">Guernsey</option>
                                                    <option value="GN">Guinea</option>
                                                    <option value="GW">Guinea-Bissau</option>
                                                    <option value="HT">Haiti</option>
                                                    <option value="VA">Holy See (Vatican City State)</option>
                                                    <option value="HN">Honduras</option>
                                                    <option value="HK">Hong Kong</option>
                                                    <option value="HU">Hungary</option>
                                                    <option value="IS">Iceland</option>
                                                    <option value="IN">India</option>
                                                    <option value="ID">Indonesia</option>
                                                    <option value="IR">Iran, Islamic Republic of</option>
                                                    <option value="IQ">Iraq</option>
                                                    <option value="IE">Ireland</option>
                                                    <option value="IM">Isle of Man</option>
                                                    <option value="IL">Israel</option>
                                                    <option value="IT">Italy</option>
                                                    <option value="JM">Jamaica</option>
                                                    <option value="JP">Japan</option>
                                                    <option value="JE">Jersey</option>
                                                    <option value="JO">Jordan</option>
                                                    <option value="KZ">Kazakhstan</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="KI">Kiribati</option>
                                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                                    <option value="KW">Kuwait</option>
                                                    <option value="KG">Kyrgyzstan</option>
                                                    <option value="LA">Lao People's Democratic Republic</option>
                                                    <option value="LV">Latvia</option>
                                                    <option value="LB">Lebanon</option>
                                                    <option value="LS">Lesotho</option>
                                                    <option value="LR">Liberia</option>
                                                    <option value="LY">Libya</option>
                                                    <option value="LI">Liechtenstein</option>
                                                    <option value="LT">Lithuania</option>
                                                    <option value="LU">Luxembourg</option>
                                                    <option value="MO">Macao</option>
                                                    <option value="MG">Madagascar</option>
                                                    <option value="MW">Malawi</option>
                                                    <option value="MY">Malaysia</option>
                                                    <option value="MV">Maldives</option>
                                                    <option value="ML">Mali</option>
                                                    <option value="MT">Malta</option>
                                                    <option value="MH">Marshall Islands</option>
                                                    <option value="MQ">Martinique</option>
                                                    <option value="MR">Mauritania</option>
                                                    <option value="MU">Mauritius</option>
                                                    <option value="MX">Mexico</option>
                                                    <option value="FM">Micronesia, Federated States of</option>
                                                    <option value="MD">Moldova, Republic of</option>
                                                    <option value="MC">Monaco</option>
                                                    <option value="MN">Mongolia</option>
                                                    <option value="ME">Montenegro</option>
                                                    <option value="MS">Montserrat</option>
                                                    <option value="MA">Morocco</option>
                                                    <option value="MZ">Mozambique</option>
                                                    <option value="MM">Myanmar</option>
                                                    <option value="NA">Namibia</option>
                                                    <option value="NR">Nauru</option>
                                                    <option value="NP">Nepal</option>
                                                    <option value="NL">Netherlands</option>
                                                    <option value="NZ">New Zealand</option>
                                                    <option value="NI">Nicaragua</option>
                                                    <option value="NE">Niger</option>
                                                    <option value="NG">Nigeria</option>
                                                    <option value="NU">Niue</option>
                                                    <option value="NF">Norfolk Island</option>
                                                    <option value="MP">Northern Mariana Islands</option>
                                                    <option value="NO">Norway</option>
                                                    <option value="OM">Oman</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="PW">Palau</option>
                                                    <option value="PS">Palestinian Territory, Occupied</option>
                                                    <option value="PA">Panama</option>
                                                    <option value="PG">Papua New Guinea</option>
                                                    <option value="PY">Paraguay</option>
                                                    <option value="PE">Peru</option>
                                                    <option value="PH">Philippines</option>
                                                    <option value="PL">Poland</option>
                                                    <option value="PT">Portugal</option>
                                                    <option value="PR">Puerto Rico</option>
                                                    <option value="QA">Qatar</option>
                                                    <option value="RO">Romania</option>
                                                    <option value="RU">Russian Federation</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="BL">Saint Barthélemy</option>
                                                    <option value="KN">Saint Kitts and Nevis</option>
                                                    <option value="LC">Saint Lucia</option>
                                                    <option value="MF">Saint Martin (French part)</option>
                                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                                    <option value="WS">Samoa</option>
                                                    <option value="SM">San Marino</option>
                                                    <option value="ST">Sao Tome and Principe</option>
                                                    <option value="SA">Saudi Arabia</option>
                                                    <option value="SN">Senegal</option>
                                                    <option value="RS">Serbia</option>
                                                    <option value="SC">Seychelles</option>
                                                    <option value="SL">Sierra Leone</option>
                                                    <option value="SG">Singapore</option>
                                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                                    <option value="SK">Slovakia</option>
                                                    <option value="SI">Slovenia</option>
                                                    <option value="SB">Solomon Islands</option>
                                                    <option value="SO">Somalia</option>
                                                    <option value="ZA">South Africa</option>
                                                    <option value="KR">South Korea</option>
                                                    <option value="SS">South Sudan</option>
                                                    <option value="ES">Spain</option>
                                                    <option value="LK">Sri Lanka</option>
                                                    <option value="SD">Sudan</option>
                                                    <option value="SR">Suriname</option>
                                                    <option value="SZ">Swaziland</option>
                                                    <option value="SE">Sweden</option>
                                                    <option value="CH">Switzerland</option>
                                                    <option value="SY">Syrian Arab Republic</option>
                                                    <option value="TW">Taiwan, District of China</option>
                                                    <option value="TJ">Tajikistan</option>
                                                    <option value="TZ">Tanzania, United Republic of</option>
                                                    <option value="TH">Thailand</option>
                                                    <option value="TG">Togo</option>
                                                    <option value="TK">Tokelau</option>
                                                    <option value="TO">Tonga</option>
                                                    <option value="TT">Trinidad and Tobago</option>
                                                    <option value="TN">Tunisia</option>
                                                    <option value="TR">Turkey</option>
                                                    <option value="TM">Turkmenistan</option>
                                                    <option value="TC">Turks and Caicos Islands</option>
                                                    <option value="TV">Tuvalu</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="UA">Ukraine</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom</option>
                                                    <option value="US" >United States</option>
                                                    <option value="UY">Uruguay</option>
                                                    <option value="UZ">Uzbekistan</option>
                                                    <option value="VU">Vanuatu</option>
                                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                    <option value="VN">Vietnam</option>
                                                    <option value="VI">Virgin Islands</option>
                                                    <option value="YE">Yemen</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="ZW">Zimbabwe</option>
                                                </select>
                                                
                                            </div>
                                            
                                            
                                            <div className="fv-row mb-7">
                                               
                                                <div className="d-flex flex-stack">
                                                    
                                                    <div className="me-5">
                                                        
                                                        <label className="fs-6 fw-semibold">Use as a billing adderess?</label>
                                                       
                                                        
                                                        <div className="fs-7 fw-semibold text-muted">If you need more info, please check budget planning</div>
                                                        
                                                    </div>
                                                   
                                                    
                                                    <label className="form-check form-switch form-check-custom form-check-solid">
                                                        
                                                        <input  onChange={(e)=>{console.log(e)}}className="form-check-input" name="billing" type="checkbox" value="1" id="kt_modal_add_customer_billing" checked="checked" />
                                                        
                                                        
                                                        <span className="form-check-label fw-semibold text-muted" htmlFor="kt_modal_add_customer_billing">Yes</span>
                                                       
                                                    </label>
                                                   
                                                </div>
                                               
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                               
                                <div className="modal-footer flex-center">
                                   
                                    <button type="reset" id="kt_modal_add_customer_cancel" className="btn btn-light me-3">Discard</button>
                                   
                                   
                                    <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                        <span className="indicator-label">Submit</span>
                                        <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                   
                                </div>
                               
                            </form>
                           
                        </div>
                    </div>
          </div>
          <div className="modal fade" id="kt_customers_export_modal" tabIndex="-1" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered mw-650px">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h2 className="fw-bold">Export Customers</h2>
                          <div id="kt_customers_export_close" className="btn btn-icon btn-sm btn-active-icon-primary">
                              <span className="svg-icon svg-icon-1">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                                  </svg>
                              </span>
                          </div>
                      </div>

                      <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                          <form id="kt_customers_export_form" className="form" action="#">
                              <div className="fv-row mb-10">
                                  <label className="fs-5 fw-semibold form-label mb-5">Select Export Format:</label>
                                  <select value={""} onChange={(e)=>{console.log(e)}} data-control="select2" data-placeholder="Select a format" data-hide-search="true" name="format" className="form-select form-select-solid">
                                      <option value="excell">Excel</option>
                                      <option value="pdf">PDF</option>
                                      <option value="cvs">CVS</option>
                                      <option value="zip">ZIP</option>
                                  </select>
                              </div>
                              <div className="fv-row mb-10">
                                  <label className="fs-5 fw-semibold form-label mb-5">Select Date Range:</label>
                                  <input  onChange={(e)=>{console.log(e)}}className="form-control form-control-solid" placeholder="Pick a date" name="date" />
                              </div>
                              <div className="row fv-row mb-15">
                                  <label className="fs-5 fw-semibold form-label mb-5">Payment Type:</label>
                                  <div className="d-flex flex-column">
                                      <label className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                          <input  onChange={(e)=>{console.log(e)}}className="form-check-input" type="checkbox" value="1" checked="checked" name="payment_type" />
                                          <span className="form-check-label text-gray-600 fw-semibold">All</span>
                                      </label>
                                      <label className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                          <input  onChange={(e)=>{console.log(e)}}className="form-check-input" type="checkbox" value="2" checked="checked" name="payment_type" />
                                          <span className="form-check-label text-gray-600 fw-semibold">Visa</span>
                                      </label>
                                      <label className="form-check form-check-custom form-check-sm form-check-solid mb-3">
                                          <input  onChange={(e)=>{console.log(e)}}className="form-check-input" type="checkbox" value="3" name="payment_type" />
                                          <span className="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                                      </label>
                                      <label className="form-check form-check-custom form-check-sm form-check-solid">
                                          <input  onChange={(e)=>{console.log(e)}}className="form-check-input" type="checkbox" value="4" name="payment_type" />
                                          <span className="form-check-label text-gray-600 fw-semibold">American Express</span>
                                      </label>
                                  </div>
                              </div>
                              <div className="text-center">
                                  <button type="reset" id="kt_customers_export_cancel" className="btn btn-light me-3">Discard</button>
                                  <button type="submit" id="kt_customers_export_submit" className="btn btn-primary">
                                      <span className="indicator-label">Submit</span>
                                      <span className="indicator-progress">Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default District;

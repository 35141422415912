import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import SalaryCutsDetail from '../../../../components/modules/hr/salary-cuts-detail';
import SalaryCustDetailMiddleware from '../../../../middleware/modules/hr/salary-cuts-detail';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,salaryCutsDetail }) => ({
  isProcessing: salaryCutsDetail.isProcessing,
  isError: salaryCutsDetail.isError,
  errorMessage: salaryCutsDetail.errorMessage,
  datalist:salaryCutsDetail.data,
  showModal:salaryCutsDetail.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>SalaryCustDetailMiddleware.getHelper(),
    create: () =>SalaryCustDetailMiddleware.create(),
    reset: () =>SalaryCustDetailMiddleware.resetList(),
    search: data =>SalaryCustDetailMiddleware.list(data),
    update: data =>SalaryCustDetailMiddleware.update(data),
    delete: (id,search) =>SalaryCustDetailMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class AllowanceDetailContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.getHelper()
    this.props.update(id)
  }
  createItem(){
    this.props.getHelper()
    this.props.create()
  }
  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Salary Cuts Detail List")}
          </title>
        </Helmet>
      <SalaryCutsDetail
        {...this.props}
        data={datalist}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceDetailContainer);

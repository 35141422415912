import QuotationRequestActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  printPr: null,
  helperQuotationRequest: null,
  quotationRequestHelperFull: null,
  requestPrintPr: false,
};

function QuotationRequestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case QuotationRequestActions.RESET_LIST:
      return INITIAL_STATE;
    case QuotationRequestActions.NEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case QuotationRequestActions.UPDATE_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        isNewRecord: false,
        showModal: true,
      };
    case QuotationRequestActions.VIEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case QuotationRequestActions.RESET_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case QuotationRequestActions.DELETE_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case QuotationRequestActions.ON_SUBMIT:
      return { ...state, onSubmit: true };
    case QuotationRequestActions.SHOW_DATA:
      return {
        ...state,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case QuotationRequestActions.SUCCESS_DATA:
      return {
        ...state,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case QuotationRequestActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false, isSuccess: false };
    case QuotationRequestActions.ERROR_DATA:
      return {
        ...state,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case QuotationRequestActions.GET_HELPER_QUOTATIONREQUEST:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperQuotationRequest: action.payload,
      };
    case QuotationRequestActions.GET_HELPER_QUOTATIONREQUEST_FULL:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        quotationRequestHelperFull: action.payload,
      };
    case QuotationRequestActions.BEFORE_PRINT_PR:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        isProcessing: true,
        printPr: null,
      };
    case QuotationRequestActions.PRINT_PR:
      return {
        ...state,
        errorInternal: false,
        isProcessing: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        printPr: action.payload,
      };
    case QuotationRequestActions.ON_LIMIT_PRINT:
      return {
        ...state,
        errorInternal: false,
        requestPrintPr: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        isProcessing: false,
      };
    case QuotationRequestActions.ON_SAVE_REQUEST_PRINT:
      return {
        ...state,
        errorInternal: false,
        requestPrintPr: true,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        isProcessing: false,
      };
    default:
      return state;
  }
}

export default QuotationRequestReducer;

import JournalActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataDetail:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperJournal:null,
  helperFullJournal:null,
  errorInternal:false,
  printJournal:null,
};

function JournalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JournalActions.RESET_LIST:
      return INITIAL_STATE;
    case JournalActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case JournalActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case JournalActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case JournalActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case JournalActions.CANCEL_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case JournalActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case JournalActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case JournalActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case JournalActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case JournalActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case JournalActions.GET_HELPER_JOURNAL:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case JournalActions.GET_HELPERFULL_JOURNAL:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperFullJournal:action.payload};
    case JournalActions.GET_DETAIL:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case JournalActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case JournalActions.BEFORE_DETAIL:
      return { ...state,errorInternal:false, onSubmit:false,data:action.payload,isNewRecord:false};
    case JournalActions.AFTER_DETAIL:
      return { ...state,errorInternal:false, onSubmit:false, dataDetail:action.payload,isNewRecord:false,isProcessing:false};
    case JournalActions.AFTER_DATA:
      return { ...state,errorInternal:false, onSubmit:false, data:action.payload,isNewRecord:false}      
    case JournalActions.BEFORE_PRINT_JOURNAL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printJournal:null};
    case JournalActions.PRINT_JOURNAL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printJournal:action.payload};
    default:
      return state;
    }
}

export default JournalReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { CashBank } from "../../../../components/modules/accounting/cash-bank";
import CashBankMiddleware from "../../../../middleware/modules/accounting/cash-bank";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// middlewares

const mapStateToProps = ({ auth, cashbank, journal }) => ({
  isProcessing: cashbank.isProcessing,
  isError: cashbank.isError,
  errorMessage: cashbank.errorMessage,
  datalist: cashbank.data,
  getHelperData: cashbank.helperJournal,
  afterSave: cashbank.afterSave,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => CashBankMiddleware.create(),
      getHelper: () => CashBankMiddleware.getHelper(),
      changeStatus: (data) => CashBankMiddleware.beforeChangeStatus(data),
      reset: () => CashBankMiddleware.resetList(),
      search: (data) => CashBankMiddleware.list(data),
      update: (data) => CashBankMiddleware.update(data),
      delete: (id, search) => CashBankMiddleware.beforeDelete(id, search),
      submit: (data, search) => CashBankMiddleware.beforeSaveNew(data, search),
    },
    dispatch
  );
};
class CashBankContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,
  };

  static defaultProps = {
    afterSave:false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.submitItem = this.submitItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    // this.props.reset();
    this.props.getHelper();
    // this.props.search(search);
  }

  submitItem() {
    this.props.submit();
  }
  render() {
    const { isProcessing, search, datalist, showModal, getHelperData,afterSave } =
      this.props;
      // if(afterSave){
      //   this.props.reset();
      //   this.props.getHelper();
      // }
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Cash / Bank List")}</title>
        </Helmet>
        <CashBank
          {...this.props}
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
          changeItem={this.changeItem}
          getHelperData={getHelperData}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBankContainer);

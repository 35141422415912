import {  Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import i18n from "../../../../i18n";
// import styles from "../../Const/StylePDF";

Font.register({
    family: 'Times-Roman',
    fonts: [
    { src: 'assets/fonts/times.ttf' , fontWeight: 400},
    { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
    });
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo:{
        width:80,
        height:80,
    },
    body: {
        padding: "20px",
    },
    row: {
        
        flex:1,
        flexDirection: 'row',
    },
    col:{
        flex:1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr:{
        flexDirection: 'row', 
    },
    td:{
        flexDirection: 'column',
    },

    //Header
    titleContainer:{
        flexDirection: 'row',
    },
    header:{
        fontSize:"18px",
        fontFamily: 'Times-Roman'
    },
    address:{
        fontSize:"12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx:{
        fontSize:"17px",
        fontWeight:700,
        fontFamily: 'Times-Roman',
        textAlign:'right'
    },
    tableTitle:{
        border:'1px solid black',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },

    tableTotal:{
        fontSize:"12px",
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
    },
    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },

    tableRemark: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        fontSize: "12px",
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        borderTop: 1,
        fontFamily: 'Times-Roman',
    },

    tableSign: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontSize: "12px",
        height: '15%',
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        fontFamily: 'Times-Roman',
    },
    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
       
    },
    rowQty: {
        flexDirection: 'row',
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
       
    },
    columnQty:{
        flexDirection: 'column',
    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    //end Table

    //Footer
    footer:{
        position:'absolute',
        bottom:0
    }
    //end Footer
  });
const tableRowsCount = 11;
class PrintWHIn extends Component{
    constructor(props){
        super(props)
        this.state={
            title:i18n.t("Warehouse In No")+' : ',
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:{
                poNo:'',
                po_detail:[]
            }
        };
    }

    renderMessage() {
        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
        }
    }

    render(){
        const {dataPrint}=this.props
       
        let address=[]
       
        if(dataPrint!=null){
            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title+ ((dataPrint !=undefined)?this.state.data.id_whin:'')}>
                        <Page wrap size={"A4"} style={styles.page}>
                        <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map(a=>
                                                <Text style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                    </View>
                                    <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'600px',paddingTop:'20px'}} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Warehouse In")} </Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Receipt No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'200px'}} >
                                                        <Text>{this.state.data.id_whin}</Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{i18n.t("Expedition")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.delivery_service}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Receiving Date")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'200px'}} >
                                                        <Text>{this.state.data.date_format}</Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{i18n.t("Delivery Date")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.delivery_date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'125px'}} >
                                                        <Text>{i18n.t("Final Branch")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'200px'}}>
                                                        <Text>{this.state.data.name_branch_des}</Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{i18n.t("No. Vehicle")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.vehicle_no}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'130px'}} >
                                                        <Text></Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text>  </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'200px'}}>
                                                        <Text></Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{i18n.t("No. Document")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.doNo}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        </View>
                                </View>
                                
                                <View style={styles.tableContainer}>
                                    <View style={styles.containerItem}>
                                        <Text style={{width:'5%'}}>{i18n.t("No")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("Item Code")}</Text>
                                        <Text style={{width:'25%'}}>{i18n.t("Item")}</Text>
                                        <Text style={{width:'10%'}}>{i18n.t("No. Band")}</Text>
                                        <Text style={{width:'15%',textAlign:'center'}}>{i18n.t("Qty")}</Text>
                                        <Text style={{width:'30%'}}>{i18n.t("Description")}</Text>
                                    </View>
                                    {this.state.data.warehouse_in_detail.map( (item,i) => 
                                        <Fragment>
                                            <View style={styles.rowItem} key={i+'itemPo'}>
                                                <Text style={{width:'5%',padding:1}}>{i+1}</Text>
                                                <Text style={{width:'15%',padding:1}}>{item.item_code}</Text>
                                                <Text style={{width:'25%',padding:1}}>{item.name_item}</Text>
                                                <Text style={{width:'10%',padding:1}}>{item.bandNo}</Text>
                                                <View style={styles.columnQty}>{item.convertionDetail.map((detail,i) =>
                                                    <View style={styles.rowQty} key={i+'itemPo'}>
                                                        <Text style={{width:'15%',padding:1,textAlign:'right'}}>{detail.qty} {detail.unitValue.label}</Text>
                                                    </View>
                                                )}</View>
                                                <Text style={{width:'30%',padding:1}} >{item.description}</Text>
                                            </View>
                                        </Fragment>
                                    )}
                                </View>
                               
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={[styles.col,{width:'500px',paddingTop:'35px'}]} >
                                            <View style={[styles.tableTotal,{textAlign:'left'}]}>
                                                <Text style={[{fontFamily:'Times-Roman',fontWeight:'900'}]}>{i18n.t("Note")} :</Text>
                                            </View>
                                            <View style={styles.tableTotal} >
                                                <Text>{this.state.data.description}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    {/* <View style={styles.row}>
                                    <View style={styles.col,{width:'500px',paddingTop:'40px',paddingLeft:'40px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>{i18n.t("Mengetahui")}</Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                            </View>  
                                            <View style={styles.tableTotal,{width:'300px',paddingLeft:'18px'}} >
                                                <Text>(AMM)</Text>
                                             
                                            </View>       
                                    </View>
                                    </View>
                                    <View style={styles.col,{width:'200px',paddingTop:'40px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>{i18n.t("Diterima Oleh")}</Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>
                                                <Text> </Text>

                                            </View>  
                                            <View style={styles.tableTotal,{width:'300px',paddingLeft:'13px'}} >
                                            <Text>({this.state.data.created_by})</Text>
                                            </View>       
                                    </View>
                                    </View>
                                    </View> */}
                                 </View>
                                 <View style={styles.tableRemark}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text>{item.remark}</Text>
                                        </View>
                                    )}</Fragment>
                                </View>
                                <View style={styles.tableSign}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text style={[styles.td, { height: '100%' }]}>{ }</Text>
                                        </View>
                                    )}</Fragment>
                                </View>
                                <View style={styles.tableName}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text>({item.username})</Text>
                                        </View>
                                    )}</Fragment>
                                </View> 
                            </View>
                        </Page>
                    </Document>
            </PDFViewer>:this.renderMessage()}
        </React.Fragment>   
        )
    }
}

export default PrintWHIn;


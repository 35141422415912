
import React, { Component, Fragment } from 'react';
import { Button, FormLabel, Modal, Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import axios from 'axios';
import InputNumber from '../../../utilities/Form/InputNumber';
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import InputCustom from '../../../utilities/Form/InputCustom';
import withReactContent from 'sweetalert2-react-content';
import DropzoneCustom from '../../../utilities/Form/DropzoneCustom';
import PhotoGalleryOne from '../../../utilities/PhotoGalleryOne';
import { TouchSensor } from '@dnd-kit/core';

const MySwal = withReactContent(Swal)
export class FormPay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                PaymentConfirmation: {},
                PaymentConfirmationDetail: [],
                DownPayment: [],
                BGDate: [],
                paymentType: [],
                noBG: [],
                image: [],
                payment: [],
            },
            file: '',
            checkDP:0,
            showImage: false,
            typePay: 'Cash',
            branchCurrent: null,
            idBilling: null,
            branchList: [],
            branchText: i18n.t('Select Branch'),
            storeCurrent: null,
            storeList: [],
            storeText: i18n.t('Select Store'),
            bankAccountList: [],
            bankAccountCurrent: null,
            bankAccountText: i18n.t('Cash/Bank account'),
            salesCurrent: null,
            salesList: [],
            salesText: i18n.t('Select Sales'),
            search: {
                limit: 10
            },
            paymentTypeList: { 'Cash': i18n.t('Cash'), 'Transfer': i18n.t('Transfer'), 'BG': i18n.t('BG') },
            set: 0,
            isPayment: 0,
            paymentNo: '',
            showPay: 0,
            transactionNo: '',
            show: false,
            onShow: false,
            showAccount: true,
            idCustomer: '',
            chooseAll: 0,
            payment: 0,
            paymentTotal: 0,
            remaining: 0,
            choose: 0,
            isUpdate: 0,
            date: new Date(),
            datePayment: new Date(),
            arrayDetail: [{
                defaultImage: [],
                logDetail: [],
                maxUploadFiles: 3,
                bond: 0,
                images: [],
            }],
        }

        this.handler = this.handler.bind(this)
        this.handlerPaymentType = this.handlerPaymentType.bind(this)
        this.handlerPayment = this.handlerPayment.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.handlerDateTimePayment = this.handlerDateTimePayment.bind(this)
        this.handlerNumberDP = this.handlerNumberDP.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
        this.updateDropzone = this.updateDropzone.bind(this);
        this.dataTotal = this.dataTotal.bind(this);

    }

    runSubmit() {
        // const detail = this.state.arrayDetail;
        MySwal.close();
        const Billing = {
            idBranch: this.state.branchCurrent == null ? '' : this.state.branchCurrent.value,
            idSales: this.state.salesCurrent == null ? '' : this.state.salesCurrent.value,
            date: moment(this.state.datePayment).format('YYYY-MM-DD'),
            total: this.state.subTotal == null ? '' : this.state.subTotal,
            paymentType: 'Cash',
            bankAccount: ((this.state.bankAccountCurrent != null) ? this.state.bankAccountCurrent.value : ''),
            idBilling: this.state.idBilling,
            paymentNo: this.state.paymentNo
        }


        let det = [];
        let dp = [];
        let BGDate = [];
        let noBG = [];
        let payment = [];
        let paymentType = [];
        let image = [];
        const detail = this.state.arrayDetail.filter((item) => item.choose == true)
        
        for (var i = 0; i < detail.length; i++) {
            det.push(detail[i]);
            payment.push(detail[i]['paymentDetail']);
            dp.push(detail[i]['useDP'] == true ? detail[i]['DP'] : 0)
            BGDate.push(detail[i]['paymentType'] == 'BG' ? moment(detail[i]['BGDate']).format('YYYY-MM-DD') : '')
            noBG.push(detail[i]['paymentType'] == 'BG' ? detail[i]['noBG'] : '')
            paymentType.push(detail[i]['paymentType'] != null ? detail[i]['paymentType'] : '')
            image.push(detail[i]['image'] != null ? detail[i]['image'] : '')
        }

        this.state.form.PaymentConfirmation = Billing;
        this.state.form.PaymentConfirmationDetail = det;
        this.state.form.DownPayment = dp;
        this.state.form.BGDate = BGDate;
        this.state.form.noBG = noBG;
        this.state.form.paymentType = paymentType;
        this.state.form.image = image;
        this.state.form.payment = payment;

        return this.state.form;

    }

    updateDropzone(files, i) {
        const arr = this.state.arrayDetail
        const objectss = arr[i]
        objectss['image'] = files != '' ? files[0] : null;
        this.state.showPay = 1;
    }

    image(i, p) {
        if (this.state.bond == 1) {
            this.setState({ viewImage: this.state.arrayDetail[p]['view'] });
            this.state.bond = 0;
        }
    }

    handlerNumberDP(e, name, i) {
        console.log(name,e)
        var key = name;
        var value = e == undefined ? 0 : e;
        let arr = [...this.state.arrayDetail];
        const objectss = arr[i];
        
        if(value <= objectss['maxDP']){
            objectss['DP'] = value;
        }else{
            this.alertError('Info','Nilai DP Melebihi Total Bill');
            objectss['DP'] = objectss['maxDP']
        }

        this.setState(arr,() => this.calculate())
    }

    handlerPayment(e, i) {
        var value = e.target.value

        const arr = this.state.arrayDetail[i]
        arr['noBG'] = value
        this.state.showPay = 1;
        this.setState(arr)
    }

    handlerDateTimePayment(e, i) {
        var value = e

        const arr = this.state.arrayDetail[i]
        arr['BGDate'] = value
        this.state.showPay = 1;
        this.setState(arr)
    }

    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        })
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            [key]: value
        })
    }

    handlerPaymentType(e, i) {
        var key = e.target.name;
        var value = e.target.value

        const arr = this.state.arrayDetail[i]
        const det = this.state.arrayDetail

        for (var i = 0; i < det.length; i++) {
            det[i][key] = value;
        }
        arr[key] = value
        this.state.showPay = 1;
        this.state.typePay = value == 'Cash' ? 'Cash' : 'Bank';
        this.state.showAccount = value == 'Cash' ? true : value == 'Transfer' ? true : false;

        this.setState({ bankAccountList: [], bankAccountCurrent: '' })
        this.setState(det,() => this.changeData({
                keyFill:"bankAccountList",
                type:"getBank",
                key:'',
              })
            );
        
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            [key]: value
        })
        this.setState({ [currentState]: e })
    }


    calculateOne(index, ins) {
        let total = 0;
        let subTotal = parseFloat(this.state.arrayDetail[ins].subTotal) || 0;
        const detail = this.state.arrayDetail[ins][index];
        if (detail.choose == true) {
            total = subTotal + parseFloat(detail.remaining) || 0
        } else {
            total = subTotal - parseFloat(detail.remaining) || 0
        }

        const objectss = this.state.arrayDetail[ins]
        objectss.subTotal = total;
        this.setState(objectss)
        this.setState({ showPay: 1 }, () => this.calculateAll(ins, ''))
    }

    calculateAll(index, check) {
        let total = 0;

        let subTotal = 0;
        const detail = this.state.arrayDetail;
        for (var i = 0; i < detail.length; i++) {
            subTotal = parseFloat(this.state.arrayDetail[i].subTotal) || 0;
            total += subTotal
        }

        this.setState({
            paymentTotal: total, isUpdate: 1
        })
    }

    handlerCurrencyArray(value, name, i, p) {
        let o = p.index;
        let refund = 0;
        let dp = 0;
        const detail = this.state.arrayDetail[o];

        let total = 0;
        const objectss = this.state.arrayDetail[o][i];
        objectss[name] = value;
       
        for (var co = 0; co < detail.length; co++) {
            total += parseFloat(this.state.arrayDetail[o][co].paymentCust) || 0
        }
        refund = detail['subTotal'] - total;
       
        detail['paymentDetail'] = total;

        if(detail['downPayment'] >= detail['subTotal']){
            dp = detail['subTotal'] - total;
            detail['DP'] = (dp <= detail['downPayment'] ? dp < 0 ? 0 : dp : 0);
            detail['maxDP'] = (dp <= detail['downPayment'] ? dp < 0 ? 0 : dp : 0);
        }else{
            dp = detail['downPayment'] - total;
            detail['DP'] = (dp <= detail['downPayment'] ? dp < 0 ? 0 : dp : 0);
            detail['maxDP'] = (dp <= detail['downPayment'] ? dp < 0 ? 0 : dp : 0);
        }
        console.log(dp)
        detail['refund'] = (refund > 0 ? 0 : Math.abs(refund));

        this.setState(detail, () => this.calculate());
       
    }

    calculateDP(detail,total){
        let DP = 0;
        let downPayment = detail['DP'];

        if(detail.useDP == true){
            DP = downPayment - total;
        }

        detail['downPayment'] = DP;
        this.setState(detail,() => this.calculate())

    }

    calculate() {
        let a = 0;
        let totalPayment = this.state.paymentTotal;
        let remaining = 0;
        
        for (var co = 0; co < this.state.arrayDetail.length; co++) {
            let arr = this.state.arrayDetail[co];
            a += parseFloat(arr['paymentDetail']) || 0;
                if(arr['useDP'] == true){
                    a += parseFloat(arr['DP']) || 0;
                }
            let subTotal = parseFloat(arr['subTotal']) || 0;

            }
            
        remaining = (parseFloat(this.state.paymentTotal) || 0) - a;

        this.setState({
            totalPayment: a,
            remaining: remaining > 0 ? remaining : 0,
        })
    }

    useDP(array, ins, key) {
        const arr = [...this.state[array]];
        const objectss = arr[ins];
        objectss[key] = !objectss[key];
        switch(objectss[key]){
            case true:
                if(objectss['paymentDetail'] == 0){
                    objectss['paymentType'] = 'Down Payment';
                    // objectss['paymentTypeLast'] = 'DP Full';
                    // arr.paymentTypeList = { Cash: 'Cash', Transfer: 'Transfer', BG: 'BG' };
                }
                break;
        }
        
        
        this.setState(arr,()=>this.calculate())
    }

    

    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                MySwal.close();
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    alertError(title, message, icon, i) {
        Swal.fire({
            title: title,
            html: message,
            icon: icon,
        });
    }

    choose(e, array, index, key) {
        let ins = e.target.id;
        const arr = [...this.state[array][ins]];
        const objectss = arr[index];

        const detail = this.state.arrayDetail;
        const total = this.state.arrayDetail[ins];
        const pay = this.state.arrayDetail[ins][index];
        let subTotal = 0;
        objectss[key] = !objectss[key];

        if (objectss[key] == false) {
            total['paymentDetail'] = total['paymentDetail'] - pay['paymentCust'];
            total['checkDetail'] -= 1;
            pay['paymentCust'] = 0;
        }
        else {
            total['checkDetail'] += 1;
            total['paymentDetail'] = 0;
        }
        this.setState(total, () => this.calculate())

        if(total['checkDetail']>0){
            for (var co = 0; co < total['checkDetail']; co++) {
                subTotal = parseFloat(objectss['total']) || 0;
            }
            
            if(subTotal >= total['downPayment']){
                total['DP'] = total['downPayment']; 
                total['maxDP'] = total['downPayment']; 
            }else{
                total['DP'] = subTotal;
                total['maxDP'] = subTotal; 

            }
            total['refund'] = 0;
            total['choose'] = true;
        }else{
            total['DP'] = 0; 
            total['useDP'] = false;
            total['refund'] = 0;
            total['choose'] = false;


        }

        this.setState(total, () => this.calculate())

        this.setState({showPay:1})
        this.setState(arr, () => this.calculateOne(index, ins))

    }

    chooseAll(e, array, key) {
        let index = e.target.id;
        let check = e.target.checked;
        let pick = 0;
        const arr = [...this.state[array][index]];
        if (arr != null) {
            for (var i = 0; i < arr.length; i++) {
                pick += 1;
                const objectss = arr[i]
                if (check == true) {
                    objectss[key] = true;
                    objectss['paymentCust'] = 0
                } else {
                    objectss[key] = false;

                }
            }
        } else {
            pick = 0;
        }
        this.setState(arr, () => this.calculate(index, check))
    }

    

    // calculateWithDP(total){
    //     this.setState({totalPayment:total})
    // }

    setList() {
        var date = moment(this.state.date).format("YYYY-MM-DD");
        var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
        var idSales = (this.state.salesCurrent == null) ? '' : this.state.salesCurrent.value;
        var transactionNo = (this.state.transactionNoCurrent == null) ? '' : this.state.transactionNoCurrent.value;

        let params = {
            date: date,
            branch: idBranch,
            sales: idSales,
            transactionNo: transactionNo,
        }
        this.props.setPayBill(params);
    }

    save() {
        const html = (
            <>
                <div>{i18n.t("Are you sure ?")}</div>
                <Button variant="warning" onClick={() => MySwal.close()}>
                    {i18n.t("Cancel")}
                </Button>
                <Button
                    variant="danger"
                    onClick={(e) =>
                        this.runSubmit()
                    }
                >
                    {i18n.t("Yes")}
                </Button>
            </>
        );
        MySwal.fire({
            title: i18n.t("Save Payment"),
            html: html,
            icon: "question",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
    }

    typeCustomer(data, i) {
        const arr = this.state.arrayDetail[i]

        var type = data;
        if (type == 'Cash') {
            arr.paymentType = 'Cash'
            arr.paymentTypeList = { Cash: 'Cash' }
        } else if (type == 'Credit') {
            arr.paymentType = 'Transfer'
            arr.paymentTypeList = { Cash: 'Cash', Transfer: 'Transfer', BG: 'BG' }
        }
        
    }

    renderList() {
        const { dataPayBilling } = this.props;
        let html = [];
        console.log(this.state.arrayDetail)
        if (dataPayBilling != null && dataPayBilling != '' ) {
            let total = 0;
            let totalPay = 0;
            let row = dataPayBilling
            this.state.arrayDetail = [];
            if (row.length > 0 ) {
                for (var i = 0; i < row.length; i++) {
                    let totalPayment = 0;
                    let totalBill = 0;
                    this.state.arrayDetail[i] = row[i].detail;
                    const objectss = this.state.arrayDetail[i];
                    objectss['index'] = i;
                    objectss['downPayment'] = row[i].down_payment;

                    if (this.state.showPay == 0) {
                        this.state.typePay = dataPayBilling[i]['customer_type'] == 'Credit'?'Bank':'Cash'
                        
                        for (var a = 0; a < objectss.length; a++) {
                            const obj = objectss[a];
                            if (obj.customer_type != null) {
                                this.typeCustomer(obj.customer_type, objectss['index']);
                            }

                            objectss['idCustomer'] = obj.customer_id;
                            objectss['images'] = [];
                            objectss['view'] = (objectss[0].evidence != '') ? objectss[0].evidence : [];
                            objectss['noBG'] = '';
                            objectss['BGDate'] = new Date();
                            
                            if (obj.total_payment > 0 && obj.status == 4) {
                                obj['choose'] = true
                                obj['paymentCust'] = parseFloat(obj.total_payment)||0
                                totalPayment += parseFloat(obj.total_payment) || 0;
                                totalBill += parseFloat(obj.remaining) || 0;
                            } else {
                                obj['paymentCust'] = 0
                            }
                        }
                        objectss['paymentDetail'] = totalPayment
                        objectss['subTotal'] = totalBill
                        objectss['checkDetail'] = 0;

                    }
                    total += totalBill;
                    totalPay += totalPayment;
                   

                    html.push(
                        <React.Fragment key={'fragment' + i}>
                            <form key={'cus' + i} id={i}>
                                <div className='row'>
                                    <div className="col-12">
                                        <hr />
                                        <h3>{i18n.t('Customer Name : ') + row[i].customer_name}</h3>
                                        <hr />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12">
                                        <Table  >
                                            <thead>
                                                <tr id={'tr' + i} key={'tr' + i} className='border' style={{backgroundColor:'floralwhite', fontWeight: 'bold', fontSize: '16', textAlign: 'center' }}>
                                                    <th >{i18n.t("No.")}</th>
                                                    <th >{i18n.t("Invoice No")}</th>
                                                    <th >{i18n.t("Type Payment")}</th>
                                                    <th >{i18n.t("Date")}</th>
                                                    <th >{i18n.t("Due Date")}</th>
                                                    <th >{i18n.t("Total Remaining")}</th>
                                                    <th >{i18n.t("Total Payment")}</th>
                                                    <th colSpan={2} >{i18n.t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody key={'body' + i}>
                                                {(row[i].detail.length > 0) ?
                                                    row[i].detail.map((detail, index, p) => {
                                                        return this.renderDetail(detail, index, p, objectss['index']);
                                                    })
                                                    : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="d-flex flex-start border">
                                        <div className='col-4'>
                                            {objectss['view'] == '' ?
                                                <>
                                                    <FormLabel>
                                                        {" "}
                                                        {i18n.t("Upload Files")} ( {i18n.t("Max")}{" "}
                                                        {1} {i18n.t("Item")})
                                                    </FormLabel>

                                                    <DropzoneCustom
                                                        maxFiles={1}
                                                        acceptFiles={{
                                                            "image/jpeg": [],
                                                            "image/png": [],
                                                            "application/pdf": [],
                                                        }}
                                                        maxSize={1024000}
                                                        changeFiles={async (e) => {
                                                            await this.updateDropzone(e, objectss['index']);
                                                        }}
                                                        defaultFiles={this.state.arrayDetail[i]['image']}
                                                    ></DropzoneCustom>
                                                </>
                                                : null}
                                        </div>

                                        <div className='col-4'>
                                        </div>
                                        <div className='col-4'>
                                            <Table responsive="sm">
                                                <tbody>

                                                    <tr className="align-middle mt-5">
                                                        <td>{i18n.t("Sub Total Bill")}</td>
                                                        <td>:</td>
                                                        <td>
                                                            <InputNumber
                                                                id={"totalBill" + i}
                                                                name="subTotal"
                                                                placeholder={i18n.t("Total Bill")}
                                                                defaultValue={0}
                                                                value={this.state.arrayDetail[i].subTotal}
                                                                decimalsLimit={2}
                                                                onValueChange={(value, name) => { }}
                                                                className='p-2 form-control form-control-xs form-control-flush text-end'
                                                                prefix='Rp '
                                                                readOnly
                                                                labelfield={"false"}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="align-middle">
                                                        <td>{i18n.t("Type")}</td>
                                                        <td>:</td>
                                                        <td>
                                                            <Radio
                                                                id={"paymentType" + i}
                                                                list={this.state.arrayDetail[i].paymentTypeList}
                                                                name={"paymentType"}
                                                                title={i18n.t("Payment Type")}
                                                                inline={true}
                                                                value={this.state.arrayDetail[i].paymentType}
                                                                defaultValue={'Cash'}
                                                                handler={(e) => {
                                                                    this.handlerPaymentType(e, objectss['index'])
                                                                }}
                                                                labelfield={"false"}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="align-middle mt-5" key={"DP"}>
                                                        <td>
                                                            <span className='get-inline '>
                                                                <InputCustom
                                                                    type={"checkbox"}
                                                                    className={
                                                                        "form-check-input form-check form-check-custom form-check-solid border-check"
                                                                    }
                                                                    id={"dp"}
                                                                    value={objectss['useDP']==undefined?false:objectss['useDP']}
                                                                    checked={objectss['useDP']==undefined?false:objectss['useDP']}
                                                                    name={"useDP"}
                                                                    labelfield={"false"}
                                                                    onChange={async (e) => {
                                                                        if(objectss['checkDetail']==0){
                                                                            this.alertError('Info','Invoice Belum Dipilih!, Pilih Invoice Terlebih Dahulu');
                                                                        }else{
                                                                            this.useDP('arrayDetail', objectss['index'], 'useDP')
                                                                        }
                                                                    }
                                                                }
                                                                />
                                                            </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className='get-inline'>
                                                                <label className='label-control mb-2' htmlFor='useDP'>{i18n.t("Down Payment")}</label>
                                                            </span>  
                                                        </td>
                                                        <td>:</td>
                                                        <td>
                                                            <InputNumber
                                                                id={"downPayment"}
                                                                name="downPayment"
                                                                placeholder={i18n.t("Down Payment")}
                                                                defaultValue={0}
                                                                value={objectss['checkDetail'] == 0 ? this.state.arrayDetail[i].downPayment:this.state.arrayDetail[i].DP}
                                                                decimalsLimit={2}
                                                                onValueChange={async (value, name) => {
                                                                    await this.handlerNumberDP(value, 'DP', objectss['index'])

                                                                }}
                                                                // className='p-0 form-control form-control-xs form-control-flush text-end'
                                                                className='p-2 form-control form-control-xs  text-end'
                                                                disabled={objectss['useDP']==undefined?true:objectss['useDP']==false?true:false}
                                                                prefix='Rp '
                                                                // readOnly
                                                                labelfield={"false"}
                                                            />
                                                        </td>
                                                    </tr>
                                                        {/* <tr className="align-middle mt-5" key={"DP" + i}>
                                                            <td><label className='label-control ' htmlFor='useDP'>{i18n.t("Use Down Payment")}</label>
                                                                <InputCustom
                                                                    type={"checkbox"}
                                                                    className={
                                                                        "form-check-input form-check form-check-custom form-check-solid"
                                                                    }

                                                                    value={this.state.arrayDetail[i].useDP}
                                                                    name={"useDP"}
                                                                    labelfield={"false"}
                                                                    onChange={
                                                                        (value) => this.useDP('arrayDetail', objectss['index'], 'useDP')
                                                                    }

                                                                />
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                <InputNumber
                                                                    id={"downPayment"}
                                                                    name="downPayment"
                                                                    placeholder={i18n.t("")}
                                                                    defaultValue={0}
                                                                    value={this.state.arrayDetail[i].downPayment}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(value, name) => { }}
                                                                    className='p-0 form-control form-control-xs form-control-flush text-end'
                                                                    prefix='Rp '
                                                                    readOnly
                                                                    labelfield={"false"}
                                                                />
                                                            </td>
                                                        </tr> */}
                                                        {(this.state.arrayDetail[i].paymentType == 'BG') ?
                                                            <>
                                                                <tr className="align-middle">
                                                                    <td>{i18n.t("BG Date")}</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <DatePickerCustom
                                                                            name={"BGDate"}
                                                                            title={"BG Date"}
                                                                            required={true}
                                                                            selected={this.state.arrayDetail[i].BGDate}
                                                                            onSelect={async (e) => {
                                                                                await this.handlerDateTimePayment(e, objectss['index']);
                                                                            }
                                                                            }
                                                                            startDay={0}
                                                                            autoComplete={"off"}
                                                                            dateFormat="dd MMM yyyy"
                                                                            className="form-control form-control-sm"
                                                                            labelfield={"false"}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr className="align-middle">
                                                                    <td><label className='label-control' htmlFor='BG No.'>{i18n.t("BG No.")}</label></td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <Input
                                                                            id={"noBG" + i}
                                                                            type={"input"}
                                                                            name={"noBG"}
                                                                            title={i18n.t("BG No")}
                                                                            required
                                                                            value={this.state.arrayDetail[i].noBG}
                                                                            handler={(e) => {
                                                                                this.handlerPayment(e, objectss['index'])
                                                                            }}
                                                                            labelfield={"false"}
                                                                        />

                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : null}

                                                    <tr className="align-middle mt-5">
                                                        <td><label className='label-control required' htmlFor='PaymentSelling'>{i18n.t("Payment")}</label></td>
                                                        <td>:</td>
                                                        <td>
                                                            <InputNumber
                                                                id={"paymentDetail" + i}
                                                                name="paymentDetail"
                                                                placeholder={i18n.t("Total Payment")}
                                                                defaultValue={0}
                                                                value={this.state.arrayDetail[i].paymentDetail}
                                                                decimalsLimit={2}
                                                                // onValueChange={async (value, name) => {
                                                                //     await this.handlerCurrencyArray(value, name, objectss['index'], 'arrayDetail')

                                                                // }}
                                                                // readOnly
                                                                className='p-2 form-control form-control-xs  text-end'
                                                                prefix='Rp '
                                                                labelfield={"false"}
                                                                disabled
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr className="align-middle ">
                                            <td><label className='label-control required' htmlFor='refundSelling'>{i18n.t("Refund")}</label></td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"Refund"}
                                                    name="Refund"
                                                    placeholder={i18n.t("Refund")}
                                                    defaultValue={0}
                                                    value={this.state.arrayDetail[i].refund}
                                                    decimalsLimit={2}
                                                    readOnly
                                                    className='p-2 form-control form-control-xs form-control-flush text-end'
                                                    prefix='Rp '
                                                    labelfield={"false"}
                                                />

                                            </td>
                                        </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </React.Fragment>
                    );
                }

            if (this.state.isUpdate == 0) {
                this.dataTotal(total,totalPay,row[0].id_billing);
                // 
                this.state.isUpdate = 1;
            }
        }
    }
   
    return html;

}

    dataTotal(total,totalPay,bill){
        this.setState({ 
            paymentTotal: total,
            totalPayment: totalPay,
            idBilling: bill}, 
            () => this.calculate());
    }

    renderDetail(detail, i, p, cust) {

        let img = [];
        if (detail.evidence != null) {
            img.push({ imageUrl: detail.evidence, thumbUrl: detail.evidence })

        }
        return <React.Fragment key={"detailData" + i} >
            <tr className={(detail.choose == true) ? 'custom-opacity-bg ' : ''} >
                <td align='center'>
                    {i + 1 + '.'}
                </td>
                <td align='center'>
                    {detail.invoice_no}
                </td>
                <td align='center'>
                    {detail.payment_type}
                </td>
                <td align='center'>
                    {detail.date}
                </td>
                <td align='center'>
                    {detail.due_date}
                </td>

                <td align='right'>
                    {detail.remaining_format}
                </td>
                {detail.status == 3 || detail.payment_type == "Credit" ?

                    detail.choose == null || detail.choose == false ?

                        <td align='right'>
                            {0}
                        </td> :
                        <td style={{ width: '13%' }}>
                            <InputNumber
                                id={'payment' + i}
                                name="paymentCust"
                                placeholder={i18n.t("Payment")}
                                defaultValue={0}
                                value={detail.paymentCust}
                                decimalsLimit={2}
                                onValueChange={async (value, name) => {
                                    await this.handlerCurrencyArray(value, "paymentCust", i, p);
                                }}
                                className='ml-5 p-1 form-control form-control-xs  text-end'
                                prefix='Rp '
                                labelfield={"false"}
                                disabled={detail.status == 3?false:true}
                            />
                        </td>
                    :

                    <td align='right'>
                        {detail.total_payment_format}
                    </td>

                }
                <td align='center'>
                    {(detail.evidence != '' || detail.sign != '' ?
                        <FontAwesomeIcon icon={faPaperclip} onClick={async (e) => {
                            await this.setState({ showImage: true, bond: 1 }, () => this.image(i, cust, 0))
                        }}></FontAwesomeIcon>

                        : null)}
                </td>
                <td align='center'>
                    <InputCustom
                        type={"checkbox"}
                        id={p.index}
                        className={
                            "form-check-input form-check form-check-custom form-check-solid"
                        }

                        disabled={detail.status == 3  ? false : true}
                        value={detail.choose}
                        checked={detail.choose ? true : false}
                        name={"chose"}
                        labelfield={"false"}
                        onChange={(e) => this.choose(e, 'arrayDetail', i, 'choose')}
                    />
                </td>
            </tr>

        </React.Fragment>
    }

    renderTotal() {
        const { dataPayBilling } = this.props;
        let dataTotal = [];
        if (dataPayBilling != null && dataPayBilling.length > 0) {
            dataTotal.push(
                <Fragment key={"totalAll"}>
                    <div className='row'>
                        <div className="d-flex flex-end">
                            <div className='col-4'>
                                <Table borderless responsive="sm">
                                    <tbody>
                                        <tr className="align-middle  ">
                                            <td>{i18n.t("Total Bill")}</td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"subTotal"}
                                                    name="subTotal"
                                                    placeholder={i18n.t("Total Bill")}
                                                    defaultValue={0}
                                                    value={this.state.paymentTotal}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { }}
                                                    className='p-2 form-control form-control-xs form-control-flush text-end'
                                                    prefix='Rp '
                                                    readOnly
                                                    labelfield={"false"}
                                                />
                                            </td>
                                        </tr>
                                        {this.state.showAccount ?
                                            <tr className="align-middle  ">
                                                <td><label className='label-control required' htmlFor='CashAccount'>{i18n.t("Cash/Bank Account")}</label></td>
                                                <td>:</td>
                                                <td>
                                                    <Select2
                                                        isClearable={true}
                                                        name={'bankAccount'}
                                                        required={true}
                                                        className={""}
                                                        size={4}
                                                        onChange={async (e) => {
                                                            await this.handlerSelect(e, "bankAccount", "bankAccountCurrent");
                                                        }
                                                        }
                                                        options={this.state.bankAccountList}
                                                        title={i18n.t('Cash/Bank Account')}
                                                        value={this.state.bankAccountCurrent}
                                                        placeholder={i18n.t('Cash/Bank Account')}
                                                        labelfield={"false"}
                                                    />
                                                    <div className="fv-plugins-message-container invalid-feedback">
                                                        {this.renderError("bankAccount")}
                                                    </div>
                                                </td>
                                            </tr>
                                            : null}
                                        <tr className="align-middle ">
                                            <td><label className='label-control required' htmlFor='PaymentSelling'>{i18n.t("Payment")}</label></td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"totalPayment"}
                                                    name="totalPayment"
                                                    placeholder={i18n.t("Total Payment")}
                                                    defaultValue={0}
                                                    value={this.state.totalPayment}
                                                    decimalsLimit={2}
                                                    // onValueChange={async (value, name) => {
                                                    //     await this.handlerNumber(value, name)

                                                    // }}
                                                    readOnly
                                                    className='p-2 form-control form-control-xs form-control-flush text-end'
                                                    prefix='Rp '
                                                    labelfield={"false"}
                                                />

                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                <hr />
                                            </td>
                                            <td>
                                                <hr />
                                            </td>
                                            <td>
                                                <hr />
                                            </td>
                                        </tr> */}
                                        {/* <tr className="align-middle mt-5">
                                            <td>{i18n.t("Remaining Payment")}</td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"totalRemaining"}
                                                    name="totalRemaining"
                                                    placeholder={i18n.t("Remaining Payment")}
                                                    defaultValue={0}
                                                    value={this.state.remaining}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { }}
                                                    className='p-2 form-control form-control-xs form-control-flush text-end'
                                                    prefix='Rp '
                                                    readOnly
                                                    labelfield={"false"}
                                                />
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                </Fragment>
            );
        }
        return dataTotal;
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch (type) {
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var type =
                    this.state.typePay != null
                        ? this.state.typePay
                        : null;
                params = {
                    branch: branch,
                    type: type,
                }
                break;
            case "getEmployeeSales":
                var url = process.env.REST_URL + "helper/get-employee-sales";
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    branch: branch,
                };
                break;
            case "getCode":
                var url = process.env.REST_URL + "helper/get-code-billing";
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    date: date,
                    branch: branch,
                };
                break;
            case 'getCodePayment':
                var url = process.env.REST_URL + 'helper/get-payment-code-selling'
                var date = ((this.state.datePayment != undefined) ? moment(this.state.datePayment).format('YYYY-MM-DD') : null)
                var branch = ((this.state.branchCurrent != null) ? this.state.branchCurrent.value : null)
                params = {
                    date: date,
                    branch: branch,
                }
                break;
            case "getStore":
                var url = process.env.REST_URL + "helper/get-store";
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    id: branch,
                };
                break;
            case 'getDP':
                var url = process.env.REST_URL + 'helper/get-down-payment'
                var date = ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
                var customer = this.state[array][arraykey].idCustomer
                params = {
                  date: date,
                  customer: customer,
                }
                if (customer == null || date == null) {
                  type = null
                }
                // var url = process.env.REST_URL + 'sales/billing/dp'
                // var date = ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
                // var customer = this.state[array][arraykey].idCustomer
                // params = {
                //     date: date,
                //     customer: customer,
                // }
                break;
            case "getPayBill":
                var url = process.env.REST_URL + "helper/get-pay-billing";
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var sales =
                    this.state.salesCurrent != null
                        ? this.state.salesCurrent.value
                        : null;
                params = {
                    date: date,
                    branch: branch,
                    sales: sales,
                };
                break;
        }

        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }

    render() {
        const { data, helperBilling, isProcessing, dataPayBilling } = this.props
        // console.log('PROPS', this.props)
        // console.log('STATE', this.state)
        if (helperBilling != null) {
            this.state.branchList = helperBilling.branch;
        }
        let detail = [];
        if (dataPayBilling != null && dataPayBilling != '') {
            detail = dataPayBilling[0]
            if (this.state.isPayment == 0) {
                this.state.transactionNo = detail.transaction_no,
                this.state.date = new Date(detail.date)
                this.state.branchCurrent = { value: detail.branch_id, label: detail.branch_name }
                this.state.salesCurrent = { value: detail.sales_id, label: detail.sales_name }

                this.changeData({
                    keyFill:"paymentNo",
                    type:"getCodePayment",
                    key:'code',
                  });

                  this.changeData({
                    keyFill:"bankAccountList",
                    type:"getBank",
                    key:'',
                  });

            }
            this.state.isPayment = 1;
        }

        return (
            <React.Fragment>
                {(!isProcessing) ?
                    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                        <div className="flex-row-fluid" id="kt_content">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-3 mt-3">
                                            <DatePickerCustom
                                                name={"date"}
                                                title={i18n.t("Billing Date")}
                                                required={true}
                                                selected={this.state.date}
                                                onSelect={async (e) => {
                                                    await this.handlerDateTime(e, 'date');
                                                    // await this.changeData('salesList', 'getEmployeeSales', '');
                                                    await this.changeData({
                                                        keyFill:"salesList",
                                                        type:"getEmployeeSales",
                                                        key:'',
                                                      })
                                                }
                                                }
                                                onChange={(e) => { }}
                                                startDay={0}
                                                disabled
                                                autoComplete={"off"}
                                                dateFormat="dd MMMM yyyy"
                                                maxDate={new Date()}
                                                minDate={this.state.limitDate}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="col-sm-3 mt-3">
                                            <Select2

                                                isClearable={true}
                                                name={"idBranch"}
                                                required={true}
                                                size={4}
                                                onChange={async (e) => {
                                                    this.setState({
                                                        salesCurrent: null, salesList: [],
                                                        transactionNo: '', storeCurrent: null, storeList: [], idStore: ''
                                                    });
                                                    if (e == null) {
                                                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                        await this.setState({ salesCurrent: null, salesList: [], storeCurrent: null, storeList: [] });
                                                    } else {
                                                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                    }
                                                }}
                                                options={this.state.branchList}
                                                title={i18n.t("Branch")}
                                                value={this.state.branchCurrent}
                                                placeholder={this.state.branchText}
                                                isDisabled
                                                labelfield={"true"}
                                            />
                                        </div>
                                        <div className="col-sm-3 mt-3">
                                            <Input
                                                type={"input"}
                                                name={"transactionNo"}
                                                title={i18n.t("Billing No.")}
                                                disabled
                                                value={this.state.transactionNo}
                                                handler={this.handler}
                                            />
                                        </div>
                                        <div className="col-sm-3 mt-3">
                                            <Select2
                                                isClearable={true}
                                                name={"idSales"}
                                                required={true}
                                                size={4}
                                                onChange={async (e) => {
                                                    if (e == null) {
                                                        await this.handlerSelect(e, "idSales", "salesCurrent");
                                                    } else {
                                                        await this.handlerSelect(e, "idSales", "salesCurrent");
                                                    }
                                                }}
                                                isDisabled
                                                options={this.state.salesList}
                                                title={i18n.t("Sales")}
                                                value={this.state.salesCurrent}
                                                placeholder={this.state.salesText}
                                                labelfield={"true"}
                                            />
                                        </div>

                                    </div>
                                    <div className='row'>

                                    </div>
                                    <div className='row'>
                                        <div className="col-3">
                                            <DatePickerCustom
                                                name={"datePayment"}
                                                title={i18n.t("Payment Date")}
                                                required={true}
                                                selected={this.state.datePayment}
                                                onSelect={async (e) => {
                                                    await this.handlerDateTime(e, 'datePayment');
                                                }
                                                }
                                                onChange={(e) => { }}
                                                startDay={0}
                                                autoComplete={"off"}
                                                dateFormat="dd MMMM yyyy"
                                                // maxDate={this.state.date}
                                                minDate={this.state.date}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <Input
                                                type={"input"}
                                                name={"paymentNo"}
                                                title={i18n.t("Payment No.")}
                                                required
                                                value={this.state.paymentNo}
                                                handler={this.handler}
                                                error={this.renderError('Billing.paymentNo')}
                                            />
                                        </div>
                                        <div key="trans" className='row'>
                                            <div className="col-12" style={{ fontSize: '40', fontWeight: 'bold' }}>
                                                {/* {i18n.t('Transaction No : ') + row[0].transaction_no } */}
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <Fragment key={"list"}>
                                            {this.renderList()}
                                            {this.renderTotal()}
                                        </Fragment>

                                    </div>
                                </div>
                                {/* {!isProcessing ?  :null} */}
                            </div>
                        </div>
                    </div>

                    :
                    <div className="text-center">
                        <div className="spinner-border  text-primary text-center  clearfix"></div>
                        <div className="text-center">{i18n.t("Loading")}</div>
                    </div>}
                <>
                    <Modal show={this.state.showImage} size={'lg'} backdrop={'static'} animationType="fade"
                        transparent={true}>
                        <Modal.Header>
                            <Modal.Title>{'Evidence & Signature'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <React.Fragment>
                                <Table>
                                    <tbody>
                                        <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                                            <td align='center'>
                                                <PhotoGalleryOne images={[this.state.viewImage]}></PhotoGalleryOne>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button variant="secondary" onClick={async (e) => {
                                await this.setState({ showImage: false })
                            }}>
                                {i18n.t("Close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </React.Fragment>

        );
    }
}

export default FormPay;
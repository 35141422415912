export default class OfferingActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_OFFERING='GET_HELPER_OFFERING';
    static GET_HELPER_OFFERING_FULL ='GET_HELPER_OFFERING_FULL';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static REJECT_OFFERING="REJECT_OFFERING";
    static APPROVE_OFFERING="APPROVE_OFFERING";
  
    static submit(){
      return {
        type: OfferingActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: OfferingActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: OfferingActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: OfferingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: OfferingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: OfferingActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: OfferingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: OfferingActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: OfferingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: OfferingActions.RESET_DATA,
      };
    }
  
    static helper(data){
      return {
        type: OfferingActions.GET_HELPER_OFFERING,
        payload:data
      };
    }
    static approve() {
      return {
        type: OfferingActions.APPROVE_OFFERING,
      };
    }

    static reject() {
      return {
        type: OfferingActions.REJECT_OFFERING,
      };
    }
    static helperFull(data){
      return {
        type:  OfferingActions.GET_HELPER_OFFERING_FULL,
        payload:data
      };
    }
    static resetList(){
      return {
        type: OfferingActions.RESET_LIST,
      };
    }
  }
  
  
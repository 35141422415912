
import React, { Component } from "react";
import Select2 from "../../../utilities/Form/Select2";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import Swal from "sweetalert2";
import i18n from "../../../../i18n";

const token = cookieManager.getUserToken();
export class FormTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      idBranch: "",
      idStore: "",
      branchValue: null,
      branchCurrent: null,
      idSetupValue: null,
      storeList: [],
      storeValue: null,
      storeCurrent: null,
      idItem: null,
      warehouseList: [],
      warehouseValue: null,
      stock: 0,
      subwarehouseList: [],
      subwarehouseValue: null,
      warehouseText: i18n.t("Select Warehouse"),
      branchText: i18n.t("Select Branch"),
      storeText: i18n.t("Select Store"),
      arraySave: [
        {
          branchCurrent: null,
          storeCurrent: null,
        },
      ],
      isUpdate: 0,
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    this.state.result = {
      idWarehouse:
        this.state.warehouseValue == null
          ? ""
          : this.state.warehouseValue.helper,
      idSubWarehouse:
        this.state.warehouseValue == null
          ? ""
          : this.state.warehouseValue.helper2,
      warehouseValue:
        this.state.warehouseValue == null ? "" : this.state.warehouseValue,
      branchValue: this.state.branchValue == null ? "" : this.state.branchValue,
      storeValue: this.state.storeValue == null ? "" : this.state.storeValue,
      idSetupValue:
        this.state.warehouseValue == null
          ? ""
          : this.state.warehouseValue.value,
      stock:
        this.state.warehouseValue == null
          ? ""
          : this.state.warehouseValue.stock,
    };
    return this.state.result;
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,

      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  renderDetail(detail, i) {}

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState(
      {
        ...this.state,
        [key]: arr,
      },
      async () => {
        await this.calculateAll();
      }
    );
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  changeData(keyFill, type = null, key = "", array = null, arraykey = null) {
    let params = null;
    switch (type) {
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != null ? this.state.branchValue.value : null;
        params = {
          id: branch,
        };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse-id";
        var idBranch =
          this.state.branchValue != null ? this.state.branchValue.value : null;
        var idStore =
          this.state.storeValue != null ? this.state.storeValue.value : null;
       var idItem =
          this.state.idItem != null ? this.state.idItem : null;   
        params = {
          branch: idBranch,
          store: idStore,
          item:idItem
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    const {  data, isNewRecord } = this.props;

    this.state.branchList = data.branchList;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.idItem = data.detail.idItem;
        this.state.branchValue =
          data.detail.idBranchMutation != null
            ? {
                value: data.detail.idBranchMutation,
                label: data.detail.nameBranch,
              }
            : null;

        this.state.storeValue =
          data.detail.idStoreMutation != null
            ? {
                value: data.detail.idStoreMutation,
                label: data.detail.nameStore,
              }
            : null;

        this.state.warehouseValue =
          data.detail.idSetupWH != ""
            ? {
                value: data.detail.idSetupWH,
                label: data.detail.nameWarehouse,
                helper: data.detail.idWarehouse,
                helper2: data.detail.idSubWarehouse,
              }
            : null;

        this.state.isUpdate = 1;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-12">
              <Select2
                isClearable={true}
                name={"branch"}
                title={i18n.t("Branch")}
                onChange={async (e) => {
                  if (e != this.state.branchValue) {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.changeData("storeList", "getStore", "");
                    await this.changeData("warehouseList", "getWarehouse", "");
                  }
                }}
                options={this.state.branchList}
                value={this.state.branchValue}
                placeholder={this.state.branchText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select2
                isClearable={true}
                name={"store"}
                title={i18n.t("Store")}
                onChange={async (e) => {
                  if (e != this.state.storeValue) {
                    await this.handlerSelect(e, "idStore", "storeValue");
                    await this.changeData("warehouseList", "getWarehouse", "");
                    await this.setState({
                       warehouseValue: "",
                       warehouseList: []
                      });
                  }
                }}
                options={this.state.storeList}
                value={this.state.storeValue}
                //isDisabled={this.state.branchValue == null ? true : false}
                placeholder={this.state.storeText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select2
                isClearable={true}
                name={"warehouse"}
                title={i18n.t("Warehouse")}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idWarehouse", "warehouseValue");
                  // await this.changeData("stock","getWarehouse","stock");
                }}
                options={this.state.warehouseList}
                value={this.state.warehouseValue}
                //isDisabled={this.state.branchValue == null ? true : false}
                placeholder={this.state.warehouseText}
              />
            </div>
          </div>
          <br />
          <hr />
        </form>
      </React.Fragment>
    );
  }
}

export default FormTransfer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import LogApproval from "../../../../components/modules/log/approval";
import LogApprovalMiddleware from "../../../../middleware/modules/log/approval";
// middlewares
const mapStateToProps = ({ auth, logApproval }) => ({
  isProcessing: logApproval.isProcessing,
  isError: logApproval.isError,
  errorMessage: logApproval.errorMessage,
  data: logApproval.data,
  showModal:logApproval.showModal,
  helper:logApproval.helperLogApproval
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper:()=>LogApprovalMiddleware.getHelper(),
      search: (data) => LogApprovalMiddleware.list(data),
      reset: () => LogApprovalMiddleware.resetList(),
      ApproveData: (data,value,search) => LogApprovalMiddleware.beforeApprove(data,value,search),
      RejectData: (data,value, search) => LogApprovalMiddleware.beforeReject(data, value,search),
      view: data =>LogApprovalMiddleware.getOne(data),
    },
    dispatch
  );
};
class LogApprovalContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.viewItem=this.viewItem.bind(this);
  }

  viewItem(id){
    this.props.view(id)
  }

  componentWillMount() {
    this.props.reset();
    this.props.getHelper();
  }
  render() {
     return (
      <React.Fragment>
         <Helmet>
            <title>{i18n.t("Log Approval")}</title>
          </Helmet>
          <LogApproval
           {...this.props}
           viewItem={this.viewItem}
          />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogApprovalContainer);

import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import Detailpo from "./detail-po";
import DetailCustomer from "./detail-customer";
import DetailOffering from "./detail-offering";
import DetailSellingPrice from "./detail-sellingprice";
import DetailMemoIn from "./detail-memoin";
import DetailMemoOut from "./detail-memoout";
import DetailReturnSelling from "./detail-return-selling";

const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { choose,module } = this.props;
    console.log(this.props)
    return (
      <Fragment>
        {choose != null ? (
          <Fragment>
              {(module=='Purchase Order')?<Detailpo {...this.props} ></Detailpo>:null}
              {(module=='Customer')?<DetailCustomer {...this.props} ></DetailCustomer>:null}
              {(module=='Goods Offer')?<DetailOffering {...this.props} ></DetailOffering>:null}
              {(module=='Selling Price')?<DetailSellingPrice {...this.props} ></DetailSellingPrice>:null}
              {(module=='Memo In')?<DetailMemoIn {...this.props} ></DetailMemoIn>:null}
              {(module=='Memo Out')?<DetailMemoOut {...this.props} ></DetailMemoOut>:null}
              {(module=='Return Selling')?<DetailReturnSelling {...this.props} ></DetailReturnSelling>:null}
          </Fragment>
        ):null}
      </Fragment>
    );
  }
}

export default Form;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}
import React, { Component, StrictMode } from "react";
import Select2 from "../../../utilities/Form/Select2";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import genRandonString from "../../../utilities/Class/RandomString";
import Table from "./table";
const MySwal = withReactContent(Swal);
export class Approval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeList:[],
      typeCurrent:null,
      typeText:'Select Type',
      branchList:[],
      branchCurrent:null,
      branchText:'Select Branch',
      isDropped:false,
      data:[],
      reload:0,
      employeeList:[],
      container:[
      ]
    };
    this.handlerSelect = this.handlerSelect.bind(this)
    this.changeData = this.changeData.bind(this)
    
  }

  changeData(props){
   
   if((this.state.typeCurrent!=null && this.state.branchCurrent!=null)){
      let post={
        type:(this.state.typeCurrent!=null)?this.state.typeCurrent.value:null,
        branch:(this.state.branchCurrent!=null)?this.state.branchCurrent.value:null,
        ...props
      }
      MySwal.fire({
        title: i18n.t("Saving Data"),
        html: 'Please wait ...',
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      this.props.save(post);
      MySwal.showLoading()
   }else{
      MySwal.fire({
        title: i18n.t("Validate"),
        html: 'Please Check your input data, type must be filled and list User Approval must be filled',
        icon: "error",
      });
   }
   
  }
  handlerSelect(e, name, currentState) {
      this.setState({ [currentState]: e ,reload:0,container:[]},()=>{
        if(this.state.typeCurrent !==null && this.state.branchCurrent!=null){
          let params={
            type:this.state.typeCurrent.value,
            branch:this.state.branchCurrent.value
          }
          this.props.getData(params);
        } 
      })
  }
  renderData(){
    const {data}=this.props
    let array=[]
    if(data!=null){
      for(var o in data.container){
        let ii=data.container[o].items
        array.push(...ii)
      }
    }
   
    return array;
  }
  render() {
    const { helperList ,data,isProcessing} = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    this.state.typeList=(helperList !=undefined || helperList !=null)?helperList.type:[]
    this.state.branchList=(helperList !=undefined || helperList !=null)?helperList.branch:[]
    if(data !=null){  
      this.state.reload=1;
    }
    
    return (
      <div id="approvalList" className="d-flex flex-column  flex-column-fluid">
        {/* <Breadcrumbs url={this.state.breadcrumbs} tools={false} /> */}
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <Select2
                        isClearable={false}
                        name={"type"}
                        required={true}
                        onChange={async(e) => {
                            await this.handlerSelect(e, "type", "typeCurrent")
                        }}
                        options={this.state.typeList}
                        title={i18n.t("Type Transaction")}
                        value={this.state.typeCurrent}
                        currentState={"typeCurrent"}
                        placeholder={this.state.typeText}
                        // error={this.renderError("type")}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select2
                        isClearable={false}
                        name={"branch"}
                        required={true}
                        onChange={async(e) => {
                            await this.handlerSelect(e, "branch", "branchCurrent")
                        }}
                        options={this.state.branchList}
                        title={i18n.t("Branch")}
                        value={this.state.branchCurrent}
                        currentState={"branchCurrent"}
                        placeholder={this.state.branchText}
                        // error={this.renderError("type")}
                    />
                  </div>
                  {/* <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    value={""}
                    handler={(e)=>{}}
                    error={null}
                    placeholder={i18n.t("Search Employee")}
                    labelfield="false"
                    addOns={
                      <button type="button" className="bg-none input-group-text" onClick={(e)=>{}}><FontAwesomeIcon icon={faRefresh}/></button>
                    }
                    /> */}
                </div>
                {(this.state.typeCurrent!=null && !isProcessing)?  
                <div className="row">
                  <Table key={genRandonString(10)} onChange={this.changeData} {...this.props} employeeList={(helperList !=undefined || helperList !=null)?helperList.employee:[]} result={"used"}></Table>
                  {/* <CustomDnd key={genRandonString(10)} onChange={this.changeData} result={"used"} container={this.state.container} data={this.renderData()} /> */}
                 </div>
                 :(isProcessing)?<>Loading</>:null}
              
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  }
}

export default Approval;

import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from "../../../utilities/Form/Select2";
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import TextArea from '../../../utilities/Form/TextArea';
import Day from '../../../utilities/Const/Day';
import cookieManager from '../../../../utils/cookieManager';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branch:"",
            branchName:"",
            store:"",
            storeName:"",
            employee:"",
            employeeName:"",
            isUpdate: 0,
            customerList:[],
            weekList:[],
            dayList:[],
            districtList:[],
            subDistrictList:[],
            oddEvenList:[{label:'Odd',value:1},{label:'Even',value:2}],
            customerText:i18n.t('Select Customer'),
            weekText:i18n.t('Select Week'),
            dayText:i18n.t('Select Day'),
            districtText:i18n.t('Select District'),
            subDistrictText:i18n.t('Select Sub District'),
            oddEvenText:i18n.t('Select Odd / Even'),
            districtCurrent:null,
            subDistrictCurrent:[],
            customerCurrent:null,
            weekCurrent:[],
            dayCurrent:[],
            typeCustomerList:[],
            typeCustomerText:i18n.t('Select Type Customer'),
            typeCustomerCurrent:[],
            oddEvenCurrent:{label:'Odd',value:1},
            form:{
              idCustomer:null,
              idDistrict:null,
              idSubDistrict:[],
              idOddEven:1,
              day:[],
              week:[],
              idTypeCustomer:[],
              typeWeek:0,//0:select week, 1: odd/even
              typeCustomer:0,//0:single, 1: Area
              typeDay:0,//0:Choose, 1: All
            }
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
        this.setState({ [currentState]: e })
    }

    runSubmit() {
      let customer=[];
      let week=[];
      let day=[];
      let subDistrict=[];
      let idTypeCustomer=[];
      let weekList=this.state.weekCurrent
      let dayList=this.state.dayCurrent
      let subDistrictList=this.state.subDistrictCurrent
      let typeCustomer=this.state.typeCustomerCurrent;
     
      if(this.state.customerCurrent !=null){
        customer.push(this.state.customerCurrent.value)
      }
     
      for(var i=0;i< typeCustomer.length ;i++){
        if(typeCustomer[i].value !=undefined){
          idTypeCustomer.push(typeCustomer[i].value)
        }
      }
      for(var i=0;i< weekList.length ;i++){
        if(weekList[i].value !=undefined){
          week.push(weekList[i].value)
        }
      }

      for(var i=0;i< dayList.length ;i++){
        if(dayList[i].value !=undefined){
          day.push(dayList[i].value)
        }
      }

      for(var i=0;i< subDistrictList.length ;i++){
        if(subDistrictList[i].value !=undefined){
          subDistrict.push(subDistrictList[i].value)
        }
      }
      

      let data=Object.assign({},this.state.form,{
        branch:this.state.branch,
        store:this.state.store,
        employee:this.state.employee,
        year:this.state.year,
        customer:customer,
        idTypeCustomer:idTypeCustomer,
        week:week,
        day:day,
        idSubDistrict:subDistrict
    },)
      let status=true
      return {status:status,data:data};
    }
    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    getSubDistrict(value){
      const token = cookieManager.getUserToken();
      if(value){
        const params={
          id:value
        }
        axios({
          method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            url: process.env.REST_URL + 'helper/get-sub-district',
            responseType: "json",
            params: params,
          })
            .then((response) => {
              if (response && response.status === 200 && response.data) {
                this.setState({subDistrictList:response.data.result});
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
   
    render() {
        const { data, isNewRecord, helper } = this.props
        this.state.customerList = helper != null ? helper.customer : [];
        this.state.typeCustomerList = helper != null ? helper.typeCustomer : [];
        this.state.districtList = helper != null ? helper.district : [];
        this.state.weekList = helper != null ? helper.week : [];
        this.state.branch=helper != null ? helper.branch.value : null;
        this.state.branchName=helper != null ? helper.branch.label : null;
        this.state.store=helper != null ? ((helper.store !==null)?helper.store.value:null) : null;
        this.state.storeName=helper != null ? ((helper.store !==null)?helper.store.label:null) : null;
        this.state.employee=helper != null ? helper.employee.value : null;
        this.state.employeeName=helper != null ? helper.employee.label : null;
        this.state.year=helper != null ? helper.year : null;
        if (data != null && !isNewRecord) {
            // if (this.state.isUpdate == 0) {
            //     this.state.form.name = data.name,
            //     this.state.form.code = data.code,
            //     this.state.form.address = data.address,
            //     this.state.provinceCurrent = {
            //         value: data.province_id,
            //         label: data.province_name != "" ? data.province_name : this.state.provinceText,
            //       };
            //     this.state.cityCurrent = {
            //         value: data.city_id,
            //         label: data.city_name != "" ? data.city_name : this.state.cityText,
            //       };    
            //     this.state.form.idCity = data.city_id,  
            //     this.state.form.idProvince = data.province_id,  
            //     this.state.form.status = data.status
            //     this.state.isUpdate = 1
            //     // this.props.changeSelect("idProvince", data.province_id);
            // }
        }
        return (
            <form>
              <div className="col-sm-12">
                <table>
                  <tbody>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Branch")}</td>
                      <td>:</td>
                      <td>{this.state.branchName}</td>
                    </tr>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Store")}</td>
                      <td>:</td>
                      <td>{this.state.storeName}</td>
                    </tr>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Employee Name")}</td>
                      <td>:</td>
                      <td>{this.state.employeeName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
                <div className="col-sm-12">
                  <Radio
                        list={{
                          0: i18n.t("Single Customer"),
                          1: i18n.t("Area"),
                        }}
                        name={"typeCustomer"}
                        title={i18n.t("type")}
                        inline={false}
                        value={this.state.form.typeCustomer}
                        handler={this.handler}
                    />
                 {this.state.form.typeCustomer==0&&<Select2
                    // closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idCustomer"}
                    required={true}
                    onChange={(e) =>
                      this.handlerSelect(e, "idCustomer", "customerCurrent")
                    }
                    // isMulti={true}
                    options={this.state.customerList}
                    title={i18n.t("Customer")}
                    value={this.state.customerCurrent}
                    currentState={"customerCurrent"}
                    placeholder={this.state.customerText}
                    error={this.renderError("idCustomer")}
                  /> }
                  {this.state.form.typeCustomer==1&&<Select2
                    // closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idDistrict"}
                    required={true}
                    onChange={(e) =>{
                      this.handlerSelect(e, "idDistrict", "districtCurrent")
                      if(e !=null){
                        this.getSubDistrict(e.value)
                      }
                    }
                      
                    }
                    // isMulti={true}
                    options={this.state.districtList}
                    title={i18n.t("District")}
                    value={this.state.districtCurrent}
                    currentState={"districtCurrent"}
                    placeholder={this.state.districtText}
                    error={this.renderError("idDistrict")}
                  /> }
                  {this.state.form.typeCustomer==1&&<Select2
                    closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idSubDistrict"}
                    required={false}
                    onChange={(e) =>
                      this.handlerSelect(e, "idSubDistrict", "subDistrictCurrent")
                    }
                    isMulti={true}
                    options={this.state.subDistrictList}
                    title={i18n.t("Sub District")}
                    value={this.state.subDistrictCurrent}
                    currentState={"subDistrictCurrent"}
                    placeholder={this.state.subDistrictText}
                    error={this.renderError("idSubDistrict")}
                  /> }
                  {this.state.form.typeCustomer==1&&<Select2
                    closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idTypeCustomer"}
                    required={false}
                    onChange={(e) =>
                      this.handlerSelect(e, "idTypeCustomer", "typeCustomerCurrent")
                    }
                    isMulti={true}
                    options={this.state.typeCustomerList}
                    title={i18n.t("Type Customer")}
                    value={this.state.typeCustomerCurrent}
                    currentState={"typeCustomerCurrent"}
                    placeholder={this.state.typeCustomerText}
                    error={this.renderError("idTypeCustomer")}
                  /> }
                </div>
                
                <div className="col-sm-12">
                  <Radio
                        list={{
                          0: i18n.t("Choose Weeks"),
                          1: i18n.t("Odd / Even"),
                          2: i18n.t("All Week"),
                        }}
                        name={"typeWeek"}
                        title={i18n.t("Type Week")}
                        inline={false}
                        value={this.state.form.typeWeek}
                        handler={this.handler }
                    />
                 {this.state.form.typeWeek ==0 && <Select2
                    closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idWeek"}
                    required={true}
                    onChange={(e) =>
                      this.handlerSelect(e, "idWeek", "weekCurrent")
                    }
                    isMulti={true}
                    options={this.state.weekList}
                    title={i18n.t("Week")}
                    value={this.state.weekCurrent}
                    currentState={"weekCurrent"}
                    placeholder={this.state.weekText}
                    error={this.renderError("idWeek")}
                  /> }
                   {this.state.form.typeWeek==1 && <Select2
                    isClearable={false}
                    name={"idOddEven"}
                    required={true}
                    onChange={(e) =>
                      this.handlerSelect(e, "idOddEven", "oddEvenCurrent")
                    }
                    options={this.state.oddEvenList}
                    title={i18n.t("Odd / Even")}
                    value={this.state.oddEvenCurrent}
                    currentState={"oddEvenCurrent"}
                    placeholder={this.state.oddEvenText}
                    error={this.renderError("idOddEven")}
                  /> }
                </div>
                <div className="col-sm-12">
                  <Radio
                        list={{
                          0: i18n.t("Choose Days"),
                          1: i18n.t("All"),
                        }}
                        name={"typeDay"}
                        title={i18n.t("Type Days")}
                        inline={false}
                        value={this.state.form.typeDay}
                        handler={this.handler}
                    />
                 {this.state.form.typeDay ==0 &&<Select2
                    closeMenuOnSelect={false}
                    isClearable={true}
                    name={"idDay"}
                    required={true}
                    onChange={(e) =>
                      this.handlerSelect(e, "idDay", "dayCurrent")
                    }
                    isMulti={true}
                    options={Day}
                    title={i18n.t("Day")}
                    value={this.state.dayCurrent}
                    currentState={"dayCurrent"}
                    placeholder={this.state.dayText}
                    error={this.renderError("idDay")}
                  /> } 
                </div>
                <b>Note:</b>
                <div className="text-danger">{i18n.t("Schedules that have not been realized in the selected week will be deleted and replaced with the current schedule, schedules that have been realized will not change")}</div>
            </form>
        );
    }
}

export default Form;
import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from "../../../utilities/Form/Select2";
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import TextArea from '../../../utilities/Form/TextArea';
import GoogleMapReact from 'google-map-react';

export class Form extends Component {

    constructor(props) {
        super(props)
        this.state = {

            form: {
                name: '',
                code: '',
                status: 1,
                target: 0,
            },
            map: 0,
            isUpdate: 0,

        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
        this.marker = null
    }

    componentDidMount(){
       
    }
    
    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
        this.setState({ [currentState]: e })
        this.props.changeSelect(name, value);
    }

    runSubmit() {
        return this.state.form
    }
    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    render() {
        const { data, isNewRecord, helperInfoDistribution, cityList,getGeo } = this.props
        console.log(this.props)

        if (data != null && !isNewRecord) {
            if (this.state.isUpdate == 0) {
                this.state.form.code = data.code,
                this.state.form.target = data.target,
                this.state.isUpdate = 1
            }
        }

        return (
            <form>
                {/* <div className="row">
                    <div className="col-md-12">
                        <Input
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code InfoDistribution")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code  ")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-12">
                        <TextArea
                            name={"address"}
                            title={i18n.t("Address")}
                            value={this.state.form.address}
                            handler={this.handler}
                            error={this.renderError("address")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Select2
                            isClearable={true}
                            name={"idProvince"}
                            required={true}
                            onChange={async (e) => {
                                await this.setState({ cityList: [], cityCurrent: null })
                                await this.handlerSelect(e, "idProvince", "provinceCurrent")
                            }}
                            options={this.state.provinceList}
                            title={i18n.t("Province")}
                            value={this.state.provinceCurrent}
                            currentState={"provinceCurrent"}
                            placeholder={this.state.provinceText}
                            error={this.renderError("idProvince")}
                        />
                    </div>
                    <div className="col-md-6">
                        <Select2
                            isClearable={true}
                            name={"idCity"}
                            required={false}
                            onChange={(e) => this.handlerSelect(e, "idCity", "cityCurrent")}
                            options={this.state.cityList}
                            title={i18n.t("City")}
                            value={this.state.cityCurrent}
                            currentState={"cityCurrent"}
                            placeholder={this.state.cityText}
                            error={this.renderError("idCity")}
                        />
                    </div>
                </div> */}
                 <div className="row">
                    <Radio
                        list={StatusForm}
                        name={"target"}
                        title={i18n.t("Target")}
                        inline={false}
                        value={this.state.form.target}
                        handler={this.handler}
                    />
                </div>
                <div className="row">
                    <Radio
                        list={StatusForm}
                        name={"status"}
                        title={i18n.t("Status")}
                        inline={false}
                        value={this.state.form.status}
                        handler={this.handler}
                    />
                </div>
              
            </form>
        );
    }
}

export default Form;
import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
// import Modal from '../../../utilities/modal';
import { Button, Dropdown, Image, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/sales/sales-target/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync,faEllipsis } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import {
  transactionSales,
} from "../../../utilities/Const/TranscationCode";
import LabelSales from "../../../utilities/Status/sales";
import FormTarget from "../../../../containers/modules/sales/sales-target/form-target";
import Form from "../../../../containers/modules/sales/sales-target/form";
const MySwal = withReactContent(Swal);
export class IndexTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Sales"), class: " text-gray-600" },
        { link: null, title: i18n.t("Sales Target"), class: " text-gray-500" },
      ],
      column: [
        // {
        //   key: "start_date",
        //   filter: { active: true, type: "Date", data: null },
        //   title: i18n.t("Start Date"),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
        // {
        //   key: "end_date",
        //   filter: { active: true, type: "Date", data: null },
        //   title: i18n.t("End Date"),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch Name"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Target"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        // {
        //   key: "name_sales",
        //   filter: { active: true, type: "Text", data: null },
        //   title: i18n.t("Sales Name"),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
        // {
        //   key: "status",
        //   filter: {
        //     active: true,
        //     type: "Select",
        //     data: transactionSales,
        //   },
        //   title: i18n.t("Status"),
        //   type: "component",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        //   component: (data, column) => {
        //     return <LabelSales value={data[column.key]} />;
        //   },
        // },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                 {data.check == true? (
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 m-0">
                    {/* Edit Button */}
                      <>
                      <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.updateData(data.id)}
                          >
                            {i18n.t("Edit")}
                          </Dropdown.Item>
                        </div>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.deleteData(data.id)}
                          >
                            {i18n.t("Delete")}
                          </Dropdown.Item>
                        </div>
                      </>
                   
                  </Dropdown.Menu>
                </Dropdown>
                 ) : null}
              </Fragment>
            );
          },
        },
        // {
        //   key: "id",
        //   filter: {
        //     active: false,
        //   },
        //   title: i18n.t("Action"),
        //   type: "action",
        //   tHclass: "text-end min-w-70px",
        //   tdClass: "",
        //   buttons: [
        //     {
        //       key: "id",
        //       btnText: i18n.t("Edit"),
        //       visible: {
        //         key: "status",
        //         params: [1],
        //         function: (id, params) => {
        //           return params.includes(id);
        //         },
        //       },
        //       btnFunction: (data, column) => {
        //         this.updateData(data);
        //       },
        //       bthClass: "btn btn-warning",
        //     },
        //     {
        //       key: "id",
        //       btnText: i18n.t("Cancel"),
        //       visible: {
        //         key: "status",
        //         params: [1],
        //         function: (id, params) => {
        //           return params.includes(id);
        //         },
        //       },
        //       btnFunction: (data, column) => {
        //         this.deleteData(data);
        //       },
        //       bthClass: "btn btn-danger",
        //     },
            
        //   ],
        // },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Sales Target");
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Sales Target");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You Will Delete This Data")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Accept")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModalTarget } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.createData()}
                    >
                      {i18n.t("Add Sales Target")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "sales_detail",
                    columns: [
                      { label: i18n.t("Start Date"), key: "start_date_format" },
                      { label: i18n.t("End Date"), key: "end_date_format" },
                      { label: i18n.t("Item Name"), key: "item_name" },
                      { label: i18n.t("Type"), key: "type_name" },
                      { label: i18n.t("Qty"), key: "qty_format" },
                      { label: i18n.t("Nominal"), key: "revenue_format" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormTarget
          showModalTarget={showModalTarget}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default IndexTarget;

import {
  faTrash,
  faBoxArchive,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
import axios from "axios";
import InputNumber from "../../../utilities/Form/InputNumber";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import InputCustom from "../../../utilities/Form/InputCustom";
import SwitchButton from '../../../utilities/Form/SwitchButton';
import FormConvertion from "./formConvertion";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Selling: {},
        SellingDetail: [],
      },
      top: 0,
      dataPromo: [],
      transactionNo: "",
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      customerCurrent: null,
      customerText: i18n.t("Select Customer"),
      customerList: [],
      detailDeliverOrder: [],
      storeCurrent: null,
      storeText: i18n.t("Select Store"),
      storeList: [],
      addDate: null,
      date: new Date(),
      valid: 0,
      paymentTypeList: {
        Cash: i18n.t("Cash/Transfer"),
        Credit: i18n.t("Credit"),
      },
      paymentType: "Cash",
      idWarehouse: null,
      marketing: null,
      BGDate: new Date(),
      bankAccountList: [],
      bankAccountCurrent: null,
      bankAccountText: i18n.t("Cash/Bank account"),
      dueDate: new Date(),
      tax: false,
      type: 0,
      List: [],
      limitCredit: null,
      typeCustomer: null,
      taxValue: 10,
      sisa: null,
      isUpdate: 0,
      grandTotal: 0,
      subTotal: 0,
      documentNo: '',
      discountHeader: 0,
      doText: i18n.t("Select Delivery Order"),
      doCurrent: null,
      doList: [],
      salesCurrent: null,
      salesList: [],
      itemList: [],
      itemText: i18n.t("Select Item"),
      idSales: '',
      salesText: i18n.t("Select Sales"),
      totalDP: 0,
      useDP: false,
      saveDP: false,
      tempDP: 0,
      itemType: 0,
      tempLimit: 0,
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      warehouseCurrent: null,
      checkDownpayment: false,
      downPayment: 0,
      formDetail: 0,
      itemList: [],
      downPayment:0,
      modalDetail:false,
      itemText: i18n.t("Select Item"),
      arrayDetail: [
        {
          marketing: [],
          unitList: [],
          unitText: i18n.t("Select Unit"),
          warehouseValue: null,
          warehouseList: [],
          warehouseText: i18n.t("Select Warehouse"),
          itemValue: null,
          unitValue: null,
          qty: 0,
          unitPrice: 0,
          discountPrice: 0,
          discount: 0,
          tax: 0,
          idWarehouse: null,
          taxPrice: 0,
          nettPrice: 0,
          total: 0,
          description: "",
          stock: 0,
          lastStock: 0,
          qtyUnit: 1,
          downPayment:0,
          idDP:null,
          unitLabel: '',
          bandNo: null,
          stokDetail: null,
          convertionDetail:[]
        },
      ],
    };
    this.formConvertion = React.createRef();
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.discountChange = this.discountChange.bind(this);

  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  setConvert(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let convert = parseFloat(this.state.arrayDetail[i].qtyUnit) || 0;
    let con = 0;
    con = qty * convert;
    objectss["convert"] = con;

    this.setState(arr);
  }

  calculateRow(i, arrayname) {
   
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let discountPromo = 0;

    let price = parseFloat(this.state.arrayDetail[i].unitPrice) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    if (this.state.arrayDetail[i].promoDetail != null) {
      for (var p = 0; p < this.state.arrayDetail[i].promoDetail.length; p++) {
        if (this.state.arrayDetail[i].promoDetail[p].label == 'DISCOUNT') {
          if (qty >= this.state.arrayDetail[i].promoDetail[p].minimum) {
            discountPromo += parseFloat(this.state.arrayDetail[i].promoDetail[p].diskon) || 0;
          }
        }
      }
    }

    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }
    let priceNett = price + priceTax - discount;
    let priceTotal = (priceNett * qty); 
    objectss["discountPromo"] = (discountPromo* qty);
    objectss["nettPrice"] = priceNett;
    objectss["taxPrice"] = priceTax;
    objectss["total"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }

  calculateAll() {
    let subTotal = 0;
    let detail = [];
    let grandTotal = 0;
    let remaining = 0;
    let taxTotal = 0;
    let discountTotal = 0; 
    let dpTotal=0
    let sisa = parseFloat(this.state.sisa) || 0;
    let arrDP = [];

    detail = this.state.arrayDetail;

    if (this.state.arrayDetail.length > 0) {
      for (let i = 0; i < detail.length; i++) {
        let price = parseFloat(detail[i].unitPrice) || 0;
        let qty = parseFloat(detail[i].qty) || 0;
        let total = parseFloat(detail[i].total) || 0;
        let discount = parseFloat(detail[i].discountPrice) || 0;
        let discountPromo = parseFloat(detail[i].discountPromo) || 0 ;
        let tax = parseFloat(detail[i].taxPrice) || 0;
        let dp=parseFloat(detail[i].downPayment) || 0

        if(i == 0){
          arrDP[i] = detail[i].idDP;
          dpTotal += dp
      }else{
          if(arrDP.includes(detail[i].idDP) == false){
              arrDP[i] = detail[i].idDP;
              dpTotal += dp
          }

      }
        subTotal += total;
        // dpTotal += dp;
        discountTotal += (discount * qty) + (discountPromo );
        taxTotal += tax * qty;
        grandTotal += total;
        grandTotal = grandTotal - discountTotal;
        console.log('1',grandTotal)
        console.log('sub',subTotal)
        console.log('2',sisa)
        console.log('3',price)
        switch (this.state.paymentType) {
          case 'Credit':
            console.log('Limit Credit sisa',sisa)
              console.log('update total credit')
              // if (grandTotal <= sisa && price != null) {
              this.setState({
                subTotal: subTotal,
                discountTotal: discountTotal,
                taxTotal: taxTotal,
                grandTotal: grandTotal,
                totalDP:dpTotal,
                remaining:(grandTotal-dpTotal > 0)?grandTotal-dpTotal:0,
              });
            // } else {
            //   console.log('masuk')
              // if (this.state.idDO != null) {
              //   this.alertError('Credit Limit', 'Total Invoice Cant Exceed Credit Limit', "info")
              //   this.handlerCurrencyArray(0, "qty", i, "arrayDetail")
              //   // this.setState({  grandTotal: 0, subTotal: 0 })
              // } else {
              //   this.alertError('Credit Limit', 'Total Invoice Cant Exceed Credit Limit', "info")
              //   this.handlerCurrencyArray(0, "qty", i, "arrayDetail")
              // }
            // }
            break;
          case 'Cash':
            this.setState({
              subTotal: subTotal,
              discountTotal: discountTotal,
              taxTotal: taxTotal,
              grandTotal: grandTotal,
              remaining:(grandTotal-dpTotal > 0)?grandTotal-dpTotal:0,
              totalDP:dpTotal,

            });
            break;
        }
      }
    } else {
      this.setState({
        subTotal: subTotal,
        discountTotal: discountTotal,
        taxTotal: taxTotal,
        grandTotal: grandTotal,
        totalDP:dpTotal,
        remaining:(grandTotal-dpTotal > 0)?grandTotal-dpTotal:0,

      });
    }
    if (this.state.paymentType =='Credit' && grandTotal > sisa) {
      alert('total melebihi limit credit customer')
    }
  }
  discountChange(e, i, arrayname, sender = "Price") {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(arr[i].unitPrice) || 0;
    var value = parseFloat(e) || 0;

    let percent = 0;
    let valuePrice = 0;
    if (sender == "Price") {
      percent = (value / price) * 100;
      valuePrice = value;
      if (value > price) {
        valuePrice = price;
        percent = 100;
      }
    }
    if (sender == "Percent") {
      percent = value;
      valuePrice = price * (value / 100);
      if (percent > 100) {
        valuePrice = price;
        percent = 100;
      }
    }

    objectss["discountPrice"] = valuePrice.toFixed(2);
    objectss["discount"] = percent.toFixed(2);
    this.setState(arr, () => this.calculateRow(i, arrayname));
  }

  runSubmit() {
    let totalHasil = 0;
    totalHasil = (this.state.totalDP - this.state.grandTotal)
    const Selling = {
      transactionNo: this.state.transactionNo,
      idBranch: (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value,
      idStore: (this.state.storeCurrent == null) ? '' : this.state.storeCurrent.value,
      idCustomer: (this.state.customerCurrent == null) ? '' : this.state.customerCurrent.value,
      idSales: (this.state.salesCurrent == null) ? '' : this.state.salesCurrent.value,
      doNo: this.state.documentNo,
      date: moment(this.state.date).format('YYYY-MM-DD'),
      idDO: (this.state.doCurrent == null) ? '' : this.state.doCurrent.value,
      tax: (this.state.tax) ? this.state.taxValue : '',
      paymentType: this.state.paymentType,
      dueDate: moment(this.state.dueDate).format('YYYY-MM-DD'),
      // BGDate: (this.state.paymentType != 'Credit') ? '' : moment(this.state.BGDate).format('YYYY-MM-DD'),
      subTotal: this.state.subTotal,
      downPayment:(totalHasil<0)?this.state.totalDP:this.state.grandTotal,
      totalPayment: this.state.grandTotal,
      remainingPayment: (this.state.paymentType == 'Credit') ? this.state.remaining : 0,
      discountHeader: (this.state.discountTotal == 0) ? 0 : this.state.discountTotal,
      bankAccount: (this.state.bankAccountCurrent != null) ? this.state.bankAccountCurrent.value : '',
      term: (this.state.paymentType == 'Credit') ? this.state.addDate.top : 0,
      status: (this.state.paymentType == 'Credit') ? 1 : 2,
      total: this.state.grandTotal,
      idWarehouse: (this.state.warehouseCurrent == null) ? '' : this.state.warehouseCurrent.helper,
      idSubWarehouse:  (this.state.warehouseCurrent == null) ? '' : this.state.warehouseCurrent.helper2 == null?'':this.state.warehouseCurrent.helper2,

    };
    const detail = this.state.arrayDetail;
    let bandNo = [];
    let qty = [];
    let description = [];
    let idUnit = [];
    let idItem = [];
    let total = [];
    let unitPrice = [];
    let nettPrice = [];
    let taxPrice = [];
    let tax = [];
    let discount = [];
    let discountPrice = [];
    let idDet = [];
    let downPayment=[]
    let idDP=[]
    let convertionDetail=[]



    for (var i = 0; i < detail.length; i++) {
      idItem.push(detail[i].idItem == null ? "" : detail[i].idItem.item);
      idDet.push(detail[i].idDet == null ? "" : detail[i].idDet);
      bandNo.push(detail[i].idItem == null ? "" : detail[i].idItem.no_band);
      qty.push(detail[i].qty);
      unitPrice.push(detail[i].unitPrice);
      discountPrice.push(detail[i].discountPrice);
      discount.push(detail[i].discount);
      tax.push(detail[i].tax);
      taxPrice.push((this.state.tax) ? this.state.taxValue : '');
      nettPrice.push(detail[i].nettPrice);
      total.push(detail[i].total);
      idUnit.push(detail[i].idItem == null ? "" : detail[i].idItem.id_unit);
      description.push(detail[i].description == null ? "" : detail[i].description);
      downPayment.push(detail[i].downPayment==null?"":detail[i].downPayment);
      idDP.push(detail[i].idDP==null?"":detail[i].idDP);
      convertionDetail.push(detail[i].convertionDetail)

    }
    this.state.form.Selling = Selling;
    this.state.form.SellingDetail = {
      idDet: idDet,
      bandNo: bandNo,
      qty: qty,
      description: description,
      taxPrice: taxPrice,
      unitPrice: unitPrice,
      discount: discount,
      discountPrice: discountPrice,
      taxPrice: taxPrice,
      tax: tax,
      nettPrice: nettPrice,
      idItem: idItem,
      idUnit: idUnit,
      total: total,
      description: description,
      downPayment:downPayment,
      idDP:idDP,
      convertionDetail:convertionDetail,

    };
    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  handlerSelectArrayUnit(e, name, i, arrayname) {

    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }

    this.setState(arr, () => this.calculateRow(i, arrayname));
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr, () => this.calculateRow(i, arrayname));
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  setItemDetail(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (this.state[arrayname][i].stokDetail != null && this.state[arrayname].itemList == null) {
      objectss['bandNo'] = this.state[arrayname][i].stokDetail.bandNo
      objectss['stock'] = this.state[arrayname][i].stokDetail.hasil
    } else {
      objectss['bandNo'] = this.state[arrayname][i].idItem.bandNo
      objectss['stock'] = this.state[arrayname][i].idItem.stock
    }

    this.setState(arr)

  }
  convertQty(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let check = 0;
    let diff = 0;
    diff = parseFloat(this.state.arrayDetail[i].idUnit.convertion) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    check = qty * diff;
    if (check > parseFloat(this.state.arrayDetail[i].stock) || 0) {
      this.alertError(
        "Info",
        i18n.t("Convert Quantity can't Exceed Quantity Stock Item"),
        "info"
      );
      this.handlerSelectArray(null, "idUnit", i, "arrayDetail")

    } else {
      this.handlerCurrencyArray(qty, "qty", i, "arrayDetail")
    }
    objectss["convert"] = check;
    this.setState(arr);
  }

  renderDetail(detail, i) {
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td >
            <Select2
              isClearable={true}
              name={"idItem"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.setState({ formDetail: 0 })
                // if (this.state.idDO == null) {
                //   await this.arrayReset();
                // }
                if (e == null && this.state.doCurrent == null) {
                  await this.handlerSelectArray(e, "idItem", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "qty", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "unitPrice", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "discount", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "discountPrice", i, "arrayDetail");
                  await this.handlerSelectArray('', "idUnit", i, "arrayDetail");
                } else if (e != null && this.state.doCurrent == null) {
                  await this.handlerSelectArray(e, "idItem", i, "arrayDetail");
                  // await this.changeData("unitList", "getUnitSelling", "", "arrayDetail", i);
                  // await this.changeData("stokDetail", "getDetailItemStock", "", "arrayDetail", i, () => {
                  //   this.setItemDetail(i, 'arrayDetail')
                  // });

                } else {
                  await this.handlerSelectArray(e, "idItem", i, "arrayDetail");

                  if (parseFloat(detail.stock) < parseFloat(detail.convert)) {
                    await this.alertError("Info", i18n.t("Quantity  can't Exceed Quantity Stock Item"), "info");
                    await this.handlerSelectArray(null, "idItem", i, "arrayDetail")
                    await this.handlerCurrencyArray(0, "stock", i, "arrayDetail")
                  }
                }
              }}
              options={this.state.doCurrent == null ? this.state.itemList : detail.itemList}
              value={detail.idItem}
              placeholder={this.state.itemText}
              title={i18n.t("Item")}
              labelfield={"false"}
            // isDisabled={this.state.idWarehouse == null ? true : (this.state.doCurrent == null) ? false : true}
            />
            <div
              className={
                detail.stock > 0 && detail.idItem != null && this.state.idWarehouse != null
                  ? "fv-plugins-message-container valid-feedback"
                  : "fv-plugins-message-container invalid-feedback"
              }
            >
              {detail.idItem != null && this.state.idWarehouse != null
                ?( "Stock Available: " +
                (detail.idItem.lastStok > 0
                  ? parseFloat(detail.idItem.lastStok)
                  : parseFloat(detail.idItem.lastStok)) + ' ' + detail.idItem.currentUnit)
                : "" }
              {/* {detail.idItem != null && this.state.idWarehouse != null && this.state.idDO != null && detail.idItem.lastStock > 0
                ? "Stock Available: " +
                (detail.stock > 0
                  ? parseFloat(detail.stock)
                  : parseFloat(detail.stock))
                : ""} */}
            </div>
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SellingDetail.idItem." + i)}
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col-9 p-0">
                <InputNumber
                  id={"qty" + i}
                  name="qty"
                  placeholder={i18n.t("Qty")}
                  defaultValue={0}
                  value={detail.qty}
                  required
                  decimalsLimit={2}
                  suffix={(detail.idItem !=null)?' '+detail.idItem.currentUnit:''}

                  // onValueChange={async (value, name) => {
                   
                  // }}
                  className="form-control  form-control-sm text-end"
                  labelfield={"false"}
                  title={i18n.t("Qty")}
                  disabled={true}
                // disabled={(detail.idItem == null) ? true : ((this.state.idDO == null) ? false : true)}
                />
              </div>
              {detail.idItem != null ? (
                <div className="col p-0">
              <Button
                variant={
                  detail.convertionDetail.length > 0 ? "warning" : "info"
                }
                className="btn p-2"
                onClick={(e) => {
                  this.setConvertion(detail, i, "arrayDetail", "qty");
                }}
              >
                <FontAwesomeIcon
                  icon={
                    detail.convertionDetail.length > 0
                      ? faBoxOpen
                      : faBoxArchive
                  }
                />
              </Button>
            </div> 
            ) : (
                ""
              )}
              </div>
            
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SellingDetail.qty." + i)}
            </div>
            <div>
              {this.state.idDO != null && detail.convert > 0
                ? "Stock Convert: " +
                (detail.convert > 0
                  ? parseFloat(detail.convert)
                  : parseFloat(detail.convert))
                : ""}
            </div>
            
              
          </td>
          {/* <td>
            <Select2
              isClearable={true}
              name={"idUnit"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                this.setState({ formDetail: 0 })
                if (e == null) {
                  await this.handlerSelectArray(e, "idUnit", i, "arrayDetail");
                  await this.changeData('unitPrice', 'getSellingPrice', 'price', 'arrayDetail', i, () => this.calculateRow(i, "arrayDetail"));
                } else {

                  await this.handlerSelectArray(e, "idUnit", i, "arrayDetail");
                  await this.convertQty(i, 'arrayDetail');
                  await this.changeData('unitPrice', 'getSellingPrice', 'price', 'arrayDetail', i, () => this.calculateRow(i, "arrayDetail"));
                  await this.changeData("marketing", "getDetailCustomerMarketing", "", "arrayDetail", i, () => this.marketing(i, 'arrayDetail'));

                }
              }}
              options={detail.unitList}
              value={detail.idUnit}
              placeholder={this.state.unitText}
              title={i18n.t("Unit")}
              isDisabled={(detail.idItem == null) ? true : ((this.state.idDO == null) ? false : true)}
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SellingDetail.idUnit." + i)}
            </div>
          </td> */}
          <td>
            <InputNumber
              id={"unitPrice" + i}
              name="unitPrice"
              title={i18n.t("Unit Price")}
              placeholder={i18n.t("Unit Price")}
              defaultValue={0}
              value={detail.unitPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "unitPrice", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SellingDetail.unitPrice." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"discount" + i}
              name="discount"
              title={i18n.t("Discount Percent")}
              placeholder={i18n.t("Discount Percent")}
              defaultValue={0}
              value={detail.discount}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.discountChange(value, i, "arrayDetail", "Percent")
              }
              className="form-control  form-control-sm text-end"
              suffix=" %"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"discountPrice" + i}
              name="discountPrice"
              title={i18n.t("Discount Price")}
              placeholder={i18n.t("Discount Price")}
              defaultValue={0}
              value={detail.discountPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.discountChange(value, i, "arrayDetail", "Price")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"taxPrice" + i}
              name="taxPrice"
              title={i18n.t("Tax")}
              placeholder={i18n.t("Tax")}
              defaultValue={0}
              value={detail.taxPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "taxPrice", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              disabled
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nettPrice" + i}
              name="nettPrice"
              title={i18n.t("Total Nett")}
              placeholder={i18n.t("Total Nett")}
              defaultValue={0}
              value={detail.nettPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "nettPrice", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              disabled
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"total" + i}
              name="total"
              title={i18n.t("Total Price")}
              placeholder={i18n.t("Total Price")}
              defaultValue={0}
              value={detail.total}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "total", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              disabled
              labelfield={"false"}
            />
          </td>
          <td>

            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>

          </td>
        </tr>

      </React.Fragment>
    );
  }

  marketing(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let data = [];

    if (this.state.arrayDetail[i] != null) {
      let promo = this.state.arrayDetail[i].marketing;
      if (promo.length > 0) {
        for (var p = 0; p < promo.length; p++) {
          data.push(
            {
              diskon: (promo[p].discountPrice == 0) ? promo[p].value : promo[p].discountPrice,
              minimum: promo[p].minimumOrder,
              label: (promo[p].discountPrice == 0) ? 'FREE ITEM' : 'DISCOUNT',
              unit: (promo[p].nameUnit == null) ? '' : promo[p].nameUnit,
              free: (promo[p].nameFreeItem == null) ? '' : promo[p].nameFreeItem,
              item: (this.state.arrayDetail[i].idItem.label)
            });
        }

        objectss['promoDetail'] = data;
        this.setState(arr);
        this.setState({ formDetail: 1 })
      } else {
        this.setState({ formDetail: 1 })
        objectss['promoDetail'] = data;
      }
    }
    if (this.state.idDO != null) {
      this.calculateRow(i, arrayname)
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    }, () => this.calculateAll());
  }

  validasiStock(i, arrayName) {
    this.state.valid = 1;
    let get = this.state.arrayDetail[i].stock;
    if (get <= 0) {
      this.alertError("Info", i18n.t("Stock not available"), "info");
      this.handlerCurrencyArray(null, "idWarehouse", i, "arrayDetail");
      this.state.valid = 0;
    }
  }

  fetchDeliverOrder() {
    this.arrayReset();
    let det = [];

    let item = this.state.detailDeliverOrder;
    this.state.customerCurrent = { value: item[0].idCustomer, label: item[0].nameCustomer }
    // this.state.salesCurrent = { value: item[0].idSales, label: item[0].nameEmployee }
    this.changeData({
      keyFill:"addDate",
      type:"getDetailCustomer",
      callback:()=>{this.addDate()}
    });
    this.changeData({
      keyFill:"tempLimit",
      type:"getLimitCredit",
      callback:()=>{this.calculateLimit()}
    });
    // this.changeData('addDate', 'getDetailCustomer', '', null, null, () => this.addDate());
    // this.changeData('tempLimit', 'getLimitCredit', '', null, null, () => this.calculateLimit())
    if (item != null) {
      var array = [];
      for (var o = 0; o < item.length; o++) {
        let key = o;
        det = [];
        let list = [];
        if (item[o].itemList.length > 0) {
          for (var i = 0; i < item[o].itemList.length; i++) {
            // if (item[o].idItem == item[o].itemList[i].id_item) {
              list.push({
                value: item[o].itemList[i].value,
                label: item[o].itemList[i].label,
                item: item[o].itemList[i].item,
                no_band: item[o].itemList[i].no_band,
                currentUnit: item[o].itemList[i].currentUnit,
                lastStok: item[o].itemList[i].lastStok,
                id_unit: item[o].itemList[i].id_unit,
                unit: item[o].itemList[i].unit,
                price_pack: item[o].itemList[i].price_pack,

                // 'label' => $list->relationItem->code . ' - ' . $list->relationItem->name . ' (' . $list->bandNo . ')',
                // 'value' => $list->idStock,
                // 'item' => $list->idItem,
                // 'no_band' => $list->bandNo,
                // 'lastStok' => $list->getStock($request),
                // 'currentUnit' => isset($list->relationItem->currentUnit->unit->name) ? $list->relationItem->currentUnit->unit->name : '',
                // 'id_unit' => isset($list->relationItem->currentUnit->unit->id) ? $list->relationItem->currentUnit->unit->id : '',
                // 'unit' => $itemList,
                // 'price_pack'=> isset($pricePack)?$pricePack->price:0
              })
            // }
          }
        };

        array.push({
          convertionDetail:[],
          idDet: item[o].id,
          qtyUnit: item[o].qtyUnit,
          // idItem: { value: item[o].idItem, label: item[o].name_item + ' - ' + band, item: item[o].idItem, },
          idUnit: { value: item[o].idUnit, label: item[o].name_unit },
          unitPrice: item[o].unit_price,
          qty: 0,
          // bandNo: band,
          // lastStock: '',
          itemList: list,
          unitPrice: item[o].price,
          labelUnit: item[o].labelUnit,
          // stock: '',
          idWarehouse: null,
          discount: 0,
          discountPrice: 0,
          tax: 0,
          taxPrice: 0,
          description: '',
          convert: item[o].convert,
          warehouseList: det,
          qtyUnit: item[o].qtyUnit,
          downPayment: item[o].downPayment,
          idDP:item[o].idDP
        });

        this.setState({ arrayDetail: array }, 
          ()=>{this.changeData({
            keyFill:"marketing",
            type:"getDetailCustomerMarketing",
            array: "arrayDetail",
            arraykey: key,
            callback:()=>{this.marketing(key, 'arrayDetail')}
          })})
      }

    }
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token = cookieManager.getUserToken();
    switch (type) {
      case "getItem":
        var url = process.env.REST_URL + "helper/get-item-selling";
        params = {
          id: this.state.warehouseCurrent !=null?this.state.warehouseCurrent.value:null,
          branch: this.state.branchCurrent !=null?this.state.branchCurrent.value:null,
          customer: this.state.customerCurrent !=null?this.state.customerCurrent.value:null,
          store: this.state.storeCurrent !=null?this.state.storeCurrent.value:null,
          date : ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
        };
        break;
      case "getEmployeeSales":
        var url = process.env.REST_URL + "helper/get-employee-sales";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case 'getDP':
        var url = process.env.REST_URL + 'sales/payment-confirmation/dp'
        var date = ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
        var customer = this.state.idCustomer
        params = {
          date: date,
          customer: customer,
        }
        if (customer == null || date == null) {
          type = null
        }
        break;
      case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case "getDetailCustomer":
        var url = process.env.REST_URL + "helper/get-detail-customer";
        var id =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        var date =
          ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
        params = {
          id: id,
          date: date
        };
        break;
      case "getDetailCustomerMarketing":
        var url = process.env.REST_URL + "helper/get-detail-customer-marketing";
        var id =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        var date =
          ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.item
            : null;
        var unit =
          this.state[array][arraykey].idUnit != null
            ? this.state[array][arraykey].idUnit.value
            : null;
        params = {
          id: id,
          date: date,
          idItem: item,
          idUnit: unit
        };
        break;
      case "getBank":
        var url = process.env.REST_URL + "helper/get-bank";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-selling";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getDeliverOrder":
        var url = process.env.REST_URL + "helper/get-delivery-order";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
          // type:0
        };
        break;
      case "getDetailDeliverOrder":
        var url = process.env.REST_URL + "helper/get-detail-sales-order";
        var id =
          this.state.doCurrent != null
            ? this.state.doCurrent.value
            : null;
        params = {
          id: id
        };
        break;
      case "getSetupWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
     
      
      case "getUnitSelling":
        var url = process.env.REST_URL + "helper/get-unit-selling";
        var a = this.state.doCurrent;
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.item
            : null;

        var unit =
          this.state[array][arraykey].idUnit != null
            ? this.state[array][arraykey].idUnit.value
            : null;
        params = {
          item: item,
          unit: unit,
        };
        break;
      case "getSellingPrice":
        var url = process.env.REST_URL + "helper/get-price-selling";
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.item
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var unit =
          this.state[array][arraykey].idUnit != null
            ? this.state[array][arraykey].idUnit.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          branch: branch,
          store: store,
          date: date,
          item: item,
          unit: unit,
        };
        break;
      case "getLimitCredit":
        var url = process.env.REST_URL + "helper/get-limit-credit";
        var id =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        params = {
          id: id,
        };
        break;
      case "getTax":
        var url = process.env.REST_URL + "helper/get-tax-effective";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          date: date,
          tax: "PPN",
        };
        break;
      case "getDetailItemStock":
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var warehouse =
          this.state.warehouseCurrent != null
            ? this.state.warehouseCurrent.helper
            : null;
        var subwarehouse =
          this.state.warehouseCurrent != null
            ? this.state.warehouseCurrent.helper2
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var bandno =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].idItem.bandno
            : null;
        var iditem =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.item
            : null;

        var url = process.env.REST_URL + "helper/get-detail-item-stock";
        params = {
          warehouse: warehouse,
          subwarehouse: subwarehouse,
          store: store,
          branch: branch,
          date: date,
          item: iditem,
          bandno: bandno
        };

        break;
      case "getItemStock":
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var warehouse =
          this.state.warehouseCurrent != null
            ? this.state.warehouseCurrent.helper
            : null;
        var subwarehouse =
          this.state.warehouseCurrent != null
            ? this.state.warehouseCurrent.helper2
            : null;
        // var itemType = 
        //   this.state.itemType != null
        //     ? this.state.itemType
        //     : null; 
        // var idDO = 
        //   this.state.doCurrent != null
        //     ? this.state.doCurrent.value
        //     : null; 
        var url = process.env.REST_URL + "helper/get-item-stock";
        params = {
          branch: idBranch,
          store: idStore,
          warehouse: warehouse,
          subwarehouse: subwarehouse,
          // type: itemType,
          // idDO: idDO

        };

        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // setDp() {
  //   this.setState({ totalDP: this.state.downPayment });
  // }
  // useDP(e) {
  //   this.setState({ checkDownpayment: !this.state.checkDownpayment }, () => this.calculateAll())
  // }

  reCalculate() {
    this.state.arrayDetail.map(async (detail, po) => {
      this.calculateRow(po, 'arrayDetail');
    })
  }

  addDate() {
    var a = moment(this.state.dueDate).add(this.state.addDate.top, 'days');
    this.state.dueDate = new Date(a);
    this.handlerDateTime(this.state.dueDate, "dueDate")
    this.state.typeCustomer = this.state.addDate.paymentType;
    this.state.limitCredit = this.state.addDate.creditLimit;
    this.typeCustomer();
  }

  limitCredit() {
    console.log('masuk limit')
    this.changeData({
      keyFill:"tempLimit",
      type:"getLimitCredit",
      callback:()=>{this.calculateLimit()}
    });
  }

  calculateLimit() {
    let limit = parseFloat(this.state.limitCredit) || 0;
    let remainingPayment = parseFloat(this.state.tempLimit) || 0;
    let sisaLimit = 0;
    sisaLimit = limit - remainingPayment;
    if (sisaLimit <= 0 && this.state.paymentType == 'Credit') {
      this.alertError('Credit Limit', 'Credit Limit Has Been Reached', "info")
      this.setState({ customerCurrent: null, typeCustomer: null, dueDate: new Date() }, () => this.typeCustomer())
    }
    this.setState({ sisa: parseFloat(sisaLimit) || 0 });
  }

  typeCustomer() {
    var type = this.state.typeCustomer;
    if (type == 'Cash') {
      this.setState({
        paymentTypeList: {
          Cash: i18n.t("Cash/Transfer"),
        }, paymentType: 'Cash'
      })
    } else if (type == 'Credit') {
      this.setState({
        paymentTypeList: {
          Credit: i18n.t("Credit"),
        }, paymentType: 'Credit'
      })
    } else {
      this.setState({
        paymentTypeList: {
          Cash: i18n.t("Cash/Transfer"),
          Credit: i18n.t("Credit"),
        }, paymentType: 'Cash'
      })
    }
  }

  arrayReset() {
    this.setState({ formDetail: 0 })
    this.setState([
      this.state.arrayDetail = [
        {
          unitList: [],
          convertionDetail: [],
          unitText: i18n.t("Select Unit"),
          itemList: [],
          itemText: i18n.t("Select Item"),
          warehouseList: [],
          idItem: null,
          idUnit: null,
          qty: 0,
          unitPrice: 0,
          discountPrice: 0,
          discount: 0,
          tax: 0,
          taxPrice: 0,
          nettPrice: 0,
          total: 0,
          description: "",
          stock: 0,
          lastStock: 0,
          qtyUnit: 1,
          unitLabel: '',
          promoDetail: [],
          marketing: [],
        },
      ],])

  }

  setList() {
    let arr = this.state.List;
    let arrDet = this.state.arrayDetail;

    for (var i = 0; i < arrDet.length; i++) {

    }
  }

   //CONVERTION
   setConvertion(detail, index, array, key) {
    if(detail.idItem !=null){
      let choose = {
        unitList: detail.idItem.unit,
        qty:detail.idItem.lastStok,
        convertionDetail: detail.convertionDetail,
        index: index,
        key: key,
        array: array,
      };
      this.setState({ itemChoose: choose, modalDetail: true });
    }
    
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss['unitPrice'] = parseFloat(objectss['idItem']['price_pack']);
        objectss["convertionDetail"] = result.detail;
        this.setState(arr,()=>this.calculateRow(result.index,'arrayDetail'));
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  render() {
    const { data, isNewRecord, helperSelling } = this.props;
    this.state.branchList = ((helperSelling != null) ? helperSelling.branch : [])
    // console.log('PROPS', this.props);
    console.log('STATE', this.state);
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Input
                type={"input"}
                name={"transactionNo"}
                title={i18n.t("Transaction No")}
                required
                disabled
                value={this.state.transactionNo}
                handler={this.handler}
                error={this.renderError('Selling.transactionNo')}
              />
            </div>
            <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                // onSelect={async (e) => {
                  
                // }}
                onChange={async(e) => {
                  await this.handlerDateTime(e, "date");
                  // await this.changeData('tempDP', 'getDP', 'totalDP', null, null, () => this.setDp())
                  await this.changeData({
                    keyFill:"transactionNo",
                    type:"getCode",
                    key:"code"
                  })
                 }}
                startDay={0}
                showYearDropdown
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                peekNextMonth
                showMonthDropdown
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"

                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3 mt-3">
              <Input
                type={"input"}
                name={"documentNo"}
                title={i18n.t("Document No")}
                value={this.state.documentNo}
                handler={this.handler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e != this.state.branchCurrent) {
                    await this.setState({
                      storeCurrent: null,
                      storeList: [],
                    });
                  }
                  await this.setState({
                    transactionNo: '', idDO: null, doList: [], bankAccountList: [], bankAccountCurrent: null,
                    warehouseList: [], doCurrent: null, customerCurrent: null, customerList: [],
                    dueDate: new Date(), subTotal: 0, grandTotal: 0, warehouseCurrent: '', idWarehouse: ''
                  })
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.arrayReset();
                  } else {
                    await this.arrayReset();
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData({
                      keyFill:"storeList",
                      type:"getStore",
                    })
                    await this.changeData({
                      keyFill:"customerList",
                      type:"getCustomer",
                    })
                    await this.changeData({
                      keyFill:"transactionNo",
                      type:"getCode",
                      key:"code"
                    })
                    await this.changeData({
                      keyFill:"bankAccountList",
                      type:"getBank",
                    })
                    await this.changeData({
                      keyFill:"doList",
                      type:"getDeliverOrder",
                    })
                    await this.changeData({
                      keyFill:"warehouseList",
                      type:"getSetupWarehouse",
                    })
                    await this.changeData({
                      keyFill:"salesList",
                      type:"getEmployeeSales",
                    })
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError('Selling.idBranch')}
              />
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
                  await this.setState({ bankAccountCurrent: null });
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                  await this.changeData({
                    keyFill:"bankAccountList",
                    type:"getCode",
                  })
                  await this.changeData({
                    keyFill:"doList",
                    type:"getDeliverOrder",
                  })
                  await this.changeData({
                    keyFill:"warehouseList",
                    type:"getSetupWarehouse",
                  })
                  if (e == null) {
                    await this.handlerSelect(e, "idStore", "storeCurrent");

                  }
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
              />
            </div>

            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idDO"}
                size={4}
                onChange={async (e) => {
                  await this.setState({
                    customerCurrent: null, itemList: [],
                    dueDate: new Date(), subTotal: 0, grandTotal: 0, warehouseCurrent: '', idWarehouse: ''
                  })
                  await this.arrayReset();
                  if (e == null) {
                    await this.handlerSelect(e, "idDO", "doCurrent");
                  } else {

                    await this.handlerSelect(e, "idDO", "doCurrent");
                    await this.changeData({
                      keyFill:"detailDeliverOrder",
                      type:"getDetailDeliverOrder",
                      callback:()=>{this.fetchDeliverOrder()}
                    })
                    // await this.changeData("detailDeliverOrder", "getDetailDeliverOrder", "", null,
                    //   null,
                    //   async () => {
                    //     await this.fetchDeliverOrder();
                    //   });
                  }
                }}
                options={this.state.doList}
                title={i18n.t("Delivery Order")}
                value={this.state.doCurrent}
                placeholder={this.state.doText}
              />
            </div>
          </div>
          <div className="row">
          <div className="col-md-3 mt-3">
          <Select2
                isClearable={true}
                name={"idCustomer"}
                size={4}
                onChange={async (e) => {
                  if (this.state.idDO == null) {
                    await this.arrayReset();
                  }
                  if (e == null) {
                      await this.handlerSelect(e, "idCustomer", "customerCurrent");

                      await this.setState({ dueDate: new Date(), typeCustomer: null, addDate: null, limitCredit: null, typeCustomer: null,warehouseCurrent:null,itemList:[] });
                      await this.typeCustomer();
                      await this.arrayReset();
                  } else {
                      await this.setState({ bankAccountCurrent: null, dueDate: new Date() });
                      await this.handlerSelect(e, "idCustomer", "customerCurrent");
                      await this.changeData({
                        keyFill:"addDate",
                        type:"getDetailCustomer",
                        callback:()=>{this.addDate()}
                      })
                      await this.changeData({
                        keyFill:"limitCredit",
                        type:"getDetailCustomer",
                        key:"creditLimit",
                        callback:()=>{this.limitCredit()}
                      })

                      if(this.state.warehouseCurrent != "" && this.state.warehouseCurrent != null){
                        await this.changeData({
                          keyFill:"itemList",
                          type:"getItem",
                        })
                      }
                    // // await this.changeData('addDate', 'getDetailCustomer', '', null, null, () => );
                    // await this.changeData('limitCredit', 'getDetailCustomer', 'creditLimit', null, null, () => this.limitCredit());
                    // // await this.changeData('tempDP', 'getDP', 'totalDP', null, null, () => this.setDp())

                  }
                }}
                options={this.state.customerList}
                title={i18n.t("Customer")}
                value={this.state.customerCurrent}
                placeholder={this.state.customerText}
                isDisabled={(this.state.doCurrent == null) ? false : true}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Selling.idCustomer")}
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idSales"}
                size={4}
                onChange={async (e) => {
                  await this.setState({ bankAccountCurrent: null });
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }}
                options={this.state.salesList}
                title={i18n.t("Sales")}
                value={this.state.salesCurrent}
                placeholder={this.state.salesText}
              // isDisabled={(this.state.doCurrent == null) ? false : true}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Selling.idSales")}
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idWarehouse"}
                size={4}
                onChange={async (e) => {
                  if (this.state.idDO == null) {
                    await this.arrayReset();
                  }
                  if (e == null) {
                    await this.handlerSelect(e, "idWarehouse", "warehouseCurrent");
                  } else {
                    await this.handlerSelect(e, "idWarehouse", "warehouseCurrent");
                    if (this.state.doCurrent == null) {
                      await this.setState({ itemType: 0 })
                      await this.changeData({
                        keyFill:"itemList",
                        type:"getItem",
                        callback:()=>{this.setList()}
                      })
                    } else {
                      await this.setState({ itemType: 1 })
                      await this.changeData({
                        keyFill:"itemList",
                        type:"getItem",
                        callback:()=>{this.setList()}
                      })
                      // await this.changeData('List', 'getItemStock', ''), () => this.setList();
                    }

                  }
                }}
                options={this.state.warehouseList}
                title={i18n.t("Warehouse")}
                value={this.state.warehouseCurrent}
                placeholder={this.state.warehouseText}
                isDisabled={this.state.customerCurrent!=null?false:true}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Selling.idWarehouse")}
              </div>
            </div>
            <div className="col-1 text-center">
              <InputCustom
                type={"checkbox"}
                className={
                  "form-check-input form-check form-check-custom form-check-solid m-zero-auto"
                }
                name={"tax"}
                title={i18n.t("Tax")}
                value={this.state.tax}
                checked={this.state.tax}
                onChange={async (e) => {
                  await this.changeData("taxValue", "getTax", "value");
                  await this.setState({ tax: !this.state.tax }, () => {
                    this.reCalculate();
                  });
                }}
              />

            </div>
            <div className="row">
              <div className="col-2">
                <Radio
                  list={this.state.paymentTypeList}
                  name={"paymentType"}
                  title={i18n.t("Payment Type")}
                  inline={true}
                  value={this.state.paymentType}
                  handler={this.handler}
                />
              </div>
              {this.state.paymentType == "BG" ? (
                <div className="col-md-3 mt-3">
                  <DatePickerCustom
                    name={"BGDate"}
                    title={i18n.t("BG Date")}
                    selected={this.state.BGdate}
                    onSelect={async (e) => {
                      await this.handlerDateTime(e, "BGdate");
                    }}
                    startDay={0}
                    autoComplete={"off"}
                    dateFormat="dd MMM yyyy"
                    minDate={this.state.date}
                    className="form-control form-control-sm"
                  />
                </div>
              ) : (
                ""
              )}
              {this.state.paymentType != "Credit" ? (
                <div className="col-md-3 mt-3">
                  <Select2
                    isClearable={true}
                    name={"bankAccount"}
                    className={""}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(
                        e,
                        "bankAccount",
                        "bankAccountCurrent"
                      );
                    }}
                    options={this.state.bankAccountList}
                    title={i18n.t("Cash/Bank Account")}
                    value={this.state.bankAccountCurrent}
                    placeholder={this.state.bankAccountText}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError("Selling.bankAccount")}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-3 mt-3">
                <DatePickerCustom
                  name={"date"}
                  title={i18n.t("Due Date")}
                  required={true}
                  selected={this.state.dueDate}
                  onSelect={async (e) => {
                    await this.handlerDateTime(e, "dueDate");
                  }}
                  startDay={0}
                  showYearDropdown
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  peekNextMonth
                  showMonthDropdown
                  autoComplete={"off"}
                  dateFormat="dd MMMM yyyy"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <br />
          <hr />

          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th width="20%" className="text-center">
                        {i18n.t("Item")}
                      </th>
                      <th width="14%" className="text-center">
                        {i18n.t("Qty")}
                      </th>
                      {/* <th width="11%" className="text-center">
                        {i18n.t("Unit")}
                      </th> */}
                      <th width="11%" className="text-center">
                        {i18n.t("Unit Price")}
                      </th>
                      <th width="8%" className="text-center">
                        {i18n.t("Discount Rate")}
                      </th>
                      <th width="10%" className="text-center">
                        {i18n.t("Discount Price")}
                      </th>
                      <th width="10%" className="text-center">
                        {i18n.t("Tax")}
                      </th>
                      <th width="10%" className="text-center">
                        {i18n.t("Total Nett")}
                      </th>
                      <th width="10%" className="text-center">
                        {i18n.t("Total Price")}
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrayDetail.map((detail, index) => {
                      return this.renderDetail(detail, index);
                    })}
                  </tbody>
                </Table>
                {this.state.doCurrent == null ?
                  <Button
                    variant="success"
                    onClick={(e) =>
                      this.addArray(
                        {
                          warehouseValue: null,
                          promoDetail: [],
                          convertionDetail: [],
                          itemValue: null,
                          unitValue: null,
                          qty: 0,
                          unitPrice: 0,
                          discountPrice: 0,
                          discount: 0,
                          tax: 0,
                          taxPrice: 0,
                          nettPrice: 0,
                          total: 0,
                          description: "",
                          idItem: null,
                          idUnit: null,
                        },
                        "arrayDetail"
                      )
                    }
                  >
                    {i18n.t("Add Item")}
                  </Button> : ''}
              </div>
            </div>
          </React.Fragment>
          <div className="row m-5">
            {(this.state.formDetail == 1) ?
              <React.Fragment>
                <div className="d-flex flex-start">
                  <div className="col-4">
                    <Table className="table table-bordered" responsive="sm">
                      <tbody>
                        <tr>{this.state.arrayDetail.map((unit, p) =>
                          <tr>
                            <td key={p + 'unitPromo'}>
                              {(unit.marketing != null) ?
                                (unit.idItem == null ? '' : 'Item : ' + unit.idItem.label + ' : ') : ''}
                            </td>
                            {(unit.promoDetail != null) ? unit.promoDetail.map((item, o) =>
                              <tr key={o + 'itemPromo'}>
                                <td>
                                  {(o + 1) + '. ' + item.label}
                                  {(item.label == 'DISCOUNT') ? ' (Rp. ' + item.diskon + '), ' :
                                    ' (' + item.diskon + ' ' + item.free + '), '}
                                  {'Minimum Qty : ' + item.minimum + ' ' + item.unit}
                                </td>
                              </tr>
                            ) : ''}</tr>
                        )}</tr>

                      </tbody>
                    </Table>
                  </div>
                </div>
              </React.Fragment> : ''}
            <div className="d-flex flex-end">
              <div className="col-4">
                <Table borderless responsive="sm">
                  <tbody>
                    <tr className="align-middle">
                      <td>{i18n.t("Sub Total")}</td>
                      <td>:</td>
                      <td>
                        <InputNumber
                          id={"subTotal"}
                          name="subTotal"
                          placeholder={i18n.t("Sub Total")}
                          defaultValue={0}
                          value={this.state.subTotal}
                          decimalsLimit={2}
                          onValueChange={(value, name) => { }}
                          className="p-0 form-control form-control-xs form-control-flush text-end"
                          prefix="Rp "
                          readOnly
                          labelfield={"false"}
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td>{i18n.t("Discount Total")}</td>
                      <td>:</td>
                      <td>
                        <InputNumber
                          id={"discountTotal"}
                          name="discountTotal"
                          placeholder={i18n.t("Discount Total")}
                          defaultValue={0}
                          value={this.state.discountTotal}
                          decimalsLimit={2}
                          onValueChange={(value, name) => { }}
                          className="p-0 form-control form-control-xs form-control-flush text-end"
                          prefix="Rp "
                          readOnly
                          labelfield={"false"}
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <td>{i18n.t("Tax Total")}</td>
                      <td>:</td>
                      <td>
                        <InputNumber
                          id={"taxTotal"}
                          name="taxTotal"
                          placeholder={i18n.t("Tax Total")}
                          defaultValue={0}
                          value={this.state.taxTotal}
                          decimalsLimit={2}
                          onValueChange={(value, name) => { }}
                          className="p-0 form-control form-control-xs form-control-flush text-end"
                          prefix="Rp "
                          readOnly
                          labelfield={"false"}
                        />
                      </td>
                    </tr>
                    <tr className="align-middle mt-5" key={"DP"}>
                        <td>
                            <span className='get-inline'>
                                <label className='label-control ' htmlFor='useDP'>{i18n.t("Down Payment")}</label>
                            </span>  
                        </td>
                        <td>:</td>
                        <td>
                            <InputNumber
                                id={"downPayment"}
                                name="downPayment"
                                placeholder={i18n.t("Down Payment")}
                                defaultValue={0}
                                value={this.state.totalDP}
                                decimalsLimit={2}
                                onValueChange={(value, name) => { }}
                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                prefix='Rp '
                                readOnly
                                labelfield={"false"}
                            />
                        </td>
                    </tr>
                    {/* {(this.state.tempDP > 0) && (this.state.paymentType == 'Credit') ?
                      <tr className="align-middle">
                        <td><label className='label-control required' htmlFor='Payment'>Down Payment</label></td>
                        <td>:</td>
                        <td class="row">
                          <div className='col-10'>
                            <InputNumber
                              id={"totalDP"}
                              name="totalDP"
                              placeholder="total Down Payment"
                              defaultValue={0}
                              value={(this.state.checkDownpayment) ? this.state.totalDP : this.state.tempDP}
                              decimalsLimit={2}
                              onValueChange={(value, name) =>
                                this.setState({ totalDP: value }, () => this.calculateAll())
                              }
                              className='p-2 form-control form-control-xs  text-end'
                              prefix='Rp '
                              labelfield={"false"}
                              disabled={true}
                            />
                          </div>
                          <div className='col-2'>
                            <SwitchButton
                              labelfield={'false'}
                              onChange={(e) => this.useDP(e)}
                              name={'choosePayment'}
                              value={this.state.checkDownpayment}
                              checked={this.state.checkDownpayment ? true : false}
                              className={'h-20px w-30px'}
                            ></SwitchButton>
                          </div>
                        </td>
                      </tr> : ''} */}
                    <tr className="align-middle">
                      <td>{i18n.t("Grand Total")}</td>
                      <td>:</td>
                      <td>
                        <InputNumber
                          id={"grandTotal"}
                          name="grandTotal"
                          defaultValue={0}
                          value={this.state.remaining}
                          decimalsLimit={2}
                          onValueChange={(value, name) => { }}
                          className="p-0 form-control form-control-xs form-control-flush text-end"
                          prefix="Rp "
                          readOnly
                          labelfield={"false"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </form>
        <Modal show={this.state.modalDetail} backdropClassName="next1" centered size="lg">
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConvertion
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formConvertion}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveConvertion(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.saveConvertion(true)}
            >
              {i18n.t("Convertion")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;

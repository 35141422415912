import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import GeneralLedgerMiddleware from "../../../middleware/modules/report/general-ledger";
import GeneralLedgerPrint from "../../../components/utilities/print/default/general-ledger";

const mapStateToProps = ({ auth, generalLedger }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: generalLedger.isProcessing,
  dataPrint: generalLedger.printGeneralLedger,
  requestPrint:generalLedger.requestPrintGeneralLedger
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (data) => GeneralLedgerMiddleware.getPrint(data),
    },
    dispatch
  );
};

class GeneralLedgerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.data);
  }

  render() {
   
    return <GeneralLedgerPrint {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedgerContainer);

import React, { Component, Fragment } from "react";
import Table from "./table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Form from "./form";
import i18n from "../../../../i18n";
import { Tab, Tabs } from "react-bootstrap";
import FormAll from "./form-all";
import MutationData from "./index-mutation";
import MutationAll from "./index-all";
import IndexMutation from "../../../../containers/modules/mutation/mutation/index-mutation";
import IndexAll from "../../../../containers/modules/mutation/mutation/index-all";

const MySwal = withReactContent(Swal);
export class Mutation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      listDataItem: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Mutation"), class: " text-gray-500" },
      ],
      columns: [
      ],
    };
  }

  render() {
  

   
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
              <Tabs
                defaultActiveKey="1"
                id={"tabs"}
                className="mb-3"
              >
                <Tab eventKey="1" title={i18n.t("Stock Trail")}>
                  <IndexMutation
                   {...this.props}
                   
                   />
                </Tab>
                <Tab eventKey="2" title={i18n.t("Stock Card")}>
                  <IndexAll
                   {...this.props}
                  
                   />
                </Tab>
              </Tabs>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default Mutation;

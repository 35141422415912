import DeliveryOrderActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperDeliveryOrder:null,
  deliveryOrderHelperFull:null,
  printDeliveryOrder:null
};

function DeliveryOrderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
   case DeliveryOrderActions.RESET_LIST:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,data:{}};
    case DeliveryOrderActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case DeliveryOrderActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false};
    case DeliveryOrderActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case DeliveryOrderActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case DeliveryOrderActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case DeliveryOrderActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case DeliveryOrderActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case DeliveryOrderActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case DeliveryOrderActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case DeliveryOrderActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case DeliveryOrderActions.GET_HELPER_DELIVERYORDER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperDeliveryOrder:action.payload};
    case DeliveryOrderActions.GET_HELPER_DELIVERYORDER_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,deliveryOrderHelperFull:action.payload};
    case DeliveryOrderActions.PRINT_DELIVERYORDER:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printDeliveryOrder:null};
    case DeliveryOrderActions.PRINT_DeliveryOrder:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printDeliveryOrder:action.payload};
    
    default:
      return state;
    }
}


export default DeliveryOrderReducer;

import MutationActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataItem:{},
  isProcessing: false,
  isProcessingItem: false,
  isError: false,
  errorMessage: [],
  isNewRecord:false,
  onChoose:false,
  onChooseItem:false,
  helperMutation:[],
  printStockCard:null,
  requestPrint:false,
};

function MutationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MutationActions.BEFORE_CHOOSE:
      return {...state,data:[],isProcessing:false,isError: false,errorMessage: [],onChoose:false};
    case MutationActions.PROCESS_CHOOSE:
      return {...state,data:[],isProcessing:true,isError: false,errorMessage: []};
    case MutationActions.AFTER_CHOOSE:
      return {...state,data:action.payload,isProcessing:false,isError: false,errorMessage: [],onChoose:true};
    case MutationActions.ERROR_CHOOSE:
      return {...state,data:[],isProcessing:false,isError: true,errorMessage: action.payload,onChoose:true};
    case MutationActions.AFTER_CHOOSE_ITEM:
      return {...state,dataItem:action.payload,isProcessingItem:false,isError: false,errorMessage: [],onChooseItem:true};
    case MutationActions.ERROR_CHOOSE_ITEM:
      return {...state,dataItem:[],isProcessingItem:false,isError: true,errorMessage: action.payload,onChooseItem:true};
    case MutationActions.BEFORE_CHOOSE_ITEM:
      return {...state,dataItem:[],isProcessingItem:false,isError: false,errorMessage: [],onChooseItem:false};
    case MutationActions.PROCESS_CHOOSE_ITEM:
      return {...state,dataItem:[],isProcessingItem:true,isError: false,errorMessage: []};
    case MutationActions.GET_HELPER_MUTATION:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperMutation:action.payload};
    case MutationActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false }; 
    case MutationActions.BEFORE_PRINT_STOCK_CARD:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printStockCard:null,isProcessing:true};
    case MutationActions.PRINT_STOCK_CARD:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printStockCard:action.payload,isProcessing:false};
    case MutationActions.ON_LIMIT_PRINT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    case MutationActions.ON_SAVE_REQUEST_PRINT:
      return { ...state,errorInternal:false,requestPrint:true,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    default:
      return state;
    }
}


export default MutationReducer;

export default class MutationDepositActions {
  static BEFORE_CHOOSE='BEFORE_CHOOSE'
  static AFTER_CHOOSE='AFTER_CHOOSE'
  static PROCESS_CHOOSE='PROCESS_CHOOSE'
  static ERROR_CHOOSE='ERROR_CHOOSE'
  static ERROR_DATA='ERROR_DATA'
  static ERROR_DATA_CUSTOMER='ERROR_DATA_CUSTOMER'
  static AFTER_CHOOSE_CUSTOMER='AFTER_CHOOSE_CUSTOMER'
  static ERROR_CHOOSE_CUSTOMER='ERROR_CHOOSE_CUSTOMER'
  static PROCESS_CHOOSE_CUSTOMER='PROCESS_CHOOSE_CUSTOMER'
  static BEFORE_CHOOSE_CUSTOMER='BEFORE_CHOOSE_CUSTOMER'
  static GET_HELPER_MUTATION='GET_HELPER_MUTATION';
  static PRINT_STOCK_CARD='PRINT_STOCK_CARD'
  static BEFORE_PRINT_STOCK_CARD='BEFORE_PRINT_STOCK_CARD'
  static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
  static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'

  static errorChoose(data){
    return {
      type:MutationDepositActions.ERROR_CHOOSE,
      payload:data
    }
  }
  static error(error){
    return {
      type: MutationDepositActions.ERROR_DATA,
      payload: error
    };
  }

  static errorCustomer(error){
    return {
      type: MutationDepositActions.ERROR_DATA_CUSTOMER,
      payload: error
    };
  }
  static beforeChooseCustomer(){
    return {
      type:  MutationDepositActions.BEFORE_CHOOSE_CUSTOMER,
    };
  }
  static processChooseCustomer(){
    return {
      type:  MutationDepositActions.PROCESS_CHOOSE_CUSTOMER,
    };
  }
  static errorChooseCustomer(dataCustomer){
    return {
      type:MutationDepositActions.ERROR_CHOOSE_CUSTOMER,
      payload:dataCustomer
    }
  }
  static afterChooseCustomer(dataCustomer){
    return {
      type:  MutationDepositActions.AFTER_CHOOSE_CUSTOMER,
      payload:dataCustomer
    };
  }
  static afterChoose(data){
    return {
      type:  MutationDepositActions.AFTER_CHOOSE,
      payload:data
    };
  }

 
  static beforeChoose(){
    return {
      type:  MutationDepositActions.BEFORE_CHOOSE,
    };
  }
  static processChoose(){
    return {
      type:  MutationDepositActions.PROCESS_CHOOSE,
    };
  }
  

  

  static helper(data){
    return {
      type: MutationDepositActions.GET_HELPER_MUTATION,
      payload:data
    };
  }

  static beforePrint(){
    return {
      type: MutationDepositActions.BEFORE_PRINT_STOCK_CARD,
    };
  }
  static print(data){
    return {
      type: MutationDepositActions.PRINT_STOCK_CARD,
      payload: data
    };
  }
  static saveRequestPrint(){
    return {
      type: MutationDepositActions.ON_SAVE_REQUEST_PRINT,
    };
  }
  static limitPrint(){
    return {
      type: MutationDepositActions.ON_LIMIT_PRINT,
    };
  }
}


export default class WarehouseActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_WAREHOUSE='GET_HELPER_WAREHOUSE';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  
  static submit(){
    return {
      type: WarehouseActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: WarehouseActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: WarehouseActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: WarehouseActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: WarehouseActions.DELETE_DATA
    };
  }
  static resetList(data) {
    return {
      type: WarehouseActions.RESET_LIST,
      payload: data
    };
  }
  static list(data) {
    return {
      type: WarehouseActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: WarehouseActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: WarehouseActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: WarehouseActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: WarehouseActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: WarehouseActions.GET_HELPER_WAREHOUSE,
      payload:data
    };
  }

  static errorInternal(error){
    return {
      type: WarehouseActions.ERROR_SERVER,
      payload: error
    };
  }

}
export default class NewsEventActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static CANCEL_DATA='CANCEL_DATA'
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_NEWSEVENT='GET_HELPER_NEWSEVENT';
    static GET_HELPER_NEWSEVENT_FULL ='GET_HELPER_NEWSEVENT_FULL';
    static BEFORE_PRINT_NEWSEVENT ='BEFORE_PRINT_NEWSEVENT';
    static PRINT_NEWSEVENT ='PRINT_NEWSEVENT';
    static RESET_LIST="RESET_LIST";
  
    static submit(){
      return {
        type: NewsEventActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: NewsEventActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: NewsEventActions.UPDATE_DATA,
        payload: data
      };
      
    }
    static cancel() {
      return {
        type: NewsEventActions.CANCEL_DATA
      };
    }
  
    static view(data) {
      return {
        type: NewsEventActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: NewsEventActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: NewsEventActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: NewsEventActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: NewsEventActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: NewsEventActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: NewsEventActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: NewsEventActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: NewsEventActions.GET_HELPER_NEWSEVENT_FULL,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  NewsEventActions.GET_HELPER_NEWSEVENT,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: NewsEventActions.BEFORE_PRINT_NEWSEVENT,
      };
    }
    static print(data){
      return {
        type: NewsEventActions.PRINT_NEWSEVENT,
        payload: data
      };
    }
  }
  
  
import { useParams } from "react-router-dom";
import React from "react";
import PrContainer from '../../../containers/Print/default/Pr'
export const PrintPr = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <PrContainer prid={id}/>
    )
}

export default PrintPr;
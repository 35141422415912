import React, { Component } from "react";
import PropTypes from "prop-types";

class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    labelfield: PropTypes.string,
    addOns:PropTypes.any
  };

  static defaultProps = {
    style: "",
    size: "sm",
    error: "",
    placeholder: "",
    required: false,
    labelfield: "true",
    addOns:null
  };
  constructor(props) {
    super(props);
  }
  renderClass() {
    const { style, size } = this.props;
    var classText = "form-control";
    switch (size) {
      case "sm":
        classText += " form-control-sm";
        break;
      case "md":
        classText += " form-control-md";
        break;
      case "lg":
        classText += " form-control-lg";
        break;
    }

    switch (style) {
      case "solid":
        classText += " form-control-solid";
        break;
      case "flush":
        classText += " form-control-flush";
        break;
    }
    return classText;
  }
  render() {
    const {
      type,
      required,
      placeholder,
      title,
      name,
      error,
      handler,
      value,
      disabled,
      labelfield,
      addOns
    } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : null}
        <div className="fv-row mb-3 input-group custompasswo">
          <input
            key={name + "input"}
            name={name}
            type={type}
            className={this.renderClass()}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handler}
            value={value}
          />
          {(addOns!=undefined)?
            addOns
          :null}
        </div>
        {error != "" ? (
          <div className="fv-plugins-message-container invalid-feedback">
            {error}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Input;

export default class CustomerApprovalActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_BRANCH='GET_HELPER_BRANCH';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static APPROVE='APPROVE';
    static REJECT='REJECT';
    static submit(){
      return {
        type: CustomerApprovalActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: CustomerApprovalActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: CustomerApprovalActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: CustomerApprovalActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: CustomerApprovalActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: CustomerApprovalActions.SHOW_DATA,
        payload: data
      };
    }
    static resetList() {
      return {
        type: CustomerApprovalActions.RESET_LIST
      };
    }
    static error(error){
      return {
        type: CustomerApprovalActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: CustomerApprovalActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: CustomerApprovalActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: CustomerApprovalActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: CustomerApprovalActions.GET_HELPER_BRANCH,
        payload:data
      };
    }
    static errorInternal(error){
      return {
        type: CustomerApprovalActions.ERROR_SERVER,
        payload: error
      };
    }
    static approve() {
      return {
        type: CustomerApprovalActions.APPROVE
      };
    }
    static reject() {
      return {
        type: CustomerApprovalActions.REJECT
      };
    }
  }
import React, { Component } from "react";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import TextArea from "../../../utilities/Form/TextArea";
import { Button, Table } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import i18n from "../../../../i18n";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        MemoIn: [],
        DetailMemoIn: [],
      },
      branchValue: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      storeValue: null,
      date: new Date(),
      storeText: i18n.t("Select Store"),
      storeList: [],
      storeListLoading: false,
      codeLoading: false,
      description: "",
      journalNo: "",
      transactionSource: 7,
      idTransaction: 0,
      status: 1,
      isSystem: 0,
      isReconcile: 0,
      totalDetail: 0,
      coa3List: [],
      coa3Text: i18n.t("Select Ledger"),
      coa3Value: null,
      coaHelperList: [],
      coaHelpertext: i18n.t("Select Helper Book"),
      coaHelperValue: null,
      arrayDetail: [
        {
          warehouseList: [],
          warehouseValue: null,
          warehouseText: i18n.t("Select Warehouse"),
          itemList: [],
          itemValue: null,
          itemText: i18n.t("Select Item"),
          qty: 0,
          price: 0,
          total: 0,
          description: "",
          suffixItem:"",
          stock: 0,
          bandNo: null,
          batchNo: null,
          expDate: null,
        },
      ],

      isUpdate: 0,
      jour: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    objectss["total"] = price * qty;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    let totalDetail = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let total = parseFloat(detail[i].total) || 0;
      totalDetail += total;
    }
    this.setState({
      totalDetail: totalDetail.toFixed(2),
    });
  }

  runSubmit() {
    const MemoIn = {
      journalNo: this.state.journalNo,
      date: moment(this.state.date).format("YYYY-MM-DD"),
      idBranch:
        this.state.branchValue == null ? "" : this.state.branchValue.value,
      idStore: this.state.storeValue == null ? "" : this.state.storeValue.value,
      coa3: this.state.coa3Value == null ? "" : this.state.coa3Value.value,
      coaHelper:
        this.state.coaHelperValue == null
          ? ""
          : this.state.coaHelperValue.value,
      transactionSource: 7,
      idTransaction: 0,
      status: 1,
      isSystem: 0,
      isReconcile: 0,
      debt: parseFloat(this.state.totalDetail) || 0,
      credit: parseFloat(this.state.totalDetail) || 0,
      description: this.state.description,
    };
    const detail = this.state.arrayDetail;
    let warehouse = [];
    let item = [];
    let qty = [];
    let price = [];
    let total = [];
    let description = [];
    let bandNo = [];
    let batchNo = [];
    let expDate = [];

    for (var i = 0; i < detail.length; i++) {
      warehouse.push(
        detail[i].warehouseValue == null ? "" : detail[i].warehouseValue.value
      );
      item.push(detail[i].itemValue == null ? "" : detail[i].itemValue.value);
      qty.push(detail[i].qty == null ? "" : detail[i].qty);
      price.push(detail[i].price == null ? "" : detail[i].price);
      total.push(detail[i].total == null ? "" : detail[i].total);
      description.push(detail[i].description);
      bandNo.push(detail[i].bandNo == null ? null : detail[i].bandNo);
      batchNo.push(detail[i].batchNo == null ? null : detail[i].batchNo);
      expDate.push(detail[i].expDate == null ? null : detail[i].expDate);
    }
    this.state.form.MemoIn = MemoIn;
    this.state.form.DetailMemoIn = {
      warehouse: warehouse,
      item: item,
      qty: qty,
      price: price,
      total: total,
      description: description,
      bandNo: bandNo,
      batchNo: batchNo,
      expDate: expDate,
    };

    return this.state.form;
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  resetDetail() {
    this.setState({
      arrayDetail: [
        {
          warehouseValue: null,
          warehouseText: i18n.t("Select Warehouse"),
          itemList: [],
          itemValue: null,
          itemText: i18n.t("Select Item"),
          qty: 0,
          price: 0,
          total: 0,
          description: null,
        },
      ],
    });
  }
  renderDetail(detail, i) {
    return (
      <React.Fragment key={"detail" + i}>
        <tr >
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"warehouse"}
              required={true}
              onChange={async (e) => {
                if(e==null){
                  await this.handlerSelectArray(
                    e,
                    "warehouse",
                    i,
                    "arrayDetail",
                    "warehouseValue"
                  );
                  await this.handlerCurrencyArray(0, "price", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "stock", i, "arrayDetail");
                  await this.handlerCurrencyArray('', "suffixItem", i, "arrayDetail");
                }else{
                  await this.handlerSelectArray(
                    e,
                    "warehouse",
                    i,
                    "arrayDetail",
                    "warehouseValue"
                  );
                  await this.changeData(
                    "itemList",
                    "getWarehouseItem",
                    "",
                    "arrayDetail",
                    i
                  );
                }
               
              }}
              options={this.state.warehouseList}
              title={i18n.t("Warehouse")}
              value={detail.warehouseValue}
              placeholder={detail.warehouseText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailMemoIn.warehouse." + i)}
            </div>
          </td>
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"item"}
              required={true}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelectArray(
                    e,
                    "item",
                    i,
                    "arrayDetail",
                    "itemValue"
                  );
                  await this.handlerCurrencyArray(0, "price", i, "arrayDetail");
                  await this.handlerCurrencyArray(0, "stock", i, "arrayDetail");
                  await this.handlerCurrencyArray('', "suffixItem", i, "arrayDetail");
                } else {
                  await this.handlerSelectArray(
                    e,
                    "item",
                    i,
                    "arrayDetail",
                    "itemValue"
                  );
                  await this.handlerSelectArray(
                    detail.itemValue.satuan
                    ,
                    "",
                    i,
                    "arrayDetail",
                    "suffixItem"
                  );
                }
              }}
              options={detail.itemList}
              title={i18n.t("Item")}
              value={detail.itemValue}
              placeholder={detail.itemText}

            />
            {/* <div className="fv-plugins-message-container valid-feedback">
              {detail.itemValue != null &&
              detail.stock >= 0
                ? "Stock Available: " + detail.stock
                : ""}
            </div> */}
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailMemoIn.item." + i)}
            </div>
          </td>
          <td width={"150px"}>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder="qty"
              defaultValue={0}
              value={detail.qty}
              decimalsLimit={2}
              onValueChange={async (value, name) => {
                await this.handlerCurrencyArray(value, "qty", i, "arrayDetail");
              }}
              className="form-control  form-control-sm text-end"
             
              labelfield={"false"}
              // suffix = {}
            />
            <div className="fv-plugins-message-container success-feedback text-end">
              {detail.suffixItem}
            </div>
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailMemoIn.qty." + i)}
            </div>
          </td>
          <td width={"150px"}>
            <InputNumber
              id={"price" + i}
              name="price"
              placeholder="price"
              defaultValue={0}
              value={detail.price}
              decimalsLimit={2}
              prefix="Rp. "
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "price", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              
            />
            </td>
          <td width={"150px"}>
            <InputNumber
              id={"total" + i}
              name="total"
              placeholder="total"
              defaultValue={0}
              value={detail.total}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "total", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp. "
              labelfield={"false"}
              disabled={true}
            />
            {/* <div className="fv-plugins-message-container invalid-feedback">{this.renderError('DetailJournal.credit.'+i)}</div> */}
          </td>

          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(keyFill, type = null, key, array = null, arraykey = null) {
    const token = cookieManager.getUserToken();

    let params = null;
    switch (type) {
      case "getCode":
        this.state.codeLoading = true;
        var url = process.env.REST_URL + "helper/get-journal-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          branch: branch,
          date: date,
        };
        break;
      case "getStore":
        this.state.storeListLoading = true;
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchValue != null ? this.state.branchValue.value : null;
        var idStore =
          this.state.storeValue != null ? this.state.storeValue.value : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;
      case "getWarehouseItem":
        var url = process.env.REST_URL + "helper/get-setup-warehouse-item";
        var idBranch =
          this.state.branchValue != null ? this.state.branchValue.value : null;
        var idStore =
          this.state.storeValue != null ? this.state.storeValue.value : null;
        var idWarehouse =
          this.state[array][arraykey].warehouseValue != null
            ? this.state[array][arraykey].warehouseValue.helper
            : null;
        var idSubWarehouse =
          this.state[array][arraykey].warehouseValue != null
            ? this.state[array][arraykey].warehouseValue.helper2
            : null;
        var type = 1;
        params = {
          branch: idBranch,
          store: idStore,
          warehouse: idWarehouse,
          subwarehouse: idSubWarehouse,
          type: type,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
          switch (type) {
            case "getStore":
              this.state.storeListLoading = false;
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reCalculate() {
    this.state.arrayDetail.map(async (detail, i) => {
      this.calculateRow(i, "arrayDetail");
    });
  }

  render() {
    const { dataUpdate, isNewRecord, helperJournal, data } = this.props;
    this.state.branchList = helperJournal != null ? helperJournal.branch : [];
    this.state.coa3List = helperJournal != null ? helperJournal.coa3 : [];
    this.state.coaHelperList =  helperJournal != null ? helperJournal.coa4 : [];
    if (this.state.jour == 0 && helperJournal != null) {
      this.state.journalNo = helperJournal != null ? helperJournal.code : null;
      this.state.jour = 1;
    }
  
    if (dataUpdate != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.journalNo = dataUpdate.no_journal),
          (this.state.description = dataUpdate.description),
          (this.state.date = new Date(dataUpdate.date));
        this.state.branchValue = {
          value: dataUpdate.id_branch,
          label: dataUpdate.name_branch,
        };
        this.state.storeValue =
          dataUpdate.id_store == null
            ? ""
            : { value: dataUpdate.id_store, label: dataUpdate.name_store };
        this.state.isUpdate = 1;
        this.state.arrayDetail = [];
        for (var po = 0; po < dataUpdate.journal_detail.length; po++) {
          this.state.arrayDetail.push({
            coa3Value: {
              value: dataUpdate.journal_detail[po].id_coa3,
              label: dataUpdate.journal_detail[po].name_coa3,
            },
            coaHelperValue: {
              value: dataUpdate.journal_detail[po].id_coa4,
              label: dataUpdate.journal_detail[po].name_coa_help,
            },
            description: dataUpdate.journal_detail[po].description,
            debt:
              dataUpdate.journal_detail[po].debt == null
                ? 0
                : dataUpdate.journal_detail[po].debt,
            credit:
              dataUpdate.journal_detail[po].credit == null
                ? 0
                : dataUpdate.journal_detail[po].credit,
          });
          this.calculateRow(po, "arrayDetail");
        }
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={false}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("journalNo", "getCode", "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                endDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.setState({ storeValue: null });
                    await this.setState({ journalNo: "" });
                    await this.setState({ storeList: [] });
                    await this.setState({ warehouseList: [] });
                    await this.resetDetail();
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.changeData("journalNo", "getCode", "code");
                    await this.changeData("storeList", "getStore", "");
                    await this.changeData("warehouseList", "getWarehouse", "");
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchValue}
                placeholder={this.state.branchText}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("MemoIn.idBranch")}
              </div>
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                isLoading={this.state.storeListLoading}
                name={"idStore"}
                required={true}
                onChange={(e) => this.handlerSelect(e, "idStore", "storeValue")}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeValue}
                placeholder={this.state.storeText}

              />
            </div>
            <div className="col-md-3">
              <Input
                type={"input"}
                name={"journalNo"}
                title={i18n.t("Transaction No.")}
                value={this.state.journalNo}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("MemoIn.journalNo")}
              </div>
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"coa3"}
                required={true}
                onChange={(e) => this.handlerSelect(e, "coa3", "coa3Value")}
                options={this.state.coa3List}
                title={i18n.t("General Ledger Acc")}
                value={this.state.coa3Value}
                placeholder={this.state.coa3Text}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("MemoIn.coa3")}
              </div>
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"coaHelper"}
                onChange={(e) =>
                  this.handlerSelect(e, "coaHelper", "coaHelperValue")
                }
                options={this.state.coaHelperList}
                title={i18n.t("Subsidiary Ledger Acc")}
                value={this.state.coaHelperValue}
                placeholder={this.state.coaHelpertext}
              />
              </div>
            <div className="col-md-4">
              <TextArea
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("MemoIn.description")}
              </div>
            </div>
            <br />
            <hr />
            <div className="row">
              <div className="col-12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{i18n.t("Warehouse")}</th>
                      <th className="text-center">{i18n.t("Item")}</th>
                      <th className="text-center">{i18n.t("Qty")}</th>
                      <th className="text-center">{i18n.t("Price")}</th>
                      <th className="text-center">{i18n.t("Total")}</th>
                      <th className="text-center">{i18n.t("Description")}</th>
                      {!this.state.fromRequest ? (
                        <th className="text-right"></th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrayDetail.map((detail, i) => {
                      return this.renderDetail(detail, i);
                    })}
                  </tbody>
                </Table>
                {!this.state.fromRequest ? (
                  <Button
                    variant="success"
                    onClick={(e) =>
                      this.addArray(
                        {
                          coa3Value: null,
                          coaHelperValue: null,
                          debt: 0,
                          credit: 0,
                          description: null,
                        },
                        "arrayDetail"
                      )
                    }
                  >
                    {i18n.t("Add Item")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
        {/*  */}
      </React.Fragment>
    );
  }
}

export default Form;


const ListLang=[
    {   
       key:'id',
       image:'/assets/media/flags/indonesia.svg',
       label:'Indonesia'
    },
    {   
        key:'en',
        image:'/assets/media/flags/united-states.svg',
        label:'English'
     }
];



export default ListLang;
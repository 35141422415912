export default class TempCustomerActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static REJECT_DATA='REJECT_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static SUCCESS_DATA='SUCCESS_DATA';
  static GET_HELPER_CUSTOMER='GET_HELPER_CUSTOMER';
  static GET_HELPER_CUSTOMER_FULL='GET_HELPER_CUSTOMER_FULL';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static APPROVE='APPROVE';

  static submit(){
    return {
      type: TempCustomerActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: TempCustomerActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: TempCustomerActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: TempCustomerActions.VIEW_DATA,
      payload: data
    };
  }

  static approve() {
    return {
      type: TempCustomerActions.APPROVE
    };
  }

  static reject() {
    return {
      type: TempCustomerActions.REJECT_DATA
    };
  }

  static list(data) {
    return {
      type: TempCustomerActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: TempCustomerActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: TempCustomerActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: TempCustomerActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: TempCustomerActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: TempCustomerActions.RESET_DATA,
    };
  }

  static helper(data){
    return {
      type: TempCustomerActions.GET_HELPER_CUSTOMER,
      payload:data
    };
  }
  static helperFull(data){
    return {
      type: TempCustomerActions.GET_HELPER_CUSTOMER_FULL,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: TempCustomerActions.ERROR_SERVER,
      payload: error
    };
  }
}


import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import CustomerMiddleware from "../../../../middleware/modules/master/customer";
import Form from "../../../../components/modules/master/customer/form";
import { Button, Modal } from "react-bootstrap";
import CityMiddleware from "../../../../middleware/modules/master/city";
import DistrictMiddleware from "../../../../middleware/modules/master/district";
import SubDistrictMiddleware from "../../../../middleware/modules/master/sub-district";
import StoresMiddleware from "../../../../middleware/modules/master/store";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const mapStateToProps = ({
  auth,
  customer,
  city,
  district,
  subDistrict,
  stores,
}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: customer.isProcessing,
  isError: customer.isError,
  errorMessage: customer.errorMessage,
  dataUpdate: customer.single,
  isSuccess: customer.isSuccess,
  isReset: customer.isReset,
  isNewRecord: customer.isNewRecord,
  onSubmit: customer.onSubmit,
  cityList: city.helperCity,
  districtList: district.helperDistrict,
  subDistrictList: subDistrict.helperSubDistrict,
  storeList: stores.helperStore,
  customerHelper: customer.customerHelperFull,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStore: (id) => StoresMiddleware.getHelper(id),
      getCity: (id) => CityMiddleware.getHelper(id),
      getDistrict: (id) => DistrictMiddleware.getHelper(id),
      getSubDistrict: (id) => SubDistrictMiddleware.getHelper(id),
      closeModal: () => CustomerMiddleware.reset(),
      new: (data, search) => CustomerMiddleware.beforeSaveNew(data, search),
      update: (id, type, search) =>
        CustomerMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
      map:0,
      geo:[],
    };
    this.form = React.createRef();
    this.getData = this.getData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.submitApproval = this.submitApproval.bind(this);
    this.close = this.close.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.dataID);
  }

  close() {
    this.props.closeModal();
  }
  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    console.log('submit',form)
    if (isNewRecord) {
      this.props.new(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
  }

  submitApproval(){
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    this.props.new(form, search);
  }

  changeSelect(type, value = null) {
    const params = { id: value };
    switch (type) {
      case "idProvince":
        this.props.getCity(params);
        break;
      case "idBranch":
        this.props.getStore(params);
        break;
      case "idCity":
        this.props.getDistrict(params);
        break;
      case "idDistrict":
        this.props.getSubDistrict(params);
        break;
      default:
        break;
    }
  }

   approve(){
    const html = (
      <>
        <div>{i18n.t("Update Data Customer")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
                MySwal.fire({
                  title: i18n.t("Proccessing Request"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.submitApproval();
          }}
        >
          {i18n.t("Accept")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  render() {

    if(this.state.map == 0){
      navigator.geolocation.getCurrentPosition((position)=> {
          this.state.geo.push({longitude:position.coords.longitude,latitude:position.coords.latitude})
      },
      error => console.log(error),
      {
        enableHighAccuracy: false,
        timeout: 20000,
        maximumAge: 10,
        distanceFilter: 0,
      },
      
      );
      this.state.map = 1;
  
    } 

    const {
      onSubmit,
      showModal,
      titleModal,
      dataUpdate,
    } = this.props;
    return (
      <>
        <Modal show={showModal} fullscreen>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Helmet>
              <title>{titleModal}</title>
            </Helmet>
            <Form
              {...this.props}
              data={dataUpdate}
              ref={this.form}
              getGeo={this.state.geo}
              changeSelect={this.changeSelect}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

import SellingPriceActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSellingPrice:null,
  SellingPriceHelperFull:null,
  printSellingPrice:null
};

function SellingPriceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SellingPriceActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case SellingPriceActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SellingPriceActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case SellingPriceActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case SellingPriceActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SellingPriceActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SellingPriceActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case SellingPriceActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SellingPriceActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SellingPriceActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SellingPriceActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case SellingPriceActions.GET_HELPER_SELLINGPRICE:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperSellingPrice:action.payload};
    case SellingPriceActions.GET_HELPER_SELLINGPRICE_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,SellingPriceHelperFull:action.payload};
    case SellingPriceActions.PRINT_SELLINGPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printSellingPrice:action.payload};
    case SellingPriceActions.APPROVE_SELLINGPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case SellingPriceActions.REJECT_SELLINGPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    default:
      return state;
    }
}


export default SellingPriceReducer;

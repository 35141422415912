import React, { Component } from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

class InputNumber extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    labelfield: PropTypes.string,
  };

  static defaultProps = {
    labelfield: "true",
    error: "",
    required: false,
  };
  constructor(props) {
    super(props);
  }

  render() {
    const { required, title, name, error, labelfield } = this.props;
    return (
      <>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : null}
        <CurrencyInput {...this.props} />
        {error != "" ? (
          <div className="fv-plugins-message-container invalid-feedback">
            {error}
          </div>
        ) : null}
      </>
    );
  }
}

export default InputNumber;

export default class ReturnSellingActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_RETURNSELLING='GET_HELPER_RETURNSELLING';
    static GET_HELPER_RETURNSELLING_FULL ='GET_HELPER_RETURNSELLING_FULL';
    static BEFORE_PRINT_RETURNSELLING ='BEFORE_PRINT_RETURNSELLING';
    static PRINT_RETURNSELLING ='PRINT_RETURNSELLING';
    static RESET_LIST="RESET_LIST";
    static APPROVE_RETURNSELLING='APPROVE_RETURNSELLING';
    static REJECT_RETURNSELLING='REJECT_RETURNSELLING';
    static BACK_WAREHOUSE='BACK_WAREHOUSE'
  
    static newBackWarehouse(){
      return {
        type: ReturnSellingActions.BACK_WAREHOUSE
      };
    }
    static submit(){
      return {
        type: ReturnSellingActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: ReturnSellingActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: ReturnSellingActions.UPDATE_DATA,
        payload: data
      };
    }

    static approve() {
      return {
        type: ReturnSellingActions.APPROVE_RETURNSELLING
      };
    }

    static reject() {
      return {
        type: ReturnSellingActions.REJECT_RETURNSELLING
      };
    }
  
    static view(data) {
      return {
        type: ReturnSellingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: ReturnSellingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: ReturnSellingActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: ReturnSellingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: ReturnSellingActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: ReturnSellingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: ReturnSellingActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: ReturnSellingActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: ReturnSellingActions.GET_HELPER_RETURNSELLING,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  ReturnSellingActions.GET_HELPER_RETURNSELLING_FULL,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: ReturnSellingActions.BEFORE_PRINT_RETURNSELLING,
      };
    }
    static print(data){
      return {
        type: ReturnSellingActions.PRINT_RETURNSELLING,
        payload: data
      };
    }
  }
  
  
export default class VisitScheduleActions {

  static NEW_DATA = 'NEW_DATA';
  static CHANGE_DATA = 'CHANGE_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_VISITSCHEDULE='GET_HELPER_VISITSCHEDULE';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static FETCH_SCHEDULE='FETCH_SCHEDULE';
  static FETCH_CHANGE='FETCH_CHANGE';
  static FORM_HELPER='FORM_HELPER';
  static FORM_HELPER_CHANGE='FORM_HELPER_CHANGE';
  static CLOSE_MODAL='CLOSE_MODAL';

  static BEFORE_SAVE_UPDATE='BEFORE_SAVE_UPDATE';
  static AFTER_SAVE_UPDATE='AFTER_SAVE_UPDATE';

  static BEFORE_SAVE_CHANGE='BEFORE_SAVE_CHANGE';
  static AFTER_SAVE_CHANGE='AFTER_SAVE_CHANGE';
  static beforeUpdate(){
    return {
      type: VisitScheduleActions.BEFORE_SAVE_UPDATE,
    };
  }

  static beforeChange(){
    return {
      type: VisitScheduleActions.BEFORE_SAVE_CHANGE,
    };
  }

  static afterUpdate(){
    return {
      type: VisitScheduleActions.AFTER_SAVE_UPDATE,
    };
  }

  static afterChange(){
    return {
      type: VisitScheduleActions.AFTER_SAVE_CHANGE,
    };
  }

  static closeModal(){
    return {
      type: VisitScheduleActions.CLOSE_MODAL,
    };
  }

  static fetchSchedule(data){
    return {
      type: VisitScheduleActions.FETCH_SCHEDULE,
      payload:data
    };
  }
  static fetchChange(data){
    return {
      type: VisitScheduleActions.FETCH_CHANGE,
      payload:data
    };
  }
  static formHelper(data){
    return {
      type: VisitScheduleActions.FORM_HELPER,
      payload:data
    };
  }
  static formHelperChange(data){
    return {
      type: VisitScheduleActions.FORM_HELPER_CHANGE,
      payload:data
    };
  }

  static submit(){
    return {
      type: VisitScheduleActions.ON_SUBMIT
    };
  }

  static create() {
    return {
      type: VisitScheduleActions.NEW_DATA
    };
  }

  static change() {
    return {
      type: VisitScheduleActions.CHANGE_DATA
    };
  }

  static update() {
    return {
      type: VisitScheduleActions.UPDATE_DATA,
    };
  }

  static view(data) {
    return {
      type: VisitScheduleActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: VisitScheduleActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: VisitScheduleActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: VisitScheduleActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: VisitScheduleActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: VisitScheduleActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: VisitScheduleActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: VisitScheduleActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: VisitScheduleActions.GET_HELPER_VISITSCHEDULE,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: VisitScheduleActions.ERROR_SERVER,
      payload: error
    };
  }
}
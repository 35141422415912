export default class MemoInActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static CHANGE_STATUS='CHANGE_STATUS';
    static VIEW_DATA='VIEW_DATA';
    static CANCEL_DATA='CANCEL_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static REJECT_MEMOIN='REJECT_MEMOIN';
    static APPROVE_MEMOIN='APPROVE_MEMOIN';
    static GET_HELPER_MEMO_IN='GET_HELPER_MEMO_IN';
    static GET_HELPERFULL_MEMO_IN='GET_HELPERFULL_MEMO_IN';
    static SUCCESS_DATA='SUCCESS_DATA';
    static AFTER_DATA = 'AFTER_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_MEMO_IN='PRINT_MEMO_IN'
    static BEFORE_PRINT_MEMO_IN='BEFORE_PRINT_MEMO_IN'
  
    static submit(){
      return {
        type: MemoInActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: MemoInActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: MemoInActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: MemoInActions.VIEW_DATA,
        payload: data
      };
    }
    static approve() {
      return {
        type: MemoInActions.APPROVE_MEMOIN,
      };
    }

    static reject() {
      return {
        type: MemoInActions.REJECT_MEMOIN,
      };
    }
  
    static cancel(data) {
      return {
        type: MemoInActions.CANCEL_DATA
      };
    }

    static change(data) {
      return {
        type: MemoInActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: MemoInActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: MemoInActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: MemoInActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: MemoInActions.PROCESS_DATA,
      };
    }
    static resetList(){
      return {
        type: MemoInActions.RESET_LIST,
      };
    }
    static reset(){
      return {
        type: MemoInActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: MemoInActions.GET_HELPER_MEMO_IN,
        payload:data
      };
    }
    static helperFull(data){
      return {
        type: MemoInActions.GET_HELPERFULL_MEMO_IN,
        payload:data
      };
    }
  
    static errorInternal(error){
      return {
        type: MemoInActions.ERROR_SERVER,
        payload: error
      };
    }
    
    static afterData(data){
      return {
        type: MemoInActions.AFTER_DATA,
        payload: data
      };
    }

    static beforePrint(){
      return {
        type: MemoInActions.BEFORE_PRINT_MEMO_IN,
      };
    }
    static print(data){
      return {
        type: MemoInActions.PRINT_MEMO_IN,
        payload: data
      };
    }
  }
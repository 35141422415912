import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import CashBankActions from "../../../../reducers/modules/accounting/cash-bank/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "accounting/journal";

export default class CashBankMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(CashBankActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(CashBankActions.update());
      CashBankMiddleware.showOne(dispatch, params);
    };
  }

  static detail(params) {
    return (dispatch) => {
      dispatch(CashBankActions.beforeDetail());
      CashBankMiddleware.showOne(dispatch, params);
    };
  }

  static detailData(params) {
    return (dispatch) => {
      dispatch(CashBankActions.beforeDetail());
      CashBankMiddleware.getDetailData(dispatch, params);
    };
  }

  static detail(params) {
    return (dispatch) => {
      dispatch(CashBankActions.beforeDetail());
      CashBankMiddleware.getDetail(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(CashBankActions.process());
      CashBankMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(CashBankActions.reset());
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(CashBankActions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(CashBankActions.view());
      CashBankMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(CashBankActions.submit());
      CashBankMiddleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveNewCash(post, search) {
    return (dispatch) => {
      dispatch(CashBankActions.submitIn());
      CashBankMiddleware.saveNewCash(dispatch, post, search);
    };
  }

  static beforeSaveNewBank(post, search) {
    return (dispatch) => {
      dispatch(CashBankActions.submitBank());
      CashBankMiddleware.saveNewBank(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(CashBankActions.submit());
      CashBankMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(CashBankActions.beforePrint());
      CashBankMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeChangeStatus(params, post) {
    return (dispatch) => {
      dispatch(CashBankActions.submit());
      CashBankMiddleware.saveChangeStatus(dispatch, params, post);
    };
  }

  static beforeCancel(id, params) {
    return (dispatch) => {
      dispatch(CashBankActions.cancel());
      CashBankMiddleware.cancel(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      CashBankMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull() {
    return (dispatch) => {
      CashBankMiddleware.showHelperFull(dispatch);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.list(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelperFull(dispatch) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-journal",
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.helperFull(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelper(dispatch, params) {
    
    axios({
     
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-cash-bank",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }
  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.view(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static getDetail(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/detail/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.afterData(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static showPrint(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/print/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.print(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static getDetailData(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "accounting/journal-detail/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CashBankActions.afterDetail(response.data.result));
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static cancel(dispatch, params, id) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/cancel/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.process(),
            CashBankMiddleware.getDetailData(dispatch, params)
          );
          CashBankMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Cancel Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    MySwal.fire({
      title: "Save Data",
      html: "Please Wait ....",
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    MySwal.showLoading();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "accounting/cashbank",
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.process(),
          );
          dispatch(CashBankActions.success());
          
          Swal.fire({
            html:"Create Data Successfully",
            icon:'success'
          }).then(()=>{
            dispatch(CashBankActions.create());
          })
        
        }
      })
      .catch((error) => {
        MySwal.close();
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveNewCash(dispatch, post, search) {
    MySwal.fire({
      title: "Save Data",
      html: "Please Wait ....",
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    MySwal.showLoading();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "accounting/cashbank/cash",
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.processIn(),
           
          );
          dispatch(CashBankActions.successIn());
          
          Swal.fire({
            html:"Create Data Successfully",
            icon:'success'
          }).then(()=>{
            dispatch(CashBankActions.createIn());
          })
        
        }
      })
      .catch((error) => {
        MySwal.close();
        CashBankMiddleware.alertHendler(dispatch, error,false,'','error','cash');
      });
  }

  static saveNewBank(dispatch, post, search) {
    MySwal.fire({
      title: "Save Data",
      html: "Please Wait ....",
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    MySwal.showLoading();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "accounting/cashbank/bank",
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.processBank(),
          );
          dispatch(CashBankActions.successBank());
          
          Swal.fire({
            html:"Create Data Successfully",
            icon:'success'
          }).then(()=>{
            dispatch(CashBankActions.createBank());
          })
        
        }
      })
      .catch((error) => {
        MySwal.close();
        CashBankMiddleware.alertHendler(dispatch, error,false,'','error','bank');
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.process(),
            CashBankMiddleware.getDetailData(dispatch, params)
          );
          dispatch(CashBankActions.success());
          CashBankMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveChangeStatus(dispatch, params, post) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CashBankMiddleware.getToken()}`,
      },
      url: url + "/change/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            CashBankActions.process(),
            CashBankMiddleware.showList(dispatch, search)
          );
          dispatch(CashBankActions.success());
          CashBankMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        CashBankMiddleware.alertHendler(dispatch, error);
      });
  }

  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error",
    source
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              if(source == 'bank'){
                dispatch(CashBankActions.errorBank(data.error));

              }else{

                dispatch(CashBankActions.errorIn(data.error));

              }
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(CashBankActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
        
    });
      
    }
  }
}

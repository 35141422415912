import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import LeftSide from './LeftSide';
const url=process.env.APP_URL
class ResetPassword extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const credentials = {
      email:this.state.email
    };
    this.props.request(credentials);
  }
   validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  render() {
    const { isProcessing,successRequest,isError,errorMessage } = this.props;
   
    return (
      
        <div className="d-flex flex-center w-lg-50 p-10 bg-primary">
        <Helmet
          titleTemplate="%s - Forgot Password"
          defaultTitle="Forgot Password"
        />
          <div className="card rounded-3 w-md-550px">
            <div className="card-body p-10 p-lg-20">
            <Alert show={isError} variant={"danger"}>
              {i18n.t(errorMessage[0])}
            </Alert>
              {!successRequest && 
              <form className="form w-100" noValidate="novalidate" id="kt_password_reset_form"  action="#">
                
                <div className="text-center mb-10">
                  
                  <h1 className="text-gray-900 fw-bolder mb-3">Forgot Password ?</h1>
                  
                  <div className="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>
                  
                </div>
                
                
                <div className="fv-row mb-8">
                  
                  <input type="text" placeholder="Email" name="email" onChange={(e)=>this.setState({email:e.target.value})} autoComplete="off" className="form-control bg-transparent" />
                  
                </div>
                
                <div className="d-flex flex-wrap justify-content-center pb-lg-0 ">
                <Link to="/login" disabled={
                        isProcessing
                      } className="btn btn-light mx-5">{i18n.t("Cancel")}</Link>
                <button type="button" onClick={()=>this.onSubmit()} id="kt_sign_in_submit" className="btn btn-primary mx-10"
                      disabled={
                        isProcessing || !this.validateEmail(this.state.email)
                      }
                    >
                      {isProcessing ? 
                        <span className="indicator-progress " >{i18n.t("Please wait")}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                        : 
                        <span className="indicator-label">{i18n.t("Submit")}</span>
                      }
                  </button>
                  
                </div>
                
              </form>}
              {successRequest && 
              <div className="text-center mb-10">
                  
                  <h1 className="text-gray-900 fw-bolder mb-3">Successfully send to your email</h1>
                  
                  <div className="text-gray-500 fw-semibold fs-6">Please check your email <span className='text-primary'>{this.state.email}</span></div>
                  <Link to="/login" className="btn btn-primary mx-5 mt-10">{i18n.t("Sign In")}</Link>
                </div>
              }
            </div>
          </div>
        </div>
    );
  }
}

export default ResetPassword;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import FormatByte from "../Class/FormatByte";
import i18n from "../../../i18n";
import cookieManager from "../../../utils/cookieManager";

class DropzoneCustom extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      error: [],
      load:0,
      showFile:0,
      loadData:false,
      defaultFiles:[]
    };
    this.onDrop = this.onDrop.bind(this);
  }
  static propTypes = {
    maxFiles: PropTypes.number,
    acceptFiles: PropTypes.object,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    changeFiles: PropTypes.func.isRequired,
  };

  static defaultProps = {
    maxFiles: null,
    acceptFiles: null,
    maxSize: null,
    minSize: null,
  };

  onDrop = (files) => {
    this.customValidate(files);
  };

  renderImage(file) {
    switch (file.type) {
      case "application/pdf":
        return "/assets/media/icons/pdf.png";
        break;
      default:
        return URL.createObjectURL(file);
        break;
    }
  }

  customValidate(files) {
    const { maxFiles, changeFiles, maxSize, minSize } = this.props;
    let error = [];
    let approveFile = [];
    files.forEach((file) => {
      let status = true;
      // console.log(file);
      if (maxSize != null) {
        if (file.size > maxSize) {
          status = false;
          error.push(
            file.name +
              " " +
              i18n.t("size is too large, max size is") +
              " " +
              FormatByte(maxSize) +
              " Bytes"
          );
        }
      }

      if (minSize != null) {
        if (file.size < minSize) {
          status = false;
          error.push(
            file.name +
              " " +
              i18n.t("size is too small, min size is") +
              " " +
              FormatByte(minSize) +
              " Bytes"
          );
        }
      }
      if (status) {
        approveFile.push(
          Object.assign(file, {
            preview: this.renderImage(file),
          })
        );
      }
    });

    let arr = [...this.state.files, ...approveFile];
    if (maxFiles != null) {
      if (arr.length > maxFiles) {
        error.push(i18n.t("Max File is") + " " + maxFiles);
      }
      arr = arr.slice(0, maxFiles);
    }
    this.setState({ files: arr, error: error }, () =>
      changeFiles(this.state.files)
    );
  }

  removeItem(index,param='new',id) {
    const { changeFiles } = this.props;
    const arr = this.state.files.filter((obj, i) => {
      return i !== index;
    });
    arr.map((item) => {
      Object.assign(item, {
        preview: this.renderImage(item),
      });
    });
    this.setState(
      {
        ...this.state,
        files: arr,
      },
      async() => {
        await changeFiles(this.state.files)
        if(param!='new'){
          await changeFiles(id,'delete')
        }
       
      }
    );
  }
  removeError(index) {
    const { changeFiles } = this.props;
    const arr = this.state.error.filter((obj, i) => {
      return i !== index;
    });

    this.setState(
      {
        ...this.state,
        error: arr,
      },
      () => changeFiles(this.state.files)
    );
  }

  postValue() {
    return this.state.files;
  }
  
  renderDefault(){
    const { defaultFiles } = this.props;
    let files = defaultFiles;
    files.map(async(file, i) =>{
      const token = cookieManager.getUserToken();
      axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: process.env.REST_URL + "download",
        method: "GET",
        params: {
          url: file.imageUrl,
        },
        responseType: "blob",
      }).then((response) => {
        if (response && response.status === 200 && response.data) {
          const data=response.data
          let responseFile=new File([data], file.name, {
            type: data.type,
          });
          const arr=[...this.state.defaultFiles,Object.assign(responseFile, {
            preview: this.renderImage(responseFile),
            ignore:true,
            id:file.id
          })]
         
          this.setState({defaultFiles:arr, showFile: (this.state.showFile +1)},()=>{
            if(this.state.showFile == files.length){
              this.state.loadData=false
              this.customValidate(arr)
            } 
          })
        }
      });
    });
  }

  renderItems() {
    const { defaultFiles } = this.props;
    let files = (defaultFiles==undefined)?[]:defaultFiles;
    if (files.length > 0 && this.state.load==0) {
      this.state.loadData=true;
      this.renderDefault();
      this.state.load=1;
    }
        return this.state.files.map((file, i) => (
          <div style={thumb} key={file.name + i} className={"thumb"}>
            <div style={thumbInner}>
              <img
                title={file.name}
                src={file.preview}
                style={img}
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
              />
            </div>
            <Button
              variant="danger"
              className={"btn-remove"}
              onClick={() => 
                this.removeItem(i, 
                                ((file.ignore == true)?'update':'new'),
                                ((file.ignore == true)?file.id:null))}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ));
    
  }

  render() {
    const { acceptFiles } = this.props;
    let dropzonClass = "dropzone";
    if (this.state.files.length > 0) {
      dropzonClass = "dropzone pt-0";
    }
    return (
      <Dropzone
        onDrop={this.onDrop}
        noClick={true}
        noKeyboard={true}
        accept={acceptFiles != null ? acceptFiles : false}
      >
        {({ getRootProps, getInputProps, open }) => (
          <section>
            <div {...getRootProps()} className={dropzonClass}>
              <input {...getInputProps()} />
              <div style={thumbsContainer}> {this.renderItems()}</div>
             
              {this.state.files.length == 0 ? (
                <Alert variant={"info"}>
                  {i18n.t(
                    "Drag 'n' drop some files here, or click Choose File to select files"
                  )}
                </Alert>
              ) : null}
               {this.state.loadData?
                 <Alert variant={"info"}>
                 {i18n.t(
                   "Loading Data From server"
                 )}
               </Alert>
              :<Button variant="primary" onClick={open} className={"mb-6"}>
              {i18n.t("Choose File")}
            </Button>}
              {this.state.error.map((error, idx) => (
                <Alert
                  key={idx}
                  variant={"danger"}
                  onClose={() => this.removeError(idx)}
                  dismissible
                >
                  {error}
                </Alert>
              ))}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}
const btnRemove = {
  position: "absolute",
};
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  position: "relative",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  margin: "0 auto",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default DropzoneCustom;

import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import ReceivingActions from "../../../../reducers/modules/pembelian/receiving/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import i18n from "../../../../i18n";
import React from "react";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "pembelian/receiving";

export default class ReceivingMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(ReceivingActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(ReceivingActions.update());
      ReceivingMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(ReceivingActions.process());
      ReceivingMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(ReceivingActions.reset());
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(ReceivingActions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(ReceivingActions.view());
      ReceivingMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(ReceivingActions.submit());
      ReceivingMiddleware.saveNew(dispatch, post, search);
    };
  }
  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(ReceivingActions.submit());
      ReceivingMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(ReceivingActions.beforePrint());
      ReceivingMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(ReceivingActions.delete());
      ReceivingMiddleware.delete(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      ReceivingMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull(params = null) {
    return (dispatch) => {
      ReceivingMiddleware.showHelperFull(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showHelperFull(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-receiving-helper",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.helperFull(response.data.result));
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.list(response.data.result));
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-receiving",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showPrint(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url + "/print/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.print(response.data.result));
        }
      })
      .catch((error) => {
        const data=error.response.data;
        switch(data.message){
          case 'Limit':
            dispatch(ReceivingActions.limitPrint());
            const html=<><div>{i18n.t("Enter your message")}</div></>
            MySwal.fire({  
              title:i18n.t("Request to Print"),
              icon:false,
              html:html,
              input: 'textarea',
              inputValue: '',
              showCancelButton: false,  
              showConfirmButton: true,  
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                  if (!value) {
                  return i18n.t("You need to write something!")
                  }
              },
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    const value= result.value
                    MySwal.fire({
                        title:i18n.t('Proccessing Data'),
                        html:i18n.t('Please Wait ....'),
                        icon:'info',
                    })
                    MySwal.showLoading()
                    ReceivingMiddleware.sendRequest(dispatch,value,params);
                }
            });   
            return;
          break;
          case 'Reject':
            dispatch(ReceivingActions.limitPrint());
            const htmlreject=<><div className="text-danger">{i18n.t("Your Request Before is Rejected reason :") +' '+ data.error +' '+ i18n.t(", Please Create a new Request")}</div><div>{i18n.t("Enter your message")}</div></>
            MySwal.fire({  
              title:i18n.t("Request to Print"),
              icon:false,
              html:htmlreject,
              input: 'textarea',
              inputValue: '',
              showCancelButton: false,  
              showConfirmButton: true,  
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                  if (!value) {
                  return i18n.t("You need to write something!")
                  }
              },
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    const value= result.value
                    MySwal.fire({
                        title:i18n.t('Proccessing Data'),
                        html:i18n.t('Please Wait ....'),
                        icon:'info',
                    })
                    MySwal.showLoading()
                    ReceivingMiddleware.sendRequest(dispatch,value,params);
                }
            });   
            return;
            break;
          case 'Waiting':
            dispatch(ReceivingActions.saveRequestPrint());
            return;
          case 'Not Found':
              dispatch(ReceivingActions.limitPrint());
              MySwal.fire({
                title:i18n.t('Data Not Found'),
                html:i18n.t('we cant found your request'),
                icon:'error',
            }).then(()=>{
              window.close()
            })
              return;
            break;
            default:
              ReceivingMiddleware.alertHendler(dispatch, error);
              break;
        }
        
      });
  }
  static sendRequest(dispatch,value,params){
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "log/print/request",
      responseType: "json",
      data:{
        id:params,
        description:value,
        type:3
      }
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.saveRequestPrint());
          ReceivingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Request To print",
            "success"
          );
        }
      })
      .catch((error) => {
      })
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ReceivingActions.view(response.data.result));
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ReceivingActions.process(),
            ReceivingMiddleware.showlist(dispatch, params)
          );
          ReceivingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ReceivingActions.process(),
            ReceivingMiddleware.showlist(dispatch, search)
          );
          dispatch(ReceivingActions.success());
          ReceivingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Create Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${ReceivingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ReceivingActions.process(),
            ReceivingMiddleware.showlist(dispatch, search)
          );
          dispatch(ReceivingActions.success());
          ReceivingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ReceivingMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(ReceivingActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(ReceivingActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

export default class BankActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_BANK='GET_HELPER_BANK';
  static GET_HELPERFULL_BANK='GET_HELPERFULL_BANK';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';

  static submit(){
    return {
      type: BankActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: BankActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: BankActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: BankActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: BankActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: BankActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: BankActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: BankActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: BankActions.PROCESS_DATA,
    };
  }
  static resetList(){
    return {
      type: BankActions.RESET_LIST,
    };
  }
  static reset(){
    return {
      type: BankActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: BankActions.GET_HELPER_BANK,
      payload:data
    };
  }
  static helperFull(data){
    return {
      type: BankActions.GET_HELPERFULL_BANK,
      payload:data
    };
  }

  static errorInternal(error){
    return {
      type: BankActions.ERROR_SERVER,
      payload: error
    };
  }
}
import CategoryActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperCategoryEmployee:[],
  errorInternal:false
};

function CategoryEmployeeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CategoryActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case CategoryActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case CategoryActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case CategoryActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case CategoryActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CategoryActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CategoryActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case CategoryActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case CategoryActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case CategoryActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case CategoryActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case CategoryActions.GET_HELPER_CATEGORYEMPLOYEE:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCategoryEmployee:action.payload};
    case CategoryActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default CategoryEmployeeReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
import { Navigate } from 'react-router-dom';
import TempCustomerMiddleware from '../../../../middleware/modules/pembelian/receiving';
import Form from '../../../../components/modules/pembelian/receiving/form-view';
import { Button, Modal } from 'react-bootstrap';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import ReceivingMiddleware from '../../../../middleware/modules/pembelian/receiving';

const mapStateToProps = ({ auth,receiving}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: receiving.isProcessing,
  isError: receiving.isError,
  errorMessage: receiving.errorMessage,
  dataUpdate: receiving.single,
  isSuccess:receiving.isSuccess,
  isReset:receiving.isReset,
  isNewRecord:receiving.isNewRecord,
  onSubmit:receiving.onSubmit,
  helperReceiving: receiving.receivingHelperFull,
  
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    closeModal:()=>ReceivingMiddleware.reset(),
    new: (data,search) =>ReceivingMiddleware.beforeSaveNew(data,search),
    update: (id,type,search) =>ReceivingMiddleware.beforeSaveUpdate(id,type,search)
  }, dispatch);
};
class FormContainerView extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate:PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit:PropTypes.bool
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess:false,
    isError: false,
    errorMessage: [],
    dataUpdate:null,
    onSubmit:false
  }
  constructor(props) {
    super(props);
    this.state={
      modal:{
        title:'',
        show:false,

      }
    }
    this.form=React.createRef();
    this.getData=this.getData.bind(this)
    this.submitForm=this.submitForm.bind(this)
    this.close=this.close.bind(this)
}
  
  getData(){
    return this.props.showOne(this.props.dataID)
  }

  close(){
      this.props.closeModal()
  }
  submitForm(){
    const {search,isNewRecord,dataUpdate}=this.props
    var form=this.form.current.runSubmit()
    if(isNewRecord){
      this.props.new(form,search)
    } else{
      this.props.update(dataUpdate.id,form,search)
    }
    
  }


  render() {
    const {helperReceiving,onSubmit,showModal,titleModal,isReset,dataUpdate,isProcessing,isError,errorMessage,isNewRecord} = this.props;
    return (
      <>
        <Modal
          show={showModal}
          // size={'lg'}
          fullscreen
        >
            <Modal.Header >
            <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Helmet>
                <title>{titleModal}</title>
              </Helmet>
            <Modal.Body>
                <Form 
                  isNewRecord={isNewRecord}
                  isReset={isReset}
                  data={dataUpdate}
                  isProcessing={isProcessing}
                  isError={isError}
                  errorMessage={errorMessage}
                  ref={this.form}
                  helperReceiving={helperReceiving}
                />
            </Modal.Body>
            <Modal.Footer className={'flex-center'}>
                <Button variant='secondary' onClick={()=>this.close(false)}>{i18n.t("Close")}</Button>
            </Modal.Footer>
        </Modal>
      
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainerView);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Link, matchPath, useLocation, useNavigation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import BillingMiddleware from "../../../../middleware/modules/sales/billing";
import Form from "../../../../components/modules/sales/billing/form";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import FormRepayment from "../../../../components/modules/sales/billing/form-repayment";
import FormBill from "../../../../components/modules/sales/billing/form-bill";
import FormPay from "../../../../components/modules/sales/billing/form-pay";

const mapStateToProps = ({ auth, billing }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: billing.isProcessing,
  isError: billing.isError,
  errorMessage: billing.errorMessage,
  dataUpdate: billing.single,
  isSuccess: billing.isSuccess,
  isReset: billing.isReset,
  isNewRecord: billing.isNewRecord,
  onSubmit: billing.onSubmit,
  helperBilling: billing.helperBilling,
  dataBilling: billing.dataBilling,
  dataPayBilling: billing.dataPayBilling,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => BillingMiddleware.reset(),
      setPayBill: (data) =>BillingMiddleware.setPayBill(data),
      submitData: (data,search) =>BillingMiddleware.savePayment(data,search),
      cancel: () =>BillingMiddleware.reset(),
    },
    dispatch
  );
};
class FormContainerPay extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    dataRepayment: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      add:0,
      modal: {
        title: "",
        show: false,
      },
    };
    this.formRepayment = React.createRef();
    this.submitData = this.submitData.bind(this);
    this.setPayBill = this.setPayBill.bind(this);
    this.savePayment = this.savePayment.bind(this);
    this.cancel = this.cancel.bind(this);
    this.close = this.close.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  close() {
    this.props.closeModal();
  }
  
  setPayBill(){
    this.props.setPayBill();
  }

  submitData(params){
    this.props.submitData(params);
  }

  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.formRepayment.current.runSubmit();
      this.props.submitData(form,search);
  }

  cancel(){
    this.props.cancel();
  }

  savePayment(params){
    this.props.savePayment(params);
  }

  render() {
    const {showModal,titleModal,onSubmit,helperBilling,isProcessing} = this.props
    return (
      <>
        <Modal show={showModal} fullscreen enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <FormPay
              {...this.props}
              ref={this.formRepayment}
              isProcessing={isProcessing}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainerPay);

import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
const MySwal = withReactContent(Swal);
export class AssetReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      show: false,
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      yearList: [],
      yearText: i18n.t("Select Year"),
      yearValue: null,
      month: null,
      year: null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Asset Report"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    })
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }})
  }

  setData() {
    var month = this.state.monthValue == null ? null : this.state.monthValue.value
    var year = this.state.yearValue == null ? null : this.state.yearValue.value
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;

    let params = {
      month: month,
      year: year,
      idBranch:idBranch,
    }
    this.props.setData(params);
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var month = this.state.monthValue == null ? null : this.state.monthValue.value
    var mLabel = this.state.monthValue == null ? null : this.state.monthValue.label
    var year = this.state.yearValue == null ? null : this.state.yearValue.value
    var yLabel = this.state.yearValue == null ? null : this.state.yearValue.label
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;


    let params = {
      month: month,
      year: year,
      mLabel: i18n.t(mLabel),
      idBranch:idBranch,
    }
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/balance-sheet/excel",
      method: "GET",
      params: params,
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", mLabel + '_' + yLabel + '_' + nameFile + '_Laporan Neraca.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props
    var value = ''
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0]
      }
      Swal.close();
    }
    return value;
  }

  renderList() {
    const { dataList } = this.props;
    let html = [];
    let no = 1;
    
    console.log('DATA', dataList);
    if (dataList != null && dataList != '') {
      let row = dataList.data !=null ? dataList.data.list : [];
      if (row != null) {
        for (var i = 0; i < row.length; i++) {
          let totalAll = 0;
          let totalPurchase = 0;
          let totalAccumulation = 0;
          let totalDepreciation = 0;
          let totalAccuDepreciation = 0;
          html.push(
              <React.Fragment>
                <tr className="table-fill-bold" key={'header' + i}>
                  <td style={{ textAlign: 'center', paddingLeft: '5px' }}>{no+i +'.'}</td>
                  <td style={{ textAlign: 'left', paddingLeft: '5px' }}>{row[i].name}</td>
                </tr>
              </React.Fragment>
            );

            let detail = row[i].detail;
            if(detail != null){
              for (var p = 0; p < detail.length; p++) {
                totalAll += parseFloat(detail[p].total)||0;
                totalPurchase += parseFloat(detail[p].purchase_price)||0;
                totalAccumulation += parseFloat(detail[p].akumulasi_depreciation)||0;
                totalDepreciation += parseFloat(detail[p].depreciation_price)||0;
                totalAccuDepreciation += parseFloat(detail[p].akumulasi)||0;
                html.push(
                    <React.Fragment>
                      <tr className="table-fill-bold" key={'detail' + p}>
                        <td style={{ textAlign: 'center', paddingLeft: '5px' }}></td>
                        <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{'-  ' + detail[p].name}</td>
                        <td style={{ textAlign: 'left' }}>{detail[p].purchase_date_format}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{'Rp. ' + detail[p].purchase_price_format}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{(detail[p].akumulasi_depreciation > 0)? 'Rp. ' + detail[p].akumulasi_depreciation_format : '-'}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{(detail[p].depreciation_price > 0)?'Rp. ' + detail[p].depreciation_price_format: '-'}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{'-'}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{(detail[p].akumulasi > 0)?'Rp. ' + detail[p].akumulasi_format: '-'}</td>
                        <td style={{ textAlign: 'Right' , paddingRight: '5px'}}>{'Rp. ' + detail[p].total_format}</td>

                      </tr>
                    </React.Fragment>
                  );
                }
            }
            html.push(
              <React.Fragment>
                <tr className="table-fill-bold" key={'total' + i} style={{backgroundColor:'#f5f8fa'}}>
                  <td colSpan={3} style={{ textAlign: 'center', paddingLeft: '5px' }}>{'TOTAL'}</td>
                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    { totalPurchase == 0 ? '-' : 'Rp. ' +parseFloat(totalPurchase)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    { totalAccumulation == 0 ? '-' :'Rp. ' +parseFloat(totalAccumulation)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    { totalDepreciation == 0 ? '-' :'Rp. ' +parseFloat(totalDepreciation)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>{'-'}</td>     

                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    { totalAccuDepreciation == 0 ? '-' : 'Rp. ' + parseFloat(totalAccuDepreciation)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                  <td style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
                    {totalAll == 0 ? 'Rp. ' + 0 : 'Rp. ' +  parseFloat(totalAll)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>           
                </tr>
              </React.Fragment>
            );
        }
      }else{
        html.push(
          <React.Fragment>
          <tr className="table-fill-bold" key={'total' + i} style={{backgroundColor:'#f5f8fa'}}>
            <td colSpan={9} style={{ textAlign: 'center', paddingLeft: '5px' }}>{i18n.t('DATA NOT FOUND')}</td>
            </tr>
          </React.Fragment>
        );
      }
    }
    return html;
  }


  renderTab() {
    const { isProcessing, helperList,dataList } = this.props;
    this.state.yearList = helperList.rangeYear
    let row = [];
    if (dataList != null && dataList != '') {
       row = dataList.data;
       console.log(row)
    }
    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="laporanAssetReport"
        id="laporan"
        className="mb-3"
      >
        <Tab eventKey="laporanAssetReport" title={i18n.t("Asset Report")}>
          <React.Fragment>
            <Fragment>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"month"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "month", "monthValue");
                    }}
                    options={this.state.monthList}
                    title={i18n.t("Month")}
                    value={this.state.monthValue}
                    placeholder={this.state.monthText}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('month')}
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"year"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "year", "yearValue");
                    }}
                    options={this.state.yearList}
                    title={i18n.t("Year")}
                    value={this.state.yearValue}
                    placeholder={this.state.yearText}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('year')}
                  </div>
                  
                </div>
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"idBranch"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      } else {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      }
                    }}
                    options={this.state.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.branchCurrent}
                    placeholder={this.state.branchText}
                    labelfield={"true"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <Button onClick={async (e) => {
                    await this.setState({ show: true }, () => this.setData())
                  }}
                    variant="success" className="btn btn-md btn btn-primary m-1 ">
                    <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Display")}
                  </Button>

                  <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Convert
                  </button>
                  <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item"
                      href={"/accounting/balance-sheet/print/"+ (this.state.branchCurrent != null ? this.state.branchCurrent.value : 'all') +"/"+
                      (this.state.monthValue != null ? this.state.monthValue.value : 'all') +"/"+
                      (this.state.yearValue != null? this.state.yearValue.value: 'all') 
                      
                      } target="_blank">
                      <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  {i18n.t("PDF")}</a> 
                    <a className="dropdown-item " href="#" onClick={() => this.convertExcel()}>
                      <FontAwesomeIcon icon={faFileExcel}>
                      </FontAwesomeIcon>  {i18n.t("Excel")}
                    </a>
                  </div>
                </div>
              </div>
            </Fragment>
            <br />
            {this.state.show == true ?
              <React.Fragment>
                <div className={isProcessing ? " table-loading" : ""}>
                  {!isProcessing ? (
                    <Fragment>
                      <div className="row">
                          <table key={"table-aktiva"} className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5' }}>
                            <thead className="table-header" key={"theader-aktiva"}>
                              <tr >
                                <th rowSpan={2} width={"5%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("No.")}</th>
                                <th rowSpan={2} width={"25%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Asset Name")}</th>
                                <th rowSpan={2} width={"10%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Purchase Date")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Purchase Price")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Accumulated Depreciation")}</th>
                                <th colSpan={2} width={"20%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Depreciation (Month)")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Accumulated Depreciation")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white', verticalAlign:'middle' }}>{i18n.t("Book Value")}</th>
                              </tr>
                              <tr>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{row == null?null:row.date}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{row == null?null:row.accu_date}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("+")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("-")}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{row == null?null:row.date}</th>
                                <th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{row == null?null:row.date}</th>
                              </tr>
                            </thead>
                            <tbody key={"body-aktiva"}>
                              {this.renderList()}
                            </tbody>
                          </table>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="table-loading-message">
                      <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                      <div className="text-center">{i18n.t("Loading")}</div>
                    </div>
                  )}
                </div>
              </React.Fragment>
              : ''}
          </React.Fragment>
        </Tab>

      </Tabs>
    );
  }

  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.branchList = helperList.branch;
    this.state.listData = datalist;

    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetReport;

import React from "react";
import {transactionBilling} from "../Const/TranscationCode";

const LabelBilling=(props)=>{
    const filter=transactionBilling.filter((item)=>item.key===props.value);
    if(filter.length==1){
        return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
    }   
    return ''
}
export default LabelBilling;
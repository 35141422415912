import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel, faDisplay } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
const MySwal = withReactContent(Swal);
export class FormSalesInvoiceItemReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemList: [],
            itemCurrent: null,
            idItem: null,
            itemText: i18n.t('All'),
            branchList: [],
            branchCurrent: null,
            idBranch: null,
            branchText: i18n.t('All'),
            listData: [],
            limitCurrent: null,
            startDate: new Date(),
            endDate: new Date(),
            page: 1,
            show: 0,
            pageLimit: [
                { value: '1', label: 'All' },
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                { value: '50', label: '50' },
            ],
            titleModal: "",
            headeTable: false,
            breadcrumbs: [
                { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
                { link: null, title: i18n.t("Report"), class: " text-gray-600" },
                { link: null, title: i18n.t("SalesInvoice Report"), class: " text-gray-500" },
            ]
        };
        this.handlerDateTime = this.handlerDateTime.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);
    }

    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        }
        )
    }
    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            [key]: value,
            [currentState]: e
        }
        )
    }

    setDataItem() {
        var startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        var endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
        var item = (this.state.itemCurrent == null) ? '' : this.state.itemCurrent.value;
        var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
        var page = (this.state.page == null) ? 1 : this.state.page;

        let params = {
            startDate: startDate,
            endDate: endDate,
            limit: limit,
            item: item,
            idBranch: idBranch,
            page: page,
        }
        this.props.setDataItem(params);
    }

    renderList() {
        const { dataItem } = this.props
        let row = dataItem.data
        let html = [];
        let no = 1;
        let total = 0;
        if (dataItem != undefined && dataItem != null) {
            Swal.close();
            if (row.length > 0) {
                for (var p = 0; p < row.length; p++) {
                    html.push(
                        <tr className="border" key={p}>
                            <td className="text-center">{no++}</td>
                            <td>
                            <Fragment>
                            <details>
                                <summary style={{ fontSize: '16',fontWeight:'medium' }}>{row[p].name_item}</summary>
                                <span width={"100%"}>
                                    <table>
                                    <thead>
                                        <tr className="fw-bold fs-6 text-gray-600 border text-center ">
                                        <th width={"20%"}>{i18n.t("Unit Name")}</th>
                                        <th width={"20%"}>{i18n.t("Price (New)")}</th>
                                        <th width={"20%"}>{i18n.t("Qty")}</th>
                                        <th width={"20%"}>{i18n.t("Total Price")}</th>
                                        <th width={"30%"}>{i18n.t("Average Price")}</th>
                                        <th width={"10%"}></th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            {row[p].unit_data.map((da,i)=>(
                                            <tr style={{ fontSize: '12' }} key={i} className="border ">
                                                <td>{da.name}</td>
                                                <td style={{ textAlign:'right' }}>{da.price_format}</td>
                                                <td style={{ textAlign:'right' }}>{da.qty_format}</td>
                                                <td style={{ textAlign:'right' }}>{da.total_format}</td>
                                                <td style={{ textAlign:'right' }}>{da.average_format}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </span>
                            </details>
                            </Fragment>
                            </td>
                            <td style={{ textAlign: 'right',fontSize: '16',fontWeight:'medium' }}>{row[p].total_format}</td>
                        </tr>)
                        total += row[p].total;
                }
                html.push(
                    <tr className="border  " >
                        <td colSpan={'2'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('TOTAL')}</td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
                        .toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                    </tr>)
            } else {
                html.push(
                    <tr className="border  " >
                        <td colSpan={'5'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
                    </tr>)
            }

            return html;
        }
    }

    alertHandler(title, message, icon, i) {
        Swal.fire({
            title: title,
            html: message,
            icon: icon,
        });
    }
    renderLoading() {
        Swal.fire({
            title: 'Loading',
            width: "200px",
            heightAuto: true
        });
        Swal.showLoading();
    }

    async convertExcel() {
        this.renderLoading();
        const token = cookieManager.getUserToken();
        axios({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            url: process.env.REST_URL + "report/purchasing-report/excel",
            method: "GET",
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                idSupplier: this.state.idSupplier,
            },
            responseType: "blob",
        }).then((response) => {
            if (response && response.status === 200 && response.data) {
                Swal.close();
                this.alertHandler('Convert Excel', 'Download Successfully', 'success')
                var urlCreator = window.URL || window.webkitURL;
                const blobUrl = urlCreator.createObjectURL(response.data);
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = blobUrl;
                link.setAttribute("download", 'Laporan Hutang.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
            }
        });
    }

    changeLimit(e, name, currentState) {
        var key = name
        var value = null
        if (e != 1) {
            value = e.value
        }
        this.setState({
            [key]: value,
            [currentState]: e
        }, () => this.setDataItem()
        )

    }

    changePage(event, url, count) {
        let get = parseInt(this.state.page) || 0;
        if (event.target.value == 0) {
            this.setState({ page: (get - 1) }, () => this.setDataItem());
        } else if (event.target.value == (count - 1)) {
            this.setState({ page: (get + 1) }, () => this.setDataItem());
        } else {
            this.setState({ page: event.target.value }, () => this.setDataItem())
        }
    }

    renderPagination() {
        const { dataItem } = this.props;
        if (this.state.show == 0) {
            this.state.limitCurrent = { value: '1', label: 'All' }
            this.state.show = 1;
        }
        this.state.limit = 10;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="dataTables_length col-sm-9">
                        <label>
                            <Select2
                                name={"limit"}
                                labelfield={"false"}
                                onChange={async (e) => {
                                    this.changeLimit(e, "limit", "limitCurrent")
                                }}
                                options={this.state.pageLimit}
                                title={i18n.t("")}
                                value={this.state.limitCurrent}
                            />
                        </label>
                    </div>
                    <div
                        className="col-sm-3"
                        id="kt_customers_table_paginate"
                    >
                        <ul className="pagination">
                            {dataItem.links != null
                                ? dataItem.links.map((item, i) => (
                                    <li
                                        className={
                                            "paginate_button page-item" +
                                            (i == 0 ? " previous" : "") +
                                            (i == dataItem.links.length - 1 ? " next" : "") +
                                            (item.url == null ? " disabled" : "") +
                                            (item.active ? " active" : "")
                                        }
                                        key={i}
                                    >
                                        <button
                                            key={i}
                                            onClick={(e) => this.changePage(e, item.url, dataItem.links.length)}
                                            aria-controls="kt_customers_table"
                                            data-dt-idx={i}
                                            tabIndex="0"
                                            className="page-link"
                                            value={i}
                                        >
                                            {i == 0 ? 'Prev' : ""}
                                            {i != 0 && i != dataItem.links.length - 1 ? item["label"] : ""}
                                            {i == dataItem.links.length - 1 ? 'Next' : ""}
                                        </button>
                                    </li>
                                ))
                                : ""}
                        </ul>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    render() {
        const { isProcessing, helperSalesInvoiceReport } = this.props;
        this.state.branchList = helperSalesInvoiceReport.branch;
        this.state.itemList = helperSalesInvoiceReport.item;
        return (

            <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card">
                            <div className="card-body pt-3">
                                <div className="row mb-4" >
                                    <div className="col-sm-4" style={{ fontSize: '20px' }}>
                                        Period Date:
                                    </div>
                                    <div className="col-sm-2" style={{ fontSize: '20px' }}>
                                        Branch:
                                    </div>
                                    <div className="col-sm-2" style={{ fontSize: '20px' }}>
                                        Item:
                                    </div>

                                </div>
                                <div className="row mb-8">
                                    <div className="col-sm-2">
                                        <DatePickerCustom
                                            name={"startDate"}
                                            title={i18n.t("")}
                                            selected={this.state.startDate}
                                            onSelect={async (e) => {
                                                await this.handlerDateTime(e, 'startDate');
                                            }
                                            }
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            peekNextMonth
                                            showMonthDropdown
                                            onChange={(e) => { }}
                                            startDay={0}
                                            autoComplete={"off"}
                                            dateFormat="dd MMM yyyy"
                                            className="form-control form-control-sm"
                                        >

                                        </DatePickerCustom>
                                    </div>
                                    {i18n.t("To")}
                                    <div className="col-sm-2">
                                        <DatePickerCustom
                                            name={"endDate"}
                                            title={i18n.t("")}
                                            selected={this.state.endDate}
                                            onSelect={async (e) => {
                                                await this.handlerDateTime(e, 'endDate');
                                            }
                                            }
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            peekNextMonth
                                            showMonthDropdown
                                            onChange={(e) => { }}
                                            autoComplete={"off"}
                                            dateFormat="dd MMM yyyy"
                                            className="form-control form-control-sm"
                                        ></DatePickerCustom>
                                    </div>
                                    <div className="col-sm-2">
                                        <Select2
                                            isClearable={true}
                                            name={"idBranch"}
                                            required={true}
                                            size={4}
                                            onChange={async (e) => {
                                                if (e == null) {
                                                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                } else {
                                                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                }
                                            }}
                                            options={this.state.branchList}
                                            title={i18n.t("Branch")}
                                            value={this.state.branchCurrent}
                                            placeholder={this.state.branchText}
                                            labelfield={"false"}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <Select2
                                            isClearable={true}
                                            name={"idItem"}
                                            labelfield={"false"}
                                            onChange={async (e) => {
                                                if (e == null) {
                                                    this.handlerSelect(e, "idItem", "itemCurrent")
                                                }
                                                else {
                                                    this.handlerSelect(e, "idItem", "itemCurrent")
                                                }
                                            }}
                                            options={this.state.itemList}
                                            title={i18n.t("")}
                                            value={this.state.itemCurrent}
                                            placeholder={this.state.itemText}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                            <Button onClick={async (e) => {
                                                await this.setState({ page: 1 }, () => this.setDataItem())
                                                await this.setState({ headeTable: true })
                                            }}
                                            variant="success" className="btn btn-md btn btn-success"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                                        </div>
                                </div>

                                {this.state.headeTable == true ?
                                     <React.Fragment>
                                     {!isProcessing ?
                                         <table className="table table-responsive table-bordered table-hover border  ">
                                             <thead>
                                                 <tr className="fw-bold fs-6 text-gray-800 bg-aquamarine square border text-center ">
                                                     <th width={"3%"}>{i18n.t("No.")}</th>
                                                     <th width={"82"}>{i18n.t("Item Name")}</th>
                                                     <th width={"15%"}>{i18n.t("Total")}</th>
                                                 </tr>
                                             </thead>
                                             <tbody>
                                             {this.renderList()}
                                             </tbody>
                                         </table>

                                         : this.renderLoading()
                                     }
                                     {this.renderPagination()}
                                 </React.Fragment> :
                                    ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormSalesInvoiceItemReport;

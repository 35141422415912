import React, { Component } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import InputNumber from "../../../utilities/Form/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faBoxArchive,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TextArea from "../../../utilities/Form/TextArea";
import FormConvertion from "./formConvertion";
import InputCustom from "../../../utilities/Form/InputCustom";
import i18n from "../../../../i18n";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        WHOutDetail: [],
      },
      itemUses: [],
      idBranchOriginal: null,
      branchDesList: [],
      branchText: i18n.t("Select Branch"),
      storeText: i18n.t("Select Store"),
      warehouseText: i18n.t("Select Warehouse"),
      itemText: i18n.t("Select Item"),
      branchOriList: [],
      branchOriValue: null,
      itemPR: false,
      branchDesValue: null,
      storeDesList: [],
      storeOriList: [],
      storeOriListLoading: false,
      storeDesListLoading: false,
      warehouseOriListLoading: false,
      storeOriValue: null,
      storeDesValue: null,
      warehouseOriList: null,
      idOriginalWH: null,
      idOriginalWHValue: null,
      deliveryService: "",
      doNo: null,
      vehicleNo: "",
      date: new Date(),
      description: "",
      status: 1,
      arrayDetail: [
        {
          suffixqty: "",
          qtySave: 0,
          qtyData: 0,
          qtyRequest: 0,
          itemLoading: false,
          idItem: null,
          idUnit: null,
          idPRDetail: null,
          batchNo: null,
          expDate: new Date(),
          bandNo: null,
          unitPrice: 0,
          unit: [],
          qty: 0,
          unitType: null,
          description: "",
          itemValue: null,
          unitValue: null,
          disabled: false,
          stock: 0,
          suffix: '',
          convertionDetail: [],
          itemList: [],
          idTemp: null,

        },
      ],
      isUpdate: 0,
    };
    (this.formConvertion = React.createRef()),
      (this.handler = this.handler.bind(this));
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
    this.props.changeSelect(name, value);
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }

  runSubmit() {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    const WHOut = {
      idBranchOriginal:
        this.state.branchOriValue == null
          ? ""
          : this.state.branchOriValue.value,
      idStoreOriginal:
        this.state.storeOriValue == null ? "" : this.state.storeOriValue.value,
      idBranchDestination:
        this.state.branchDesValue == null
          ? ""
          : this.state.branchDesValue.value,
      idStoreDestination:
        this.state.storeDesValue == null ? "" : this.state.storeDesValue.value,
      idOriginalWH: this.state.idOriginalWH,
      deliveryService: this.state.deliveryService,
      doNo: this.state.doNo,
      vehicleNo: this.state.vehicleNo,
      date: date,
      description: this.state.description,
      status: 1,
    };

    const WHOutDetail = this.state.arrayDetail;
    let idItem = [];
    let idUnit = [];
    let idPRDetail = [];
    let idWarehouseOut = [];
    let batchNo = [];
    let expDate = [];
    let bandNo = [];
    let unitPrice = [];
    let qty = [];
    let description = [];
    let convertionList = [];
    let idTemp = [];

    for (var i = 0; i < WHOutDetail.length; i++) {
      idItem.push(WHOutDetail[i].idItem == null ? "" : WHOutDetail[i].idItem);
      idPRDetail.push(
        WHOutDetail[i].idPRDetail == null ? "" : WHOutDetail[i].idPRDetail
      );
      batchNo.push(
        WHOutDetail[i].batchNo == null ? "" : WHOutDetail[i].batchNo
      );
      expDate.push(
        WHOutDetail[i].expDate == null ? "" : WHOutDetail[i].expDate
      );
      bandNo.push(WHOutDetail[i].bandNo == null ? "" : WHOutDetail[i].bandNo);
      qty.push(WHOutDetail[i].qty == null ? "" : WHOutDetail[i].qty);
      unitPrice.push(
        WHOutDetail[i].unitPrice == null ? "" : WHOutDetail[i].unitPrice
      );
      description.push(
        WHOutDetail[i].description == null ? "" : WHOutDetail[i].description
      );
      idTemp.push(
        WHOutDetail[i].idTemp == null ? "" : WHOutDetail[i].idTemp
      );
      convertionList.push(
        WHOutDetail[i].convertionDetail == null
          ? ""
          : WHOutDetail[i].convertionDetail
      );
    }
    this.state.form.WHOut = WHOut;
    this.state.form.WHOutDetail = {
      idItem: idItem,
      idPRDetail: idPRDetail,
      unitPrice: unitPrice,
      description: description,
      qty: qty,
      convertionDetail: convertionList,
      bandNo: bandNo,
      idTemp: idTemp
    };
    this.state.isUpdate = 0;
    return this.state.form;
  }

  //CONVERTION
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.stock,
      check: this.state.itemPR,
      convertionDetail: detail.convertionDetail,
      qtyRequest: detail.qtyData,
      qtyValid: detail.qtySave,
      suffixty: detail.suffixqty,
      convert: detail.idU,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  setItem(detail, i) {
    let arr = [...this.state.arrayDetail];
    const objectss = arr[i];
    objectss['stock'] = parseInt(arr[i].itemValue.lastStok)
    objectss['suffix'] = arr[i].itemValue.currentUnit
    objectss['bandNo'] = arr[i].itemValue.no_band
    this.setState(arr)

  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td width={"500px"}>
            <Select2
              isClearable={true}
              name={"idItem"}
              required={true}
              labelfield={"false"}
              title={"itemList"}
              onChange={async (e) => {
                var valueBefore = detail.itemValue;
                if (e != null) {
                  if (e != valueBefore) {
                    if (valueBefore != null) {
                      var index = this.state.itemUses.indexOf(
                        valueBefore.value
                      );
                      if (index !== -1) {
                        this.state.itemUses.splice(index, 1);
                      }
                    }
                    if (this.state.itemUses.includes(e.value)) {
                      this.alertError(i18n.t("Item Use"), i18n.t("Items Are Used"), "info");
                    } else {
                      this.state.itemUses.push(e.value);
                      await this.handlerSelectArray(e, "idItem", i, "arrayDetail", "itemValue");
                      await this.setItem(i);

                      if (this.state.itemPR) {
                        await this.changeData({
                          keyFill: "idItem",
                          type: "getStockItem",
                          key: "",
                          array: "arrayDetail",
                          arraykey: i
                        })

                        let remaining = e != null ? e.helper : 0;
                        let array = remaining.toString().split(" ");
                        await this.handlerCurrencyArray(
                          parseFloat(array[0]),
                          "qtyData",
                          i,
                          "arrayDetail"
                        );
                        if (array.length > 1) {
                          await this.handlerCurrencyArray(
                            " " + array[1],
                            "suffixqty",
                            i,
                            "arrayDetail"
                          );
                        }
                        await this.changeData({
                          keyFill: "bandNo",
                          type: "getDetailItemStock",
                          key: "bandNo",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "unitType",
                          type: "getStockItem",
                          key: "unit",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "idU",
                          type: "getStockItem",
                          key: "convert",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "idTemp",
                          type: "getStockItem",
                          key: "",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "idTemp",
                          type: "getStockItem",
                          key: "id",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "qtySave",
                          type: "getStockItem",
                          key: "qtyMutation",
                          array: "arrayDetail",
                          arraykey: i
                        })
                        await this.changeData({
                          keyFill: "idPRDetail",
                          type: "getStockItem",
                          key: "idPRDetail",
                          array: "arrayDetail",
                          arraykey: i
                        })

                      } else {
                        await this.changeData({
                          keyFill: "unitPrice",
                          type: "getStockItem",
                          key: "unitPrice",
                          array: "arrayDetail",
                          arraykey: i
                        })

                      }
                      await this.changeData({
                        keyFill: "unit",
                        type: "getUnitWarehouseTF",
                        key: "",
                        array: "arrayDetail",
                        arraykey: i
                      })
                    }
                  }
                } else {
                  if (valueBefore != null) {
                    var index = this.state.itemUses.indexOf(valueBefore.value);
                    if (index !== -1) {
                      this.state.itemUses.splice(index, 1);
                    }
                  }
                  await this.handlerSelectArray(
                    e,
                    "idItem",
                    i,
                    "arrayDetail",
                    "itemValue"
                  );
                  await this.setState({ itemUses: [] });
                  await this.handlerCurrencyArray(
                    {},
                    "stock",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(
                    [],
                    "convertionDetail",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(0, "qty", i, "arrayDetail");
                  await this.handlerCurrencyArray(
                    0,
                    "unitPrice",
                    i,
                    "arrayDetail"
                  );
                }
              }}
              options={this.state.itemList}
              placeholder={this.state.itemText}

              value={detail.itemValue}

            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("WHOutDetail.idItem." + i)}
            </div>
          </td>
          <td width={"250px"}>
            <div className="row">
              <div className="col-9 p-0">
                <InputNumber
                  id={"qty" + i}
                  name="qty"
                  labelfield={"false"}
                  required={true}
                  defaultValue={0}
                  value={detail.qty}
                  decimalsLimit={2}
                  disabled={true}
                  onValueChange={(value, name) => {
                    this.handlerCurrencyArray(value, "qty", i, "arrayDetail");
                  }}
                  className="form-control  form-control-sm text-end"
                  error={this.renderError("WHOutDetail.qty." + i)}
                />
              </div>

              {(detail.stock > 0 && detail.unit.length > 0) ? (
                <div className="col p-0">
                  <Button
                    variant={
                      detail.convertionDetail.length > 0 ? "warning" : "info"
                    }
                    className="btn p-2"
                    onClick={(e) => {
                      this.setConvertion(detail, i, "arrayDetail", "qty");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        detail.convertionDetail.length > 0
                          ? faBoxOpen
                          : faBoxArchive
                      }
                    />{" "}
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="col-9 p-0">
                <InputNumber
                  id={"qtyData" + i}
                  name="qtyData"
                  labelfield={"false"}
                  defaultValue={0}
                  value={detail.qtyData}
                  decimalsLimit={2}
                  disabled={true}
                  onValueChange={(value, name) => {

                  }}
                  hidden={true}
                  className="form-control  form-control-sm text-end"
                  suffix={detail.suffixqty}
                />
              </div>
            </div>
          </td>

          <td width={"200px"}>
            <InputNumber
              id={"stock"}
              name={"stock"}
              labelfield={"false"}
              value={detail.stock}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "stock", i, "arrayDetail")
              }
              suffix={detail.suffix}
              className="form-control  form-control-sm text-end"
              disabled={true}
            />
          </td>
          <td>
            <InputNumber
              id={"unitPrice" + i}
              name="unitPrice"
              placeholder={i18n.t("unitPrice")}
              labelfield={"false"}
              defaultValue={0}
              value={detail.unitPrice}
              decimalsLimit={2}
              hidden={true}
              prefix="Rp "
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "unitPrice", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              disabled={true}
            />
          </td>
          <td>
            <input
              className="form-control  form-control-sm "
              labelfield={"false"}
              name={"description"}
              id={"description" + i}
              value={detail.description ?? ''}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            />
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData({ keyFill, type = null, key = "", array = null, arraykey = null, callback = () => { } } = props) {
    const token = cookieManager.getUserToken()
    let params = null
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-wh-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchOriValue != undefined
            ? this.state.branchOriValue.value
            : null;
        params = {
          date: date,
          idBranchOriginal: branch,
        };
        break;
      case "getStoreOri":
        this.state.storeOriListLoading = true;
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchOriValue != null
            ? this.state.idBranchOriginal
            : null;
        params = {
          id: branch,
        };

        break;
      case "getStoreDes":
        this.state.storeDesListLoading = true;
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchDesValue != null
            ? this.state.branchDesValue.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getWarehouseOri":
        this.state.warehouseOriListLoading = true;
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchOriValue != null
            ? this.state.branchOriValue.value
            : null;
        var idStore =
          this.state.storeOriValue != null
            ? this.state.storeOriValue.value
            : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;
      case "getUnitWarehouseTF":
        var itemPR = this.state.itemPR;
        if (itemPR) {
          var item =
            this.state[array][arraykey] != null
              ? this.state[array][arraykey].itemValue.idTemp
              : null;
          var type = 'out';
          params = {
            id: item,
            type: type
          };
          var url = process.env.REST_URL + "helper/get-unit-recap-warehousetf";
        } else {
          var item =
            this.state[array][arraykey] != null
              ? this.state[array][arraykey].itemValue.value
              : null;
          var type = 'out';
          params = {
            id: item,
            type: type

          };
          var url = process.env.REST_URL + "helper/get-unit-warehousetf";
        }

        break;

      case "getDesRecap":
        var itemPR = this.state.itemPR;
        if (itemPR) {
          var url = process.env.REST_URL + "helper/get-des-recap";
        }
        var item =
          this.state[array] != null ? this.state[array][arraykey].idItem : null;
        params = {
          id: item,
        };
        break;

      case "getItemStock":
        this.state.arrayDetail = [
          {
            itemLoading: true,
            suffixqty: "",
            idItem: null,
            idUnit: null,
            idPRDetail: null,
            batchNo: null,
            expDate: new Date(),
            bandNo: null,
            unitPrice: 0,
            unit: [],
            qty: 0,
            description: "",
            itemValue: null,
            itemList: [],
            unitValue: null,
            disabled: false,
            stock: 0,
            suffix: '',
            convertionDetail: [],
            itemUses: [],
            idU: null,
          },
        ];

        var idBranch =
          this.state.branchOriValue != null
            ? this.state.branchOriValue.value
            : null;
        var idStore =
          this.state.storeOriValue != null
            ? this.state.storeOriValue.value
            : null;
        var warehouse =
          this.state.idOriginalWHValue != null
            ? this.state.idOriginalWHValue.value
            : null;
        // var subwarehouse =
        //   this.state.idOriginalWHValue != null
        //     ? this.state.idOriginalWHValue.helper2
        //     : null;
        // var idBranchDes =
        //   this.state.idBranchDestination != null
        //     ? this.state.idBranchDestination
        //     : null;

        var itemPR = this.state.itemPR;
        if (itemPR) {
          var url = process.env.REST_URL + "helper/get-item-stock-recap";
          params = {
            id: idBranchDes,
          };
        } else {
          var url = process.env.REST_URL + "helper/get-item-stock";
          params = {
            branch: idBranch,
            store: idStore,
            warehouse: warehouse,
            subwarehouse: subwarehouse,
          };
        }
        break;

      case "getDetailItemStock":
        var itemPR = this.state.itemPR;
        var branch =
          this.state.branchOriValue != null
            ? this.state.branchOriValue.value
            : null;
        var store =
          this.state.storeOriValue != null
            ? this.state.storeOriValue.value
            : null;
        var warehouse =
          this.state.idOriginalWHValue != null
            ? this.state.idOriginalWHValue.helper
            : null;
        var subwarehouse =
          this.state.idOriginalWHValue != null
            ? this.state.idOriginalWHValue.helper2
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var bandno =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.bandno
            : null;
        var idk =
          this.state.idBranchDestination != null
            ? this.state.idBranchDestination
            : null;
        var iditem =
          this.state[array][arraykey].itemValue != null
            ? this.state[array][arraykey].itemValue.item
            : null;

        if (itemPR) {
          var url = process.env.REST_URL + "helper/get-detail-item-stock-recap";
          params = {
            warehouse: warehouse,
            subwarehouse: subwarehouse,
            store: store,
            branch: branch,
            date: date,
            item: iditem,
            bandno: bandno
          };
        } else {
          var url = process.env.REST_URL + "helper/get-detail-item-stock";
          params = {
            warehouse: warehouse,
            subwarehouse: subwarehouse,
            store: store,
            branch: branch,
            date: date,
            item: iditem,
            bandno: bandno
          };
        }
        break;

      case "getStockItem":
        var itemPR = this.state.itemPR;
        if (itemPR) {
          var idk =
            this.state[array][arraykey] != null
              ? this.state[array][arraykey].itemValue.idTemp
              : null;
          params = {
            id: idk,
          };
          var url = process.env.REST_URL + "helper/get-stock-item-recap";
        } else {
          var idk =
            this.state[array][arraykey] != null
              ? this.state[array][arraykey].itemValue.value
              : null;
          params = {
            id: idk,
          };
          var url = process.env.REST_URL + "helper/get-stock-item";
        }


        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse = null
            if (array != null) {
              var arr = [...this.state[array]];

              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse = response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse = response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse = response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse = response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () => callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    const { data, isNewRecord, helperWarehouseOut } = this.props;
    if (helperWarehouseOut != null && helperWarehouseOut != undefined) {
      this.state.branchOriList = helperWarehouseOut != null ? helperWarehouseOut.branch : null;

      this.state.branchDesList = helperWarehouseOut != null ? helperWarehouseOut.branch : null;

    }

    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={false}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData({
                    keyFill: "doNo",
                    type: "getCode",
                    key: "code",
                  })
                  // await this.changeData("doNo", "getCode", "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                endDate={new Date()}
                className="form-control form-control-sm"
                error={this.renderError("WHOut.date")}
              />
            </div>
            <div className="col-md-3">
              <Input
                disabled={true}
                type={"input"}
                name={"doNo"}
                required={true}
                title={i18n.t("Document No.")}
                value={this.state.doNo}
                handler={this.handler}
                error={this.renderError("WHOut.doNo")}
              />
            </div>
            <div className="col-md-3">
              <Input
                type={"input"}
                name={"vehicleNo"}
                required={true}
                title={i18n.t("Plate No.")}
                value={this.state.vehicleNo}
                handler={this.handler}
                error={this.renderError("WHOut.vehicleNo")}
              />
            </div>
            <div className="col-md-3">
              <Input
                type={"input"}
                required={true}
                name={"deliveryService"}
                title={i18n.t("Expedition")}
                value={this.state.deliveryService}
                handler={this.handler}
                error={this.renderError("WHOut.deliveryService")}
              />
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-6">
              <h1>{i18n.t("Origin Warehouse")}</h1>
            </div>
            <div className="col-md-6">
              <h1>{i18n.t("Destination Warehouse")}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"idBranchOriginal"}
                required={true}
                onChange={async (e) => {

                  if (e == null) {
                    await this.handlerSelect(
                      e,
                      "idBranchOriginal",
                      "branchOriValue"
                    );
                    await this.setState({
                      doNo: "", idOriginalWHValue: null, itemUses: [], storeOriValue: null, warehouseOriList: [], storeOriList: [],
                      branchDesValue: "", storeDesValue: "", itemList: [], itemValue: null, itemPR: false
                    })
                  } else {
                    await this.handlerSelect(
                      e,
                      "idBranchOriginal",
                      "branchOriValue"
                    );

                    await this.setState({ idOriginalWHValue: null, storeOriValue: null, warehouseOriList: [] });

                    await this.changeData({
                      keyFill: "doNo",
                      type: "getCode",
                      key: "code",
                    })
                    await this.changeData({
                      keyFill: "storeOriList",
                      type: "getStoreOri",
                      key: "",
                      callback: () => { this.setState({ storeOriListLoading: false }) }
                    })
                    await this.changeData({
                      keyFill: "warehouseOriList",
                      type: "getWarehouseOri",
                      key: "",
                      callback: () => { this.setState({ warehouseOriListLoading: false }) }
                    })
                  }
                }}
                options={this.state.branchOriList}
                title={i18n.t("Branch")}
                value={this.state.branchOriValue}
                placeholder={this.state.branchText}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("WHOut.idBranchOriginal")}
              </div>
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                isLoading={this.state.storeOriListLoading}
                name={"idStoreOriginal"}
                onChange={async (e) => {
                  await this.handlerSelect(
                    e,
                    "idStoreOriginal",
                    "storeOriValue"
                  );

                  await this.changeData({
                    keyFill: "warehouseOriList",
                    type: "getWarehouseOri",
                    key: "",
                  })
                  if (e == null) {
                    await this.setState({ warehouseOriList: [], idOriginalWHValue: null, itemUses: [] });
                  }
                }}
                options={this.state.storeOriList}
                title={i18n.t("Store")}
                value={this.state.storeOriValue}
                placeholder={this.state.storeText}

              />
            </div>

            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"idBranchDestination"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(
                      e,
                      "idBranchDestination",
                      "branchDesValue"
                    );
                    await this.setState({ storeDesValue: null, itemUses: [] });
                  } else {
                    await this.handlerSelect(
                      e,
                      "idBranchDestination",
                      "branchDesValue"
                    );
                    await this.setState({ itemUses: [], storeDesValue: null });

                    if (this.state.itemPR) {
                      await this.changeData({
                        keyFill: "itemList",
                        type: "getItemStock",
                        key: "",
                      })
                    }
                    await this.changeData({
                      keyFill: "storeDesList",
                      type: "getStoreDes",
                      key: "",
                      callback: () => { this.setState({ storeDesListLoading: false }) }
                    })
                  }
                }}
                options={this.state.branchDesList}
                title={i18n.t("Branch")}
                value={this.state.branchDesValue}
                placeholder={this.state.branchText}

              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("WHOut.idBranchDestination")}
              </div>
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                isLoading={this.state.storeDesListLoading}
                name={"idStoreDestination"}
                onChange={async (e) => {
                  await this.handlerSelect(
                    e,
                    "idStoreDestination",
                    "storeDesValue"
                  );
                  if (e == null) {
                    await this.setState({ itemUses: [] });
                  }
                }}
                options={this.state.storeDesList}
                title={i18n.t("Store")}
                value={this.state.storeDesValue}
                placeholder={this.state.storeText}

              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <Select2
                isClearable={true}
                isLoading={this.state.warehouseOriListLoading}
                name={"idOriginalWH"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(
                      e,
                      "idOriginalWH",
                      "idOriginalWHValue"
                    );
                    await this.changeData({
                      keyFill: "itemList",
                      type: "getItemStock",
                      key: "",
                    })
                    await this.setState({ itemPR: false, itemUses: [], idTemp: null });
                  } else {
                    await this.handlerSelect(
                      e,
                      "idOriginalWH",
                      "idOriginalWHValue"
                    );
                    await this.setState({ itemUses: [] });
                    await this.changeData({
                      keyFill: "itemList",
                      type: "getItemStock",
                      key: "",
                    })
                  }
                }}
                options={this.state.warehouseOriList}
                title={i18n.t("Initial Warehouse")}
                value={this.state.idOriginalWHValue}
                placeholder={this.state.warehouseText}

              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("WHOut.idOriginalWH")}
              </div>
            </div>
            <div className="col-3">
              <InputCustom
                type={"checkbox"}
                className={
                  "form-check-input form-check form-check-custom form-check-solid"
                }
                name={"itemPR"}
                title={i18n.t("Stock From Purchase Recap")}
                value={this.state.itemPR}
                checked={this.state.itemPR}
                disabled={this.state.idOriginalWHValue == null ? true : false}
                onChange={async (e) => {
                  await this.setState({ itemUses: [], itemList: [], itemPR: !this.state.itemPR });
                  await this.changeData({
                    keyFill: "itemList",
                    type: "getItemStock",
                    key: "",
                  })
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextArea
                type={"input"}
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description ?? ""}
                handler={this.handler}
              />
            </div>

          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-left">{i18n.t("Item")}</th>
                    <th className="text-left">{i18n.t("Qty")}</th>
                    <th className="text-left">{i18n.t("Stock")}</th>
                    <th className="text-left"></th>
                    <th className="text-left">{i18n.t("Description")}</th>
                    <th className="text-left">{i18n.t("Action")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      idItem: null,
                      idUnit: null,
                      idPRDetail: null,
                      batchNo: null,
                      expDate: new Date(),
                      bandNo: null,
                      unitPrice: 0,
                      unit: [],
                      qty: 0,
                      description: "",
                      itemValue: null,
                      unitValue: null,
                      disabled: false,
                      stock: 0,
                      suffix: '',
                      convertionDetail: [],
                      idU: null,
                      suffixqty: "",
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Details")}
              </Button>
            </div>
          </div>
        </form>
        <Modal show={this.state.modalDetail} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConvertion
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formConvertion}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveConvertion(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.saveConvertion(true)}
            >
              {i18n.t("Convertion")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;


import React, { Component } from "react";
import cookieManager from "../../../../utils/cookieManager";
import InputNumber from "../../../utilities/Form/InputNumber";
import Swal from "sweetalert2";
import i18n from "../../../../i18n";

const token = cookieManager.getUserToken();
export class FormPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      updatePrice: 0,
      dataPrice: [],
      isUpdate: 0,
      detail: [],
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  calculateAll() {}
  runSubmit() {
    if (this.state.updatePrice == 0) {
      this.alertError("Warning", i18n.t("Price Must be Greater Than 0"), "warning");
    } else {
      for (var i = 0; i < this.state.detail.length; i++) {
        this.state.dataPrice.push(this.state.detail[i].updated);
      }
      this.state.dataResult = {
        price: this.state.dataPrice,
      };
    }
    return this.state.dataResult;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  calculateRow(value) {
    const arr = this.state.detail;
    for (var i = 0; i < arr.length; i++) {
      const objectss = arr[i];
      if (arr[i].changePrice != null && arr[i].changePrice == 1) {
        let price = parseFloat(arr[i].price) || 0;
        let result = parseFloat(value) + parseFloat(price);
        objectss["updated"] = parseFloat(result) || 0;
      } else {
        let price = parseFloat(arr[i].price) || 0;
        let result = 0 + parseFloat(price);
        objectss["updated"] = parseFloat(result) || 0;
      }
    }
    this.setState(arr);
  }

  handlerCurrency(value, name) {
    var key = name;
    var value = value;
    this.setState({
      [key]: value,
    });
    this.calculateRow(value);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState(
      {
        ...this.state,
        [key]: arr,
      },
      async () => {
        await this.calculateAll();
      }
    );
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  render() {
    const { data } = this.props;
    this.state.detail = data.array;
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-mid">
              <InputNumber
                id={"updatePrice"}
                name="updatePrice"
                placeholder={i18n.t("Update Price")}
                title={i18n.t("Additional Price")}
                defaultValue={0}
                value={this.state.updatePrice}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  this.handlerCurrency(value, "updatePrice")
                }
                className="form-control  form-control-sm text-end"
                prefix="Rp "
              />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default FormPrice;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import PurchasingReportMiddleware from "../../../../middleware/modules/report/purchasing-report";
import FormPurchasingItemReport from "../../../../components/modules/report/purchasing-report/purchasing-item";

const mapStateToProps = ({ auth, purchasingReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: purchasingReport.isProcessing,
  isError: purchasingReport.isError,
  errorMessage: purchasingReport.errorMessage,
  datalistItem: purchasingReport.dataItem,
  isSuccess: purchasingReport.isSuccess,
  isReset: purchasingReport.isReset,
  isNewRecord: purchasingReport.isNewRecord,
  onSubmit: purchasingReport.onSubmit,
  helperPurchasingReport: purchasingReport.helperPurchasingReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDataItem: (data) => PurchasingReportMiddleware.listItem(data),
    },
    dispatch
  );
};
class PurchasingItemReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    datalistItem: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.setDataItem = this.setDataItem.bind(this);
  }

  setDataItem() {
    this.props.setDataItem();
  }


  render() {
    const { datalistItem } = this.props
    return (
      <Fragment>
        <FormPurchasingItemReport
          {...this.props}
          dataItem={datalistItem}
        />
      </Fragment>

    );
    // }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingItemReport);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import SalesInvoiceReportMiddleware from "../../../../middleware/modules/report/sales-report";
import FormSalesInvoiceItemReport from "../../../../components/modules/report/sales-report/sales-item";

const mapStateToProps = ({ auth, salesInvoiceReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: salesInvoiceReport.isProcessing,
  isError: salesInvoiceReport.isError,
  errorMessage: salesInvoiceReport.errorMessage,
  datalistItem:salesInvoiceReport.dataItem,
  isSuccess: salesInvoiceReport.isSuccess,
  isReset: salesInvoiceReport.isReset,
  isNewRecord: salesInvoiceReport.isNewRecord,
  onSubmit: salesInvoiceReport.onSubmit,
  helperSalesInvoiceReport: salesInvoiceReport.helperSalesInvoiceReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDataItem: (data) =>SalesInvoiceReportMiddleware.listItem(data),
    },
    dispatch
  );
};
class SalesInvoiceItemReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    datalistItem: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show : 0,
    };
    this.setDataItem = this.setDataItem.bind(this);
  }

  setDataItem() {
    this.props.setDataItem();
  }


  render() {
    const {datalistItem} = this.props
   
    return (
      <Fragment>
            <FormSalesInvoiceItemReport
             {...this.props}
             dataItem={datalistItem}
            />
         </Fragment>
     
    );
  // }
}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceItemReport);

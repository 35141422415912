import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Supplier } from '../../../../components/modules/master/supplier';
// middlewares
import SupplierMiddleware from '../../../../middleware/modules/master/supplier';
import ProvinceMiddleware from '../../../../middleware/modules/master/province';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,supplier }) => ({
  isProcessing: supplier.isProcessing,
  isError: supplier.isError,
  errorMessage: supplier.errorMessage,
  datalist:supplier.data,
  showModal:supplier.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>SupplierMiddleware.create(),
    reset: () =>SupplierMiddleware.resetList(),
    search: data =>SupplierMiddleware.list(data),
    update: data =>SupplierMiddleware.update(data),
    delete: (id,search) =>SupplierMiddleware.beforeDelete(id,search),
    //helpers
    getHelper: () =>SupplierMiddleware.getHelperFull(),
   
  }, dispatch);
};
class SupplierContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.getHelper()
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Supplier List")}
          </title>
        </Helmet>
      <Supplier
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierContainer);

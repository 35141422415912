import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import InputNumber from "../../../utilities/Form/InputNumber";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        SalesTarget: {},
        SalesTargetDetail: [],
      },
      transactionNo: "",
      titleModal: i18n.t("Convertion"),
      date: new Date(),
      startDateDaily: new Date(),
      endDateDaily: new Date(),
      startDateWeekly: new Date(),
      endDateWeekly: new Date(),
      dateDaily:new Date(),
      qtyDaily: 0,
      qtyWeekly: 0,
      qtyMonthly: 0,
      nominalDaily: 0,
      nominalWeekly: 0,
      nominalMonthly: 0,
      dateMonthly:null,
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      arrayDetail: [
        {
          startDate: new Date(),
          endDate: new Date(),
          dateDaily:new Date(),
          qtyDaily: 0,
          qtyWeekly: 0,
          qtyMonthly: 0,
          type: null,
          nominalDaily: 0,
          nominalWeekly: 0,
          nominalMonthly: 0,
        },
      ],
      daily:i18n.t('Daily'),
      weekly:i18n.t('Weekly'),
      monthly:i18n.t('Monthly'),
     
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
    
      isUpdate: 0,
      status: 1,
      totalPrice: 0,
      grandTotal: 0,
      limit:0,
      allTrans:0,
      addItem:0,
      paymentType:null,
    };

    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerNumber(value,name) {
    var key = name;
    var value = value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }

    let priceNett = price + priceTax - discount;
    let priceTotal = priceNett * qty;
    objectss["priceNett"] = priceNett;
    objectss["priceTax"] = priceTax;
    objectss["priceTotal"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    //total
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].price) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].priceTax) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }

    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });
  }
  runSubmit() {
    const SalesTarget = {
      date: moment(this.state.date).format("YYYY-MM-DD"),
      startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
      idBranch:
        this.state.branchCurrent != null ? this.state.branchCurrent.value : "",
      status: 1,

    };
    
    const detail = this.state.arrayDetail;
    
    let startDateDaily = null;
    let endDateDaily = null;
    let startDateWeekly = null;
    let endDateWeekly = null;
    let dateMonthly = null;
    let qtyDaily = null;
    let qtyMonthly = null;
    let qtyWeekly = null;
    let nominalDaily = null;
    let nominalMonthly = null;
    let nominalWeekly = null;
    let type= [];


    // for (var i = 0; i < detail.length; i++) {
     
      
      
    // }
    type.push(this.state.daily,this.state.weekly,this.state.monthly)
    startDateDaily = (this.state.startDateDaily != null ?  moment(this.state.startDateDaily).format("YYYY-MM-DD") : '')
    endDateDaily = (this.state.endDateDaily != null ?  moment(this.state.endDateDaily).format("YYYY-MM-DD") : '')
    startDateWeekly = (this.state.startDateWeekly != null ?  moment(this.state.startDateWeekly).format("YYYY-MM-DD") : '')
    endDateWeekly = (this.state.endDateWeekly != null ?  moment(this.state.endDateWeekly).format("YYYY-MM-DD") : '')
    dateMonthly = (this.state.monthValue != null ? this.state.monthValue.value : '')
    qtyDaily = (this.state.qtyDaily != null ? this.state.qtyDaily : '');
    qtyMonthly = (this.state.qtyMonthly != null ? this.state.qtyMonthly : '');
    qtyWeekly = (this.state.qtyWeekly != null ? this.state.qtyWeekly : '');
    nominalDaily = (this.state.nominalDaily != null ? this.state.nominalDaily : '');
    nominalMonthly = (this.state.nominalMonthly != null ? this.state.nominalMonthly : '');
    nominalWeekly = (this.state.nominalWeekly != null ? this.state.nominalWeekly : '');

    this.state.form.SalesTarget = SalesTarget;
    this.state.form.SalesTargetDetail = {
      type: type,
      qtyDaily: qtyDaily,
      qtyMonthly: qtyMonthly,
      qtyWeekly: qtyWeekly,
      nominalDaily:nominalDaily,
      nominalMonthly:nominalMonthly,
      nominalWeekly:nominalWeekly,
      startDateDaily:startDateDaily,
      endDateDaily:endDateDaily,
      startDateWeekly:startDateWeekly,
      endDateWeekly:endDateWeekly,
      dateMonthly:dateMonthly,

    };

    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.qtyAvailable,
      convertionDetail: detail.convertionDetail,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.daily}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
            <DatePickerCustom
              startDate={this.state.startDateDaily}
              endDate={this.state.endDateDaily}
              selectsRange={true}
              isClearable={false}
              name={"dateDaily"}
              title={i18n.t("Effective Date")}
              required={true}
              onChange={(e) => {
                const [start, end] = e;
                this.setState({ startDateDaily: start, endDateDaily: end });
              }}
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              peekNextMonth
              showMonthDropdown
              autoComplete={"off"}
              dateFormat="dd MMMM yyyy"
              className="form-control form-control-md"
              labelfield={"false"}
            />
          </td>
          <td>
          <InputNumber
              id={"qtyDaily" + i}
              name="qtyDaily"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyDaily}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalDaily" + i}
              name="nominalDaily"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalDaily}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.weekly}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
          <DatePickerCustom
              startDate={this.state.startDateWeekly}
              endDate={this.state.endDateWeekly}
              selectsRange={true}
              isClearable={false}
              name={"dateWeekly"}
              title={i18n.t("Effective Date")}
              required={true}
              onChange={(e) => {
                const [start, end] = e;
                this.setState({ startDateWeekly: start, endDateWeekly: end });
              }}
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              peekNextMonth
              showMonthDropdown
              autoComplete={"off"}
              dateFormat="dd MMMM yyyy"
              className="form-control form-control-md"
              labelfield={"false"}
            />
            {/* <Select2
              isClearable={true}
              name={"period"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idPeriod", "periodCurrent");
              }}
              options={this.state.periodList}
              title={i18n.t("Period")}
              value={this.state.periodCurrent}
              labelfield={"false"}
              placeholder={this.state.periodText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('startDate')}
            </div> */}
          </td>
          <td>
          <InputNumber
              id={"qtyWeekly" + i}
              name="qtyWeekly"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyWeekly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalWeekly" + i}
              name="nominalWeekly"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalWeekly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.monthly}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
            <Select2
              isClearable={true}
              name={"month"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "month", "monthValue");
              }}
              options={this.state.monthList}
              title={i18n.t("Month")}
              value={this.state.monthValue}
              placeholder={this.state.monthText}
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('month')}
            </div>
          </td>
          <td>
          <InputNumber
              id={"qtyMonthly" + i}
              name="qtyMonthly"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyMonthly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalMonthly" + i}
              name="nominalMonthly"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalMonthly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
      </React.Fragment>
    );
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-so";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case "getInvoiceLimit":
        var url = process.env.REST_URL + "helper/get-invoice-limit";
        var id =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        params = {
          id:id,
        };
        break;  
      case "getSellingPrice":
        var url = process.env.REST_URL + "helper/get-price-selling";
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.value
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var unit =
          this.state[array][arraykey].idUnit != null
            ? this.state[array][arraykey].idUnit.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          branch: branch,
          store: store,
          date: date,
          item: item,
          unit: unit,
        };
        break;
        case "getEmployeeSales":
          var url = process.env.REST_URL + "helper/get-employee-sales";
          var branch =
            this.state.branchCurrent != null
              ? this.state.branchCurrent.value
              : null;
          var store =
            this.state.storeCurrent != null
              ? this.state.storeCurrent.value
              : null;
          params = {
            branch: branch,
            store: store,
          };
          break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  render() {
    const { data, isNewRecord, helperSalesTarget } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    if(helperSalesTarget!= null){
      this.state.branchList =
      helperSalesTarget != null ? helperSalesTarget.branch : [];
      this.state.periodList = 
      helperSalesTarget != null ? helperSalesTarget.workCalendar : [];
    }

    if (data != null && !isNewRecord) {
      if(this.state.isUpdate == 0){
        this.state.branchCurrent = {value:data.idBranch,label:data.name_branch};
        this.state.date=new Date(data.date);

        let row = data.sales_target

        this.state.startDateDaily = row[0] != null ? new Date(row[0].start_date): new Date();
        this.state.endDateDaily = row[0] != null ? new Date(row[0].end_date): new Date();
        this.state.qtyDaily = row[0] != null ? parseFloat(row[0].qty)||0:0;
        this.state.nominalDaily = row[0] != null ? parseFloat(row[0].revenue)||0:0;

        this.state.startDateWeekly = row[1] != null ? new Date(row[1].start_date): new Date();
        this.state.endDateWeekly = row[1] != null ? new Date(row[1].end_date): new Date();
        this.state.qtyWeekly = row[1] != null ? parseFloat(row[1].qty)||0:0;
        this.state.nominalWeekly = row[1] != null ? parseFloat(row[1].revenue)||0:0;

        this.state.monthValue = row[2] != null ? {value:row[2].month,label:i18n.t(row[2].month_name)}:'';
        this.state.qtyMonthly = row[2] != null ? parseFloat(row[2].qty)||0:0;
        this.state.nominalMonthly = row[2] != null ? parseFloat(row[2].revenue)||0:0;

      }
      this.state.isUpdate = 1;
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-md"
              />
            </div>
            <div className="col-md-4 mt-4">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                  }

                  ;
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("SalesTarget.idBranch")}
              />
            </div>
            {/* <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
                error={this.renderError("SalesTarget.idStore")}
              />
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idSales"}
                required={true}
                size={4}
                onChange={async (e) => {

                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }}
                options={this.state.salesList}
                title={i18n.t("Sales")}
                value={this.state.salesCurrent}
                placeholder={this.state.salesText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("SalesTarget.idSales")}
               </div>
            </div> */}
            {/* <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idCustomer"}
                size={4}
                onChange={async (e) => {
                  if(e==null){
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.setState({limit:0,allTrans:0,addItem:0});
                  }
                  else{
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.changeData('limit', "getInvoiceLimit", "batas");
                    await this.changeData('paymentType', "getInvoiceLimit", "type");
                    await this.changeData('allTrans', "getInvoiceLimit", "allTransaction",null,null,()=>this.checkInvoiceLimit());
                  }
                 
                }}
                options={this.state.customerList}
                title={i18n.t("Customer")}
                value={this.state.customerCurrent}
                placeholder={this.state.customerText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("SalesTarget.idCustomer")}
               </div>
            </div> */}
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center">{i18n.t("Type")}</th>
                    <th className="text-center">{i18n.t("Effective Date")}</th>
                    <th className="text-center">{i18n.t("Qty")}</th>
                    <th className="text-center">{i18n.t("Nominal")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              {/* {this.state.addItem == 0 ? 
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      itemValue: null,
                      description: "",
                      qty: 0,
                      batchNo: "",
                      expiredDate: null,
                      unit: "",
                      poValue: null,
                      warehouseValue: null,
                      qtyAvailable: 0,
                      itemList: [],
                      bandNo: "",
                      suffixqty: "",
                      convertionDetail: [],
                      unitList: [],
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button> : ''} */}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Form;

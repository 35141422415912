import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Task from '../../../../components/modules/sales/task';
import TaskMiddleware from '../../../../middleware/modules/sales/task';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,task }) => ({
  authUser:auth.authUser,
  isProcessing: task.isProcessing,
  isError: task.isError,
  errorMessage: task.errorMessage,
  showModal:task.showModal,
  data:task.data
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>TaskMiddleware.create(),
    reset: () =>TaskMiddleware.resetList(),
    search: data =>TaskMiddleware.list(data),
    update: data =>TaskMiddleware.update(data),
    delete: (id,search) =>TaskMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class TaskContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Task List")}
          </title>
        </Helmet>
      <Task
        {...this.props}
        createData={this.createItem}
        updateItem={this.updateItem}
        deleteItem={this.deleteItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);

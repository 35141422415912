import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan, faLessThan,  } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import SelectMultiple from "../../../utilities/Form/SelectMultiple";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
export class Route extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableItem: [],
      useItem: [],
      tempavailableItem:[],
      tempuseItem:[],
      isUpdate:0,
      AssigmentText:i18n.t("Select assigment"),
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: '/user/admin', title: i18n.t("Admin"), class: " text-gray-600" },
        { link: null, title: i18n.t("Routes"), class: " text-gray-600" },
      ],
    };
    this.handlerSelectMultiple= this.handlerSelectMultiple.bind(this);
  }
  componentDidMount(){
    this.props.reset()
    this.props.getData()
  }
  componentWillUnmount(){
    this.props.reset()
  }
  validateChoose(){
    const {data}=this.props
    let list=[];
    if(Object.keys(data).length >0 && data.used !=undefined){
        this.state.useItem=data.used
        let value=Array.from(this.state.useItem, (option) => option.value);
        list=data.available.filter(obj=>!value.includes(obj.value.toString())); 
    }
    return list
  }
  handlerSelectMultiple(e,currentState){
    let value = Array.from(e.target.selectedOptions, (option) => Object.assign({value:option.value,label:option.label}));
    this.setState({[currentState]: value});
  }
  selectChoose(){
    const {assign} = this.props
    let data=[];
    for(let i=0;i< this.state.tempavailableItem.length;i++){
      data.push(this.state.tempavailableItem[i].value);
    }
    assign({assign:data})
    MySwal.fire({
      title: i18n.t("Saving Data"),
      html: i18n.t("Please Wait ...."),
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    MySwal.showLoading();
    
    var listBox = document.getElementById("roleList");
    listBox.selectedIndex = -1;
  }
  removeChoose(){
    const {revoke} = this.props
    let data=[];
    for(let i=0;i< this.state.tempuseItem.length;i++){
      data.push(this.state.tempuseItem[i].value);
    }
    revoke({revoke:data})
    MySwal.fire({
      title: i18n.t("Saving Data"),
      html: i18n.t("Please Wait ...."),
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    MySwal.showLoading();
    var listBox = document.getElementById("roleUse");
    listBox.selectedIndex = -1;
  }
  render() {
    const { isProcessing, data } = this.props;
    if(data !=null && this.state.isUpdate==0){
      this.state.availableItem=data.available
      this.state.useItem=data.used
      this.state.isUpdate=1
    }
    
    return (
      <div id="route-list" className="d-flex flex-column flex-column-fluid">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="d-flex flex-column flex-xl-row">
              <div className="w-100 flex-lg-row-auto w-lg-300px mb-7 me-7 me-lg-10">
                <div className="card card-flush py-4"><div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin"}>
                          <span className="menu-title fw-bold">Assignment</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link className="menu-link active" to={"/user/admin/route"}>
                          <span className="menu-title fw-bold">Route</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link"
                          to={"/user/admin/permission"}
                        >
                          <span className="menu-title fw-bold">Permission</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link"
                          to={"/user/admin/role"}
                        >
                          <span className="menu-title fw-bold">Roles</span>
                        </Link>
                      </div>
                    </div>
                  </div></div>
              </div>
              {data != null ? (
                <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                  <div className="card card-flush py-4">
                    <div className="card-header d-flex justify-content-end border-0 pt-6">
                      <h3>
                        Route List
                      </h3>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col-md-5">
                          <SelectMultiple
                            name={"routeList"}
                            style={{ height: "450px" }}
                            options={this.validateChoose()}
                            title={i18n.t("Route")}
                            placeholder={this.state.AssigmentText}
                            onChange={(e) =>
                              this.handlerSelectMultiple(e, "tempavailableItem")
                            }
                            onClick={(e) => {
                              e.ctrlKey = true;
                            }}
                          />
                        </div>
                        <div className="col-md-2 d-flex flex-column justify-content-center align-items-center">
                          <div className="d-block mb-1">
                            <Button
                              variant="success"
                              onClick={() => {
                                this.selectChoose();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faGreaterThan}
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                icon={faGreaterThan}
                              ></FontAwesomeIcon>
                            </Button>
                          </div>
                          <div className="d-block mb-1">
                            <Button
                              variant="danger"
                              onClick={() => {
                                this.removeChoose();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faLessThan}
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                icon={faLessThan}
                              ></FontAwesomeIcon>
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <SelectMultiple
                            style={{ height: "450px" }}
                            name={"routeUse"}
                            options={this.state.useItem}
                            title={i18n.t("Route Choosed")}
                            placeholder={this.state.AssigmentText}
                            onChange={(e) =>
                              this.handlerSelectMultiple(e, "tempuseItem")
                            }
                            onClick={(e) => {
                              e.ctrlKey = true;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                  <div className="card card-flush py-4">
                    <div className="card-body pt-0">Searching Route</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Route;

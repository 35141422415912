import AssetTypeActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  dataIn:{},
  dataOut:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperAssetType:null,
  helperFullAssetType:null,
  errorInternal:false
};

function AssetTypeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AssetTypeActions.RESET_LIST:
      return INITIAL_STATE;
    case AssetTypeActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case AssetTypeActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case AssetTypeActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case AssetTypeActions.VIEW_DATA_IN:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, dataIn:action.payload,isNewRecord:false,showModalIn:true};
    case AssetTypeActions.VIEW_DATA_OUT:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, dataOut:action.payload,isNewRecord:false,showModalOut:true};
    case AssetTypeActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case AssetTypeActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case AssetTypeActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case AssetTypeActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case AssetTypeActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case AssetTypeActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case AssetTypeActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case AssetTypeActions.GET_HELPER_ASSET_TYPE:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperAssetType:action.payload};
    case AssetTypeActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case AssetTypeActions.VOID_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form};
       
    default:
      return state;
    }
}

export default AssetTypeReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { DeliveryOrder } from "../../../../components/modules/sales/delivery-order";
import DeliveryOrderMiddleware from "../../../../middleware/modules/sales/delivery-order";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ auth, deliveryOrder }) => ({
  isProcessing: deliveryOrder.isProcessing,
  isError: deliveryOrder.isError,
  errorMessage: deliveryOrder.errorMessage,
  datalist: deliveryOrder.data,
  showModal: deliveryOrder.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => DeliveryOrderMiddleware.create(),
      reset: () => DeliveryOrderMiddleware.resetList(),
      search: (data) => DeliveryOrderMiddleware.list(data),
      update: (data) => DeliveryOrderMiddleware.update(data),
      delete: (id, search) => DeliveryOrderMiddleware.beforeDelete(id, search),
      getHelperFull: (id, search) =>
        DeliveryOrderMiddleware.getHelperFull(id, search),
    },
    dispatch
  );
};
class DeliveryOrderContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Delivery Order List")}
          </title>
        </Helmet>
      <DeliveryOrder
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOrderContainer);

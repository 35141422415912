import React, { Component } from "react";
import i18n from "../../../../i18n";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        status: 1,
        label :''
      },
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  runSubmit() {
    return this.state.form;
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  render() {
    const { data, isNewRecord } = this.props;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.form.name = data.name);
          (this.state.form.status = data.status);
            (this.state.form.label = data.label);
        this.state.isUpdate = 1;
      }
    }
    return (
      <form>
        <Input
          type={"input"}
          name={"name"}
          title={i18n.t("Name")}
          required
          value={this.state.form.name}
          handler={this.handler}
          error={this.renderError("name")}
        />
        <Input
          type={"input"}
          name={"label"}
          title={i18n.t("Label")}
          required
          value={this.state.form.label}
          handler={this.handler}
          error={this.renderError("label")}
        />
        <Radio
          list={{ 1: i18n.t("Active"), 0: i18n.t("Non Active") }}
          name={"status"}
          title={i18n.t("Status")}
          inline={false}
          value={this.state.form.status}
          handler={this.handler}
          error={this.renderError("status")}
        />
      </form>
    );
  }
}

export default Form;

import { useParams  } from "react-router-dom";
import React from "react";
import WorkSheetContainer from '../../../containers/Print/default/Work-Sheet'
export const PrintWorkSheet = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <WorkSheetContainer data={data}/>
    )
}

export default PrintWorkSheet;
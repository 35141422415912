import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from "../../../utilities/Form/Select2";
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import TextArea from '../../../utilities/Form/TextArea';
import Day from '../../../utilities/Const/Day';
export class ViewForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branch:"",
            branchName:"",
            store:"",
            storeName:"",
            employee:"",
            employeeName:"",
            isUpdate: 0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
        this.setState({ [currentState]: e })
    }

    runSubmit() {
      let customer=[];
      let week=[];
      let day=[];
      let customerList=this.state.customerCurrent
      let weekList=this.state.weekCurrent
      let dayList=this.state.dayCurrent
      // for(var i=0;i< customerList.length ;i++){
        // if(customerList[i].value !=undefined){
        //   customer.push(customerList[i].value)
        // }
      // }
      if(this.state.customerCurrent !=null){
        customer.push(this.state.customerCurrent.value)
      }
      for(var i=0;i< weekList.length ;i++){
        if(weekList[i].value !=undefined){
          week.push(weekList[i].value)
        }
      }

      for(var i=0;i< dayList.length ;i++){
        if(dayList[i].value !=undefined){
          day.push(dayList[i].value)
        }
      }

      let data={
          branch:this.state.branch,
          store:this.state.store,
          employee:this.state.employee,
          year:this.state.year,
          customer:customer,
          week:week,
          day:day,
      }
      return data;
    }
    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    render() {
        const { data, isNewRecord, helper } = this.props
        this.state.customerList = helper != null ? helper.customer : [];
        this.state.weekList = helper != null ? helper.week : [];
        this.state.branch=helper != null ? helper.branch.value : null;
        this.state.branchName=helper != null ? helper.branch.label : null;
        this.state.store=helper != null ? ((helper.store !==null)?helper.store.value:null) : null;
        this.state.storeName=helper != null ? ((helper.store !==null)?helper.store.label:null) : null;
        this.state.employee=helper != null ? helper.employee.value : null;
        this.state.employeeName=helper != null ? helper.employee.label : null;
        this.state.year=helper != null ? helper.year : null;
        return (
            <form>
              <div className="col-sm-12">
                <table>
                  <tbody>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Branch")}</td>
                      <td>:</td>
                      <td>{this.state.branchName}</td>
                    </tr>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Store")}</td>
                      <td>:</td>
                      <td>{this.state.storeName}</td>
                    </tr>
                    <tr className="form-label">
                      <td width={"30%"}>{i18n.t("Employee Name")}</td>
                      <td>:</td>
                      <td>{this.state.employeeName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </form>
        );
    }
}

export default ViewForm;
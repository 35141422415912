import React from "react";
import i18n from "../../i18n";
const Loading=()=>{
    return (
    <div className="loadingDiv">
          <div className="content-center">
            <div className="text-center">
              <div className="spinner-border  text-primary clearfix" style={{width:'3rem',height:'3rem'}} role="status"></div>
              <div className="sr-only">{i18n.t("Loading")}</div>
            </div>
          </div>
      </div>
      )
}

export default Loading;
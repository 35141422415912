import { isError } from 'lodash';
import SalesPerformanceChartActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  dataTable:{},
  dataTarget:{},
  dataMonitor:{},
  dataDistribution:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSalesChart:null,
  errorInternal:false
};

function SalesPerformanceChartReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SalesPerformanceChartActions.RESET_LIST:
      return INITIAL_STATE;
    case SalesPerformanceChartActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SalesPerformanceChartActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case SalesPerformanceChartActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SalesPerformanceChartActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesPerformanceChartActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesPerformanceChartActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case SalesPerformanceChartActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SalesPerformanceChartActions.SHOW_DATA_TABLE:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataTable: action.payload, errorMessage: [],isProcessing:false };
    case SalesPerformanceChartActions.SHOW_DATA_TARGET:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataTarget: action.payload, errorMessage: [],isProcessing:false,isError:false };
    case SalesPerformanceChartActions.SHOW_DATA_DISTRIBUTION:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataDistribution: action.payload, errorMessage: [],isProcessing:false };
    case SalesPerformanceChartActions.SHOW_DATA_MONITOR:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataMonitor: action.payload, errorMessage: [],isProcessing:false };
    case SalesPerformanceChartActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SalesPerformanceChartActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SalesPerformanceChartActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case SalesPerformanceChartActions.GET_HELPER_SALESPERFORMANCE_CHART:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperSalesChart:action.payload};
    case SalesPerformanceChartActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case SalesPerformanceChartActions.VOID_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form};
       
    default:
      return state;
    }
}

export default SalesPerformanceChartReducer;

import ReturnSellingActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperReturnSelling:null,
  ReturnSellingHelperFull:null,
  printReturnSelling:null
};

function ReturnSellingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ReturnSellingActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case ReturnSellingActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ReturnSellingActions.BACK_WAREHOUSE:
     
      return { 
        ...state,
        errorInternal:false,
        onSubmit:false, 
        isProcessing: false,
        single:null, 
        errorMessage:[],
        form:INITIAL_STATE.form,
        isNewRecord:true,
        showModal:true
      };
    case ReturnSellingActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case ReturnSellingActions.VIEW_DATA:
      
      return { ...state, 
        errorInternal:false,
        onSubmit:false,
        isProcessing: false, 
        single:action.payload,
        errorMessage:[],
        isNewRecord:false,
        showModal:true
      };
    case ReturnSellingActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReturnSellingActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReturnSellingActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case ReturnSellingActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case ReturnSellingActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case ReturnSellingActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case ReturnSellingActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case ReturnSellingActions.GET_HELPER_RETURNSELLING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperReturnSelling:action.payload};
    case ReturnSellingActions.GET_HELPER_RETURNSELLING_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,ReturnSellingHelperFull:action.payload};
    case ReturnSellingActions.PRINT_RETURNSELLING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printReturnSelling:action.payload};
    case ReturnSellingActions.APPROVE_RETURNSELLING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case ReturnSellingActions.REJECT_RETURNSELLING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    default:
      return state;
    }
}


export default ReturnSellingReducer;


import React from "react";
import i18n from "../../../i18n";
import { Link } from "react-router-dom";

const LinkPrint=(props)=>{
    const {module,id_transaction,code}=props
    switch(module){
        case 'Purchase Order':
            return <Link to={"/pembelian/purchase-order/print/"+id_transaction} target="_blank">{code}</Link>
    }
}
export default LinkPrint;
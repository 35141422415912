import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import PhotoAlbum from "react-photo-album";
import ButtonDownload from "./ButtonDownload";
import i18n from "../../i18n";
class PhotoGalleryOne extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
  };

  static defaultProps = {
    images: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      index: -1,
    };
  }
  renderImages() {
    const { images } = this.props;
    console.log('photo 1',images)

    const wew = images.map((image, i) => {
      let url = process.env.APP_URL + "assets/media/icons/no_image.jpg";
      let thumb = process.env.APP_URL + "assets/media/icons/no_image.jpg";

          url = process.env.UPLOAD_URL + image;
          thumb = process.env.UPLOAD_URL + image;
        
      return { src: url, title: 'Evidence', pathReal: image};
    });
    return wew;
  }
  renderThumb() {
    const { images } = this.props;
    console.log('photo 2',images)
    const wew = images.map((image, i) => {
      let url = process.env.APP_URL + "assets/media/icons/no_image.jpg";
      let thumb = process.env.APP_URL + "assets/media/icons/no_image.jpg";

      
          url = process.env.UPLOAD_URL + image;
          thumb = process.env.UPLOAD_URL + image;
     
      return {
        src: url,
        width: 800,
        height: 600,
        title: "Evidence",
        pathReal: image,
        status:1
      };
    });
    return wew;
  }

  setIndex(value) {
    this.setState({ index: value });
  }

  renderPhoto({
    layout,
    layoutOptions,
    imageProps: { alt, style, ...restImageProps },
    ...props
  }) {
    let background='none'
    let label=null
    switch(props.photo.status){
      case 2:
        background= 'lavenderblush'
        label=i18n.t('Remove')
      break;
      case 0:
        background= 'aquamarine'
        label=i18n.t('New')
      break;
    }
    return (
      <div
        style={{
          border: "2px solid #eee",
          borderRadius: "4px",
          boxSizing: "content-box",
          alignItems: "center",
          width: "150px",
          padding: `${layoutOptions.padding - 2}px`,
          paddingBottom: 0,
          backgroundColor:(props.photo.status!=1)?background:'none'
        }}
      >
        <img
          alt={alt}
          style={{ ...style, width: "100%", padding: 0 }}
          {...restImageProps}
        />

        <div
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
            overflow: "visible",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
        >
          {/* <ButtonDownload
            title={restImageProps.title}
            url={props.photo.pathReal}
          ></ButtonDownload> */}
        </div>
        <div
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
            overflow: "visible",
            textAlign: "center",
          }}
        >
          <p>{restImageProps.title} </p>
          {(props.photo.status!=1)?<p><label className="text-danger">{label}</label></p>:null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <PhotoAlbum
          photos={this.renderThumb()}
          layout="columns"
          spacing={2}
          targetRowHeight={50}
          onClick={({ index }) => this.setIndex(index)}
          renderPhoto={this.renderPhoto}
        ></PhotoAlbum>
        <Lightbox
          slides={this.renderImages()}
          open={this.state.index >= 0}
          index={this.state.index}
          close={() => this.setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </Fragment>
    );
  }
}

export default PhotoGalleryOne;
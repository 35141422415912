import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = cookieManager.getUserToken();

export class DetailOffering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      change: [],
      check: 0,
    };
  }

  filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
        })
    }
    return data
  }
  render() {
    const { choose } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)

    if (choose != null && this.state.check == 0) {
      this.state.detail = choose.log_value.detail;
      this.state.change = choose.change_value.detail;
      this.state.headerV = choose.log_value.header;
      this.state.headerC = choose.change_value.header;
      this.state.check = 1;
    }
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-12">
          <Table rules="cols">
            <thead></thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>DATA ASLI</td>
                <td>DATA YANG DIUBAH</td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" , padding: "5",}}>
                <td>{i18n.t("Branch Name")}</td>
                <td>:</td>
                <td>
                  {this.state.headerV.branch
                    ? this.state.headerV.branch
                    : "-"}
                </td>
                <td>
                  {this.state.headerV.branch != this.state.headerC.branch
                    ? this.state.headerC.branch
                    : "-"}
                </td>
              </tr>
              <tr style={{fontSize: "15px",fontWeight: "bold",}}>
                <td>{i18n.t("Supplier Name")}</td>
                <td>:</td>
                <td>
                  {this.state.headerV.supplier ? this.state.headerV.supplier : "-"}
                </td>
                <td>
                  {this.state.headerV.supplier != this.state.headerC.supplier
                    ?this.state.headerC.supplier
                    : "-"}
                </td>
              </tr>
              <tr style={{fontSize: "15px",fontWeight: "bold",}}>
                <td>{i18n.t("Effective Date")}</td>
                <td>:</td>
                <td>
                  {this.state.headerV.date ? this.state.headerV.date : "-"}
                </td>
                <td>
                  {this.state.headerV.date != this.state.headerC.date
                    ?this.state.headerC.date
                    : "-"}
                </td>
              </tr>
              <tr style={{fontSize: "15px",fontWeight: "bold",}}>
                <td>{i18n.t("Note")}</td>
                <td>:</td>
                <td>
                  {this.state.headerV.description ? this.state.headerV.description : "-"}
                </td>
                <td>
                  {this.state.headerV.description != this.state.headerC.description
                    ?this.state.headerC.description
                    : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row">
      <h3 className="text-center">{i18n.t('Detail')}</h3>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{i18n.t('Item Name')}</th>
            <th>{i18n.t('Unit Name')}</th>
            <th>{i18n.t('Unit Price')}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.detail.map((log,i)=>{
              return (
                <tr key={'a'+i}>
                  <td>{i+1}</td>
                  <td>{log.item}</td>
                  <td>{log.unit}</td>
                  <td>{log.price}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      {(this.state.headerC.type == 'edit')?
      <React.Fragment>
      <p className="text-center"><FontAwesomeIcon icon={faArrowDown} /></p>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{i18n.t('Item Name')}</th>
            <th>{i18n.t('Unit Name')}</th>
            <th>{i18n.t('Unit Price')}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.change.map((log,i)=>{
              return (
                <tr key={'a'+i}>
                  <td>{i+1}</td>
                  <td>{log.item}</td>
                  <td>{log.unit}</td>
                  <td>{log.price}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      </React.Fragment>
      :''}
      </div>
    </React.Fragment>
    )
  }
}

export default DetailOffering;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}
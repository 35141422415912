export default class UnitActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_UNIT='GET_HELPER_UNIT';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER'
  static RESET_LIST='RESET_LIST'

  static submit(){
    return {
      type: UnitActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: UnitActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: UnitActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: UnitActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: UnitActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: UnitActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: UnitActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: UnitActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: UnitActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: UnitActions.RESET_DATA,
    };
  }
  static resetList(){
    return {
      type: UnitActions.RESET_LIST,
    };
  }
  static helper(data){
    return {
      type: UnitActions.GET_HELPER_UNIT,
      payload:data
    };
  }
  static errorInternal(data){
    return {
      type: UnitActions.ERROR_SERVER,
      payload:data
    };
  }
}
export default class SalaryCutsDetailActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_SALARYCUTSDETAIL='GET_HELPER_SALARYCUTSDETAIL';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static submit(){
    return {
      type: SalaryCutsDetailActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: SalaryCutsDetailActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: SalaryCutsDetailActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: SalaryCutsDetailActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: SalaryCutsDetailActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: SalaryCutsDetailActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: SalaryCutsDetailActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: SalaryCutsDetailActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: SalaryCutsDetailActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: SalaryCutsDetailActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: SalaryCutsDetailActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: SalaryCutsDetailActions.GET_HELPER_SALARYCUTSDETAIL,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: SalaryCutsDetailActions.ERROR_SERVER,
      payload: error
    };
  }
}
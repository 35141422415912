export default class PurchasingReportActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static SHOW_DATA_SUPP='SHOW_DATA_SUPP';
  static SHOW_DATA_SUPP_DETAIL='SHOW_DATA_SUPP_DETAIL';
  static SHOW_DATA_INVOICE_DETAIL='SHOW_DATA_INVOICE_DETAIL';
  static SHOW_DATA_ITEM='SHOW_DATA_ITEM';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_PURCHASINGREPORT='GET_HELPER_PURCHASINGREPORT';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static SHOW_DATA_FROM_PO='SHOW_DATA_FROM_PO';
  static SHOW_DATA_FROM_RECEIVING='SHOW_DATA_FROM_RECEIVING';
  static submit(){
    return {
      type: PurchasingReportActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: PurchasingReportActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: PurchasingReportActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: PurchasingReportActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: PurchasingReportActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: PurchasingReportActions.SHOW_DATA,
      payload: data
    };
  }

  static listSupplier(dataSupp) {
    return {
      type: PurchasingReportActions.SHOW_DATA_SUPP,
      payload: dataSupp
    };
  }

  static listSupplierDetail(dataSuppDetail) {
    return {
      type: PurchasingReportActions.SHOW_DATA_SUPP_DETAIL,
      payload: dataSuppDetail
    };
  }
  
  static listInvoiceDetail(dataInvoiceDetail) {
    return {
      type: PurchasingReportActions.SHOW_DATA_INVOICE_DETAIL,
      payload: dataInvoiceDetail
    };
  }

  static listDataItem(dataItem) {
    return {
      type: PurchasingReportActions.SHOW_DATA_ITEM,
      payload: dataItem
    };
  }

  static listPO(dataPO) {
    return {
      type: PurchasingReportActions.SHOW_DATA_FROM_PO,
      payload: dataPO
    };
  }

  static listReceiving(dataReceiving) {
    return {
      type: PurchasingReportActions.SHOW_DATA_FROM_RECEIVING,
      payload: dataReceiving
    };
  }

  static resetList() {
    return {
      type: PurchasingReportActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: PurchasingReportActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: PurchasingReportActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: PurchasingReportActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: PurchasingReportActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: PurchasingReportActions.GET_HELPER_PURCHASINGREPORT,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: PurchasingReportActions.ERROR_SERVER,
      payload: error
    };
  }
}
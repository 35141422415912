import { useParams  } from "react-router-dom";
import React from "react";
import GeneralLedgerContainer from "../../../containers/Print/default/General-Ledger";
export const PrintGeneralLedger = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <GeneralLedgerContainer data={data}/>
    )
}

export default PrintGeneralLedger;
export default class ItemBrandActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_ITEMBRAND='GET_HELPER_ITEMBRAND';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER'
    static RESET_LIST='RESET_LIST'
    
    static submit(){
      return {
        type: ItemBrandActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: ItemBrandActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: ItemBrandActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: ItemBrandActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: ItemBrandActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: ItemBrandActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: ItemBrandActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: ItemBrandActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: ItemBrandActions.PROCESS_DATA,
      };
    }
    static resetList(){
      return {
        type: ItemBrandActions.RESET_LIST,
      };
    }
    static reset(){
      return {
        type: ItemBrandActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: ItemBrandActions.GET_HELPER_ITEMBRAND,
        payload:data
      };
    }
    static errorInternal(data){
      return {
        type: ItemBrandActions.ERROR_SERVER,
        payload:data
      };
    }
  }
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import BillingMiddleware from "../../../../middleware/modules/sales/billing";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import FormPayment from "../../../../components/modules/sales/billing/form-payment";
// middlewares
const mapStateToProps = ({ auth, billing }) => ({
  isProcessing: billing.isProcessing,
  isError: billing.isError,
  errorMessage: billing.errorMessage,
  datalist: billing.data,
  dataPayment:billing.dataPayment,
  dataBilling: billing.dataBilling,
  helperBilling: billing.helperBilling,
  showModal: billing.showModal,
  showModalBilling: billing.showModalBilling,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: () => BillingMiddleware.resetList(),
      searchPayment: (data) => BillingMiddleware.listPayment(data),
      voidItem: (data,search) => BillingMiddleware.beforeVoid(data, search),
    },
    dispatch
  );
};
class BillingPaymentContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    dataPayment: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.voidItem = this.voidItem.bind(this);
  }

  voidItem(data,searchPayment) {
    this.props.voidItem(data,searchPayment);
  }

  componentWillMount() {
    const searchPayment = {
      limit: 10,
    };
    this.props.searchPayment(searchPayment);

  }
 
  render() {
    const { isProcessing,dataPayment } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Billing List")}
          </title>
        </Helmet>
      <FormPayment
        {...this.props}
        dataPayment={dataPayment}
        isProcessing={isProcessing}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPaymentContainer);

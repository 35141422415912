import SalesOrderActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSalesOrder:null,
  salesOrderHelperFull:null,
  printSalesOrder:null
};

function SalesOrderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
   case SalesOrderActions.RESET_LIST:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,data:{}};
    case SalesOrderActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SalesOrderActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false};
    case SalesOrderActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SalesOrderActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesOrderActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesOrderActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case SalesOrderActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SalesOrderActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SalesOrderActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case SalesOrderActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SalesOrderActions.GET_HELPER_SALESORDER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperSalesOrder:action.payload};
    case SalesOrderActions.GET_HELPER_SALESORDER_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,salesOrderHelperFull:action.payload};
    case SalesOrderActions.PRINT_SALESORDER:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printSalesOrder:null};
    case SalesOrderActions.PRINT_SalesOrder:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printSalesOrder:action.payload};
    
    default:
      return state;
    }
}


export default SalesOrderReducer;

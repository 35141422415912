
import React,{ Component } from 'react';
import { Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import SwitchButton from '../../../utilities/Form/SwitchButton';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import axios from 'axios';
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import PhotoGallery from '../../../utilities/PhotoGallery';

export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            date:new Date(),
            arrayDetail:[],
            onOpen:0,
           idCosCash:null,
           listCoa:[],
           listCoaCurrent:null,
           listText:i18n.t("Cash / Bank"),
           listExpense:[{label:"aa",value:10}],
           listExpenseText:i18n.t("Expense"),
        }
        this.handler = this.handler.bind(this)
        // this.handlerPayment = this.handlerPayment.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
    runSubmit(){
        const {single}=this.props
        const detail=this.state.arrayDetail;
        let idCash=[]
        let nominal=[]
        let idCoaExpense=[]
        let description=[]
        
        for(var i=0;i<detail.length;i++){
            if(detail[i]?.expenseCurrent && detail[i]?.expenseCurrent?.value){
                idCash.push(detail[i].id);
                nominal.push(detail[i].nominal);
                idCoaExpense.push(detail[i].expenseCurrent.value);
                description.push(detail[i].description);
            }
        }
        if(this.state.listCoaCurrent ==null){
            alert('Cash/Bank must be filled !!')
        }else{
            const CashSales={
                idSales:  single.sales_id,
                idCashBank:this.state.listCoaCurrent?.value||null,
                date:moment(this.state.date).format('YYYY-MM-DD'),
                dateTx:moment(single.date).format('YYYY-MM-DD'),
                details:{
                    idCash:idCash,
                    nominal:nominal,
                    idCoaExpense:idCoaExpense,
                    description:description,
                } 
            }
            console.log('post',CashSales)
            return CashSales
        }
       
    }
    
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }

   
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    

    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    renderDetail(detail,i){
        return <React.Fragment key={"detail"+i}>
                            <tr className={(detail.choose==true)?'custom-opacity-bg':''} >
                                <td align='center'>
                                    {i+1}
                                </td>
                                <td>
                                    {detail?.transaction_no}
                                </td>
                                <td>
                                    {detail?.description}
                                </td>
                                <td>
                                    <PhotoGallery images={detail?.photo}></PhotoGallery>
                                </td>
                                <td align='right'>
                                    {detail?.nominal_format||"Rp 0"}
                                </td>
                                <td>
                                   <Select2
                                        isClearable={true}
                                        name={"Expense[]"}
                                        required={true}
                                        size={4}
                                        onChange={async (e) => {
                                            let arrayDetail=this.state.arrayDetail

                                            arrayDetail[i].expenseCurrent=e
                                            this.setState({
                                                ...this.state,
                                                arrayDetail:arrayDetail
                                            })
                                        }}
                                        options={this.state.listExpense}
                                        title={i18n.t("Expense")}
                                        value={detail.expenseCurrent}
                                        placeholder={this.state.listExpenseText}
                                        labelfield={"false"}
                                   >
                                   </Select2>
                                </td>
                            </tr>
            </React.Fragment>
    }
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }


    render(){
        const {data,helper,single}=this.props
        // this.state.arrayDetail=data||[]
        this.state.listExpense=helper?.coaExpense||[]
        this.state.listCoa=helper?.coaCashBank||[]
        if(this.state.onOpen==0 && data.length > 0){
            this.state.arrayDetail=data
            this.state.onOpen=1
        }
        console.log(single)
        return (
            <React.Fragment>
            <form>
                <div className='row'>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Invoice Selling Date")}
                            required={true}
                            selected={this.state.date}
                            onSelect={async (e)=>{
                                    await this.handlerDateTime(e,'date');
                                }
                            }
                            onChange={(e)=>{}}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMMM yyyy"
                            maxDate={new Date()}
                            minDate={(single?.date)?new Date(single.date):new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className='col-3'>
                                <Select2
                                    isClearable={true}
                                    name={"CashBank"}
                                    required={true}
                                    size={4}
                                    onChange={async (e) => {
                                        this.setState({
                                            ...this.state,
                                            listCoaCurrent:e
                                        })
                                    }}
                                    options={this.state.listCoa}
                                    title={i18n.t("Cash / Bank")}
                                    value={this.state.listCoaCurrent}
                                    placeholder={this.state.listText}
                                    labelfield={"true"}
                                >
                                </Select2>
                    </div>
                </div>
                <br />
                <hr />
                <div className='row'>
                    <div className="col-12">
                        <Table  bordered >
                            <thead>
                                <tr className='bg-secondary'>
                                    <th className='text-center'>{i18n.t("No")}</th>
                                    <th >{i18n.t("No Nota")}</th>
                                    <th >{i18n.t("Description")}</th>
                                    <th >{i18n.t("Photo")}</th>
                                    <th className='text-end' >{i18n.t("Nominal")}</th>
                                    <th >{i18n.t("Ledger")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </form>
           
           </React.Fragment>
        );
    }
}

export default Form;
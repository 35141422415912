import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">	
				<div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
					<div className="text-dark order-2 order-md-1">
						<span className="text-muted fw-semibold me-1">2024&copy;</span>
						<a href="https://mitraprimasolusindo.com" target="_blank" className="text-gray-800 text-hover-primary">Mitra Prima Solusindo</a>
					</div>
					<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
						<li className="menu-item">
							<a href="https://mitraprimasolusindo.com#about" target="_blank" className="menu-link px-2">About</a>
						</li>
						<li className="menu-item">
							<a href="https://mitraprimasolusindo.com#contact" target="_blank" className="menu-link px-2">Support</a>
						</li>
						
					</ul>
				</div>
			</div>
		);
	}
}

export default Footer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import IncomeStatement from '../../../../components/modules/report/income-statement';
import IncomeStatementMiddleware from '../../../../middleware/modules/report/income-statement';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,incomeStatement }) => ({
  isProcessing: incomeStatement.isProcessing,
  isError: incomeStatement.isError,
  errorMessage: incomeStatement.errorMessage,
  dataList:incomeStatement.data,
  showModal:incomeStatement.showModal,
  helperList:incomeStatement.helperIncomeStatement
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>IncomeStatementMiddleware.getHelper(),
    reset: () =>IncomeStatementMiddleware.resetList(),
    setData: (data) =>IncomeStatementMiddleware.list(data),
    excel: (data) =>IncomeStatementMiddleware.showExcel(data),
  }, dispatch);
};
class IncomeStatementContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Income Statement Report")}
          </title>
        </Helmet>
      <IncomeStatement
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeStatementContainer);

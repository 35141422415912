import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import UncollectedDebt from '../../../../components/modules/report/uncollected-debt';
import UncollectedDebtMiddleware from '../../../../middleware/modules/report/uncollected-debt';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,uncollectedDebt }) => ({
  isProcessing: uncollectedDebt.isProcessing,
  isError: uncollectedDebt.isError,
  errorMessage: uncollectedDebt.errorMessage,
  datalist:uncollectedDebt.data,
  showModal:uncollectedDebt.showModal,
  helperList:uncollectedDebt.helperUncollectedDebt
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>UncollectedDebtMiddleware.getHelper(),
    reset: () =>UncollectedDebtMiddleware.resetList(),
    // search: data =>UncollectedDebtMiddleware.list(data),
    setData: (data) =>UncollectedDebtMiddleware.list(data),
    excel: (data) =>UncollectedDebtMiddleware.showExcel(data),
  }, dispatch);
};
class UncollectedDebtContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    // search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
    // this.props.search(search);
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Uncollected Debt List")}
          </title>
        </Helmet>
      <UncollectedDebt
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UncollectedDebtContainer);


import React, { Component, Fragment } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import SwitchButton from '../../../utilities/Form/SwitchButton';
import axios from 'axios';
import InputNumber from '../../../utilities/Form/InputNumber';
import { connect } from "react-redux";
import i18n from '../../../../i18n';
import FormPayment from './form-payment';
import { bindActionCreators } from "redux";
import BillingMiddleware from '../../../../middleware/modules/sales/billing';
import PaymentSellingMiddleware from '../../../../middleware/modules/sales/payment-selling';
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            pay: (data, id) => BillingMiddleware.beforePayment(data, id),
            repaymentData: (data) => BillingMiddleware.getRepaymentData(data),
        },
        dispatch
    );
};
export class FormRepayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                BillingDetail: []
            },
            show: 0,
            idCust: null,
            chooseAll: 0,
            sales: null,
            set: 0,
            idCustomer: '',
            date: new Date(),
            limitDate: new Date(),
            paymentNo: '',
            subTotal: 0,
            totalPayment: 0,
            totalRemaining: 0,
            count: 0,
            branch: null,
            arrayDetail: [
            ],
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
        this.saveConvertion = this.saveConvertion.bind(this)
        this.formConvertion = React.createRef()
    }

    runSubmit() {

        return '1';
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            [key]: value
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            [key]: value
        })
        this.setState({ [currentState]: e })
    }

    calculateAll() {
        const detail = this.state.arrayDetail.filter((item) => item.choose == true)
        let subTotal = 0
        let grandTotal = 0
        let refund = 0
        let payment = this.state.totalPayment
        let remaining = 0
        let totalRemaining = 0;
        detail.forEach((item, index) => {
            subTotal += parseFloat(item.remaining) || 0
        })

        totalRemaining = (parseFloat(grandTotal) || 0) - (parseFloat(payment) || 0);

        if (totalRemaining < 0) {
            refund = (parseFloat(payment) || 0) - (parseFloat(grandTotal) || 0)
            totalRemaining = 0
        }

        this.setState({
            subTotal: subTotal,
            refund: refund,
            payment: payment,
            grandTotal: grandTotal,
            totalRemaining: totalRemaining,
        })
    }

    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    saveConvertion(status) {
        const detail = this.state.arrayDetail.filter((item) => item.choose == true)
        if (status) {
            let result = this.formConvertion.current.runSubmit();
            if (result != null) {
                let id = this.state.sales
                let res = result.hasil

                this.props.pay(res, id),
                    this.setState({ modalDetail: false }, () => this.calculateAll());
            }
        } else {
            this.setState({ modalDetail: false });
        }
    }

    renderDetail(detail, i) {
        return <React.Fragment key={"detail" + i}>
            <tr className={(detail.choose == true) ? 'custom-opacity-bg' : ''} >
                <td align='center'>
                    {i + 1 + '.'}
                </td>
                <td>
                    {detail.transaction_no}
                </td>
                <td>
                    {detail.customer_name}
                </td>
                <td>
                    {detail.date}
                </td>
                <td>
                    {detail.due_date}
                </td>
                <td>
                    {detail.payment_type}
                </td>
                <td align='right'>
                    {detail.total_format}
                </td>
                <td align='right'>
                    {detail.payment_format}
                </td>
                <td align='right'>
                    {detail.remaining_format}
                </td>
                <td align='right'>
                    <SwitchButton
                        labelfield={'false'}
                        onChange={(e) => this.choose(e, 'arrayDetail', i, 'choose')}
                        name={'choosePaymentSelling'}
                        value={detail.choose}
                        checked={detail.choose ? true : false}
                        className={'h-20px w-30px'}
                    ></SwitchButton>
                </td>
            </tr>
        </React.Fragment>
    }
    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        })
    }

    choose(e, array, index, key) {
        const arr = [...this.state[array]];
        const objectss = arr[index]
        objectss[key] = !objectss[key];
        this.setState(arr, () => this.calculateAll())
    }

    filterTagihan() {
        const { dataBilling } = this.props
        this.setState({ arrayDetail: [...dataBilling.detail] })

    }

    chooseAll(e, array, key) {
        console.log(e.target.value)
        const arr = [...this.state[array]];
        if (this.state.chooseAll == 0) {
            for (var i = 0; i < arr.length; i++) {
                const objectss = arr[i]
                objectss[key] = true;
                this.state.chooseAll = 1;
            }
        } else {
            for (var i = 0; i < arr.length; i++) {
                const objectss = arr[i]
                objectss[key] = false;
                this.state.chooseAll = 0;
            }
        }
        this.setState(arr, () => this.calculateAll())
    }

    showModal(key) {
        let choose = {
            data: this.state.arrayDetail.filter((item) => item.choose == true),
            id_branch: this.state.branch,
            id_cust: this.state.idCust
        };

        if (key == 1) {
            this.setState({ itemChoose: choose, modalDetail: true });
            key == 0;
        }
    }

    render() {
        const { dataRepayment, isNewRecord, type, showModal, onSubmit } = this.props
        console.log('PROPS', this.props)
        console.log('STATE', this.state)
        if (dataRepayment != null && !isNewRecord) {
            this.state.arrayDetail = [...dataRepayment.detail];
            let row = this.state.arrayDetail;
            if (this.state.set == 0) {
                this.state.sales = dataRepayment.id;
                this.state.branch = dataRepayment.id_branch;
                for (var p = 0; p < row.length; p++) {
                    this.state.idCust = (row[0].id_cust)
                    row[p].choose = (row[p].status == 3) ? true : false;
                    this.calculateAll()
                }
                this.state.set = 1;
            }
        }
        return (
            <React.Fragment>
                <form>
                    <div className='row' style={{ fontWeight: 'bold' }}>
                        <div className="col-3">
                            {i18n.t('Sales : ' + dataRepayment.name)}
                        </div>
                        <div className="col-3">
                            {i18n.t('Branch  : ' + dataRepayment.branch)}
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className='row'>
                        <div className="col-12">
                            <Table bordered >
                                <thead>
                                    <tr className='bg-secondary'>
                                        <th className='text-center'>{i18n.t("No")}</th>
                                        <th >{i18n.t("Invoice No")}</th>
                                        <th >{i18n.t("Customer Name")}</th>
                                        <th >{i18n.t("Invoice Date")}</th>
                                        <th >{i18n.t("Due Date")}</th>
                                        <th >{i18n.t("Payment Type")}</th>
                                        <th className='text-end'>{i18n.t("Total")}</th>
                                        <th className='text-end'>{i18n.t("Total Payment")}</th>
                                        <th className='text-end'>{i18n.t("Total Remaining")}</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.arrayDetail.length == 0 ?
                                        <React.Fragment key={'null'}>
                                            <tr >
                                                <td colSpan={'9'} style={{ textAlign: 'center', fontSize: '14', fontWeight: 'bold' }}>
                                                    {i18n.t('Tidak Ada Invoice')}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {this.state.arrayDetail.map((detail, index) => {
                                                return this.renderDetail(detail, index);
                                            })}
                                        </React.Fragment>
                                    }
                                </tbody>
                            </Table>

                        </div>
                    </div>

                    <div className='row'>
                        <div className="d-flex flex-end">
                            <div className='col-4'>
                                <Table borderless responsive="sm">
                                    <tbody>
                                        <tr className="align-middle">
                                            <td>{i18n.t("Total Bill")}</td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"subTotal"}
                                                    name="subTotal"
                                                    placeholder={i18n.t("Total Bill")}
                                                    defaultValue={0}
                                                    value={this.state.subTotal}
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => { }}
                                                    className='p-0 form-control form-control-xs form-control-flush text-end'
                                                    prefix='Rp '
                                                    readOnly
                                                    labelfield={"false"}
                                                />
                                            </td>
                                            <td>
                                                {dataRepayment.detail.length > 0 && this.state.subTotal > 0 ?
                                                <Fragment>
                                                <div className="col-sm-2">
                                                    <Button onClick={() => this.showModal(1)}
                                                    variant="success" className="btn btn-md btn btn-success"> {i18n.t("Payment")} </Button>
                                                </div>
                                                </Fragment> : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>

                </form>
                <>
                    <Modal show={this.state.modalDetail} backdropClassName="next1" centered size='xl'>
                        <Modal.Header>
                            <Modal.Title>{this.state.titleModal}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormPayment
                                ref={this.formConvertion}
                                {...this.props}
                                data={this.state.itemChoose}
                            />
                        </Modal.Body>
                        <Modal.Footer className={(!onSubmit) ? "justify-content-between" : "justify-content-center"}>
                            {onSubmit ? (
                                ""
                            ) : (
                                <Button variant="secondary" onClick={() => this.saveConvertion(false)}>
                                    {i18n.t("Close")}
                                </Button>
                            )}
                            <Button variant="primary" onClick={() => this.saveConvertion(true)}>
                                {onSubmit ? (
                                    <span className="indicator-progress show">
                                        {i18n.t("Please wait...")}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                ) : (
                                    <span className="indicator-label">{i18n.t("Save")}</span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </React.Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(FormRepayment);
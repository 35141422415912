import PurchaseRecapActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperpurchaserecap:null,
  purchaserecapHelperFull:null,
  helperoff:null
};

function PurchaseRecapReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PurchaseRecapActions.RESET_LIST:
      return INITIAL_STATE;
    case PurchaseRecapActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case PurchaseRecapActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case PurchaseRecapActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PurchaseRecapActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseRecapActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseRecapActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case PurchaseRecapActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PurchaseRecapActions.DETAIL_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case PurchaseRecapActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PurchaseRecapActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case PurchaseRecapActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PurchaseRecapActions.GET_HELPER_PURCHASERECAP:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperpurchaserecap:action.payload};
    case PurchaseRecapActions.GET_HELPER_PURCHASERECAP_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,purchaserecapHelperFull:action.payload};
      case PurchaseRecapActions.CONVERT_PR:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case PurchaseRecapActions.GET_HELPER_OFFERING:
        return { ...state, isError: false,isSuccess:false,onSubmit:false,helperoff:action.payload};  
    default:
      return state;
    }
}


export default PurchaseRecapReducer;

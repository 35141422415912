import { useParams  } from "react-router-dom";
import React from "react";
import CashFlowContainer from '../../../containers/Print/default/Cash-Flow'
export const PrintCashFLow = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <CashFlowContainer data={data}/>
    )
}

export default PrintCashFLow;
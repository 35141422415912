import InvoiceActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperInvoice: null,
  invoiceHelperFull: null,
  printInvoice:null,
  requestPrintInvoice:false,
};

function InvoiceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case InvoiceActions.RESET_LIST:
      return INITIAL_STATE;
    case InvoiceActions.NEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case InvoiceActions.UPDATE_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        isNewRecord: false,
        showModal: true,
      };
    case InvoiceActions.VIEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case InvoiceActions.RESET_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case InvoiceActions.DELETE_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case InvoiceActions.ON_SUBMIT:
      return { ...state, onSubmit: true };
    case InvoiceActions.SHOW_DATA:
      return {
        ...state,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case InvoiceActions.SUCCESS_DATA:
      return {
        ...state,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case InvoiceActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false, isSuccess: false };
    case InvoiceActions.ERROR_DATA:
      return {
        ...state,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case InvoiceActions.GET_HELPER_INVOICE:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperInvoice: action.payload,
      };
    case InvoiceActions.GET_HELPER_INVOICE_FULL:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        invoiceHelperFull: action.payload,
      };
    case InvoiceActions.BEFORE_PRINT_INVOICE:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        printInvoice: null,
        isProcessing: true,
      };
    case InvoiceActions.PRINT_INVOICE:
      return {
        ...state,
        errorInternal: false,
        requestPrintInvoice: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        printInvoice: action.payload,
      };
    case InvoiceActions.ON_LIMIT_PRINT:
      return {
        ...state,
        errorInternal: false,
        requestPrintInvoice: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        isProcessing: false,
      };
    case InvoiceActions.ON_SAVE_REQUEST_PRINT:
      return {
        ...state,
        errorInternal: false,
        requestPrintInvoice: true,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default InvoiceReducer;

import PaymentSellingActions from './actions';

const INITIAL_STATE = {
  dataPaymentSelling: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperPaymentSelling:null,
  paymentHelperFull:null
};

function PaymentSellingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PaymentSellingActions.BEFORE_PAYMENTSELLING:
      return { ...state, isProcessing: false,showModal:false,type:action.payload,dataPaymentSelling:{}};
    case PaymentSellingActions.AFTER_PAYMENTSELLING:
      return { ...state, isProcessing: false,showModal:true,dataPaymentSelling:action.payload,isNewRecord:false,onSubmit:false,errorMessage: []};
    case PaymentSellingActions.RESET_LIST:
      return INITIAL_STATE;
    case PaymentSellingActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true,errorMessage:[]};
    case PaymentSellingActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case PaymentSellingActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PaymentSellingActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PaymentSellingActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PaymentSellingActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case PaymentSellingActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PaymentSellingActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PaymentSellingActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case PaymentSellingActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PaymentSellingActions.GET_HELPER_PAYMENTSELLING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperPaymentSelling:action.payload};
     case PaymentSellingActions.GET_HELPER_PAYMENTSELLING_FULL:
        return { ...state, isError: false,isSuccess:false,onSubmit:false,paymentHelperFull:action.payload};
      default:
      return state;
    }
}


export default PaymentSellingReducer;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import SalesInvoiceReportMiddleware from "../../../../middleware/modules/report/sales-report";
import FormSalesInvoiceAllReport from "../../../../components/modules/report/sales-report/sales";

const mapStateToProps = ({ auth, salesInvoiceReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: salesInvoiceReport.isProcessing,
  isError: salesInvoiceReport.isError,
  errorMessage: salesInvoiceReport.errorMessage,
  datalist:salesInvoiceReport.data,
  isSuccess: salesInvoiceReport.isSuccess,
  isReset: salesInvoiceReport.isReset,
  isNewRecord: salesInvoiceReport.isNewRecord,
  onSubmit: salesInvoiceReport.onSubmit,
  helperSalesInvoiceReport: salesInvoiceReport.helperSalesInvoiceReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setData: (data) =>SalesInvoiceReportMiddleware.list(data),
    },
    dispatch
  );
};
class SalesInvoiceAllReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    datalist: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show : 0,
    };
    this.setData = this.setData.bind(this);
  }

  setData() {
    this.props.setData();
  }


  render() {
    const {show,datalist} = this.props
    this.state.show = show;
    return (
      <Fragment>
            <FormSalesInvoiceAllReport
             {...this.props}
             data={datalist}
            />
         </Fragment>
     
    );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceAllReport);

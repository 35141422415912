export default class VehicleActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ERROR_DATA='ERROR_DATA';
    
    static create() {
      return {
        type: VehicleActions.NEW_DATA
      };
    }
  
    static update(data) {
      return {
        type: VehicleActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: VehicleActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: VehicleActions.DELETE_DATA,
        payload: data
      };
    }
  
    static list(data) {
      return {
        type: VehicleActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: VehicleActions.ERROR_DATA,
        payload: data
      };
    }


  }


import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import LoginContainer from './../../containers/LoginContainer';

class Login extends Component {

  componentWillUnmount() {}

  render() {
    return (
        <LoginContainer />
    );
  }
}

export default Login;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import SalesPerformanceChartMiddleware from "../../../../middleware/modules/chart/sales-performance";
import SalesPerformanceChart from "../../../../components/modules/chart/sales-performance";
import SalesPerformanceTable from "../../../../components/modules/chart/sales-performance/index-table";
// middlewares

const mapStateToProps = ({ auth, salesPerformanceChart }) => ({
  isProcessing: salesPerformanceChart.isProcessing,
  isError: salesPerformanceChart.isError,
  errorMessage: salesPerformanceChart.errorMessage,
  datatable: salesPerformanceChart.dataTable,
  helperSalesChart: salesPerformanceChart.helperSalesChart,
  afterSave: salesPerformanceChart.afterSave,
  showModal: salesPerformanceChart.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => SalesPerformanceChartMiddleware.getHelper(),
      setDataTable: (data) => SalesPerformanceChartMiddleware.setDataTable(data),
      reset: () => SalesPerformanceChartMiddleware.resetList(),
      search: (data) => SalesPerformanceChartMiddleware.table(data),
    },
    dispatch
  );
};
class SalesPerformanceTableContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,

  };

  static defaultProps = {
    afterSave: false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.setDataTable = this.setDataTable.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    // this.props.getHelper();
  }

  setDataTable(params) {
    this.props.setDataTable(params);
  }

  render() {
    const { isProcessing, helperSalesChart } =
      this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Sales Performance List")}</title>
        </Helmet>
        <SalesPerformanceTable
          {...this.props}
          createData={this.createItem}
          voidItem={this.voidItem}
          helperSalesChart={helperSalesChart}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformanceTableContainer);

import { combineReducers } from 'redux';
import AuthReducer from './auth';
import CustomerReducer from './modules/master/customer';
import CustomerTypeReducer from './modules/master/customer-type';
import UnitReducer from './modules/master/unit';
import StoresReducer from './modules/master/store';
import ProvinceReducer from './modules/master/province';
import CityReducer from './modules/master/city';
import DistrictReducer from './modules/master/district';
import SubDistrictReducer from './modules/master/sub-district';
import PostalCodeReducer from './modules/master/postalcode';
import BranchReducer from './modules/master/branch';
import WarehouseReducer from './modules/master/warehouse';
import SubWarehouseReducer from './modules/master/subwarehouse';
import SupplierReducer from './modules/master/supplier';
import VehicleReducer from './modules/master/vehicle';
import TaxReducer from './modules/master/tax';
import WorkCalendarReducer from './modules/master/work-calendar';
import ItemReducer from './modules/master/item';
import ItemTypeReducer from './modules/master/itemtype';
import OfferingReducer from './modules/pembelian/offering';
import PurchaseRequestReducer from './modules/pembelian/purchase-request';
import PurchaseRecapReducer from './modules/pembelian/purchase-recap';
import PurchaseOrderReducer from './modules/pembelian/purchase-order';
import ReceivingReducer from './modules/pembelian/receiving';
import SetupWarehouseReducer from './modules/master/setup-warehouse';
import Coa1Reducer from './modules/accounting/coa1';
import Coa2Reducer from './modules/accounting/coa2';
import Coa3Reducer from './modules/accounting/coa3';
import Coa4Reducer from './modules/accounting/coa4';
import Coa5Reducer from './modules/accounting/coa5';
import AccSetupReducer from './modules/accounting/acc-setup';
import InvoiceReducer from './modules/pembelian/invoice';
import WarehouseOutReducer from './modules/warehouse/warehouse-out';
import WarehouseInReducer from './modules/warehouse/warehouse-in';
import BankReducer from './modules/accounting/bank';
import ConfigurationReducer from './modules/accounting/configuration';
import PaymentReducer from './modules/pembelian/payment';
import MutationReducer from './modules/mutation/mutation';
import JournalReducer from './modules/accounting/journal';
import CashBankReducer from './modules/accounting/cash-bank';
import PurchaseReturnReducer from './modules/pembelian/purchase-return';
import MemoOutReducer from './modules/accounting/memo-out';
import MemoInReducer from './modules/accounting/memo-in';
import CustomerHierarchyReducer from './modules/master/customer-hierarchy';
import SetupHierarchyReducer from './modules/master/setup-hierarchy';
import MenuReducer from './modules/user/menu';
import RoleReducer from './modules/user/role';
import AssignmentReducer from './modules/user/assignment';
import AdminReducer from './modules/user/admin';
import TempCustomerReducer from './modules/master/customer-temp';
import SalesOrderReducer from './modules/sales/sales-order';
import SellingReducer from './modules/sales/selling';
import logPrintReducer from './modules/log/print';
import CustomerApprovalReducer from './modules/log/customer-approval';
import SellingPriceReducer from './modules/sales/selling-price';
import TransactionUpdateReducer from './modules/log/transaction-update';
import DivisionReducer from './modules/hr/divison';
import PositionReducer from './modules/hr/position';
import CategoryEmployeeReducer from './modules/hr/category';
import SalaryTypeReducer from './modules/hr/salary-type';
import AllowanceReducer from './modules/hr/allowance';
import AllowanceDetailReducer from './modules/hr/allowance-detail';
import SalaryCutsReducer from './modules/hr/salary-cuts';
import SalaryCutsDetailReducer from './modules/hr/salary-cuts-detail';
import SalaryClassReducer from './modules/hr/salary-class';
import AttendanceReducer from './modules/hr/attendance';
import HolidayReducer from './modules/hr/holiday';
import EmployeeReducer from './modules/hr/employee';
import ProgramTypeReducer from './modules/marketing/program-type';
import MarketingReducer from './modules/marketing/marketing';
import DailyAttendanceReducer from './modules/hr/daily-attendance';
import LogActivityReducer from './modules/log/activity';
import PaymentSellingReducer from './modules/sales/payment-selling';
import ApprovalReducer from './modules/user/approval';
import LogApprovalReducer from './modules/log/approval';
import NewsEventReducer from './modules/news/news-event';
import AccountPayableReducer from './modules/report/account-payable';
import UncollectedDebtReducer from './modules/report/uncollected-debt';
import AccountReceiveableReducer from './modules/report/account-receiveable';
import RouteReducer from './modules/user/route';
import PermissionReducer from './modules/user/permission';
import CashBankBookReducer from './modules/report/cash-bank';
import PurchasingReportReducer from './modules/report/purchasing-report';
import VisitScheduleReducer from './modules/sales/visit-schedule';
import SalesInvoiceReportReducer from './modules/report/sales-report';
import TaskReducer from './modules/sales/task';
import BillingReducer from './modules/sales/billing';
import ItemBrandReducer from './modules/master/item-brand';
import ItemCategoryReducer from './modules/master/item-category';
import InventoryReducer from './modules/master/inventory';
import DeliveryOrderReducer from './modules/sales/delivery-order';
import QuotationRequestReducer from './modules/pembelian/quotation';
import GeneralLedgerReducer from './modules/report/general-ledger';
import WorkSheetReducer from './modules/report/work-sheet';
import TakingGoodsReducer from './modules/warehouse/taking-goods';
import ReturnPriceReducer from './modules/sales/return-price';
import ReturnSellingReducer from './modules/sales/return-selling';
import LogBgReducer from './modules/accounting/log-bg';
import DownPaymentReducer from './modules/accounting/down-payment';
import IncomeStatementReducer from './modules/report/income-statement';
import BalanceSheetReducer from './modules/report/balance-sheet';
import CashFlowReducer from './modules/report/cash-flow';
import ConfigBranchReducer from './modules/master/config-branch';
import SalesPerformanceChartReducer from './modules/chart/sales-performance';
import SalesTargetReducer from './modules/sales/sales-target';
import BeginningBalanceReducer from './modules/accounting/beginning-balance';
import AssetTypeReducer from './modules/accounting/asset-type';
import AssetReducer from './modules/accounting/asset';
import AssetReportReducer from './modules/report/asset-report';
import CustomerPerformanceReducer from './modules/chart/customer-performance';
import CashSalesReducer from './modules/sales/cash-sales';
import TargetAssignmentReducer from './modules/sales/target-assignment';
import CustomerReporReducer from './modules/report/customer-report';
import CustomerReportReducer from './modules/report/customer-report';
import InfoDistributionReducer from './modules/master/info-distribution';
import SalesTargetReportReducer from './modules/report/sales-target-report';
import ListPaymentReducer from './modules/accounting/list-payment';
import ReportVisitReducer from './modules/sales/report-visit';
import MutationDepositReducer from './modules/mutation/mutation-deposit';
import StockCardReducer from './modules/mutation/stock-card';
import SettlementSalesReducer from './modules/mutation/settlement-sales';


export default combineReducers({
    // navigation: NavigationStateReducer,
    auth: AuthReducer,
    customer:CustomerReducer,
    customerType:CustomerTypeReducer,
    stores:StoresReducer,
    province:ProvinceReducer,
    city:CityReducer,
    district:DistrictReducer,
    subDistrict:SubDistrictReducer,
    postalcode:PostalCodeReducer,
    branch:BranchReducer,
    warehouse:WarehouseReducer,
    subWarehouse:SubWarehouseReducer,
    supplier:SupplierReducer,
    vehicle:VehicleReducer,
    tax:TaxReducer,
    workCalendar:WorkCalendarReducer,
    item:ItemReducer,
    inventory:InventoryReducer,
    unit:UnitReducer,
    itemType:ItemTypeReducer,
    itemBrand:ItemBrandReducer,
    itemCategory:ItemCategoryReducer,
    offering:OfferingReducer,
    purchaserequest:PurchaseRequestReducer,
    purchaserecap:PurchaseRecapReducer,
    purchaseOrder:PurchaseOrderReducer,
    receiving:ReceivingReducer,
    setupWarehouse:SetupWarehouseReducer,
    coa1:Coa1Reducer,
    coa2:Coa2Reducer,
    coa3:Coa3Reducer,
    coa4:Coa4Reducer,
    coa5:Coa5Reducer,
    accsetup:AccSetupReducer,
    invoice:InvoiceReducer,
    warehouseout:WarehouseOutReducer,
    warehousein:WarehouseInReducer,
    bank:BankReducer,
    configuration:ConfigurationReducer,
    payment:PaymentReducer,
    mutasi:MutationReducer,
    journal:JournalReducer,
    cashbank:CashBankReducer,
    purchasereturn:PurchaseReturnReducer,
    memoout:MemoOutReducer,
    memoin:MemoInReducer,
    customerhierarchy:CustomerHierarchyReducer,
    setuphierarchy:SetupHierarchyReducer,
    menu:MenuReducer,
    role:RoleReducer,
    assignment:AssignmentReducer,
    tempcustomer:TempCustomerReducer,
    salesorder:SalesOrderReducer,
    selling:SellingReducer,
    logPrint:logPrintReducer,
    customerApproval:CustomerApprovalReducer,
    sellingPrice:SellingPriceReducer,
    transactionUpdate:TransactionUpdateReducer,
    division:DivisionReducer,
    position:PositionReducer,
    categoryEmployee:CategoryEmployeeReducer,
    salaryType:SalaryTypeReducer,
    allowance:AllowanceReducer,
    allowanceDetail:AllowanceDetailReducer,
    salaryCuts:SalaryCutsReducer,
    salaryCutsDetail:SalaryCutsDetailReducer,
    salaryClass:SalaryClassReducer,
    attendance:AttendanceReducer,
    holiday:HolidayReducer,
    employee:EmployeeReducer,
    programType:ProgramTypeReducer,
    marketing:MarketingReducer,
    dailyAttendance:DailyAttendanceReducer,
    logActivity:LogActivityReducer,
    paymentSelling:PaymentSellingReducer,
    approval:ApprovalReducer,
    logApproval:LogApprovalReducer,
    newsEvent:NewsEventReducer,
    accountPayable:AccountPayableReducer,
    uncollectedDebt:UncollectedDebtReducer,
    accountReceiveable:AccountReceiveableReducer,
    cashbankBook:CashBankBookReducer,
    admin:AdminReducer,
    route:RouteReducer,
    permission:PermissionReducer,
    purchasingReport:PurchasingReportReducer,
    visitSchedule:VisitScheduleReducer,
    salesInvoiceReport:SalesInvoiceReportReducer,
    task:TaskReducer,
    billing:BillingReducer,
    deliveryOrder:DeliveryOrderReducer,
    quotationRequest:QuotationRequestReducer,
    generalLedger:GeneralLedgerReducer,
    workSheet:WorkSheetReducer,
    takingGoods:TakingGoodsReducer,
    returnPrice:ReturnPriceReducer,
    logBg:LogBgReducer,
    downPayment:DownPaymentReducer,
    returnSelling:ReturnSellingReducer,
    incomeStatement:IncomeStatementReducer,
    balanceSheet:BalanceSheetReducer,
    cashFlow:CashFlowReducer,
    configBranch:ConfigBranchReducer,
    salesPerformanceChart:SalesPerformanceChartReducer,
    salesTarget:SalesTargetReducer,
    beginningBalance:BeginningBalanceReducer,
    assetType:AssetTypeReducer,
    asset:AssetReducer,
    assetReport:AssetReportReducer,
    customerPerformance:CustomerPerformanceReducer,
    cashSales:CashSalesReducer,
    targetAssignment:TargetAssignmentReducer,
    customerReport:CustomerReportReducer,
    infoDistribution:InfoDistributionReducer,
    salesTargetReport:SalesTargetReportReducer,
    listPayment:ListPaymentReducer,
    reportVisit:ReportVisitReducer,
    mutationDeposit:MutationDepositReducer,
    stockCard:StockCardReducer,
    settlementSales:SettlementSalesReducer
    // logBg:LogBgReducer
})
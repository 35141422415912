import { useParams  } from "react-router-dom";
import React from "react";
import IncomeStatementContainer from '../../../containers/Print/default/Income-Statement'
export const PrintIncomeStatement = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <IncomeStatementContainer data={data}/>
    )
}

export default PrintIncomeStatement;
import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { Button } from "react-bootstrap";
import cookieManager from "../../../../utils/cookieManager";
import DataSales from "./data_sales";
import Timeline from "./timeline";
import data from "./sample";
const MySwal = withReactContent(Swal);
export class ReportVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report Visit Sales"), class: " text-gray-500" },
      ],
      idBranch: null,
      branchCurrent: null,
      branchText: "Select Branch",
      branchList: [],
      loadingStore:false,
      loadingEmployee:false,
      idStore: null,
      storeCurrent: null,
      storeText: "Select Store",
      storeList: [],
      idEmployee: null,
      employeeCurrent: null,
      employeeText: "Select Employee",
      employeeList: [],
      date:new Date(),
    }
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime=this.handlerDateTime.bind(this);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
        [key]: value,
        [currentState]: e,
      }
    );
  }
  getData(type,extra=()=>{}) {
    const token = cookieManager.getUserToken();
    let params = null;
    let fill = null;
    switch (type) {
      case "getStore":
        fill = "storeList";
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        if (branch != null) {
          params = {
            id: branch,
          };
        }

        break;
      case "getEmployee":
        fill = "employeeList";
        var url = process.env.REST_URL + "helper/get-employee";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != undefined
            ? this.state.storeCurrent.value
            : null;
        if (branch != null || store != null) {
          params = {
            branch: branch,
            store: store,
          };
        }

        break;
    }

    if (type != null && params != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            this.setState({ [fill]: response.data.result },extra);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handlerDateTime(e,name) {
      var key=name;
      var value=e
      this.setState({
          [key]:value
      })
  }
  showTimeline(){
    const post={
      date:moment(this.state.date).format('YYYY-MM-DD'),
      idEmployee:this.state.idEmployee,
    }
    console.log('state',this.state)
    if(this.state.idEmployee !=null && this.state.date!=''){
      this.props.getTimeline(post)
    }
  }

  render() {
    const { helper,timeline} = this.props;
    // const { helper} = this.props;
    if (helper != null && helper.branch != undefined) {
      this.state.branchList = helper.branch;
    }
    // let timeline=data
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
           
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4">
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      name={"idBranch"}
                      required={true}
                      onChange={async (e) => {
                        if (e != this.state.branchCurrent) {
                          await this.setState({
                            storeList: [],
                            storeCurrent: null,
                            idStore: null,
                            employeeList: [],
                            employeeCurrent: null,
                            idEmployee: null,
                            loadingStore:true,
                            loadingEmployee:true,
                          });
                          await this.handlerSelect(
                            e,
                            "idBranch",
                            "branchCurrent"
                          );
                          await this.getData("getStore",()=>{
                            this.setState({ loadingStore:false})
                          });
                          await this.getData("getEmployee",()=>{
                            this.setState({ loadingEmployee:false})
                          });
                        }
                      }}
                      options={this.state.branchList}
                      title={i18n.t("Branch")}
                      value={this.state.branchCurrent}
                      currentState={"branchCurrent"}
                      placeholder={this.state.branchText}
                      error={this.renderError("idBranch")}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      isLoading={this.state.loadingStore}
                      name={"idStore"}
                      required={false}
                      onChange={async (e) => {
                        if (e != this.state.storeCurrent) {
                          await this.setState({
                            employeeList: [],
                            employeeCurrent: null,
                            idEmployee: null,
                            loadingEmployee:true,
                          });
                          await this.handlerSelect(
                            e,
                            "idStore",
                            "storeCurrent"
                          );
                          await this.getData("getEmployee",()=>{
                            this.setState({ loadingEmployee:false})
                          });
                        }
                      }}
                      options={this.state.storeList}
                      title={i18n.t("Store")}
                      value={this.state.storeCurrent}
                      currentState={"storeCurrent"}
                      placeholder={this.state.storeText}
                      error={this.renderError("idStore")}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      name={"idEmployee"}
                      required={true}
                      isLoading={this.state.loadingEmployee}
                      onChange={(e) =>
                        this.handlerSelect(e, "idEmployee", "employeeCurrent")
                      }
                      options={this.state.employeeList}
                      title={i18n.t("Employee")}
                      value={this.state.employeeCurrent}
                      currentState={"employeeCurrent"}
                      placeholder={this.state.employeeText}
                      error={this.renderError("idEmployee")}
                    />
                  </div>
                  <div className="col-sm-3">
                    <DatePickerCustom
                        name={"date"}
                        title={i18n.t("Date")}
                        required={true}
                        selected={this.state.date}
                        onSelect={async (e) => {
                              await this.handlerDateTime(e, 'date');
                          }
                        }
                        onChange={(e) => { }}
                        startDay={0}
                        autoComplete={"off"}
                        dateFormat="dd MMMM yyyy"
                        maxDate={new Date()}
                        className="form-control form-control-sm"
                    />
                  </div>
                  
                  <div className="col-sm-3">
                    <Button
                      onClick={(e) => {
                        this.showTimeline();
                      }}
                      variant="success"
                      className="btn btn-sm btn btn-success mt-8 "
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {timeline &&<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
						
						<div className="content flex-row-fluid" id="kt_content">
							
							<div className="d-flex flex-row">
							
								<div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px" data-kt-drawer="true" data-kt-drawer-name="start-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '250px': '300px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_social_start_sidebar_toggle">
									
									<DataSales
                  {...timeline.data_sales}
                  ></DataSales>
								</div>
								
								
								<div className="w-100 flex-lg-row-fluid mx-lg-13">
									
									<div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
										<div className="d-flex align-items-center gap-2">
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_start_sidebar_toggle">
												<i className="ki-duotone ki-profile-circle fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_end_sidebar_toggle">
												<i className="ki-duotone ki-scroll fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
										</div>
									</div>
									
									
									<div className="card">
										
										<div className="card-header card-header-stretch">
											
											<div className="card-title d-flex align-items-center">
												<i className="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
													<span className="path4"></span>
													<span className="path5"></span>
													<span className="path6"></span>
												</i>
												{/* <h3 className="fw-bold m-0 text-gray-800">{(timeline?.date)?moment(timeline?.date).format('MMMM DD, YYYY'):''}</h3> */}
											</div>
											<div className="card-toolbar m-0">
												<ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
													<li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#timeline">Timeline</a>
													</li>
													<li className="nav-item" role="presentation">
														<a  className="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#status">Status Selling</a>
													</li>
												</ul>
											</div>
										</div>
										
										<div className="card-body">
                      <div className="tab-content">
                        <div id="timeline" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="timeline">
                             <Timeline
                              {...timeline}
                              {...this.props}
                             >

                             </Timeline>
                        </div>
                        <div id="status" className="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="status">
                       
                        </div>
											</div>
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>}
        
      </div>
    );
  }
}

export default ReportVisit;

import CityActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: {},
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperCity: null,
};

function CityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CityActions.NEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case CityActions.UPDATE_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        isNewRecord: false,
        showModal: true,
      };
    case CityActions.VIEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case CityActions.RESET_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case CityActions.DELETE_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case CityActions.ON_SUBMIT:
      return { ...state, onSubmit: true };
    case CityActions.SHOW_DATA:
      return {
        ...state,
        isError: false,
        data: action.payload,
        errorMessage: {},
        isProcessing: false,
      };
    case CityActions.SUCCESS_DATA:
      return {
        ...state,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case CityActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false, isSuccess: false };
    case CityActions.ERROR_DATA:
      return {
        ...state,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case CityActions.GET_HELPER_CITY:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperCity: action.payload,
      };
    default:
      return state;
  }
}

export default CityReducer;

import SetupHierarchyActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSetupHierarchy:null,
  errorInternal:false,
  isErrorDelete:false,
};

function SetupHierarchyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SetupHierarchyActions.RESET_LIST:
      return { ...state, isProcessing: false, single:null, form:INITIAL_STATE.form, isNewRecord:true,data:{},isError: false};
    case SetupHierarchyActions.NEW_DATA:
      return { ...state, isProcessing: false, single:null, form:INITIAL_STATE.form, isNewRecord:true, showModal:true, isError: false};
    case SetupHierarchyActions.UPDATE_DATA:
      return { ...state,isProcessing: false, single:null, isNewRecord:false, isError: false};
    case SetupHierarchyActions.VIEW_DATA:
      return { ...state,isProcessing: false, single:action.payload,isNewRecord:false,showModal:true,  isError: false};
    case SetupHierarchyActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SetupHierarchyActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SetupHierarchyActions.ON_SUBMIT:
      return { ...state, onSubmit:true, isError: false};
    case SetupHierarchyActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SetupHierarchyActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SetupHierarchyActions.PROCESS_DATA:
      return { ...state,  isProcessing: true, isError: false,isSuccess:false};
    case SetupHierarchyActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SetupHierarchyActions.GET_HELPER_SETUP_HIERARCHY:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperSetupHierarchy:action.payload};
    case SetupHierarchyActions.ERROR_SERVER:
      return { ...state, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case SetupHierarchyActions.ERROR_DELETE:
      return { ...state,  isError: false, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
      
    default:
      return state;
    }
}

export default SetupHierarchyReducer;

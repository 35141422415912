import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import InputNumber from "../../../utilities/Form/InputNumber";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        SalesOrder: {},
        SalesOrderDetail: [],
      },
      transactionNo: "",
      titleModal: i18n.t("Convertion"),
      date: new Date(),
      arrayDetail: [
        {
          itemValue: [],
          description: "",
          qty: 0,
          idUnit: null,
          idItem: null,
          status: 1,
          unitPrice: 0,
        },
      ],
      itemList: [],
      unitList: [],
      detailSalesOrder: [],
      itemText: i18n.t("Select Item"),
      unitText: i18n.t("Select Unit"),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      salesCurrent: null,
      salesText: i18n.t("Select Sales"),
      salesList: [],
      customerCurrent: null,
      customerText: i18n.t("Select Customer"),
      customerList: [],
      storeCurrent: null,
      storeText: i18n.t("Select Store"),
      storeList: [],
      isUpdate: 0,
      status: 1,
      totalPrice: 0,
      grandTotal: 0,
      limit:0,
      allTrans:0,
      addItem:0,
      paymentType:null,
    };

    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }

    let priceNett = price + priceTax - discount;
    let priceTotal = priceNett * qty;
    objectss["priceNett"] = priceNett;
    objectss["priceTax"] = priceTax;
    objectss["priceTotal"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    //total
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].price) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].priceTax) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }

    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });
  }
  runSubmit() {
    const SalesOrder = {
      transactionNo: this.state.transactionNo,
      date: moment(this.state.date).format("YYYY-MM-DD"),
      idBranch:
        this.state.branchCurrent != null ? this.state.branchCurrent.value : "",
      idStore:
        this.state.storeCurrent != null ? this.state.storeCurrent.value : "",
      idSales:
        this.state.salesCurrent != null ? this.state.salesCurrent.value : "",
      idCustomer:
        this.state.customerCurrent != null ? this.state.customerCurrent.value : "",
      status: 1,

    };
    
    const detail = this.state.arrayDetail;
    
    let idItem = [];
    let idUnit = [];
    let qty = [];
    let unitPrice = [];
    let status = [];


    for (var i = 0; i < detail.length; i++) {
      idItem.push(detail[i].idItem == null ? "" : detail[i].idItem.value);
      idUnit.push(detail[i].idUnit == null ? "" : detail[i].idUnit.value);
      unitPrice.push(detail[i].unitPrice == null ? "" : detail[i].unitPrice);
      qty.push(detail[i].qty);
      status.push(1)

    }
    this.state.form.SalesOrder = SalesOrder;
    this.state.form.SalesOrderDetail = {
      idItem: idItem,
      idUnit: idUnit,
      qty: qty,
      status: status,
      unitPrice:unitPrice,

    };

    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.qtyAvailable,
      convertionDetail: detail.convertionDetail,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td>
            <Select
              isClearable={true}
              name={"idItem"}
              required={true}
              className={""}
              size={4}
              onChange={(e) =>
                this.handlerSelectArray(e, "idItem", i, "arrayDetail")
              }
              options={this.state.itemList}
              value={detail.idItem}
              placeholder={this.state.itemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SalesOrderDetail.idItem." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qty}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "qty", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
            <div
              className={
                detail.stock > 0
                  ? "fv-plugins-message-container valid-feedback"
                  : "fv-plugins-message-container invalid-feedback"
              }
            >
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SalesOrderDetail.qty." + i)}
            </div>
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idUnit"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectArray(e, "idUnit", i, "arrayDetail")
                await this.changeData('unitPrice', 'getSellingPrice', 'price', 'arrayDetail', i)
              }}
              options={this.state.unitList}
              value={detail.idUnit}
              placeholder={this.state.unitText}
            />
           <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SalesOrderDetail.idUnit." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"unitPrice" + i}
              name="unitPrice"
              title={i18n.t("Unit Price")}
              placeholder={i18n.t("Unit Price")}
              defaultValue={0}
              value={detail.unitPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "unitPrice", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("SalesOrderDetail.unitPrice." + i)}
            </div>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-so";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case "getInvoiceLimit":
        var url = process.env.REST_URL + "helper/get-invoice-limit";
        var id =
          this.state.customerCurrent != null
            ? this.state.customerCurrent.value
            : null;
        params = {
          id:id,
        };
        break;  
      case "getSellingPrice":
        var url = process.env.REST_URL + "helper/get-price-selling";
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.value
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var unit =
          this.state[array][arraykey].idUnit != null
            ? this.state[array][arraykey].idUnit.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          branch: branch,
          store: store,
          date: date,
          item: item,
          unit: unit,
        };
        break;
        case "getEmployeeSales":
          var url = process.env.REST_URL + "helper/get-employee-sales";
          var branch =
            this.state.branchCurrent != null
              ? this.state.branchCurrent.value
              : null;
          var store =
            this.state.storeCurrent != null
              ? this.state.storeCurrent.value
              : null;
          params = {
            branch: branch,
            store: store,
          };
          break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

checkInvoiceLimit(){
  let Invoice = this.state.limit;
  let trans = this.state.allTrans;
  let type = this.state.paymentType;
  if((Invoice <= trans) && (type == 'Credit')){
    this.alertError("Info", i18n.t("Can't Make a New Invoice (Invoice Limit Reached Max)"), "info");
    this.setState({arrayDetail:[],addItem:1})
  }else{
    this.setState({addItem:0})
  }
}

  render() {
    const { data, isNewRecord, helperSalesOrder } = this.props;
    this.state.branchList =
      helperSalesOrder != null ? helperSalesOrder.branch : [];
    this.state.itemList =
      helperSalesOrder != null ? helperSalesOrder.item : [];
    this.state.unitList =
      helperSalesOrder != null ? helperSalesOrder.unit : [];


    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.transactionNo = data.transactionNo
        this.state.description = data.description
        this.state.date = new Date(data.date)
        this.state.branchCurrent = { value: data.idBranch, label: data.name_branch }
        this.state.storeCurrent = { value: data.idStore, label: data.name_store }
        this.state.salesCurrent = { value: data.idSales, label: data.name_sales }
        this.state.customerCurrent = { value: data.idCustomer, label: data.name_customer }
        this.state.arrayDetail = [];
          if(this.state.branchCurrent != null){
          this.changeData("customerList", 'getCustomer', "");
          this.changeData("storeList", 'getStore', "");
          this.changeData("salesList", 'getEmployeeSales', "");
          }
        for (var po = 0; po < data.sales_detail.length; po++) {
          this.state.arrayDetail.push({
            idItem: {
              value: data.sales_detail[po].id_item,
              label: data.sales_detail[po].name_item,
            },
            idUnit: {
              value: data.sales_detail[po].id_unit,
              label: data.sales_detail[po].name_unit,
            },
            qty: data.sales_detail[po].qty,
            unitPrice: data.sales_detail[po].unit_price

          });
        }
        this.state.isUpdate = 1;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Input
                type={"input"}
                name={"transactionNo"}
                title={i18n.t("Order No")}
                required
                disabled
                value={this.state.transactionNo}
                handler={this.handler}
                error={this.renderError("SalesOrder.transactionNo")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Order Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("transactionNo", 'getCode', "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e != this.state.branchCurrent) {
                    await this.setState({
                      storeCurrent: null,
                      storeList: [],
                    });
                  }
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ transactionNo: '', storeCurrent: '', salesCurrent: '', customerCurrent: '' });
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData("transactionNo", 'getCode', "code");
                    await this.changeData("storeList", 'getStore', "");
                    await this.changeData("customerList", 'getCustomer', "");
                    await this.changeData("salesList", 'getEmployeeSales', "");
                  }

                  ;
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("SalesOrder.idBranch")}
              />
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
                error={this.renderError("SalesOrder.idStore")}
              />
            </div>

            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idSales"}
                required={true}
                size={4}
                onChange={async (e) => {

                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }}
                options={this.state.salesList}
                title={i18n.t("Sales")}
                value={this.state.salesCurrent}
                placeholder={this.state.salesText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("SalesOrder.idSales")}
               </div>
            </div>
            <div className="col-md-3 mt-3">
              <Select2
                isClearable={true}
                name={"idCustomer"}
                size={4}
                onChange={async (e) => {
                  if(e==null){
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.setState({limit:0,allTrans:0,addItem:0});
                  }
                  else{
                    await this.handlerSelect(e, "idCustomer", "customerCurrent");
                    await this.changeData('limit', "getInvoiceLimit", "batas");
                    await this.changeData('paymentType', "getInvoiceLimit", "type");
                    await this.changeData('allTrans', "getInvoiceLimit", "allTransaction",null,null,()=>this.checkInvoiceLimit());
                  }
                 
                }}
                options={this.state.customerList}
                title={i18n.t("Customer")}
                value={this.state.customerCurrent}
                placeholder={this.state.customerText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("SalesOrder.idCustomer")}
               </div>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center">{i18n.t("Item")}</th>
                    <th className="text-center">{i18n.t("Qty")}</th>
                    <th className="text-center">{i18n.t("Unit")}</th>
                    <th className="text-center">{i18n.t("Price")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              {this.state.addItem == 0 ? 
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      itemValue: null,
                      description: "",
                      qty: 0,
                      batchNo: "",
                      expiredDate: null,
                      unit: "",
                      poValue: null,
                      warehouseValue: null,
                      qtyAvailable: 0,
                      itemList: [],
                      bandNo: "",
                      suffixqty: "",
                      convertionDetail: [],
                      unitList: [],
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button> : ''}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Form;

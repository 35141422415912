import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Role } from "../../../../components/modules/user/role";
import { CreateForm } from "../../../../components/modules/user/role/form";
// middlewares
import RoleMiddleware from "../../../../middleware/modules/user/role";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import Assign from "../../../../components/modules/user/role/assign";

const mapStateToProps = ({ auth, role }) => ({
  isProcessing: role.isProcessing,
  isError: role.isError,
  errorMessage: role.errorMessage,
  data: role.data,
  isNewRecord:role.isNewRecord
  
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: () => RoleMiddleware.resetList(),
      create: () => RoleMiddleware.create(),
      search: (data) => RoleMiddleware.list(data),
      update: (data) => RoleMiddleware.update(data),
      saveNew:(data,navigate)=>RoleMiddleware.beforeSaveNew(data,navigate),
      saveUpdate:(id,data,navigate)=>RoleMiddleware.beforeSaveUpdate(id,data,navigate),
      delete: (id, search) => RoleMiddleware.beforeDelete(id, search),
      showAssign:(id)=>RoleMiddleware.showAssign(id),
      assign: (data,id) =>RoleMiddleware.beforeAssign(data,id),
      revoke : (data,id) =>RoleMiddleware.beforeRevoke(data,id),
    },
    dispatch
  );
};
class RoleContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentWillMount() {
    const {typeUrl,id}=this.props
    this.props.reset();
    switch (typeUrl) {
      case "assign":
        
        break;
      case "create":
        this.props.create()
        break;
        case "update":
        this.props.create(id)
        break;
      default:
        
        break;
    }
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }

  renderContent() {
    const { typeUrl } = this.props;
    switch (typeUrl) {
      case "assign":
        return (
          <>
            <Assign {...this.props} />
          </>
        );
      case "create":
        return (
          <>
            <CreateForm {...this.props} />
          </>
        );
        case "update":
          return (
            <>
              <CreateForm {...this.props} />
            </>
          );
      default:
        return (
          <>
            <Helmet>
              <title>{i18n.t("Role List")}</title>
            </Helmet>
            <Role {...this.props} />
          </>
        );
    }
  }
  render() {
    return <React.Fragment>{this.renderContent()}</React.Fragment>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleContainer);

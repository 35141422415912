import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faFileExcel,faFilePdf } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Radio from "../../../utilities/Form/radio";
import { Period } from "../../../utilities/Const/StatusActive";
import { t } from "i18next";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
export class WorkSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      coa3Current: null,
      coa3Text: i18n.t("Select Coa3"),
      coa3List: [],
      idCoa3: null,
      listData: [],
      list:[],
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      page: 1,
      fill: 0,
      bound:0,
      type: 2,
      limitCurrent : { value: '1', label: 'All' },
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      headeTable: false,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Ledger"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
  }


  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    });
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }

    this.setState({
      [key]: value,
      [currentState]: e
    });
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    });
  }

  handlerSearch(e) {
    var value = e.target.value;
    let data = this.state.listData
    var updateList = [...this.state.list];
    if(value != ""){
      
      updateList = updateList.filter((item)=>{
        if(item.journal_no != null){
           return item.journal_no.toLowerCase().includes(value.toLowerCase()) ||
                  item.remark.toLowerCase().includes(value.toLowerCase()) || 
                  item.date.toLowerCase().includes(value.toLowerCase()) ||
                  item.debt.toLowerCase().includes(value.toLowerCase()) ||
                  item.credit.toLowerCase().includes(value.toLowerCase())||
                  item.balance_str.toLowerCase().includes(value.toLowerCase());
        }
     })
     this.setState({
      bound:1,
      search:value,
      listData: updateList,
    });
    }else{
      this.setState({
        fill: 1,
        search:'',
        bound:0,
      });
    }
  }

  setData() {

    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);
    var coa3 = (this.state.coa3Current == null) ? '' : this.state.coa3Current.value;
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var type = this.state.type;
    var search = (this.state.search == null) ? '' : this.state.search;
   
    let params = {
      startDate: startDate,
      endDate: endDate,
      coa3: coa3,
      limit: limit,
      page: page,
      type: type,
      search:search,
      idBranch:idBranch
    }
    this.props.setData(params);
  }

  

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading...',
      width: "200px",
      heightAuto: true,
      allowEnterKey:true,
      allowOutsideClick:false
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;

    var coa3 = (this.state.coa3Current == null) ? '' : this.state.coa3Current.value;
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/general-ledger/excel",
      method: "GET",
      params: {
        startDate: startDate,
        endDate: endDate,
        coa3: coa3,
        idBranch:idBranch
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
        moment(startDate).format("D/M/YYYY") + ' - ' +
        moment(endDate).format("D/M/YYYY") + ' ' +
        ' Laporan Buku Besar.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e,
      page: 1
    }, () => this.setData()
    )
  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (event.target.value == 0) {
      this.setState({ page: (get - 1) }, () => this.setData());
    } else if (event.target.value == (count - 1)) {
      this.setState({ page: (get + 1) }, () => this.setData());
    } else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }

  renderPagination() {
    const { isProcessing, helperList, datalist, data } = this.props;

    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"] : ""}
                      {i == data.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

renderList() {
  const {data} = this.props
  let html = [];
  let totalDebt = 0;
  let totalCredit = 0;
  if(data != null && data != undefined){
    let row = this.state.listData;
    if(row.length > 0){
      for (var p = 0; p < this.state.listData.length; p++) {
          totalDebt += parseFloat(row[p].debt) || 0;
          totalCredit += parseFloat(row[p].credit) || 0;
      }
    }
    html.push(
      <tr className="border" key={"total"} style={{backgroundColor:'#DCDCDC'}}>
        <td colSpan={'4'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalDebt)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalCredit)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
      </tr>)
    }
    return html;
  
  }

renderDetail(detail,i){
  return <React.Fragment key={"detail"+i}>
            <tr className="table-fill" >
                <td align='center'>
                    {i+1}
                </td>
                <td>
                    {detail.date}
                </td> 
                <td><Link target="_blank"
                    to={"/accounting/journal/detail/" + detail.id}
                  >
                    {detail.journal_no}
                  </Link>
                </td>
                <td>
                    {detail.remark}
                </td>
                <td align='right'>
                    {detail.debt_format}
                </td>
                <td align='right'>
                    {detail.credit_format}
                </td>
                <td align='right' style={{paddingRight:'5px'}}>
                    {detail.balance_format}
                </td>
            </tr>
      </React.Fragment>
}

  renderTab() {
    const { isProcessing } = this.props;
    if(this.state.fill == 0 && this.state.list != undefined){
      this.state.listData = this.state.list
      this.state.fill = 1;
    }

    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="laporanWorkSheet"
        id="laporan"
        className="mb-3"
      >
        <Tab eventKey="laporanWorkSheet" title={i18n.t("Ledger")}>
          <React.Fragment>
              <div className="row">
                <div className="col-mb-1">
                  <Radio
                    list={Period}
                    name={"type"}
                    title={""}
                    inline={false}
                    value={this.state.type}
                    handler={this.handlerRadio}
                  />
                </div>
              </div>
              <div className="row">
                {this.state.type == 1 ?
                  <Fragment>
                    <div className="col-md-4 mt-3">
                      <Select2
                        isClearable={true}
                        name={"period"}
                        required={true}
                        size={4}
                        onChange={async (e) => {
                          await this.handlerSelect(e, "idPeriod", "periodCurrent");
                        }}
                        options={this.state.periodList}
                        title={i18n.t("Period")}
                        value={this.state.periodCurrent}
                        placeholder={this.state.periodText}
                      />
                        <div className="fv-plugins-message-container invalid-feedback">
                          {this.renderError('startDate')}
                        </div>
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    <div className="col-md-4 mt-3">
                      <DatePickerCustom
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        selectsRange={true}
                        isClearable={false}
                        name={"date"}
                        title={i18n.t("Date Period")}
                        required={true}
                        onChange={(e) => {
                          const [start, end] = e;
                          this.setState({ startDate: start, endDate: end });
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        autoComplete={"off"}
                        dateFormat="dd MMMM yyyy"
                        maxDate={new Date()}
                        className="form-control form-control-md"
                      />
                    </div>

                  </Fragment>
                }
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"idBranch"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      } else {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      }
                    }}
                    options={this.state.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.branchCurrent}
                    placeholder={this.state.branchText}
                    labelfield={"true"}
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"idCoa3"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(e, "idCoa3", "coa3Current");
                      } else {
                        await this.handlerSelect(e, "idCoa3", "coa3Current");
                      }
                    }}
                    options={this.state.coa3List}
                    title={i18n.t("Coa Level 3 (Buku Besar)")}
                    value={this.state.coa3Current}
                    placeholder={this.state.coa3Text}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('coa3')}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9 mt-3">
                    <Button onClick={async (e) => {
                      await this.setState({ page: 1}, () => this.setData())
                      await this.setState({ headeTable: true , search:'',bound:0})
                    }}
                      variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                
                  <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {i18n.t("Convert")}
                  </button>
                  <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                  {/* <Link to={{ pathname: '/admin/users', search: qs.stringify(newQueryParam)}} /> */}
                     <a className="dropdown-item"
                        href={"/accounting/general-ledger/print/"+ (this.state.branchCurrent != null ? this.state.branchCurrent.value : 'all') +"/"+
                        (this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate)) +"/"+
                        (this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate)) +"/"+
                        (this.state.coa3Current != null ? this.state.coa3Current.value : '' )
                        } target="_blank">
                        <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  {i18n.t("PDF")}</a> 
                      <a className="dropdown-item " href="#" onClick={()=>this.convertExcel()}><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>  {i18n.t("Excel")} </a>
                
                  </div>
                </div>
                {this.state.headeTable == true?
                  <Fragment>
                  <div className="col-md-3 mt-3 ">
                    <input
                        type={"text"}
                        className="form-control form-control-md justify-content-right"
                        placeholder="Search"
                        name={"search"}
                        title={""}
                        value={this.state.search}
                        onChange = {(e)=>this.handlerSearch(e)}
                    />
                  </div>
                  </Fragment>:null}
                </div>
                {this.state.headeTable == true ?
                  <React.Fragment>
                    <div className={isProcessing ? " table-loading" : ""}>
                    {!isProcessing ? (
                    <Fragment>
                      <div>
                        <br />
                        <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5'}}>
                          <thead>
                            <tr className="table-header">
                              <th width={"3%"} className="text-center">{i18n.t("No.")}</th>
                              <th width={"15%"} >{i18n.t("Date")}</th>
                              <th width={"15%"} >{i18n.t("No. Journal")}</th>
                              <th width={"31%"} >{i18n.t("Remark")}</th>
                              <th width={"12%"} >{i18n.t("Debt")}</th>
                              <th width={"12%"} >{i18n.t("Credit")}</th>
                              <th width={"15%"} >{i18n.t("Balance")}</th>
                            </tr>
                          </thead>
                          <tbody key={"body"}>
                              {this.state.listData.length > 0?
                                this.state.listData.map((detail,index)=>{
                                return  this.renderDetail(detail,index);
                            }):null}

                              {this.renderList()}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-12 mt-3">
                        {this.renderPagination()}
                      </div>
                    </Fragment>
                    ) : (
                      <div className="table-loading-message">
                        <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                        <div className="text-center">{i18n.t("Loading")}</div>
                      </div>
                    )
                  }
                   </div>
                  </React.Fragment> :
                  ''}
              
          </React.Fragment>
        </Tab>

      </Tabs>
    );
  }
  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.periodList = helperList.workCalendar
    this.state.coa3List = helperList.coa3;
    this.state.branchList = helperList.branch;

    if(data != undefined){
      this.state.list = data.data
      if(this.state.bound == 0){
        this.state.fill = 0
      }
    }

    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8 ">

              {this.renderTab()}
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default WorkSheet;

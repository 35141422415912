import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import ResetPassword from '../../../components/auth/ResetPassword';
// middlewares
import AuthMiddleware from './../../../reducers/auth/middleware';


const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  successRequest:auth.successRequest
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    request: credentials => AuthMiddleware.beforeRequest(credentials)
  }, dispatch);
};

class ResetContainer extends Component {

  render() {
    return (
      <ResetPassword
       {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetContainer);

import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
// import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import cookieManager from "../../../../utils/cookieManager";
import FormContainer from "../../../../containers/modules/sales/visit-schedule/form";
import ViewContainer from "../../../../containers/modules/sales/visit-schedule/view";
const MySwal = withReactContent(Swal);
export class VisitSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idBranch: null,
      branchCurrent: null,
      branchText: "Select Branch",
      branchList: [],
      loadingStore:false,
      loadingEmployee:false,
      idStore: null,
      storeCurrent: null,
      storeText: "Select Store",
      storeList: [],
      idEmployee: null,
      employeeCurrent: null,
      employeeText: "Select Employee",
      employeeList: [],
      year: new Date().getFullYear(),
      search: {
        limit: -1,
      },
      data: [],
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Sales"), class: " text-gray-600" },
        {
          link: null,
          title: i18n.t("Visit Schedule"),
          class: " text-gray-500",
        },
      ],
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    const views = {
      multiMonthFourMonth: {
        type: "multiMonth",
        duration: { months: 2 },
      },
    };
    this.calendarComponent = React.createRef();
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
        [key]: value,
        [currentState]: e,
      }
    );
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  renderBackground(status){
    switch(status){
      case 0:
        return ''
      case 1:
        return '#47be7d'
      case 2:
        return '#d9214e'
    }
  }

  viewEvent(value){
    // this.state.titleModal='View Schedule'
    // this.props.view(value.event.id)
  }


  renderCalendar() {
    const { data } = this.props
    let row = data;
    return (
      <Fragment>
        {(row != null && row != undefined)? (
          <FullCalendar
            ref={this.calendarComponent}
            // key={new Date()}
            plugins={[multiMonthPlugin, interactionPlugin, dayGridPlugin]}
            initialView="multiMonthYear"
            customButtons={{
              addEvent:{
                text:i18n.t("Add Schedule"),
                click: () => {
                  this.state.titleModal = i18n.t("Add Schedule");
                  const data={
                    branch:this.state.branchCurrent,
                    store:this.state.storeCurrent,
                    employee:this.state.employeeCurrent,
                    year:this.state.year,
                  }
                  this.props.newEvent(data)
                },
              },
              refresh:{
                text:i18n.t("Refresh"),
                click: () => {
                  this.showSchedule()
                },
              },
              next: {
                click: () => {
                  this.setState({year:(this.state.year +1)},()=>this.showSchedule())
                  const calendarApi = this.calendarComponent.current.getApi();
                  calendarApi.next();
                  
                },
              },
              today:{
                text:i18n.t("Today"),
                click: () => {
                  this.setState({year: new Date().getFullYear()},()=>this.showSchedule())
                  const calendarApi = this.calendarComponent.current.getApi();
                  calendarApi.today();
                },
              },
              prev: {
                click: () => {
                  const calendarApi = this.calendarComponent.current.getApi();
                  calendarApi.prev();
                  this.setState({year:(this.state.year -1)},()=>this.showSchedule())
                  
                },
              },
            }}
            headerToolbar={{
              left: "prev,today,next",
              center: "title",
              right: "addEvent,refresh",
            }}
            // dateClick={this.handleDateClick}
            events={data.map((item,i)=>{
                return {
                    id:item.id,
                    title:item.name,
                    start:new Date(item.date),
                    backgroundColor:this.renderBackground(item.status)
                  }
                }
              )}
            eventClick={(e)=>this.viewEvent(e)}
            displayEventTime={false}
            selectable
            firstDay={1}
            dayMaxEvents={true}
            multiMonthMaxColumns={2}
          />
        ) : null}
      </Fragment>
    );
  }
  getData(type,extra=()=>{}) {
    const token = cookieManager.getUserToken();
    let params = null;
    let fill = null;
    switch (type) {
      case "getStore":
        fill = "storeList";
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        if (branch != null) {
          params = {
            id: branch,
          };
        }

        break;
      case "getEmployee":
        fill = "employeeList";
        var url = process.env.REST_URL + "helper/get-employee";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != undefined
            ? this.state.storeCurrent.value
            : null;
        if (branch != null || store != null) {
          params = {
            branch: branch,
            store: store,
          };
        }

        break;
    }

    if (type != null && params != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            this.setState({ [fill]: response.data.result },extra);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  showSchedule() {
    if (
      this.state.branchCurrent != null &&
      this.state.employeeCurrent != null
    ) {
      let data = {
        branch:
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null,
        store:
          this.state.storeCurrent != undefined
            ? this.state.storeCurrent.value
            : null,
        employee:
          this.state.employeeCurrent != undefined
            ? this.state.employeeCurrent.value
            : null,
        year: this.state.year,
      };
      this.props.show(data);
    }
  }

  render() {
    const { helper,showModal ,isProcessing,userAction} = this.props;
    if (helper != null && helper.branch != undefined) {
      this.state.branchList = helper.branch;
    }
    return (
      <Fragment>
      <div id="Visit-schedule">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4">
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      name={"idBranch"}
                      required={true}
                      onChange={async (e) => {
                        if (e != this.state.branchCurrent) {
                          await this.setState({
                            storeList: [],
                            storeCurrent: null,
                            idStore: null,
                            employeeList: [],
                            employeeCurrent: null,
                            idEmployee: null,
                            loadingStore:true,
                            loadingEmployee:true,
                          });
                          await this.handlerSelect(
                            e,
                            "idBranch",
                            "branchCurrent"
                          );
                          await this.getData("getStore",()=>{
                            this.setState({ loadingStore:false})
                          });
                          await this.getData("getEmployee",()=>{
                            this.setState({ loadingEmployee:false})
                          });
                        }
                      }}
                      options={this.state.branchList}
                      title={i18n.t("Branch")}
                      value={this.state.branchCurrent}
                      currentState={"branchCurrent"}
                      placeholder={this.state.branchText}
                      error={this.renderError("idBranch")}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      isLoading={this.state.loadingStore}
                      name={"idStore"}
                      required={false}
                      onChange={async (e) => {
                        if (e != this.state.storeCurrent) {
                          await this.setState({
                            employeeList: [],
                            employeeCurrent: null,
                            idEmployee: null,
                            loadingEmployee:true,
                          });
                          await this.handlerSelect(
                            e,
                            "idStore",
                            "storeCurrent"
                          );
                          await this.getData("getEmployee",()=>{
                            this.setState({ loadingEmployee:false})
                          });
                        }
                      }}
                      options={this.state.storeList}
                      title={i18n.t("Store")}
                      value={this.state.storeCurrent}
                      currentState={"storeCurrent"}
                      placeholder={this.state.storeText}
                      error={this.renderError("idStore")}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      name={"idEmployee"}
                      required={true}
                       isLoading={this.state.loadingEmployee}
                      onChange={(e) =>
                        this.handlerSelect(e, "idEmployee", "employeeCurrent")
                      }
                      options={this.state.employeeList}
                      title={i18n.t("Employee")}
                      value={this.state.employeeCurrent}
                      currentState={"employeeCurrent"}
                      placeholder={this.state.employeeText}
                      error={this.renderError("idEmployee")}
                    />
                  </div>

                  <div className="col-sm-3">
                    <Button
                      onClick={(e) => {
                        this.showSchedule();
                      }}
                      variant="success"
                      className="btn btn-sm btn btn-success mt-8 "
                    >
                      Show
                    </Button>
                  </div>
                </div>
                {this.renderCalendar()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(showModal && userAction=="create")?<FormContainer
        titleModal={this.state.titleModal}
      />:null}
      {(showModal && userAction=="update")?<FormContainer
        titleModal={this.state.titleModal}
      />:null}
      {(showModal && userAction=="view")?<ViewContainer 
        titleModal={this.state.titleModal}
      />:null}
      </Fragment>
    );
  }
}

export default VisitSchedule;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Branch from '../../../../components/modules/master/branch';
import BranchMiddleware from '../../../../middleware/modules/master/branch';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
import ConfigBranchMiddleware from '../../../../middleware/modules/master/config-branch';
// middlewares
 

const mapStateToProps = ({ auth,branch, configBranch}) => ({
  authUser:auth.authUser,
  isProcessing: branch.isProcessing,
  isError: branch.isError,
  errorMessage: branch.errorMessage,
  datalist:branch.data,
  showModal:branch.showModal,
  showModalConfig:configBranch.showModalConfig
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>BranchMiddleware.create(),
    reset: () =>BranchMiddleware.resetList(),
    search: data =>BranchMiddleware.list(data),
    update: data =>BranchMiddleware.update(data),
    delete: (id,search) =>BranchMiddleware.beforeDelete(id,search),
    getHelper: (id, search) =>BranchMiddleware.getHelper(id, search),
    getConfig:(id)=>ConfigBranchMiddleware.getOne(id)
  }, dispatch);
};
class BranchContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
    this.updateConfig=this.updateConfig.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    
    this.props.update(id)
    this.props.getHelper()
  }
  updateConfig(id){
    this.props.getConfig(id)
  }

  createItem(){
    this.props.create()
   
  }
  render() {
    const {isProcessing, search , datalist,showModal,authUser,showModalConfig} = this.props;
    console.log('showModal',showModal)
    console.log('showModalConfig',showModalConfig)
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Branch List")}
          </title>
        </Helmet>
      <Branch
        authUser={authUser}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        showModalConfig={showModalConfig}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        updateConfig={this.updateConfig}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchContainer);

export default class WarehouseInActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static CANCEL_DATA = 'CANCEL_DATA';
    static GET_HELPER_WAREHOUSE_FULL='GET_HELPER_WAREHOUSE_FULL';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_WHIN='PRINT_WHIN';
    static BEFORE_PRINT_WHIN='BEFORE_PRINT_WHIN';
  
    static submit(){
      return {
        type: WarehouseInActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: WarehouseInActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: WarehouseInActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: WarehouseInActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: WarehouseInActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: WarehouseInActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: WarehouseInActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: WarehouseInActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: WarehouseInActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: WarehouseInActions.RESET_DATA,
      };
    }

    static helperFull(data){
        return {
          type: WarehouseInActions.GET_HELPER_WAREHOUSE_FULL,
          payload:data
        };
      }

      static cancel() {
        return {
          type: WarehouseInActions.CANCEL_DATA
        };
      }

      static resetList(){
        return {
          type: WarehouseInActions.RESET_LIST,
        };
      }

      static errorInternal(error){
        return {
          type: WarehouseInActions.ERROR_SERVER,
          payload: error
        };
      }

      static beforePrint(){
        return {
          type: WarehouseInActions.BEFORE_PRINT_WHIN,
        };
      }
      static print(data){
        return {
          type: WarehouseInActions.PRINT_WHIN,
          payload: data
        };
      }
  }
import React, { Component } from "react";
import PropTypes from "prop-types";

class TextArea extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.string,
    size: PropTypes.string,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    style: "",
    size: "sm",
    error: "",
    placeholder: "",
    required: false,
  };
  constructor(props) {
    super(props);
  }
  renderClass() {
    const { style, size } = this.props;
    var classText = "form-control";
    switch (size) {
      case "sm":
        classText += " form-control-sm";
        break;
      case "md":
        classText += " form-control-md";
        break;
      case "lg":
        classText += " form-control-lg";
        break;
    }

    switch (style) {
      case "solid":
        classText += " form-control-solid";
        break;
      case "flush":
        classText += " form-control-flush";
        break;
    }
    return classText;
  }
  render() {
    const { required, placeholder, title, name, error, handler, value, row } =
      this.props;
    return (
      <div className="mb-3">
        <label
          htmlFor={name + "label"}
          className={"form-label" + (required ? " required" : "")}
        >
          {title}
        </label>
        <textarea
          key={name + "input"}
          name={name}
          className={this.renderClass()}
          placeholder={placeholder}
          onChange={handler}
          value={value}
        />
        <div className="fv-plugins-message-container invalid-feedback">
          {error}
        </div>
      </div>
    );
  }
}

export default TextArea;

import React, { Component, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/pembelian/invoice/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import TableInvoice from "./table";

const MySwal = withReactContent(Swal);
export class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Transaction"), class: " text-gray-600" },
        { link: null, title: i18n.t("Invoice"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "invoice_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Invoice No."),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Invoice Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_supplier",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Supplier Name"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        // {
        //   key: "supplier_inv_no",
        //   filter: { active: true, type: "Text", data: null },
        //   title: i18n.t("Supplier Invoice No."),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
        {
          key: "payment_type",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Payment Type"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "total_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
            key:'id',
            filter:{
                active:false
            },
            title:'Action',
            type:'action',
            tHclass:'text-end min-w-70px m-0',
            tdClass:'',
            buttons:[
              {
                key:'id',
                btnText:i18n.t('Print'),
                visible:{
                    key:'status',
                    // params:[0,5],
                    function:(id,params)=>{
                        return true;
                    }
                },
                btnFunction:(data,column)=>{
                   return false
                },
                btnLink:{
                    url:'/pembelian/invoice/print',
                    target:'_blank'
                },
                bthClass:'btn btn-warning',
             },
          ]
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Invoice");
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Invoice");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.createData()}
                    >
                      {i18n.t("Add Invoice")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <TableInvoice
                  // checkAble={true}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "details",
                    columns: [
                      { label: i18n.t("Receiving No."), key: "id_receiving" },
                      { label: i18n.t("Code Item"), key: "code_item" },
                      { label: i18n.t("Item"), key: "item_name" },
                      { label: i18n.t("Qty Recevied"), key: "qty_format" },
                      { label: i18n.t("Unit"), key: "unit_name" },
                      { label: i18n.t("Price"), key: "nett_price_format" },
                      { label: i18n.t("Discount"), key: "discount_rate_format" },
                      { label: i18n.t("Tax"), key: "tax_price_format" },
                      { label: i18n.t("Total Price"), key: "total_format" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default Invoice;

import React, { Component, Fragment, useState } from "react";
import { addDays } from "date-fns";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Chart  from 'react-apexcharts'
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Radio from "../../../utilities/Form/radio";
import { SalesPerformance, SalesPerformanceWeek } from "../../../utilities/Const/StatusActive";
const MySwal = withReactContent(Swal);
const token=cookieManager.getUserToken()
export class SalesPerformanceMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      fillList:0,
      dataSeries : [],
      detail : [],
      range:[],
      year: new Date().getFullYear(),
      branchCurrent:null,
      branchList: [],
      branchText: i18n.t('Select Branch'),
      salesCurrent: null,
      salesList: [],
      salesText: i18n.t('Select Sales'),
      customerList: [],
      customerCurrent: null,
      customerText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      periodEndList : [],
      periodStartCurrent: null,
      periodEndCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      periodCurrentExcel: null,
      periodTextExcel: i18n.t("Select Period"),
      periodListExcel: [],
      loadingSales:false,
      loadingCustomer:false,
      page: 1,
      typeMonitor: 2,
      typeExcel: 2,
      show: 0,
      choose: {
        1: i18n.t("Quantity"),
        2: i18n.t("Revenue"),
      },
      typeDataChart:1,
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Account Payable"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTime(e, name) {
   
    var key = name;
    var value = e
    if(name == 'startDate'){
      this.setState({
        endDate: value,
      })
    }
    this.setState({
      [key]: value,
    })
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handlerSelectPeriod(e, name, currentState) {
    var key = name
    var value = null
    let list = [];
    let dataList = [];

    dataList =this.state.periodList;
    if(key == "periodStartCurrent" && e != null){
      var index = dataList.findIndex(item => item.value === e.value)
      for(var i = index; i < index+5;i++){
        list.push(dataList[i])
      }
      this.setState({periodEndList:list});

    }else{
      
    }
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  setData() {
    var startDate = this.state.typeMonitor == 1 ? moment(this.state.startDate).format("YYYY-MM-DD") : 
                    this.state.typeMonitor == 2 ? (this.state.periodStartCurrent == null?'':this.state.periodStartCurrent.value) :
                    this.state.type == 3 ? null:null;
    var endDate = this.state.typeMonitor == 1 ? moment(this.state.endDate).format("YYYY-MM-DD") : 
                  this.state.typeMonitor == 2 ? (this.state.periodEndCurrent == null?'':this.state.periodEndCurrent.value) :
                  this.state.typeMonitor == 3 ? null:null;
    var month = this.state.monthValue != null ? this.state.monthValue.value : '';
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var idSales = (this.state.salesCurrent == null) ? '' : this.state.salesCurrent.value;
    var type = this.state.typeMonitor;
    var typeData = this.state.typeDataChart;
    let params = {
      startDate: startDate,
      endDate: endDate,
      idBranch: idBranch,
      idSales: idSales,
      type: type,
      typeData:typeData,
      month:month
    }
    this.props.setData(params);
  }

  renderList() {
    const { helperList, data } = this.props
    let html = [];
    // this.state.listData = data;
    // let row = data.data
    // let html = [];
    // let no = 1;
    // let totalDebt = 0;
    // let totalCredit = 0;
    // if (data != undefined && data != null && row != null) {
    //   Swal.close();
    //   for (var p = 0; p < row.length; p++) {
    //     if (row[p].date != null) {
    //       html.push(
    //         <tr className="border" key={p}>
    //           <td className="text-center">{no++}</td>
    //           <td>{row[p].date}</td>
    //           <td>{row[p].supplier_name}</td>
    //           <td>
    //             <Link
    //               to={"/accounting/journal/detail/" + row[p].id_journal}
    //             >
    //               {row[p].no_journal}
    //             </Link>
    //           </td>
    //           <td>{row[p].description}</td>
    //           <td style={{ textAlign: 'right' }}>{row[p].debt_format}</td>
    //           <td style={{ textAlign: 'right' }}> {row[p].credit_format}</td>
    //           <td style={{ textAlign: 'right', paddingRight:'5px' }}> {(row[p].balance_format)}</td>

    //         </tr>)
    //     }
    //     totalDebt += parseFloat(row[p].debt) || 0;
    //     totalCredit += parseFloat(row[p].credit) || 0;

    //   }
    //   html.push(
    //     <tr className="border" style={{backgroundColor:'#DCDCDC'}}>
    //       <td colSpan={'5'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalDebt)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalCredit)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
    //     </tr>)

      return html;
    // }
  }

  alertHandler(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token=cookieManager.getUserToken()
    switch (type) {
        case "getEmployee":
          var url = process.env.REST_URL + "helper/get-employee";
          if(this.state.branchCurrent==null){
            type=null;
          }
          params = {
            branch: this.state.branchCurrent !=null?this.state.branchCurrent.value:null,
          };
          break;
     
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  renderPlace(place){
    let html = [];
    let name = '';
    let sep = ' - ';
    if (place != null && place != undefined ) {
      if(place.length>0){
        for(var i=0; i<place.length; i++){
          console.log(place[i].sub_district)
          if(i==0){
            name = place[i].sub_district!=''?place[i].sub_district:'';
          }else {
            name = place[i].sub_district!=''?sep + place[i].sub_district:'';
          }
            html.push(
              <>
                {name}
              </>
            );
        }
      }
    }
    return html;
  }
  renderDetail(data,i){
    let html = [];
    let totalQty = 0;
    let totalRp = 0;
    if (data != null && data != undefined ) {
      let row = data;
      if(row.length > 0){
          for(var i=0; i<row.length;i++){
           totalQty += row[i].total_qty;
           totalRp += row[i].total_rp;
            html.push(
              <React.Fragment>
                <tr className="border">
                    <td style={{textAlign:'center'}}>
                      {row[i].tanggal.date + ' - ' + i18n.t(row[i].tanggal.month)}
                    </td>
                    <td style={{textAlign:'center'}}>
                      {i18n.t(row[i].tanggal.day)}
                    </td>
                    <td style={{textAlign:'left',paddingLeft:'10px'}}>
                     {this.renderPlace(row[i].place)}
                    </td>
                    <td style={{textAlign:'right',paddingRight:'10px'}}>
                      {i18n.t(row[i].total_qty_format)}
                    </td>
                    <td style={{textAlign:'right',paddingRight:'10px'}}>
                      {i18n.t(row[i].total_rp_format)}
                    </td>
                  </tr>
                </React.Fragment>
              );
             
            }
            html.push(
              <React.Fragment>
                <tr className="border" >
                    <td colSpan={3} style={{textAlign:'center',fontWeight:'bold'}}>
                      {i18n.t("Total  ")}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold',paddingRight:'10px' }}>{parseFloat(totalQty)
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>  
                    <td style={{ textAlign: 'right', fontWeight: 'bold' ,paddingRight:'10px'}}>{parseFloat(totalRp)
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>  
                  </tr>
              </React.Fragment>
            );
   
      }
    }
  return html;
  }

  // renderDetail(){
  //   const { dataMonitor,isError } = this.props;
  //   let html = [];

  //   if (dataMonitor != null && dataMonitor != undefined ) {
  //      let row = dataMonitor.data
  //      if(row.length > 0){
  //         for(var i=0; i<row.length;i++){
  //           html.push(
  //             <React.Fragment>
  //               <tr className="border">
  //                   <td style={{textAlign:'center'}}>
  //                     {row[i].date + ' - ' + i18n.t(row[i].month)}
  //                   </td>
  //                   <td style={{textAlign:'center'}}>
  //                     {i18n.t(row[i].day)}
  //                   </td>
  //                   <td style={{textAlign:'center'}}>
  //                     {row[i].place!=null?
  //                     row[i].place.map((district,i)=>{
  //                        return (<div>{district.name_district}</div>);
  //                     })
                      
  //                     :null}
  //                   </td>
  //                   <td style={{textAlign:'right',paddingRight:'5px'}}>
  //                     {row[i].place!=null?
  //                     row[i].place.map((district,i)=>{
  //                        return (district.total_qty_format);
  //                     })
                      
  //                     :null}
  //                   </td>
  //                   <td style={{textAlign:'right',paddingRight:'5px'}}>
  //                     {row[i].place!=null?
  //                     row[i].place.map((district,i)=>{
  //                        return (district.total_rp_format);
  //                     })
                      
  //                     :null}
  //                   </td>
  //                     {/* {this.renderDistrict(row[i].place)} */}
                   
  //               </tr>
  //             </React.Fragment>
  //           );
  //         }
  //       }
      
  //   }
  //   return html;
  // }

  renderTable() {
    const { dataMonitor,isError } = this.props;
    if (dataMonitor != null && dataMonitor != undefined && isError == false) {
      return (
        <div>
          <div id="html-dist"></div>
            <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
              {i18n.t("Daily Monitor")}
            </div>
            
          {/* <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', marginTop: '5px' }}>
            {i18n.t("Daily Monitoring")}
          </div> */}
          <div className="row">
            <Fragment>
              <div>
              <br />
                {dataMonitor.data.map((tabel,i)=>
                <Fragment>
                <div className="row">
                    <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', marginTop: '5px' }}>
                        {i18n.t("Week : ")}{tabel[0].week}
                    </div>
                </div>
                <div className="row">
                    <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', marginTop: '5px' }}>
                        {i18n.t("District : ")}{tabel[0].name_district}
                    </div>
                    <div>

                    </div>
                </div>
                <table className="table table-responsive table-bordered table-hover" style={{margin:'auto', borderBottom: '1px solid #eff2f5' }}>
                  <thead>
                    <tr key="table-header" className="table-header" style={{ textAlign: 'center', color: 'white' }}>
                      <th width={"10%"} >{i18n.t("Date")}</th>
                      <th width={"10%"} >{i18n.t("Day")}</th>
                      <th width={"50%"} >{i18n.t("Sub District")}</th>
                      <th width={"15%"} >{i18n.t("Total Sales (Qty)")}</th>
                      <th width={"15%"} >{i18n.t("Total Sales (Rp.)")}</th>
                    </tr>
                    {/* <tr key="table-header" className="table-header">
                      {this.state.detail != null ?
                        this.state.detail.map((sales, index) => {
                          return (<th width={"10%"} style={{ textAlign: 'center', color: 'white' }}>{sales.name}</th>);
                        }) : null}
                    </tr> */}
                  </thead>
                  <tbody key={"body"}>
                    {this.renderDetail(tabel,i)}
                  </tbody>
                </table>
                <br />
                </Fragment>
              
              
              )}
                
              </div>
            </Fragment>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
          {i18n.t("DATA NOT FOUND")}
        </div>);
    }
  }

  render() {
    const { isProcessing,helperSalesChart,dataMonitor } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    if(helperSalesChart!= null && this.state.fillList == 0){
      this.state.branchList = helperSalesChart.branch;
      this.state.periodList = helperSalesChart.workCalendar;
      this.state.fillList = 1;
    }
    return (
        <React.Fragment >
          <div className="row ">
            <div className="col-sm-2 mt-12">
              <Radio
                list={SalesPerformanceWeek}
                name={"typeMonitor"}
                title={""}
                labelfield={"false"}
                inline={false}
                value={this.state.typeMonitor}
                handler={this.handlerRadio}
              />
            </div>
        
          {this.state.typeMonitor == 2 ?
          <Fragment>
          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"period"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
              }}
              options={this.state.periodList}
              title={i18n.t("Week Period")}
              value={this.state.periodStartCurrent}
              placeholder={this.state.periodText}
            />
          </div>
          <div style={{width:'35px'}} className="col-sm-1 mt-12">
            {i18n.t("To")}
          </div>
          <div className="col-sm-2 mt-3">
          <Select2
              isClearable={true}
              name={"periodEnd"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
              }}
              options={this.state.periodEndList}
              title={i18n.t(" ")}
              value={this.state.periodEndCurrent}
              placeholder={this.state.periodText}
            />
          </div>
            </Fragment>
            : this.state.typeMonitor == 1 ?
            <Fragment>
              <div className="col-sm-2 mt-3">
                  <DatePickerCustom
                    name={"startDate"}
                    title={i18n.t("Date Period")}
                    selected={this.state.startDate}
                    onSelect={async (e) => {
                      await this.handlerDateTime(e, 'startDate');
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    onChange={(e) => { 
                    }}
                    startDay={0}
                    autoComplete={"off"}
                    dateFormat="dd MMMM yyyy"
                    className="form-control form-control-sm"
                    required

                  >
                  </DatePickerCustom>
                </div>
                <div style={{width:'35px'}} className="col-md-1 mt-11">
                  {i18n.t("To")}
                </div>
                <div className="col-sm-2 mt-11">
                  <DatePickerCustom
                    name={"endDate"}
                    title={i18n.t("")}
                    selected={this.state.endDate}
                    onSelect={async (e) => {
                      await this.handlerDateTime(e, 'endDate');
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    onChange={(e) => { }}
                    autoComplete={"off"}
                    dateFormat="dd MMMM yyyy"
                    className="form-control form-control-sm"
                    labelfield={"false"}
                    minDate = {this.state.startDate}
                    maxDate={addDays(new Date(this.state.startDate), 30)}
                    required
                  ></DatePickerCustom>
                </div>
            </Fragment>
          : this.state.typeMonitor == 3 ?
            <Fragment>
                <div className="col-sm-2 mt-3">
                  <Select2
                    isClearable={true}
                    name={"month"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "month", "monthValue");
                    }}
                    options={this.state.monthList}
                    title={i18n.t("Month")}
                    value={this.state.monthValue}
                    placeholder={this.state.monthText}
                    labelfield={"true"}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('month')}
                  </div>
                </div>
              </Fragment>
          :null
          } 
            {/* <div className="col-sm-2 mt-5">
              <Radio
                list={this.state.choose}
                name={"typeDataChart"}
                title={""}
                inline={false}
                value={this.state.typeDataChart}
                handler={this.handlerRadio}
              />
            </div> */}
            <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idBranch", "branchCurrent");
                await this.setState({
                  salesList: [], salesCurrent: ''
                })
                if (e == null) {
                  await this.setState({
                    salesList: [], salesCurrent: ''
                  })

                } else {
                  await this.setState({
                    loadingSales: true
                  });
                  this.changeData({
                    keyFill: "salesList",
                    type: "getEmployee",
                    callback: () => { this.setState({ loadingSales: false }) }
                  })
                }
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchCurrent}
              placeholder={this.state.branchText}
              labelfield={"true"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('idBranch')}
            </div>
          </div>

          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idSales"}
              required={true}
              size={4}
              isLoading={this.state.loadingSales}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                } else {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }
              }}
              options={this.state.salesList}
              title={i18n.t("Sales")}
              value={this.state.salesCurrent}
              placeholder={this.state.salesText}
              labelfield={"true"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('idSales')}
            </div>
          </div>
      {/* </div>
      <div className="row ml-5"> */}
          {/* <div className="col-sm-3 mt-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idBranch", "branchCurrent");
                if (e == null) {
                  await this.setState({
                    salesList:[],salesCurrent:''
                  })
                
                } else {
                  await this.setState({
                    loadingSales:true
                  });
                  this.changeData({
                        keyFill:"salesList",
                        type:"getEmployee",
                        callback:()=>{this.setState({loadingSales:false})}
                      })
                }
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchCurrent}
              placeholder={this.state.branchText}
              labelfield={"true"}
            />
          </div> */}
          {/* <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idSales"}
              required={true}
              size={4}
              isLoading={this.state.loadingSales}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                } else {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }
              }}
              options={this.state.salesList}
              title={i18n.t("Sales")}
              value={this.state.salesCurrent}
              placeholder={this.state.salesText}
              labelfield={"true"}
            />
          </div> */}
          {/* <div className="col-md-3 mt-3">
            <Select2
              isClearable={true}
              name={"idCustomer"}
              required={true}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                } else {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                }
              }}
              options={this.state.customerCurrent}
              title={i18n.t("Customer")}
              value={this.state.customerCurrent}
              placeholder={this.state.customerText}
              labelfield={"true"}
            />
          </div> */}
          
          </div>
          <div className="row ml-5">
            <div className="col-md-9 mt-3 mb-3">
                <Button onClick={async (e) => {
                  await this.setState({ page: 1 }, ()=>this.setData())
                  await this.setState({ headeTable: true })
                }}
                  variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Generate")} </Button>
            </div>
        </div>
        {this.state.headeTable == true ?
          <React.Fragment>
            <div className={isProcessing ? " table-loading" : ""}>
            {!isProcessing ? (
            <Fragment>
                {this.renderTable()}
            </Fragment>
              ):(
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              )
            }
            </div>
          </React.Fragment> :
          ''}
      </React.Fragment>
          
    );
  }
}

export default SalesPerformanceMonitor;

import SalesInvoiceReportActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataCus: {},
  dataSO:{},
  dataSelling:{},
  dataCusDetail:{},
  dataItem:{},
  dataInvoiceDetail:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSalesInvoiceReport:[],
  errorInternal:false
};

function SalesInvoiceReportReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SalesInvoiceReportActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{},dataSupp:{}};
    case SalesInvoiceReportActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SalesInvoiceReportActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case SalesInvoiceReportActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case SalesInvoiceReportActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesInvoiceReportActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SalesInvoiceReportActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case SalesInvoiceReportActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SalesInvoiceReportActions.SHOW_DATA_CUSTOMER:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataCus: action.payload, errorMessage: [],isProcessing:false }; 
    case SalesInvoiceReportActions.SHOW_DATA_FULL_SELLING:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataSelling: action.payload, errorMessage: [],isProcessing:false }; 
    case SalesInvoiceReportActions.SHOW_DATA_FULL_SO:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataSO: action.payload, errorMessage: [],isProcessing:false }; 
    case SalesInvoiceReportActions.SHOW_DATA_CUS_DETAIL:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataCusDetail: action.payload, errorMessage: [],isProcessing:false };   
    case SalesInvoiceReportActions.SHOW_DATA_INVOICE_DETAIL:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataInvoiceDetail: action.payload, errorMessage: [],isProcessing:false };   
    case SalesInvoiceReportActions.SHOW_DATA_ITEM:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataItem: action.payload, errorMessage: [],isProcessing:false };   
    case SalesInvoiceReportActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SalesInvoiceReportActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SalesInvoiceReportActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case SalesInvoiceReportActions.GET_HELPER_SALESINVOICEREPORT:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperSalesInvoiceReport:action.payload};
    case SalesInvoiceReportActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default SalesInvoiceReportReducer;

import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faBroom, faDisplay, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
const MySwal = withReactContent(Swal);
export class FormSalesInvoiceAllReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierList: [],
      supplierCurrent: null,
      idSupplier: null,
      supplierText: i18n.t('All'),
      branchList: [],
      branchCurrent: null,
      idBranch: null,
      branchText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      page: 1,
      show: 0,
      invoiceNo:null,
      customer:null,
      item:null,
      unit:null,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);

  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setData();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setData();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  setData() {
    var startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    var endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var invoiceNo = (this.state.invoiceNo == null) ? '' : this.state.invoiceNo;
    var customer = (this.state.page == customer) ? '' : this.state.customer;
    var item = (this.state.item == null) ? '' : this.state.item;
    var unit = (this.state.unit == null) ? '' : this.state.unit;

    let params = {
      startDate: startDate,
      endDate: endDate,
      limit: limit,
      idBranch: idBranch,
      page: page,
      customer:customer,
      invoiceNo:invoiceNo,
      item:item,
      unit:unit,
    }
    this.props.setData(params);
  }

  renderList() {
    const { data } = this.props
    this.state.listData = data;
    let row = data.data
    let html = [];
    let no = 1;
    let total = 0;
    if (data != undefined && data != null) {
      Swal.close();
      if (row.length > 0) {
        for (var p = 0; p < row.length; p++) {
          html.push(
            <tr className="border  " key={p}>
              <td className="text-center">{no++}</td>
              <td>{row[p].date}</td>
              <td>{row[p].invoice_no}</td>
              <td>{row[p].customer}</td>
              <td>{row[p].item_name}</td>
              <td style={{ textAlign: 'right' }}>{row[p].qty}</td>
              <td>{row[p].unit_name}</td>
              <td style={{ textAlign: 'right' }}>{row[p].unit_price}</td>
              <td style={{ textAlign: 'right' }}>{row[p].discount_price}</td>
              <td style={{ textAlign: 'right' }}> {row[p].tax_price}</td>
              <td style={{ textAlign: 'right' }}> {(parseFloat(row[p].total).toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}</td>
            </tr>)
          total += parseFloat(row[p].total) || 0;
        }
        html.push(
          <tr className="border  " >
            <td colSpan={'10'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

          </tr>)
      } else {
        html.push(
          <tr className="border  " >
            <td colSpan={'11'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
      return html;
    }
  }

  renderSearch() {
    return (
      <Fragment>
        <div className="row">
          <div className="row col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Invoice No."
              name={"invoiceNo"}
              title={"Invoice No."}
              labelfield={'true'}
              value={this.state.invoiceNo}
              handler={(e) => this.handler(e)}
            />
          </div>
          {/* <div className="col-sm-2">
           <DatePickerCustom
              name={"dateSearch"}
              title={i18n.t("Date")}
              selected={this.state.dateSearch}
              onSelect={async (e) => {
                        await this.handlerDateTime(e, 'dateSearch');
                      }}
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              peekNextMonth
              showMonthDropdown
              onChange={(e) => { }}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              className="form-control form-control-sm"
               ></DatePickerCustom>
          </div> */}
          <div className="row  col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Customer"
              name={"customer"}
              title={"Customer"}
              labelfield={'true'}
              value={this.state.customer}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="row col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Item Name"
              name={"item"}
              title={"Item Name"}
              labelfield={'true'}
              value={this.state.item}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="row  col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Unit"
              name={"unit"}
              title={"Unit"}
              labelfield={'true'}
              value={this.state.unit}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="col-sm-2 ">
            <Button
              variant="danger"
              className="btn btn-sm btn btn-info mt-8"
              onClick={async (e) => {
                await this.setState({ invoiceNo: '', customer: '', item: '', unit: '' })
              }}
            >
              <FontAwesomeIcon icon={faBroom}></FontAwesomeIcon>
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            <Button onClick={async (e) => {
              await this.setState({ page: 1 }, () => this.setData())
              await this.setState({ headeTable: true })
            }}
              variant="success" className="btn btn-md btn btn-info mt-4"><FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> Search </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/SalesInvoice-report/excel",
      method: "GET",
      params: {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        idSupplier: this.state.idSupplier,
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", 'Laporan Pembelian.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }, () => this.setData()
    )
  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (event.target.value == 0) {
      this.setState({ page: (get - 1) }, () => this.setData());
    } else if (event.target.value == (count - 1)) {
      this.setState({ page: (get + 1) }, () => this.setData());
    } else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }

  renderPagination() {
    const { data } = this.props;
    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '1', label: 'All' }
      this.state.show = 1;
    }
    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"] : ""}
                      {i == data.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  render() {
    const { isProcessing, helperSalesInvoiceReport, datalist, data } = this.props;
    this.state.branchList = helperSalesInvoiceReport.branch;
    this.state.listData = datalist;
    return (
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4" >
                  <div className="col-sm-4" style={{ fontSize: '20px' }}>
                    Period Date:
                  </div>
                  <div className="col-sm-3" style={{ fontSize: '20px' }}>
                    Branch:
                  </div>
                </div>
                <div className="row mb-8">
                  <div className="col-sm-2">
                    <DatePickerCustom
                      name={"startDate"}
                      title={i18n.t("")}
                      selected={this.state.startDate}
                      onSelect={async (e) => {
                        await this.handlerDateTime(e, 'startDate');
                      }}
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      peekNextMonth
                      showMonthDropdown
                      onChange={(e) => { }}
                      startDay={0}
                      autoComplete={"off"}
                      dateFormat="dd MMM yyyy"
                      className="form-control form-control-sm"
                    >
                    </DatePickerCustom>
                  </div>
                  {i18n.t("To")}
                  <div className="col-sm-2">
                    <DatePickerCustom
                      name={"endDate"}
                      title={i18n.t("")}
                      selected={this.state.endDate}
                      onSelect={async (e) => {
                        await this.handlerDateTime(e, 'endDate');
                      }}
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      peekNextMonth
                      showMonthDropdown
                      onChange={(e) => { }}
                      autoComplete={"off"}
                      dateFormat="dd MMM yyyy"
                      className="form-control form-control-sm"
                    ></DatePickerCustom>
                  </div>
                  <div className="col-sm-2">
                    <Select2
                      isClearable={true}
                      name={"idBranch"}
                      required={true}
                      size={4}
                      onChange={async (e) => {
                        if (e == null) {
                          await this.handlerSelect(e, "idBranch", "branchCurrent");
                        } else {
                          await this.handlerSelect(e, "idBranch", "branchCurrent");
                        }
                      }}
                      options={this.state.branchList}
                      title={i18n.t("Branch")}
                      value={this.state.branchCurrent}
                      placeholder={this.state.branchText}
                      labelfield={"false"}
                    />
                  </div>
                  <div className="col-sm-2">
                      <Button onClick={async (e) => {
                        await this.setState({ page: 1 }, () => this.setData())
                        await this.setState({ headeTable: true })
                      }}
                        variant="success" className="btn btn-md btn btn-success"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                    </div>
                </div>
                {this.state.headeTable == true ?
                  <React.Fragment>
                    {!isProcessing ?
                      <Fragment>
                        {/* <table className="table table-responsive table-bordered table-hover border  ">
                          <thead>
                            <tr className="text-center fw-semibold fs-6 text-gray-800  square ">
                              <th style={{ textAlign: 'left' }} width={"100%"} >
                                <p>
                                  <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> {i18n.t(" Advanced Search ")}
                                  </button>
                                </p>
                                <div className="collapse" id="collapseExample">
                                  <div className="card card-body">
                                    {this.renderSearch()}
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table> */}
                        <table className="table table-responsive table-bordered table-hover border  ">
                          <thead>
                            <tr className="fw-bold fs-6 text-gray-800 bg-aquamarine square border text-center ">
                              <th width={"3%"}>{i18n.t("No.")}</th>
                              <th width={"9%"}>{i18n.t("Date")}</th>
                              <th width={"10%"}>{i18n.t("Invoice No.")}</th>
                              <th width={"11%"} >{i18n.t("Customer Name")}</th>
                              <th width={"13%"}>{i18n.t("Item Name")}</th>
                              <th width={"7%"}>{i18n.t("Qty")}</th>
                              <th width={"7%"}>{i18n.t("Unit Name")}</th>
                              <th width={"8%"}>{i18n.t("Unit Price")}</th>
                              <th width={"7%"}>{i18n.t("Disc (Rp.)")}</th>
                              <th width={"7%"}>{i18n.t("Tax (Rp.)")}</th>
                              <th width={"10%"}>{i18n.t("Total")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderList()}
                          </tbody>
                        </table>
                      </Fragment>
                      : this.renderLoading()
                    }
                    {this.renderPagination()}
                  </React.Fragment> :
                  ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormSalesInvoiceAllReport;

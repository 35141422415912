import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { CashBank } from "../../../../components/modules/accounting/cash-bank";
import CashBankMiddleware from "../../../../middleware/modules/accounting/cash-bank";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import LogBgMiddleware from "../../../../middleware/modules/accounting/log-bg";
import LogBg from "../../../../components/modules/accounting/log-bg";
import LogBgIn from "../../../../components/modules/accounting/log-bg/index-in";
import LogBgOut from "../../../../components/modules/accounting/log-bg/index-out";
// middlewares

const mapStateToProps = ({ auth, cashbank, logBg }) => ({
  isProcessing: logBg.isProcessing,
  isError: logBg.isError,
  errorMessage: logBg.errorMessage,
  datalist: logBg.data,
  getHelperData: logBg.helperJournal,
  afterSave: logBg.afterSave,
  showModalOut:logBg.showModalOut
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      search: (data) => LogBgMiddleware.list(data),
      confirmOut: (data) => LogBgMiddleware.dataBGOut(data),
      rejectOut: (data) => LogBgMiddleware.rejectOut(data),
    },
    dispatch
  );
};
class LogBgOutContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,
  };

  static defaultProps = {
    afterSave:false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.confirmOut = this.confirmOut.bind(this);
    this.rejectOut = this.rejectOut.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
      key:'Out'
    };
    this.props.reset();
    // this.props.getHelper();
    this.props.search(search);
  }

  confirmOut(data) {
    this.props.confirmOut(data);
  }

  rejectOut(data) {
    this.props.rejectOut(data);
  }

  render() {
   
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Log BG Out List")}</title>
        </Helmet>
        <LogBgOut
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogBgOutContainer);

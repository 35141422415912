import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { WarehouseOut } from "../../../../components/modules/warehouse/warehouse-out";
// middlewares
import WarehouseOutMiddleware from "../../../../middleware/modules/warehouse/warehouse-out";
import ItemMiddleware from "../../../../middleware/modules/master/item";
import UnitMiddleware from "../../../../middleware/modules/master/unit";
import SupplierMiddleware from "../../../../middleware/modules/master/supplier";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";

const mapStateToProps = ({ auth, warehouseout }) => ({
  isProcessing: warehouseout.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist: warehouseout.data,
  showModal: warehouseout.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      cancel: (id, value, search) => WarehouseOutMiddleware.beforeCancel(id, value, search),
      create: (search) => WarehouseOutMiddleware.create(search),
      reset: () => WarehouseOutMiddleware.resetList(),
      search: (data) => WarehouseOutMiddleware.list(data),
      update: (data) => WarehouseOutMiddleware.update(data),
      getHelperFull: () => WarehouseOutMiddleware.getHelperFull(),
      delete: (id, value, search) =>
        WarehouseOutMiddleware.beforeDelete(id, search),
    },
    dispatch
  );
};

class WarehouseOutContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {},
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.cancelItem = this.cancelItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  cancelItem(id, value, search) {
    this.props.cancel(id, value, search);
  }
  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.update(id);
    this.props.getHelperFull();
  }
  createItem(search = null) {
    this.props.create(search);
    this.props.getHelperFull();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Warehouse Out List")}
          </title>
        </Helmet>
        <WarehouseOut
          {...this.props}
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
          cancelItem={this.cancelItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseOutContainer);

import {
  faBoxArchive,
  faBoxOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Modal, ModalBody, Table,FormLabel  } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import axios from "axios";
import InputNumber from "../../../utilities/Form/InputNumber";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import DropzoneCustom from "../../../utilities/Form/DropzoneCustom";
import CreatableSelect from "../../../utilities/Form/SelectCreate";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        NewsEvent: {},
        NewsEventDetail: [],
        removeFiles:[],
        image:[]
      },
      transactionNo: null,
      date: new Date(),
      branchList: [],
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      storeCurrent: null,
      storeList: [],
      unitList: [],
      itemList: [],
      images: [],
      defaultImage:[],
      status: 1,
      maxUploadFiles: 3,
      storeText: i18n.t("Select Store"),
      isUpdate:0,
      arrayDetail: [
        {
          unitText: i18n.t("Select Unit"),
          itemValue: null,
          unitValue: null,
          qty: 0,
          idWarehouse: null,
          description: "",
          qtyUnit: 1,
          unitLabel: '',
        },
      ],
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.updateDropzone = this.updateDropzone.bind(this);

  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  updateDropzone(files,param='new') {
    if(param=='delete'){
      const arr=[...this.state.form.removeFiles,files]
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          removeFiles: arr,
        },
      });
    }else{
      const realFiles=files.filter((file)=> file.ignore!==true);
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          images: realFiles,
        },
      });
    }
    
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    this.setState(arr);
  }
  calculateAll() {
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    let discountInvoice = parseFloat(this.state.discountHeader) || 0;
    const detail = this.state.arrayDetail;
    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].unitPrice) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].taxPrice) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }
    discountTotal += discountInvoice;
    grandTotal -= discountInvoice;
    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });
  }

  runSubmit() {
    const NewsEvent = {
      transactionNo: this.state.transactionNo,
      idBranch: (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value,
      idStore: (this.state.storeCurrent == null) ? '' : this.state.storeCurrent.value,
      doNo: this.state.documentNo,
      status: 1,
      date: moment(this.state.date).format('YYYY-MM-DD'),
      remove: (this.state.form.removeFiles == null) ? '' : this.state.form.removeFiles,
       

    };
    const detail = this.state.arrayDetail;
    let qty = [];
    let description = [];
    let idUnit = [];
    let idItem = [];
    let nameItem = [];

    for (var i = 0; i < detail.length; i++) {
      idItem.push(detail[i].idItem == null ? "" : (detail[i].idItem.helper == undefined)?"":detail[i].idItem.helper);
      nameItem.push(detail[i].idItem == null ? "" : detail[i].idItem.label);
      qty.push(detail[i].qty);
      idUnit.push(detail[i].idUnit == null ? "" : detail[i].idUnit.value);
      description.push(detail[i].description == null ? "" : detail[i].description);
    }
    this.state.form.NewsEvent = NewsEvent;
    this.state.form.NewsEventDetail = {
      qty: qty,
      description: description,
      idItem: idItem,
      idUnit: idUnit,
      nameItem: nameItem
    };
    // this.state.isUpdate = 0;
    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  handlerSelectArrayUnit(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr, () => this.calculateRow(i, arrayname));
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr, () => this.calculateRow(i, arrayname));
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderDetail(detail, i) {
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td >
            <CreatableSelect
              isClearable={true}
              name={"idItem"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelectArray(e, "idItem", i, "arrayDetail");
                  await this.handlerSelectArray(null, "idUnit", i, "arrayDetail");
                } else {
                  await this.handlerSelectArray(e, "idItem", i, "arrayDetail");
                }
              }}
              options={this.state.itemList}
              value={detail.idItem}
              placeholder={this.state.itemText}
              title={i18n.t("Item")}
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
            </div>
          </td>
          <td>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qty}
              required
              decimalsLimit={2}
              onValueChange={async (value, name) => {
                await this.handlerCurrencyArray(value, "qty", i, "arrayDetail")
              }}
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              title={i18n.t("Qty")}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("NewsEventDetail.qty." + i)}
            </div>
          </td>
          <td>
            <Select2
              isClearable={true}
              name={"idUnit"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectArray(e, "idUnit", i, "arrayDetail");
              }}
              options={this.state.unitList}
              value={detail.idUnit}
              placeholder={this.state.unitText}
              title={i18n.t("Unit")}
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("NewsEventDetail.idUnit." + i)}
            </div>
          </td>
          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td></td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>

          </td>
        </tr>
      </React.Fragment>
    );
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    }, () => this.calculateAll());
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-news-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reCalculate() {
    this.state.arrayDetail.map(async (detail, po) => {
      this.calculateRow(po, 'arrayDetail');
    })
  }

  arrayReset() {
    this.setState([
      this.state.arrayDetail = [
        {
          unitList: [],
          unitText: i18n.t("Select Unit"),
          itemList: [],
          itemText: i18n.t("Select Item"),
          idItem: null,
          qty: 0,
          description: "",
        },
      ],])
  }

  render() {
    const { data, isNewRecord, helperNewsEvent } = this.props;
    this.state.branchList = ((helperNewsEvent != null) ? helperNewsEvent.branch : [])
    this.state.itemList = ((helperNewsEvent != null) ? helperNewsEvent.item : [])
    this.state.unitList = ((helperNewsEvent != null) ? helperNewsEvent.unit : [])

    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.form.removeFiles = [] 
        this.state.transactionNo = data.transaction_no
        this.state.documentNo = (data.do_no == null) ? '' : data.do_no
        this.state.date = new Date(data.date)
        this.state.branchCurrent = {value: data.id_branch, label : data.name_branch}
        this.state.storeCurrent = (data.id_store == null) ? { value: '', label: '' } : { value: data.id_store, label: data.name_store }
        this.state.defaultImage=(data.image!=null)?data.image:[];
        if (data.id_branch != null) {
          this.changeData('storeList', 'getStore', '');
        }
        this.state.arrayDetail = [];
        
        for (var po = 0; po < data.news_detail.length; po++) {
          this.state.arrayDetail.push({
            idItem: {value:data.news_detail[po].item_id,label:data.news_detail[po].item_name,helper:data.news_detail[po].item_id},
            idUnit: {value:data.news_detail[po].unit_id,label:data.news_detail[po].unit_name},
            qty: data.news_detail[po].qty,
            description: data.news_detail[po].description,
          });
        }
      }
      this.state.isUpdate = 1;
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-2">
              <Input
                type={"input"}
                name={"transactionNo"}
                title={i18n.t("Transaction No")}
                required
                disabled
                value={this.state.transactionNo}
                handler={this.handler}
                error={this.renderError('NewsEvent.transactionNo')}
              />
            </div>
            <div className="col-2">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                onSelect={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData('transactionNo', "getCode", "code");
                  await this.changeData('tempDP', 'getDP', 'totalDP', null, null, () => this.setDp())
                }}
                onChange={(e) => { }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                className="form-control form-control-sm"
              />
            </div>

            <div className="col-2">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({
                      transactionNo: '', storeCurrent: null
                    })
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData('transactionNo', "getCode", "code");
                    await this.changeData('storeList', "getStore", "");

                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError('NewsEvent.idBranch')}
              />
            </div>
            <div className="col-2">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
               
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                  if (e == null) {
                    await this.handlerSelect(e, "idStore", "storeCurrent");

                  }
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
              />
            </div>
            <div className="col-2">
              <Input
                type={"input"}
                name={"documentNo"}
                title={i18n.t("Document No")}
                value={this.state.documentNo}
                handler={this.handler}
              />
            </div>
          </div>
          <div className="col-md-8">
            <FormLabel>
              {" "}
              {i18n.t("Upload Files")} ( {i18n.t("max")}{" "}
              {this.state.maxUploadFiles} {i18n.t("items")})
            </FormLabel>
            <DropzoneCustom
              maxFiles={this.state.maxUploadFiles}
              acceptFiles={{
                "image/jpeg": [],
                "image/png": [],
                "application/pdf": [],
              }}
              maxSize={1024000}
              changeFiles={this.updateDropzone}
              defaultFiles={this.state.defaultImage}
            ></DropzoneCustom>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th width="25%" className="text-center">
                      {i18n.t("Item")}
                    </th>
                    <th width="15%" className="text-center">
                      {i18n.t("Qty")}
                    </th>
                    <th width="20%" className="text-center">
                      {i18n.t("Unit")}
                    </th>
                    <th width="30%" className="text-center">
                      {i18n.t("Description")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              {this.state.soCurrent == null ?
                <Button
                  variant="success"
                  onClick={(e) =>
                    this.addArray(
                      {
                        itemValue: null,
                        unitValue: null,
                        qty: 0,
                        description: "",
                      },
                      "arrayDetail"
                    )
                  }
                >
                  {i18n.t("Add Item")}
                </Button> : ''}
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default Form;

import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import BillingActions from "../../../../reducers/modules/sales/billing/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import { param } from "jquery";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "sales/billing";

export default class BillingMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(BillingActions.create());
    };
  }


  static update(params) {
    return (dispatch) => {
      dispatch(BillingActions.update());
      BillingMiddleware.showOne(dispatch, params);
    };
  }

  static beforeClose(id, params) {
    return (dispatch) => {
      dispatch(BillingActions.closing());
      BillingMiddleware.closingData(dispatch, params, id);
    };
  }

  static beforeVoid(id, params) {
    return (dispatch) => {
      dispatch(BillingActions.void());
      BillingMiddleware.voidData(dispatch, params, id);
    };
  }

  static payment(params) {
    return (dispatch) => {
      dispatch(BillingActions.payment());
      BillingMiddleware.showPayBill(dispatch, params);
    };
  }

  static savePayBill(params) {
    return (dispatch) => {
      dispatch(BillingActions.update());
      BillingMiddleware.showOne(dispatch, params);
    };
  }

  static getSalesBilling(id, type) {
    return (dispatch) => {
      dispatch(BillingActions.beforeSalesBilling(type));
      BillingMiddleware.showSalesBilling(dispatch, id, type);
    };
  }

  static getRepaymentData(params){
    return (dispatch) => {
        dispatch(BillingActions.process());
        BillingMiddleware.showRepaymentData(dispatch, params);
      };
  }

  static beforeCancel(id, value, search) {
    const post = {
      id: id,
      value: value,
    };
      return (dispatch) => {
      dispatch(BillingActions.cancel());
      BillingMiddleware.saveCancel(dispatch, post, search);
    };
  }

  static listPayment(params) {
    return (dispatch) => {
      dispatch(BillingActions.process());
      BillingMiddleware.showlistPayment(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(BillingActions.process());
      BillingMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(BillingActions.reset());
    };
  }
  static resetBill() {
    return (dispatch) => {
      dispatch(BillingActions.resetBill());
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(BillingActions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(BillingActions.view());
      BillingMiddleware.showOne(dispatch, params);
    };
  }

  static closingData(dispatch, params, id) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/close/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, params)
          );
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Closing Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static voidData(dispatch, params, id, ) {
    console.log(params)
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/void/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlistPayment(dispatch, params)
          );
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static showRepaymentData(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BillingMiddleware.getToken()}`
        },
        url:process.env.REST_URL +'sales/billing/repayment',
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.listRepayment(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch,error)
      });
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(BillingActions.submit());
      BillingMiddleware.saveNew(dispatch, post, search);
    };
  }

  static savePayment(post, search) {
    return (dispatch) => {
      dispatch(BillingActions.submit());
      BillingMiddleware.savePay(dispatch, post, search);
    };
  }

  static beforePayment(params, post, id) {
    return (dispatch) => {
      dispatch(BillingActions.submit());
      BillingMiddleware.savePay(dispatch, params, post, id);
    };
  }

  static beforeSaveUpdate(post, search) {
    return (dispatch) => {
      dispatch(BillingActions.submit());
      BillingMiddleware.updateNew(dispatch, post, search);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(BillingActions.beforePrint());
      BillingMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(BillingActions.delete());
      BillingMiddleware.delete(dispatch, params, id);
    };
  }

  static setListBilling(params){
    return (dispatch) => {
        dispatch(BillingActions.process());
        BillingMiddleware.showBill(dispatch, params);
      };
  }

  static setIndex(params){
    return (dispatch) => {
        dispatch(BillingActions.process());
        BillingMiddleware.showIndex(dispatch, params);
      };
  }

  static setPayBill(params){
    return (dispatch) => {
        dispatch(BillingActions.process());
        BillingMiddleware.showPayBill(dispatch, params);
      };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      BillingMiddleware.showHelper(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showSalesBilling(dispatch, id, type) {
    let params = {
      type: type,
    };
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/list/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.afterSalesBilling(response.data.result));
        }
      })
      .catch((error) => {
        BillingActions.alertHendler(dispatch, error);
      });
  }

 
  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.list(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static showlistPayment(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "sales/payment-billing",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.listPayment(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveCancel(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/destroy/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, search)
          );
          dispatch(BillingActions.success());
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error, true);
      });
  }
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-billing",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showPrint(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/print/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.print(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/" + params  ,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.view(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/" + params  ,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.view(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, params)
          );
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static showBill(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${BillingMiddleware.getToken()}`
        },
        url:process.env.REST_URL +'sales/billing/list-bill',
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(BillingActions.listBill(response.data.result));
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch,error)
      });
}

static showIndex(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BillingMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'sales/billing/list-index',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(BillingActions.listIndex(response.data.result));
      }
    })
    .catch((error) => {
      BillingMiddleware.alertHendler(dispatch,error)
    });
}

static showPayBill(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BillingMiddleware.getToken()}`
      },
      url: url + "/pay-bill/" + params  ,
      responseType: 'json',
     
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(BillingActions.listPayBill(response.data.result));
      }
    })
    .catch((error) => {
      BillingMiddleware.alertHendler(dispatch,error)
    });
}

  static savePay(dispatch, post, id, search) {
    console.log(post)
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "sales/billing/payment-confirmation",
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          let params = {
            id:id
          }
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, search),
            BillingMiddleware.showlistPayment(dispatch, search)
          );
          dispatch(BillingActions.successPay());
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Payment Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url:process.env.REST_URL +'sales/billing/save',
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, search)
          );
          dispatch(BillingActions.success());
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Save Billing Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static updateNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url:process.env.REST_URL +'sales/billing/update',
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
          );
          dispatch(BillingActions.success());
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Billing Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${BillingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            BillingActions.process(),
            BillingMiddleware.showlist(dispatch, search)
          );
          dispatch(BillingActions.success());
          BillingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        BillingMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(BillingActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(BillingActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

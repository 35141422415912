export default class ReceivingActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_RECEIVING='GET_HELPER_RECEIVING';
    static GET_HELPER_RECEIVING_FULL ='GET_HELPER_RECEIVING_FULL';
    static BEFORE_PRINT_RECEIVING ='BEFORE_PRINT_RECEIVING';
    static PRINT_RECEIVING ='PRINT_RECEIVING';
    static RESET_LIST="RESET_LIST";
  
    static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
    static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'
    
    static submit(){
      return {
        type: ReceivingActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: ReceivingActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: ReceivingActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: ReceivingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: ReceivingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: ReceivingActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: ReceivingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: ReceivingActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: ReceivingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: ReceivingActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: ReceivingActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: ReceivingActions.GET_HELPER_RECEIVING,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  ReceivingActions.GET_HELPER_RECEIVING_FULL,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: ReceivingActions.BEFORE_PRINT_RECEIVING,
      };
    }
    static print(data){
      return {
        type: ReceivingActions.PRINT_RECEIVING,
        payload: data
      };
    }
    static saveRequestPrint(){
      return {
        type: ReceivingActions.ON_SAVE_REQUEST_PRINT,
      };
    }
    static limitPrint(){
      return {
        type: ReceivingActions.ON_LIMIT_PRINT,
      };
    }
  }
  
  
import CashFlowActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperCashFlow:[],
  errorInternal:false,
  printCashFlow:null,
};

function CashFlowReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CashFlowActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case CashFlowActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case CashFlowActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case CashFlowActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case CashFlowActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CashFlowActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CashFlowActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case CashFlowActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case CashFlowActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case CashFlowActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case CashFlowActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case CashFlowActions.GET_HELPER_CASHFLOW:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCashFlow:action.payload};
    case CashFlowActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case CashFlowActions.BEFORE_PRINT_CASHFLOW:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printCashFlow:null,isProcessing:true};
    case CashFlowActions.PRINT_CASHFLOW:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printCashFlow:action.payload,isProcessing:false};
       
    default:
      return state;
    }
}

export default CashFlowReducer;

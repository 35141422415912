import React, { Component, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import {  Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
// import React from 'react';
import FullCalendar from '@fullcalendar/react' ;
import dayGridPlugin from '@fullcalendar/daygrid' ;
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from '@fullcalendar/multimonth';
import swal from "sweetalert";
import { WeekNumberContainer } from "@fullcalendar/core/internal";
const MySwal = withReactContent(Swal);
export class WorkCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        
      startDate: new Date('2024-01-01'),
      search: {
        limit: -1,
      },
      data:[],
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Master"), class: " text-gray-600" },
        { link: null, title: i18n.t("Work Calendar"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    const views = {
      multiMonthFourMonth: {
        type: 'multiMonth',
        duration: { months: 2 }
      }
    };
    this.calendarComponent = React.createRef();
  }
  
  setDate(){
    var startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    var limit = this.state.search.limit;
    let params = {
      startDate: startDate,
      limit :limit,
    }
    this.props.setDate(params);
  }
  handlerDateTime(e,name) {
    var key=name;
    var value=e
    this.setState({
        [key]:value
    },()=>{
      this.search()
    })
}
handlerSelect(e,name,currentState) {
  var key=name
  var value=null
  if(e!=null){
      value=e.value
  }
  this.setState({
      [key]:value,
      [currentState]:e
  },()=>{
    this.search()
  })
}
  handler(e,id) {
    var value=e.target.value
    this.setState({
        ...this.state, 
        absence: {
            ...this.state.absence,
            [id]:value
        } 
    },()=>{
      let post={
        value:value,
      }
      this.props.updateAbsence(post,id)})
  }
  search(){
    let search={
      date:moment(this.state.startDate).format('YYYY-MM-DD'),
      // branch:this.state.idBranch
    }
    // this.props.search(search);
  }
  renderList(){
    const {data}=this.props

    if(data.data !=undefined && data.data !=null){
      let html=[];
      let no=1;
      let row = data.data
     
      for(var p=0; p <row.length; p++){
        html.push(
          <tr >
            <td className="text-center">{no++}</td>
            <td >{row[p].week}</td>
            <td>{row[p].startDate_format}</td>
            <td>{row[p].endDate_format}</td>
            <td>{row[p].year}</td>
            <td>{row[p].status_due}</td>
          </tr>)
      }
      return html;
    }

  }
  runSearch() {
    this.search();
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  handleDateClick = (arg) => { // bind with an arrow function
    let date = moment(arg.date).format('YYYY-MM-DD')
    this.setState({
      startDate:new Date(date)
    })
  }

 

  renderCalendar(){
    const {data,helperList,isProcessing}=this.props
    {isProcessing?this.renderLoading():Swal.close()}
    this.state.data = [];
    let row=[];
    if(data.data !=undefined && data.data !=null){
    row = data.data
    }else{
    row = helperList;
    }
    return (
      <FullCalendar
          ref={this.calendarComponent}
          plugins={[ multiMonthPlugin, interactionPlugin,dayGridPlugin ]}
          initialView="multiMonthYear"
          customButtons={{
            dayGridYear:{
              text:i18n.t("List Week"),
              click: () => {
                const calendarApi = this.calendarComponent.current.getApi();
                calendarApi.changeView('dayGridYear');
              }
            },
          }}
          headerToolbar={{
            left:'prev,next',
            center:"title", 
            right: "multiMonthYear,dayGridMonth,dayGridYear"
          }}
          dateClick={this.handleDateClick}
          events={row.map((item,i)=>(
              { 
                id:i, 
                title:i18n.t('Week') + ' - ' +item.week,
                start: new Date(item.startDate),
                end:new Date(item.endDate),
                backgroundColor:((item.week % 2)===0)?'#378006':'#00BFFF',
                overlap:false
              }
        ))}
        
        eventOverlap={false}
        slotEventOverlap={false}
        fixedWeekCount={false}
        // showNonCurrentDates={true}
        displayEventTime={false}
        selectable
        firstDay={0}
        multiMonthMaxColumns={3}
        
      />
    )
  }

  
  render() {
    const { isProcessing,helperList,data} = this.props;
    
    return (
      <div id="salary-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4">
                  <div className="col-sm-3">
                    <DatePickerCustom
                        name={"startDate"}
                        title={i18n.t("First Date")}
                        required={true}
                        selected={this.state.startDate}
                        onSelect={async (e)=>{
                                await this.handlerDateTime(e,'startDate');
                            }
                        }
                        onChange={(e)=>{}}
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        autoComplete={"off"}
                        dateFormat="dd MMMM yyyy"
                        className="form-control form-control-sm"
                        ></DatePickerCustom>
                    </div>
                    <div className="col-sm-3">
                        <Button onClick={(e)=>{
                      this.setDate();
                     }}
                        variant="success" className="btn btn-sm btn btn-success mt-8 ">Generate</Button>
                    </div>
                    
                    {/* <div className="col text-end">
                     <Button onClick={(e)=>{
                      this.search();
                     }} variant="warning" className="btn btn-sm btn btn-warning mt-4"><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon></Button>
                    </div> */}
                </div>
                     
                    {this.renderCalendar()} 
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}

export default WorkCalendar;

import React, { Component } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import InputNumber from "../../../utilities/Form/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faBoxArchive,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TextArea from "../../../utilities/Form/TextArea";
import FormConvertion from "./formConvertion";
import InputCustom from "../../../utilities/Form/InputCustom";
import i18n from "../../../../i18n";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionNo:'',
      branchValue:null,
      branchText:i18n.t("Select Branch"),
      branchList:[],
      loadingBranch:false,
      storeValue:null,
      storeText:i18n.t("Select Store"),
      storeList:[],
      loadingStore:false,
      salesValue:null,
      salesText:i18n.t("Select Sales"),
      salesList:[],
      loadingSales:false,
      description:'',
      date:new Date(),
      itemList:[],
      itemText:"Select Item",
      warehouseValue:null,
      warehouseText:i18n.t("Select Warehouse"),
      warehouseList:[],
      loadingWarehouse:false,
      salesTransaction:null,
      spvname:'',
      spvid:null,
      status: 1,
     
      arrayDetail: [
        {
          itemValue:null,
          qty:0,
          description:'',
          convertionDetail:[]
        },
      ],
      isUpdate: 0,
    };
    this.formConvertion = React.createRef(),
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  
  runSubmit() {
    const {arrayDetail} =this.state
    const Taking={
      date:moment(this.state.date).format("YYYY-MM-DD"),
      transactionNo:this.state.transactionNo,
      idBranch:(this.state.branchValue !=null)?this.state.branchValue.value:'',
      idStore:(this.state.storeValue !=null)?this.state.storeValue.value:'',
      idSales:(this.state.salesValue !=null)?this.state.salesValue.value:'',
      idSupervisor:this.state.spvid,
      idWarehouse:(this.state.warehouseValue !=null)?this.state.warehouseValue.value:'',//this id setup warehouse
      idDriver:(this.state.driverValue !=null)?this.state.driverValue.value:'',
      idVehicle:(this.state.vehicleValue !=null)?this.state.vehicleValue.value:'',
      description:this.state.description
    }
    let item=[]
    let qty=[]
    let unit=[]
    let description=[]
    let convertionDetail=[]
    for(let o=0;o < arrayDetail.length;o++){
      if(arrayDetail[o].itemValue !=null){
        item.push(arrayDetail[o].itemValue.value)
        qty.push(arrayDetail[o].qty)
        // unit.push(arrayDetail[o])
        description.push(arrayDetail[o].description)
        convertionDetail.push(arrayDetail[o].convertionDetail)
      }
      
    }
    const post={
      TakingGoods:Taking,
      TakingDetails:{
        idItem:item,
        qty:qty,
        description:description,
        convertionDetail:convertionDetail,
      }
    }
    this.state.isUpdate = 0;
    return post;
  }

  //CONVERTION
  setConvertion(detail, index, array, key) {
    if(detail.itemValue !=null){
      let choose = {
        unitList: detail.itemValue.unit,
        qty:detail.itemValue.lastStok,
        convertionDetail: detail.convertionDetail,
        index: index,
        key: key,
        array: array,
      };
      this.setState({ itemChoose: choose, modalDetail: true });
    }
    
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        console.log(result)
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td width={"500px"}>
            <Select2
              isClearable={true}
              name={"idItem"}
              title={'Item'}
              required={true}
              labelfield={"false"}
              placeholder={this.state.itemText}
              onChange={async (e) => {
                if(detail.itemValue != e){
                  if(e !=null){
                    let check=this.state.arrayDetail.filter((item)=> item.itemValue===e)
                    if(check.length == 0){ 
                      await this.handlerSelectArray(e,"itemId",i,"arrayDetail","itemValue")
                    }else{
                      await this.handlerSelectArray(null,"itemId",i,"arrayDetail","itemValue")
                    }
                  }else{
                    await this.handlerSelectArray(e,"itemId",i,"arrayDetail","itemValue")
                  }
                }
              }}
              options={this.state.itemList}
              value={detail.itemValue}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("WHOutDetail.idItem." + i)}
            </div>
          </td>
          <td width={"250px"}>
            <div className="row">
              <div className="col-9 p-0">
                <InputNumber
                  id={"qty" + i}
                  name="qty"
                  labelfield={"false"}
                  required={true}
                  defaultValue={0}
                  value={(detail.itemValue !=null)?detail.itemValue.lastStok:0}
                  decimalsLimit={2}
                  disabled={true}
                  suffix={(detail.itemValue !=null)?' '+detail.itemValue.currentUnit:''}
                  onValueChange={(value, name) => {
                  }}
                  className="form-control  form-control-sm text-end"
                  error={this.renderError("WHOutDetail.lasStock." + i)}
                />
              </div>
            </div>
          </td>
          <td width={"250px"}>
            <div className="row">
              <div className="col-9 p-0">
                <InputNumber
                  id={"qty" + i}
                  name="qty"
                  labelfield={"false"}
                  required={true}
                  defaultValue={0}
                  value={detail.qty}
                  decimalsLimit={2}
                  disabled={true}
                  onValueChange={(value, name) => {
                  }}
                  suffix={(detail.itemValue !=null)?' '+detail.itemValue.currentUnit:''}
                  className="form-control  form-control-sm text-end"
                  error={this.renderError("WHOutDetail.qty." + i)}
                />
              </div>
              {detail.itemValue != null ? (
                <div className="col p-0">
                  <Button
                    variant={
                      detail.convertionDetail.length > 0 ? "warning" : "info"
                    }
                    className="btn p-2"
                    onClick={(e) => {
                      this.setConvertion(detail, i, "arrayDetail", "qty");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        detail.convertionDetail.length > 0
                          ? faBoxOpen
                          : faBoxArchive
                      }
                    />
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </td>
          <td>
            <input
              className="form-control  form-control-sm "
              labelfield={"false"}
              name={"description"}
              id={"description" + i}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            />
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-taking-goods";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != null
            ? this.state.branchValue.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
        case "getStore":
          var url = process.env.REST_URL + "helper/get-store";
          params = {
            branch: this.state.branchValue !=null?this.state.branchValue.value:null,
          };
          break;
        case "getWarehouse":
          var url = process.env.REST_URL + "helper/get-setup-warehouse-full";
          params = {
            branch: this.state.branchValue !=null?this.state.branchValue.value:null,
            store: this.state.storeValue !=null?this.state.storeValue.value:null,
          };
          break;
        case "getEmployee":
          var url = process.env.REST_URL + "helper/get-employee";
          if(this.state.branchValue==null){
            type=null;
          }
          params = {
            branch: this.state.branchValue !=null?this.state.branchValue.value:null,
            store: this.state.storeValue !=null?this.state.storeValue.value:null,
          };
          break;
        case "getSupervisor":
          var url = process.env.REST_URL + "helper/get-supervisor";
          if(this.state.salesValue==null){
            type=null;
          }
          params = {
            id: this.state.salesValue !=null?this.state.salesValue.value:null,
          };
          break;

        case "getTransaction":
          var url = process.env.REST_URL + "helper/get-taking-return";
          if(this.state.salesValue==null){
            type=null;
          }
          params = {
            id: this.state.salesValue !=null?this.state.salesValue.value:null,
          };
          break;    
    
        case "getItem":
          var url = process.env.REST_URL + "helper/get-item-by-setupwh";
          params = {
            id: this.state.warehouseValue !=null?this.state.warehouseValue.value:null,
            branch: this.state.branchValue !=null?this.state.branchValue.value:null,
            store: this.state.storeValue !=null?this.state.storeValue.value:null,
          };
          break;

      case "getDetailItemStock":
        var itemPR = this.state.itemPR;
        var branch =
          this.state.branchOriValue != null
            ? this.state.branchOriValue.value
            : null;
        var store =
          this.state.storeOriValue != null
            ? this.state.storeOriValue.value
            : null;
        var warehouse =
          this.state.idOriginalWHValue != null
            ? this.state.idOriginalWHValue.helper
            : null;
        var subwarehouse =
          this.state.idOriginalWHValue != null
            ? this.state.idOriginalWHValue.helper2
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var bandno =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.bandno
            : null;
        var idk =
          this.state.idBranchDestination != null
            ? this.state.idBranchDestination
            : null;
        var iditem =
          this.state[array][arraykey].itemValue != null
            ? this.state[array][arraykey].itemValue.item
            : null;
          
        if (itemPR) {
          var url = process.env.REST_URL + "helper/get-detail-item-stock-recap";
          params = {
            warehouse: warehouse,
            subwarehouse: subwarehouse,
            store: store,
            branch: branch,
            date: date,
            item: iditem,
            bandno:bandno
          };
        } else {
          var url = process.env.REST_URL + "helper/get-detail-item-stock";
          params = {
            warehouse: warehouse,
            subwarehouse: subwarehouse,
            store: store,
            branch: branch,
            date: date,
            item: iditem,
            bandno:bandno
          };
        }
        break;

      case "getStockItem":
        var itemPR = this.state.itemPR;
        if (itemPR) {
          var idk =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.idTemp
            : null;
        params = {
          id: idk,
        };
          var url = process.env.REST_URL + "helper/get-stock-item-recap";
        } else {
          var idk =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.value
            : null;
        params = {
          id: idk,
        };
          var url = process.env.REST_URL + "helper/get-stock-item";
        }
        

        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  checkValidate(){
    if(this.state.salesTransaction!=null){
      console.log('masuk sini')
      this.alertError('Info','Have a Taking Goods Already, Cant Make New Taking Goods')
      this.setState({salesTransaction:'',spvid:'',spvname:'',salesValue:'',idSales:''})
    }
  }
  
  render() {
    const { data, isNewRecord, helperTakingGoods } = this.props;
    console.log('props',this.props)
    console.log('state',this.state)
    this.state.branchList =
      helperTakingGoods != null ? helperTakingGoods.branch : [];

    if(data != null && !isNewRecord){
      if (this.state.isUpdate == 0) {
          this.state.salesValue = {value:data.id_sales , label:data.name_sales}
          this.state.branchValue = {value:data.id_branch , label:data.name_branch}
          this.state.storeValue = {value:data.id_store , label:data.name_store}
          this.state.warehouseValue = {value:data.id_setup_warehouse , label:data.name_warehouse +' - '+data.name_sub_warehouse}
          if(data.id_branch != null){
              this.changeData({
              keyFill:"storeList",
              type:"getStore",
              callback:()=>{this.setState({loadingStore:false})}
            })
              this.changeData({
              keyFill:"salesList",
              type:"getEmployee",
              callback:()=>{this.setState({loadingSales:false})}
            })
          
              this.changeData({
              keyFill:"warehouseList",
              type:"getWarehouse",
              callback:()=>{this.setState({loadingWarehouse:false})}
            })

            this.changeData({
              keyFill:"itemList",
              type:"getItem",
              callback:()=>{this.setState({loadingItem:false})}
            })
          }
          this.state.date = new Date(data.date)
          this.state.spvid = data.id_spv
          this.state.spvname = data.name_spv
          this.state.transactionNo = data.transaction_no
          this.state.description = data.description == null ? "" : data.description
          this.state.arrayDetail = [];
        if(data.details != null){
        for (var po = 0; po < data.details.length; po++) {

          this.state.arrayDetail.push({
            itemValue: {
              value: data.details[po].id_item,
              label: data.details[po].code_item+' - '+data.details[po].name_item + ' ('+ data.details[po].bandNo+')',
            },
            qty:data.details[po].qty,
            convertionDetail:data.details[po].convertion_detail
           
          });
        }
      }
        this.state.isUpdate = 1;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={false}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData({
                    keyFill:"transactionNo",
                    type:"getCode",
                    key:"code"
                  });
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                endDate={new Date()}
                className="form-control form-control-sm"
                error={this.renderError("TakingGoods.date")}
              />
            </div>

            <div className="col-md-3">
              <Select2 
                  isClearable={true}
                  name={'idBranch'}
                  required={true}
                  className={""}
                  size={4}
                  onChange={async(e)=>{
                    if(this.state.branchValue !=e){
                      await this.handlerSelect(e,"idBranch","branchValue")
                      if(e !=null){
                        await this.changeData({
                          keyFill:"transactionNo",
                          type:"getCode",
                          key:"code"
                        });
                        await this.setState({loadingStore:true,loadingSales:true,loadingSupervisior:true,loadingWarehouse:true})
                        await this.changeData({
                          keyFill:"storeList",
                          type:"getStore",
                          callback:()=>{this.setState({loadingStore:false})}
                        })
                        await this.changeData({
                          keyFill:"salesList",
                          type:"getEmployee",
                          callback:()=>{this.setState({loadingSales:false})}
                        })
                      
                        await this.changeData({
                          keyFill:"warehouseList",
                          type:"getWarehouse",
                          callback:()=>{this.setState({loadingWarehouse:false})}
                        })
                      }else{
                        await this.setState({
                          warehouseList:[],
                          supervisiorList:[],
                          salesList:[],
                          storeList:[],
                          warehouseValue:null,
                          supervisiorValue:null,
                          salesValue:null,
                          storeValue:null
                        })
                      }
                      
                    }
                  }}
                    
                  options={this.state.branchList}
                  title={i18n.t("Branch")}
                  value={this.state.branchValue}
                  placeholder={this.state.branchText}
                  error={this.renderError('TakingGoods.idBranch')}
              />
            </div>
            <div className="col-md-3">
              <Select2 
                  isClearable={true}
                  name={'idStore'}
                  className={""}
                  size={4}
                  isLoading={this.state.loadingStore}
                  onChange={async (e)=>{
                    
                        await this.handlerSelect(e,"idStore","storeValue")
                        if(e !=null){
                          await this.changeData({
                            keyFill:"transactionNo",
                            type:"getCode",
                            key:"code"
                          });
                          await this.setState({warehouseValue:null,loadingSales:true,loadingSupervisior:true,loadingWarehouse:true})
                      
                          await this.changeData({
                            keyFill:"salesList",
                            type:"getEmployee",
                            callback:()=>{this.setState({loadingSales:false})}
                          })
                          
                          await this.changeData({
                            keyFill:"warehouseList",
                            type:"getWarehouse",
                            callback:()=>{this.setState({loadingWarehouse:false})}
                          })
                        
                        }else{
                          await this.changeData({
                            keyFill:"warehouseList",
                            type:"getWarehouse",
                            callback:()=>{this.setState({loadingWarehouse:false})}
                          })
                          await this.changeData({
                            keyFill:"salesList",
                            type:"getEmployee",
                            callback:()=>{this.setState({loadingSales:false})}
                          })
                          await this.setState({
                            warehouseList:[],
                            supervisiorList:[],
                            salesList:[],
                            warehouseValue:null,
                            supervisiorValue:null,
                            salesValue:''
                          })
                        }
                      
                    }}
                  options={this.state.storeList}
                  title={i18n.t("Store")}
                  value={this.state.storeValue}
                  placeholder={this.state.storeText}
                  error={this.renderError('TakingGoods.idStore')}
              />
            </div>
            <div className="col-md-3">
              <Input
                disabled={true}
                type={"input"}
                name={"transactionNo"}
                required={true}
                title={i18n.t("Document No.")}
                value={this.state.transactionNo}
                handler={this.handler}
                error={this.renderError("TakingGoods.transactionNo")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
                <Select2 
                    isClearable={true}
                    name={'idSales'}
                    required={true}
                    className={""}
                    size={4}
                    isLoading={this.state.loadingSales}
                    onChange={async(e)=>{
                        await this.handlerSelect(e,"idSales","salesValue")
                        await this.changeData({
                          keyFill:"spvid",
                          type:"getSupervisor",
                          callback:(data)=>{
                            this.setState({spvid:data.idSupervisor,spvname:data.supervisorName})
                          }
                        })
                        await this.changeData({
                          keyFill:'salesTransaction',
                          type:"getTransaction",
                          callback:(data)=>{
                            this.setState({salesTransaction:data},()=>this.checkValidate())
                          }
                        })
                        
                      }
                    }
                    options={this.state.salesList}
                    title={i18n.t("Sales")}
                    value={this.state.salesValue}
                    placeholder={this.state.salesText}
                    error={this.renderError('TakingGoods.idSales')}
                />
              </div>
              <div className="col-md-3">
                <Input 
                   disabled={true}
                   required={true}
                   type={"input"}
                   name={"Supervisior"}
                   title={i18n.t("Supervisior")}
                   value={this.state.spvname}
                   handler={()=>{}}
                   error={this.renderError("TakingGoods.idSupervisior")}
                />
              </div>
              <div className="col-md-3">
                <Select2 
                    isClearable={true}
                    name={'idWarehouse'}
                    required={true}
                    className={""}
                    size={4}
                    isLoading={this.state.loadingWarehouse}
                    onChange={async(e)=>{
                      if(this.state.warehouseValue !=e){
                        await this.handlerSelect(e,"idWarehouse","warehouseValue")
                        if(e !=null){
                          await this.setState({loadingItem:true,arrayDetail:[ {
                            itemValue:null,
                            qty:0,
                            description:'',
                            convertionDetail:[]   
                          }],itemList:[]})
                          await this.changeData({
                            keyFill:"itemList",
                            type:"getItem",
                            callback:()=>{this.setState({loadingItem:false})}
                          })
                        }else{
                          this.setState({itemList:[],arrayDetail:[{
                            itemValue:null,
                            qty:0,
                            description:'',
                            convertionDetail:[]   
                          }]})
                        }
                      }
                      
                    }
                    }
                    options={this.state.warehouseList}
                    title={i18n.t("Warehouse")}
                    value={this.state.warehouseValue}
                    placeholder={this.state.warehouseText}
                    error={this.renderError('TakingGoods.idWarehouse')}
                />
              </div>
              
          </div>
          <div className="row">
            <div className="col-6">
              <TextArea
                type={"input"}
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description}
                handler={this.handler}
              />
            </div>
            
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-left">{i18n.t("Item")}</th>
                    <th className="text-left">{i18n.t("Stock")}</th>
                    <th className="text-left">{i18n.t("Qty")}</th>
                    <th className="text-left">{i18n.t("Description")}</th>
                    <th className="text-left">{i18n.t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      itemValue:null,
                      qty:0,
                      description:'',
                      convertionDetail:[]   
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Details")}
              </Button>
            </div>
          </div>
        </form>
        <Modal show={this.state.modalDetail} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConvertion
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formConvertion}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveConvertion(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.saveConvertion(true)}
            >
              {i18n.t("Convertion")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;

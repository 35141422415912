import {  Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        paddingTop: 30,
        paddingBottom: 50,
    },
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        // paddingTop: "10px",
        // border:'1px',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "100px",
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
    rowHeader: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    rowFooter: {
        flexDirection: 'row',
        marginRight:'20px',
    },
    colFooter: {
        flexDirection: 'column',
        marginRight:'20px',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        borderBottom: '1px',
        borderTop: '1px',
        flexDirection: 'row',
    },
    trHeader: {
        backgroundColor: 'rgba(255, 255, 128)',
        // borderBottom: '1px',
        flexDirection: 'row',
    },
    td: {
        // borderRight: '1px',
        borderLeft: '1px',
        flexDirection: 'column',
    },
    tdcol: {
        colspan: 2,
        borderLeft: '1px',
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    titleHeader: {
        flexDirection: 'row',
        marginBottom: '10px'
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },

    titleTranx: {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'left',
        marginBottom: '5px'
    },
    title: {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        marginBottom: '5px'
    },

    div: {
        width: '100%',
        height: 400,
        border: '1 solid black'
        // fontSize: "15px",
        // fontWeight: 700,
        // fontFamily: 'Times-Bold',
        // textAlign: 'center',
        // marginBottom:'5px'
    },

    tableTitle: {
        borderTop: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        // padding: '5px',
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        border: '2px'
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },
    //Dalam Table
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "12px",
    },

    totalBottom: {
        textAlign: 'right',
        fontFamily: 'Times-Bold',
        paddingRight: '1px',
        paddingTop: '2px',
        fontSize: "8px",
    },


    fillText: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "10px",
        paddingTop: '1px'

    },


    fillTextType: {
        textAlign: 'center',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fill: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    

    fillNumber: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
    },

    fillNumberBold: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
        fontFamily: 'Times-Bold',
    },


    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 30,
        textAlign: 'right',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintWorkSheet extends Component{
    constructor(props){
        super(props)
        this.state={
            title:i18n.t("Laporan Neraca Lajur"),
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:[],
            required:0,
            total:0,
        };
    }
    renderMessage(){
        const {isProcessing, requestPrint}=this.props
        switch(true){
            case isProcessing:
                return (
                <div style={loader}>
                    <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
                    <div style={{fontSize:36}}>Getting Data From Server</div>
                </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{fontSize:36}}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={()=>{this.props.getPrint(this.props.id)}}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderMenu(){
        html.push(
           
        );
        return html;
    }
    render(){
        const {dataPrint}=this.props
        let total=0
        var totalAll=0
        let pageNumber = 1;
       
        let address=[]
        if(dataPrint!=null){
            let row =  dataPrint.data;
            this.state.data = dataPrint.data;
            if(this.state.required == 0){
                this.state.total = dataPrint.data[0];
                this.state.data.shift();
                this.state.required = 1;
            }
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
           
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title}>
                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer} >
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map((a,i)=>
                                                <Text key={'add'+i} style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col} >
                                            <View style={styles.title}>
                                                <Text>{i18n.t("Laporan Neraca Lajur")}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.startDate + ' - ' + dataPrint.endDate}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.branch}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.titleContainer,{marginTop:'10px'}]}>
                                    <View fixed style={styles.tableTitle}  >
                                        <View style={styles.trHeader}>
                                            <Fragment key={'menuHeader'}>
                                                <View style={[styles.td,{width:'6%'}]}>
                                                    <Text style={styles.menuHeader}>{"Code"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'28%'}]}>
                                                    <Text style={styles.menuHeader}>{"COA Name"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%'}]}>
                                                    <Text style={styles.menuHeader}>{"Beginning Debt"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%',}]}>
                                                    <Text style={styles.menuHeader}>{"Beginning Credit"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%'}]}>
                                                    <Text style={styles.menuHeader}>{"Debt"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%'}]}>
                                                    <Text style={styles.menuHeader}>{"Credit"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%'}]}>
                                                    <Text style={styles.menuHeader}>{"Ending   Debt"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'11%',borderRight:'1px'}]}>
                                                    <Text style={styles.menuHeader}>{"Ending Credit"}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>
                                   
                                     <View style={styles.titleContainer}>
                                        <View  style={styles.tableTitle}>
                                            
                                            {this.state.data != null ?
                                             this.state.data.map((row,i)=>{
                                                return(
                                                    <Fragment key={'data' + i} >
                                                      <View style={styles.tr}>
                                                          <Fragment >
                                                              <View style={[styles.td,{width:'6%'}]}  wrap={false}>
                                                                  <Text style={styles.fillText}>{row.acc_id}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'28%'}]}>
                                                                  <Text style={styles.fillText}>{row.acc_name}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%'}]}>
                                                                  <Text style={styles.fillNumber}> 
                                                                    {(row.position == 'Debt' && row.beginning_balance >= 0)?row.beginning_balance_format:
                                                                     (row.position == 'Credit' && row.beginning_balance < 0)?row.beginning_diff_format:'0.00'}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%'}]}>
                                                                  <Text style={styles.fillNumber}> 
                                                                    {(row.position == 'Credit' && row.beginning_balance >= 0)?row.beginning_balance_format:
                                                                    (row.position == 'Debt' && row.beginning_balance < 0)?row.beginning_diff_format:'0'}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%'}]}>
                                                                  <Text style={styles.fillNumber}>{row.debt_format}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%'}]}>
                                                                  <Text style={styles.fillNumber}>{row.credit_format}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%'}]}>
                                                                  <Text style={styles.fillNumber}>
                                                                    {(row.position == 'Debt' && row.ending >= 0 )?row.ending_format:
                                                                    (row.position == 'Credit' && row.ending < 0)?row.ending_diff_format:'0'}</Text>
                                                              </View>
                                                              <View style={[styles.td,{width:'11%',borderRight:'1px'}]}>
                                                                  <Text style={styles.fillNumber}>
                                                                    {(row.position == 'Credit' && row.ending >= 0)?row.ending_format:
                                                                    (row.position == 'Debt' && row.ending < 0)?row.ending_diff_format:'0'}</Text>
                                                              </View>

                                                            </Fragment>
                                                        </View>
                                                    </Fragment>

                                                );
                                            }):null}
                                            <View style={styles.tr}>
                                        <Fragment >
                                            <View style={[styles.tdcol,{width:'34%'}]} >
                                                <Text style={styles.menuHeader} >Total</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.beginningDebt}</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.beginningCredit}</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.debt}</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.credit}</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.endingDebt}</Text>
                                            </View>
                                            <View style={[styles.td,{width:'11%',borderRight:'1px'}]}>
                                                <Text style={styles.totalBottom}>{this.state.total.endingCredit}</Text>
                                            </View>                
                                        </Fragment>
                                    </View>
                                    </View>
                                   
                                </View>
                                {/* <View style={[styles.titleContainer,{marginTop:'10px'}]}>
                                    <View  style={styles.tableTotal}>
                                        <Fragment >
                                            <View style={styles.rowFooter}>
                                                <View style={[styles.titleTranx]} >
                                                    <Text style={styles.fill}>SUMMARY</Text>
                                                </View>
                                            </View>
                                            <View style={styles.rowFooter}>
                                                <View style={[styles.colFooter,{width:'15%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Awal Debt</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.colFooter,{width:'15%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Awal Credit</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.colFooter,{width:'15%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Debt</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.colFooter,{width:'15%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Credit</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.colFooter,{width:'15%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Akhir Debt</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.colFooter,{width:'20%'}]} >
                                                    <View style={[styles.titleTranx]} >
                                                        <Text style={styles.fill}>Total Akhir Credit</Text>
                                                    </View>
                                                </View>
                                                
                                            </View>
                                            {this.state.total != null?
                                            <Fragment>
                                                <View style={styles.rowFooter}>
                                                    <View style={[styles.colFooter,{width:'15%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.beginningDebt}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.colFooter,{width:'15%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.beginningCredit}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.colFooter,{width:'15%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.debt}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.colFooter,{width:'15%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.credit}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.colFooter,{width:'15%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.endingDebt}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={[styles.colFooter,{width:'20%'}]} >
                                                        <View style={[styles.titleTranx]} >
                                                            <Text style={styles.fill}>{this.state.total.endingCredit}</Text>
                                                        </View>
                                                    </View>
                                                    
                                                </View>
                                            </Fragment>
                                            :null }
                                        </Fragment>
                                    </View>
                                </View> */}
                            </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                `Page ${pageNumber} of ${totalPages}`
                            )} fixed />
                        </Page>
                    </Document>
            </PDFViewer>
            :
            this.renderMessage()}
            
        </React.Fragment>   
        
        )
    }
}

export default PrintWorkSheet;

const loader={
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection:'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}

import React from "react";
import i18n from "../../../i18n";
import { statusMemoIn } from "../Const/StatusJournal";

    const LabelActiveJournal=(props)=>{
        const filter=statusMemoIn.filter((item)=>item.key===props.value);
        if(filter.length==1){
            return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
        }   
        return ''
    }
export default LabelActiveJournal;
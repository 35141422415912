import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import PrintStockCard from "../../../components/utilities/print/default/stock-card";
// middlewares
import AuthMiddleware from "../../../reducers/auth/middleware";
import MutationMiddleware from "../../../middleware/modules/mutation/mutation";

const mapStateToProps = ({ auth, mutasi }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: mutasi.isProcessing,
  dataPrint: mutasi.printStockCard,
  requestPrint:mutasi.requestPrint
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (data) => MutationMiddleware.getPrint(data),
    },
    dispatch
  );
};

class StockCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.data);
  }

  render() {
    return <PrintStockCard {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockCardContainer);

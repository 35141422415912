import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import Swal from "sweetalert2";
import Form from "../../../../components/modules/log/customer-approval/form-view";
import { Button, Modal } from "react-bootstrap";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import CustomerApprovalMiddleware from "../../../../middleware/modules/log/customer-approval";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const mapStateToProps = ({ auth, customerApproval }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: customerApproval.isProcessing,
  isError: customerApproval.isError,
  errorMessage: customerApproval.errorMessage,
  dataUpdate: customerApproval.single,
  isSuccess: customerApproval.isSuccess,
  isReset: customerApproval.isReset,
  isNewRecord: customerApproval.isNewRecord,
  onSubmit: customerApproval.onSubmit,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => CustomerApprovalMiddleware.reset(),
      approveModal: (id, value) =>
        CustomerApprovalMiddleware.beforeApprove(id, value),
      rejectModal: (id, value) =>
        CustomerApprovalMiddleware.beforeReject(id, value),
    },
    dispatch
  );
};
class FormContainerView extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.close = this.close.bind(this);
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
  }
  close() {
    this.props.closeModal();
  }
  approve() {
    const { dataUpdate } = this.props;
    const html = (
      <>
        <div>{i18n.t("Approve This Request")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.approveModal(dataUpdate.id, value);
              }
            });
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  reject() {
    const { dataUpdate } = this.props;

    const html = (
      <>
        <div>{i18n.t("Reject This Request")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.rejectModal(dataUpdate.id, value);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  render() {
    const {
      customerHelperFull,
      onSubmit,
      showModal,
      titleModal,
      isReset,
      dataUpdate,
      isProcessing,
      isError,
      errorMessage,
      cityList,
      storeList,
      isNewRecord,
    } = this.props;
    return (
      <>
        <Modal show={showModal} size={"fullscreen"} enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
            <title>{titleModal}</title>
          </Helmet>
          <Modal.Body>
            <Form
              isNewRecord={isNewRecord}
              isReset={isReset}
              data={dataUpdate}
              isProcessing={isProcessing}
              isError={isError}
              errorMessage={errorMessage}
              ref={this.form}
              cityList={cityList}
              storeList={storeList}
              {...this.props}
              customerHelperFull={customerHelperFull}
              changeSelect={this.changeSelect}
            />
          </Modal.Body>
          {(dataUpdate)?<Modal.Footer className={"flex-center"}>
            <Button variant="warning" onClick={() => this.close(false)}>
              {i18n.t("Cancel")}
            </Button>
            <Button variant="danger" onClick={() => this.reject()}>
              {i18n.t("Reject")}
            </Button>

            <Button variant="success" onClick={() => this.approve()}>
              {i18n.t("Approve")}
            </Button>
          </Modal.Footer>:null}
          
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainerView);

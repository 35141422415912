import React, { Component } from "react";
import { Button, Table, Modal, FormLabel } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import InputNumber from "../../../utilities/Form/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faBoxArchive,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TextArea from "../../../utilities/Form/TextArea";
import FormConvertion from "./formConvertion";
import DropzoneCustom from "../../../utilities/Form/DropzoneCustom";
import i18n from "../../../../i18n";

const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        WHInDetail: [],
      },
      dataOut: [],
      itemUses: [],
      idDestinationWH: null,
      idBranchDestination: null,
      idStoreDestination: null,
      branchValue: null,
      doNoList: [],
      doNoValue: null,
      deliveryService: "",
      doNo: null,
      vehicleNo: "",
      date: new Date(),
      description: "",
      wiNo: null,
      idWarehouseOut: null,
      status: 1,
      images: [],
      arrayDetail: [
        {
          idItem: null,
          idDestinationWHValue: null,
          idDestinationWHList: [],
          idDestinationWH: null,
          idPRDetail: null,
          unitPrice: 0,
          unit: "",
          qtySend: 0,
          description: null,
          itemValue: null,
          unitValue: null,
          disabled: false,
          qtyReceive: null,
          convertionDetail: [],
        },
      ],
      isUpdate: 0,
      dateCheck: 0,
    };
    (this.formConvertion = React.createRef()),
      (this.handler = this.handler.bind(this));
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.updateDropzone = this.updateDropzone.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      ...this.state,
      [key]: value
    })
    this.setState({ [currentState]: e })
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }

  updateDropzone(files) {
    this.setState({ images: files });
  }
  runSubmit() {
    const images = this.state.images;
    let date = moment(this.state.date).format("YYYY-MM-DD");
    const WHIn = {
      idBranchDestination:
        this.state.doNoValue == null ? "" : this.state.doNoValue.helper,
      idStoreDestination:
        this.state.doNoValue == null ? "" : this.state.doNoValue.helper2,
      idWarehouseOut:
        this.state.doNoValue == null ? "" : this.state.doNoValue.value,

      deliveryService: this.state.deliveryService,
      doNo: this.state.doNoValue == null ? "" : this.state.doNoValue.label,
      vehicleNo: this.state.vehicleNo,
      date: date,
      wiNo: this.state.wiNo,
      description: this.state.description,
      status: 1,
      images: images.length > 0 ? images : null,
    };

    const WHInDetail = this.state.arrayDetail;
    let idItem = [];
    let idPRDetail = [];
    let idWarehouseIn = [];
    let idWarehouse = [];
    let idSubWarehouse = [];
    let idDestinationWH = [];
    let qtyReceive = [];
    let description = [];
    let convertionList = [];
    let qtySend = [];

    for (var i = 0; i < WHInDetail.length; i++) {
      idItem.push(WHInDetail[i].idItem == null ? "" : WHInDetail[i].idItem);
      idDestinationWH.push(
        WHInDetail[i].idDestinationWH == null
          ? ""
          : WHInDetail[i].idDestinationWH
      );
      idPRDetail.push(
        WHInDetail[i].idPRDetail == null ? "" : WHInDetail[i].idPRDetail
      );
      idWarehouseIn.push(
        WHInDetail[i].idWarehouseIn == null ? "" : WHInDetail[i].idWarehouseIn
      );

      qtyReceive.push(
        WHInDetail[i].qtyReceive == null ? "" : WHInDetail[i].qtyReceive
      );
      qtySend.push(WHInDetail[i].qtySend == null ? "" : WHInDetail[i].qtySend);
      idWarehouse.push(
        WHInDetail[i].idWarehouse == null ? "" : WHInDetail[i].idWarehouse
      );
      idSubWarehouse.push(
        WHInDetail[i].idSubWarehouse == null ? "" : WHInDetail[i].idSubWarehouse
      );

      description.push(
        WHInDetail[i].description == null ? "" : WHInDetail[i].description
      );
      convertionList.push(
        WHInDetail[i].convertionDetail == null
          ? ""
          : WHInDetail[i].convertionDetail
      );
    }
    this.state.form.WHIn = WHIn;
    this.state.form.WHInDetail = {
      idItem: idItem,
      idWarehouse: idWarehouse,
      idSubWarehouse: idSubWarehouse,
      idPRDetail: idPRDetail,
      idWarehouseIn: idDestinationWH,
      description: description,
      qty: qtyReceive,
      qtySend: qtySend,
      convertionDetail: convertionList,
    };
    this.state.isUpdate = 0;
    return this.state.form;
  }

  //CONVERTION
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.qtySend,
      convertionDetail: detail.convertionDetail,
      index: index,
      key: key,
      arrlength: this.state.arrayDetail.length,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  setItem(i) {
    let arr = [...this.state.arrayDetail];
    const objectss = arr[i];
    objectss['qtySend'] = parseInt(arr[i].itemValue.stock)
    objectss['bandNo'] = parseInt(arr[i].itemValue.bandNo)
    // objectss['suffix'] = arr[i].itemValue.currentUnit
    // objectss['bandNo'] = arr[i].itemValue.no_band
    this.setState(arr)

  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td width={"500px"}>
            <Select2
              isClearable={true}
              title={i18n.t("id")}
              name={"idItem"}
              labelfield={"false"}
              onChange={async (e) => {

                var valueBefore = detail.itemValue;
                if (e != null) {
                  if (e != valueBefore) {
                    if (valueBefore != null) {
                      var index = this.state.itemUses.indexOf(
                        valueBefore.value
                      );
                      if (index !== -1) {
                        this.state.itemUses.splice(index, 1);
                      }
                    }
                    if (this.state.itemUses.includes(e.value)) {
                      this.alertError("Item Use", "Items Are Used", "info");
                    } else {
                      this.state.itemUses.push(e.value);
                      await this.handlerSelectArray(
                        e,
                        "idItem",
                        i,
                        "arrayDetail",
                        "itemValue"
                      );
                      await this.setItem(i);
                      await this.changeData({
                        keyFill: "idDestinationWHList",
                        type: "getSetupWarehouse",
                        key: "",
                        // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                      })
                      await this.changeData({
                        keyFill: "unit",
                        type: "getUnitWarehouseTF",
                        key: "",
                        array: "arrayDetail",
                        arraykey: i
                        // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                      })
                      await this.changeData({
                        keyFill: "idPRDetail",
                        type: "getStockItemIn",
                        key: "idPRDetail",
                        array: "arrayDetail",
                        arraykey: i
                        // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                      })
                    }
                  }
                } else {
                  if (valueBefore != null) {
                    var index = this.state.itemUses.indexOf(valueBefore.value);
                    if (index !== -1) {
                      this.state.itemUses.splice(index, 1);
                    }
                  }
                  await this.handlerSelectArray(
                    e,
                    "idItem",
                    i,
                    "arrayDetail",
                    "itemValue"
                  );
                  await this.handlerCurrencyArray(
                    0,
                    "qtyReceive",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(
                    [],
                    "convertionDetail",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(
                    0,
                    "qtySend",
                    i,
                    "arrayDetail"
                  );
                }
              }}
              required={true}
              isDisabled={this.state.doNoValue == null ? true : false}
              options={this.state.doNoValue == null ? "" : this.state.itemList}
              value={this.state.doNoValue == null ? "" : detail.itemValue}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("WHInDetail.idItem." + i)}
            </div>
          </td>
          <td width={"200px"}>
            <InputNumber
              id={"qtySend" + i}
              name="qtySend"
              labelfield={"false"}
              defaultValue={0}
              value={detail.qtySend}
              decimalsLimit={2}
              disabled={true}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "qtySend", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
            />
          </td>
          <td width={"200px"}>
            <div className="row">
              <div className="col-9 p-0">
                <InputNumber
                  id={"qtyReceive" + i}
                  name="qtyReceive"
                  labelfield={"false"}
                  defaultValue={0}
                  value={detail.qtyReceive}
                  decimalsLimit={2}
                  disabled={true}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "qtyReceive",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                />
              </div>
              {detail.qtySend > 0 && detail.itemValue != null ? (
                <div className="col p-0">
                  <Button
                    variant={detail.length > 0 ? "warning" : "info"}
                    className="btn p-2"
                    onClick={(e) => {
                      this.setConvertion(
                        detail,
                        i,
                        "arrayDetail",
                        "qtyReceive"
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={detail.length > 0 ? faBoxOpen : faBoxArchive}
                    />{" "}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("WHInDetail.qty." + i)}
            </div>
          </td>
          <td width={"300px"}>
            <Select2
              isClearable={true}
              name={"idDestinationWH"}
              labelfield={"false"}
              title={i18n.t("id")}
              required={true}
              onChange={async (e) => {
                await this.handlerSelectArray(
                  e,
                  "idDestinationWH",
                  i,
                  "arrayDetail",
                  "idDestinationWHValue"
                );
                await this.changeData({
                  keyFill: "idWarehouse",
                  type: "getWHDes",
                  key: "idWarehouse",
                  array: "arrayDetail",
                  arraykey: i
                  // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                })
                await this.changeData({
                  keyFill: "idSubWarehouse",
                  type: "getWHDes",
                  key: "idSubWarehouse",
                  array: "arrayDetail",
                  arraykey: i
                  // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                })
              }}
              isDisabled={this.state.doNoValue == null ? true : false}
              options={
                this.state.doNoValue == null
                  ? ""
                  : this.state.idDestinationWHList
              }
              value={
                this.state.doNoValue == null ? "" : detail.idDestinationWHValue
              }
            />

            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("WHInDetail.idWarehouseIn." + i)}
            </div>
          </td>

          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description ?? ''}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData({ keyFill, type = null, key = "", array = null, arraykey = null, callback = () => { } } = props) {
    const token = cookieManager.getUserToken()
    let params = null
    switch (type) {
      case "getCodeWI":
        var url = process.env.REST_URL + "helper/get-wh-in-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var idWh =
          this.state.doNoValue != undefined ? this.state.doNoValue.value : null;

        params = {
          date: date,
          idWh: idWh,
        };
        break;
      case "getSetupWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var branch =
          this.state.doNoValue != undefined
            ? this.state.doNoValue.helper
            : null;
        var store =
          this.state.doNoValue != undefined
            ? this.state.doNoValue.helper2
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;
      case "getListDoc1":
        this.state.doNoList = [];
        this.state.doNoValue = null;
        var url = process.env.REST_URL + "helper/get-list-doc1";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          date: date,
        };
        break;
      case "getListDoc":
        this.state.doNoList = [];
        this.state.doNoValue = null;
        var url = process.env.REST_URL + "helper/get-list-doc";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;

        break;
      case "getDetailItemStockIn":
        var url = process.env.REST_URL + "helper/get-detail-item-stock-in";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var idtrans =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.id_wh_out
            : null;
        var idtransDet =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.id_wh_detail
            : null;
        params = {
          date: date,
          idTr: idtrans,
          idTrDet: idtransDet,
        };
        break;
      case "getUnitWarehouseTF":
        var url = process.env.REST_URL + "helper/get-unit-warehousetf";
        var item =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].itemValue.value
            : null;
        var type = 'in';
        params = {
          id: item,
          type: type
        };
        break;

      case "getWHDes":
        var url = process.env.REST_URL + "helper/get-wh-des";
        var item =
          this.state[array][arraykey] != null
            ? this.state[array][arraykey].idDestinationWH
            : null;
        params = {
          id: item,
        };
        break;
      case "getItemStockIn":
        this.state.itemUses = [];
        this.state.arrayDetail = [
          {
            idItem: null,
            idDestinationWHValue: null,
            idDestinationWHList: [],
            idDestinationWH: null,
            idPRDetail: null,
            unitPrice: 0,
            unit: "",
            qtySend: 0,
            description: null,
            itemValue: null,
            unitValue: null,
            disabled: false,
            qtyReceive: null,
            convertionDetail: [],
          },
        ];
        var url = process.env.REST_URL + "helper/get-item-stock-in";
        var idk =
          this.state.doNoValue != null ? this.state.doNoValue.value : null;
        params = {
          idTr: idk,
        };
        break;

      case "getDetailItemStock":
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchOriValue != null
            ? this.state.branchOriValue.value
            : null;
        var store =
          this.state.storeOriValue != null
            ? this.state.storeOriValue.value
            : null;
        for (var po = 0; po < this.state.arrayDetail.length; po++) {
          var url = process.env.REST_URL + "helper/get-detail-item-stock";
          var idk =
            this.state.arrayDetail[po] != null
              ? this.state.arrayDetail[po].idItem
              : null;
          params = {
            date: date,
            id: idk,
            store: store,
            branch: branch,
          };
        }
        break;

      case "getStockItemIn":

        var url = process.env.REST_URL + "helper/get-stock-item-in";
        var idk = this.state.doNoValue != null ? this.state.doNoValue.value : null;
        params = {
          id: idk,
        };

        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse = null
            if (array != null) {
              var arr = [...this.state[array]];

              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse = response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse = response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse = response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse = response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () => callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  setForm() {
    let data = this.state.dataOut;
    if (data != null) {
      this.setState({
        vehicleNo: data.vehicleNo,
        deliveryService: data.deliveryService
      })
    }
  }

  render() {
    const { helperWarehouseIn } = this.props;
    if (this.state.dateCheck == 0 && helperWarehouseIn != null) {

      this.changeData({
        keyFill: "doNoList",
        type: "getListDoc",
        key: "",
        // callback: () => { this.setState({ warehouseOriListLoading: false }) }
      })
      this.state.dateCheck = 1;
    }

    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-2">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={false}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.setState({ doNoList: [], doNoValue: "", wiNo: "", vehicleNo: "", deliveryService: "", itemUses: [] });
                  await this.changeData({
                    keyFill: "doNoList",
                    type: "getListDoc1",
                    key: "",
                    // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                  })

                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-3">
              <Select2
                isClearable={true}
                name={"doNo"}
                required={true}
                onChange={async (e) => {
                  await this.setState({ vehicleNo: "", deliveryService: "", wiNo: "", itemUses: [] });

                  if (e == null) {
                    await this.handlerSelect(e, "doNo", "doNoValue");
                  } else {
                    await this.handlerSelect(e, "doNo", "doNoValue");

                    await this.changeData({
                      keyFill: "dataOut",
                      type: "getStockItemIn",
                      key: "",
                      callback: () => this.setForm()
                    })
                    await this.changeData({
                      keyFill: "wiNo",
                      type: "getCodeWI",
                      key: "code",
                      // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                    })
                    await this.changeData({
                      keyFill: "itemList",
                      type: "getItemStockIn",
                      key: "",
                      // callback: () => { this.setState({ warehouseOriListLoading: false }) }
                    })
                  }

                }}
                options={this.state.doNoList}
                title={i18n.t("Document No.")}
                value={this.state.doNoValue}
                isDisabled={this.state.date == null ? true : false}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("WHIn.doNo")}
              </div>
            </div>

            <div className="col-md-2">
              <Input
                type={"input"}
                name={"wiNo"}
                title={i18n.t("Receipt No.")}
                value={this.state.wiNo ?? ''}
                handler={this.handler}
              />
            </div>
            <div className="col-md-2">
              <Input
                type={"input"}
                name={"vehicleNo"}
                required={true}
                title={i18n.t("Plate No.")}
                disabled={true}
                value={this.state.vehicleNo ?? ''}
                handler={this.handler}
                error={this.renderError("WHOut.vehicleNo")}
              />
            </div>
            <div className="col-md-2">
              <Input
                disabled={true}
                type={"input"}
                required={true}
                name={"deliveryService"}
                title={i18n.t("Expedition")}
                value={this.state.deliveryService ?? ''}
                handler={this.handler}
                error={this.renderError("WHOut.deliveryService")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextArea
                type={"input"}
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description ?? ''}
                handler={this.handler}
              />
            </div>
            <div className="col-6">
              <FormLabel> {i18n.t("Upload Files")} ( {i18n.t("max")} {this.state.maxUploadFiles} {i18n.t("items")})</FormLabel>
              <DropzoneCustom
                maxFiles={this.state.maxUploadFiles}
                acceptFiles={{
                  "image/jpeg": [],
                  "image/png": [],
                  "application/pdf": [],
                }}
                maxSize={1024000}
                changeFiles={this.updateDropzone}
              ></DropzoneCustom>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-left">{i18n.t("Item")}</th>
                    <th className="text-left">{i18n.t("Qty Sent")}</th>
                    <th className="text-left">{i18n.t("Qty Received")}</th>
                    <th className="text-left">{i18n.t("Warehouse")}</th>
                    <th className="text-left">{i18n.t("Description")}</th>
                    <th className="text-left">{i18n.t("Action")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      idItem: null,
                      idDestinationWHValue: null,
                      idDestinationWHList: [],
                      idDestinationWH: null,
                      idPRDetail: null,
                      unitPrice: 0,
                      unit: "",
                      qtySend: 0,
                      description: null,
                      itemValue: null,
                      unitValue: null,
                      disabled: false,
                      qtyReceive: null,
                      convertionDetail: [],
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Details")}
              </Button>
            </div>
          </div>
        </form>
        <Modal show={this.state.modalDetail} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConvertion
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formConvertion}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveConvertion(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.saveConvertion(true)}
            >
              {i18n.t("Convertion")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;

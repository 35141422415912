import { faMonument, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { Component, Fragment } from "react";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";
import cookieManager from "../../../../utils/cookieManager";
import Select2 from "../../../utilities/Form/Select2";
import TextArea from "../../../utilities/Form/TextArea";
import InputNumber from "../../../utilities/Form/InputNumber";
import i18n from "../../../../i18n";
import DropzoneCustom from "../../../utilities/Form/DropzoneCustom";
import { Button, FormLabel, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from 'google-map-react';

const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectProvince: React.createRef(),
      form: {
        latitude:'',
        longitude:'',
        name: "",
        idType: "",
        code: "",
        address: "",
        idBranch: "",
        idStore: "",
        idProvince: "",
        idCity: "",
        phone: "",
        email: "",
        idNumber: "",
        taxStatus: 0,
        npwp: "",
        contactPerson: "",
        contactPersonPhone: "",
        top: "",
        invoiceLimit: "",
        creditLimit: "",
        status: 1,
        paymentType: "",
        images: [],
        comps: [],
      },
      latitude:'',
      longitude:'',
      map:0,
      maxUploadFiles: 3,
      dataHierarchy: [],
      images: [],
      top: null,
      suffixTop: "",
      suffixIL: "",
      suffixCL: "",
      invoiceLimit: null,
      creditLimit: null,
      payment: null,
      paymentTypeCurrent: null,
      provinceCurrent: null,
      branchCurrent: null,
      cityCurrent: null,
      storeCurrent: null,
      typeCurrent: null,
      provinceText: i18n.t("Select Province"),
      branchText: i18n.t("Select Branch"),
      storeText: i18n.t("Select Store"),
      cityText: i18n.t("Select City"),
      typeText: i18n.t("Select Type Customer"),
      isUpdate: 0,
      check: 0,
      arrayDetail: [
        {
          itemName: "",
          sellingPrice: "",
          omset: "",
          topDetail: "",
          invoiceLimitDetail: "",
          creditLimitDetail: "",
          description: "",
        },
      ],
    };
    this.handler = this.handler.bind(this);
    this.handlerCurrency = this.handlerCurrency.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.updateDropzone = this.updateDropzone.bind(this);
  }

  handlerCurrency(value, name) {
    var key = name;
    var value = value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
    this.props.changeSelect(name, value);
  }
  runSubmit() {
    const detail = this.state.arrayDetail;
    let itemName = [];
    let sellingPrice = [];
    let omset = [];
    let topDetail = [];
    let invoiceLimitDetail = [];
    let creditLimitDetail = [];
    let description = [];
    let status = [];

    for (var i = 0; i < detail.length; i++) {
      itemName.push(detail[i].itemName ? detail[i].itemName : '' );
      sellingPrice.push(detail[i].sellingPrice ? detail[i].sellingPrice : '');
      omset.push(detail[i].omset ? detail[i].omset : '');
      topDetail.push(detail[i].topDetail ? detail[i].topDetail : '');
      creditLimitDetail.push(detail[i].creditLimitDetail ? detail[i].creditLimitDetail : '');
      invoiceLimitDetail.push(detail[i].invoiceLimitDetail ? detail[i].invoiceLimitDetail : '');
      description.push(detail[i].description ? detail[i].description : '');
      status.push(1);
    }
    this.state.form.comps = {
      itemName: itemName,
      sellingPrice: sellingPrice,
      omset: omset,
      topDetail: topDetail,
      creditLimitDetail: creditLimitDetail,
      invoiceLimitDetail: invoiceLimitDetail,
      description: description,
      status: status,
    };
    return this.state.form;
  }

  updateDropzone(files) {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        images: files,
      },
    });
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td width={"300px"}>
            <Input
              type={"input"}
              name={"itemName"}
              title={"name item"}
              labelfield={"false"}
              handler={(e) =>
                this.handlerInputArray(e, "itemName", i, "arrayDetail")
              }
              value={detail.itemName}
            />
          </td>
          <td>
            <InputNumber
              id={"sellingPrice"}
              name={"sellingPrice"}
           
              labelfield={"false"}
              value={detail.sellingPrice}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(
                  value,
                  "sellingPrice",
                  i,
                  "arrayDetail"
                )
              }
              prefix={"Rp. "}
              className="form-control  form-control-sm text-end"
            />
          </td>
          <td>
            <Input
              id={"omset"}
              name={"omset"}
              labelfield={"false"}
              value={detail.omset}
              handler={(e) =>
                this.handlerInputArray(e, "omset", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
            />
          </td>
          <td>
            <InputNumber
              id={"topDetail"}
              name={"topDetail"}
              labelfield={"false"}
              value={detail.topDetail}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "topDetail", i, "arrayDetail")
              }
              suffix={" " + this.state.suffixTop}
              className="form-control  form-control-sm "
            />
          </td>
          <td>
            <InputNumber
              id={"invoiceLimitDetail"}
              name={"invoiceLimitDetail"}
              title={i18n.t("Invoice Limit")}
              labelfield={"false"}
              value={detail.invoiceLimitDetail}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(
                  value,
                  "invoiceLimitDetail",
                  i,
                  "arrayDetail"
                )
              }
              suffix={" " + this.state.suffixIL}
              className="form-control  form-control-sm "
            />
          </td>
          <td>
            <InputNumber
              id={"creditLimitDetail"}
              name={"creditLimitDetail"}
              title={i18n.t("Credit Limit")}
              labelfield={"false"}
              value={detail.creditLimitDetail}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(
                  value,
                  "creditLimitDetail",
                  i,
                  "arrayDetail"
                )
              }
              suffix={
                this.state.suffixCL != "Rp." ? " " + this.state.suffixCL : ""
              }
              prefix={
                this.state.suffixCL == "Rp." ? this.state.suffixCL + " " : ""
              }
              className="form-control  form-control-sm "
            />
          </td>
          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  changeData(keyFill, type = null, key = "", array = null, arraykey = null) {
    let params = null;
    switch (type) {
      case "getSetupHierarchy":
        var url = process.env.REST_URL + "helper/get-setup-hierarchy";
        var id =
          this.state.typeCurrent != undefined
            ? this.state.typeCurrent.value
            : null;
        params = {
          idType: id,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array == null) {
              var arr = [];
              const objectss = arr[arraykey];
              if (key == "") {
                arr = response.data.result;
                this.state.dataHierarchy = response.data.result;
                this.dataFill(arr);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  dataFill(arr) {
    if (arr.length > 0 && this.state.form.paymentType == "Credit") {
      this.setState({
        top: "",
        suffixTop: "",
        invoiceLimit: "",
        creditLimit: "",
        suffixCL: "",
        suffixIL: "",
      });
      this.state.form.top = "";
      this.state.form.invoiceLimit = "";
      this.state.form.creditLimit = "";
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].idHierarchy == 2) {
          this.setState({ top: arr[i].value ? arr[i].value : "" });
          this.setState({ suffixTop: arr[i].label ? arr[i].label : "" });
          this.state.form.top = arr[i].value ? arr[i].value : "";
        } else if (arr[i].idHierarchy == 3) {
          this.setState({ creditLimit: arr[i].value ? arr[i].value : "" });
          this.setState({ suffixCL: arr[i].label ? arr[i].label : "" });
          this.state.form.creditLimit = arr[i].value ? arr[i].value : "";
        } else if (arr[i].idHierarchy == 4) {
          this.setState({ invoiceLimit: arr[i].value ? arr[i].value : "" });
          this.setState({ suffixIL: arr[i].label ? arr[i].label : "" });
          this.state.form.invoiceLimit = arr[i].value ? arr[i].value : "";
        }
      }
    } else {
      this.setState({
        top: "",
        suffixTop: "",
        invoiceLimit: "",
        creditLimit: "",
        suffixCL: "",
        suffixIL: "",
      });
      this.state.form.top = "";
      this.state.form.invoiceLimit = "";
      this.state.form.creditLimit = "";
    }
  }

  renderMarkers(map, maps) {
    let marker = new maps.Marker({
        position: { lat: this.state.latitude, lng: this.state.longitude },
        map,
        draggable: true
    });
    marker.addListener('dragend', (e) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
            }
        })
    }
    )
}

  render() {
    const { customerHelperFull, data, isNewRecord, cityList, storeList,getGeo } =
      this.props;
    const options = [
      { value: "Cash", label: i18n.t("Cash") },
      { value: "Credit", label: i18n.t("Credit") },
    ];

    if (this.state.map == 0) {
      this.state.latitude = getGeo.length > 0 ?getGeo[0].latitude:1;
      this.state.longitude =  getGeo.length > 0 ?getGeo[0].longitude:1;
      this.state.map = 1;
  }
    this.state.typeList = customerHelperFull != null ? customerHelperFull.type : [];
    this.state.provinceList =
    customerHelperFull != null ? customerHelperFull.province : [];
    this.state.cityList = cityList != null ? cityList : [];
    this.state.branchList = customerHelperFull != null ? customerHelperFull.branch : [];
    this.state.storeList = storeList != null ? storeList : [];

    if (this.state.check == 0) {
      this.state.paymentTypeCurrent = { value: "Cash", label: i18n.t("Cash") };
      this.state.form.paymentType = "Cash";
      this.state.check = 1;
    }

    if (storeList != null && storeList.length == 0) {
      this.state.storeCurrent = null;
      this.state.form.idStore = "";
    }
    if (cityList != null && cityList.length == 0) {
      this.state.cityCurrent = null;
      this.state.form.idCity = "";
    }

    if (isNewRecord && customerHelperFull != null) {
      this.state.form.code = customerHelperFull.codeTemp;
    }
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.form = {
          name: data.name,
          idType: data.type_id,
          code: data.code,
          address: data.address,
          idBranch: data.branch_id,
          idStore: data.store_id,
          idProvince: data.province_id,
          idCity: data.city_id,
          phone: data.phone,
          email: data.email,
          idNumber: data.id_number,
          taxStatus: data.tax_status,
          npwp: data.npwp,
          contactPerson: data.contact_person,
          contactPersonPhone: data.contact_person_phone,
          top: data.top,
          invoiceLimit: data.invoice_limit,
          creditLimit: data.credit_limit,
          status: data.status,
          paymentType: data.paymentType,
          longBusiness:data.longBusiness,
         
        };
        this.state.arrayDetail = [];
        for (var po = 0; po < data.competitor.length; po++) {
          this.state.arrayDetail.push({
          itemName : data.competitor[po].itemName,
          sellingPrice : data.competitor[po].sellingPrice,
          omset : data.competitor[po].omset,
          topDetail : data.competitor[po].top,
          invoiceLimitDetail : data.competitor[po].invoiceLimit,
          creditLimitDetail : data.competitor[po].creditLimit,
          description : data.competitor[po].description
          });
        }

        this.state.paymentTypeCurrent = {
          value: data.paymentType,
          label: data.paymentType,
        };

        this.state.provinceCurrent = {
          value: data.province_id,
          label: data.province != "" ? data.province : this.state.provinceText,
        };
        this.state.cityCurrent = {
          value: data.city_id,
          label: data.city != "" ? data.city : this.state.cityText,
        };
        this.state.storeCurrent = {
          value: data.store_id,
          label: data.store != "" ? data.store : this.state.storeText,
        };
        this.state.branchCurrent = {
          value: data.branch_id,
          label: data.branch_name != "" ? data.branch_name : this.state.branchText,
        };
        this.state.typeCurrent = {
          value: data.type_id,
          label: data.type != "" ? data.type : this.state.typeText,
        };
        this.state.isUpdate = 1;
        this.state.top = data.top;
        this.state.invoiceLimit = data.invoice_limit;
        this.state.creditLimit = data.credit_limit_format;
        this.state.suffixTop = data.top_label;
        this.state.suffixIL = data.invoice_label;
        this.state.suffixCL = data.credit_label;
        if(data.latitude !=null){
          this.state.latitude = parseFloat(data.latitude)||0;
        }
        if(data.longitude){
          this.state.longitude =  parseFloat(data.longitude)||0;
        }
        this.props.changeSelect("idBranch", data.branch_id);
        this.props.changeSelect("idProvince", data.province_id);
      }
    }

    return (
      <form>
        <div className="row">
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              onChange={(e) =>
                this.handlerSelect(e, "idBranch", "branchCurrent")
              }
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchCurrent}
              placeholder={this.state.branchText}
              error={this.renderError("idBranch")}
            />
          </div>
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"idStore"}
              onChange={(e) => this.handlerSelect(e, "idStore", "storeCurrent")}
              options={this.state.storeList}
              title={i18n.t("Store")}
              value={this.state.storeCurrent}
              placeholder={this.state.storeText}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled={true}
              type={"input"}
              name={"code"}
              title={i18n.t("Customer Code")}
              required
              value={this.state.form.code}
              handler={this.handler}
              error={this.renderError("code")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"idType"}
              required={true}
              onChange={async (e) => {
                await this.handlerSelect(e, "idType", "typeCurrent");
                await this.setState({
                  top: "",
                  suffixTop: "",
                  invoiceLimit: "",
                  creditLimit: "",
                  suffixCL: "",
                  suffixIL: "",
                });
                await this.changeData("dataHierarchy", "getSetupHierarchy", "");
              }}
              options={this.state.typeList}
              title={i18n.t("Customer Type")}
              placeholder={this.state.typeText}
              value={this.state.typeCurrent}
              error={this.renderError("idType")}
            />
          </div>
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"paymentType"}
              required={true}
              onChange={async (e) => {
                await this.handlerSelect(
                  e,
                  "paymentType",
                  "paymentTypeCurrent"
                );
                await this.setState({
                  top: "",
                  suffixTop: "",
                  invoiceLimit: "",
                  creditLimit: "",
                  suffixCL: "",
                  suffixIL: "",
                });
                await this.changeData("dataHierarchy", "getSetupHierarchy", "");
              }}
              options={options}
              title={i18n.t("Payment Type")}
              value={this.state.paymentTypeCurrent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"name"}
              title={i18n.t("Name")}
              required
              value={this.state.form.name}
              handler={this.handler}
              error={this.renderError("name")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <TextArea
              name={"address"}
              title={i18n.t("Address")}
              value={this.state.form.address}
              handler={this.handler}
              error={this.renderError("address")}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"idProvince"}
              required={true}
              onChange={(e) =>
                this.handlerSelect(e, "idProvince", "provinceCurrent")
              }
              options={this.state.provinceList}
              title={i18n.t("Province")}
              value={this.state.provinceCurrent}
              currentState={"provinceCurrent"}
              placeholder={this.state.provinceText}
              error={this.renderError("idProvince")}
            />
          </div>
          <div className="col-md-4">
            <Select2
              isClearable={true}
              name={"idCity"}
              required={true}
              onChange={(e) => this.handlerSelect(e, "idCity", "cityCurrent")}
              options={this.state.cityList}
              title={i18n.t("City")}
              value={this.state.cityCurrent}
              currentState={"cityCurrent"}
              placeholder={this.state.cityText}
              error={this.renderError("idCity")}
            />
          </div>
          <div className="col-md-4">
            <InputNumber
              id={"longBusiness"}
              name={"longBusiness"}
              title={i18n.t("Long Business")}
              labelfield={"true"}
              required={true}
              value={this.state.form.longBusiness}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrency(value, "longBusiness")
              }
              suffix={" Tahun"}
              className="form-control  form-control-sm "
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"phone"}
              title={i18n.t("Phone")}
              value={this.state.form.phone}
              handler={this.handler}
              error={this.renderError("phone")}
              required
            />
          </div>
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"idNumber"}
              title={i18n.t("ID Number")}
              value={this.state.form.idNumber}
              handler={this.handler}
              error={this.renderError("idNumber")}
              required={this.state.form.paymentType == "Credit" ? true : false}
            />
          </div>
          <div className="col-md-4">
            <Input
              type={"email"}
              name={"email"}
              title={i18n.t("Email")}
              value={this.state.form.email}
              handler={this.handler}
            />
          </div>
        </div>
        {this.state.form.paymentType == "Credit" ? (
          <Fragment>
            <div className="row">
              <div className="col-md-4">
                <InputNumber
                  id={"top"}
                  name={"top"}
                  title={i18n.t("Top")}
                  labelfield={"true"}
                  required={true}
                  value={this.state.form.top}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrency(value, "top")
                  }
                  suffix={" " + this.state.suffixTop}
                  className="form-control  form-control-sm "
                />
                {this.state.form.paymentType == "Credit" &&
                this.state.form.top != null ? (
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError("top")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4">
                <InputNumber
                  id={"invoiceLimit"}
                  name={"invoiceLimit"}
                  title={i18n.t("Invoice Limit")}
                  labelfield={"true"}
                  required={true}
                  value={this.state.form.invoiceLimit}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrency(value, "invoiceLimit")
                  }
                  suffix={" " + this.state.suffixIL}
                  className="form-control  form-control-sm "
                />
                {this.state.form.paymentType == "Credit" &&
                this.state.form.invoiceLimit != null ? (
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError("invoiceLimit")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4">
                <InputNumber
                  id={"creditLimit"}
                  name={"creditLimit"}
                  title={i18n.t("Credit Limit")}
                  labelfield={"true"}
                  required={true}
                  value={this.state.form.creditLimit}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrency(value, "creditLimit")
                  }
                  suffix={
                    this.state.suffixCL != "Rp."
                      ? " " + this.state.suffixCL
                      : ""
                  }
                  prefix={
                    this.state.suffixCL == "Rp."
                      ? this.state.suffixCL + " "
                      : ""
                  }
                  className="form-control  form-control-sm "
                />
                {this.state.form.paymentType == "Credit" &&
                this.state.form.creditLimit != null ? (
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError("creditLimit")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"contactPerson"}
              title={i18n.t("Contact Person")}
              value={this.state.form.contactPerson}
              handler={this.handler}
            />
          </div>
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"contactPersonPhone"}
              title={i18n.t("Contact Person Phone")}
              value={this.state.form.contactPersonPhone}
              handler={this.handler}
            />
          </div>
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"npwp"}
              title={i18n.t("NPWP")}
              value={this.state.form.npwp}
              handler={this.handler}
              error={this.renderError("npwp")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <Radio
              list={{ 1: i18n.t("PKP"), 0: i18n.t("NON PKP") }}
              name={"taxStatus"}
              title={i18n.t("Tax Status")}
              inline={false}
              value={this.state.form.taxStatus}
              handler={this.handler}
              error={this.renderError("taxStatus")}
              required
            />
          </div>
          <div className="col-md-8">
            <FormLabel>
              {" "}
              {i18n.t("Upload Files")} ( {i18n.t("max")}{" "}
              {this.state.maxUploadFiles} {i18n.t("items")})
            </FormLabel>
            <DropzoneCustom
              maxFiles={this.state.maxUploadFiles}
              acceptFiles={{
                "image/jpeg": [],
                "image/png": [],
                "application/pdf": [],
              }}
              maxSize={1024000}
              changeFiles={this.updateDropzone}
            ></DropzoneCustom>
          </div>
          <div></div>
        </div>
          <br/>
        <div className="row">
        <div className="col-md-12" style={{ height: '300px', }}>
                    <GoogleMapReact

                        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
                        defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                        defaultZoom={this.state.latitude == 0?1:18}
                        style={{ position: 'relative', height: '100%', width: '100%' }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    >
                    </GoogleMapReact>
                </div>
          
        </div>
        <div></div>
        <br />
        <hr />
        <div className="row">
          <div className="col-12">
            <Table striped bordered hover>
              <thead>
              <tr><th> <h1>{i18n.t("Competitors")}</h1></th></tr>
                <tr>
                  <th className="text-left">{i18n.t("Item")}</th>
                  <th className="text-left">{i18n.t("Selling Price")}</th>
                  <th className="text-left">{i18n.t("Turnover/Week")}</th>
                  <th className="text-left">{i18n.t("Top")}</th>
                  <th className="text-left">{i18n.t("Invoice Limit")}</th>
                  <th className="text-left">{i18n.t("Credit Limit")}</th>
                  <th className="text-left">{i18n.t("Note")}</th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.arrayDetail.map((detail, index) => {
                  return this.renderDetail(detail, index);
                })}
              </tbody>
            </Table>
            <Button
              variant="success"
              onClick={(e) =>
                this.addArray(
                  {
                    itemName: "",
                    sellingPrice: "",
                    omset: "",
                    top: "",
                    invoiceLimit: "",
                    creditLimit: "",
                    description: "",
                  },
                  "arrayDetail"
                )
              }
            >
              {i18n.t("Add Competitors")}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import WorkCalendar from '../../../../components/modules/master/work-calendar';
import WorkCalendarMiddleware from '../../../../middleware/modules/master/work-calendar';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,workCalendar }) => ({
  isProcessing: workCalendar.isProcessing,
  isError: workCalendar.isError,
  errorMessage: workCalendar.errorMessage,
  datalist:workCalendar.data,
  showModal:workCalendar.showModal,
  helperList:workCalendar.helperWorkCalendar
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>WorkCalendarMiddleware.getHelper(),
    reset: () =>WorkCalendarMiddleware.resetList(),
    // search: data =>WorkCalendarMiddleware.list(data),
    setDate: (data) =>WorkCalendarMiddleware.list(data),
    excel: (data) =>WorkCalendarMiddleware.showExcel(data),
  }, dispatch);
};
class WorkCalendarContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    // search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setDate();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper();
    // this.props.search(search);
  
  }

  render() {
    const {datalist,helperList} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Work Calendar")}
          </title>
        </Helmet>
      <WorkCalendar
        {...this.props}
        data={datalist}
        helperList={helperList}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkCalendarContainer);

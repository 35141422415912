import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { CashBank } from "../../../../components/modules/accounting/cash-bank";
import CashBankMiddleware from "../../../../middleware/modules/accounting/cash-bank";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import LogBgMiddleware from "../../../../middleware/modules/accounting/log-bg";
import LogBg from "../../../../components/modules/accounting/log-bg";
import LogBgIn from "../../../../components/modules/accounting/log-bg/index-in";
// middlewares

const mapStateToProps = ({ auth, cashbank, logBg }) => ({
  isProcessing: logBg.isProcessing,
  isError: logBg.isError,
  errorMessage: logBg.errorMessage,
  datalist: logBg.data,
  getHelperData: logBg.helperJournal,
  afterSave: logBg.afterSave,
  showModalIn: logBg.showModalIn,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      search: (data) => LogBgMiddleware.list(data),
      confirmIn: (data) => LogBgMiddleware.dataBGIn(data),
      rejectIn: (data) => LogBgMiddleware.rejectIn(data),
    },
    dispatch
  );
};
class LogBgInContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,
  };

  static defaultProps = {
    afterSave:false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.confirmIn = this.confirmIn.bind(this);
    this.rejectIn = this.rejectIn.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
      key:'in'
    };

    this.props.reset();
    this.props.search(search);
  }

  confirmIn(data) {
    this.props.confirmIn(data);
  }

  rejectIn(data) {
    this.props.rejectIn(data);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Log BG In List")}</title>
        </Helmet>
        <LogBgIn
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogBgInContainer);

import MemoInActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataDetail:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperMemoIn:null,
  helperJournal:null,
  errorInternal:false,
  printMemoIn:null,
};

function MemoInReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MemoInActions.RESET_LIST:
      return INITIAL_STATE;
    case MemoInActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case MemoInActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case MemoInActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case MemoInActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case MemoInActions.CANCEL_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case MemoInActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case MemoInActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case MemoInActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case MemoInActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case MemoInActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case MemoInActions.GET_HELPER_MEMO_IN:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case MemoInActions.GET_HELPERFULL_MEMO_IN:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case MemoInActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case MemoInActions.AFTER_DATA:
      return { ...state,errorInternal:false, onSubmit:false, data:action.payload,isNewRecord:false}      
    case MemoInActions.BEFORE_PRINT_MEMO_IN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printMemoIn:null};
    case MemoInActions.PRINT_MEMO_IN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printMemoIn:action.payload};
    case MemoInActions.APPROVE_MEMOIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case MemoInActions.REJECT_MEMOIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};    
    default:
      return state;
    }
}

export default MemoInReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import StockCardMiddleware from '../../../../middleware/modules/mutation/stock-card';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import StockCard from "../../../../components/modules/mutation/stock-card";
// middlewares
const mapStateToProps = ({ stockCard }) => ({
  isProcessing: stockCard.isProcessing,
  isError: stockCard.isError,
  errorMessage: stockCard.errorMessage,
  helperList:stockCard.helperStockCard,
  data: stockCard.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => StockCardMiddleware.getHelper(),
      reset: () =>StockCardMiddleware.resetList(),

    },
    dispatch
  );
};
class StockCardContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <StockCard
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockCardContainer);

import React, { Component } from "react";

import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import i18n from "../../../../i18n";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config_name: "",
      acc3Debt: null,
      acc3Credit: null,
      coa3List: null,
      currentDebt: null,
      currentCredit: null,
      currentDebtText: "Select Debt",
      currentCreditText: "Select Credit",
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,

      [key]: value,
    });
  }

  runSubmit() {
    return this.state;
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return value;
  }

  render() {
    const { data, isNewRecord, coa3List } = this.props;
    this.state.coa3List = coa3List;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.config_name = data.config_name),
          (this.state.currentDebt = {
            value: data.debt_id == null ? "" : data.debt_id,
            label: data.debt_name,
          }),
          (this.state.currentCredit = {
            value: data.credit_id == null ? "" : data.credit_id,
            label: data.credit_name,
          }),
          (this.state.acc3Debt = data.debt_id),
          (this.state.acc3Credit = data.credit_id),
          (this.state.isUpdate = 2);
      }
    }
    return (
      <form>
        <div className="row">
          <div className="col-md-12">
            <Input
              type={"input"}
              name={"config_name"}
              title={i18n.t("Config Name")}
              disabled={true}
              value={this.state.config_name}
              handler={this.handler}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select2
                isClearable={true}
                name={"acc3Debt"}
                title={i18n.t("Code Ledger(Debt)")}
                onChange={(e) =>
                  this.handlerSelect(e, "acc3Debt", "currentDebt")
                }
                options={this.state.coa3List}
                value={this.state.currentDebt}
                placeholder={this.state.currentDebtText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select2
                isClearable={true}
                name={"acc3Credit"}
                title={i18n.t("Code Ledger(Credit)")}
                onChange={(e) =>
                  this.handlerSelect(e, "acc3Credit", "currentCredit")
                }
                options={this.state.coa3List}
                value={this.state.currentCredit}
                placeholder={this.state.currentCreditText}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

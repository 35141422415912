import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormatCurrency from "../../../utilities/Class/FormatCurrency";
const token = cookieManager.getUserToken();

export class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      check: 0,
    };
  }

  render() {
    const { data } = this.props;
    if (data != null && this.state.check == 0) {
      this.state.data=data;
      this.state.check = 1;
    }
    return (
      <form>
        {data != null ? (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Table rules="cols">
                  <thead></thead>
                  <tbody>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Code")}</td>
                      <td>:</td>
                      <td>
                        {this.state.data.code ? this.state.data.code : "-"}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.data.name ? this.state.data.name : "-"}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Branch")}</td>
                      <td>:</td>
                      <td>
                        {this.state.data.branch ? this.state.data.branch : "-"}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Type")}</td>
                      <td>:</td>
                      <td>
                        {this.state.data.type ? this.state.data.type : "-"}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Basic")}</td>
                      <td>:</td>
                      <td>
                        {this.state.data.basic ? FormatCurrency(this.state.data.basic,'Rp') : "-"}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Allowance")}</td>
                      <td>:</td>
                      <td>
                        <ul>
                            {this.state.data?.listAllowance.map((allowance,i)=>
                                <li key={"allowance"+i}>{allowance.name}  ({allowance.allowanceType}) ({FormatCurrency(allowance.nominal,'Rp')})</li>
                            )}
                            
                        </ul>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Cuts")}</td>
                      <td>:</td>
                      <td>
                        <ul>
                            {this.state.data.listCuts.map((cuts,i)=>
                                <li key={"cuts"+i}>{cuts.name} ({cuts.salaryCutsType}) ({FormatCurrency(cuts.nominal,'Rp')})</li>
                            )}
                            
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            
          </React.Fragment>
        ) : (
          <div>
              <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
              <div style={{fontSize:36}}>Getting Data From Server</div>
          </div>
        )}
      </form>
    );
  }
}

export default View;
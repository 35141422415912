import CashSalesActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  helperCashSales:{coaCashBank:[],coaExpense:[]},
  isProcessingShow:false,
  listCash:[],
  single:{},
  errorMessage:[]
};

function CashSalesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Reconcile
    case CashSalesActions.BEFORE_VOID:
      return {...state,isProcessing:true,onSubmit:true,}
    case CashSalesActions.BEFORE_SAVE:
      return {...state,isProcessing:true,onSubmit:true,}
    case CashSalesActions.AFTER_SAVE:
      return {...state,isProcessing:false,onSubmit:false,}
    case CashSalesActions.SET_HELPER:
      return {...state,helperCashSales:action.payload}
    case CashSalesActions.SHOW_RECONCILE:
      return {...state,isProcessingShow:true,listCash:[],showModal:true,errorMessage:[],single:action.payload}
    case CashSalesActions.AFTER_SHOW_RECONCILE:
      return {...state,isProcessingShow:false,listCash:action.payload,errorMessage:[]}
    case CashSalesActions.RESET_LIST:
      return INITIAL_STATE;
    case CashSalesActions.RESET_DATA:
      return { ...state, isProcessing: false,isError: false,errorMessage: [], form:INITIAL_STATE.form,showModal:false};
    case CashSalesActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
 case CashSalesActions.SUCCESS_DATA:
      return { ...state,isProcessing:false ,isError: false,showModal:false,showModalBilling:false,form:INITIAL_STATE.form , onSubmit:false};
    case CashSalesActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false,errorMessage: []};
    case CashSalesActions.ERROR_DATA:
      return { ...state, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case CashSalesActions.GET_HELPER_CASH:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperCash:action.payload};
   default:
      return state;
    }
}


export default CashSalesReducer;

import {
  faTrash,faPlus,faPerson
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Tab, Table, Tabs } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import InputNumber from "../../../utilities/Form/InputNumber";

export class FormTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        SalesTarget: {},
        SalesTargetDetail: [],
      },
      arrayTarget:[
        {
          itemValue: null,
          qtyTarget: null,
          nominalTarget: 0,
          show:false,
          arrayInfo:[
          ],
        }
      ],
      
      
      name:'',
      transactionNo: "",
      titleModal: i18n.t("Convertion"),
      date: new Date(),
      startDateDaily: new Date(),
      endDateDaily: new Date(),
      startDateWeekly: new Date(),
      endDateWeekly: new Date(),
      dateDaily:new Date(),
      qtyDaily: 0,
      qtyWeekly: 0,
      qtyMonthly: 0,
      nominalDaily: 0,
      nominalWeekly: 0,
      nominalMonthly: 0,
      dateMonthly:null,
      periodEndList : [],
      periodStartCurrent: null,
      periodEndCurrent: null,
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      arrayDetail: [
        {
          startDate: new Date(),
          endDate: new Date(),
          dateDaily:new Date(),
          qtyDaily: 0,
          qtyWeekly: 0,
          qtyMonthly: 0,
          type: null,
          nominalDaily: 0,
          nominalWeekly: 0,
          nominalMonthly: 0,
        },
      ],
      daily:i18n.t('Daily'),
      weekly:i18n.t('Weekly'),
      monthly:i18n.t('Monthly'),
     
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      branchTargetCurrent: null,
      branchTargetText: i18n.t("Select Branch"),
      branchList: [],
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      dataUpdate: [],
      isUpdate: 0,
      status: 1,
      totalPrice: 0,
      grandTotal: 0,
      limit:0,
      allTrans:0,
      addItem:0,
      type:null,
      saveType:2,
      tab:"SalesTargetShow",
    };

    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handlerTab = this.handlerTab.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerNumber(value,name) {
    var key = name;
    var value = value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }

    let priceNett = price + priceTax - discount;
    let priceTotal = priceNett * qty;
    objectss["priceNett"] = priceNett;
    objectss["priceTax"] = priceTax;
    objectss["priceTotal"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    //total
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].price) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].priceTax) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }

    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });
  }
  runSubmit() {
    if(this.state.saveType == 2){
      const SalesTarget = {
        // date: moment(this.state.date).format("YYYY-MM-DD"),
        startDate : this.state.periodStartCurrent == null?'':this.state.periodStartCurrent.value,
        endDate : this.state.periodEndCurrent == null?'':this.state.periodEndCurrent.value,
        name:
          this.state.name != null ? this.state.name : "",
        idBranch:
          this.state.branchTargetCurrent != null ? this.state.branchTargetCurrent.value : "",
        status: 1,
        saveType:this.state.saveType,
      };
      let item = [];
      let qty = [];
      let nominal = [];
      let info = [];
      let detail = this.state.arrayTarget;
      for(var i = 0; i<detail.length;i++){
        info.push(detail[i].arrayInfo != null ? detail[i].arrayInfo : "" );
        item.push(detail[i].itemValue != null ? detail[i].itemValue.value : "");
        qty.push(detail[i].qtyTarget);
        nominal.push(detail[i].nominalTarget);
      }
      this.state.form.SalesTarget = SalesTarget;
      this.state.form.SalesTargetDetail = {
        item: item,
        info: info,
        qty: qty,
        nominal: nominal,
       

      };

    }else{
      const SalesTarget = {
        date: moment(this.state.date).format("YYYY-MM-DD"),
        startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        idBranch:
          this.state.branchCurrent != null ? this.state.branchCurrent.value : "",
        status: 1,
        saveType:this.state.saveType,
      };
      
      const detail = this.state.arrayDetail;
      
      let startDateDaily = null;
      let endDateDaily = null;
      let startDateWeekly = null;
      let endDateWeekly = null;
      let dateMonthly = null;
      let qtyDaily = null;
      let qtyMonthly = null;
      let qtyWeekly = null;
      let nominalDaily = null;
      let nominalMonthly = null;
      let nominalWeekly = null;
      let type= [];

      type.push(this.state.daily,this.state.weekly,this.state.monthly)
      startDateDaily = (this.state.startDateDaily != null ?  moment(this.state.startDateDaily).format("YYYY-MM-DD") : '')
      endDateDaily = (this.state.endDateDaily != null ?  moment(this.state.endDateDaily).format("YYYY-MM-DD") : '')
      startDateWeekly = (this.state.startDateWeekly != null ?  moment(this.state.startDateWeekly).format("YYYY-MM-DD") : '')
      endDateWeekly = (this.state.endDateWeekly != null ?  moment(this.state.endDateWeekly).format("YYYY-MM-DD") : '')
      dateMonthly = (this.state.monthValue != null ? this.state.monthValue.value : '')
      qtyDaily = (this.state.qtyDaily != null ? this.state.qtyDaily : '');
      qtyMonthly = (this.state.qtyMonthly != null ? this.state.qtyMonthly : '');
      qtyWeekly = (this.state.qtyWeekly != null ? this.state.qtyWeekly : '');
      nominalDaily = (this.state.nominalDaily != null ? this.state.nominalDaily : '');
      nominalMonthly = (this.state.nominalMonthly != null ? this.state.nominalMonthly : '');
      nominalWeekly = (this.state.nominalWeekly != null ? this.state.nominalWeekly : '');

      this.state.form.SalesTarget = SalesTarget;
      this.state.form.SalesTargetDetail = {
        type: type,
        qtyDaily: qtyDaily,
        qtyMonthly: qtyMonthly,
        qtyWeekly: qtyWeekly,
        nominalDaily:nominalDaily,
        nominalMonthly:nominalMonthly,
        nominalWeekly:nominalWeekly,
        startDateDaily:startDateDaily,
        endDateDaily:endDateDaily,
        startDateWeekly:startDateWeekly,
        endDateWeekly:endDateWeekly,
        dateMonthly:dateMonthly,

      };
    }

    return this.state.form;
  }
  handlerSelectPeriod(e, name, currentState) {
    var key = name
    var value = null
    let list = [];
    let dataList = [];

    dataList =this.state.periodList;
    // if(key == "periodStartCurrent" && e != null){
    //   var index = dataList.findIndex(item => item.value === e.value)
    //   for(var i = index; i < index+5;i++){
    //     list.push(dataList[i])
    //   }
    //   this.setState({periodEndList:list});

    // }else{
      
    // }
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }
  
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  handlerItemArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
      objectss['show'] = false;
    } else {
      objectss[name] = e;
      objectss['show'] = true;
      
    }
    this.setState(arr);
  }

 

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }

  handlerNumberArray(e, name, p, arrayname,i) {
    const arr = [...this.state[arrayname]];
    const obj = arr[i];
    const objectss = obj.arrayInfo[p];

    objectss[name] = e;

    this.setState(arr);
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.qtyAvailable,
      convertionDetail: detail.convertionDetail,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.daily}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
            <DatePickerCustom
              startDate={this.state.startDateDaily}
              endDate={this.state.endDateDaily}
              selectsRange={true}
              isClearable={false}
              name={"dateDaily"}
              title={i18n.t("Effective Date")}
              required={true}
              onChange={(e) => {
                const [start, end] = e;
                this.setState({ startDateDaily: start, endDateDaily: end });
              }}
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              peekNextMonth
              showMonthDropdown
              autoComplete={"off"}
              dateFormat="dd MMMM yyyy"
              className="form-control form-control-md"
              labelfield={"false"}
            />
          </td>
          <td>
          <InputNumber
              id={"qtyDaily" + i}
              name="qtyDaily"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyDaily}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalDaily" + i}
              name="nominalDaily"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalDaily}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.weekly}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
          <DatePickerCustom
              startDate={this.state.startDateWeekly}
              endDate={this.state.endDateWeekly}
              selectsRange={true}
              isClearable={false}
              name={"dateWeekly"}
              title={i18n.t("Effective Date")}
              required={true}
              onChange={(e) => {
                const [start, end] = e;
                this.setState({ startDateWeekly: start, endDateWeekly: end });
              }}
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              peekNextMonth
              showMonthDropdown
              autoComplete={"off"}
              dateFormat="dd MMMM yyyy"
              className="form-control form-control-md"
              labelfield={"false"}
            />
            {/* <Select2
              isClearable={true}
              name={"period"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idPeriod", "periodCurrent");
              }}
              options={this.state.periodList}
              title={i18n.t("Period")}
              value={this.state.periodCurrent}
              labelfield={"false"}
              placeholder={this.state.periodText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('startDate')}
            </div> */}
          </td>
          <td>
          <InputNumber
              id={"qtyWeekly" + i}
              name="qtyWeekly"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyWeekly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalWeekly" + i}
              name="nominalWeekly"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalWeekly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
        <tr>
          <td>
          <Input
            type={"input"}
            name={"type"}
            title={i18n.t("type")}
            value={this.state.monthly}
            disabled
            labelfield={"false"}
            handler={this.handler}
          />
          </td>
          <td>
            <Select2
              isClearable={true}
              name={"month"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "month", "monthValue");
              }}
              options={this.state.monthList}
              title={i18n.t("Month")}
              value={this.state.monthValue}
              placeholder={this.state.monthText}
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('month')}
            </div>
          </td>
          <td>
          <InputNumber
              id={"qtyMonthly" + i}
              name="qtyMonthly"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={this.state.qtyMonthly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalMonthly" + i}
              name="nominalMonthly"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={this.state.nominalMonthly}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerNumber(value,name)
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
          
          </td>
        </tr>
      </React.Fragment>
    );
  }

  fetchData(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if(this.state.targetList != null && this.state.targetList != [] && arr != []){
      objectss['arrayInfo'] = [];
      let row = this.state.targetList;
      for(var p = 0; p<row.length;p++){
        objectss['arrayInfo'].push({
          idDis : row[p].id,
          codeDis : row[p].code,
          qtyTarget :0,
          nominalTarget:0,
          index:p
        })
      }
      this.setState(arr);
    }
  }

  renderInfo(detail,i){
    let html = [];
    let row = detail;

      if(row.length > 0){
        for(var p = 0; p<row.length;p++){
          let index = row[p].index;
        console.log('data',index)

          html.push(
          <React.Fragment key={"info"+p}>
            <tr>
                <td>
                  <input
                    className="form-control  form-control-sm "
                    type={"input"}
                    name={"infoName"}
                    id={"infoName" + p}
                    value={row[p].codeDis}
                    disabled
                   
                  />
                </td>
                <td>  
                <InputNumber
                    id={"qtyTarget"+p}
                    name="qtyTarget"
                    placeholder={i18n.t("Qty")}
                    defaultValue={0}
                    value={row[p].qtyTarget}
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      this.handlerNumberArray(value,name,index,'arrayTarget',i)
                    }
                    
                    className="form-control  form-control-sm text-end"
                    labelfield={"false"}
                  />
                </td>
                <td>
                  <InputNumber
                    id={"nominalTarget"+p}
                    name="nominalTarget"
                    title={i18n.t("Nominal")}
                    placeholder={i18n.t("Nominal")}
                    defaultValue={0}
                    value={row[p].nominalTarget}
                    decimalsLimit={2}
                    onValueChange={(value, name) =>
                      this.handlerNumberArray(value,name,index,'arrayTarget',i)
                    }
                    className="form-control  form-control-sm text-end"
                    labelfield={"false"}
                    prefix="Rp "
                  />
              
                </td> 
              </tr>
            </React.Fragment>);
        }
      }
        
      return html;
  }

  renderDetailTarget(detail,i) {
   
    return (
      <React.Fragment key={"detail"+i}>
        <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idItem"}
              required={true}
              size={4}
              onChange={async (e) => {
                
                await this.handlerItemArray(e, "itemValue", i, 'arrayTarget');
                if(e!=null){
                  this.fetchData(e, "itemValue", i, 'arrayTarget');
                }
              }}
              options={this.state.itemList}
              title={i18n.t("Item")}
              value={detail.itemValue}
              placeholder={this.state.itemText}
              labelfield={"true"}
            />
            {/* <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError('month')}
            </div> */}
          {/* </td> */}
          {/* <td></td>
          <td></td> */}

          {/* <td>
          <InputNumber
              id={"qtyTarget"+i}
              name="qtyTarget"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qtyTarget}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value,name,i,'arrayTarget')
              }
              
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
          </td>
          <td>
            <InputNumber
              id={"nominalTarget"+i}
              name="nominalTarget"
              title={i18n.t("Nominal")}
              placeholder={i18n.t("Nominal")}
              defaultValue={0}
              value={detail.nominalTarget}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value,name,i,'arrayTarget')
              }
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
              prefix="Rp "
            />
        
          </td> */}
          {/* <td> */}
         </div>
         <div className="col-md-3 mt-8">
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayTarget")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          {/* </td>
        </tr> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.arrayTarget[i].show && detail.arrayInfo != []?
            <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center" width="20%">{i18n.t("Name")}</th>
                    <th className="text-center" width="20%">{i18n.t("Qty")}</th>
                    <th className="text-center" width="20%">{i18n.t("Nominal")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderInfo(detail.arrayInfo,i)}
                </tbody>
              </Table>
              :null}
          </div>
        </div>
      </React.Fragment>
    );
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token=cookieManager.getUserToken()
    switch (type) {
      case "getItemWarehouseAll":
        var url = process.env.REST_URL + "helper/get-item-warehouse-all";
        if(this.state.branchTargetCurrent==null){
          type=null;
        }
        params = {
          branch: this.state.branchTargetCurrent !=null?this.state.branchTargetCurrent.value:null,
        };
        break;
     
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderTarget(){
    const { data, isNewRecord, helperSalesTarget } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    // if(helperSalesTarget!= null){
    //   this.state.branchList =
    //   helperSalesTarget != null ? helperSalesTarget.branch : [];
    //   this.state.periodList = 
    //   helperSalesTarget != null ? helperSalesTarget.workCalendar : [];
    // }

    // if (data != null && !isNewRecord) {
    //   if(this.state.isUpdate == 0){
    //     this.state.branchCurrent = {value:data.idBranch,label:data.name_branch};
    //     this.state.date=new Date(data.date);

    //     let row = data.sales_target

    //     this.state.startDateDaily = row[0] != null ? new Date(row[0].start_date): new Date();
    //     this.state.endDateDaily = row[0] != null ? new Date(row[0].end_date): new Date();
    //     this.state.qtyDaily = row[0] != null ? parseFloat(row[0].qty)||0:0;
    //     this.state.nominalDaily = row[0] != null ? parseFloat(row[0].revenue)||0:0;

    //     this.state.startDateWeekly = row[1] != null ? new Date(row[1].start_date): new Date();
    //     this.state.endDateWeekly = row[1] != null ? new Date(row[1].end_date): new Date();
    //     this.state.qtyWeekly = row[1] != null ? parseFloat(row[1].qty)||0:0;
    //     this.state.nominalWeekly = row[1] != null ? parseFloat(row[1].revenue)||0:0;

    //     this.state.monthValue = row[2] != null ? {value:row[2].month,label:i18n.t(row[2].month_name)}:'';
    //     this.state.qtyMonthly = row[2] != null ? parseFloat(row[2].qty)||0:0;
    //     this.state.nominalMonthly = row[2] != null ? parseFloat(row[2].revenue)||0:0;

    //   }
    //   this.state.isUpdate = 1;
    // }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-md"
              />
            </div>
            <div className="col-md-4 mt-4">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e == null) {
                   
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                  } else {
                   
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                  };
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("SalesTarget.idBranch")}
              />
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center">{i18n.t("Type")}</th>
                    <th className="text-center">{i18n.t("Effective Date")}</th>
                    <th className="text-center">{i18n.t("Qty")}</th>
                    <th className="text-center">{i18n.t("Nominal")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderSales(){
    // if(this.state.dataUpdate != null && this.saveType == 2 && isNewRecord == false){
    //   console.log('masujk')
    //   if (this.state.updateData == 0) {
     
    // }
    return(
      <>
      {/* <FormWizard
        // onComplete={handleComplete}
        // color="#094899"
        onTabChange={this.handlerData()}
      >
        <FormWizard.TabContent title="Add Target" icon={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>}>
  
          <h3>Add</h3> */}
          <form>
          <div className="row">
            <div className="col-sm-2 mt-3">
              <Select2
                isClearable={true}
                name={"period"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                  await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
                }}
                options={this.state.periodList}
                title={i18n.t("Week Period")}
                value={this.state.periodStartCurrent}
                placeholder={this.state.periodText}
              />
            </div>
            <div style={{width:'35px'}} className="col-sm-1 mt-12">
              {i18n.t("To")}
            </div>
            <div className="col-sm-2 mt-3">
            <Select2
                isClearable={true}
                name={"periodEnd"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
                }}
                options={this.state.periodList}
                title={i18n.t(" ")}
                value={this.state.periodEndCurrent}
                placeholder={this.state.periodText}
              />
            </div>
            {/* <div className="col-md-3 mt-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-md"
              />
            </div> */}
            <div className="col-md-3 mt-4">
              <Select2
                isClearable={true}
                name={"idBranchTarget"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idBranchTarget", "branchTargetCurrent");
                  await this.setState({
                    itemList:[],
                  });
                  if (e == null) {
                    await this.setState({
                      arrayTarget:({
                          idItem: null,
                          qty: null,
                          nominal: 0,
                      })
                    });
                    // await this.handlerSelect(e, "idBranchTarget", "branchTargetCurrent");
                  } else {

                    this.changeData({
                      keyFill:"itemList",
                      type:"getItemWarehouseAll",
                      key:""
                    })
                    
                  }

                  ;
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchTargetCurrent}
                placeholder={this.state.branchText}
                // error={this.renderError("SalesTarget.idBranch")}
              />
            </div>
            <div className="col-md-3 mt-4">
              <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.name}
                    handler={this.handler}
                    error={this.renderError('name')}
                />
            </div>
          </div>
          <br />
          <div className="row">
              {this.state.arrayTarget.map((detail, index) => {
                return this.renderDetailTarget(detail, index);
              })}
          </div>
          <div className="row">
            <div className="col-md-3">
            <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      itemValue: null,
                      qtyTarget: null,
                      nominalTarget: 0,
                      show:false,
                      arrayInfo:[
                      ],
                    },
                    "arrayTarget"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button>
              </div>
            </div>
          </form>
        {/* </FormWizard.TabContent>
        <FormWizard.TabContent title="Assignment" icon={<FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>}>
          <h3>Second Tab</h3>
          <p>Some content for the second tab</p>
        </FormWizard.TabContent>
        {/* <FormWizard.TabContent title="Last step" icon="fa fa-city">
          <h3>Last Tab</h3>
          <p> Yuhuuu! This seems pretty damn simple</p>
        </FormWizard.TabContent> */}
      {/* </FormWizard> */} 
       {/* add style */}
       {/* <style>{`
         @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
        `}</style> */}
      </>
    );
  }

  
  handlerTab(index){
    this.setState({
      saveType: index
    });
  }
  render() {
    const { data, isNewRecord, helperSalesTarget } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    if(helperSalesTarget!= null){
      this.state.branchList = helperSalesTarget != null ? helperSalesTarget.branch : [];
      this.state.periodList = helperSalesTarget != null ? helperSalesTarget.workCalendar : [];
      this.state.targetList = helperSalesTarget != null ? helperSalesTarget.target : [];
    }
    
    if(data!= null && isNewRecord ==  false){
      if(this.state.isUpdate == 0){
        this.state.dataUpdate = data;
        this.state.tab = data.name != null?"SalesTargetShow":"TargetShow";
        this.state.saveType = data.name != null?2:1;
        console.log('dataUpdate', data)
        if(this.state.saveType == 2){
          this.state.branchTargetCurrent = {
            value:data.id_branch,
            label:data.name_branch
          };
          this.state.periodStartCurrent = {
            value:data.start_date,
            label:data.label_start
          };
          this.state.periodEndCurrent = {
            value:data.end_date,
            label:data.label_end
          };
          this.state.name = data.name;
          this.changeData({
            keyFill:"itemList",
            type:"getItemWarehouseAll",
            key:""
          })
          this.state.arrayTarget = [];
          for(var i=0;i<data.detail.length;i++){
            let item = data.detail[i];
        
          let arraInfo = [];
          if(item.detail_item != null){
            for(var p=0;p<item.detail_item.length;p++){
              arraInfo.push({
                  idDis : item.detail_item[p].id_info,
                  codeDis : item.detail_item[p].name_info,
                  qtyTarget :parseFloat(item.detail_item[p].qty)||0,
                  nominalTarget:parseFloat(item.detail_item[p].revenue)||0,
                  index:p
                })
            }
          }
          this.state.arrayTarget.push({
              itemValue: {
                value: data.detail[i].id_item,
                label: data.detail[i].name_item,
              },
              arrayInfo : arraInfo,
              show:true
          });
        }
      }else if(this.state.saveType == 1){
        this.state.branchCurrent = {value:data.id_branch,label:data.name_branch};
            this.state.date=new Date(data.date);
            let row = data.detail
            if(row != null){
              this.state.startDateDaily = row[0] != null ? new Date(row[0].start_date): new Date();
              this.state.endDateDaily = row[0] != null ? new Date(row[0].end_date): new Date();
              this.state.qtyDaily = row[0] != null ? parseFloat(row[0].qty)||0:0;
              this.state.nominalDaily = row[0] != null ? parseFloat(row[0].revenue)||0:0;
      
              this.state.startDateWeekly = row[1] != null ? new Date(row[1].start_date): new Date();
              this.state.endDateWeekly = row[1] != null ? new Date(row[1].end_date): new Date();
              this.state.qtyWeekly = row[1] != null ? parseFloat(row[1].qty)||0:0;
              this.state.nominalWeekly = row[1] != null ? parseFloat(row[1].revenue)||0:0;
      
              this.state.monthValue = row[2] != null ? {value:row[2].month,label:i18n.t(row[2].month_name)}:'';
              this.state.qtyMonthly = row[2] != null ? parseFloat(row[2].qty)||0:0;
              this.state.nominalMonthly = row[2] != null ? parseFloat(row[2].revenue)||0:0;
            }
      }
      
      this.state.isUpdate = 1;

    }
  }


    return(
      <Tabs
        defaultActiveKey={this.state.tab}
        id={"tabs"}
        className="mb-3"
        unmountOnExit
      >
          <Tab eventKey="TargetShow" title={i18n.t("Target")} onEnter={()=>this.handlerTab(1)} disabled={(isNewRecord==false && this.state.saveType==2)?true:false}>
            {this.renderTarget()}
          </Tab>
          <Tab eventKey="SalesTargetShow" title={i18n.t("Sales Target")} onEnter={()=>this.handlerTab(2)} disabled={(isNewRecord==false && this.state.saveType==1)?true:false}>
            {this.renderSales()}
          </Tab>
       
    </Tabs>
    );
  }
}




export default FormTarget;


import React,{ Component, Fragment } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from '../../../utilities/Form/InputNumber';
import Radio from '../../../utilities/Form/radio';
import { Table } from 'react-bootstrap';
import SwitchButton from '../../../utilities/Form/SwitchButton';

const MySwal = withReactContent(Swal);

export class FormAsset extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
              Asset:{},
            },
            date:new Date(),
            arrayDetail:[],
            paymentTypeList: { 'Cash': i18n.t('Cash'), 'Credit': i18n.t('Credit') },
            paymentType:'Cash',
            name:'',
            isUpdate : 0,
            idBranch:null,
            branchCurrent: null,
            branchText: i18n.t("Select Branch"),
            branchList: [],
            idReceiving:null,
            receivingCurrent: null,
            receivingText: i18n.t("Select Receiving"),
            receivingList: [],
            idACC:null,
            accCurrent: null,
            accInventCurrent:null,
            accText: i18n.t("Select Account"),
            accList: [],
            idType:null,
            typeCurrent: null,
            typeText: i18n.t("Select Type"),
            typeList: [],
            coa3Credit:null,
            coa3CreditCurrent: null,
            coa3CreditText: i18n.t("Select Account"),
            coa3CreditList: [],
            idAccount:null,
            choose:true,
            inventory:false,
            depreciationDate:new Date(),
            purchaseDate:new Date(),
            cutOffDate:new Date(),
            setDate:0,
            depreciation:0,
            loadingType:false,
            loadingAcc:false,
            loadingReceiving:false,
        }
        this.handler = this.handler.bind(this)
        this.handlerRadio = this.handlerRadio.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    runSubmit(){
        if(this.state.inventory == false){
            const Asset = {
                idBranch:this.state.branchCurrent != null?this.state.branchCurrent.value:null,
                idType:this.state.typeCurrent != null?this.state.typeCurrent.value:null,
                choose:this.state.choose,
                inventory:this.state.inventory,
                name:this.state.name,
                paymentNo:this.state.paymentNo != null ? this.state.paymentNo: null,
                idACC:this.state.accCurrent != null? this.state.accCurrent.value:null,
                idAccount:this.state.accCurrent==null? null:this.state.accCurrent.value,
                paymentNo:this.state.paymentNo,
                purchasePrice :this.state.purchasePrice,
                depreciation:this.state.depreciation,
                purchaseDate:this.state.choose==true?moment(this.state.cutOffDate).format('YYYY-MM-DD'):moment(this.state.purchaseDate).format('YYYY-MM-DD'),
                depreciationDate:moment(this.state.depreciationDate).format('YYYY-MM-DD'),
            }

            this.state.form.Asset = Asset;

        }else{
            const Asset = {
                idBranch:this.state.branchCurrent != null?this.state.branchCurrent.value:null,
                idAccountInvent:this.state.accInventCurrent != null? this.state.accInventCurrent.value:null,
                paymentType:this.state.paymentType != null? this.state.paymentType:null,
                idReceiving:this.state.receivingCurrent != null? this.state.receivingCurrent.value:null,
                invoiceNo:this.state.invoiceNo != null? this.state.invoiceNo:null,
                date:moment(this.state.date).format('YYYY-MM-DD'),
                inventory:this.state.inventory,
            }
            const detail=this.state.arrayDetail
            let idType = [];
            let name = [];
            let id = [];
            let idReceiving = [];
            let purchaseDate = [];
            let purchasePrice = [];
            let depreciation = [];
            let depreciationDate = [];
            

            for(var i=0;i<detail.length;i++){
                idType.push(detail[i].typeCurrent != null?detail[i].typeCurrent.value:null)
                name.push(detail[i].name != null?detail[i].name:null)
                id.push(detail[i].id != null?detail[i].id:null)
                purchaseDate.push(detail[i].purchaseDate != null?moment(detail[i].purchaseDate).format('YYYY-MM-DD'):null)
                purchasePrice.push(detail[i].purchasePrice != null?detail[i].purchasePrice:null)
                depreciation.push(detail[i].depreciation != null?detail[i].depreciation:null)
                depreciationDate.push(detail[i].depreciationDate != null?moment(detail[i].depreciationDate).format('YYYY-MM-DD'):null)
                idReceiving.push(detail[i].idReceiving != null?detail[i].idReceiving:null)
            }
            this.state.form.Asset=Asset
            this.state.form.AssetDetail={
                idType:idType,
                name:name,
                id:id,
                purchaseDate:purchaseDate,
                purchasePrice:purchasePrice,
                depreciation:depreciation,
                depreciationDate:depreciationDate,
                idReceiving:idReceiving
            }
        }
       
        return this.state.form
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }

    choose(e){
        
        const arr = this.state.choose;
        this.setState({choose:!arr})
    }

    paymentType(e){
        var value = e.target.value
        const arr = this.state.paymentType;
        this.setState({paymentType:value})
    }

    chooseInventory(e){
        this.changeData({
            keyFill:"receivingList",
            type:"getReceivingList",
            key:"",
            callback:()=>{this.setState({loadingReceiving:false})}
          })
        const arr = this.state.inventory;
        this.setState({inventory:!arr})
    }

    
    handlerNumber(e,name) {
    
        var key=name;
        var value=e
        this.setState({
            [key]:value 
        })
    }

    handlerRadio(e){
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value,
            supplierCurrent:null,
            customerCurrent:null,
            accountCurrent:null,
            soCurrent:null, poCurrent:null,
           
        })
    }

    handlerSelectArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        if (e == null) {
          objectss[name] = null;
        } else {
          objectss[name] = e;
        }
        this.setState(arr);
      }
    
    handlerCurrencyArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        objectss[name] = e;
        this.setState(arr)
        this.calculateRow(i,arrayname)
    }  

    handlerInputArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        var value=e.target.value
        objectss[name] = value;
        this.setState(arr)
    }

    handlerDateTimeArray(e,name,i,arrayname,rec) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        var value = e
        var key = name
        if(rec){
            objectss[name] = value;
            objectss['depreciationDate'] = value
            this.setState({
                [key]:value
            })
        }else{
            objectss[name] = value;
            this.setState({
                [key]:value
            })
        }
       
    }

    handlerSelect(e,name,currentState,rec) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
      
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
        
    }

    fetchReceiving(){
        let item=this.state.receivingCurrent.data
        if(item !=null){
            var array=[];
            for(var o=0;o<item.length;o++){
                array.push(
                    {
                        id:item[o].id,
                        name:item[o].name_item,
                        purchaseDate:new Date(item[o].date),
                        purchasePrice:item[o].price,
                        maxDate:new Date(item[o].date),
                        depreciation:0,
                        depreciationDate:new Date(),
                        idReceiving:item[o].idReceiving,
                        idReceivingDetail:item[o].id,
                    }
                )
            }
            this.setState({arrayDetail:array})
        }
    }
    
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getAssetType':
                var url = process.env.REST_URL + 'helper/get-asset-type'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;
                params = {
                    branch: branch,
                }
            break;
            case 'getReceivingList':
                var url = process.env.REST_URL + 'helper/get-receiving-asset'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;
                params = {
                    branch: branch,
                }
            break;
            case 'getCode':
                var url=process.env.REST_URL +'helper/get-invoice-code'
                var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                params={
                    date:date,
                    branch:branch,
                }
            break;
            case 'getDepreciation':
                var url = process.env.REST_URL + 'helper/get-asset-depreciation'
                if(array == null){
                    var type =
                    this.state.typeCurrent != null
                        ? this.state.typeCurrent.value
                        : null;
                }else{
                    var type =
                    this.state[array][arraykey].typeCurrent != null
                        ? this.state[array][arraykey].typeCurrent.value
                        : null;
                }
                
                params = {
                    type: type,
                }
            break;
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;
                params = {
                    branch: branch,
                }
            }
        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }

    renderDetail(detail,i){
        
        return (
        <React.Fragment key={"detail" + i}>
            <tr>
                <td>
                    <Select2
                        isClearable={true}
                        name={"idType"}
                        required={true}
                        size={4}
                        onChange={async (e) => {
                            await this.handlerSelectArray(e,'typeCurrent',i,'arrayDetail');
                            await  this.changeData({
                                keyFill:"depreciation",
                                type:"getDepreciation",
                                key:"",
                                array:"arrayDetail",
                                arraykey:i
                            })
                        }}
                        options={this.state.typeList}
                        isLoading={this.state.loadingType}
                        title={i18n.t("Asset Type")}
                        value={detail.typeCurrent}
                        placeholder={this.state.typeText}
                        labelfield={"false"}
                    />
                </td>
                <td>
                    <Input
                        type={"input"}
                        name={"name"}
                        labelfield={"false"}
                        title={i18n.t("Name")}
                        handler={(e) =>
                            this.handlerInputArray(e, "name", i, "arrayDetail")
                        }
                        className={'form-control  form-control-md '}
                        value={detail.name}
                    />
                </td>
                <td>
                    <DatePickerCustom
                        name={"purchaseDate"}
                        title={i18n.t("Purchase Date")}
                        required={true}
                        selected={detail.purchaseDate}
                        onSelect={async (e) => {
                            await this.handlerDateTimeArray(e, 'purchaseDate',i,'arrayDetail',true);
                        }
                        }
                        onChange={(e) => { }}
                        startDay={0}
                        autoComplete={"off"}
                        dateFormat="dd MMMM yyyy"
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        maxDate={detail.maxDate}
                        className="form-control form-control-md"
                        labelfield={"false"}

                    />
                </td>
                <td>
                    <InputNumber
                        id={"purchasePrice"}
                        name="purchasePrice"
                        title={i18n.t("Purchase Price")}
                        labelfield={"false"}
                        value={detail.purchasePrice}
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                            this.handlerCurrencyArray(value, 'purchasePrice',i,'arrayDetail')
                        }
                        prefix = {'Rp. '}
                        className="form-control  form-control-md text-end"

                    />  
                </td>
                <td>
                    <InputNumber
                        id={"depreciation"}
                        name="depreciation"
                        title={i18n.t("Depreciation")}
                        labelfield={"false"}

                        value={detail.depreciation}
                        decimalsLimit={2}
                        onValueChange={(value, name) =>
                            this.handlerCurrencyArray(value, 'purchasePrice',i,'arrayDetail')
                        }
                        className="form-control  form-control-md text-end"

                        
                    />
                    <div className="col-md-2 mt-8">
                        {/* <label style={{fontWeight:'bold',marginTop:'20px'}}>
                            {i18n.t('Month')}
                        </label> */}
                    </div>
                </td>
                <td>
                    <DatePickerCustom
                        name={"depreciationDate"}
                        title={i18n.t("Depreciation Date")}
                        required={true}
                        selected={detail.depreciationDate}
                        onSelect={async (e) => {
                            await this.handlerDateTimeArray(e, 'depreciationDate',i,'arrayDetail',false);

                        }
                        }
                        onChange={(e) => { }}
                        startDay={0}
                        autoComplete={"off"}
                        dateFormat="dd MMMM yyyy"
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        minDate={detail.maxDate}
                        className="form-control form-control-md"
                        labelfield={"false"}

                    />
                </td>
            </tr>
           
        </React.Fragment>
        );
    }

    render(){
        const {helperAsset,isNewRecord,dataUpdate}=this.props

        if(helperAsset!=null && helperAsset!=''){
            this.state.branchList=helperAsset.branch
           if(this.state.setDate == 0){
                this.state.cutOffDate = new Date(helperAsset.cutOff);
                this.state.setDate = 1;
           }
        }

        if(!isNewRecord && dataUpdate != undefined){
            if(this.state.isUpdate == 0){
                this.state.branchCurrent = {value:dataUpdate.id_branch,label:dataUpdate.branch_name};
                this.state.typeCurrent = {value:dataUpdate.id_type,label:dataUpdate.type_name};
                this.state.name = dataUpdate.name;
                this.state.choose = dataUpdate.id_account != null ? false:true;
                this.state.accCurrent = {value:dataUpdate.id_account,label:dataUpdate.name_account};
                this.state.paymentNo = dataUpdate.payment_no;
                this.state.purchaseDate = new Date(dataUpdate.date_purchase);
                this.state.depreciation = dataUpdate.depreciation_format == '-'? 0 : dataUpdate.depreciation_format;
                this.state.purchasePrice = dataUpdate.price;
                this.state.cutOffDate = new Date(dataUpdate.date_purchase);
                this.state.depreciationDate = new Date(dataUpdate.date_depreciation);
                this.changeData({
                    keyFill:"typeList",
                    type:"getAssetType",
                    key:"",
                    callback:()=>{this.setState({loadingType:false})}
                  })
                  this.changeData({
                    keyFill:"accList",
                    type:"getBank",
                    callback:()=>{this.setState({loadingAcc:false})}
                  })
            }
            this.state.isUpdate = 1;
        }
        
        return (
            <React.Fragment>
            <form>
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"idBranch"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                                await this.setState({
                                    idType: '', typeCurrent:null, typeList:[], idAcc:'', accCurrent:null, accList:[]
                                });
                                if (e == null) {
                                    this.setState({
                                        idType: '', typeCurrent:null, typeList:[], idAcc:'', accCurrent:null, accList:[]
                                    });
                                } else {
                                    await this.setState({
                                        loadingType:true,loadingAcc:true,loadingReceiving:true
                                    });
                                    this.changeData({
                                        keyFill:"typeList",
                                        type:"getAssetType",
                                        key:"",
                                        callback:()=>{this.setState({loadingType:false})}
                                      })
                                      
                                    if(this.state.inventory == true){
                                        this.changeData({
                                            keyFill:"receivingList",
                                            type:"getReceivingList",
                                            key:"",
                                            callback:()=>{this.setState({loadingReceiving:false})}
                                          })
                                    }
                                   
                                    this.changeData({
                                        keyFill:"accList",
                                        type:"getBank",
                                        callback:()=>{this.setState({loadingAcc:false})}
                                      })
                                }      
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <label style={{fontWeight:'bold'}}>
                            {i18n.t("From Inventory")}
                        </label>
                        <SwitchButton 
                            onChange={(e)=>this.chooseInventory(e)}
                            name={'cutOff'}
                            value={this.state.inventory}
                            checked={this.state.inventory?true:false}
                            className={'h-20px w-30px'}
                            position={'justify-content-left'}
                        ></SwitchButton>
                    </div>
                </div>
                {this.state.inventory == false ?
                <>
                    <div className="row">
                        <div className="col mt-3">
                            <Select2
                                isClearable={true}
                                name={"idType"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "idType", "typeCurrent");
                                    await  this.changeData({
                                        keyFill:"depreciation",
                                        type:"getDepreciation",
                                        key:""
                                    })
                                }}
                                options={this.state.typeList}
                                isLoading={this.state.loadingType}
                                title={i18n.t("Asset Type")}
                                value={this.state.typeCurrent}
                                placeholder={this.state.typeText}
                                labelfield={"true"}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('idType')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-3">
                            <Input
                                type={"input"}
                                name={"name"}
                                title={i18n.t("Name")}
                                required
                                value={this.state.name}
                                handler={this.handler}
                                className={'form-control  form-control-md '}

                            />
                        </div>
                    </div>        
                    <div className="row">
                        <div className="col mt-3">
                            <label style={{fontWeight:'bold'}}>
                                {i18n.t("Cut Off")}
                            </label>
                            <SwitchButton 
                                onChange={(e)=>this.choose(e)}
                                name={'cutOff'}
                                value={this.state.choose}
                                checked={this.state.choose?true:false}
                                className={'h-20px w-30px'}
                                position={'justify-content-left'}
                            ></SwitchButton>
                        </div>
                    </div>
                    {!this.state.choose? 
                    <Fragment>
                    <div className="row">
                        <div className="col mt-3">
                            <Input
                                type={"input"}
                                name={"paymentNo"}
                                title={i18n.t("No. Payment")}
                                value={this.state.paymentNo}
                                handler={this.handler}
                                className={'form-control  form-control-md '}
                            />
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col mt-3">
                            <Select2
                                isClearable={true}
                                name={"idAcc"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "idAcc", "accCurrent");
                                }}
                                options={this.state.accList}
                                isLoading={this.state.loadingAcc}
                                title={i18n.t("Kas / Bank")}
                                value={this.state.accCurrent}
                                placeholder={this.state.accText}
                                labelfield={"true"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-3">
                            <DatePickerCustom
                                name={"purchaseDate"}
                                title={i18n.t("Purchase Date")}
                                required={true}
                                selected={this.state.purchaseDate}
                                onSelect={async (e) => {
                                    await this.handlerDateTime(e, 'purchaseDate');
                                    await this.setState({depreciationDate:e})

                                }
                                }
                                onChange={(e) => { }}
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                showYearDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                peekNextMonth
                                showMonthDropdown
                                minDate={this.state.cutOffDate}
                                className="form-control form-control-md"
                            />
                        </div>
                    </div>
                    </Fragment>
                    : 
                    <Fragment>
                    <div className="row">
                        <div className="col mt-3">
                            <DatePickerCustom
                                name={"cutOffDate"}
                                title={i18n.t("Purchase Date")}
                                required={true}
                                selected={this.state.cutOffDate}
                                onSelect={async (e) => {
                                    await this.handlerDateTime(e, 'cutOffDate');
                                    await this.setState({depreciationDate:e})
                                }
                                }
                                onChange={(e) => { }}
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                showYearDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                peekNextMonth
                                showMonthDropdown
                                maxDate={this.state.cutOffDate}
                                className="form-control form-control-md"
                            />
                        </div>
                    </div>
                    </Fragment>}
                    
                    <div className="row">
                        <div className="col mt-3">
                            <InputNumber
                                id={"purchasePrice"}
                                name="purchasePrice"
                                title={i18n.t("Purchase Price")}
                                labelfield={"true"}
                                value={this.state.purchasePrice}
                                decimalsLimit={2}
                                onValueChange={(value, name) =>
                                    this.handlerNumber(value, 'purchasePrice')
                                }
                                prefix = {'Rp. '}
                                className="form-control  form-control-md text-end"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mt-3">
                        <InputNumber
                                id={"depreciation"}
                                name="depreciation"
                                title={i18n.t("Depreciation")}
                                labelfield={"true"}

                                value={this.state.depreciation}
                                decimalsLimit={2}
                                onValueChange={(value, name) =>
                                    this.handlerNumber(value, 'depreciation')
                                }
                            
                                className="form-control  form-control-md text-end"
                                
                            />
                        </div>
                        <div className="col-md-2 mt-8">
                            <label style={{fontWeight:'bold',marginTop:'20px'}}>
                                {i18n.t('Month')}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-3">
                            <DatePickerCustom
                                name={"depreciationDate"}
                                title={i18n.t("Depreciation Date")}
                                required={true}
                                selected={this.state.depreciationDate}
                                onSelect={async (e) => {
                                    await this.handlerDateTime(e, 'depreciationDate');
                                }
                                }
                                onChange={(e) => { }}
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                showYearDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                peekNextMonth
                                showMonthDropdown
                                className="form-control form-control-md"
                            />
                        </div>
                    </div>
                </>
                :
                <>
                <div className="row">
                    <div className="col-6 mt-3 ">
                        <Select2
                            isClearable={true}
                            name={"idReceiving"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if(e != null){
                                    await this.handlerSelect(e, "idReceiving", "receivingCurrent",true);
                                    await this.changeData({
                                        keyFill:"invoiceNo",
                                        type:"getCode",
                                        key:"code",
                                    });
                                    await this.fetchReceiving();
                                }else{
                                    await this.handlerSelect(e, "idReceiving", "receivingCurrent",true);
                                    await this.setState({arrayDetail:[]})
                                }
                                
                            }}
                            options={this.state.receivingList}
                            title={i18n.t("Receiving")}
                            value={this.state.receivingCurrent}
                            placeholder={this.state.receivingText}
                            labelfield={"true"}
                            isLoading={this.state.loadingReceiving}
                        />
                        {/* <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div> */}
                    </div>
                    <div className="col-6  mt-3" >
                        <Input
                            type={"input"}
                            name={"invoiceNo"}
                            title={i18n.t("Transaction No")}
                            required
                            disabled
                            value={this.state.invoiceNo}
                            handler={this.handler}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <Table striped bordered hover width={"100%"} style={{paddingLeft:'50px'}}>
                            <thead>
                                <tr className='fw-bold color-head' >
                                    <th className="text-center" width="20%">{i18n.t("Asset Type")}</th>
                                    <th className="text-center" width="20%">{i18n.t("Name")}</th>
                                    <th className="text-center" width="15%">{i18n.t("Purchase Date")}</th>
                                    <th className="text-center" width="15%">{i18n.t("Purchase Price")}</th>
                                    <th className="text-center" width="10%">{i18n.t("Depreciation")}</th>
                                    <th className="text-center" width="15%">{i18n.t("Depreciation Date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail, index) => {
                                    return this.renderDetail(detail, index);
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-8">
                        <Radio
                            list={this.state.paymentTypeList}
                            name={"paymentType"}
                            title={i18n.t("Payment Type")}
                            inline={true}
                            value={this.state.paymentType}
                            handler={async (e) => {
                                await this.paymentType(e)
                                
                            }}
                            labelfield={"true"}
                        />
                    </div>
                </div>
                {this.state.paymentType == 'Cash'?
                <>
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"idAcc"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idAcc", "accInventCurrent");
                            }}
                            options={this.state.accList}
                            isLoading={this.state.loadingAcc}
                            title={i18n.t("Kas / Bank")}
                            value={this.state.accInventCurrent}
                            placeholder={this.state.accText}
                            labelfield={"true"}
                        />
                    </div>
                </div>
                </>
                :null}
                </>
                
                }
            </form>
           </React.Fragment>
        );
    }
}

export default FormAsset;
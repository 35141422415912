import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import DetailJournal, {
  Journal,
} from "../../../../components/modules/accounting/journal/detail";
import JournalMiddleware from "../../../../middleware/modules/accounting/journal";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, journal }) => ({
  isProcessing: journal.isProcessing,
  isError: journal.isError,
  errorMessage: journal.errorMessage,
  datalist: journal.dataDetail,
  getHelper: journal.getHelper,
  datadetail: journal.dataDetail,
  showModal: journal.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => JournalMiddleware.getHelperFull(),
      getDetailData: (id) => JournalMiddleware.detail(id),
      getDetail: (id) => JournalMiddleware.detailData(id),
      getHelper: () => JournalMiddleware.getHelper(),
      create: () => JournalMiddleware.create(),
      search: (data) => JournalMiddleware.list(data),
      reset: () => JournalMiddleware.resetList(),
      update: (data) => JournalMiddleware.update(data),
      cancel: (id, search) => JournalMiddleware.beforeCancel(id, search),
    },
    dispatch
  );
};
class JournalDetailContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.cancelItem = this.cancelItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  cancelItem(id, search) {
    this.props.cancel(id, search);
  }
  updateItem(id) {
    this.props.getHelper();
    this.props.update(id);
  }
  createItem() {
    this.props.getHelper();
    this.props.create();
  }

  UNSAFE_componentWillMount() {
    this.props.getDetailData(this.props.id);
    this.props.getDetail(this.props.id);
  }

  render() {
    const { isProcessing, search, datalist, datadetail, showModal } =
      this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Detail Journal :")}</title>
        </Helmet>
      <DetailJournal
        {...this.props}
        data={datalist}
        dataDetail={datadetail}
        isProcessing={isProcessing}
        showModal={showModal}
        cancelItem={this.cancelItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalDetailContainer);

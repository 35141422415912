import MemoOutActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataDetail:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperMemoOut:null,
  helperJournal:null,
  errorInternal:false,
  printMemoOut:null,
};

function MemoOutReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MemoOutActions.RESET_LIST:
      return INITIAL_STATE;
    case MemoOutActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case MemoOutActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case MemoOutActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case MemoOutActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case MemoOutActions.CANCEL_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case MemoOutActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case MemoOutActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case MemoOutActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case MemoOutActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case MemoOutActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case MemoOutActions.GET_HELPER_MEMO_OUT:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case MemoOutActions.GET_HELPERFULL_MEMO_OUT:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case MemoOutActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case MemoOutActions.AFTER_DATA:
      return { ...state,errorInternal:false, onSubmit:false, data:action.payload,isNewRecord:false}      
    case MemoOutActions.BEFORE_PRINT_MEMO_OUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printMemoOut:null};
    case MemoOutActions.PRINT_MEMO_OUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printMemoOut:action.payload};
    case MemoOutActions.APPROVE_MEMOOUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case MemoOutActions.REJECT_MEMOOUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    default:
      return state;
    }
}

export default MemoOutReducer;

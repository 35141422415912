import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form:{
                name:"",
                status:1,
            },
            isUpdate: 0
        }
        this.handler = this.handler.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }
    runSubmit() {
        return this.state.form
    }
    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render() {
        const { dataUpdate, isNewRecord } = this.props
        
        if (dataUpdate != null && !isNewRecord) {
            if (this.state.isUpdate == 0) {
                this.state.form.name = dataUpdate.name
                this.state.form.status = dataUpdate.status
                this.state.isUpdate = 1
            }
        }
        return (
            <form>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <Radio
                        list={StatusForm}
                        name={"status"}
                        title={i18n.t("Status")}
                        inline={false}
                        value={this.state.form.status}
                        handler={this.handler}
                    />
                </div>
            </form>
        );
    }
}

export default Form;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import AssetReport from '../../../../components/modules/report/asset-report';
import AssetReportMiddleware from '../../../../middleware/modules/report/asset-report';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,assetReport }) => ({
  isProcessing: assetReport.isProcessing,
  isError: assetReport.isError,
  errorMessage: assetReport.errorMessage,
  dataList:assetReport.data,
  showModal:assetReport.showModal,
  helperList:assetReport.helperAssetReport
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>AssetReportMiddleware.getHelper(),
    reset: () =>AssetReportMiddleware.resetList(),
    setData: (data) =>AssetReportMiddleware.list(data),
    excel: (data) =>AssetReportMiddleware.showExcel(data),
  }, dispatch);
};
class AssetReportContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Asset Report")}
          </title>
        </Helmet>
      <AssetReport
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetReportContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Admin } from '../../../../components/modules/user/admin';
// middlewares
import AdminMiddleware from '../../../../middleware/modules/user/admin';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,admin }) => ({
  isProcessing: admin.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  data:admin.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>AdminMiddleware.resetList(),
    create: (search) =>AdminMiddleware.create(search),
    search: data =>AdminMiddleware.list(data),
    update: data =>AdminMiddleware.update(data),
    delete: (id,search) =>AdminMiddleware.beforeDelete(id,search),
    //helpers
    getHelper: () =>AdminMiddleware.getHelper(),
   
  }, dispatch);
};
class AdminContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
    this.props.getHelper()
  }
  createItem(search=null){
    this.props.create(search)
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Assignment List")}
          </title>
        </Helmet>
      <Admin
        {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);

import CustomerPerformanceActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  dataTable:{},
  dataMonitor:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperCustomerPerformance:null,
  errorInternal:false
};

function CustomerPerformanceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CustomerPerformanceActions.RESET_LIST:
      return INITIAL_STATE;
    case CustomerPerformanceActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case CustomerPerformanceActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case CustomerPerformanceActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case CustomerPerformanceActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CustomerPerformanceActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CustomerPerformanceActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case CustomerPerformanceActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case CustomerPerformanceActions.SHOW_DATA_TABLE:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataTable: action.payload, errorMessage: [],isProcessing:false };
    case CustomerPerformanceActions.SHOW_DATA_MONITOR:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, dataMonitor: action.payload, errorMessage: [],isProcessing:false };
    case CustomerPerformanceActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case CustomerPerformanceActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case CustomerPerformanceActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case CustomerPerformanceActions.GET_HELPER_CUSTOMERPERFORMANCE:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCustomerPerformance:action.payload};
    case CustomerPerformanceActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case CustomerPerformanceActions.VOID_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form};
       
    default:
      return state;
    }
}

export default CustomerPerformanceReducer;

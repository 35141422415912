import WorkCalendarActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperWorkCalendar:[],
  errorInternal:false
};

function WorkCalendarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WorkCalendarActions.RESET_LIST:
      return INITIAL_STATE;
    case WorkCalendarActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case WorkCalendarActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case WorkCalendarActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case WorkCalendarActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WorkCalendarActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WorkCalendarActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case WorkCalendarActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case WorkCalendarActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case WorkCalendarActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case WorkCalendarActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case WorkCalendarActions.GET_HELPER_WORKCALENDAR:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperWorkCalendar:action.payload};
    case WorkCalendarActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default WorkCalendarReducer;

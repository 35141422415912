import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import Coa2 from "../../../../components/modules/accounting/coa2";
import Coa2Middleware from "../../../../middleware/modules/accounting/coa2";
import Coa1Middleware from "../../../../middleware/modules/accounting/coa1";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, coa2 }) => ({
  isProcessing: coa2.isProcessing,
  isError: coa2.isError,
  errorMessage: coa2.errorMessage,
  datalist: coa2.data,
  showModal: coa2.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => Coa2Middleware.create(),
      reset: () => Coa2Middleware.resetList(),
      search: (data) => Coa2Middleware.list(data),
      update: (data) => Coa2Middleware.update(data),
      delete: (id, search) => Coa2Middleware.beforeDelete(id, search),
      helperCOA1: () => Coa1Middleware.getHelper(),
    },
    dispatch
  );
};
class Coa2Container extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.helperCOA1();
    this.props.update(id);
  }
  createItem() {
    this.props.helperCOA1();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Coa 2")}</title>
        </Helmet>
        <Coa2
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
        />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coa2Container);

import React,{ useState } from "react"

export default function DataSales(data){
    return (
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-15 px-0">
                
                <div className="d-flex flex-column text-center mb-9 px-9">
                    
                    <div className="symbol symbol-80px symbol-lg-150px mb-4">
                        <img src={data?.avatar || "/assets/media/avatars/admin.jpg"} className="" alt="" />
                    </div>
                    <div className="text-center">
                        
                        <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-4">{data?.name||''}</a>
                        
                        
                        <span className="text-muted d-block fw-semibold">{data?.positionName||''}</span>
                        
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}
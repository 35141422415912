import React, { Component, Fragment, useState } from "react";
import { Button, Spinner, Tab, Tabs, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel, faDisplay, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";

const MySwal = withReactContent(Swal);
export class FormPurchasingSupplierReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierList: [],
      supplierCurrent: null,
      idSupplier: null,
      supplierText: i18n.t('All'),
      branchList: [],
      branchCurrent: null,
      idBranch: null,
      branchText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate:new Date(),
      endDate:new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      page: 1,
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      typeRs: 2,
      search: null,
      idSupp: null,
      show: 0,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      showDetail: 0,
      invoiceDetail: null,
      invoiceNo: null,
      listData: [],
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setDataDetail();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setDataDetail();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  setDataSupplier() {
    var startDate = this.state.typeRs == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.typeRs == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
     var idSupplier = (this.state.idSupplier == null) ? '' : this.state.idSupplier;
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var type = this.state.typeRs;

    let params = {
      startDateRs: startDate,
      endDateRs: endDate,
      idSupplier: idSupplier,
      idBranch: idBranch,
      limit: limit,
      page: page,
      type:type,
    }
    this.props.setDataSupplier(params);
  }

  setDataDetail() {
    var startDate = this.state.typeRs == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.typeRs == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var idSupp = (this.state.idSupp == null) ? '' : this.state.idSupp;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var search = (this.state.search == null) ? '' : this.state.search;

    let params = {
      startDate: startDate,
      endDate: endDate,
      idSupp: idSupp,
      idBranch: idBranch,
      limit: limit,
      page: page,
      search: search,
    }
    this.props.setSupplierDetail(params);
  }

  setDataInvoice() {
    var invoice = (this.state.invoiceNo == null) ? '' : this.state.invoiceNo;

    let params = {
      invoice: invoice,
      type: 1,
    }
    this.props.setInvoiceDetail(params);
  }

  showDetail(id) {
    this.setState({ showDetail: 1, page: 1, limit: 1, idSupp: id }, () => this.setDataDetail())
  }

  showInvoice(id) {
    this.setState({ showDetail: 2, page: 1, limit: 1, invoiceNo: id, search: null }, () => this.setDataInvoice())
  }

  renderDetailList() {
    const { datalistDetail, dataSuppDetail } = this.props;
    let row = datalistDetail.data
    let det = [];
    let no = 1;
    let total = 0;
    let payment = 0;
    let remaining = 0;
    if (datalistDetail.data != undefined && datalistDetail.data != null  && row != null) {
      Swal.close();
      if (datalistDetail.data.length > 0) {
        for (var p = 0; p < datalistDetail.data.length; p++) {
          let id = row[p].id_purchasing;
          total += parseFloat(row[p].total);
          remaining += parseFloat(row[p].remaining);
          payment += parseFloat(row[p].total_payment);
          det.push(
            <tr className="border " key={p} >
              <td className="text-center">{no++}</td>
              <td><span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={() => this.showInvoice(id)}
              >
                {row[p].no_invoice}
              </span>
              </td>
              <td >{row[p].name_branch}</td>
              <td >{row[p].name_supplier}</td>
              <td >{row[p].date}</td>
              <td >{row[p].due_date}</td>
              <td style={{ textAlign: 'right' }} >{row[p].total_format}</td>
              <td style={{ textAlign: 'right' }} >{row[p].total_payment_format}</td>
              <td style={{ textAlign: 'right' }} >{row[p].remaining_format}</td>
              <td style={{ textAlign: 'right' }} >{row[p].ap_aging + i18n.t(" Day")} </td>
              <td><span
                style={{
                  cursor: "pointer",
                }}
                className={row[p].status == 0 ? "badge badge-light-success" : "badge badge-light-danger"}
              >
                {row[p].status == 0 ? i18n.t('Paid') : i18n.t('Unpaid')}
              </span>
              </td>
            </tr>
          );

        }
        det.push(
          <tr className="border " >
            <td colSpan={'6'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Total")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(payment)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(remaining)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
          </tr>)
      } else {
        det.push(
          <tr className="border  " >
            <td colSpan={'11'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
    }

    return det;
  }

  renderInvoice() {
    const { datalistInvoiceDetail } = this.props;
    let row = datalistInvoiceDetail.detail
    let no = 1;
    let total = 0;
    let html = [];
    if (datalistInvoiceDetail != null  && row != null) {
      for (var p = 0; p < datalistInvoiceDetail.count; p++) {
        html.push(
          <tr>
            <td style={{ textAlign: 'center' }}>{no++}</td>
            <td>{row[p].code_item}</td>
            <td>{row[p].name_item}</td>
            <td>{row[p].name_unit}</td>
            <td style={{ textAlign: 'right' }}>{row[p].qty_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].unit_price_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].disc_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].tax_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].total_format}</td>
          </tr>
        );
        total += parseFloat(row[p].total) || 0;
      }
    }
    // html.push(
    //   <tr className="border " >
    //     <td colSpan={'6'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Total Invoice")}</td>
    //     <td></td>
    //     <td></td>
    //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
    //       .toFixed(2)
    //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
    //   </tr>)
    html.push(
      <tr className="border " >
        <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Sub. Total")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.sub_total_format}</td>
      </tr>)
    html.push(
      <tr className="border " >
        <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Discount")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.discount_format}</td>
      </tr>)
    html.push(
      <tr className="border " >
        <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Total Payment")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.total_payment_format}</td>
      </tr>)
    html.push(
      <tr className="border " >
        <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Remaining Payment")}</td>
        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.remaining_format}</td>
      </tr>)
    return html;
  }

  renderList() {
    const { dataSupp } = this.props
    let row = dataSupp.data
    let html = [];
    let no = 1;
    let total = 0;

    if (dataSupp != undefined && dataSupp != null  && row != null) {
      Swal.close();
      if (row.length > 0) {
        for (var p = 0; p < row.length; p++) {
          let id = row[p].id;
          html.push(
            <tr className="border " key={p} >
              <td className="text-center">{no++}</td>
              <td><span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
                onClick={() => this.showDetail(id)}
              >
                {row[p].name}
              </span>
              </td>
              <td style={{ textAlign: 'right' }}>{row[p].balance_format}</td>
            </tr>)
          total += parseFloat(row[p].balance) || 0;
        }
        html.push(
          <tr className="border " >
            <td colSpan={'2'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t('TOTAL')}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

          </tr>)
      } else {
        html.push(
          <tr className="border  " >
            <td colSpan={'3'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
      return html;

    } else {
      Swal.close();
      html.push(
        <tr>
           <td colSpan={'3'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
        </tr>
      );
      return html;
    }

  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var startDate = this.state.typeRs == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : this.state.periodCurrent.startDate;
    var endDate = this.state.typeRs == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : this.state.periodCurrent.endDate;
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/purchasing-report-supplier/excel",
      method: "GET",
      params: {
        startDate: startDate,
        endDate: endDate,
        idSupp: (this.state.idSupp == null) ? '' : this.state.idSupp,
        idBranch: (this.state.idBranch == null) ? '' : this.state.idBranch,


      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
          moment(startDate).format("D/M/YYYY") + '/' +
          moment(endDate).format("D/M/YYYY") + ' ' +
          nameFile +
          ' Laporan Pembelian Per Supplier.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    if (this.state.showDetail == 0) {
      this.setState({
        [key]: value,
        [currentState]: e
      }, () => this.setDataSupplier()
      )
    } else if (this.state.showDetail == 1) {
      this.setState({
        [key]: value,
        [currentState]: e
      }, () => this.setDataDetail()
      )
    }

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (this.state.showDetail == 0) {
      if (event.target.value == 0) {
        this.setState({ page: (get - 1) }, () => this.setDataSupplier());
      } else if (event.target.value == (count - 1)) {
        this.setState({ page: (get + 1) }, () => this.setDataSupplier());
      } else {
        this.setState({ page: event.target.value }, () => this.setDataSupplier())
      }
    } else if (this.state.showDetail == 1) {
      if (event.target.value == 0) {
        this.setState({ page: (get - 1) }, () => this.setDataDetail());
      } else if (event.target.value == (count - 1)) {
        this.setState({ page: (get + 1) }, () => this.setDataDetail());
      } else {
        this.setState({ page: event.target.value }, () => this.setDataDetail())
      }
    }
  }

  renderPagination() {
    const { dataSupp, datalistDetail } = this.props;
    var setLimit = dataSupp;

    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '10', label: '10' }
      this.state.show = 1;
    }
    if (this.state.showDetail == 0) {
      setLimit = dataSupp
    } else if (this.state.showDetail == 1) {
      setLimit = datalistDetail
    }
    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {setLimit.links != null
                ? setLimit.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == setLimit.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, setLimit.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != setLimit.links.length - 1 ? item["label"] : ""}
                      {i == setLimit.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  render() {
    const { isProcessing, helperPurchasingReport, datalist, data, datalistInvoiceDetail } = this.props;
    this.state.supplierList = helperPurchasingReport.supplier;
    this.state.periodList = helperPurchasingReport.workCalendar;
    this.state.branchList = helperPurchasingReport.branch;
    if (this.state.showDetail == 0) {
      return (
        <Fragment>
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body pt-3">
                    <div className="row mb-4" >
                      <div className="row">
                        <div className="col-mb-3">
                          <Radio
                            list={Period}
                            name={"typeRs"}
                            title={""}
                            inline={false}
                            value={this.state.typeRs}
                            handler={this.handlerRadio}
                          />
                        </div>
                      </div>
                      {this.state.typeRs == 1 ?
                        <Fragment>
                          <div className="col-3 mt-3">
                            <Select2
                              isClearable={true}
                              name={"period"}
                              required={true}
                              size={4}
                              onChange={async (e) => {
                                await this.handlerSelect(e, "idPeriod", "periodCurrent");
                              }}
                              options={this.state.periodList}
                              title={i18n.t("Week Period")}
                              value={this.state.periodCurrent}
                              placeholder={this.state.periodText}
                            />
                             <div className="fv-plugins-message-container invalid-feedback">
                              {this.renderError('startDateRs')}
                            </div>
                          </div>
                        </Fragment>
                        :
                        <Fragment>
                          <div className="col-3 mt-3">
                            <DatePickerCustom
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              selectsRange={true}
                              isClearable={false}
                              name={"date"}
                              title={i18n.t("Date Period")}
                              required={true}
                              onChange={(e) => {
                                const [start, end] = e;
                                this.setState({ startDate: start, endDate: end });
                              }}
                              la
                              showYearDropdown
                              yearDropdownItemNumber={15}
                              scrollableYearDropdown
                              peekNextMonth
                              showMonthDropdown
                              autoComplete={"off"}
                              dateFormat="dd MMM yyyy"
                              maxDate={new Date()}
                              className="form-control form-control-sm"
                            />
                          </div>

                        </Fragment>
                      }
                      <div className="col-sm-3 m-3">
                        <Select2
                          isClearable={true}
                          name={"idBranch"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            if (e == null) {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                            } else {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                            }
                          }}
                          options={this.state.branchList}
                          title={i18n.t("Branch")}
                          value={this.state.branchCurrent}
                          placeholder={this.state.branchText}
                          labelfield={"true"}
                        />
                      </div>
                      <div className="col-sm-3 m-3">
                        <Select2
                          isClearable={true}
                          name={"idSupplier"}
                          labelfield={"true"}
                          onChange={async (e) => {
                            if (e == null) {
                              this.handlerSelect(e, "idSupplier", "supplierCurrent")
                            }
                            else {
                              this.handlerSelect(e, "idSupplier", "supplierCurrent")
                            }
                          }}
                          options={this.state.supplierList}
                          title={i18n.t("Supplier")}
                          value={this.state.supplierCurrent}
                          placeholder={this.state.supplierText}
                        />
                      </div>

                      <div className="col text-end">
                      </div>
                      <div className="row mb-4">
                      <div className="col-sm-2">
                              <Button onClick={async (e) => {
                                await this.setState({ page: 1 }, () => this.setDataSupplier())
                                await this.setState({ headeTable: true })
                              }}
                                variant="success" className="btn btn-md btn btn-success"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                            </div>
                        </div>
                      </div>

                    {this.state.headeTable == true ?
                      <React.Fragment>
                        {!isProcessing ?
                        <Fragment>
                          <table className="table table-responsive table-bordered  border">
                            <thead>
                              <tr className="text-center fw-semibold fs-6 text-gray-800 bg-aquamarine square ">
                                <th width={"3%"} >{i18n.t("No.")}</th>
                                <th width={"57%"}>{i18n.t("Supplier Name")}</th>
                                <th width={"40%"}>{i18n.t("Total Purchase")}</th>

                              </tr>
                            </thead>
                            <tbody>
                              {this.renderList()}
                            </tbody>
                          </table>
                          {this.renderPagination()}
                          </Fragment>
                          : this.renderLoading()
                        }
                      </React.Fragment> :
                      ''}
                  </div>
                </div>
          </div>
        </Fragment>
      );
    } else if (this.state.showDetail == 1) {
      return (
        <Fragment>
          <div className="row mb-6">
            <div className="col-sm-2 ">
              {this.state.showDetail ? (
                <Button
                  key={"secondary"}
                  type="button"
                  variant="secondary"
                  onClick={(e) => this.setState({ showDetail: 0 })}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                  ></FontAwesomeIcon>
                </Button>
              ) : ''}
            </div>
            <div className="col-sm-2">
              <Button onClick={async (e) => {
                await this.convertExcel()
              }}
                variant="success" className="btn btn-md btn btn-seceondary"> <FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon> Excel </Button>
            </div>
            <div className="col-sm-6 ">

            </div>
            <div className="row align-items-end col-sm-2 ">
              <input
                type={"text"}
                className="form-control"
                placeholder="Search"
                name={"search"}
                title={""}
                value={this.state.search}
                onChange={(e) => this.handler(e)}
                onKeyPress={this._onEnter}
              />
            </div>
          </div>
          {this.state.headeTable == true ?
            <React.Fragment>
              {!isProcessing ?
                <table className="table table-responsive table-bordered  border ">
                  <thead>
                    <tr className="text-center fw-semibold fs-6 text-gray-800 bg-aquamarine square border ">
                      <th width={"3%"} >{i18n.t("No.")}</th>
                      <th width={"10%"}>{i18n.t("Invoice No.")}</th>
                      <th width={"10%"}>{i18n.t("Branch Name")}</th>
                      <th width={"12%"}>{i18n.t("Supplier Name")}</th>
                      <th width={"10%"}>{i18n.t("Date")}</th>
                      <th width={"10%"}>{i18n.t("Due Date")}</th>
                      <th width={"11%"}>{i18n.t("Total Invoice")}</th>
                      <th width={"11%"}>{i18n.t("Total Payment")}</th>
                      <th width={"11%"}>{i18n.t("Remaining Payment")}</th>
                      <th width={"6%"}>{i18n.t("AP Aging")}</th>
                      <th width={"6%"}>{i18n.t("Status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderDetailList()}
                  </tbody>
                </table>

                : this.renderLoading()
              }
              {this.renderPagination()}
            </React.Fragment> :
            ''}
        </Fragment>
      );
    } else if (this.state.showDetail == 2) {
      if (datalistInvoiceDetail != null) {
        let invoiceDetail = datalistInvoiceDetail;
        Swal.close();
        return (
          <Fragment>
            <div>
              {this.state.showDetail ? (
                <Button
                  key={"secondary"}
                  type="button"
                  variant="secondary"
                  onClick={(e) =>
                    this.setState({
                      showDetail: 1,
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                  ></FontAwesomeIcon>
                </Button>
              ) : ''}
            </div>

            <br />
            {this.state.headeTable == true ?
              <React.Fragment>
                {!isProcessing ?
                  <Fragment>
                    <Table className="table table-responsive table-bordered ">
                      <thead style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <tr className="">
                          <th>{i18n.t("Supplier Name")} :  <br />
                            {invoiceDetail.name_supplier} </th>
                          <th>{i18n.t("Branch Name")} :  <br />
                            {invoiceDetail.name_branch} </th>
                          <th>{i18n.t("Status")} :  <br />
                            {invoiceDetail.remaining > 0 ?
                              i18n.t('Unpaid') : i18n.t('Paid')
                            } </th>
                        </tr>
                        <tr className="">
                          <th>{i18n.t("Invoice No.")} : <br />
                            {invoiceDetail.no_invoice} </th>
                          <th>{i18n.t("Transaction Date")} : <br />
                            {invoiceDetail.date} </th>
                          <th>{i18n.t("Due Date")} : <br />
                            {invoiceDetail.due_date} </th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </Table>
                    <table className="table table-responsive table-bordered  border ">
                      <thead>
                        <tr className="border  text-center">
                          <th width={"3%"} className="text-center">{i18n.t("No")}</th>
                          <th width={"7%"} >{i18n.t("Item Code")}</th>
                          <th width={"15%"}>{i18n.t("Item Name")}</th>
                          <th width={"10%"}>{i18n.t("Unit")}</th>
                          <th width={"10%"}>{i18n.t("Qty")}</th>
                          <th width={"10%"}>{i18n.t("Unit Price")}</th>
                          <th width={"7%"}>{i18n.t("Discount")}</th>
                          <th width={"7%"}>{i18n.t("Tax")}</th>
                          <th width={"15%"}>{i18n.t("Total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderInvoice()}
                      </tbody>
                    </table>
                  </Fragment>
                  : this.renderLoading()
                }
              </React.Fragment> :
              ''}
          </Fragment>
        );
      }
    }
  }
}

export default FormPurchasingSupplierReport;

export default class SellingPriceActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_SELLINGPRICE='GET_HELPER_SELLINGPRICE';
    static GET_HELPER_SELLINGPRICE_FULL ='GET_HELPER_SELLINGPRICE_FULL';
    static BEFORE_PRINT_SELLINGPRICE ='BEFORE_PRINT_SELLINGPRICE';
    static PRINT_SELLINGPRICE ='PRINT_SELLINGPRICE';
    static RESET_LIST="RESET_LIST";
    static APPROVE_SELLINGPRICE='APPROVE_SELLINGPRICE';
    static REJECT_SELLINGPRICE='REJECT_SELLINGPRICE';
  
    static submit(){
      return {
        type: SellingPriceActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: SellingPriceActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: SellingPriceActions.UPDATE_DATA,
        payload: data
      };
    }

    static approve() {
      return {
        type: SellingPriceActions.APPROVE_SELLINGPRICE
      };
    }

    static reject() {
      return {
        type: SellingPriceActions.REJECT_SELLINGPRICE
      };
    }
  
    static view(data) {
      return {
        type: SellingPriceActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: SellingPriceActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: SellingPriceActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: SellingPriceActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: SellingPriceActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: SellingPriceActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: SellingPriceActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: SellingPriceActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: SellingPriceActions.GET_HELPER_SELLINGPRICE,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  SellingPriceActions.GET_HELPER_SELLINGPRICE_FULL,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: SellingPriceActions.BEFORE_PRINT_SELLINGPRICE,
      };
    }
    static print(data){
      return {
        type: SellingPriceActions.PRINT_SELLINGPRICE,
        payload: data
      };
    }
  }
  
  
export default class WarehouseOutActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static CANCEL_DATA='CANCEL_DATA'
    static GET_HELPER_WarehouseOut_FULL ='GET_HELPER_WarehouseOut_FULL';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_WAREHOUSE_FULL='GET_HELPER_WAREHOUSE_FULL';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_WHOUT='PRINT_WHOUT';
    static BEFORE_PRINT_WHOUT='BEFORE_PRINT_WHOUT';
  
  
    static submit(){
      return {
        type: WarehouseOutActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: WarehouseOutActions.NEW_DATA,
        payload: data
      };
    }
  
    static cancel() {
      return {
        type: WarehouseOutActions.CANCEL_DATA
      };
    }

    static update(data) {
      return {
        type: WarehouseOutActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: WarehouseOutActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: WarehouseOutActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: WarehouseOutActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: WarehouseOutActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: WarehouseOutActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: WarehouseOutActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: WarehouseOutActions.RESET_DATA,
      };
    }

    static helperFull(data){
        return {
          type: WarehouseOutActions.GET_HELPER_WAREHOUSE_FULL,
          payload:data
        };
      }

      static resetList(){
        return {
          type: WarehouseOutActions.RESET_LIST,
        };
      }

      static errorInternal(error){
        return {
          type: WarehouseOutActions.ERROR_SERVER,
          payload: error
        };
      }

      static beforePrint(){
        return {
          type: WarehouseOutActions.BEFORE_PRINT_WHOUT,
        };
      }
      static print(data){
        return {
          type: WarehouseOutActions.PRINT_WHOUT,
          payload: data
        };
      }
  }
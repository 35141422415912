import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import PurchasingReport from '../../../../components/modules/report/purchasing-report';
import PurchasingReportMiddleware from '../../../../middleware/modules/report/purchasing-report';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares


const mapStateToProps = ({ auth, purchasingReport }) => ({
  isProcessing: purchasingReport.isProcessing,
  isError: purchasingReport.isError,
  errorMessage: purchasingReport.errorMessage,
  datalist: purchasingReport.data,
  showModal: purchasingReport.showModal,
  helperList: purchasingReport.helperPurchasingReport
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper: () => PurchasingReportMiddleware.getHelper(),
    reset: () => PurchasingReportMiddleware.resetList(),
    excel: (data) => PurchasingReportMiddleware.showExcel(data),
  }, dispatch);
};
class PurchasingReportContainer extends Component {

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()

  }

  render() {
    const { datalist } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Invoice Report List")}
          </title>
        </Helmet>
        <PurchasingReport
          {...this.props}
          data={datalist}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingReportContainer);

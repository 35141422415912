import ApprovalActions from './actions';

const INITIAL_STATE = {
  data: null,
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  errorInternal:false,
  helperApproval:null
};

function ApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ApprovalActions.RESET_LIST:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,data:{}};
    case ApprovalActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ApprovalActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false};
    case ApprovalActions.VIEW_DATA:
      return { ...state, isProcessing: false, data:action.payload,isNewRecord:false,showModal:true};
    case ApprovalActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ApprovalActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ApprovalActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case ApprovalActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case ApprovalActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , data:action.payload,onSubmit:false};
    case ApprovalActions.PROCESS_DATA:
      return { ...state, isProcessing: true, data:null,isError: false,isSuccess:false};
    case ApprovalActions.GET_HELPER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperApproval:action.payload};  
    case ApprovalActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case ApprovalActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
     
    default:
      return state;
    }
}


export default ApprovalReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { bindActionCreators } from "redux";
import AuthMiddleware from "../../reducers/auth/middleware";
import useScript from "../../components/utilities/useScript";
import LeftSide from "../../components/auth/LeftSide";

const mapStateToProps = ({ auth}) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};
class LoginLayout extends Component {
  render() {
    const {isAuthenticated}=this.props
    if(isAuthenticated){
      return <Navigate to="/"></Navigate>
    }
      return (
        <div>
          <div id="login" className="d-flex flex-column flex-root min-vh-100">
              <div className="d-flex flex-column flex-column-fluid flex-lg-row">
                <LeftSide />
                <Outlet />
              </div>
          </div>
        </div>
        );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);;

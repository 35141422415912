export default class CustomerPerformanceActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static SHOW_DATA_TABLE='SHOW_DATA_TABLE';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_CUSTOMERPERFORMANCE='GET_HELPER_CUSTOMERPERFORMANCE';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static VOID_DATA="VOID_DATA";
  static SHOW_DATA_MONITOR="SHOW_DATA_MONITOR";

  static submit(){
    return {
      type: CustomerPerformanceActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: CustomerPerformanceActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: CustomerPerformanceActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: CustomerPerformanceActions.VIEW_DATA,
      payload: data
    };
  }
  
  static delete(data) {
    return {
      type: CustomerPerformanceActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: CustomerPerformanceActions.SHOW_DATA,
      payload: data
    };
  }
  static listTable(dataTable) {
    return {
      type: CustomerPerformanceActions.SHOW_DATA_TABLE,
      payload: dataTable
    };
  }
  static listMonitor(dataMonitor) {
    return {
      type: CustomerPerformanceActions.SHOW_DATA_MONITOR,
      payload: dataMonitor
    };
  }
  static error(error){
    return {
      type: CustomerPerformanceActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: CustomerPerformanceActions.SUCCESS_DATA,
    };
  }
  static void(data) {
    return {
      type: CustomerPerformanceActions.VOID_DATA,
      payload: data
    };
    
  }
  static process(){
    return {
      type: CustomerPerformanceActions.PROCESS_DATA,
    };
  }
  static resetList(){
    return {
      type: CustomerPerformanceActions.RESET_LIST,
    };
  }
  static reset(){
    return {
      type: CustomerPerformanceActions.RESET_DATA,
    };
  }
  static helper(helperSalesChart){
    return {
      type: CustomerPerformanceActions.GET_HELPER_CUSTOMERPERFORMANCE,
      payload:helperSalesChart
    };
  }
  static helperFull(data){
    return {
      type: CustomerPerformanceActions.GET_HELPERFULL_CUSTOMERPERFORMANCE,
      payload:data
    };
  }

  static errorInternal(error){
    return {
      type: CustomerPerformanceActions.ERROR_SERVER,
      payload: error
    };
  }
}
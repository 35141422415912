import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import LogBgInContainer from "../../../../containers/modules/accounting/log-bg/index-in";
import LogBgOutContainer from "../../../../containers/modules/accounting/log-bg/index-out";

const MySwal = withReactContent(Swal);
export class LogBg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Accounting"), class: " text-gray-600" },
        {
          link: null,
          title: i18n.t("Billyet Giro"),
          class: " text-gray-500",
        },
      ],
    }

    this.runSearch = this.runSearch.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  renderTab() {
    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="Out"
        id={"tabs"}
        className="mb-3"
      >
        <Tab eventKey="In" title={i18n.t("BG In")}>
        <LogBgInContainer
        {...this.props}
        />
        </Tab>

        <Tab eventKey="Out" title={i18n.t("BG Out")}>
        <LogBgOutContainer
        {...this.props}
        />
        </Tab>
       
      </Tabs>
    );
  }

  render() {
  return (
    <div id="invoice-page">
    <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
    <div
      id="kt_content_container"
      className="d-flex flex-column-fluid align-items-start container-fluid"
    >
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card">
          <div className="card-body pt-8">{this.renderTab()}</div>
        </div>
      </div>

    </div>

  </div>
    );
  }
}

export default LogBg;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Link, matchPath, useLocation, useNavigation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import BillingMiddleware from "../../../../middleware/modules/sales/billing";
import Form from "../../../../components/modules/sales/billing/form";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import FormRepayment from "../../../../components/modules/sales/billing/form-repayment";
import FormBill from "../../../../components/modules/sales/billing/form-bill";

const mapStateToProps = ({ auth, billing }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: billing.isProcessing,
  isError: billing.isError,
  errorMessage: billing.errorMessage,
  dataUpdate: billing.single,
  isSuccess: billing.isSuccess,
  isReset: billing.isReset,
  isNewRecord: billing.isNewRecord,
  onSubmit: billing.onSubmit,
  helperBilling: billing.helperBilling,
  dataBilling: billing.dataBilling,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => BillingMiddleware.resetBill(),
      setListBill: (data) =>BillingMiddleware.setListBilling(data),
      submitData: (data,search) =>BillingMiddleware.beforeSaveNew(data,search),
      updateData: (data,search) =>BillingMiddleware.beforeSaveUpdate(data,search),
    },
    dispatch
  );
};
class FormContainerBill extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    dataRepayment: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show:0,
      showMod : false,
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
    this.submitData = this.submitData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.setListBill = this.setListBill.bind(this);
  }

  close() {
    this.props.closeModal();
  }

  setListBill(){
    this.props.setListBill();
  }

  submitData(params,search){
    this.props.submitData(params,search);
  }

  updateData(params,search){
    this.props.updateData(params,search);
  }

  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    if(form.Update == 1){
      this.props.updateData(form,search);
    }else{
      this.props.submitData(form,search);
    }
  }

  render() {
    const {showModalBilling,titleModal,onSubmit,helperBilling} = this.props
    return (
      <>
        <Modal show={showModalBilling} fullscreen enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <FormBill
              {...this.props}
              ref={this.form}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainerBill);

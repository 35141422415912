import AdminActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  onSubmit:false,
  single:null,
  errorInternal:false,
  helperFull:null
};

function AdminReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AdminActions.RESET_LIST:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,data:{}};
    case AdminActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true};
    case AdminActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false};
    case AdminActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false};
    case AdminActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case AdminActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case AdminActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case AdminActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case AdminActions.SUCCESS_DATA:
      return { ...state, isError: false, form:INITIAL_STATE.form , onSubmit:false};
    case AdminActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case AdminActions.GET_HELPER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperFull:action.payload};  
    case AdminActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case AdminActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
     
    default:
      return state;
    }
}


export default AdminReducer;

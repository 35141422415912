import TempCustomerActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperTempCustomer: null,
  customerHelperFull: null,
  errorInternal: false,
};

function TempCustomerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TempCustomerActions.RESET_LIST:
      return {
        INITIAL_STATE
      };
    case TempCustomerActions.NEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case TempCustomerActions.UPDATE_DATA:
      return {
        ...state,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case TempCustomerActions.VIEW_DATA:
      return {
        ...state,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case TempCustomerActions.RESET_DATA:
      return {
        ...state,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case TempCustomerActions.REJECT_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
      };
    case TempCustomerActions.ON_SUBMIT:
      return { ...state, onSubmit: true };
    case TempCustomerActions.SHOW_DATA:
      return {
        ...state,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case TempCustomerActions.SUCCESS_DATA:
      return {
        ...state,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case TempCustomerActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false, isSuccess: false };
    case TempCustomerActions.ERROR_DATA:
      return {
        ...state,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case TempCustomerActions.APPROVE:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
      };
    case TempCustomerActions.GET_HELPER_CUSTOMER:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        customerHelperFull: action.payload,
      };
    case TempCustomerActions.GET_HELPER_CUSTOMER_FULL:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        customerHelperFull: action.payload,
      };
    case TempCustomerActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default TempCustomerReducer;

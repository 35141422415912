import SetupWarehouseActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSetup:null,
  errorInternal:false
};

function SetupWarehouseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SetupWarehouseActions.RESET_LIST:
      return INITIAL_STATE;
    case SetupWarehouseActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SetupWarehouseActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case SetupWarehouseActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SetupWarehouseActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SetupWarehouseActions.DELETE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SetupWarehouseActions.ON_SUBMIT:
      return { ...state,errorInternal:false,  onSubmit:true};
    case SetupWarehouseActions.SHOW_DATA:
      return { ...state,errorInternal:false, onSubmit:false,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SetupWarehouseActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SetupWarehouseActions.PROCESS_DATA:
      return { ...state, isProcessing: true,onSubmit:false, isError: false,isSuccess:false};
    case SetupWarehouseActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SetupWarehouseActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
      case SetupWarehouseActions.GET_HELPER_SETUPWH:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,helperSetup:action.payload};
    default:
      return state;
    }
}

export default SetupWarehouseReducer;

import React, { Component, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import {
  Button,
  Card,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Form from "./form-view";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faBackward,
  faBan,
  faChain,
  faCheck,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import LabelApprove from "../../../utilities/Status/LogApproval";
import { logApproval } from "../../../utilities/Const/TranscationCode";
import cookieManager from "../../../../utils/cookieManager";
import { Link } from "react-router-dom";
import LinkPrint from "../../../utilities/Form/LinkPrint";
import Activity from "./activity";
const MySwal = withReactContent(Swal);
export class LogApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      showTable: true,
      first: 0,
      choose: null,
      type: null,
      titleModal: "",
      listData: [],
      isProcessing: false,
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          className: " text-gray-600",
        },
        { link: null, title: i18n.t("Log"), className: " text-gray-600" },
        { link: null, title: i18n.t("Approval"), className: " text-gray-500" },
      ],
      column: [
        {
          key: "branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "text-start",
          component: (data, column) => {
            return (
              <Fragment>
                <div>
                  {data.branch}
                </div>
                
              </Fragment>
            );
          },
        },
        {
          key: "code",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code Transaction"),
          type: "component",
          tHclass: " min-w-125px",
          tdClass: "text-start",
          component: (data, column) => {
            data = Object.assign(data, { module: this.state.module });
            return (
                  <Fragment>
                    {this.state.category == "Print" ? (
                      <LinkPrint {...data} />
                    ) : null}
                    {this.state.category == "Approve" ? (
                      <span
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        onClick={(e) => this.showDetail(data)}
                      >
                        {data.code}
                      </span>
                    ) : null}
                    {this.state.category == "Compare" ? (
                      <span
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        onClick={(e) => this.showDetail(data)}
                      >
                        {data.code}
                      </span>
                    ) : null}
                  </Fragment>
            );
          },
        },
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("User Request"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <div className="badge badge-light-success">
                  {data.created_by}
                </div>
                <br />
                <div className="badge badge-light-info">{data.created_at}</div>
              </Fragment>
            );
          },
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: logApproval,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return <LabelApprove value={data[column.key]} />;
          },
        },

        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            if (data.can && data.status==1) {
              return (
                <Fragment>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => this.approveData(data.id)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  {!data.approveOnly && <Button
                    variant="danger"
                    size="sm m-1"
                    onClick={() => this.rejectData(data.id)}
                  >
                    <FontAwesomeIcon icon={faBan} />
                  </Button> }
                  
                </Fragment>
              );
            }
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.approveData = this.approveData.bind(this);
    this.rejectData = this.rejectData.bind(this);
    this.viewData = this.viewData.bind(this);
    this.changeType = this.changeType.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  viewData(id) {
    this.state.titleModal = i18n.t("Customer");
    this.props.viewItem(id);
  }
  showDetail(data) {
    this.setState({
      showTable: false,
      choose: data,
    });
  }
  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve this Request")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Request"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.ApproveData(id, value,this.state.search);
              }
            });
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This Request")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Request"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.RejectData(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  changeType(data) {
    let search = {
      limit: 10,
      type: data.name,
    };
    this.setState({
      search: search,
      type: data.name,
      module: data.module,
      category: data.category,
      showTable: true,
    });

    this.props.search(search);
  }
  getData(search) {
    const url = process.env.REST_URL + "log/approval";
    const axios=require('axios');
    if (axios != undefined) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookieManager.getUserToken()}`,
        },
        url: url,
        responseType: "json",
        params: search,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            this.setState({
              listData: response.data.result,
              isProcessing: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isProcessing: false,
          });
        });
    }
  }
  render() {
    const { isProcessing, data, showModal, helper } = this.props;
    if(data !=null){
      this.state.listData = data;
    }
   
    this.state.isProcessing = isProcessing;
    if (helper.length > 0 && this.state.first == 0) {
      let search = {
        limit: 10,
        type: helper[0].name,
      };
      this.state.search = search;
      this.state.type = helper[0].name;
      this.state.module = helper[0].module;
      this.state.category = helper[0].category;
      this.state.isProcessing = true;
        this.getData(search);
        this.state.first = 1;
    }
    console.log(this.state)
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="d-flex flex-column flex-lg-row">
              <div
                className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px"
                data-kt-drawer="true"
                data-kt-drawer-name="inbox-aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_inbox_aside_toggle"
              >
                <div
                  className="card card-flush mb-0"
                  data-kt-sticky="false"
                  data-kt-sticky-name="inbox-aside-sticky"
                  data-kt-sticky-offset="{default: false, xl: '100px'}"
                  data-kt-sticky-width="{lg: '275px'}"
                  data-kt-sticky-left="auto"
                  data-kt-sticky-top="100px"
                  data-kt-sticky-animation="false"
                  data-kt-sticky-zindex="95"
                >
                  <div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                      {helper != null
                        ? helper.map((row, i) => (
                            <div
                              key={"menuap" + i}
                              className="menu-item mb-3"
                              onClick={() => this.changeType(row)}
                            >
                              <span
                                className={
                                  "menu-link " +
                                  (this.state.type == row.name ? "active" : "")
                                }
                              >
                                {row.icon ? (
                                  <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2 me-3">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          opacity="0.3"
                                          d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                ) : null}
                                <span className="menu-title fw-bold">
                                  {row.name}
                                </span>
                                {/* <span className="badge badge-light-success">3</span> */}
                              </span>
                            </div>
                          ))
                        : "Loading Type"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                <div className="card">
                  <div
                    className={
                      "card-header d-flex" +
                      (this.state.showTable
                        ? " justify-content-end "
                        : " justify-content-start ") +
                      "py-5 gap-2 gap-md-5"
                    }
                  >
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        {this.state.showTable ? (
                          <Button
                            key={"warning"}
                            type="button"
                            variant="warning"
                            onClick={(e) => this.runSearch(this.state.search)}
                          >
                            <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                          </Button>
                        ) : (
                          <Button
                            key={"secondary"}
                            type="button"
                            variant="secondary"
                            onClick={(e) =>
                              this.setState({
                                showTable: !this.state.showTable,
                                choose: null,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faArrowLeftLong}
                            ></FontAwesomeIcon>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    {this.state.showTable ? (
                      <Table
                        data={this.state.listData}
                        tools={false}
                        filterSearch={true}
                        column={this.state.column}
                        searchData={this.state.search}
                        runSearch={this.runSearch}
                        deleteData={this.deleteData}
                        updateData={this.updateData}
                        isProcessing={this.state.isProcessing}
                        expandAble={true}
                        customExpand={true}
                        componentExpand={(data)=>{
                            return <Activity data={data}></Activity>
                          }
                        }

                      />
                    ) : (
                      <Collapse in={!this.state.showTable} dimension="height">
                        <div id="example-collapse-text">
                          <Form {...this.state} />
                        </div>
                      </Collapse>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogApproval;

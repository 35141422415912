
import React,{ Component } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from '../../../utilities/Form/InputNumber';
import TextArea from '../../../utilities/Form/TextArea';

const MySwal = withReactContent(Swal);

export class FormOutConfirm extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
              Data:{},
            },
            branch:'',
            idBranch:null,
            transactionNo:'',
            dueDate:new Date(),
            date:new Date(),
            name:'',
            BGid:null,
            idBank:null,
            bankValue:null,
            bankText:i18n.t('Select Bank'),
            bankList:[],
            nominal:0,
            isFill:0,
            loadingPaymentType:false,
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    runSubmit(){
        const Data = {
            idAccountBank:this.state.bankValue != null?this.state.bankValue.value:null,
            BGid:this.state.BGid,
            type:'Out',
            date:moment(this.state.date).format('YYYY-MM-DD'),
            ifReject:this.state.ifReject
        }
        this.state.form.Data = Data
        return this.state.form
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }

    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    

    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;

                params = {
                    branch: branch,
                    type: 'Bank',
                }
            break;
           
        }

        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }
    
    render(){
        const {dataBG,reject}=this.props
        console.log('PROPS',this.props)
        console.log('STATE',this.state)

        if(dataBG != null && dataBG != ''){
            let row = dataBG;
            if(this.state.isFill == 0){
                this.state.branch = row.name_branch;
                this.state.idBranch = row.id_branch;
                this.state.name = row.name_coa4;
                this.state.dueDate = new Date(row.date_bg)
                this.state.transactionNo = row.no_transaction;
                this.state.BGNo = row.no_bg;
                this.state.BGid = row.id;
                this.state.nominal = row.nominal;
                this.changeData({
                    keyFill:"bankList",
                    type:"getBank",
                    callback:()=>{this.setState({loadingPaymentType:false})}
                  })
            }
            this.state.isFill = 1;
        }
        
        return (
            <React.Fragment>
            <form>
                <div className='row'>
                    <div className="col-12">
                            <DatePickerCustom
                                name={"date"}
                                title={i18n.t("Date")}
                                required={true}
                                selected={this.state.dueDate}
                                onChange={async (e)=>{
                                    await this.handlerDateTime(e,'date'); 
                                }}
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                minDate={new Date()}
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"branch"}
                            title={i18n.t("Branch")}
                            value={this.state.branch}
                            labelfield={"true"}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            value={this.state.name}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"transaction_no"}
                            title={i18n.t("No. Transaction")}
                            value={this.state.transactionNo}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <DatePickerCustom
                            disabled
                            name={"dueDate"}
                            title={i18n.t("BG Date")}
                            selected={this.state.dueDate}
                            onChange={async (e) => {
                            await this.handlerDateTime(e, "dueDate");
                          
                            }}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMMM yyyy"
                            endDate={new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"BGNo"}
                            title={i18n.t("No. BG")}
                            value={this.state.BGNo}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <InputNumber
                            id={"nominal"}
                            name="nominal"
                            placeholder={i18n.t("Nominal")}
                            defaultValue={0}
                            value={this.state.nominal}
                            decimalsLimit={2}
                            disabled
                            className="form-control  form-control-sm text-end"
                            prefix="Rp "
                            title={i18n.t("Nominal")}
                        />
                    </div>
                </div>
                {reject == true?  
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <TextArea
                            className="form-control  form-control-sm"
                            name={"ifReject"}
                            title={i18n.t("Note Reject")}
                            value={this.state.ifReject}
                            handler={this.handler}
                        />
                         <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('ifReject')}
                        </div>
                    </div>
                </div>:
                <div className="row">
                    <div className="col-md-12">
                        <Select2
                            isClearable={true}
                            name={'idBank'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async(e)=>{
                                await this.handlerSelect(e,"idBank","bankValue")
                            }}
                                
                            options={this.state.bankList}
                            title={i18n.t("Bank")}
                            value={this.state.bankValue}
                            placeholder={this.state.bankText}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idAccountBank')}
                        </div>
                    </div>
                </div>
    }
                {/* <div className="row">
                    <div className="col-md-12">
                        <Select2
                            isClearable={true}
                            name={'idBank'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async(e)=>{
                                await this.handlerSelect(e,"idBank","bankValue")
                            }}
                                
                            options={this.state.bankList}
                            title={i18n.t("Bank")}
                            value={this.state.bankValue}
                            placeholder={this.state.bankText}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idAccountBank')}
                        </div>
                    </div>
                </div> */}
            </form>
           </React.Fragment>
        );
    }
}

export default FormOutConfirm;
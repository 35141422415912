import React, { Component } from "react";
import i18n from "../../../../i18n";
import { ChoseCOA, StatusCOA } from "../../../utilities/Const/StatusActive";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ACC1ID: "",
        ACC1Name: "",
        position: "Debt",
        balanceSheet: 1,
      },
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handler2(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  runSubmit() {
    return this.state.form;
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  render() {
    const { data, isNewRecord } = this.props;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.form.ACC1Name = data.name),
          (this.state.form.ACC1ID = data.code),
          (this.state.form.position = data.position),
          (this.state.form.balanceSheet = data.balance_sheet);
        this.state.isUpdate = 1;
      }
    }
    return (
      <form>
        <Input
          type={"input"}
          name={"ACC1ID"}
          title={i18n.t("ACC1 ID")}
          required
          value={this.state.form.ACC1ID}
          handler={this.handler}
          error={this.renderError("ACC1ID")}
        />
        <Input
          type={"input"}
          name={"ACC1Name"}
          title={i18n.t("ACC1 Name")}
          required
          value={this.state.form.ACC1Name}
          handler={this.handler}
          error={this.renderError("ACC1Name")}
        />
        <Radio
          list={ChoseCOA}
          name={"position"}
          title={i18n.t("Position")}
          inline={false}
          value={this.state.form.position}
          handler={this.handler}
        />
        <Radio
          list={StatusCOA}
          name={"balanceSheet"}
          title={i18n.t("Balance Sheet")}
          inline={false}
          value={this.state.form.balanceSheet}
          handler={this.handler}
        />
      </form>
    );
  }
}

export default Form;

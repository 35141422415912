import i18n from "../../../i18n";

const Day = [
  {
    value: 0,
    label: i18n.t("Monday"),
  },
  {
    value: 1,
    label: i18n.t("Tuesday"),
  },
  {
    value: 2,
    label: i18n.t("Wednesday"),
  },
  {
    value: 3,
    label: i18n.t("Thursday"),
  },
  {
    value: 4,
    label: i18n.t("Friday"),
  },
  {
    value: 5,
    label: i18n.t("Saturday"),
  },
  {
    value: 6,
    label: i18n.t("Sunday"),
  },
];
export default Day;

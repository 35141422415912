import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import LogPrintActions from "../../../../reducers/modules/log/print/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "log/print";

export default class LogPrintMiddleware {
  
  static resetList() {
    return (dispatch) => {
      dispatch(LogPrintActions.resetList());
    };
  }
  static list(params) {
    return (dispatch) => {
      dispatch(LogPrintActions.process());
      LogPrintMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(LogPrintActions.reset());
    };
  }
  static beforeApprove(id, value, search) {
    const post = {
      id: id,
      value: value,
    };
    return (dispatch) => {
      dispatch(LogPrintActions.approve());
      LogPrintMiddleware.saveApprove(dispatch, post, search);
    };
  }
  static beforeReject(id, value, search) {
    const post = {
      id: id,
      value: value,
    };
    return (dispatch) => {
      dispatch(LogPrintActions.reject());
      LogPrintMiddleware.saveReject(dispatch, post, search);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }
  static saveApprove(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LogPrintMiddleware.getToken()}`,
      },
      url: url + "/approve/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            LogPrintActions.process(),
            LogPrintMiddleware.showlist(dispatch, search)
          );
          dispatch(LogPrintActions.success());
          LogPrintMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Approve Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        LogPrintMiddleware.alertHendler(dispatch, error, true);
      });
  }
  static saveReject(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LogPrintMiddleware.getToken()}`,
      },
      url: url + "/reject/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            LogPrintActions.process(),
            LogPrintMiddleware.showlist(dispatch, search)
          );
          dispatch(LogPrintActions.success());
          LogPrintMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Reject Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        LogPrintMiddleware.alertHendler(dispatch, error, true);
      });
  }
  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${LogPrintMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(LogPrintActions.list(response.data.result));
        }
      })
      .catch((error) => {
        LogPrintMiddleware.alertHendler(dispatch, error);
      });
  }
  
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(LogPrintActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(LogPrintActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

/*
    Color Avaiable
    - success
    - warning
    - info
    - primary
    - secondary
    - light
    - dark
*/

import i18n from "../../../i18n";

export const statusMemoIn = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  // {
  //   key: 0,
  //   label: i18n.t("Waiting For Approval"),
  //   color: "warning",
  // },
  {
    key: 1,
    label: i18n.t("Active"),
    color: "success",
  },
  {
    key: 2,
    label: i18n.t("Journal Closed"),
    color: "info",
  },
  {
    key: 3,
    label: i18n.t("Void"),
    color: "danger",
  },
  // {
  //   key: 4,
  //   label: i18n.t("Rejected"),
  //   color: "danger",
  // },
  
  
  
];


export default class JournalActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static CHANGE_STATUS='CHANGE_STATUS';
    static VIEW_DATA='VIEW_DATA';
    static CANCEL_DATA='CANCEL_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_JOURNAL='GET_HELPER_JOURNAL';
    static GET_HELPERFULL_JOURNAL='GET_HELPERFULL_JOURNAL';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_DETAIL = 'GET_DETAIL';
    static BEFORE_DETAIL = 'BEFORE_DETAIL';
    static AFTER_DETAIL = 'AFTER_DETAIL';
    static AFTER_DATA = 'AFTER_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_JOURNAL='PRINT_JOURNAL'
    static BEFORE_PRINT_JOURNAL='BEFORE_PRINT_JOURNAL'
  
    static submit(){
      return {
        type: JournalActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: JournalActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: JournalActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: JournalActions.VIEW_DATA,
        payload: data
      };
    }
  
    static cancel(data) {
      return {
        type: JournalActions.CANCEL_DATA
      };
    }

    static change(data) {
      return {
        type: JournalActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: JournalActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: JournalActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: JournalActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: JournalActions.PROCESS_DATA,
      };
    }
    static resetList(){
      return {
        type: JournalActions.RESET_LIST,
      };
    }
    static reset(){
      return {
        type: JournalActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: JournalActions.GET_HELPER_JOURNAL,
        payload:data
      };
    }
    static helperFull(data){
      return {
        type: JournalActions.GET_HELPERFULL_JOURNAL,
        payload:data
      };
    }
  
    static errorInternal(error){
      return {
        type: JournalActions.ERROR_SERVER,
        payload: error
      };
    }

    static detail(data){
      return {
        type: JournalActions.GET_DETAIL,
        payload: data
      };
    }

    static beforeDetail(data){
      return {
        type: JournalActions.BEFORE_DETAIL,
        payload: data
      };
    }

    static afterDetail(data){
      return {
        type: JournalActions.AFTER_DETAIL,
        payload: data
      };
    }

    static afterData(data){
      return {
        type: JournalActions.AFTER_DATA,
        payload: data
      };
    }

    static beforePrint(){
      return {
        type: JournalActions.BEFORE_PRINT_JOURNAL,
      };
    }
    static print(data){
      return {
        type: JournalActions.PRINT_JOURNAL,
        payload: data
      };
    }
  }
import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import TextArea from '../../../utilities/Form/TextArea';


export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            selectProvince:React.createRef(),
            form:{
                name:'',
                code:'',
                address:'',
                idProvince:null,
                idCity:null,
                phone:'',
                email:'',
                idNumber:'',
                taxStatus:0,
                npwp:'',
                contactPerson:'',
                contactPersonPhone:'',
                top:'',
                invoiceLimit:'',
                creditLimit:'',
                status:1,
            },
            provinceCurrent:null,
            cityCurrent:null,
            provinceText:i18n.t('Select Province'),
            cityText:i18n.t('Select City'),
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
        this.setState({[currentState]:e})
        this.props.changeSelect(name,value);
    }
    runSubmit(){
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){
        const {data,isNewRecord,cityList,supplierHelper}=this.props 
        this.state.provinceList=((supplierHelper !=null)?supplierHelper.province:null)
        this.state.cityList=cityList 
        if(cityList !=null && cityList.length==0){
            this.state.cityCurrent=null
            this.state.form.idCity=''
        }
        if(isNewRecord && supplierHelper!=null){
            this.state.form.code=supplierHelper.code
        }   
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form={
                    name:data.name,
                    code:data.code,
                    address:data.address,
                    idProvince:data.province_id,
                    idCity:data.city_id,
                    phone:data.phone,
                    email:data.email,
                    idNumber:data.id_number,
                    taxStatus:data.tax_status,
                    npwp:data.npwp,
                    contactPerson:data.contact_person,
                    contactPersonPhone:data.contact_person_phone,
                    top:data.top,
                    invoiceLimit:data.invoice_limit,
                    creditLimit:data.credit_limit,
                    status:data.status
                }
                this.state.provinceCurrent={value:data.province_id,label:((data.province !='')?data.province:this.state.provinceText)}
                this.state.cityCurrent={value:data.city_id,label:((data.city !='')?data.city:this.state.cityText)}
                this.state.isUpdate=1
                this.props.changeSelect('idProvince',data.province_id);
            }
        }
        return (
            <form>
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <TextArea
                            required={true}
                            name={"address"}
                            title={i18n.t("Address")}
                            value={this.state.form.address}
                            handler={this.handler}
                            error={this.renderError('address')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Select2 
                            isClearable={true}
                            name={'idProvince'}
                            required={true}
                            onChange={(e)=>this.handlerSelect(e,"idProvince","provinceCurrent")}
                            options={this.state.provinceList}
                            title={i18n.t("Province")}
                            value={this.state.provinceCurrent}
                            placeholder={this.state.provinceText}
                            error={this.renderError('idProvince')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Select2 
                            isClearable={true}
                            name={'idCity'}
                            required={true}
                            onChange={(e)=>this.handlerSelect(e,"idCity","cityCurrent")}
                            options={this.state.cityList}
                            title={i18n.t("City")}
                            value={this.state.cityCurrent}
                            placeholder={this.state.cityText}
                            error={this.renderError('idCity')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"phone"}
                            title={i18n.t("Phone")}
                            value={this.state.form.phone}
                            handler={this.handler}
                            error={this.renderError('phone')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"idNumber"}
                            title={i18n.t("ID Number")}
                            value={this.state.form.idNumber}
                            handler={this.handler}
                            error={this.renderError('idNumber')}
                        />
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"top"}
                            title={i18n.t("TOP")}
                            required={true}
                            value={this.state.form.top}
                            handler={this.handler}
                            error={this.renderError('top')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"email"}
                            name={"email"}
                            title={i18n.t("Email")}
                            required={true}
                            value={this.state.form.email}
                            handler={this.handler}
                            error={this.renderError('email')}
                        />
                    </div>
                </div>
                
               
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"contactPerson"}
                            title={i18n.t("Contact Person")}
                            value={this.state.form.contactPerson}
                            handler={this.handler}
                            error={this.renderError('contactPerson')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"contactPersonPhone"}
                            title={i18n.t("Contact Person Phone")}
                            value={this.state.form.contactPersonPhone}
                            handler={this.handler}
                            error={this.renderError('contactPersonPhone')}
                        />
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-md-6'>
                        <Radio
                            list={{1:'PKP',0:'NON PKP'}}
                            name={"taxStatus"}
                            title={i18n.t("Tax Status")}
                            inline={false}
                            value={this.state.form.taxStatus}
                            handler={this.handler}
                            error={this.renderError('taxStatus')}
                        />
                    </div>
                    <div className='col-md-6'>
                            <Input
                                type={"input"}
                                name={"npwp"}
                                title={i18n.t("NPWP")}
                                value={this.state.form.npwp}
                                handler={this.handler}
                                error={this.renderError('npwp')}
                           />
                    </div>
                </div>
                
                
                <div className='row'>
                    <div className='col-md-12'>
                        <Radio
                            list={StatusForm}
                            name={"status"}
                            title={i18n.t("Status")}
                            inline={false}
                            value={this.state.form.status}
                            handler={this.handler}
                            error={this.renderError('status')}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;
import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faFileExcel, faDisplay,faEye } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TableCashBankBook from "./table";
import genRandonString from "../../../utilities/Class/RandomString";
import { Link } from "react-router-dom";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";
const MySwal = withReactContent(Swal);
export class CashBankBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      coaList: [],
      coaCurrent: null,
      idCOA: null,
      coaText: i18n.t('All'),
      listData: [],
      absence: [],
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      type: 2,
      page: 1,
      show: 0,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Cash Bank"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  
  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }
  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  setData() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);
    var type = this.state.type;
    var idCOA = (this.state.idCOA == null) ? '' : this.state.idCOA;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;



    let params = {
      startDate: startDate,
      endDate: endDate,
      idCOA: idCOA,
      limit:limit,
      page:page,
      type:type,
      idBranch:idBranch
    }
    this.props.setData(params);
  }

  renderList() {
    const { data } = this.props
    this.state.listData = data;
    let row = data.data
    let html = [];
    let no = 1;
    let totalDebt = 0;
    let totalCredit = 0;
    
    if (data != undefined && data != null && row != null) {
      for (var p = 0; p < row.length; p++) {
        if (row[p].date != null) {
          html.push(
            <tr key={p} className="border">
              <td className="text-center">{no++}</td>
              <td>{row[p].date}</td>
              <td>{row[p].nameCB}</td>
              <td>
              <Link
                    to={"/accounting/journal/detail/" + row[p].id_journal}
                  >
                    {row[p].no_journal}
                  </Link>
                </td>
              <td>{row[p].description}</td>
              <td style={{ textAlign: 'right' }}>{row[p].debt_format}</td>
              <td style={{ textAlign: 'right' }}> {row[p].credit_format}</td>
              <td style={{ textAlign: 'right', paddingRight:'5px' }}> {row[p].balance_format}</td>
            </tr>)
        }
        totalDebt += parseFloat(row[p].debt) || 0;
        totalCredit += parseFloat(row[p].credit) || 0;

      }
      html.push(
        <tr className="border" style={{backgroundColor:'#DCDCDC'}}>
          <td colSpan={'5'} style={{ textAlign: "center",   fontWeight: 'bold' }}>TOTAL</td>
          <td style={{ textAlign: 'right',   fontWeight: 'bold' }}>{parseFloat(totalDebt)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
          <td style={{ textAlign: 'right',   fontWeight: 'bold' }}>{parseFloat(totalCredit)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
          <td style={{ textAlign: 'right',   fontWeight: 'bold' }}></td>
        </tr>)
      
      return html;
    }

  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }
  
  async convertExcel() {
    var nameFile = (this.state.coaCurrent == null) ? 'All' : this.state.coaCurrent.label;
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
     
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/cash-bank/excel",
      method: "GET",
      params: {
        startDate: startDate,
        endDate: endDate,
        idBranch:idBranch,
        idCOA: this.state.idCOA,
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel','Download Successfully','success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
        moment(startDate).format("D/M/YYYY") + ' - ' +
        moment(endDate).format("D/M/YYYY") + ' ' +
        nameFile +
        ' Laporan Kas Bank.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Fill Work Period', 'info')
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e,
      page:1
    }, () => this.setData()
    )

  }

  changePage(event, url,count) {
    let get = parseInt(this.state.page)||0;
    if(event.target.value == 0){
      this.setState({ page: (get-1) }, () => this.setData());
    }else if(event.target.value == (count-1)){
      this.setState({ page: (get+1) }, () => this.setData());
    }else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }
  renderPagination() {
    const { isProcessing, helperList, datalist, data } = this.props;
    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '10', label: '10' }
      this.state.show = 1;
    }
    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url,data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"]: ""}
                      {i == data.links.length - 1 ? 'Next': ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}


  renderTab(){
    const { isProcessing, helperList, datalist,data } = this.props;
    
    this.state.listData = datalist;
   
    return (
    <Tabs
      unmountOnExit
      defaultActiveKey="laporanKasBank"
      id="laporan"
      className="mb-3"
      >
      <Tab eventKey="laporanKasBank" title={i18n.t("Cash/Bank Book Report")}>
        <React.Fragment>
          <div className="row">
            <div className="col-mb-1">
              <Radio
                list={Period}
                name={"type"}
                title={""}
                inline={false}
                value={this.state.type}
                handler={this.handlerRadio}
              />
            </div>
          </div>
          <div className="row">
          {this.state.type == 1 ?
            <Fragment>
              <div className="col-md-4 mt-3">
                <Select2
                  isClearable={true}
                  name={"period"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                    await this.handlerSelect(e, "idPeriod", "periodCurrent");
                  }}
                  options={this.state.periodList}
                  title={i18n.t("Week Period")}
                  value={this.state.periodCurrent}
                  placeholder={this.state.periodText}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError('startDate')}
                </div>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="col-md-4 mt-3">
                <DatePickerCustom
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  selectsRange={true}
                  isClearable={false}
                  name={"date"}
                  title={i18n.t("Date Period")}
                  required={true}
                  onChange={(e) => {
                    const [start, end] = e;
                    this.setState({ startDate: start, endDate: end });
                  }}
                  la
                  showYearDropdown
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  peekNextMonth
                  showMonthDropdown
                  autoComplete={"off"}
                  dateFormat="dd MMM yyyy"
                  maxDate={new Date()}
                  className="form-control form-control-md"
                />
              </div>
            </Fragment>}
              <div className="col-md-4 mt-3">
                <Select2
                  isClearable={true}
                  name={"idBranch"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                    if (e == null) {
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                    } else {
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                    }
                  }}
                  options={this.state.branchList}
                  title={i18n.t("Branch")}
                  value={this.state.branchCurrent}
                  placeholder={this.state.branchText}
                  labelfield={"true"}
                />
            </div>
            <div className="col-md-4 mt-3">
              <Select2
                isClearable={true}
                name={"idCOA"}
                labelfield={"true"}
                onChange={async (e) => {
                  if (e == null) {
                    this.handlerSelect(e, "idCOA", "coaCurrent")
                  }
                  else {
                    this.handlerSelect(e, "idCOA", "coaCurrent")
                  }
                }}
                options={this.state.coaList}
                title={i18n.t("Cash / Bank Name")}
                value={this.state.coaCurrent}
                placeholder={this.state.coaText}
                error={null}
              />
          </div>
        </div>
        <div className="row">
            <div className="col-md-9 mt-3">
              <Button onClick={async (e) => {
                await this.setState({ page: 1}, () => this.setData())
                await this.setState({ headeTable: true })
              }}
                variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
        
              <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {i18n.t("Convert")}

              </button>
            <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
              {/* <a class="dropdown-item" href="#" onClick={()=>this.showItem()}><FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  PDF</a> */}
              <a className="dropdown-item " href="#" onClick={()=>this.convertExcel()}><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>  {i18n.t("Excel")} </a>
          
            </div>
          </div>
        </div>
        {this.state.headeTable == true ?
          <React.Fragment>
            <div className={isProcessing ? " table-loading" : ""}>
            {!isProcessing ? (
            <Fragment>
            <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5'}}>
              <thead>
                <tr className="table-header">
                  <th width={"3%"} >{i18n.t("No.")}</th>
                  <th width={"12%"}>{i18n.t("Date")}</th>
                  <th width={"13%"}>{i18n.t("COA 3 Name")}</th>
                  <th width={"12%"}>{i18n.t("No. Journal")}</th>
                  <th width={"19%"} >{i18n.t("Remark")}</th>
                  <th width={"12%"}>{i18n.t("Debt")}</th>
                  <th width={"12%"}>{i18n.t("Credit")}</th>
                  <th width={"12%"}>{i18n.t("Balance")}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderList()}
              </tbody>
            </table>
            {this.renderPagination()}
            </Fragment>
             ) : (
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              )
            }
            </div>
          </React.Fragment>:
          ''}
        </React.Fragment>
      </Tab>
    </Tabs> 
    ); 
  }
  render() {
    const { isProcessing, helperList, datalist,data } = this.props;
    this.state.coaList = helperList.coa;
    this.state.branchList = helperList.branch;
    this.state.periodList = helperList.workCalendar;
    this.state.listData = datalist;
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>
      
        </div>

      </div>
    );
  }
}

export default CashBankBook;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import DailyAttendance from '../../../../components/modules/hr/daily-attendance';
import DailyAttendanceMiddleware from '../../../../middleware/modules/hr/daily-attendance';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,dailyAttendance }) => ({
  isProcessing: dailyAttendance.isProcessing,
  isError: dailyAttendance.isError,
  errorMessage: dailyAttendance.errorMessage,
  datalist:dailyAttendance.data,
  showModal:dailyAttendance.showModal,
  helperList:dailyAttendance.helperDailyAttendance
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>DailyAttendanceMiddleware.getHelper(),
    reset: () =>DailyAttendanceMiddleware.resetList(),
    search: data =>DailyAttendanceMiddleware.list(data),
    updateAbsence: (data,params) =>DailyAttendanceMiddleware.beforeSaveUpdate(data,params),
  }, dispatch);
};
class DailyAttendanceContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
    this.props.search(search);
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Daily Attendance List")}
          </title>
        </Helmet>
      <DailyAttendance
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyAttendanceContainer);

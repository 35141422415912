import { useParams } from "react-router-dom";
import React from "react";
import SellingContainer from '../../../containers/Print/default/Selling'
export const PrintSelling = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <SellingContainer id={id}/>
    )
}

export default PrintSelling;
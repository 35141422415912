import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import Swal from "sweetalert2";
import { Link, matchPath, useLocation, useNavigation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import DeliveryOrderMiddleware from "../../../../middleware/modules/sales/delivery-order";
import Form from "../../../../components/modules/sales/delivery-order/form";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// const MySwal = withReactContent(Swal);
const mapStateToProps = ({ auth, deliveryOrder }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: deliveryOrder.isProcessing,
  isError: deliveryOrder.isError,
  errorMessage: deliveryOrder.errorMessage,
  dataUpdate: deliveryOrder.single,
  isSuccess: deliveryOrder.isSuccess,
  isReset: deliveryOrder.isReset,
  isNewRecord: deliveryOrder.isNewRecord,
  onSubmit: deliveryOrder.onSubmit,
  helperDeliveryOrder: deliveryOrder.helperDeliveryOrder,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => DeliveryOrderMiddleware.reset(),
      new: (data, search) => DeliveryOrderMiddleware.beforeSaveNew(data, search),
      update: (id, type, search) =>
        DeliveryOrderMiddleware.beforeSaveUpdate(id, type, search),
      create: () => DeliveryOrderMiddleware.create(),
    },
    dispatch
  );
};
class FormContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  close() {
    this.props.closeModal();
  }
  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
   if(form == false){
    this.alertError('Item Must Be Fill')
   }else{
    if (isNewRecord) {
      this.props.new(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
  }
  }


  render() {
    const {
      helperDeliveryOrder,
      isNewRecord,
      onSubmit,
      showModal,
      titleModal,
      isReset,
      dataUpdate,
      isProcessing,
      isError,
      errorMessage,
    } = this.props;

    return (
      <>
        <Modal show={showModal} fullscreen enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <Form
              isNewRecord={isNewRecord}
              isReset={isReset}
              data={dataUpdate}
              isProcessing={isProcessing}
              isError={isError}
              errorMessage={errorMessage}
              ref={this.form}
              helperDeliveryOrder={helperDeliveryOrder}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : ( 
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

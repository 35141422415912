import {
  faBoxArchive,
  faBoxOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import TextArea from "../../../utilities/Form/TextArea";
import InputNumber from "../../../utilities/Form/InputNumber";
import cookieManager from "../../../../utils/cookieManager";
import Radio from "../../../utilities/Form/radio";
import i18n from "../../../../i18n";
import FormConvertion from "./formConvertion";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      transactionNo: "",
      fromRequest: false,
      date: new Date(),
      idSupplier: "",
      itemUses: [],
      idBranch: "",
      idStore: "",
      idWarehouse: "",
      supplierValue: "",
      supplierList: [],
      itemText: i18n.t("Select Item"),
      itemValue: "",
      itemList: [],
      supplierText: i18n.t("Select Supplier"),
      supplierValue: "",
      supplierList: [],
      supplierText: i18n.t("Select Supplier"),
      warehouseValue: "",
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      branchCurrent: "",
      branchList: [],
      branchText: i18n.t("Select Branch"),
      accountBank: "",
      accountBankList: [],
      accountBankValue: null,
      transactionIDValue: null,
      transactionIDList: [],
      transactionIDText: i18n.t("Select ID"),
      storeCurrent: "",
      storeList: null,
      storeText: i18n.t("Select Store"),
      returnTypeList: { 1: i18n.t("Credit Note (CN)"), 2: i18n.t("Cash") },
      returnTypeListStock: { 1: i18n.t("Credit Note (CN)") },
      returnType: 1,
      type: 1,
      returnMethod: 1,
      detailInvoice: [],
      detailStock: [],
      status: 1,
      totalPrice: 0,
      unitPrice:'',
      totalPriceReturn: 0,
      close: 0,
      arrayDetail: [
        {
          itemValue: null,
          idItem: null,
          idUnit: null,
          unit: null,
          description: "",
          qty: 0,
          unitValue: null,
          price: 0,
          discountPercent: 0,
          discountPrice: 0,
          taxPrice: 0,
          nettPrice: 0,
          priceTotal: 0,
          tax: 0,
          idTransactionDet: null,
          idReceiving: null,
          idReceivingDetail: null,
          bandNo: null,
          batchNo: null,
          expDate: null,
          stock: 0,
          status: 1,
          totalReturn: 0,
          convertionDetail: [],
          itemPrice:'',
        },
      ],
      show: false,
      isUpdate: 0,
    };
    this.formConvertion = React.createRef();
    this.handler = this.handler.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.discountChange = this.discountChange.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    if (value == 1) {
      this.setState({
        [key]: value,
        supplierValue: "",
        transactionIDValue: "",
        returnType: 1,
        accountBankValue: "",
      });
    } else {
      this.setState({
        [key]: value,
        warehouseValue: "",
        returnType: 1,
      });
    }

    this.resetDetail();
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let nettPrice = parseFloat(this.state.arrayDetail[i].nettPrice) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qtyReturn) || 0;
    let priceTotal = nettPrice * qty;
    let qtyA = parseFloat(this.state.arrayDetail[i].qtyReturn) || 0;
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    objectss["total"] = price * qty;
    objectss["totalReturn"] = priceTotal.toFixed(2);
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    let totalPrice = 0;
    let totalPriceReturn = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let nettPrice = parseFloat(detail[i].nettPrice) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let qtyReturn = parseFloat(detail[i].qtyReturn) || 0;

      totalPrice += nettPrice * qty;
      totalPriceReturn += nettPrice * qtyReturn;
    }

    this.setState({
      totalPrice: totalPrice.toFixed(2),
      totalPriceReturn: totalPriceReturn.toFixed(2),
    });
  }
  runSubmit() {
    const PurchaseReturn = {
      transactionNo: this.state.transactionNo,
      date: moment(this.state.date).format("YYYY-MM-DD"),
      idSupplier: this.state.idSupplier,
      idBranch: this.state.idBranch,
      idWarehouse: this.state.warehouseValue
        ? this.state.warehouseValue.value
        : "",
      idStore: this.state.idStore,
      description: this.state.description,
      idTransaction: this.state.transactionIDValue
        ? this.state.transactionIDValue.value
        : "",
      returnType: this.state.returnType,
      status: 1,
      type: 1,
      returnMethod: this.state.returnMethod,
      accountBank: this.state.accountBankValue
        ? this.state.accountBankValue.value
        : "",
    };
    const detail = this.state.arrayDetail;
    let idItem = [];
    let idUnit = [];
    let qty = [];
    let qtyReturn = [];
    let unitPrice = [];
    let discount = [];
    let discountRate = [];
    let taxPrice = [];
    let nettPrice = [];
    let total = [];
    let tax = [];
    let idTransactionDet = [];
    let idReceiving = [];
    let idReceivingDetail = [];
    let bandNo = [];
    let batchNo = [];
    let expDate = [];
    let status = [];
    let convertionList = [];

    if (this.state.returnMethod == 2) {
      for (var i = 0; i < detail.length; i++) {
        idItem.push(detail[i].idItem == null ? "" : detail[i].idItem);
        qty.push(detail[i].qty);
        qtyReturn.push(detail[i].qtyReturn);
        idUnit.push(detail[i].idUnit == null ? "" : detail[i].idUnit);
        unitPrice.push(detail[i].price);
        discount.push(detail[i].discountPercent);
        discountRate.push(detail[i].discountPrice);
        taxPrice.push(detail[i].taxPrice);
        tax.push(detail[i].tax);
        nettPrice.push(detail[i].nettPrice);
        total.push(detail[i].priceTotal);
        idTransactionDet.push(detail[i].idTransactionDet);
        idReceiving.push(detail[i].idReceiving);
        idReceivingDetail.push(detail[i].idReceivingDetail);
        bandNo.push(detail[i].bandNo ? detail[i].bandNo : "");
        status.push(1);
      }
    } else if (this.state.returnMethod == 1) {
      for (var i = 0; i < detail.length; i++) {
        idItem.push(detail[i].idItem == null ? "" : detail[i].idItem.item);
        qtyReturn.push(detail[i].qtyReturn);
        nettPrice.push(detail[i].nettPrice);
        total.push(detail[i].totalReturn);
        bandNo.push(detail[i].idItem ? detail[i].idItem.bandno : "");
        convertionList.push(
          detail[i].convertionDetail == null ? "" : detail[i].convertionDetail
        );
      }
    }
    this.state.form.PurchaseReturn = PurchaseReturn;
    this.state.form.PurchaseReturnDetail = {
      idItem: idItem,
      idUnit: idUnit,
      qty: qty,
      qtyReturn: qtyReturn,
      unitPrice: unitPrice,
      discount: discount,
      discountRate: discountRate,
      taxPrice: taxPrice,
      nettPrice: nettPrice,
      total: total,
      tax: tax,
      idTransactionDet: idTransactionDet,
      idReceiving: idReceiving,
      idReceivingDetail: idReceivingDetail,
      bandNo: bandNo,
      status: status,
      convertionDetail: convertionList,
    };
    return this.state.form;
  }

  discountChange(e, i, arrayname, sender = "Price") {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(arr[i].price) || 0;
    var value = parseFloat(e) || 0;

    let percent = 0;
    let valuePrice = 0;
    if (sender == "Price") {
      percent = (value / price) * 100;
      valuePrice = value;
      if (value > price) {
        valuePrice = price;
        percent = 100;
      }
    }
    if (sender == "Percent") {
      percent = value;
      valuePrice = price * (value / 100);
      if (percent > 100) {
        valuePrice = price;
        percent = 100;
      }
    }

    objectss["discountPrice"] = valuePrice.toFixed(2);
    objectss["discountPercent"] = percent.toFixed(2);
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  //CONVERTION
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.idItem.stock,
      convertionDetail: detail.convertionDetail,
      suffixty: detail.suffixqty,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      // objectss.value = null;
      objectss[name] = null;
    } else {
      // var value=e.value
      // objectss.value = e;
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
    this.calculateRow(i, arrayname);
    this.discountChange(objectss.discountPercent, i, arrayname, "percent");
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return value;
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  fetchInvoice() {
    let item = this.state.detailInvoice;
    if (item != null) {
      var array = [];

      for (var o = 0; o < item.length; o++) {
        array.push({
          idTransactionDet: item[o].id,
          idReceiving: item[o].idReceiving,
          idReceivingDetail: item[o].idReceivingDetail,
          bandNo: item[o].bandNo,
          batchNo: item[o].batchNo,
          expDate: item[o].expDate,
          itemValue: item[o].name_item,
          idItem: item[o].idItem,
          idUnit: item[o].idUnit,
          unitValue: item[o].name_unit,
          qty: item[o].qty,
          tax: item[o].tax,
          price: parseFloat(item[o].unitPrice) || 0,
          discountPercent: item[o].discount,
          discountPrice: parseFloat(item[o].discountPrice) || 0,
          taxPrice: parseFloat(item[o].taxPrice) || 0,
          nettPrice: parseFloat(item[o].nettPrice) || 0,
          priceTotal: parseFloat(item[o].total) || 0,
          stock: item[o].stock,
          stockFormat: item[o].stock_format,
        });
      }
      this.setState({ arrayDetail: array });
    }
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        {this.state.returnMethod == 2 ? (
          <Fragment>
            <tr>
              <td width={"200px"}>
                <Input
                  type={"input"}
                  name={"itemId"}
                  title={i18n.t("Item")}
                  value={detail.itemValue}
                  handler={(e) => {}}
                  labelfield={"false"}
                  disabled
                />
              </td>
              <td>
                <InputNumber
                  id={"qty" + i}
                  name="qty"
                  placeholder={i18n.t("Qty")}
                  defaultValue={0}
                  value={detail.qty}
                  decimalsLimit={2}
                  disabled={true}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(value, "qty", i, "arrayDetail")
                  }
                  className="form-control  form-control-sm text-end"
                  labelfield={"false"}
                />
                <div
                  className={
                    detail.stock > 0
                      ? "fv-plugins-message-container valid-feedback"
                      : "fv-plugins-message-container invalid-feedback"
                  }
                >
                  {this.state.transactionIDValue != null && detail.qty != 0
                    ? i18n.t("Stock Available") + ": " + detail.stockFormat
                    : ""}
                </div>
              </td>
              <td>
                <Input
                  type={"input"}
                  name={"unitValue"}
                  title={i18n.t("Unit")}
                  value={detail.unitValue}
                  handler={(e) => {}}
                  labelfield={"false"}
                  disabled
                />

                <div className="fv-plugins-message-container invalid-feedback"></div>
              </td>

              <td>
                <InputNumber
                  id={"nettPrice" + i}
                  name="nettPrice"
                  placeholder={i18n.t("Nett Price")}
                  defaultValue={0}
                  value={detail.nettPrice}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "nettPrice",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                  prefix="Rp "
                  disabled
                  labelfield={"false"}
                />
              </td>
              <td width={"200px"}>
                <InputNumber
                  id={"priceTotal" + i}
                  name="priceTotal"
                  placeholder={i18n.t("Total Price")}
                  defaultValue={0}
                  value={detail.priceTotal}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "priceTotal",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                  prefix="Rp "
                  disabled
                  labelfield={"false"}
                />
              </td>
              <td>
                <InputNumber
                  id={"qtyReturn" + i}
                  name="qtyReturn"
                  placeholder={i18n.t("Qty Return")}
                  defaultValue={0}
                  value={detail.qtyReturn}
                  decimalsLimit={2}
                  disabled={this.state.transactionIDValue ? false : true}
                  onValueChange={async (value, name) => {
                    await this.handlerCurrencyArray(
                      value,
                      "qtyReturn",
                      i,
                      "arrayDetail"
                    );
                    if (detail.qtyReturn > detail.stock) {
                      await this.alertError(
                        "Info",
                        i18n.t("Quantity Return can't Exceed Avaliable Stock"),
                        "info"
                      );
                      await this.handlerCurrencyArray(
                        0,
                        "qtyReturn",
                        i,
                        "arrayDetail"
                      );
                    } else {
                      await this.handlerCurrencyArray(
                        value,
                        "qtyReturn",
                        i,
                        "arrayDetail"
                      );
                    }
                  }}
                  className="form-control  form-control-sm text-end"
                  labelfield={"false"}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("PurchaseReturnDetail.qtyReturn." + i)}
                </div>
              </td>
              <td width={"200px"}>
                <InputNumber
                  id={"totalReturn" + i}
                  name="totalReturn"
                  placeholder={i18n.t("Total Price Return")}
                  defaultValue={0}
                  value={detail.totalReturn}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "totalReturn",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                  prefix="Rp "
                  disabled
                  labelfield={"false"}
                />
              </td>
              <td>
                <Button
                  variant="danger"
                  className="btn btn-sm"
                  onClick={(e) => this.removeArray(i, "arrayDetail")}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              </td>
            </tr>
          </Fragment>
        ) : (
          //STOCK
          <Fragment>
            <tr>
              <td width={"500px"}>
                <Select2
                  isClearable={true}
                  name={"idItem"}
                  className={""}
                  size={4}
                  labelfield={"false"}
                  onChange={async (e) => {
                    var valueBefore = detail.idItem;
                    if (e != null) {
                      if (e != valueBefore) {
                        if (valueBefore != null) {
                          var index = this.state.itemUses.indexOf(
                            valueBefore.value
                          );
                          if (index !== -1) {
                            this.state.itemUses.splice(index, 1);
                          }
                        }
                        if (this.state.itemUses.includes(e.value)) {
                          this.alertError(
                            i18n.t("Item Use"),
                            i18n.t("Items Are Used"),
                            "info"
                          );
                        } else {
                          this.state.itemUses.push(e.value);
                          await this.handlerSelectArray(
                            e,
                            "idItem",
                            i,
                            "arrayDetail",
                            "itemValue"
                          );
                          await this.changeData(
                            "unit",
                            "getUnitWarehouseTF",
                            "",
                            "arrayDetail",
                            i
                          );
                          
                        }
                      }
                    } else {
                      if (valueBefore != null) {
                        var index = this.state.itemUses.indexOf(
                          valueBefore.value
                        );
                        if (index !== -1) {
                          this.state.itemUses.splice(index, 1);
                        }
                      }
                      await this.handlerSelectArray(
                        e,
                        "idItem",
                        i,
                        "arrayDetail"
                      );
                      await this.setState({ itemUses: [] });
                      await this.handlerCurrencyArray(
                        [],
                        "convertionDetail",
                        i,
                        "arrayDetail"
                      );
                      await this.handlerCurrencyArray(
                        0,
                        "qtyReturn",
                        i,
                        "arrayDetail"
                      );
                      await this.handlerCurrencyArray(
                        0,
                        "nettPrice",
                        i,
                        "arrayDetail"
                      );
                    }
                  }}
                  options={this.state.itemList}
                  value={detail.idItem}
                  placeholder={this.state.itemText}
                />
                 <div className={"fv-plugins-message-container valid-feedback"}>
                  {detail.idItem != null && this.state.idWarehouse != null
                    ? i18n.t("Price") + " : Rp. " + 
                    ((detail.idItem.price!=null)?parseFloat(detail.idItem.price)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "0.00")
                    : ""}
                </div>
              </td>
              <td width={"200px"}>
                <div className="row">
                  <div className="col-9 p-0">
                    <InputNumber
                      id={"qtyReturn" + i}
                      name="qtyReturn"
                      placeholder={i18n.t("Qty Return")}
                      defaultValue={0}
                      value={detail.qtyReturn}
                      decimalsLimit={2}
                      disabled
                      onValueChange={async (value, name) => {
                        await this.handlerCurrencyArray(
                          value,
                          "qtyReturn",
                          i,
                          "arrayDetail"
                        );
                        if (
                          detail.qtyReturn > parseFloat(detail.idItem.stock)
                        ) {
                          await this.alertError(
                            "Info",
                            i18n.t(
                              "Quantity Return can't Exceed Avaliable Stock"
                            ),
                            "info"
                          );
                          await this.handlerCurrencyArray(
                            0,
                            "qtyReturn",
                            i,
                            "arrayDetail"
                          );
                        } else {
                          await this.handlerCurrencyArray(
                            value,
                            "qtyReturn",
                            i,
                            "arrayDetail"
                          );
                        }
                      }}
                      className="form-control  form-control-sm text-end"
                      labelfield={"false"}
                    />
                  </div>
                  {detail.idItem != null ? (
                    <div className="col p-0">
                      <Button
                        variant={
                          detail.convertionDetail.length > 0
                            ? "warning"
                            : "info"
                        }
                        className="btn p-2"
                        onClick={(e) => {
                          this.setConvertion(
                            detail,
                            i,
                            "arrayDetail",
                            "qtyReturn"
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            detail.convertionDetail.length > 0
                              ? faBoxOpen
                              : faBoxArchive
                          }
                        />{" "}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className={"fv-plugins-message-container valid-feedback"}>
                  {detail.idItem != null && this.state.idWarehouse != null
                    ? i18n.t("Stock Available") + ": " + detail.idItem.stock
                    : ""}
                </div>
              </td>

              <td width={"200px"}>
                <InputNumber
                  id={"nettPrice" + i}
                  name="nettPrice"
                  placeholder={i18n.t("Nett Price")}
                  defaultValue={0}
                  value={detail.nettPrice}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "nettPrice",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                  prefix="Rp "
                  labelfield={"false"}
                />
              </td>
              {/* <td width={"200px"}>
            <InputNumber
              id={"priceTotal" + i}
              name="priceTotal"
              placeholder={i18n.t("Total Price")}
              defaultValue={0}
              value={detail.priceTotal}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "priceTotal", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              disabled
              labelfield={"false"}
            />
          </td> */}
              {/* <td>
            <InputNumber
              id={"qtyReturn" + i}
              name="qtyReturn"
              placeholder={i18n.t("Qty Return")}
              defaultValue={0}
              value={detail.qtyReturn}
              decimalsLimit={2}
              disabled={this.state.transactionIDValue ? false : true}
              onValueChange={async (value, name) => {
                await this.handlerCurrencyArray(
                  value,
                  "qtyReturn",
                  i,
                  "arrayDetail"
                );
                if (detail.qtyReturn > detail.stock) {
                  await this.alertError(
                    "Info",
                    i18n.t("Quantity Return can't Exceed Avaliable Stock"),
                    "info"
                  );
                  await this.handlerCurrencyArray(
                    0,
                    "qtyReturn",
                    i,
                    "arrayDetail"
                  );
                } else {
                  await this.handlerCurrencyArray(
                    value,
                    "qtyReturn",
                    i,
                    "arrayDetail"
                  );
                }
              }}
              className="form-control  form-control-sm text-end"
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReturnDetail.qtyReturn." + i)}
            </div>
          </td> */}
              <td width={"200px"}>
                <InputNumber
                  id={"totalReturn" + i}
                  name="totalReturn"
                  placeholder={i18n.t("Total Price Return")}
                  defaultValue={0}
                  value={detail.totalReturn}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray(
                      value,
                      "totalReturn",
                      i,
                      "arrayDetail"
                    )
                  }
                  className="form-control  form-control-sm text-end"
                  prefix="Rp "
                  disabled
                  labelfield={"false"}
                />
              </td>
              <td>
                <Button
                  variant="danger"
                  className="btn btn-sm"
                  onClick={(e) => this.removeArray(i, "arrayDetail")}
                >
                  <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                </Button>
              </td>
            </tr>
          </Fragment>
        )}
      </React.Fragment>
    );
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    const { data, isNewRecord } = this.props;
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-code-pret";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
        };
        break;
      case "getStockPret":
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var url = process.env.REST_URL + "helper/get-stock-pret";
        params = {
          idBranch: branch,
          idStore: store,
          bandNo: 2023,
          batchNo: 1001,
          expDate: "2023-02-06",
          idItem: 5,
        };

        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getInvoice":
        var url = process.env.REST_URL + "helper/get-invoice-pret";
        var supplier =
          this.state.supplierValue != undefined
            ? this.state.supplierValue.value
            : null;
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != undefined
            ? this.state.storeCurrent.value
            : null;
        params = {
          supplier: supplier,
          branch: branch,
          store: store,
        };
        break;
      case "getDetailInvoice":
        var url = process.env.REST_URL + "helper/get-detail-invoice-pret";
        var id =
          this.state.transactionIDValue != undefined
            ? this.state.transactionIDValue.value
            : null;
        params = {
          id: id,
        };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;
      case "getItemWarehouse":
        var idWH =
          this.state.warehouseValue != null
            ? this.state.warehouseValue.value
            : null;
        var url = process.env.REST_URL + "helper/get-item-warehouse";
        params = {
          idWH: idWH,
        };
        break;
      case "getWarehouseStock":
        var url = process.env.REST_URL + "helper/get-stock-warehouse";
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var idWarehouse =
          this.state.warehouseValue != null
            ? this.state.warehouseValue.helper
            : null;
        var idSubWarehouse =
          this.state.warehouseValue != null
            ? this.state.warehouseValue.helper2
            : null;
        var id =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.item
            : null;

        params = {
          branch: idBranch,
          store: idStore,
          warehouse: idWarehouse,
          subwarehouse: idSubWarehouse,
          id: id,
        };
        break;
      case "getUnitWarehouseTF":
        var url = process.env.REST_URL + "helper/get-unit-warehousetf";
        var item =
          this.state[array][arraykey].idItem != null
            ? this.state[array][arraykey].idItem.value
            : null;
        params = {
          id: item,
          type:'return'
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  reCalculate() {
    this.state.arrayDetail.map(async (detail, po) => {
      this.calculateRow(po, "arrayDetail");
    });
  }

  resetState() {
    if (this.state.close == 1) {
      this.setState({
        supplierValue: "",
      });
      this.state.close = 2;
    }
  }
  resetDetail() {
    this.setState({
      arrayDetail: [
        {
          itemValue: null,
          idItem: null,
          idUnit: null,
          unit: null,
          description: "",
          qty: 0,
          unitValue: null,
          price: 0,
          discountPercent: 0,
          discountPrice: 0,
          taxPrice: 0,
          nettPrice: 0,
          priceTotal: 0,
          tax: 0,
          idTransactionDet: null,
          idReceiving: null,
          idReceivingDetail: null,
          bandNo: null,
          batchNo: null,
          expDate: null,
          stock: 0,
          status: 1,
          totalReturn: 0,
          convertionDetail: [],
        },
      ],
    });
  }

  render() {
    const { helperPR } = this.props;
    this.state.supplierList = helperPR != null ? helperPR.supplier : null;
    this.state.branchList = helperPR != null ? helperPR.branch : null;
    this.state.accountBankList = helperPR != null ? helperPR.bank : null;

    return (
      <form>
        <React.Fragment>
          <div className="row">
            <div className="col-3">
              <Input
                type={"input"}
                name={"transactionNo"}
                title={i18n.t("Transaction No.")}
                required
                disabled
                value={this.state.transactionNo}
                handler={this.handler}
                error={this.renderError("PurchaseReturn.transactionNo")}
              />
            </div>
            <div className="col-3">
              <DatePickerCustom
                name={"date"}
                title={"Date"}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("transactionNo", "getCode", "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.setState({
                    transactionIDValue: "",
                    transactionIDList: [],
                    transactionNo: "",
                    warehouseList: [],
                    warehouseValue: null,
                    supplierValue: null,
                    itemUses: [],
                  });
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ storeCurrent: null, storeList: [] });
                   
                    await this.resetDetail();
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData("transactionNo", "getCode", "code");
                    await this.changeData("warehouseList", "getWarehouse", "");
                    await this.changeData("storeList", "getStore", "");
                    await this.changeData(
                      "transactionIDList",
                      "getInvoice",
                      ""
                    );
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("PurchaseReturn.idBranch")}
              />
            </div>
            <div className="col-3">
              <Select2
                isClearable={true}
                name={"idStore"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "idStore", "storeCurrent");
                  await this.changeData("transactionIDList", "getInvoice", "");
                  await this.setState({ itemUses: [] });
                  await this.resetDetail();
                }}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Radio
                list={{ 1: i18n.t("Stock"), 2: i18n.t("Invoice") }}
                name={"returnMethod"}
                title={i18n.t("Return Method")}
                inline={false}
                value={this.state.returnMethod}
                handler={this.handlerRadio}
                error={this.renderError("returnMethod")}
                required
              />
            </div>
          </div>

          <div className="row">
            {this.state.returnMethod == 2 ? (
              <React.Fragment>
                <div className="col-3">
                  <Select2
                    isClearable={true}
                    name={"idSupplier"}
                    required={true}
                    className={""}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(
                          e,
                          "idSupplier",
                          "supplierValue"
                        );
                        await this.setState({
                          transactionIDValue: "",
                          transactionIDList: [],
                          itemUses: [],
                        });
                        await this.resetDetail();
                      } else {
                        await this.handlerSelect(
                          e,
                          "idSupplier",
                          "supplierValue"
                        );
                        await this.changeData(
                          "transactionIDList",
                          "getInvoice",
                          ""
                        );
                      }
                    }}
                    options={this.state.supplierList}
                    title={i18n.t("Supplier")}
                    value={this.state.supplierValue}
                    placeholder={this.state.supplierText}
                    error={this.renderError("PurchaseReturn.idSupplier")}
                  />
                </div>
                <div className="col-3">
                  <Select2
                    isClearable={true}
                    name={"transactionID"}
                    required={true}
                    className={""}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(
                          e,
                          "transactionID",
                          "transactionIDValue"
                        );
                        await this.resetDetail();
                      } else {
                        await this.handlerSelect(
                          e,
                          "transactionID",
                          "transactionIDValue"
                        );
                        await this.changeData(
                          "detailInvoice",
                          "getDetailInvoice",
                          "",
                          null,
                          null,
                          async () => {
                            await this.fetchInvoice();
                          }
                        );
                      }
                    }}
                    options={this.state.transactionIDList}
                    title={i18n.t("ID Transaction")}
                    value={this.state.transactionIDValue}
                    placeholder={this.state.transactionIDText}
                    isDisabled={this.state.supplierValue ? false : true}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError("PurchaseReturn.idTransaction")}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="col-3">
                  <Select2
                    isClearable={true}
                    name={"idWarehouse"}
                    required={true}
                    className={""}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {

                        await this.handlerSelect(
                          e,
                          "idWarehouse",
                          "warehouseValue"
                        );
                        await this.setState({ 
                          itemUses:[], 
                          itemList: [], 
                            arrayDetail:[{
                            idItem : null,
                            convertionDetail : [],
                        }]
                     });
                      } else {
                        await this.handlerSelect(
                          e,
                          "idWarehouse",
                          "warehouseValue"
                        );
                       
                        await this.changeData(
                          "itemList",
                          "getItemWarehouse",
                          ""
                        );
                      }
                    }}
                    options={this.state.warehouseList}
                    value={this.state.warehouseValue}
                    placeholder={this.state.warehouseText}
                    title={i18n.t("Warehouse")}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="col-3">
              <Radio
                list={
                  this.state.returnMethod == 2
                    ? this.state.returnTypeList
                    : this.state.returnTypeListStock
                }
                name={"returnType"}
                title={i18n.t("Return Type")}
                inline={true}
                value={this.state.returnType}
                handler={this.handler}
              />
            </div>
            {this.state.returnType == 2 ? (
              <div className="col-3">
                <Select2
                  isClearable={true}
                  name={"accountBank"}
                  onChange={async (e) => {
                    await this.handlerSelect(
                      e,
                      "accountBank",
                      "accountBankValue"
                    );
                  }}
                  options={this.state.accountBankList}
                  title={i18n.t("Account Bank")}
                  value={this.state.accountBankValue}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <TextArea
                name={"description"}
                title={i18n.t("Description")}
                value={this.state.description}
                handler={this.handler}
                error={this.renderError("PurchaseReturn.description")}
              />
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {this.state.returnMethod == 2 ? (
                      <Fragment>
                        <th>{i18n.t("Item")}</th>
                        <th className="text-center">{i18n.t("Qty")}</th>
                        <th className="text-center">{i18n.t("Unit")}</th>
                        <th className="text-center">{i18n.t("Price Nett")}</th>
                        <th className="text-center">{i18n.t("Price total")}</th>
                        <th className="text-center">{i18n.t("Qty Return")}</th>
                        <th className="text-center">
                          {i18n.t("Total Return")}
                        </th>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <th>{i18n.t("Item")}</th>
                        <th className="text-center">{i18n.t("Qty Return")}</th>
                        <th className="text-center">{i18n.t("Price Nett")}</th>
                        <th className="text-center">
                          {i18n.t("Total Return")}
                        </th>
                      </Fragment>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              {this.state.returnMethod == 1 ? (
                <Button
                  variant="success"
                  onClick={(e) =>
                    this.addArray(
                      {
                        itemValue: null,
                        idItem: null,
                        idUnit: null,
                        unit: null,
                        description: "",
                        qty: 0,
                        unitValue: null,
                        price: 0,
                        discountPercent: 0,
                        discountPrice: 0,
                        taxPrice: 0,
                        nettPrice: 0,
                        priceTotal: 0,
                        tax: 0,
                        idTransactionDet: null,
                        idReceiving: null,
                        idReceivingDetail: null,
                        bandNo: null,
                        batchNo: null,
                        expDate: null,
                        stock: 0,
                        status: 1,
                        totalReturn: 0,
                        convertionDetail: [],
                      },
                      "arrayDetail"
                    )
                  }
                >
                  {i18n.t("Add Item")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-end">
              <div className="col-4">
                <Table borderless responsive="sm">
                  <tbody>
                    <tr className="align-middle">
                      <td>{i18n.t("Total Price Return")}</td>
                      <td>:</td>
                      <td>
                        <InputNumber
                          id={"totalPriceReturn"}
                          name="totalPriceReturn"
                          placeholder=""
                          defaultValue={0}
                          value={this.state.totalPriceReturn}
                          decimalsLimit={2}
                          onValueChange={(value, name) => {}}
                          className="p-0 form-control form-control-xs form-control-flush text-end"
                          prefix="Rp "
                          readOnly
                          labelfield={"false"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.modalDetail}
            backdropClassName="next1"
            centered
          >
            <Modal.Header>
              <Modal.Title>{this.state.titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormConvertion
                data={this.state.itemChoose}
                isNewRecord={false}
                ref={this.formConvertion}
              />
            </Modal.Body>
            <Modal.Footer className={"flex-center"}>
              <Button
                variant="secondary"
                onClick={(e) => this.saveConvertion(false)}
              >
                {i18n.t("Close")}
              </Button>
              <Button
                variant="primary"
                onClick={(e) => this.saveConvertion(true)}
              >
                {i18n.t("Convertion")}
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      </form>
    );
  }
}

export default Form;

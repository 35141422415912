import DownPaymentActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  dataIn:{},
  dataOut:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperDownPayment:null,
  helperFullDownPayment:null,
  errorInternal:false
};

function DownPaymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DownPaymentActions.RESET_LIST:
      return INITIAL_STATE;
    case DownPaymentActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case DownPaymentActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case DownPaymentActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case DownPaymentActions.VIEW_DATA_IN:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, dataIn:action.payload,isNewRecord:false,showModalIn:true};
    case DownPaymentActions.VIEW_DATA_OUT:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, dataOut:action.payload,isNewRecord:false,showModalOut:true};
    case DownPaymentActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case DownPaymentActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case DownPaymentActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case DownPaymentActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case DownPaymentActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case DownPaymentActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case DownPaymentActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case DownPaymentActions.GET_HELPER_DOWNPAYMENT:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperDownPayment:action.payload};
    case DownPaymentActions.GET_HELPERFULL_DOWNPAYMENT:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperFullDownPayment:action.payload};
    case DownPaymentActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case DownPaymentActions.VOID_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form};
       
    default:
      return state;
    }
}

export default DownPaymentReducer;

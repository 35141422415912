import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import SubWarehouse from '../../../../components/modules/master/subwarehouse';
import WarehouseMiddleware from '../../../../middleware/modules/master/warehouse';
import SubWarehouseMiddleware from '../../../../middleware/modules/master/subwarehouse';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
// middlewares


const mapStateToProps = ({ auth,subWarehouse }) => ({
  isProcessing: subWarehouse.isProcessing,
  isError: subWarehouse.isError,
  errorMessage: subWarehouse.errorMessage,
  datalist:subWarehouse.data,
  showModal:subWarehouse.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getWarehouse:()=>WarehouseMiddleware.getHelper(),
    create: () =>SubWarehouseMiddleware.create(),
    reset: () =>SubWarehouseMiddleware.resetList(),
    search: data =>SubWarehouseMiddleware.list(data),
    update: data =>SubWarehouseMiddleware.update(data),
    delete: (id,search) =>SubWarehouseMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class SubWarehouseContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.getWarehouse()
    this.props.update(id)
  }
  createItem(){
    this.props.create()
    this.props.getWarehouse()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Sub Warehouse List")}
          </title>
        </Helmet>
      <SubWarehouse
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubWarehouseContainer);

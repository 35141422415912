import NewsEventActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperNewsEvent:null,
  sellingHelperFull:null,
  printNewsEvent:null
};

function NewsEventReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NewsEventActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case NewsEventActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case NewsEventActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case NewsEventActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case NewsEventActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case NewsEventActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case NewsEventActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case NewsEventActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case NewsEventActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case NewsEventActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case NewsEventActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case NewsEventActions.GET_HELPER_NEWSEVENT:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperNewsEvent:action.payload};
    case NewsEventActions.GET_HELPER_NEWSEVENT_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,NewsEventHelperFull:action.payload};
    case NewsEventActions.BEFORE_PRINT_NEWSEVENT:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printNewsEvent:null,isProcessing:true};
    case NewsEventActions.PRINT_NEWSEVENT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printNewsEvent:action.payload};
    
    default:
      return state;
    }
}


export default NewsEventReducer;

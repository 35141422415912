import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import FormInConfirm from "../../../../components/modules/accounting/log-bg/form-in";
import LogBgMiddleware from "../../../../middleware/modules/accounting/log-bg";

const mapStateToProps = ({ auth, logBg }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: logBg.isProcessing,
  isError: logBg.isError,
  errorMessage: logBg.errorMessage,
  dataBG: logBg.dataIn,
  isSuccess: logBg.isSuccess,
  isReset: logBg.isReset,
  isNewRecord: logBg.isNewRecord,
  onSubmit: logBg.onSubmit,
  reject: logBg.reject,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModalIn: () => LogBgMiddleware.resetIn(),
      submitData: (data,search) =>LogBgMiddleware.beforeSaveNew(data,search),
      rejectData: (data,search) =>LogBgMiddleware.beforeReject(data,search),
      cancel: () =>LogBgMiddleware.reset(),
    },
    dispatch
  );
};
class FormBGIn extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      add:0,
      modal: {
        title: "",
        show: false,
      },
    };
    this.formBGIn = React.createRef();
    this.close = this.close.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  close() {
    this.props.closeModalIn();
  }

  submitForm(status) {
    const {isNewRecord, dataUpdate } = this.props;
    const search = {
      limit: 10,
      key:'In'
    };
    var form = this.formBGIn.current.runSubmit();
    if(status == true){
      this.props.submitData(form,search);
    }else{
      this.props.rejectData(form,search);
    }
  }

  render() {
    const {showModalIn,reject,onSubmit,helperLogBg,isProcessing} = this.props
    return (
      <>
        <Modal show={showModalIn} size="md" enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{reject==true?"Reject Billyet Giro":"Confirm Billyet Giro"}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{reject==true?"Reject Billyet Giro":"Confirm Billyet Giro"}</title>
              </Helmet>
          <Modal.Body>
            <FormInConfirm
              {...this.props}
              ref={this.formBGIn}
              isProcessing={isProcessing}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant={reject==true?"danger":"primary"} onClick={() => ((onSubmit)?false:reject==true?this.submitForm(false):this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{reject==true?i18n.t("Reject"):i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBGIn);

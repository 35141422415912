import ConfigBranchActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModalConfig: false,
  onSubmit: false,
  single: null,
  helperBranch: [],
  errorInternal: false,
};

function ConfigBranchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ConfigBranchActions.RESET_LIST:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        data: {},
        showModalConfig:false,
      };
    case ConfigBranchActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        errorMessage: [],
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModalConfig: true,
      };
    case ConfigBranchActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case ConfigBranchActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: action.payload,
        errorMessage: [],
        isNewRecord: false,
        showModalConfig: true,
      };
    case ConfigBranchActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModalConfig: false,
      };
    case ConfigBranchActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModalConfig: false,
      };
    case ConfigBranchActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case ConfigBranchActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case ConfigBranchActions.ERROR_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case ConfigBranchActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        showModalConfig: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case ConfigBranchActions.PROCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: true,
        isError: false,
        isSuccess: false,
      };
    case ConfigBranchActions.GET_HELPER_BRANCH:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperBranch: action.payload,
      };
    case ConfigBranchActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default ConfigBranchReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import BeginningBalanceMiddleware from "../../../../middleware/modules/accounting/beginning-balance";
import BeginningBalance from "../../../../components/modules/accounting/beginning-balance";
// middlewares

const mapStateToProps = ({ auth, beginningBalance }) => ({
  isProcessing: beginningBalance.isProcessing,
  isError: beginningBalance.isError,
  errorMessage: beginningBalance.errorMessage,
  datalist: beginningBalance.data,
  helperBeginningBalance: beginningBalance.helperBeginningBalance,
  afterSave: beginningBalance.afterSave,
  showModal: beginningBalance.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => BeginningBalanceMiddleware.create(),
      getHelper: () => BeginningBalanceMiddleware.getHelper(),
      reset: () => BeginningBalanceMiddleware.resetList(),
      search: (data) => BeginningBalanceMiddleware.list(data),
      update: (data) => BeginningBalanceMiddleware.update(data),
      void: (data,search) => BeginningBalanceMiddleware.beforeVoid(data,search),
      delete: (id, search) => BeginningBalanceMiddleware.beforeDelete(id, search),
      submit: (data, search) => BeginningBalanceMiddleware.beforeSaveNew(data, search),
    },
    dispatch
  );
};
class BeginningBalanceContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,

  };

  static defaultProps = {
    afterSave: false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.submitItem = this.submitItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.voidItem = this.voidItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  createItem() {
    this.props.getHelper();
    this.props.create();
  }

  submitItem() {
    this.props.submit();
  }

  voidItem(data,search) {
    this.props.void(data,search);
  }

  render() {
    const { isProcessing, search, datalist, showModal } =
      this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Beginning Balance List")}</title>
        </Helmet>
        <BeginningBalance
          {...this.props}
          createData={this.createItem}
          voidItem={this.voidItem}
          showModal={showModal}
          // helperBeginningBalance={helperBeginningBalance}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginningBalanceContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { PaymentSelling } from "../../../../components/modules/sales/payment-selling";
import PaymentSellingMiddleware from "../../../../middleware/modules/sales/payment-selling";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// middlewares
const mapStateToProps = ({ auth, paymentSelling }) => ({
  isProcessing: paymentSelling.isProcessing,
  isError: paymentSelling.isError,
  errorMessage: paymentSelling.errorMessage,
  data: paymentSelling.data,
  showModal: paymentSelling.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => PaymentSellingMiddleware.create(),
      reset: () => PaymentSellingMiddleware.resetList(),
      search: (data) => PaymentSellingMiddleware.list(data),
      update: (data) => PaymentSellingMiddleware.update(data),
      delete: (id, search) => PaymentSellingMiddleware.beforeDelete(id, search),
      getHelperFull: (id, search) =>
        PaymentSellingMiddleware.getHelperFull(id, search),
      payment: (id, type) => PaymentSellingMiddleware.getPaymentSelling(id, type),
    },
    dispatch
  );
};
class PaymentSellingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    data: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    data: {},
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>
          {i18n.t("Payment Selling")}
        </title>
      </Helmet>
    <PaymentSelling {...this.props} />
    </React.Fragment>
    );
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSellingContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import Coa5 from "../../../../components/modules/accounting/coa5";
import Coa5Middleware from "../../../../middleware/modules/accounting/coa5";
import Coa2Middleware from "../../../../middleware/modules/accounting/coa2";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, coa5 }) => ({
  isProcessing: coa5.isProcessing,
  isError: coa5.isError,
  errorMessage: coa5.errorMessage,
  datalist: coa5.data,
  showModal: coa5.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => Coa5Middleware.create(),
      reset: () => Coa5Middleware.resetList(),
      search: (data) => Coa5Middleware.list(data),
      update: (data) => Coa5Middleware.update(data),
      delete: (id, search) => Coa5Middleware.beforeDelete(id, search),
      helperCOA2: () => Coa2Middleware.getHelper(),
    },
    dispatch
  );
};
class Coa5Container extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.helperCOA2();
    this.props.update(id);
  }
  createItem() {
    this.props.helperCOA2();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Coa 5")}</title>
        </Helmet>
      <Coa5
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coa5Container);

import React, { Component, useEffect, useState } from 'react'
import InputNumber from '../../../utilities/Form/InputNumber'
import Select2 from '../../../utilities/Form/Select2'
import i18n from '../../../../i18n'

export class TRComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unitList:[],
            update:0,
            warehouse:null
        }
    }
     renderUnit(data){
        const {unit}=this.props
        let html=[]
        let total=0;
        html.push(unit.map((unit,i)=>{
                const check=data.unit.filter((i)=>i.idUnit==unit.idUnit)
                if(check?.length >0){
                    total +=check[0].qty
                }
                return <td align="center">{(check?.length >0)?check[0].qtyCurrent:0}</td>
            }
        ))
        html.push(<td align="center">{total}</td>)
        return html
    }
     renderUnitInput(){
        return this.state.unitList.map((unit,i)=>{
                return <td align="center">
                    <InputNumber
                        key={i}
                        error=""
                        required={false}
                        title={unit.unitName}
                        labelfield=""
                        onValueChange ={(e)=>{
                            const unitList=this.state.unitList
                            unitList[i].value=(e > unit.qtyCurrent)?unit.qtyCurrent:e
                            this.setState({unitList:unitList})
                        }}
                        className="form-control form-control-sm"
                        name="unit[]"
                        decimalsLimit={0}
                        fixedDecimalLength={0}
                        value={unit?.value}
                    ></InputNumber>
                </td>
            }
        )
    }
    render() {
        const {warehouseList,unit,no,data}=this.props
        if(this.state.update==0){
            this.state.update=1;
            this.state.unitList=data.unit.map((p)=> Object.assign({},{...p,value:p.qtyCurrent})) 
        }
        return(
        <tr>
            <td align="center">{no+1}</td>
            <td>{data.name} ({data.bandNo})</td>
            {this.renderUnit(data)}
            {/* <td></td> */}
            {this.renderUnitInput(data)}
            <td></td>
            <td><Select2
                labelfield={"false"}
                isClearable
                name={"idWarehouse"}
                required={true}
                onChange={async (e) => {
                    this.setState({warehouse:e})
                }}
                options={warehouseList}
                title={i18n.t("Warehouse")}
                value={this.state.warehouse}
                placeholder={"Select Warehouse"}
                currentState={"typeCurrent"}
                error={""}
            /></td>
        </tr>)
    }
}

export default TRComponent;
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import Radio from "../../../utilities/Form/radio";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form:{},
      type:'',
      prNo: "",
      date: new Date(),
      deliveryDate: new Date(),
      description: "",
      status: 1,
      arrayUnit: [
        {
          idItem: null,
          idUnit: null,
          qty: 0,
          itemList:[],
          description: "",
          itemValue: null,
          typeItemValue:null,
          unitValue: null,
          disabled: false,
        },
      ],
      idSO:null,
      detailSalesOrder:[],
      unitList: [],
      unitText: i18n.t("Select Unit"),
      itemList: [],
      itemText: i18n.t("Select Item"),
      branchList: null,
      branchValue: null,
      branchText: i18n.t("Select Branch"),
      storeList:[],
      storeValue: null,
      storeText: i18n.t("Select Store"),
      typeItemList:[],
      typeItemValue: null,
      typeItemText: i18n.t("Select Item Type"),
      salesOrderCurrent: null,
      salesOrderText: i18n.t("Select Sales Order"),
      salesOrderList: [],
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let deliveryDate = moment(this.state.deliveryDate).format("YYYY-MM-DD");
    const PurchaseRequest = {
      prNo: this.state.prNo,
      date: date,
      // type: this.state.type,
      deliveryDate: deliveryDate,
      description: this.state.description,
      idBranch:
        this.state.branchValue != null ? this.state.branchValue.value : "",

      idItemType : this.state.typeItemValue != null ? this.state.typeItemValue.value : "",
      idStore: this.state.storeValue != null ? this.state.storeValue.value : "",
      idSO: this.state.salesOrderCurrent != null ? this.state.salesOrderCurrent.value : "",
      status: this.state.status,
    };
    const PRDetail = this.state.arrayUnit;
    let idItem = [];
    let qty = [];
    // let type = [];
    let idUnit = [];
    let description = [];
    let status = [];

    for (var i = 0; i < PRDetail.length; i++) {
      idItem.push(PRDetail[i].itemValue.value);
      description.push(
        PRDetail[i].description == "" ? "" : PRDetail[i].description
      );
      idUnit.push(PRDetail[i].unitValue.value);
      qty.push(PRDetail[i].qty);
      // type.push((PRDetail[i].typeItemValue!==null)?PRDetail[i].typeItemValue.value:null);
      status.push(1);
    }
    this.state.form.PurchaseReq = PurchaseRequest;
    this.state.form.PurchaseReqDetail = {
      idItem: idItem,
      description: description,
      idUnit: idUnit,
      qty: qty,
      // type: type,
      status: status,
    };
    this.state.isUpdate = 0;
    return this.state.form;
  }
  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    // this.calculateRow(i,arrayname)
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  renderDetail(detail, i) {
    return (
        <tr key={"detail"+i}>
          {/* <td>
            <Select
              isClearable={true}
              name={"typeItem"}
              required={true}
              onChange={async (e) =>{
                await this.handlerSelectArray(
                  e,
                  "typeItem",
                  i,
                  "arrayUnit",
                  "typeItemValue"
                )
                await this.changeData("itemList", "getItem","item","arrayUnit",i);
              }
                
              }
              options={this.state.typeItemList}
              title={i18n.t("Type Item")}
              value={detail.typeItemValue}
              placeholder={this.state.typeItemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReqDetail.typeItem." + i)}
            </div>
          </td> */}
          <td>
            <Select
              isClearable={true}
              name={"idItem"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "idItem",
                  i,
                  "arrayUnit",
                  "itemValue"
                )
              }
              options={this.state.itemList}
              title={i18n.t("Item")}
              value={detail.itemValue}
              placeholder={this.state.itemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReqDetail.idItem." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qty}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "qty", i, "arrayUnit")
              }
              className="form-control  form-control-sm text-end"
              error={this.renderError("PurchaseReqDetail.qty." + i)}
              labelfield={"false"}
            />
          </td>
          <td>
            <Select
              name={"idUnit"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "idUnit",
                  i,
                  "arrayUnit",
                  "unitValue"
                )
              }
              options={this.state.unitList}
              title={i18n.t("Unit")}
              value={detail.unitValue}
              placeholder={this.state.unitText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReqDetail.idUnit." + i)}
            </div>
          </td>
          <td>
            <input
              className="form-control  form-control-sm "
              type={"input"}
              name={"description"}
              id={"description" + i}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayUnit")
              }
            />
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayUnit")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
    );
  }

  resetDetail(){
    this.state = {
    arrayUnit: [
      {
        idItem: null,
        idUnit: null,
        qty: 0,
        itemList:[],
        description: "",
        itemValue: null,
        unitValue: null,
        disabled: false,
      },
    ],
  }
  }

  fetchSalesOrder() {
    const {isProcessing} = this.props
    let row = this.state.detailSalesOrder;

    if(row !=null){
        var array=[];
          for(var p=0;p<row.length;p++){
            array.push(
              {
                idSO: row[p].id_so,
                itemValue: { value: row[p].id_item, label: row[p].name_item },
                unitValue: { value: row[p].id_unit, label: row[p].name_unit },
                unitPrice: row[p].price,
                qty: row[p].qty,
              }
            )
          }
        this.setState({arrayUnit:array})
    }
  }

  changeData(keyFill,type=null,key='',array=null,arraykey=null,extraFunction=null){
    const token=cookieManager.getUserToken()
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-pr-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        var store =
          this.state.storeValue != undefined
            ? this.state.storeValue.value
            : null;
        params = {
          date: date,
          branch: branch,
          store: store,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          id: branch,
        };
        break;
        case "getItem":
          var url=process.env.REST_URL + "helper/get-item-pr";
          var value=this.state.typeItemValue != undefined 
          ? this.state.typeItemValue.value 
          :null;
            params = {
              type: value
            }
        break;
        case "getItemDetail":
          var url=process.env.REST_URL + "helper/get-item-pr";
          var value=this.state[array][arraykey].typeItemValue
          if(value !=null){
            params = {
              type: (value !=null)?value.value:null,
            };
          }else{
            type=null
          }
         
        break;
        case "getSalesOrder":
        var url = process.env.REST_URL + "helper/get-sales-order";
        var type = this.state.type;
        var branch =
          this.state.branchValue != null
            ? this.state.branchValue.value
            : null;
        var store =
          this.state.storeValue != null
            ? this.state.storeValue.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          branch: branch,
          store: store,
          type:0,
          date:date
        };
        break;
        case "getDetailSalesOrder":
          var url = process.env.REST_URL + "helper/get-sales-order-detail";
          var id= this.state.salesOrderCurrent != null 
          ? this.state.salesOrderCurrent.value : null
        
              params={
                  id:id,
                  type:99
              }
          
        break;
    }
    if(type !=null){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          url:url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            console.log(response)
              if(array!=null){
                  var arr = [...this.state[array]];
                  const objectss = arr[arraykey]
                  if(key==''){
                      objectss[keyFill] = response.data.result;
                  }else{
                      objectss[keyFill] = response.data.result[key];
                  }
              }else{
                  if(key==''){
                     var arr= {[keyFill]:response.data.result}
                  }else{
                      var arr= {[keyFill]:response.data.result[key]}
                  }
              }
              if(extraFunction!=null){
                  this.setState(arr,extraFunction)
              }else{
                  this.setState(arr)
              }
             
          }
        })
        .catch((error) => {
          console.log(error)
        });
  }
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  render() {
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    const {
      data,
      isNewRecord,
      purchaserequestHelper,
    } = this.props;
    this.state.unitList =
      purchaserequestHelper != null ? purchaserequestHelper.unit : null;
    this.state.typeItemList =
      purchaserequestHelper != null ? purchaserequestHelper.typeItem : null;
    this.state.branchList =
      purchaserequestHelper != null ? purchaserequestHelper.branch : null;

    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.prNo = data.pr_no,
          this.state.date = new Date(data.date),
          this.state.deliveryDate = new Date(data.delivery_date),
          this.state.description = data.description,
          this.state.status = data.status,
          this.state.storeValue = { value: data.id_store, label: data.name_store, };
          this.state.branchValue = {
            value: data.id_branch,
            label: data.name_branch,
          };

        this.state.arrayUnit = [];
        for (var po = 0; po < data.req_detail.length; po++) {
          if (data.req_detail[po].status < 3) {
            this.state.arrayUnit.push({
              idItem: data.req_detail[po].id_item,
              description:
                data.req_detail[po].description == null
                  ? ""
                  : data.req_detail[po].description,
              idUnit: data.req_detail[po].id_unit,
              qty:
                data.req_detail[po].qty == null ? "" : data.req_detail[po].qty,
              status: data.req_detail[po].status,
              itemValue: {
                value:
                  data.req_detail[po].id_item == null
                    ? ""
                    : data.req_detail[po].id_item,
                label: data.req_detail[po].name_item,
              },
              typeItemValue: {
                value:
                  data.req_detail[po].id_type_item == null
                    ? ""
                    : data.req_detail[po].id_type_item,
                label: data.req_detail[po].name_type_item,
              },
              itemList:data.req_detail[po].item_rel,
              unitValue: {
                value:
                  data.req_detail[po].id_unit == null
                    ? ""
                    : data.req_detail[po].id_unit,
                label: data.req_detail[po].name_unit,
              },
            });
          }
        }

        this.state.isUpdate = 1;
      }
    }

    return (
      <form>
        <div className="row">
          <div className="col-md-3">
            <Input
              disabled={true}
              type={"input"}
              name={"prNo"}
              title={i18n.t("Purchase Request No.")}
              required
              value={this.state.prNo}
              handler={this.handler}
              error={this.renderError("PurchaseReq.prNo")}
            />
          </div>

          <div className="col-md-3">
            <DatePickerCustom
              name={"date"}
              title={i18n.t("Date")}
              required={false}
              selected={this.state.date}
              onChange={async(e) =>{
                await this.handlerDateTime(e, "date")
                await this.changeData("salesOrderList", 'getSalesOrder', "");
              }}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm"
              error={this.renderError("PurchaseReq.date")}
            />
          </div>
          <div className="col-md-3">
            <DatePickerCustom
              name={"deliveryDate"}
              title={i18n.t("Delivery Date")}
              required={false}
              selected={this.state.deliveryDate}
              onChange={async (e) => {
                await this.handlerDateTime(e, "deliveryDate");
                await this.changeData("prNo", "getCode", "code");
              }}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm"
              error={this.renderError("PurchaseReq.deliveryDate")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              onChange={async (e) => {
                await this.resetDetail();
                await this.handlerSelect(e, "idBranch", "branchValue");
                await this.changeData("prNo", "getCode", "code");
                await this.changeData("storeList", "getStore", "");
                await this.changeData("salesOrderList", 'getSalesOrder', "");
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchValue}
              placeholder={this.state.branchText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReq.idBranch")}
            </div>
          </div>
          <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idStore"}
              onChange={(e) => this.handlerSelect(e, "idStore", "storeValue")}
              options={this.state.storeList}
              title={i18n.t("Store")}
              value={this.state.storeValue}
              placeholder={this.state.storeText}
            />
          </div>
          <div className="col-md-3">
          <Select2
              isClearable={true}
              name={"typeItem"}
              required={true}
              onChange={async (e) =>{
                await this.resetDetail();
                await this.handlerSelect(e, "idItemType", "typeItemValue");
                await this.changeData("itemList","getItem","item");
              }  
              }
              options={this.state.typeItemList}
              title={i18n.t("Type Item")}
              value={this.state.typeItemValue}
              placeholder={this.state.typeItemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReq.idItemType")}
            </div>
          </div>
          <div className="col-3">
              <Select2
                isClearable
                name={"idSalesOrder"}
                onChange={async (e) => {
                  if(e != null){
                    await this.handlerSelect(e, "idSalesOrder", "salesOrderCurrent");
                    await this.changeData("detailSalesOrder", "getDetailSalesOrder","",null,null,
                                          async(e)=>{await this.fetchSalesOrder()});
                  }else{
                    // await this.handlerSelect(e, "idSalesOrder", "salesOrderCurrent");
                    // await this.handlerSelect([], "idSalesOrder","salesOrderCurrent")
                    await this.resetDetail();
                  }
                 

                }}
                options={this.state.salesOrderList}
                title={i18n.t("Sales Order")}
                value={this.state.salesOrderCurrent}
                placeholder={this.state.salesOrderText}
              />
               <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError("DeliveryOrder.idSalesOrder")}
               </div>
            </div>
          {/* <div className="col-md-3">
          <Radio
              list={{
                'sell':i18n.t('Item Sell'),
                'inventory':i18n.t('Inventory'),
                'asset':i18n.t('Asset')
              }}
              name={"type"}
              title={i18n.t("Type")}
              inline={false}
              value={this.state.type}
              handler={(e)=>{
                  if(e.target.value != this.state.type){
                    this.setState({type:e.target.value,arrayUnit:[],itemList:[]},async()=>{
                      await this.changeData("itemList", "getItem", "item");
                      this.addArray(
                        {
                          idItem: null,
                          idUnit: null,
                          qty: 0,
                          description: "",
                          itemValue: null,
                          unitValue: null,
                          disabled: false,
                        },
                        "arrayUnit"
                      )
                    })
                  }
                }
              }
                
          />
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-8">
            <Input
              type={"input"}
              name={"description"}
              title={i18n.t("Description")}
              value={this.state.description}
              handler={this.handler}
            />
          </div>
        </div>
        <br />
        <hr />
        <div className="row">
          <div className="col-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th className="text-left" width="15%" >{i18n.t("Type Item")}</th> */}
                  <th className="text-left" width="25%" >{i18n.t("Item")}</th>
                  <th className="text-left" width="15%">{i18n.t("Qty")}</th>
                  <th className="text-left" width="15%">{i18n.t("Unit")}</th>
                  <th className="text-left" width="25%"> {i18n.t("Description")}</th>
                  <th className="text-left" width="5%">{i18n.t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.arrayUnit.map((detail, index) => {
                  return this.renderDetail(detail, index);
                })}
              </tbody>
            </Table>
            <Button
              variant="success"
              onClick={(e) =>
                this.addArray(
                  {
                    idItem: null,
                    idUnit: null,
                    qty: 0,
                    itemList:[],
                    description: "",
                    itemValue: null,
                    typeItemValue:null,
                    unitValue: null,
                    disabled: false,
                  },
                  "arrayUnit"
                )
              }
            >
              {i18n.t("Add Details")}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import BalanceSheet from '../../../../components/modules/report/balance-sheet';
import BalanceSheetMiddleware from '../../../../middleware/modules/report/balance-sheet';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,balanceSheet }) => ({
  isProcessing: balanceSheet.isProcessing,
  isError: balanceSheet.isError,
  errorMessage: balanceSheet.errorMessage,
  dataList:balanceSheet.data,
  showModal:balanceSheet.showModal,
  helperList:balanceSheet.helperBalanceSheet
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>BalanceSheetMiddleware.getHelper(),
    reset: () =>BalanceSheetMiddleware.resetList(),
    setData: (data) =>BalanceSheetMiddleware.list(data),
    excel: (data) =>BalanceSheetMiddleware.showExcel(data),
  }, dispatch);
};
class BalanceSheetContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Balance Sheet Report")}
          </title>
        </Helmet>
      <BalanceSheet
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetContainer);

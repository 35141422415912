import React,{ Component, Fragment } from 'react';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import TextArea from '../../../utilities/Form/TextArea';
import cookieManager from '../../../../utils/cookieManager';
const token = cookieManager.getUserToken();

export class FormSupplier extends Component {
    constructor(props) {
        super(props)
        this.state={
            selectProvince:React.createRef(),
            form:{
                idQuote:null,
                name:'',
                code:'',
                address:'',
                idProvince:null,
                idCity:null,
                phone:'',
                email:'',
                idNumber:'',
                taxStatus:0,
                npwp:'',
                contactPerson:'',
                contactPersonPhone:'',
                top:'',
                invoiceLimit:'',
                creditLimit:'',
                status:1,
                isExist:0,
            },
            provinceCurrent:null,
            provinceList:[],
            provinceText:i18n.t('Select Province'),
            supplierCurrent:null,
            supplierList:[],
            supplierText:i18n.t('Select Supplier'),
            cityList:[],
            cityCurrent:null,
            cityText:i18n.t('Select City'),
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    getCity(){
      let id=(this.state.provinceCurrent!=null)?this.state.provinceCurrent.value:null;
      if(id !=null){
        axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: process.env.REST_URL + "helper/get-city",
          responseType: "json",
          params:{
            id:id
          }
        })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              this.setState({cityList:response.data.result})
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      
    }

    render(){
        const {data,quote}=this.props 
        this.state.provinceList=((data !=null)?data.province:[])
        this.state.supplierList=((data !=null)?data.supplier:[])
        this.state.form.code=data.code 
        this.state.form.name=quote.name 
        this.state.form.idQuote=quote.id
        return (
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <Radio
                            list={{
                                0: i18n.t("New Supplier"),
                                1: i18n.t("Existing Supplier"),
                              }}
                            name={"isExist"}
                            title={i18n.t("")}
                            inline={false}
                            value={this.state.form.isExist}
                            handler={this.handler}
                            error={this.renderError('')}
                        />
                    </div>
                </div>
                {(this.state.form.isExist==0)?<Fragment>
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            disabled={true}
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <TextArea
                            required={true}
                            name={"address"}
                            title={i18n.t("Address")}
                            value={this.state.form.address}
                            handler={this.handler}
                            error={this.renderError('address')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Select2 
                            isClearable={true}
                            name={'idProvince'}
                            required={true}
                            onChange={async(e)=>{
                              if(e != this.state.provinceCurrent){
                               await this.handlerSelect(e,"idProvince","provinceCurrent")
                               await this.getCity();
                              }
                             
                            }}
                            options={this.state.provinceList}
                            title={i18n.t("Province")}
                            value={this.state.provinceCurrent}
                            placeholder={this.state.provinceText}
                            error={this.renderError('idProvince')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Select2 
                            isClearable={true}
                            name={'idCity'}
                            required={true}
                            onChange={(e)=>this.handlerSelect(e,"idCity","cityCurrent")}
                            options={this.state.cityList}
                            title={i18n.t("City")}
                            value={this.state.cityCurrent}
                            placeholder={this.state.cityText}
                            error={this.renderError('idCity')}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"phone"}
                            title={i18n.t("Phone")}
                            value={this.state.form.phone}
                            handler={this.handler}
                            error={this.renderError('phone')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"idNumber"}
                            title={i18n.t("ID Number")}
                            value={this.state.form.idNumber}
                            handler={this.handler}
                            error={this.renderError('idNumber')}
                        />
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"top"}
                            title={i18n.t("TOP")}
                            required={true}
                            value={this.state.form.top}
                            handler={this.handler}
                            error={this.renderError('top')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"email"}
                            name={"email"}
                            title={i18n.t("Email")}
                            required={true}
                            value={this.state.form.email}
                            handler={this.handler}
                            error={this.renderError('email')}
                        />
                    </div>
                </div>
                
               
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"contactPerson"}
                            title={i18n.t("Contact Person")}
                            value={this.state.form.contactPerson}
                            handler={this.handler}
                            error={this.renderError('contactPerson')}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            type={"input"}
                            name={"contactPersonPhone"}
                            title={i18n.t("Contact Person Phone")}
                            value={this.state.form.contactPersonPhone}
                            handler={this.handler}
                            error={this.renderError('contactPersonPhone')}
                        />
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-md-6'>
                        <Radio
                            list={{1:'PKP',0:'NON PKP'}}
                            name={"taxStatus"}
                            title={i18n.t("Tax Status")}
                            inline={false}
                            value={this.state.form.taxStatus}
                            handler={this.handler}
                            error={this.renderError('taxStatus')}
                        />
                    </div>
                    <div className='col-md-6'>
                            <Input
                                type={"input"}
                                name={"npwp"}
                                title={i18n.t("NPWP")}
                                value={this.state.form.npwp}
                                handler={this.handler}
                                error={this.renderError('npwp')}
                           />
                    </div>
                </div>
                
                
                <div className='row'>
                    <div className='col-md-12'>
                        <Radio
                            list={StatusForm}
                            name={"status"}
                            title={i18n.t("Status")}
                            inline={false}
                            value={this.state.form.status}
                            handler={this.handler}
                            error={this.renderError('status')}
                        />
                    </div>
                </div></Fragment>:<Fragment>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Select2 
                                isClearable={true}
                                name={'idSupplier'}
                                required={true}
                                onChange={async(e)=>{
                                if(e != this.state.supplierCurrent){
                                await this.handlerSelect(e,"idSupplier","supplierCurrent")
                                await this.getCity();
                                }
                                
                                }}
                                options={this.state.supplierList}
                                title={i18n.t("Supplier")}
                                value={this.state.supplierCurrent}
                                placeholder={this.state.supplierText}
                                error={this.renderError('idSupplier')}
                            />
                        </div>
                    </div>
                </Fragment>}
            </form>
        );
    }
}

export default FormSupplier;
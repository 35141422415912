import React, { Component, Fragment } from "react";
import Table from "./table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Form from "./form";
import i18n from "../../../../i18n";
import FormAll from "./form-all";

const MySwal = withReactContent(Swal);
export class MutationAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 10,
      },
      titleModal: "",
      listDataItem: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Mutation"), class: " text-gray-500" },
      ],
      columns: [
        {
          key: "date",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        // {
        //   key: "bandNo",
        //   filter: { active: true, type: "Text", data: null },
        //   title: i18n.t("Band No"),
        //   type: "text",
        //   tHclass: "",
        //   tdClass: "",
        // },
        // {
        //   key: "batchNo",
        //   filter: { active: true, type: "Text", data: null },
        //   title: i18n.t("Batch No"),
        //   type: "text",
        //   tHclass: "",
        //   tdClass: "",
        // },
        // {
        //   key: "expDate",
        //   filter: { active: true, type: "Date", data: null },
        //   title: i18n.t("Expired Date"),
        //   type: "text",
        //   tHclass: "",
        //   tdClass: "",
        // },
        // {
        //   key: "beginningStock",
        //   filter: { active: false, type: "Text", data: null },
        //   title: i18n.t("Beginning Stock"),
        //   type: "text",
        //   tHclass: "",
        //   tdClass: "",
        // },
        // {
        //   key: "beginningBalance",
        //   filter: { active: false, type: "Text", data: null },
        //   title: i18n.t("Beginning Balance"),
        //   type: "text",
        //   tHclass: "",
        //   tdClass: "",
        // },
        {
          key: "qtyIn",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Qty In"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "totalIn",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Balance In"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "qtyOut",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Qty Out"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "totalOut",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Balance Out"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "endingStock",
          filter: { active: false, type: "Text", data: null },
          title: "Ending Stock",
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "endingBalance",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Ending Balance"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "description",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Remark"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },

      ],
    };
  }
  renderConvert(dataConvert) {
    let convert = [];
    let hasil = '';
    for (var i = 0; i < dataConvert.length; i++) {
      hasil = hasil + dataConvert[i].total + '/';

    }
    convert.push(hasil);
    return convert;
  }

  renderTableItem() {
    const { isProcessingItem, dataItem, onChooseItem } = this.props;
    let html = [];
    let row = dataItem;
    let no = 1;
    if (dataItem != undefined && dataItem != null) {
      for (var i = 0; i < row.length; i++) {
        html.push(
          <Fragment>
            <tr key={i} className="border">
              <td style={{ textAlign: 'center' }}>{no++}.</td>
              <td style={{ textAlign: 'left' }}>{row[i].name_item}</td>
              <td style={{ textAlign: 'right' }}>{row[i].stock_awal_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].pembelian_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].total_stock_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].penjualan_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].wh_in_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].wh_out_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].stock_akhir_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].stock_cover_format}</td>
              <td style={{ textAlign: 'right' }}>{row[i].transit_format}</td>
            </tr>
          </Fragment>
        );
      }
    } else {
      html.push(
        <tr>
          <td colSpan={11} style={{ textAlign: 'center' }}> No. Data </td>
        </tr>)
    }
    return html;
  }
  render() {
    const { isProcessingItem, dataItem, onChooseItem,isError } = this.props;
    return (
      <div id="invoice-page">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                <FormAll {...this.props}
                  dataItem={dataItem} />
                {isProcessingItem && isError == false ? <div className="text-center">
                  <div className="spinner-border  text-primary text-center  clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div> : null}
              </div>
            </div>
            {/* {this.state.show == 0 ? null :
              <Fragment>
                <table className="border table table-responsive  ">
                  <thead>
                    <tr className="border  text-center" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      <th width={"3%"} className="text-center">{i18n.t("No.")}</th>
                      <th width={"15%"}>{i18n.t("Item Name")}</th>
                      <th width={"8%"}>{i18n.t("Stock Awal")}</th>
                      <th width={"8%"}>{i18n.t("Pembelian")}</th>
                      <th width={"8%"}>{i18n.t("Total Stock")}</th>
                      <th width={"8%"}>{i18n.t("Penjualan")}</th>
                      <th colSpan={'2'} width={"8%"}>{i18n.t("Warehouse")}</th>
                      <th width={"8%"}>{i18n.t("Stock Akhir")}</th>
                      <th width={"8%"}>{i18n.t("Stock Cover")}</th>
                      <th width={"8%"}>{i18n.t("Dalam Perjalanan")}</th>
                    </tr>
                    <tr className="border  text-center" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      <th width={"3%"} className="text-center"></th>
                      <th width={"15%"}></th>
                      <th width={"8%"}></th>
                      <th width={"8%"}></th>
                      <th width={"8%"}></th>
                      <th width={"8%"}></th>
                      <th width={"8%"}>{i18n.t("In")}</th>
                      <th width={"8%"}>{i18n.t("Out")}</th>
                      <th width={"8%"}></th>
                      <th width={"8%"}></th>
                      <th width={"8%"}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderTableItem()}
                  </tbody>
                </table>
              </Fragment>} */}
            {/* {onChooseItem ? this.renderTableItem() : ""} */}

          </div>
        </div>
      </div>
    );
  }
}

export default MutationAll;

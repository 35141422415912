import PurchaseReturnActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperPR:null,
  errorInternal:false,
  helperPRFull:null,
  printPr:null
};

function PurchaseReturnReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PurchaseReturnActions.RESET_LIST:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false,data:{}};
    case PurchaseReturnActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case PurchaseReturnActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case PurchaseReturnActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PurchaseReturnActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseReturnActions.CANCEL_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseReturnActions.ON_SUBMIT:
      return { ...state,errorInternal:false,  onSubmit:true};
    case PurchaseReturnActions.SHOW_DATA:
      return { ...state,errorInternal:false, onSubmit:false,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PurchaseReturnActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PurchaseReturnActions.PROCESS_DATA:
      return { ...state, isProcessing: true,onSubmit:false, isError: false,isSuccess:false};
    case PurchaseReturnActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PurchaseReturnActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case PurchaseReturnActions.GET_HELPER_PR:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,helperPR:action.payload};
    case PurchaseReturnActions.GET_HELPER_PRFULL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,helperPRFull:action.payload};
    case PurchaseReturnActions.BEFORE_PRINT_PR:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printPr:null};
    case PurchaseReturnActions.PRINT_PR:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printPr:action.payload};
    default:
      return state;
    }
}

export default PurchaseReturnReducer;

import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import InputNumber from "../../../utilities/Form/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import Radio from "../../../utilities/Form/radio";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      type: "",
      prNo: "",
      date: new Date(),
      deliveryDate: new Date(),
      description: "",
      status: 1,
      arrayUnit: [
        {
          idItem: null,
          idUnit: null,
          qty: 1,
          price:0,
          totalPrice:0,
          description: "",
          itemValue: null,
          unitValue: null,
          disabled: false,
        },
      ],
      unitList: [],
      unitText: i18n.t("Select Unit"),
      supplierText: i18n.t("Select / Create The Supplier"),
      itemList: [],
      requestList: [],
      supplierList: [],
      newSupplier: [],
      supplierValue: null,
      requestValue: null,
      idRequest: null,
      itemText: i18n.t("Select Item"),
      requestText: i18n.t("Select Request"),
      branchList: [],
      branchValue: null,
      storeValue: [],
      branchText: i18n.t("Select Branch"),
      storeText: i18n.t("Select Store"),
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let deliveryDate = moment(this.state.deliveryDate).format("YYYY-MM-DD");
    const Quotation = {
      quoteNo: this.state.quoteNo,
      date: date,
      idRequest:
        this.state.requestValue != null ? this.state.requestValue.value : "",
      idSupplier:
        this.state.supplierValue != null
          ? parseFloat(this.state.supplierValue.value) || null
          : "",
      supplierName:
        this.state.supplierValue != null ? this.state.supplierValue.label : "",
      description: this.state.description,
      idBranch:
        this.state.branchValue != null ? this.state.branchValue.value : "",
      idStore: this.state.storeValue != null ? this.state.storeValue.value : "",
      status: this.state.status,
    };
    const QuotationDetail = this.state.arrayUnit;
    let idItem = [];
    let qty = [];
    let idUnit = [];
    let price = [];
    let totalPrice = [];
    let description = [];
    let status = [];

    for (var i = 0; i < QuotationDetail.length; i++) {
      idItem.push((QuotationDetail[i].itemValue!=null?QuotationDetail[i].itemValue.value:null));
      description.push(
        QuotationDetail[i].description == ""
          ? ""
          : QuotationDetail[i].description
      );
      idUnit.push((QuotationDetail[i].itemValue!=null?QuotationDetail[i].itemValue.unitId:null));
      price.push(QuotationDetail[i].price);
      totalPrice.push(QuotationDetail[i].totalPrice);
      qty.push(QuotationDetail[i].itemValue.qty);
      status.push(1);
    }
    this.state.form.Quotation = Quotation;
    this.state.form.QuotationDetail = {
      idItem: idItem,
      description: description,
      idUnit: idUnit,
      price: price,
      totalPrice: totalPrice,
      qty: qty,
      status: status,
    };
    this.state.isUpdate = 0;
    return this.state.form;
  }
  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    // this.calculateRow(i,arrayname)
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  calculateRow(i,arrayname){
    const arr = [...this.state[arrayname]];
    const objectss = arr[i]
    let price= parseFloat(this.state.arrayUnit[i].price)||0
    let qty=parseFloat(this.state.arrayUnit[i].qty)||0
    let totalPrice=price * qty
    objectss["totalPrice"] =totalPrice.toFixed(2)
    this.setState(arr)
    
    // this.calculateAll()
}

  renderDetail(detail, i) {
    return (
      <tr key={"detail" + i}>
        <td>
          <Select
            isClearable={true}
            name={"idItem"}
            required={true}
            onChange={(e) =>{
                this.handlerSelectArray(e, "idItem", i, "arrayUnit", "itemValue")
                if(e != null){
                  this.handlerCurrencyArray(e.qty, "qty", i, "arrayUnit")
                }
                
              } 
            }
            options={this.state.itemList}
            title={i18n.t("Item")}
            value={detail.itemValue}
            placeholder={this.state.itemText}
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {this.renderError("QuotationDetail.idItem." + i)}
          </div>
        </td>
        <td>
            <InputNumber
              id={"qty" + i}
              name="qty"
              placeholder={i18n.t("Qty")}
              defaultValue={0}
              value={detail.qty}
              decimalsLimit={2}
              onValueChange={async(value, name) =>
                {
                  await this.handlerCurrencyArray(value, "qty", i, "arrayUnit")
                  await this.calculateRow( i, "arrayUnit");
                }
              }
              className="form-control  form-control-sm text-end"
              error={this.renderError("QuotationDetail.qty." + i)}
              labelfield={"false"}
              disabled
            />
          </td>
        {/* <td>
          <Select
            name={"idUnit"}
            required={true}
            onChange={(e) =>
              this.handlerSelectArray(e, "idUnit", i, "arrayUnit", "unitValue")
            }
            options={this.state.unitList}
            title={i18n.t("Unit")}
            value={detail.unitValue}
            placeholder={this.state.unitText}
          />
          <div className="fv-plugins-message-container invalid-feedback">
            {this.renderError("QuotationDetail.idUnit." + i)}
          </div>
        </td> */}
        <td>
          <InputNumber
            id={"price" + i}
            name="price"
            placeholder={i18n.t("Price")}
            defaultValue={0}
            value={detail.price}
            decimalsLimit={2}
            onValueChange={async (value, name) =>
              {
                await this.handlerCurrencyArray(value, "price", i, "arrayUnit")
                await this.calculateRow( i, "arrayUnit");
              }
             
            }
            className="form-control  form-control-sm text-end"
            error={this.renderError("QuotationDetail.price." + i)}
            labelfield={"false"}
          />
        </td>
        <td>
          <InputNumber
            id={"price" + i}
            name="price"
            placeholder={i18n.t("Total")}
            defaultValue={0}
            value={detail.totalPrice}
            decimalsLimit={2}
            onValueChange={(value, name) =>{}
            }
            className="form-control  form-control-sm text-end"
            error={this.renderError("QuotationDetail.price." + i)}
            labelfield={"false"}
            disabled
          />
        </td>
        <td>
          <input
            className="form-control  form-control-sm "
            type={"input"}
            name={"description"}
            id={"description" + i}
            value={detail.description}
            onChange={(e) =>
              this.handlerInputArray(e, "description", i, "arrayUnit")
            }
          />
        </td>
        <td>
          <Button
            variant="danger"
            className="btn btn-sm"
            onClick={(e) => this.removeArray(i, "arrayUnit")}
          >
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </Button>
        </td>
      </tr>
    );
  }
  changeData(keyFill, type = null, key, array = null, arraykey = null) {
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-quotation-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        var store =
          this.state.storeValue != undefined
            ? this.state.storeValue.value
            : null;
        params = {
          date: date,
          branch: branch,
          store: store,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getPurchase":
        var url = process.env.REST_URL + "helper/get-quotation-request";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        var store =
          this.state.storeValue != undefined
            ? this.state.storeValue.value
            : null;
        params = {
          date: date,
          branch: branch,
          store: store,
        };
        break;
      case "getItem":
        var url = process.env.REST_URL + "helper/get-quotation-item";
        params = {
          requestId: this.state.idRequest,
        };
        break;
    }
    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  listSupplier() {
    const { quotationRequestHelper } = this.props;
    var supplierList =
      quotationRequestHelper != null ? quotationRequestHelper.supplier : [];
    return supplierList.concat(this.state.newSupplier);
  }

  render() {
    const { data, isNewRecord, quotationRequestHelper } = this.props;

    this.state.unitList =
      quotationRequestHelper != null ? quotationRequestHelper.unit : [];
    this.state.branchList =
      quotationRequestHelper != null ? quotationRequestHelper.branch : [];
    this.state.supplierList =
      quotationRequestHelper != null ? quotationRequestHelper.supplier : [];
    this.state.supplierList = this.state.supplierList.concat(
      this.state.newSupplier
    );
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.quoteNo = data.quote_no),
          (this.state.date = new Date(data.date)),
          (this.state.description = data.description),
          (this.state.status = data.status),
          (this.state.storeValue =
            data.id_store != null
              ? { value: data.id_store, label: data.name_store }
              : null);
              this.state.idRequest= data.pr_id
        this.state.requestValue =
          data.pr_id != null ? { value: data.pr_id, label: data.pr_no } : null;
        this.state.supplierValue =
          ((data.id_supplier == null &&
          data.name_supplier != null &&
          data.name_supplier != "")
            ? {
                value:
                 ((data.id_supplier != null)
                    ? data.id_supplier
                    : data.name_supplier),
                label: data.name_supplier,
              }
            : {
              value:
               ((data.id_supplier != null)
                  ? data.id_supplier
                  : data.name_supplier),
              label: data.name_supplier,
            });
        if (
          data.id_supplier == null &&
          data.name_supplier != null &&
          data.name_supplier != ""
        ) {
          this.state.newSupplier = [this.state.supplierValue];
        }

        this.state.branchValue = {
          value: data.id_branch,
          label: data.name_branch,
        };

        this.state.arrayUnit = [];
        for (var po = 0; po < data.quotation_detail.length; po++) {
          this.state.arrayUnit.push({
            idItem: data.quotation_detail[po].id_item,
            description:
              data.quotation_detail[po].description == null
                ? ""
                : data.quotation_detail[po].description,
            // idUnit: data.quotation_detail[po].id_unit,
            qty:
              data.quotation_detail[po].qty == null
                ? 1
                : parseFloat(data.quotation_detail[po].qty) || 1,
            price:
              data.quotation_detail[po].price == null
                ? 0
                : parseFloat(data.quotation_detail[po].price) || 0,
              totalPrice:
                data.quotation_detail[po].totalPrice == null
                  ? 0
                  : parseFloat(data.quotation_detail[po].totalPrice) || 0,
            itemValue: {
              value:
                data.quotation_detail[po].label_item == null
                  ? null
                  : data.quotation_detail[po].label_item,
              label: data.quotation_detail[po].label_item,
              helper: data.quotation_detail[po].id_item,
              helper2: data.quotation_detail[po].id_unit,
            },
            unitValue: {
              value:
                data.quotation_detail[po].id_unit == null
                  ? ""
                  : data.quotation_detail[po].id_unit,
              label: data.quotation_detail[po].name_unit,
            },
          });
        }
        this.changeData("storeList", "getStore", "");
        this.changeData("requestList", "getPurchase", "");
        this.changeData("itemList", "getItem", "");
        this.state.isUpdate = 1;
      }
     
    }
    return (
      <form>
        <div className="row">
          <div className="col-md-3">
            <Input
              disabled={true}
              type={"input"}
              name={"quoteNo"}
              title={i18n.t("Quote No.")}
              required
              value={this.state.quoteNo}
              handler={this.handler}
              error={this.renderError("Quotation.quoteNo")}
            />
          </div>

          <div className="col-md-3">
            <DatePickerCustom
              name={"date"}
              title={i18n.t("Date")}
              required={false}
              selected={this.state.date}
              onChange={async (e) => {
                if (e != this.state.date) {
                  await this.handlerDateTime(e, "date");
                  await this.setState(
                    {
                      arrayUnit: [],
                      requestList: [],
                    },
                    () => {
                      this.addArray(
                        {
                          idItem: null,
                          idUnit: null,
                          unitList: [],
                          qty: 1,
                          price: 0,
                          totalPrice: 0,
                          description: "",
                          itemValue: null,
                          unitValue: null,
                          disabled: false,
                        },
                        "arrayUnit"
                      );
                    }
                  );
                  await this.changeData("requestList", "getPurchase", "");
                }
              }}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm"
              error={this.renderError("PurchaseReq.date")}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">{i18n.t("Supplier Name")}</label>
            <CreatableSelect
              isClearable
              options={this.state.supplierList}
              onChange={(newValue) =>
                this.setState(
                  {
                    supplierValue: newValue,
                  },
                  () => {
                    var p = this.state.supplierList.filter(
                      (obj) => obj == newValue
                    );
                    if (p.length == 0) {
                      this.setState({
                        newSupplier: [...this.state.newSupplier, newValue],
                      });
                    }
                  }
                )
              }
              value={this.state.supplierValue}
              placeholder={this.state.supplierText}
            />
            <div className="badge badge-light-info">{i18n.t("you can create the temporary supplier with type the name supplier")}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              onChange={async (e) => {
                if (e != this.state.branchValue) {
                  await this.handlerSelect(e, "idBranch", "branchValue");
                  await this.setState(
                    {
                      arrayUnit: [],
                      requestList: [],
                    },
                    () => {
                      this.addArray(
                        {
                          idItem: null,
                          idUnit: null,
                          unitList: [],
                          qty: 1,
                          price: 0,
                          totalPrice: 0,
                          description: "",
                          itemValue: null,
                          unitValue: null,
                          disabled: false,
                        },
                        "arrayUnit"
                      );
                    }
                  );
                  await this.changeData("quoteNo", "getCode", "code");
                  await this.changeData("storeList", "getStore", "");
                  await this.changeData("requestList", "getPurchase", "");
                }
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchValue}
              placeholder={this.state.branchText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("PurchaseReq.idBranch")}
            </div>
          </div>
          <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idStore"}
              onChange={async (e) => {
                if (e != this.state.storeValue) {
                  this.handlerSelect(e, "idStore", "storeValue");
                  await this.setState(
                    {
                      arrayUnit: [],
                      requestList: [],
                    },
                    () => {
                      this.addArray(
                        {
                          idItem: null,
                          idUnit: null,
                          unitList: [],
                          qty: 1,
                          price: 0,
                          totalPrice: 0,
                          description: "",
                          itemValue: null,
                          unitValue: null,
                          disabled: false,
                        },
                        "arrayUnit"
                      );
                    }
                  );
                  await this.changeData("quoteNo", "getCode", "code");
                  await this.changeData("requestList", "getPurchase", "");
                }
              }}
              options={this.state.storeList}
              title={i18n.t("Store")}
              value={this.state.storeValue}
              placeholder={this.state.storeText}
            />
           
          </div>
          <div className="col-md-3">
            <Select2
              isClearable={true}
              name={"idRequest"}
              onChange={async (e) => {
                await this.handlerSelect(e, "idRequest", "requestValue");
                await this.changeData("itemList", "getItem", "");
              }}
              options={this.state.requestList}
              title={i18n.t("Request List")}
              value={this.state.requestValue}
              placeholder={this.state.requestText}
            />
          </div>
        </div>
        <Input
          type={"input"}
          name={"description"}
          title={i18n.t("Description")}
          value={this.state.description}
          handler={this.handler}
        />
        <br />
        <hr />
        <div className="row">
          <div className="col-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="text-left">{i18n.t("Item")}</th>
                  <th className="text-left">{i18n.t("Qty")}</th>
                  {/* <th className="text-left">{i18n.t("Unit")}</th> */}
                  <th className="text-left">{i18n.t("Price")}</th>
                  <th className="text-left">{i18n.t("Total")}</th>
                  <th className="text-left">{i18n.t("Description")}</th>
                  <th className="text-left">{i18n.t("Action")}</th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.arrayUnit.map((detail, index) => {
                  return this.renderDetail(detail, index);
                })}
              </tbody>
            </Table>
            <Button
              variant="success"
              onClick={(e) =>
                this.addArray(
                  {
                    idItem: null,
                    idUnit: null,
                    qty: 1,
                    price:0,
                    totalPrice: 0,
                    description: "",
                    itemValue: null,
                    unitValue: null,
                    disabled: false,
                  },
                  "arrayUnit"
                )
              }
            >
              {i18n.t("Add Details")}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import DownPaymentMiddleware from "../../../../middleware/modules/accounting/down-payment";
import DownPayment from "../../../../components/modules/accounting/down-payment";
// middlewares

const mapStateToProps = ({ auth, downPayment }) => ({
  isProcessing: downPayment.isProcessing,
  isError: downPayment.isError,
  errorMessage: downPayment.errorMessage,
  datalist: downPayment.data,
  helperDownPayment: downPayment.helperDownPayment,
  afterSave: downPayment.afterSave,
  showModal: downPayment.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => DownPaymentMiddleware.create(),
      getHelper: () => DownPaymentMiddleware.getHelper(),
      reset: () => DownPaymentMiddleware.resetList(),
      search: (data) => DownPaymentMiddleware.list(data),
      update: (data) => DownPaymentMiddleware.update(data),
      void: (data,search) => DownPaymentMiddleware.beforeVoid(data,search),
      delete: (id, search) => DownPaymentMiddleware.beforeDelete(id, search),
      submit: (data, search) => DownPaymentMiddleware.beforeSaveNew(data, search),
    },
    dispatch
  );
};
class DownPaymentContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,

  };

  static defaultProps = {
    afterSave: false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.submitItem = this.submitItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.voidItem = this.voidItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  createItem() {
    this.props.getHelper();
    this.props.create();
  }

  submitItem() {
    this.props.submit();
  }

  voidItem(data,search) {
    this.props.void(data,search);
  }

  render() {
    const { isProcessing, search, datalist, showModal } =
      this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Down Payment List")}</title>
        </Helmet>
        <DownPayment
          {...this.props}
          createData={this.createItem}
          voidItem={this.voidItem}
          showModal={showModal}
          // helperDownPayment={helperDownPayment}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownPaymentContainer);

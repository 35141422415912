import React from "react";
import {takingGoods} from "../Const/TranscationCode";
import i18n from "../../../i18n";

const LabelTakingGoods=(props)=>{
    switch(props.value){
        case 1:
            return <div className="badge badge-light-warning">{i18n.t("Waiting For Approval")}</div>
        case 2:
            return <div className="badge badge-light-success">{i18n.t("Active")}</div>
        case 0:
            return <div className="badge badge-light-danger">{i18n.t("Close")}</div>
        case 3:
            return <div className="badge badge-light-danger">{i18n.t("Rejected")}</div>
        case 4:
            return <div className="badge badge-light-info">{i18n.t("Done")}</div>
        case 5:
            return <div className="badge badge-light-info">{i18n.t("Return")}</div>
    }
    // const filter=takingGoods.filter((item)=>item.key===props.value);
    // if(filter.length==1){
    //     return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
    // }   
    // return ''
}
export default LabelTakingGoods;
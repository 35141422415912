import React, { Component } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import PhotoGallery from "../../../utilities/PhotoGallery";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <form>
        {data != null ? (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Table>
                  <thead></thead>
                  <tbody>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Date")}</td>
                      <td>:</td>
                      <td>{data.date_format ? data.date_format : "-"}</td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Document no.")}</td>
                      <td>:</td>
                      <td>{data.doNo ? data.doNo : "-"}</td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Receipt no.")}</td>
                      <td>:</td>
                      <td>{data.wi_no ? data.wi_no : "-"}</td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Origin Branch")}</td>
                      <td>:</td>
                      <td>{data.name_branch ? data.name_branch : "-"}</td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Destination Branch")}</td>
                      <td>:</td>
                      <td>
                        {data.name_branch_des ? data.name_branch_des : "-"}
                      </td>
                    </tr>
                    {/* <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Warehouse")}</td>
                  <td>:</td><td>{(data.type)?data.type:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Code")}</td>
                  <td>:</td><td>{(data.code_item)?data.code_item:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Item Name")}</td>
                  <td>:</td><td>{(data.name_item)?data.name_item:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Qty Received")}</td>
                  <td>:</td><td>{(data.qty_format)?data.qty_format:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Unit")}</td>
                  <td>:</td><td>{(data.name_unit)?data.name_unit:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Banderol")}</td>
                  <td>:</td><td>{(data.bandNo)?data.bandNo:'-'}</td></tr> */}
                    {data.image.length > 0 ? (
                      <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                        <td>{i18n.t("Image")}</td>
                        <td>:</td>
                        <td>
                          <PhotoGallery images={data.image}></PhotoGallery>
                        </td>
                      </tr>
                    ) : null}

                    {/* <Fragment>{data.image.map((item,i) => 
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Image")}</td>
                  <td>:</td><td><Image className="img-fluid" src={process.env.UPLOAD_URL+item.imageUrl}></Image></td></tr>
                  )}</Fragment> */}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row">
              <div className="d-flex flex-end">
                <div className="col-4"></div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </form>
    );
  }
}

export default Form;


import React from "react";
import { transactionWH} from "../Const/TranscationCode";

const LabelWarehouse=(props)=>{
    const filter=transactionWH.filter((item)=>item.key===props.value);
    if(filter.length==1){
        return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
    }   
    return ''
}
export default LabelWarehouse;
import AuthActions from './actions';

const INITIAL_STATE = {
  authUser: {},
  isAuthenticated: false,
  isProcessing: false,
  isRegistered: false,
  isError: false,
  errorMessage: [],
  isTrigger:false,
  tokenValid:false,
  successRequest:false,
  successNewPassword:false,
  isErrorNewPassword:false,
  errorMessageNew:[],
  onVerify:true
};

function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case AuthActions.BEFORE_SET_PASSWORD:
    return { ...state, isProcessing: true,errorMessage:[],isErrorNewPassword:false, isAuthenticated: false,successNewPassword:false, tokenValid: true, isError: false, successRequest:false,};
  case AuthActions.SUCCESS_SET_PASSWORD:
      return { ...state, isProcessing: false, isAuthenticated: false, tokenValid: true, isErrorNewPassword: false, successNewPassword:true,};
  case AuthActions.FAILED_SET_PASSWORD:
    return { ...state, isProcessing: false, isAuthenticated: false, tokenValid: true, isErrorNewPassword: true,errorMessage:action.payload, successNewPassword:false,};
  case AuthActions.RESET:
    return { ...state, isProcessing: false,errorMessage:[],isError:false, isAuthenticated: false,successNewPassword:false, tokenValid: false, isError: false, successRequest:false,};
  case AuthActions.BEFORE_REQUEST:
      return { ...state, isProcessing: true, isAuthenticated: false, tokenValid: false, isError: false, successRequest:false,};
  case AuthActions.REQUEST_SUCCESS:
    return { ...state, isProcessing: false, isAuthenticated: false, tokenValid: false, isError: false, successRequest:true,};
  case AuthActions.REQUEST_FAILED:
    return { ...state, isProcessing: false, isAuthenticated: false, tokenValid: false, isError: true, successRequest:false,errorMessage:action.payload};
  case AuthActions.BEFORE_VERIFICATION_TOKEN:
    return { ...state, isProcessing: true,onVerify:true,  isAuthenticated: false, tokenValid: false, isError: false,  isErrorNewPassword:false,successNewPassword:false };
  case AuthActions.VERIFICATION_TOKEN_SUCCESS:
    return { ...state, isProcessing: false, onVerify:false, tokenValid: true, isErrorNewPassword: false };
  case AuthActions.VERIFICATION_TOKEN_FAILED:
    return { ...state, isProcessing: false,onVerify:false, tokenValid: false, isErrorNewPassword: true,errorMessage:action.payload };
  case AuthActions.SIGNIN:
    return { ...state, isProcessing: true, isAuthenticated: false, isError: false };
  case AuthActions.SIGNIN_SUCCESSFUL:
    return { ...state,onVerify:false, isProcessing: false, isAuthenticated: true, isError: false, authUser: action.payload, errorMessage: [] };
  case AuthActions.SIGNIN_REJECTED:
    return { ...state,onVerify:false, isProcessing: false, isAuthenticated: false, authUser: {}, isError: true, errorMessage: action.payload };
  case AuthActions.LOGOUT:
    return { ...state, isProcessing: true,isTrigger:true };
  case AuthActions.LOGOUT_SUCCESSFUL:
    return { ...state,isTrigger:false, isProcessing: false, isAuthenticated: false, authUser: {} };
  case AuthActions.ISLOGGEDIN:
    return { ...state,onVerify:false, isProcessing: false, isAuthenticated: true, authUser: action.payload };
  case AuthActions.ISLOGGEDOUT:
    return { ...state,isTrigger:false, onVerify:false, isProcessing: false, isAuthenticated: false, authUser: {} };
  case AuthActions.ON_VERIFY:
    return { ...state,onVerify:action.payload };
  default:
    return state;
  }
}

export default AuthReducer;

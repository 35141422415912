import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import TakingGoods from "../../../../components/modules/warehouse/taking-goods";
// middlewares
import TakingGoodsMiddleware from "../../../../middleware/modules/warehouse/taking-goods";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";

const mapStateToProps = ({ auth, takingGoods }) => ({
  isProcessing: takingGoods.isProcessing,
  isError: takingGoods.isError,
  errorMessage: takingGoods.errorMessage,
  datalist: takingGoods.data,
  showModal: takingGoods.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      approve: (id, value, search) =>
      TakingGoodsMiddleware.beforeApprove(id, value, search),
      reject: (id, value, search) =>
      TakingGoodsMiddleware.beforeReject(id, value, search),
      create: (search) => TakingGoodsMiddleware.create(search),
      reset: () => TakingGoodsMiddleware.resetList(),
      search: (data) => TakingGoodsMiddleware.list(data),
      update: (data) => TakingGoodsMiddleware.update(data),
      return: (data) => TakingGoodsMiddleware.return(data),
      viewItem: (data) => TakingGoodsMiddleware.viewItem(data),
      getHelperFull: () => TakingGoodsMiddleware.getHelperFull(),
      delete: (id, search) =>
      TakingGoodsMiddleware.beforeDelete(id, search),
    },
    dispatch
  );
};

class TakingGoodsContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {},
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.returnItem = this.returnItem.bind(this);
    this.cancelItem = this.cancelItem.bind(this);
    this.viewItem = this.viewItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  cancelItem(id, value, search) {
    this.props.cancel(id, value, search);
  }
  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.update(id);
    this.props.getHelperFull();
  }
  returnItem(id) {
    this.props.return(id);
    this.props.getHelperFull();
  }
  viewItem(id) {
    this.props.viewItem(id);
    this.props.getHelperFull();

  }
  createItem(search = null) {
    this.props.create(search);
    this.props.getHelperFull();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Taking Goods List")}
          </title>
        </Helmet>
        <TakingGoods
          {...this.props} 
          data={datalist}
          createItem={this.createItem}
          returnItem={this.returnItem}
          updateItem={this.updateItem}
          deleteItem={this.deleteItem}
          cancelItem={this.cancelItem}
          viewItem={this.viewItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakingGoodsContainer);

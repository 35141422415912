import React, { Component, Fragment, useState } from "react";
import { addDays } from "date-fns";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Chart  from 'react-apexcharts'
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Radio from "../../../utilities/Form/radio";
import { SalesPerformance } from "../../../utilities/Const/StatusActive";
const MySwal = withReactContent(Swal);
const token=cookieManager.getUserToken()
export class SalesPerformanceChart extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      fillList:0,
      dataSeries : [],
      detail : [],
      range:[],
      year: new Date().getFullYear(),
      branchCurrent:null,
      branchList: [],
      branchText: i18n.t('All'),
      salesCurrent: null,
      salesList: [],
      salesText: i18n.t('All'),
      customerList: [],
      customerCurrent: null,
      customerText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      periodEndList : [],
      periodStartCurrent: null,
      periodEndCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      periodCurrentExcel: null,
      periodTextExcel: i18n.t("Select Period"),
      periodListExcel: [],
      loadingSales:false,
      loadingCustomer:false,
      page: 1,
      typeChart: 1,
      typeExcel: 2,
      show: 0,
      choose: {
        1: i18n.t("Quantity"),
        2: i18n.t("Revenue"),
      },
      typeDataChart:1,
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Account Payable"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handlerRadio(e) {
    console.log(e)
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value,
    })
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handlerSelectPeriod(e, name, currentState) {
    var key = name
    var value = null
    let list = [];
    let dataList = [];

    dataList =this.state.periodList;
    if(key == "periodStartCurrent" && e != null){
      var index = dataList.findIndex(item => item.value === e.value)
      for(var i = index; i < index+5;i++){
        list.push(dataList[i])
      }
      this.setState({periodEndList:list});

    }else{
      
    }
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  setData() {
    var startDate = this.state.typeChart == 1 ? moment(this.state.startDate).format("YYYY-MM-DD") : 
                    this.state.typeChart == 2 ? (this.state.periodStartCurrent == null?'':this.state.periodStartCurrent.value) :
                    this.state.type == 3 ? null:null;
    var endDate = this.state.typeChart == 1 ? moment(this.state.endDate).format("YYYY-MM-DD") : 
                  this.state.typeChart == 2 ? (this.state.periodEndCurrent == null?'':this.state.periodEndCurrent.value) :
                  this.state.typeChart == 3 ? null:null;
    var month = this.state.monthValue != null ? this.state.monthValue.value : '';
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var idSales = (this.state.salesCurrent == null) ? '' : this.state.salesCurrent.value;
    var type = this.state.typeChart;
    var typeData = this.state.typeDataChart;
    let params = {
      startDate: startDate,
      endDate: endDate,
      idBranch: idBranch,
      idSales: idSales,
      type: type,
      typeData:typeData,
      month:month
    }
    this.props.setData(params);
  }

  renderList() {
    const { helperList, data } = this.props
    let html = [];
    // this.state.listData = data;
    // let row = data.data
    // let html = [];
    // let no = 1;
    // let totalDebt = 0;
    // let totalCredit = 0;
    // if (data != undefined && data != null && row != null) {
    //   Swal.close();
    //   for (var p = 0; p < row.length; p++) {
    //     if (row[p].date != null) {
    //       html.push(
    //         <tr className="border" key={p}>
    //           <td className="text-center">{no++}</td>
    //           <td>{row[p].date}</td>
    //           <td>{row[p].supplier_name}</td>
    //           <td>
    //             <Link
    //               to={"/accounting/journal/detail/" + row[p].id_journal}
    //             >
    //               {row[p].no_journal}
    //             </Link>
    //           </td>
    //           <td>{row[p].description}</td>
    //           <td style={{ textAlign: 'right' }}>{row[p].debt_format}</td>
    //           <td style={{ textAlign: 'right' }}> {row[p].credit_format}</td>
    //           <td style={{ textAlign: 'right', paddingRight:'5px' }}> {(row[p].balance_format)}</td>

    //         </tr>)
    //     }
    //     totalDebt += parseFloat(row[p].debt) || 0;
    //     totalCredit += parseFloat(row[p].credit) || 0;

    //   }
    //   html.push(
    //     <tr className="border" style={{backgroundColor:'#DCDCDC'}}>
    //       <td colSpan={'5'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalDebt)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalCredit)
    //         .toFixed(2)
    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
    //       <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
    //     </tr>)

      return html;
    // }
  }

  alertHandler(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token=cookieManager.getUserToken()
    switch (type) {
        case "getEmployee":
          var url = process.env.REST_URL + "helper/get-employee";
          if(this.state.branchCurrent==null){
            type=null;
          }
          params = {
            branch: this.state.branchCurrent !=null?this.state.branchCurrent.value:null,
          };
          break;
     
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  renderDetail(detail,i){
    let total = 0;
    total += detail.nominal;
    return <React.Fragment key={"detail"+i}>
              <tr className="table-fill" style={{fontSize:'16px'}} >
                  <td align='center'>
                      {detail.date}
                  </td> 
                  <td align='right' style={{paddingRight:'5px'}}>
                      {detail.nominal_format}
                  </td>
              </tr>
        </React.Fragment>
  }

  renderChart(){
    const { isProcessing,datalist } = this.props;
    let total = 0;
    let count = 1;
    let average = 0;
    let str = '';
    if(datalist != null && datalist != undefined){
      this.state.dataSeries = datalist.Penjualan
      this.state.range = datalist.Tanggal
      this.state.detail = datalist.Detail

      str = (this.state.typeDataChart==1?i18n.t('Quantity (Pack)'):i18n.t('Revenue (Rp.)'));
      if(this.state.detail.length > 0){
        for(var i=0; i<this.state.detail.length;i++){
          total += parseFloat(this.state.detail[i].nominal)||0;
          count++;
        }

        average = Math.round(total / count);
      }
    }

    const options = {
      
      colors: ["#4682B4", "#228B22"],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5,5],
        curve: 'straight',
        dashArray: [0,0]
      },
      title: {
        text: 'Sales Performance',
        align: 'center',
        style:{
          fontSize:  '18px',
        }
      },
     
      // legend: {
      //   position: 'top'
      // },
      legend: {
        tooltipHoverFormatter: function(val, opts) {
          return val + ' - <strong>' + parseFloat(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] )
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + '</strong>'
        }
      },
      markers: {
        size: 6,
        colors : ['#999900','#006400'],
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        // title: {
          
        //   text: i18n.t('Date')
        // },
        categories: this.state.range != null?this.state.range:[],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseFloat(value)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
        },
        title: {
          text: i18n.t(this.state.typeDataChart==1?i18n.t('Quantity (Pack)'):i18n.t('Revenue (Rp.)'))
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + str
              }
            }
          },
          {
            title: {
              formatter: function (val) {
              
                return val + str  
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    };

    return (
      <div>
        <div id="chart">
          <Chart  options={options} series={this.state.dataSeries!=null?this.state.dataSeries:[]} type="line" height={550} />
        </div>
        <div id="html-dist"></div>
        <div style={{fontSize:'16px',fontWeight:'bold',textAlign:'center',marginTop:'5px'}}>
          {i18n.t("Detail")}
        </div>
        <div className="row">
        <Fragment>
          <div>
            <br />
            <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5' ,marginLeft:'400px',width:'50%'}}>
              <thead>
                <tr className="table-header">
                  <th width={"50%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Date")}</th>
                  <th width={"50%"} style={{ textAlign: 'center', color: 'white' }}>{this.state.typeDataChart == 1?i18n.t("Nominal (Qty)"):i18n.t("Nominal (Rp.)")}</th>
                </tr>
              </thead>
              <tbody key={"body"}>
                  {this.state.detail.length > 0?
                    this.state.detail.map((item,index)=>{
                    return  this.renderDetail(item,index);
                }):null}
              </tbody>
              <tfoot>
                <tr className="table-header">
                  <th style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Total Selling")}</th>
                  <th style={{ color: 'white',textAlign:'right', paddingRight:'5px'  }}>{parseFloat(total)
                      .toFixed(0)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</th>
                </tr>
                <tr className="table-header">
                  <th cstyle={{ textAlign: 'center', color: 'white' }}>{i18n.t("Average Selling")}</th>
                  <th  style={{ color: 'white',textAlign:'right', paddingRight:'5px' }}>{parseFloat(average)
                      .toFixed(0)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Fragment>
        </div>
      </div>
    );
    // const domContainer = document.querySelector('#app');
    // ReactDOM.render(React.createElement(ApexChart), domContainer);
}


  render() {
    const { isProcessing,helperSalesChart,datalist } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    if(helperSalesChart!= null && this.state.fillList == 0){
      this.state.branchList = helperSalesChart.branch;
      this.state.periodList = helperSalesChart.workCalendar;
      this.state.fillList = 1;
    }
    return (
          <React.Fragment >
          <div className="row ">
            <div className="col-sm-2 mt-11">
              <Radio
                list={SalesPerformance}
                name={"typeChart"}
                title={""}
                labelfield={"false"}
                inline={false}
                value={this.state.typeChart}
                handler={this.handlerRadio}
              />
            </div>
        
          {this.state.typeChart == 2 ?
          <Fragment>
          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"period"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
              }}
              options={this.state.periodList}
              title={i18n.t("Week Period")}
              value={this.state.periodStartCurrent}
              placeholder={this.state.periodText}
            />
          </div>
          <div style={{width:'35px'}} className="col-sm-1 mt-11">
            {i18n.t("To")}
          </div>
          <div className="col-sm-2 mt-3">
          <Select2
              isClearable={true}
              name={"periodEnd"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
              }}
              options={this.state.periodEndList}
              title={i18n.t(" ")}
              value={this.state.periodEndCurrent}
              placeholder={this.state.periodText}
            />
          </div>
            </Fragment>
            : this.state.typeChart == 1 ?
            <Fragment>
              <div className="col-sm-2 mt-3">
                  <DatePickerCustom
                    name={"startDate"}
                    title={i18n.t("Date Period")}
                    selected={this.state.startDate}
                    onSelect={async (e) => {
                      await this.handlerDateTime(e, 'startDate');
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    onChange={(e) => { }}
                    startDay={0}
                    autoComplete={"off"}
                    dateFormat="dd MMMM yyyy"
                    className="form-control form-control-sm"
                    required

                  >
                  </DatePickerCustom>
                </div>
                <div style={{width:'35px'}} className="col-md-1 mt-11">
                  {i18n.t("To")}
                </div>
                <div className="col-sm-2 mt-11">
                  <DatePickerCustom
                    name={"endDate"}
                    title={i18n.t("")}
                    selected={this.state.endDate}
                    onSelect={async (e) => {
                      await this.handlerDateTime(e, 'endDate');
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    onChange={(e) => { }}
                    autoComplete={"off"}
                    dateFormat="dd MMMM yyyy"
                    className="form-control form-control-sm"
                    labelfield={"false"}
                    minDate = {this.state.startDate}
                    maxDate={addDays(new Date(this.state.startDate), 30)}
                    required
                  ></DatePickerCustom>
                </div>
            </Fragment>
          : this.state.typeChart == 3 ?
            <Fragment>
                <div className="col-sm-2 mt-3">
                  <Select2
                    isClearable={true}
                    name={"month"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "month", "monthValue");
                    }}
                    options={this.state.monthList}
                    title={i18n.t("Month")}
                    value={this.state.monthValue}
                    placeholder={this.state.monthText}
                    labelfield={"true"}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {this.renderError('month')}
                  </div>
                </div>
              </Fragment>
          :null
          } 
            <div className="col-sm-2 mt-5">
              <Radio
                list={this.state.choose}
                name={"typeDataChart"}
                title={""}
                inline={false}
                value={this.state.typeDataChart}
                handler={this.handlerRadio}
              />
            </div>
      {/* </div>
      <div className="row ml-5"> */}
          <div className="col-sm-3 mt-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idBranch", "branchCurrent");
                if (e == null) {
                  await this.setState({
                    salesList:[],salesCurrent:''
                  })
                
                } else {
                  await this.setState({
                    loadingSales:true
                  });
                  this.changeData({
                        keyFill:"salesList",
                        type:"getEmployee",
                        callback:()=>{this.setState({loadingSales:false})}
                      })
                }
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchCurrent}
              placeholder={this.state.branchText}
              labelfield={"true"}
            />
          </div>
          {/* <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idSales"}
              required={true}
              size={4}
              isLoading={this.state.loadingSales}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                } else {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }
              }}
              options={this.state.salesList}
              title={i18n.t("Sales")}
              value={this.state.salesCurrent}
              placeholder={this.state.salesText}
              labelfield={"true"}
            />
          </div> */}
          {/* <div className="col-md-3 mt-3">
            <Select2
              isClearable={true}
              name={"idCustomer"}
              required={true}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                } else {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                }
              }}
              options={this.state.customerCurrent}
              title={i18n.t("Customer")}
              value={this.state.customerCurrent}
              placeholder={this.state.customerText}
              labelfield={"true"}
            />
          </div> */}
          
          </div>
          <div className="row ml-5">
            <div className="col-md-9 mt-3 mb-3">
                <Button onClick={async (e) => {
                  await this.setState({ page: 1 }, ()=>this.setData())
                  await this.setState({ headeTable: true })
                }}
                  variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Generate")} </Button>
            </div>
        </div>
        {this.state.headeTable == true ?
          <React.Fragment>
            <div className={isProcessing ? " table-loading" : ""}>
            {!isProcessing ? (
            <Fragment>
                {this.renderChart()}
            </Fragment>
              ):(
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              )
            }
            </div>
          </React.Fragment> :
          ''}
      </React.Fragment>
          
    );
  }
}

export default SalesPerformanceChart;

import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = cookieManager.getUserToken();

export class DetailReturnSelling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      change: [],
      check: 0,
    };
  }

  filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
          
        })
    }
    return data
  }

  renderItemType(change){
    let name=""
    switch(change?.type_item){
      case 2:
        name="Good"
      break;
      case 3:
        name="Bad"
      break;
    }

    return name;
    
  }

  renderDetail(data){
    let tr=[];
      data?.map((log,i)=>{
        return (
          tr =<tr key={'a'+i}>
            <td>{i+1}</td>
            <td>{log.selling_no}</td>
            <td>{log.item_name}</td>
            <td>{log.bandNo}</td>
            <td>{log.unit_name}</td>
            <td>{log.qty}</td>
            <td>{log.unitPrice}</td>
            <td>{log.total}</td>
          </tr>
        )
      })
    
    return tr
  }
  render() {
    const { choose } = this.props;
    if (choose != null && this.state.check == 0) {
      this.state.detail = choose.log_value;
      this.state.change = choose.change_value;
      this.state.check = 1;
    }
    return (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Table rules="cols">
                  <thead></thead>
                  <tbody>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("Customer Code")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.customer_code ||''}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Sales Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.sales_name||''}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Branch Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.branch_name || ''}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Store Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.store_name || ''}
                      </td>
                     
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Date")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.date||''}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Type Item")}</td>
                      <td>:</td>
                      <td>
                        {this.renderItemType(this.state.change)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Type Payment")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.type||""}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Total")}</td>
                      <td>:</td>
                      <td>
                        {this.state.change?.total||0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row">
            <h3 className="text-center">{i18n.t('Details')}</h3>
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{i18n.t('Invoice No.')}</th>
                  <th>{i18n.t('Item')}</th>
                  <th>{i18n.t('Band No.')}</th>
                  <th>{i18n.t('Unit')}</th>
                  <th>{i18n.t('Qty')}</th>
                  <th>{i18n.t('Price')}</th>
                  <th>{i18n.t('Total')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.renderDetail(this.state.change?.details)
                  
                }
              </tbody>
            </Table>
            </div>
          </React.Fragment>
        )
  }
}

export default DetailReturnSelling;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}
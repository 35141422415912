import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import CashBankBook from '../../../../components/modules/report/cash-bank';
import CashBankBookMiddleware from '../../../../middleware/modules/report/cash-bank';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,cashbankBook }) => ({
  isProcessing: cashbankBook.isProcessing,
  isError: cashbankBook.isError,
  errorMessage: cashbankBook.errorMessage,
  datalist:cashbankBook.data,
  showModal:cashbankBook.showModal,
  helperList:cashbankBook.helperCashBankBook
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>CashBankBookMiddleware.getHelper(),
    reset: () =>CashBankBookMiddleware.resetList(),
    setData: (data) =>CashBankBookMiddleware.list(data),
    excel: (data) =>CashBankBookMiddleware.showExcel(data),
  }, dispatch);
};
class CashBankBookContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
    // this.props.search(search);
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Cash Bank Report")}
          </title>
        </Helmet>
      <CashBankBook
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBankBookContainer);

export default class LogBgActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static VIEW_DATA_IN='VIEW_DATA_IN';
  static VIEW_DATA_OUT='VIEW_DATA_OUT';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_LOG_BG='GET_HELPER_LOG_BG';
  static GET_HELPERFULL_LOG_BG='GET_HELPERFULL_LOG_BG';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static REJECT_DATA_IN='REJECT_DATA_IN';
  static REJECT_DATA_OUT='REJECT_DATA_OUT';

  static submit(){
    return {
      type: LogBgActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: LogBgActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: LogBgActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: LogBgActions.VIEW_DATA,
      payload: data
    };
  }

  static viewDataIn(dataIn) {
    return {
      type: LogBgActions.VIEW_DATA_IN,
      payload: dataIn
    };
  }

  static viewDataOut(dataOut) {
    return {
      type: LogBgActions.VIEW_DATA_OUT,
      payload: dataOut
    };
  }

  static rejectDataIn(dataIn) {
    return {
      type: LogBgActions.REJECT_DATA_IN,
      payload: dataIn
    };
  }

  static rejectDataOut(dataOut) {
    return {
      type: LogBgActions.REJECT_DATA_OUT,
      payload: dataOut
    };
  }

  static delete(data) {
    return {
      type: LogBgActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: LogBgActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: LogBgActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: LogBgActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: LogBgActions.PROCESS_DATA,
    };
  }
  static resetList(){
    return {
      type: LogBgActions.RESET_LIST,
    };
  }
  static reset(){
    return {
      type: LogBgActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: LogBgActions.GET_HELPER_LOG_BG,
      payload:data
    };
  }
  static helperFull(data){
    return {
      type: LogBgActions.GET_HELPERFULL_LOG_BG,
      payload:data
    };
  }

  static errorInternal(error){
    return {
      type: LogBgActions.ERROR_SERVER,
      payload: error
    };
  }
}
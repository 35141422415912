import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import LeftSide from './LeftSide';
import useScript from '../utilities/useScript';
const url=process.env.APP_URL
class NewPassword extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      rePassword: '',
      password: '',
      showPassword: false,
      errorMessage:[],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }
  async componentDidMount(){
    const {token}=this.props
    this.props.verificationForgot(token)
  }
  onSubmit(e) {
      const credentials = {
        password: this.state.password,
        token:this.props.token,
        rePassword:this.state.rePassword,
      };
      this.props.setPassword(credentials);
    
  }
    

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  render() {
    const { isProcessing, isError, errorMessage,tokenValid,successNewPassword} = this.props;
   
    return (
      <div>
          <div id="login" className="d-flex flex-column flex-root min-vh-100">
              <div className="d-flex flex-column flex-column-fluid flex-lg-row">
                <LeftSide />
                <div className="d-flex flex-center w-lg-50 p-10 bg-primary">
                  <Helmet
                    titleTemplate="%s - Reset Password"
                    defaultTitle="Reset Password"
                  />
                    <div className="card rounded-3 w-md-550px">
                      <div className="card-body p-10 p-lg-20">
                            <Alert show={isError} variant={"danger"}>
                                {i18n.t(errorMessage[0])}
                              </Alert>
                          {(tokenValid  && !successNewPassword)  && 
                          <form className="form w-100" noValidate="novalidate" >
                          
                          <div className="text-center mb-10">
                          
                            <h1 className="text-gray-900 fw-bolder mb-3">Setup New Password</h1>
                          
                            
                            <div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ? <Link to="/login" className="link-primary fw-bold">{i18n.t("Sign in")}</Link>
                            </div>
                            
                          </div>
                          
                          
                          <div className="fv-row mb-8" data-kt-password-meter="true">
                            
                            <div className="mb-1">
                              
                              <div className="position-relative mb-3">
                                <input className="form-control bg-transparent" type={(this.state.showPassword)?"text":"password"} placeholder="Password" name="password" autoComplete="off" onChange={(e)=>this.setState({password:e.target.value})}  />
                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                  <i className="ki-duotone ki-eye-slash fs-2"></i>
                                  <i className="ki-duotone ki-eye fs-2 d-none"></i>
                                </span>
                              </div>
                              
                              
                              <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                              </div>
                              
                            </div>
                            
                            
                            <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
                            
                          </div>
                          
                          
                          <div className="fv-row mb-8">
                            
                            <input type={(this.state.showPassword)?"text":"password"} onChange={(e)=>this.setState({rePassword:e.target.value})} placeholder="Repeat Password" name="confirm-password" autoComplete="off" className="form-control bg-transparent" />
                            
                          </div>
                          <div className="checkbox">
                              <label htmlFor="Show Password">
                                <input id="showpassword" type="checkbox" value="1" onChange={e=>this.setState({ showPassword: !this.state.showPassword })} />
                                <i className="info" /> {i18n.t("Show Password")}
                              </label>
                            </div>
                          <div className="d-flex flex-wrap justify-content-center pb-lg-0 ">
                          <button type="button" onClick={()=>this.setState({errorMessage:[]},()=>this.onSubmit())} id="kt_sign_in_submit" className="btn btn-primary mx-5"
                                disabled={
                                  isProcessing
                                }
                              >
                                {isProcessing ? 
                                  <span className="indicator-progress " >{i18n.t("Please wait")}...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span> 
                                  : 
                                  <span className="indicator-label">{i18n.t("Submit")}</span>
                                }
                            </button>
                            
                          </div>
                          
                        </form>}
                        {(!tokenValid && !isProcessing && !successNewPassword) &&
                          <div className="d-flex flex-wrap justify-content-center pb-lg-0 ">
                            <Link to="/login" className="btn btn-primary mx-5 mt-10">{i18n.t("Sign In")}</Link>
                          </div>
                        }

                        {successNewPassword &&
                          <div className="text-center mb-10">
                            
                            <h1 className="text-gray-900 fw-bolder mb-3">Successfully change password</h1>
                            
                            <div className="text-gray-500 fw-semibold fs-6">Your password changed, please <Link to="/login" className="text-primary">{i18n.t("Sign In")}</Link></div>
                            
                        </div>
                        }
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
       
    );
  }
}

export default NewPassword;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Dropdown, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";

class TableUncollectedDebt extends Component {
  static propTypes = {
    data: PropTypes.any,
  };

  static defaultProps = {
    data: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      link: [],
      columns: [],
      search: {
        limit: 10,
      },
      fulldata: {
        branch: "",
      },
      page: null,
      currentPage: 1,
      totalData: 0,
      load: 0,
      isProcessing: false,
    
    };
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }
  changePage(event, url) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = urlParams.get("page");
      // this.runSearch();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    // this.runSearch();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    // this.runSearch();
  }

  render() {
    const { data, search } = this.props;
    let row = data.data;
    if (data == undefined && this.state.load == 0) {
      var detail = data;
      this.state.link = detail.links;
      this.state.fulldata = row;
      this.state.search = search;
      this.state.currentPage = detail.current_page;
      this.state.totalData = detail.total;
      this.state.page = detail.current_page;
      this.state.load = 1;
    }
    return (
      <React.Fragment>
       
          <div className="row">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="dataTables_length">
              <label>
                <select
                  onChange={(e) => this.changeLimit(e)}
                  name="kt_customers_table_length"
                  aria-controls="kt_customers_table"
                  className="form-select form-select-sm form-select-solid"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="-1">{i18n.t("All")}</option>
                </select>
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="kt_customers_table_paginate"
            >
              <ul className="pagination">
                {this.state.link != null
                  ? this.state.link.map((data, i) => (
                      <li
                        className={
                          "paginate_button page-item" +
                          (i == 0 ? " previous" : "") +
                          (i == this.state.link.length - 1 ? " next" : "") +
                          (data.url == null ? " disabled" : "") +
                          (data.active ? " active" : "")
                        }
                        key={i}
                      >
                        <button
                          key={i}
                          onClick={(e) => this.changePage(e, data.url)}
                          aria-controls="kt_customers_table"
                          data-dt-idx={i}
                          tabIndex="0"
                          className="page-link"
                        >
                          {i == 0 ? <i className="previous"></i> : ""}
                          {i != 0 && i != this.state.link.length - 1
                            ? data["label"]
                            : ""}
                          {i == this.state.link.length - 1 ? (
                            <i className="next"></i>
                          ) : (
                            ""
                          )}
                        </button>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TableUncollectedDebt;

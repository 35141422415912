import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import SalesPerformanceChartContainer from "../../../../containers/modules/chart/sales-performance/index-chart";
import SalesPerformanceTableContainer from "../../../../containers/modules/chart/sales-performance/index-table";
import SalesPerformanceMonitorContainer from "../../../../containers/modules/chart/sales-performance/index-monitor";
import SalesPerformanceTargetContainer from "../../../../containers/modules/chart/sales-performance/index-target";
import SalesPerformanceDistributionContainer from "../../../../containers/modules/chart/sales-performance/index-distribution";

const MySwal = withReactContent(Swal);
export class SalesPerfromance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      show: null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Sales Performance"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);

  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  renderTab() {
    // const { datalistItem, helperSalesInvoiceReport, datalist, data, dataCus, datalistDetail, datalistInvoiceDetail } = this.props;
    return (
      <Tabs
        defaultActiveKey="SalesPerformancDistribution"
        id={"tabs"}
        className="mb-3"
        // unmountOnExit
      >
        <Tab eventKey="SalesPerformanceChart" title={i18n.t("Sales Performance Chart")}>
          <SalesPerformanceChartContainer
          />
        </Tab>
        <Tab eventKey="SalesPerformanceTable" title={i18n.t("Sales Performance View")}>
          <SalesPerformanceTableContainer
          />
        </Tab>
        <Tab eventKey="SalesPerformanceDailyMonitor" title={i18n.t("Sales Performance Daily Monitor")}>
          <SalesPerformanceMonitorContainer
          />
        </Tab>
        <Tab eventKey="SalesPerformanceTarget" title={i18n.t("Sales Performance Target")}>
          <SalesPerformanceTargetContainer
          />
        </Tab>
        <Tab eventKey="SalesPerformancDistribution" title={i18n.t("Sales Performance Distribution")}>
          <SalesPerformanceDistributionContainer
          />
        </Tab>
        
     
      </Tabs>
    );

  }
  render() {
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default SalesPerfromance;

import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Menu from './Nav'
import ListLang from './ListLang'
import cookieManager from '../../utils/cookieManager';
import i18n from '../../i18n';
import { useLocation } from 'react-router-dom'
const changeLanguage = (lng) => {
	i18n.changeLang(lng);
  };
function checkActive(){
	//reset menu level 1
	var menuLevel1=document.getElementById('kt_header_tabs');
	var menuLevel1List=menuLevel1.getElementsByClassName('nav-link');
	if(menuLevel1List!=null && menuLevel1List!=undefined){
		for(var i=0;i<menuLevel1List.length;i++){
			menuLevel1List[i].classList.remove("active")
		}
	}
	//reset menu level 2
	var menuLevel2=document.getElementById('kt_header_navs_wrapper');
	var menuLevel2List=menuLevel2.querySelectorAll(".tab-pane.level2");
	
	if(menuLevel2List!=null && menuLevel2List!=undefined){
		for(var i=0;i<menuLevel2List.length;i++){
			menuLevel2List[i].classList.remove("active")
			var span=menuLevel2List[i].querySelectorAll(".spanIndex")
			for(var p=0;p<span.length;p++){
				span[p].classList.remove("active")
			}
		}
	}

	var elem=document.querySelector("[aria-current='page']");
	if(elem !=null && elem !=undefined && location.pathname!='/'){
		var currentLevel2=elem.closest('.tab-pane.level2')
		var currentLevel2ID=currentLevel2.id
		currentLevel2.classList.add('active')
		var masterLevel2=currentLevel2.closest('.tab-content')
		var listTab=masterLevel2.getElementsByClassName('level2')

		var tab=elem.closest('.tab-pane');
		var tabContent=tab.closest('.tab-content');
		
		var tabPane=tabContent.getElementsByClassName('tab-pane');
		for(var i=0;i<tabPane.length;i++){
			tabPane[i].classList.remove("active")
		}
		tab.classList.add("active");
		var level2=elem.closest('.MenuLevel2');
		var tabLevel1=level2.closest('.tab-pane').id;
		var TabIndex=level2.getElementsByClassName(tab.id)[0];
		if(TabIndex!=null && TabIndex!=undefined){
			TabIndex.classList.add("active")
		}

		var MenuLevelIndex=level2.getElementsByClassName('spanIndex')[0];
		if(MenuLevelIndex!=null && MenuLevelIndex!=undefined){
			MenuLevelIndex.classList.add("active")
		}

		//update Tab Level 1
		if(tabLevel1!=null && tabLevel1!=undefined){
			var currentMenuLevel1=menuLevel1.getElementsByClassName(tabLevel1)[0]
			if(currentMenuLevel1!=null && currentMenuLevel1!=undefined){
				currentMenuLevel1.classList.add("active")
			}
		}

		for(var pp=0;pp< listTab.length;pp++){
			var firtsTab=listTab[pp].getElementsByClassName('MenuLevel2');
			if(listTab[pp].id != currentLevel2ID){
				for(var tt=0;tt < firtsTab.length;tt++){
					var subHeader=firtsTab[tt].getElementsByClassName('subHeaderLevel2');
					for(var ll=0;ll < subHeader.length;ll++){
						if(ll==0){
							subHeader[ll].classList.add('active')
						}else{
							subHeader[ll].classList.remove('active')
						}
					}
				}
			}else{
				for(var tt=0;tt < firtsTab.length;tt++){
					var classa=firtsTab[tt].getElementsByClassName('spanIndex');
					for(var ll=0;ll < classa.length;ll++){
						var classList = Array.from(classa[ll].classList).filter(word => word == "active");
						if(classList.length ==0){
							var subHeader=firtsTab[tt].getElementsByClassName('subHeaderLevel2');
							for(var oo=0;oo<subHeader.length;oo++){
								if(oo==0){
									subHeader[oo].classList.add('active')
								}else{
									subHeader[oo].classList.remove('active')
								}
							}
							
						}
					}
				}
			}
		}
	}else{
		if(menuLevel2List!=null && menuLevel2List!=undefined){
			for(var i=0;i<menuLevel2List.length;i++){
				menuLevel2List[i].classList.remove("active")
				var span=menuLevel2List[i].querySelectorAll(".spanIndex")
				for(var p=0;p<span.length;p++){
					span[p].classList.remove("active")
					var subHeader=span[p].closest('.MenuLevel2').getElementsByClassName('subHeaderLevel2');
					for(var ll=0;ll < subHeader.length;ll++){
						if(ll==0){
							subHeader[ll].classList.add('active')
						}else{
							subHeader[ll].classList.remove('active')
						}
					}
				}
			}
		}
		
	}
}
 
const CheckAccess=(authUser,menu)=>{
	if(menu.visible==true){
		if(authUser !=null){
			if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
				let route=authUser.userAccess.route
				let name=menu.menuName
				if(!Array.isArray(name)){
					
					if(route.includes(name)){
						return true
					}
				}else{
					for(let i=0;i<name.length;i++){
						if(route.includes(name[i])){
							return true
						}
					}
				}
				
			}
		}
	}
	return false
}

class Header extends Component {
	constructor(props){
		super(props)
		this.state={
			user:null,
			currentUrl:location.pathname,
			firstLoad:0
		}
		this.logout=this.logout.bind(this)
	}
	setCurrentUrl(){
		this.setState({currentUrl:location.pathname})
	}
	changeLang(lang){
		cookieManager.setLanguage(lang)
		changeLanguage(lang);
	}
	componentDidMount(){
		checkActive()
		this.state.firstLoad=1;
	}
	componentDidUpdate(){
		checkActive()
	}
	logout(){
		this.props.logout()
	}
	renderLang(){
		let lang=ListLang.filter(lang=> lang.key== ((cookieManager.getCookie('lang')==undefined)?'en':cookieManager.getCookie('lang')))
		if(lang.length>0){
			return <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">{lang[0].label}
				<img className="w-15px h-15px rounded-1 ms-2" src={lang[0].image} alt="" /></span>
		}
	}
	render() {
	const {authUser}=this.props

	return (
		<div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
			<div className="header-top d-flex align-items-stretch flex-grow-1">
				<div className="d-flex container-fluid align-items-stretch">
					<div className="d-flex align-items-center align-items-lg-stretch me-5 flex-row-fluid">			
						<button className="d-lg-none btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 w-35px h-35px h-md-40px w-md-40px ms-n2 me-2" id="kt_header_navs_toggle">
							<span className="svg-icon svg-icon-2">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
									<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
								</svg>
							</span>
						</button>
						<NavLink to={'/'} className="d-flex align-items-center" onClick={()=>this.setCurrentUrl('/')}>
							<img alt="Logo" src="/assets/media/logos/logo.png" className="h-25px h-lg-30px" />
						</NavLink>
						<div className="align-self-end overflow-auto" id="kt_brand_tabs">	
							<div className="header-tabs overflow-auto mx-4 ms-lg-10 mb-5 mb-lg-0" id="kt_header_tabs" >		
								<ul className="nav flex-nowrap text-nowrap">
									{Menu.map((menu,i)=>{
										return (menu.visible)?
										<li key={"HeaderMenu"+i} className="nav-item">
											<a key={"HeaderMenua"+i} className={"nav-link "+menu.id} data-bs-toggle="tab" href={menu.link}>{menu.label}</a>
										</li>:''
									}
									)}
								</ul>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center flex-row-auto">
						<div className="d-flex align-items-center ms-1" id="kt_header_user_menu_toggle">
							<div className="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3" data-kt-menu-trigger="{default:'click', 'lg': 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
								<div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
									<span className="text-white opacity-75 fs-8 fw-semibold lh-1 mb-1">{(authUser!=null && authUser!=undefined)?authUser.username:'-'}</span>
									<span className="text-white fs-8 fw-bold lh-1">{(authUser!=null && authUser!=undefined)?authUser.name:'-'}</span>
								</div>
								<div className="symbol symbol-30px symbol-md-40px">
									<img src="/assets/media/avatars/admin.jpg" alt="image" />
								</div>
							</div>
							
							<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
								<div className="menu-item px-3">
									<div className="menu-content d-flex align-items-center px-3">
										<div className="symbol symbol-50px me-5">
											<img alt="Logo" src="/assets/media/avatars/admin.jpg" />
										</div>
										<div className="d-flex flex-column">
											<div className="fw-bold d-flex align-items-center fs-5">
												{(authUser!=null && authUser!=undefined)?authUser.username:'-'}
												{/* <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
													{(authUser!=null && authUser!=undefined)?authUser.devision:'-'}
												</span> */}
											</div>
											<a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{(authUser!=null && authUser!=undefined)?authUser.email:'-'}</a>
										</div>
									</div>
								</div>
								<div className="separator my-2"></div>
								<div className="menu-item px-5">
									<a href="#" className="menu-link px-5">My Profile</a>
								</div>
								
								<div className="separator my-2"></div>
								<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
									<a href="#" className="menu-link px-5">
										<span className="menu-title position-relative">{i18n.t("Language")}
										{this.renderLang()}
										</span>
									</a>
									
									<div className="menu-sub menu-sub-dropdown w-175px py-4">
										{
											ListLang.map(lang=>
												<div key={"lang#"+lang.key} className="menu-item px-3">
													<Link href="#" onClick={()=>this.changeLang(lang.key)} className="menu-link d-flex px-5 active">
													<span className="symbol symbol-20px me-4">
														<img className="rounded-1" src={lang.image} alt="" />
													</span>{lang.label}</Link>
												</div>
											)
										}
									</div>
								</div>
								<div className="menu-item px-5">
									<Link to={'/logout'} onClick={()=>this.logout()} className="menu-link px-5">Sign Out</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header-navs d-flex align-items-stretch flex-stack h-lg-70px w-100 py-5 py-lg-0" id="kt_header_navs">
				<div className="d-lg-flex container-fluid w-100">						
					<div className="d-lg-flex flex-column justify-content-lg-center w-100" id="kt_header_navs_wrapper">
						<div className="tab-content" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: false}" data-kt-scroll-height="auto" data-kt-scroll-offset="70px">
							{Menu.map((header,i)=>
								header.visible?
								<div key={"aaaAA"+i} className={"tab-pane level2 fade show "+header.id} id={header.id}>
									<div className="header-menu flex-column align-items-stretch flex-lg-row">
										<div className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-400 fw-semibold align-items-stretch flex-grow-1 px-2 px-lg-0" id="#kt_header_menu" data-kt-menu="true">
											{header.childs.map((menuHeader,i) =>
												(CheckAccess(authUser,menuHeader))?
												<div key={menuHeader.id+i} data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className={"menu-item menu-lg-down-accordion me-0 me-lg-2 MenuLevel2"}>
													<span className={"menu-link py-3 spanIndex"}>
														<span className="menu-title">{menuHeader.label}</span>
														<span className="menu-arrow d-lg-none"></span>
													</span>
													<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0">
														<div className="menu-active-bg px-4 px-lg-0">
															<div className="d-flex w-100 overflow-auto">
																<ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1">
																	{menuHeader.childs.map((menu,i)=>
																		(CheckAccess(authUser,menu))?
																		<li key={menu.id+i} className="nav-item mx-lg-1">
																			<a className={"subHeaderLevel2 nav-link py-3 py-lg-6 text-active-primary "+menu.id } href="#" data-bs-toggle="tab" data-bs-target={menu.link}>{menu.label}</a>
																		</li>:''
																	)}
																</ul>
															</div>
															<div className="tab-content py-4 py-lg-8 px-lg-7">
																{menuHeader.childs.map((menu,i)=>
																	(CheckAccess(authUser,menu))?
																	<div key={menu.id+i} className={"tab-pane w-lg-600px" + ((menu.active)?' active':'')} id={menu.id}>
																		<div className="row">
																			<div className="col-lg-5 mb-6 mb-lg-0">
																				<div className="row">
																					<div className="col-lg-12">
																						{menu.childs.map((menuLast,i)=>
																							(CheckAccess(authUser,menuLast))?
																							<div key={'LastMenu'+i} className="menu-item p-0 m-0">
																								<NavLink  to={menuLast.link} className={"menu-link px-5"} onClick={()=>this.setCurrentUrl(menuLast.link)}>
																									<span className="menu-title">{menuLast.label}</span>
																								</NavLink>
																							</div>:''
																						)}
																					</div>
																				</div>
																			</div>
																			<div className="col-lg-7">
																				<img src="/assets/media/stock/900x600/46.jpg" className="rounded mw-100" alt="" />
																			</div>
																		</div>
																	</div>:''
																)}
															</div>
														</div>
													</div>
												</div>:''
											)}
										</div>
									</div>
								</div>:''
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
		
	);
}
  }	

export default Header;

import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import ConfigBranchActions from '../../../../reducers/modules/master/config-branch/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url = process.env.REST_URL + 'master/config-branch'

export default class ConfigBranchMiddleware {

  static create(params) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.create());
      ConfigBranchMiddleware.showHelper(dispatch, params);
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.update());
      ConfigBranchMiddleware.showOne(dispatch, params);
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(ConfigBranchActions.resetList());
    };
  }
  static list(params) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.process());
      ConfigBranchMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(ConfigBranchActions.reset());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.view());
      ConfigBranchMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.submit());
      ConfigBranchMiddleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.submit());
      ConfigBranchMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }
  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(ConfigBranchActions.delete());
      ConfigBranchMiddleware.delete(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      ConfigBranchMiddleware.showHelper(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }
 
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-branch',
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch,error)
      });
  }

  static showlist(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
      },
      url: url,
      responseType: 'json',
      params: params
    })
      .then((response) => {

        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.list(response.data.result));
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch, error)
      });
  }
  
  static showOne(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
      },
      url: url + '/' + params,
      responseType: 'json'
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.view(response.data.result));
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch, error)
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
      },
      url: url + '/' + id,
      responseType: 'json'
    })
      .then((response) => {

        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.process(), ConfigBranchMiddleware.showlist(dispatch, params));
          ConfigBranchMiddleware.alertHendler(dispatch, response, true, 'Delete Data Successfully', 'success')
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch, error, true)
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
      },
      url: url,
      data: post,
      responseType: 'json'
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.process(), ConfigBranchMiddleware.showlist(dispatch, search));
          dispatch(ConfigBranchActions.success());
          ConfigBranchMiddleware.alertHendler(dispatch, response, true, 'Create Data Successfully', 'success')
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch, error)
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${ConfigBranchMiddleware.getToken()}`
      },
      url: url + '/' + params,
      data: post,
      responseType: 'json'
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ConfigBranchActions.process(),
            ConfigBranchMiddleware.showlist(dispatch, search));
          dispatch(ConfigBranchActions.success());
          ConfigBranchMiddleware.alertHendler(dispatch, response, true, 'Update Data Successfully', 'success')
        }
      })
      .catch((error) => {
        ConfigBranchMiddleware.alertHendler(dispatch, error)
      });
  }
  static alertHendler(dispatch, result, isSwal = false, extraMessage = '', type = 'error') {
    if (type == 'error') {
      if (result.response) {
        const status = result.response.status
        switch (status) {

          case 500:
            if (isSwal) {
              const data = result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title: 'Delete Error',
                html: data.error,
                icon: 'error',
                showConfirmButton: true,
              })
            } else {
              const data = result.response.data
              dispatch(ConfigBranchActions.error(data.error));
            }
            break;
          case 403:
            console.log('Error 403');
            break;
          case 404:
            console.log('Error 404');
            break;
          case 401:
            const data = result.response.data
            MySwal.fire({
              title: 'Login Invalid',
              html: data.error,
              icon: 'error',
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>
              }
            })
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: 'Internal Server Error',
          html: 'Please info your Developer to fix this',
          icon: 'error',
          showConfirmButton: true,
        })
        dispatch(ConfigBranchActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    } else {
      MySwal.fire({
        title: 'Success',
        html: extraMessage,
        icon: 'success',
        showConfirmButton: true,
      })
    }
  }
}

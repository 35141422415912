import OfferingActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  errorInternal:false,
  helperOffering:null,
  offeringHelperFull:null
};

function OfferingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OfferingActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case OfferingActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case OfferingActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case OfferingActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case OfferingActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case OfferingActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case OfferingActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case OfferingActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case OfferingActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case OfferingActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case OfferingActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case OfferingActions.GET_HELPER_Offering:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperOffering:action.payload};
    case OfferingActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case OfferingActions.GET_HELPER_OFFERING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperOffering:action.payload};
    case OfferingActions.GET_HELPER_OFFERING_FULL:
        return { ...state, isError: false,isSuccess:false,onSubmit:false,offeringHelperFull:action.payload};
    case OfferingActions.APPROVE_OFFERING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case OfferingActions.REJECT_OFFERING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};     
    
      default:
      return state;
    }
}


export default OfferingReducer;

export default class CustomerHierarchyActions {
  static UPDATE_DATA = "UPDATE_DATA";
  static SHOW_DATA = "SHOW_DATA";
  static VIEW_DATA = "VIEW_DATA";
  static ON_SUBMIT = "ON_SUBMIT";
  static ERROR_DATA = "ERROR_DATA";
  static ERROR_SERVER = "ERROR_SERVER";
  static ERROR_DELETE = "ERROR_DELETE";
  static PROCESS_DATA = "PROCESS_DATA";
  static RESET_DATA = "RESET_DATA";
  static SUCCESS_DATA = "SUCCESS_DATA";
  static RESET_LIST = "RESET_LIST";

  static submit() {
    return {
      type: CustomerHierarchyActions.ON_SUBMIT,
    };
  }

  static update(data) {
    return {
      type: CustomerHierarchyActions.UPDATE_DATA,
      payload: data,
    };
  }

  static view(data) {
    return {
      type: CustomerHierarchyActions.VIEW_DATA,
      payload: data,
    };
  }

  static resetList() {
    return {
      type: CustomerHierarchyActions.RESET_LIST,
    };
  }
  static list(data) {
    return {
      type: CustomerHierarchyActions.SHOW_DATA,
      payload: data,
    };
  }
  static error(error) {
    return {
      type: CustomerHierarchyActions.ERROR_DATA,
      payload: error,
    };
  }
  static success() {
    return {
      type: CustomerHierarchyActions.SUCCESS_DATA,
    };
  }
  static process() {
    return {
      type: CustomerHierarchyActions.PROCESS_DATA,
    };
  }
  static reset() {
    return {
      type: CustomerHierarchyActions.RESET_DATA,
    };
  }
  static errorInternal(error) {
    return {
      type: CustomerHierarchyActions.ERROR_SERVER,
      payload: error,
    };
  }
  static errorDelete(error) {
    return {
      type: CustomerHierarchyActions.ERROR_DELETE,
      payload: error,
    };
  }
}

import React, { Component, Fragment } from "react";
import Input from "../../../utilities/Form/input";
import { Button, Modal, Table } from "react-bootstrap";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import InputCustom from "../../../utilities/Form/InputCustom";
import FormPrice from "./formPrice";
import i18n from "../../../../i18n";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Offering: {},
        Off: [],
      },
      idSupplier: "",
      date: new Date(),
      description: "",
      supplierValue: null,
      count: 0,
      arrayOff: [
        {
          idItem: null,
          idUnit: null,
          price: null,
          itemValue: null,
          unitValue: null,
          changePrice: null,
        },
      ],

      supplierCurrent: null,
      itemCurrent: null,
      unitCurrent: null,
      supplierText: i18n.t("Select Supplier"),
      itemText: i18n.t("Select Item"),
      unitText: i18n.t("Select Unit"),

      isUpdate: 0,
    };
    (this.formPrice = React.createRef()),
      (this.handler = this.handler.bind(this));
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      [key]: value,
      [currentState]: e,
    });
  }

  runSubmit() {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    const Offering = {
      idSupplier: this.state.idSupplier,
      date: date,
      description: this.state.description,
    };
    const Off = this.state.arrayOff;
    let idItem = [];
    let price = [];
    let idUnit = [];

    for (var i = 0; i < Off.length; i++) {
      idItem.push(Off[i].idItem);
      idUnit.push(Off[i].idUnit);
      price.push(Off[i].price);
    }
    this.state.form.Offering = Offering;
    this.state.form.Off = {
      idItem: idItem,
      idUnit: idUnit,
      price: price,
    };
    this.state.isUpdate = 0;
    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  setPrice(detail, key) {
    let choose = {
      key: key,
      array: detail,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }

  saveUpdatePrice(status) {
    if (status) {
      let result = this.formPrice.current.runSubmit();
      for (var i = 0; i < result.price.length; i++) {
        this.handlerCurrencyArray(
          result.price[i].toFixed(2),
          "price",
          i,
          "arrayOff"
        );
      }

      this.setState({ modalDetail: false });
    } else {
      this.setState({ modalDetail: false });
    }
  }

  renderDetail(detail, i) {
    return (
      <Fragment key={'detail'+i}>
        <tr>
          <td>
            <Select
              isClearable={true}
              name={"idItem"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(e, "idItem", i, "arrayOff", "itemValue")
              }
              options={this.state.itemList}
              title={i18n.t("Item")}
              value={detail.itemValue}
              placeholder={this.state.itemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("Off.idItem." + i)}
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idUnit"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(e, "idUnit", i, "arrayOff", "unitValue")
              }
              options={this.state.unitList}
              title={i18n.t("Unit")}
              value={detail.unitValue}
              placeholder={this.state.unitText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("Off.idUnit." + i)}
            </div>
          </td>
          <td>
            <InputNumber
              id={"price" + i}
              name="price"
              placeholder={i18n.t("Price")}
              defaultValue={0}
              value={detail.price}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "price", i, "arrayOff")
              }
              // disabled={(this.state.isUpdate==0)?false:true}
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("Off.price." + i)}
            </div>
          </td>
          {this.state.isUpdate == 1 ? (
            <td>
              <InputCustom
                type={"checkbox"}
                className={
                  "form-check-input form-check form-check-custom form-check-solid"
                }
                name={"changePrice"}
                labelfield={"false"}
                value={detail.changePrice == 1 ? 0 : 1}
                onChange={(value) =>
                  this.handlerInputArray(value, "changePrice", i, "arrayOff")
                }
              />
            </td>
          ) : (
            ""
          )}
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayOff")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </Fragment>
    );
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  render() {
    const {
      data,
      isNewRecord,
      offeringHelperFull,
    } = this.props;
    this.state.supplierList =
      offeringHelperFull != null ? offeringHelperFull.supplier : "";
    this.state.itemList =
      offeringHelperFull != null ? offeringHelperFull.item : "";
    this.state.unitList =
      offeringHelperFull != null ? offeringHelperFull.unit : "";
    var c = 0;

    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.idSupplier = data.id_supplier
          this.state.date = new Date(data.date)
          this.state.supplierValue = {
            value: data.id_supplier,
            label: data.supplier_name
          },
          this.state.description = data.description
          this.state.description = data.description == null ? "" : data.description
          this.state.arrayOff = [];

        for (var po = 0; po < data.details.length; po++) {
          this.state.arrayOff.push({
            idItem: data.details[po].id_item,
            idUnit: data.details[po].id_unit,
            price: parseFloat(data.details[po].price)||0,
            itemValue: {
              value: data.details[po].id_item,
              label: data.details[po].item_name,
            },
            unitValue: {
              value: data.details[po].id_unit,
              label: data.details[po].unit_name,
            },
          });
        }
        this.state.isUpdate = 1;
      }
    }
      
    return (
      <form>
        <React.Fragment>
          <div className="row">
            <div className="col-md-4">
              <Select2
                //  isClearable={true}
                name={"idSupplier"}
                required={true}
                onChange={(e) =>
                  this.handlerSelect(e, "idSupplier", "supplierValue")
                }
                options={this.state.supplierList}
                title={i18n.t("Supplier")}
                value={this.state.supplierValue}
                placeholder={this.state.supplierText}
                currentState={"supplierCurrent"}
                error={this.renderError("Offering.idSupplier")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Effecticve Date")}
                required={true}
                selected={this.state.date}
                onChange={(e) => this.handlerDateTime(e, "date")}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMMM yyyy"
                endDate={new Date()}
                showYearDropdown
                className="form-control form-control-sm"
                error={this.renderError("Offering.date")}
              />
            </div>
            <div className="row">
              <div className="col-md-8">
                <Input
                  type={"input"}
                  name={"description"}
                  title={i18n.t("Description")}
                  value={this.state.description}
                  handler={this.handler}
                />
              </div>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-left">{i18n.t("Item")}</th>
                    <th className="text-left">{i18n.t("Unit")}</th>
                    <th className="text-left">{i18n.t("Price")}</th>
                    <th className="text-left">{i18n.t("Action")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayOff.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>

              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      idItem: null,
                      idUnit: null,
                      price: 0,
                      itemValue: null,
                      unitValue: null,
                      disabled: false,
                    },
                    "arrayOff"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button>
              {this.state.isUpdate == 1 ? (
                <Button
                  variant="btn btn-primary m-1"
                  onClick={(e) => {
                    this.state.count = 0;
                    for (var i = 0; i < this.state.arrayOff.length; i++) {
                      if (
                        this.state.arrayOff[i].changePrice != null &&
                        this.state.arrayOff[i].changePrice == 1
                      ) {
                        this.state.count += 1;
                      }
                    }

                    (this.state.count == 0)
                      ? this.alertError("Info", "Checklist Item First", "info")
                      : this.setPrice(this.state.arrayOff, "price");
                  }}
                >
                  {i18n.t("Update Price")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Modal
            show={this.state.modalDetail}
            backdropClassName="next1"
            centered
          >
            <Modal.Header>
              <Modal.Title>{this.state.titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormPrice
                data={this.state.itemChoose}
                isNewRecord={false}
                ref={this.formPrice}
              />
            </Modal.Body>
            <Modal.Footer className={"flex-center"}>
              <Button
                variant="btn btn-secondary m-1"
                onClick={(e) => this.saveUpdatePrice(false)}
              >
                {i18n.t("Close")}
              </Button>
              <Button
                variant="btn btn-primary m-1"
                onClick={(e) => this.saveUpdatePrice(true)}
              >
                {i18n.t("Update Price")}
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      </form>
    );
  }
}

export default Form;

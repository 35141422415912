import { useParams } from "react-router-dom";
import React from "react";
import AssignmentContainer from '../../../containers/modules/user/assignment'
export const Assignment = (props)=>{
    let params=useParams();
    let id=params.id;
    console.log('masuk sini');
    return (
        <AssignmentContainer id={id}/>
    )
}

export default Assignment;
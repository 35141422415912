import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Permission } from "../../../../components/modules/user/permission";
import { CreateForm } from "../../../../components/modules/user/permission/form";
// middlewares
import PermissionMiddleware from "../../../../middleware/modules/user/permission";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import Assign from "../../../../components/modules/user/permission/assign";

const mapStateToProps = ({ auth, permission }) => ({
  isProcessing: permission.isProcessing,
  isError: permission.isError,
  errorMessage: permission.errorMessage,
  data: permission.data,
  showModal: permission.showModal,
  isNewRecord:permission.isNewRecord
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: () => PermissionMiddleware.resetList(),
      create: () => PermissionMiddleware.create(),
      search: (data) => PermissionMiddleware.list(data),
      update: (data) => PermissionMiddleware.update(data),
      saveNew:(data,navigate)=>PermissionMiddleware.beforeSaveNew(data,navigate),
      saveUpdate:(id,data,navigate)=>PermissionMiddleware.beforeSaveUpdate(id,data,navigate),
      delete: (id, search) => PermissionMiddleware.beforeDelete(id, search),
      showAssign:(id)=>PermissionMiddleware.showAssign(id),
      assign: (data,id) =>PermissionMiddleware.beforeAssign(data,id),
      revoke : (data,id) =>PermissionMiddleware.beforeRevoke(data,id),
    },
    dispatch
  );
};
class PermissionContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentWillMount() {
    const {typeUrl,id}=this.props
    this.props.reset();
    switch (typeUrl) {
      case "assign":
        
        break;
      case "update":
        this.props.update(id)
        break;
      case "create":
        this.props.create()
        break;
      default:
        
        break;
    }
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }

  renderContent() {
    const { typeUrl } = this.props;
    switch (typeUrl) {
      case "assign":
        return (
          <>
            <Assign {...this.props} />
          </>
        );
      case "create":
        return (
          <>
            <CreateForm {...this.props} />
          </>
        );
      case "update":
          return (
            <>
              <CreateForm {...this.props} />
            </>
          );
      default:
        return (
          <>
            <Helmet>
              <title>{i18n.t("Permission List")}</title>
            </Helmet>
            <Permission {...this.props} />
          </>
        );
    }
  }
  render() {
    return <React.Fragment>{this.renderContent()}</React.Fragment>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionContainer);

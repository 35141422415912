import WarehouseInActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  errorInternal:false,
  helperWarehouseIn:null,
  WarehouseInHelperFull:null,
  printWHIn:null
};

function WarehouseInReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WarehouseInActions.RESET_LIST:
      return INITIAL_STATE;
    case WarehouseInActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, errorMessage: [], form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case WarehouseInActions.UPDATE_DATA:
      return { ...state, isProcessing: false,  errorMessage: [], single:null,isNewRecord:false,showModal:true};
    case WarehouseInActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case WarehouseInActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WarehouseInActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WarehouseInActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case WarehouseInActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case WarehouseInActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case WarehouseInActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case WarehouseInActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case WarehouseInActions.GET_HELPER_WAREHOUSE_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false, WHInHelperFull:action.payload}; 
    case WarehouseInActions.CANCEL_DATA:
          return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    case WarehouseInActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case WarehouseInActions.BEFORE_PRINT_WHIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHIn:null};
    case WarehouseInActions.PRINT_WHIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHIn:action.payload};    
        
    default:
      return state;
    }
}


export default WarehouseInReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { CustomerHierarchy } from "../../../../components/modules/master/customer-hierarchy";
// middlewares
import CustomerHierarchyMiddleware from "../../../../middleware/modules/master/customer-hierarchy";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, customerhierarchy }) => ({
  isProcessing: customerhierarchy.isProcessing,
  datalist: customerhierarchy.data,
  showModal: customerhierarchy.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      update: (data) => CustomerHierarchyMiddleware.update(data),
      reset: () => CustomerHierarchyMiddleware.resetList(),
      search: (data) => CustomerHierarchyMiddleware.list(data),
    },
    dispatch
  );
};
class CustomerHierarchyContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };

    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  updateItem(id) {
    this.props.update(id);
  }

  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Customer Hierarchy List")}
          </title>
        </Helmet>
      <CustomerHierarchy
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        updateItem={this.updateItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerHierarchyContainer);

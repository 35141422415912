import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/master/info-distribution/form";
// import ConfigContainer from "../../../../containers/modules/master/config-branch/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import LabelActive from "../../../utilities/Status/active";
import i18n from "../../../../i18n";
import { StatusActive, StatusTarget } from "../../../utilities/Const/StatusActive";
const MySwal = withReactContent(Swal);
export class InfoDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        
      },
      map:0,
      getGeo:[],
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Master"), class: " text-gray-600" },
        { link: null, title: i18n.t("Info Distribution"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "code",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code  "),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
          sortable:true
        },
        {
          key: "target",
          filter: {
            active: true,
            type: "Select",
            data: StatusTarget,
          },
          title: i18n.t("Target"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelActive value={data[column.key]} />
          }
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: StatusActive,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelActive value={data[column.key]} />
          }
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-end min-w-70px",
          tdClass: "",
          buttons: [
            {
              key: "id",
              btnText: i18n.t("Edit"),
              visible: {
                key: "status",
                params: [],
                function: (id, params) => {
                  const {authUser}=this.props
                  if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
                    let route=authUser.userAccess.route
                    if(route.includes('Info Distribution Update')){
                      return true
                    }
                  }
                  return false;
                },
              },
              btnFunction: (data, column) => {
                this.updateData(data);
              },
              bthClass: "btn btn-warning",
            },
            // {
            //   key: "id",
            //   btnText: i18n.t("Configure"),
            //   visible: {
            //     key: "status",
            //     params: [],
            //     function: (id, params) => {
            //       const {authUser}=this.props
            //       if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
            //         let route=authUser.userAccess.route
            //         if(route.includes('Config InfoDistribution Show')){
            //           return true
            //         }
            //       }
            //       return false;
            //     },
            //   },
            //   btnFunction: (data, column) => {
            //     this.updateConfig(data);
            //   },
            //   bthClass: "btn btn-warning",
            // },
            {
              key: "id",
              btnText: i18n.t("Delete"),
              visible: {
                key: "status",
                params: [],
                function: (id, params) => {
                  const {authUser}=this.props
                  if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
                    let route=authUser.userAccess.route
                    if(route.includes('Info Distribution Delete')){
                      return true
                    }
                  }
                  return false;
                },
              },
              btnFunction: (data, column) => {
                this.deleteData(data);
              },
              bthClass: "btn btn-danger",
            },
            
          ],
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  updateConfig(value){
    this.state.titleModal = i18n.t("Edit Config Info Distribution");
    this.props.updateConfig(value);
  }

  createData() {
    // this.props.createData();
    this.state.titleModal = i18n.t("Add Info Distribution");
    this.props.createData();

      // navigator.permissions.query({ name: 'geolocation' })
      // .then((response)=>{
      //   console.log(response)
      //   if(response.state === 'granted'){
      //   }else if (response.state === "prompt") {

      //   }else{
      //     Swal.fire({
      //       title: 'Permission Location Browser',
      //       html: i18n.t('Go To Menu Setting->Privacy and Security->Site Setting->In Recent Activity Select The Website->Select Location and Change Value with Allow '),
      //       icon: 'info',
      //     });
      //   }
      // })
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Info Distribution");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t('You will delete this Item')}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  renderCreateButton(){
    const {authUser}=this.props
    if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
      let route=authUser.userAccess.route
      if(route.includes('Info Distribution Create')){
        return (<Button
          type="button"
          className="btn btn-primary m-1"
          onClick={(e) => this.createData()}
        >
          {i18n.t("Add Info Distribution")}
        </Button>)
      }
    }
    return null;
  }
  render() {
    const { isProcessing, data, showModal,showModalConfig  } = this.props;
    this.state.listData = data;
    return (
      <div id="branch">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Fragment>
                      {this.renderCreateButton()}
                    </Fragment>
                    
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                  defaultSort={{name:'asc'}}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
         {/* <ConfigContainer
          showModal={showModalConfig}
          search={this.state.search}
          titleModal={this.state.titleModal}
        /> */}
      </div>
    );
  }
}

export default InfoDistribution;

import { useParams } from "react-router-dom";
import React from "react";
import ReceivingContainer from '../../../containers/Print/default/Receiving'
export const PrintReceiving = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <ReceivingContainer id={id}/>
    )
}

export default PrintReceiving;
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import RoleContainer from '../../../containers/modules/user/role'
export const Role = (props)=>{
    let params=useParams();
    let id=params.id;
    const navigate = useNavigate ();
   
    return (
        <RoleContainer id={id} {...props} navigate={navigate}/>
    )
}

export default Role;
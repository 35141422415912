import TakingGoodsActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  errorInternal:false,
  helperWarehouseIn:null,
  takingGoodsHelper:null,
  printWHIn:null
};

export default function TakingGoodsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TakingGoodsActions.RESET_LIST:
      return INITIAL_STATE;
    case TakingGoodsActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, errorMessage: [], form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case TakingGoodsActions.UPDATE_DATA:
      return { ...state, errorInternal:false,errorMessage:[],Submit:false,isProcessing: false, single:null,isNewRecord:false};
    case TakingGoodsActions.RETURN_DATA:
      return { ...state, isProcessing: true,  errorMessage: [], single:null,isNewRecord:false,showModal:true};
   case TakingGoodsActions.VIEW_ITEM_DATA:
      return { ...state, isProcessing: true,  errorMessage: [], single:null,isNewRecord:false,showModal:true};
    case TakingGoodsActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case TakingGoodsActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case TakingGoodsActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case TakingGoodsActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case TakingGoodsActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case TakingGoodsActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case TakingGoodsActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case TakingGoodsActions.APPROVE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case TakingGoodsActions.REJECT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case TakingGoodsActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case TakingGoodsActions.GET_HELPER_TAKINGGOODS_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false, takingGoodsHelper:action.payload}; 
    case TakingGoodsActions.CANCEL_DATA:
          return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    case TakingGoodsActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case TakingGoodsActions.BEFORE_PRINT_WHIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHIn:null};
    case TakingGoodsActions.PRINT_WHIN:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHIn:action.payload};    
        
    default:
      return state;
    }
}



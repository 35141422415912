import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Spinner, Tab, Tabs, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRefresh, faSync, faFileExcel, faDisplay } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import TablePurchasingReport from "./table";
import genRandonString from "../../../utilities/Class/RandomString";
import { Link } from "react-router-dom";
import { PurchasingSupplierReport } from "./purchasing-supplier";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";
const MySwal = withReactContent(Swal);
export class FormPurchasingItemReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemList: [],
            itemCurrent: null,
            idItem: null,
            itemText: i18n.t('All'),
            branchList: [],
            branchCurrent: null,
            idBranch: null,
            branchText: i18n.t('All'),
            listData: [],
            limitCurrent: null,
            startDate: new Date(),
            endDate: new Date(),
            periodCurrent: null,
            periodText: i18n.t("Select Period"),
            periodList: [],
            typeRi: 2,
            page: 1,
            col: 0,
            show: 0,
            pageLimit: [
                { value: '1', label: 'All' },
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                { value: '50', label: '50' },
            ],
            titleModal: "",
            headeTable: false,
            breadcrumbs: [
                { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
                { link: null, title: i18n.t("Report"), class: " text-gray-600" },
                { link: null, title: i18n.t("Purchasing Report"), class: " text-gray-500" },
            ]
        };
        this.handlerDateTime = this.handlerDateTime.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);
        this.handlerRadio = this.handlerRadio.bind(this);
    }

    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        }
        )
    }
    handlerRadio(e) {
        var key = e.target.name;
        var value = e.target.value;
        this.setState({
            [key]: value,
        });
    }
    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            [key]: value,
            [currentState]: e
        }
        )
    }

    setDataItem() {
        var startDate = this.state.typeRi == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
        var endDate = this.state.typeRi == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
        var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
        var item = (this.state.itemCurrent == null) ? '' : this.state.itemCurrent.value;
        var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
        var page = (this.state.page == null) ? 1 : this.state.page;
        var type = this.state.typeRi;

        let params = {
            startDateRi: startDate,
            endDateRi: endDate,
            limit: limit,
            item: item,
            idBranch: idBranch,
            page: page,
            type: type,
        }
        this.props.setDataItem(params);
    }

    renderList() {
        const { dataItem } = this.props
        let row = dataItem.data
        let html = [];
        let no = 1;
        let total = 0;
        if (dataItem != undefined && dataItem != null  && row != null) {
            Swal.close();
            if (row.length > 0) {
                for (var p = 0; p < row.length; p++) {
                    html.push(
                        <tr className="border" key={p}>
                            <td className="text-center">{no++}</td>
                            <td>
                                <Fragment>
                                    <details>
                                        <summary style={{ fontSize: '16', fontWeight: 'medium' }}>{row[p].name_item}</summary>
                                        <span width={"100%"}>
                                            <table>
                                                <thead>
                                                    <tr className="fw-bold fs-6 text-gray-600 border text-center ">
                                                        <th width={"20%"}>{i18n.t("Unit Name")}</th>
                                                        <th width={"20%"}>{i18n.t("Price (New)")}</th>
                                                        <th width={"20%"}>{i18n.t("Qty")}</th>
                                                        <th width={"20%"}>{i18n.t("Total Price")}</th>
                                                        <th width={"30%"}>{i18n.t("Average Price")}</th>
                                                        <th width={"10%"}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row[p].unit_data.map((da, i) => (
                                                        <tr style={{ fontSize: '12' }} key={i} className="border ">
                                                            <td>{da.name}</td>
                                                            <td style={{ textAlign: 'right' }}>{da.price_format}</td>
                                                            <td style={{ textAlign: 'right' }}>{da.qty_format}</td>
                                                            <td style={{ textAlign: 'right' }}>{da.total_format}</td>
                                                            <td style={{ textAlign: 'right' }}>{da.average_format}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </span>
                                    </details>
                                </Fragment>
                            </td>
                            <td style={{ textAlign: 'right', fontSize: '16', fontWeight: 'medium' }}>{row[p].total_format}</td>
                        </tr>)
                    total += row[p].total;
                }
                html.push(
                    <tr className="border  " >
                        <td colSpan={'2'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('TOTAL')}</td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
                            .toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>

                    </tr>)
            } else {
                html.push(
                    <tr className="border  " >
                        <td colSpan={'5'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
                    </tr>)
            }

            return html;
        }
    }

    alertHandler(title, message, icon, i) {
        Swal.fire({
            title: title,
            html: message,
            icon: icon,
        });
    }
    renderLoading() {
        Swal.fire({
            title: 'Loading',
            width: "200px",
            heightAuto: true
        });
        Swal.showLoading();
    }

    async convertExcel() {
        this.renderLoading();
        const token = cookieManager.getUserToken();
        axios({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            url: process.env.REST_URL + "report/purchasing-report/excel",
            method: "GET",
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                idSupplier: this.state.idSupplier,
            },
            responseType: "blob",
        }).then((response) => {
            if (response && response.status === 200 && response.data) {
                Swal.close();
                this.alertHandler('Convert Excel', 'Download Successfully', 'success')
                var urlCreator = window.URL || window.webkitURL;
                const blobUrl = urlCreator.createObjectURL(response.data);
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = blobUrl;
                link.setAttribute("download", 'Laporan Hutang.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
            }
        });
    }

    changeLimit(e, name, currentState) {
        var key = name
        var value = null
        if (e != 1) {
            value = e.value
        }
        this.setState({
            [key]: value,
            [currentState]: e
        }, () => this.setDataItem()
        )

    }

    changePage(event, url, count) {
        let get = parseInt(this.state.page) || 0;
        if (event.target.value == 0) {
            this.setState({ page: (get - 1) }, () => this.setDataItem());
        } else if (event.target.value == (count - 1)) {
            this.setState({ page: (get + 1) }, () => this.setDataItem());
        } else {
            this.setState({ page: event.target.value }, () => this.setDataItem())
        }
    }

    renderPagination() {
        const { dataItem } = this.props;
        if (this.state.show == 0) {
            this.state.limitCurrent = { value: '10', label: '10' }
            this.state.show = 1;
        }
        this.state.limit = 10;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="dataTables_length col-sm-9">
                        <label>
                            <Select2
                                name={"limit"}
                                labelfield={"false"}
                                onChange={async (e) => {
                                    this.changeLimit(e, "limit", "limitCurrent")
                                }}
                                options={this.state.pageLimit}
                                title={i18n.t("")}
                                value={this.state.limitCurrent}
                            />
                        </label>
                    </div>
                    <div
                        className="col-sm-3"
                        id="kt_customers_table_paginate"
                    >
                        <ul className="pagination">
                            {dataItem.links != null
                                ? dataItem.links.map((item, i) => (
                                    <li
                                        className={
                                            "paginate_button page-item" +
                                            (i == 0 ? " previous" : "") +
                                            (i == dataItem.links.length - 1 ? " next" : "") +
                                            (item.url == null ? " disabled" : "") +
                                            (item.active ? " active" : "")
                                        }
                                        key={i}
                                    >
                                        <button
                                            key={i}
                                            onClick={(e) => this.changePage(e, item.url, dataItem.links.length)}
                                            aria-controls="kt_customers_table"
                                            data-dt-idx={i}
                                            tabIndex="0"
                                            className="page-link"
                                            value={i}
                                        >
                                            {i == 0 ? 'Prev' : ""}
                                            {i != 0 && i != dataItem.links.length - 1 ? item["label"] : ""}
                                            {i == dataItem.links.length - 1 ? 'Next' : ""}
                                        </button>
                                    </li>
                                ))
                                : ""}
                        </ul>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    collapse(id) {
        $('#demo').collapse('hide');
        $('#demo').collapse('show');
    }

    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
            Swal.close();
        }
        return value;
    }

    render() {
        const { isProcessing, helperPurchasingReport } = this.props;
        this.state.branchList = helperPurchasingReport.branch;
        this.state.periodList = helperPurchasingReport.workCalendar;
        this.state.itemList = helperPurchasingReport.item;
        return (
            <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body pt-3">
                    <div className="row mb-4" >
                        <div className="row">
                            <div className="col-mb-3">
                                <Radio
                                    list={Period}
                                    name={"typeRi"}
                                    title={""}
                                    inline={false}
                                    value={this.state.typeRi}
                                    handler={this.handlerRadio}
                                />
                            </div>
                        </div>
                        {this.state.typeRi == 1 ?
                            <Fragment>
                                <div className="col-3 mt-3">
                                    <Select2
                                        isClearable={true}
                                        name={"period"}
                                        required={true}
                                        size={4}
                                        onChange={async (e) => {
                                            await this.handlerSelect(e, "idPeriod", "periodCurrent");
                                        }}
                                        options={this.state.periodList}
                                        title={i18n.t("Week Period")}
                                        value={this.state.periodCurrent}
                                        placeholder={this.state.periodText}
                                    />
                                     <div className="fv-plugins-message-container invalid-feedback">
                                        {this.renderError('startDateRi')}
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <div className="col-3 mt-3">
                                    <DatePickerCustom
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        selectsRange={true}
                                        isClearable={false}
                                        name={"date"}
                                        title={i18n.t("Date Period")}
                                        required={true}
                                        onChange={(e) => {
                                            const [start, end] = e;
                                            this.setState({ startDate: start, endDate: end });
                                        }}
                                        la
                                        showYearDropdown
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                        peekNextMonth
                                        showMonthDropdown
                                        autoComplete={"off"}
                                        dateFormat="dd MMM yyyy"
                                        maxDate={new Date()}
                                        className="form-control form-control-sm"
                                    />
                                </div>

                            </Fragment>
                        }
                        <div className="col-sm-3 m-3">
                            <Select2
                                isClearable={true}
                                name={"idBranch"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    if (e == null) {
                                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                                    } else {
                                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                                    }
                                }}
                                options={this.state.branchList}
                                title={i18n.t("Branch")}
                                value={this.state.branchCurrent}
                                placeholder={this.state.branchText}
                                labelfield={"true"}
                            />
                        </div>
                        <div className="col-sm-3 m-3">
                            <Select2
                                isClearable={true}
                                name={"idItem"}
                                labelfield={"true"}
                                onChange={async (e) => {
                                    if (e == null) {
                                        this.handlerSelect(e, "idItem", "itemCurrent")
                                    } else {
                                        this.handlerSelect(e, "idItem", "itemCurrent")
                                    }
                                }}
                                options={this.state.itemList}
                                title={i18n.t("Item")}
                                value={this.state.itemCurrent}
                                placeholder={this.state.itemText}
                            />
                        </div>
                        <div className="col text-end">
                        </div>
                        <div className="row mb-4">
                            <div className="col-sm-2">
                                <Button onClick={async (e) => {
                                    await this.setState({ page: 1 }, () => this.setDataItem())
                                    await this.setState({ headeTable: true })
                                }}
                                    variant="success" className="btn btn-md btn btn-success"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                            </div>
                        </div>
                    </div>

                    {this.state.headeTable == true ?
                        <React.Fragment>
                            {!isProcessing ?
                                <Fragment>
                                    <table className="table table-responsive table-bordered table-hover border  ">
                                        <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 bg-aquamarine square border text-center ">
                                                <th width={"3%"}>{i18n.t("No.")}</th>
                                                <th width={"82"}>{i18n.t("Item Name")}</th>
                                                <th width={"15%"}>{i18n.t("Total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderList()}
                                        </tbody>
                                    </table>
                                    {this.renderPagination()}
                                </Fragment>
                                : this.renderLoading()
                            }

                        </React.Fragment> :
                        ''}
                </div>
                </div>
            </div>
        );
    }
}

export default FormPurchasingItemReport;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import ListPayment from '../../../../components/modules/accounting/list-payment';
import ListPaymentMiddelware from '../../../../middleware/modules/accounting/list-payment';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
import Loading from '../../../../components/utilities/Loading';
// middlewares
 

const mapStateToProps = ({ auth,listPayment }) => ({
  isProcessing: listPayment.isProcessing,
  isError: listPayment.isError,
  errorMessage: listPayment.errorMessage,
  showModal:listPayment.showModal,
  helper:listPayment.helperListPayment,
  data:listPayment.data,
  dataSupplier:listPayment.listPaymentSupplier,
  dataCustomer:listPayment.listPaymentCustomer,
  userAction:listPayment.userAction
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>ListPaymentMiddelware.getHelper(),
    reset: () =>ListPaymentMiddelware.resetList(),
    show: (data) =>ListPaymentMiddelware.show(data),
    newEvent:(data)=>ListPaymentMiddelware.create(data),
    view:(id)=>ListPaymentMiddelware.view(id)
  }, dispatch);
};
class ListPaymentContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    helper:PropTypes.any,
    userAction:PropTypes.string
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    helper:null,
    userAction:null
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    // this.props.reset();
    // this.props.getHelper();
  }

  render() {
    const {isProcessing,data}=this.props
    
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("List Payment")}
          </title>
        </Helmet>
      <ListPayment
        {...this.props}
      />
      {/* {isProcessing?<Loading></Loading>:null} */}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPaymentContainer);

import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Pagination, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
const MySwal = withReactContent(Swal);
export class CustomerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPerPage: 20,
      currentPage: 1,
      setCurrentPage: 1,
      maxPaginationItems: 5,
      branchCurrent: null,
      branchList: [],
      data: null,
      branchText: i18n.t('Select Branch'),
      typeText: i18n.t('All'),
      show: false,
      fill: 0,
      bound: 0,
      headeTable: false,
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      yearList: [],
      yearText: i18n.t("Select Year"),
      yearValue: null,
      month: null,
      year: null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Customer Report"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    })
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    })
  }

  setData() {
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var idTypeCustomer = (this.state.typeCurrent == null) ? null : this.state.typeCurrent.value;


    let params = {
      idTypeCustomer: idTypeCustomer,
      idBranch: idBranch,
    }
    this.props.setData(params);
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var month = this.state.monthValue == null ? null : this.state.monthValue.value
    var mLabel = this.state.monthValue == null ? null : this.state.monthValue.label
    var year = this.state.yearValue == null ? null : this.state.yearValue.value
    var yLabel = this.state.yearValue == null ? null : this.state.yearValue.label
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;


    let params = {
      month: month,
      year: year,
      mLabel: i18n.t(mLabel),
      idBranch: idBranch,
    }
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/balance-sheet/excel",
      method: "GET",
      params: params,
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", mLabel + '_' + yLabel + '_' + nameFile + '_Laporan Neraca.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  handlerSearch(e) {
    var value = e.target.value;
    console.log(value)
    // let tampung = this.state.data
    var updateList = [...this.state.list];
    if (value != "") {
      updateList = updateList.filter((item) => {
        if (item.name != null) {
          return item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.code.toLowerCase().includes(value.toLowerCase()) ||
            item.type.toLowerCase().includes(value.toLowerCase()) ||
            item.owner.toLowerCase().includes(value.toLowerCase()) ||
            item.phone.toLowerCase().includes(value.toLowerCase()) ||
            item.email.toLowerCase().includes(value.toLowerCase()) ||
            item.address.toLowerCase().includes(value.toLowerCase()) ||
            item.province.toLowerCase().includes(value.toLowerCase()) ||
            item.city.toLowerCase().includes(value.toLowerCase()) ||
            item.district.toLowerCase().includes(value.toLowerCase()) ||
            item.sub_district.toLowerCase().includes(value.toLowerCase()) ||
            item.latitude.toLowerCase().includes(value.toLowerCase()) ||
            item.longitude.toLowerCase().includes(value.toLowerCase());
        }
      })
      this.setState({
        bound: 1,
        search: value,
        data: updateList,
      });
    } else {
      this.setState({
        fill: 1,
        search: '',
        bound: 0,
      });
    }
  }

  renderError(name) {
    const { isError, errorMessage } = this.props
    var value = ''
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0]
      }
      Swal.close();
    }
    return value;
  }

  renderList(currentItems) {
    let html = [];
    if (this.state.data != null && this.state.data != undefined) {

      let row = currentItems;
      if (row.length > 0) {
        for (var i = 0; i < row.length; i++) {
          html.push(
            <Fragment>
              <tr className="border" key={i}>
                <td className="search-td">{row[i].branch}</td>
                <td className="search-td">{row[i].code}</td>
                <td className="search-td">{row[i].name}</td>
                <td className="search-td">{row[i].type}</td>
                <td className="search-td">{row[i].owner}</td>
                <td className="search-td">{row[i].phone}</td>
                <td className="search-td">{row[i].email}</td>
                <td className="search-td">{row[i].address}</td>
                {/* <td className="search-td">{row[i].province}</td> */}
                <td className="search-td">{row[i].city}</td>
                <td className="search-td">{row[i].district}</td>
                <td className="search-td">{row[i].sub_district}</td>
                <td className="search-td" style={{textAlign:'center'}}>{row[i].latitude + ', ' + row[i].longitude}</td>
                {/* <td className="search-td">{row[i].longitude}</td> */}
                <td className="search-td">{row[i].visit}</td>
                <td className="search-td">{row[i].code_sales}</td>
                <td className="search-td">{row[i].sales}</td>
                <td className="search-td" style={{textAlign:'right'}}>{row[i].credit_limit}</td>
              </tr>

            </Fragment>
          );

        }
      }

    }
    return html;
  }



  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber
    })
  }

  renderTable() {
    const { isProcessing, helperList, dataList, isError } = this.props;
    let currentPage = this.state.currentPage
    console.log('P', this.props)
    console.log('S', this.state)

    if (this.state.data != null && this.state.data != undefined && isError == false) {

      let items = this.state.data;

      if (items.length > 0) {

        if (this.state.head == 0) {
          this.state.beginningDebt = items[0].beginningDebt,
            this.state.beginningCredit = items[0].beginningCredit,
            this.state.debt = items[0].debt,
            this.state.credit = items[0].credit,
            this.state.endingDebt = items[0].endingDebt,
            this.state.endingCredit = items[0].endingCredit,

            this.state.head = 1;
        }

        const totalPages = Math.ceil(items.length / this.state.itemsPerPage);
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
        const startPage = Math.max(1, currentPage - Math.floor(this.state.maxPaginationItems / 2));
        const endPage = Math.min(totalPages, startPage + this.state.maxPaginationItems - 1);
        const adjustedStartPage = Math.max(1, endPage - this.state.maxPaginationItems + 1);
        return (
          <Fragment>
            <div className="search-table-outter " id="tableContainer">
              <table className="search-table" style={{ borderBottom: '1px solid #eff2f5' }}>
                <thead className="search-thead">
                  <tr>
                    <th className="search-th">{i18n.t("Branch")}</th>
                    <th className="search-th">{i18n.t("Customer Code")}</th>
                    <th className="search-th">{i18n.t("Customer Name")}</th>
                    <th className="search-th">{i18n.t("Customer Type")}</th>
                    <th className="search-th">{i18n.t("Owner Name")}</th>
                    <th className="search-th">{i18n.t("Phone")}</th>
                    <th className="search-th">{i18n.t("E-mail")}</th>
                    <th className="search-th">{i18n.t("Address")}</th>
                    {/* <th  className="search-th">{i18n.t("Province")}</th> */}
                    <th className="search-th">{i18n.t("City")}</th>
                    <th className="search-th">{i18n.t("District")}</th>
                    <th className="search-th">{i18n.t("Sub District")}</th>
                    <th className="search-th">{i18n.t("Latitude & Longitude")}</th>
                    {/* <th  className="search-th">{i18n.t("Longitude")}</th> */}
                    <th className="search-th">{i18n.t("Visit")}</th>
                    <th className="search-th">{i18n.t("Sales Code")}</th>
                    <th className="search-th">{i18n.t("Sales Name")}</th>
                    <th className="search-th">{i18n.t("Credit Limit")}</th>
                  </tr>
                </thead>
                <tbody className="search-body" key={"body"}>
                  {this.renderList(currentItems)}
                </tbody>
              </table>

            </div>
            <div className="row mt-4">
              <Fragment>
                <Pagination>
                  <Pagination.First onClick={() => this.handlePageChange(1)} disabled={currentPage === 1} />
                  <Pagination.Prev onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

                  {Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => (
                    <Pagination.Item
                      key={adjustedStartPage + index}
                      active={adjustedStartPage + index === currentPage}
                      onClick={() => this.handlePageChange(adjustedStartPage + index)}
                    >
                      {adjustedStartPage + index}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  <Pagination.Last onClick={() => this.handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
              </Fragment>
            </div>
          </Fragment>
        );
        // Change page
        // const paginate = (pageNumber) => setCurrentPage(pageNumber);
        //   return(
        //   <div>
        //       <h3>Paginated Items</h3>
        //       <ul>
        //         {items.map((item, index) => (
        //           <li key={index}>{item.name}</li>
        //         ))}
        //       </ul>
        //       <Pagination
        //         totalPages={totalPages}
        //         // paginate={paginate}
        //         currentPage={currentPage}
        //       />
        //     </div>
        //   );
        // return (
        //   <div>
        //     <div id="html-dist"></div>
        //     <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
        //       {i18n.t("CUSTOMER")}
        //     </div>
        //     <div className="row">
        //       <Fragment>
        //         <div className="search-table-outter ">
        //           <br />
        //           <table className="search-table" style={{ borderBottom: '1px solid #eff2f5' }}>
        //             <thead className="search-thead">
        //             <tr>
        // <th  className="search-th">{i18n.t("Customer Code")}</th>
        // <th  className="search-th">{i18n.t("Customer Name")}</th>
        // <th  className="search-th">{i18n.t("Customer Type")}</th>
        // <th  className="search-th">{i18n.t("Owner Name")}</th>
        // <th  className="search-th">{i18n.t("Phone")}</th>
        // <th  className="search-th">{i18n.t("E-mail")}</th>
        // <th  className="search-th">{i18n.t("Address")}</th>
        // <th  className="search-th">{i18n.t("Province")}</th>
        // <th  className="search-th">{i18n.t("City")}</th>
        // <th  className="search-th">{i18n.t("District")}</th>
        // <th  className="search-th">{i18n.t("Sub District")}</th>
        // <th  className="search-th">{i18n.t("Latitude")}</th>
        // <th  className="search-th">{i18n.t("Longitude")}</th>

        //               {/* {item.length > 0 ?
        //               item.map((col,i)=>{
        //                 return (<th colSpan={3} className="search-th">{col.name}</th>);
        //               })
        //               :null
        //             } */}

        //             </tr>
        //             <tr>
        //             {/* {item.length > 0 ?
        //               item.map((col,i)=>{
        //                 return (
        //                   <Fragment>
        //                     <th className="search-th">{i18n.t("Stock")}</th>
        //                     <th className="search-th">{i18n.t("Volume")}</th>
        //                     <th className="search-th">{i18n.t("Omset")}</th>
        //                     </Fragment>
        //                 );
        //               })
        //               :null
        //             } */}
        //             </tr>
        //             </thead>
        //             <tbody className="search-body"key={"body"}>
        //               {this.renderList()}
        //             </tbody>
        //           </table>
        //         </div>
        //       </Fragment>
        //     </div>
        //   </div>
        // );
      } else {
        return (
          <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
            {i18n.t("DATA NOT FOUND")}
          </div>);
      }
    }
  }


  renderTab() {
    const { isProcessing, helperList, dataList } = this.props;
    if (this.state.fill == 0 && this.state.list != undefined) {
      this.state.data = this.state.list
      this.state.fill = 1;
    }
    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="laporanCustomerReport"
        id="laporan"
        className="mb-3"
      >
        <Tab eventKey="laporanCustomerReport" title={i18n.t("Customer Report")}>
          <React.Fragment>
            <Fragment>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"idBranch"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      } else {
                        await this.handlerSelect(e, "idBranch", "branchCurrent");
                      }
                    }}
                    options={this.state.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.branchCurrent}
                    placeholder={this.state.branchText}
                    labelfield={"true"}
                    error={this.renderError("idBranch")}

                  />
                </div>
                <div className="col-md-4 mt-3">
                  <Select2
                    isClearable={true}
                    name={"idCustomerType"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e == null) {
                        await this.handlerSelect(e, "idCustomerType", "typeCurrent");
                      } else {
                        await this.handlerSelect(e, "idCustomerType", "typeCurrent");
                      }
                    }}
                    options={this.state.typeList}
                    title={i18n.t("Customer Type")}
                    value={this.state.typeCurrent}
                    placeholder={this.state.typeText}
                    labelfield={"true"}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-md-9 mt-3">
                  <Button onClick={async (e) => {
                    await this.setState({ show: true, headeTable: true, currentPage: 1 }, () => this.setData())
                  }}
                    variant="success" className="btn btn-md btn btn-primary m-1 ">
                    <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Display")}
                  </Button>

                  {/* <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Convert
                  </button>
                  <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item"
                      href={"/accounting/balance-sheet/print/"+ (this.state.branchCurrent != null ? this.state.branchCurrent.value : 'all') +"/"+
                      (this.state.monthValue != null ? this.state.monthValue.value : 'all') +"/"+
                      (this.state.yearValue != null? this.state.yearValue.value: 'all') 
                      
                      } target="_blank">
                      <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  {i18n.t("PDF")}</a> 
                    <a className="dropdown-item " href="#" onClick={() => this.convertExcel()}>
                      <FontAwesomeIcon icon={faFileExcel}>
                      </FontAwesomeIcon>  {i18n.t("Excel")}
                    </a> */}
                </div>
                {/* </div>  */}
                {this.state.headeTable == true ?
                  <Fragment>
                    <div className="col-md-3">
                      <input
                        type={"text"}
                        className="form-control form-control-md justify-content-end"
                        placeholder="Search"
                        name={"search"}
                        title={""}
                        value={this.state.search}
                        onChange={(e) => this.handlerSearch(e)}
                      />
                    </div>
                  </Fragment> : null}
              </div>
            </Fragment>
            <br />
            <div style={{ display: "block", width: '100%', height: '100%' }}>
              <div className="table-loading-message">
                <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                <div className="text-center">{i18n.t("Loading")}</div>
              </div>
            </div>

            {this.state.show == true ?
              <React.Fragment>
                <div className={isProcessing ? " table-loading" : ""}>
                  {!isProcessing ? (
                    <Fragment>
                      {this.renderTable()}
                      {/* <div className="row">
                        <span className='get-inline ' key={"span-aktiva"} style={{ width: '50%' }}>
                          <table key={"table-aktiva"} className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5' }}>
                            <thead>
                              <tr className="table-header" key={"theader-aktiva"}>
                                <th width={"60%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Aktiva")}</th>
                                <th width={"30%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Amount")}</th>

                              </tr>
                            </thead>
                            <tbody key={"body-aktiva"}>
                              {this.renderListAktiva()}
                            </tbody>
                          </table>
                        </span>
                        <span className='get-inline ' key={"span-pasiva"} style={{ width: '50%' }}>
                          <table key={"table-pasiva"} className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5' }}>
                            <thead>
                              <tr className="table-header" key={"theader-pasiva"}>
                                <th width={"60%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Pasiva")}</th>
                                <th width={"30%"} style={{ textAlign: 'center', color: 'white' }}>{i18n.t("Amount")}</th>
                              </tr>
                            </thead>
                            <tbody key={"body-pasiva"}>
                              {this.renderListPassiva()}
                            </tbody>
                          </table>
                        </span>
                      </div> */}

                    </Fragment>
                  ) : (
                    <div className="table-loading-message">
                      <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                      <div className="text-center">{i18n.t("Loading")}</div>
                    </div>
                  )}

                </div>
              </React.Fragment>
              : ''}

          </React.Fragment>
        </Tab>

      </Tabs>
    );
  }

  render() {
    const { isProcessing, helperList, dataList } = this.props;
    if (helperList != null) {
      this.state.branchList = helperList.branch;
      this.state.typeList = helperList.typeCustomer;
    }
    if (dataList != null && dataList != undefined) {
      this.state.list = dataList
      if (this.state.bound == 0) {
        this.state.fill = 0
      }
    }

    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerReport;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Category from '../../../../components/modules/hr/category';
import CategoryMiddleware from '../../../../middleware/modules/hr/category';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,categoryEmployee }) => ({
  isProcessing: categoryEmployee.isProcessing,
  isError: categoryEmployee.isError,
  errorMessage: categoryEmployee.errorMessage,
  datalist:categoryEmployee.data,
  showModal:categoryEmployee.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>CategoryMiddleware.create(),
    reset: () =>CategoryMiddleware.resetList(),
    search: data =>CategoryMiddleware.list(data),
    update: data =>CategoryMiddleware.update(data),
    delete: (id,search) =>CategoryMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class CategoryContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Category Employee List")}
          </title>
        </Helmet>
      <Category
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);

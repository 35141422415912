import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Selling } from "../../../../components/modules/sales/selling";
import SellingMiddleware from "../../../../middleware/modules/sales/selling";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ auth, selling }) => ({
  isProcessing: selling.isProcessing,
  isError: selling.isError,
  errorMessage: selling.errorMessage,
  datalist: selling.data,
  showModal: selling.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      cancel: (id, value, search) => SellingMiddleware.beforeCancel(id, value, search),
      create: () => SellingMiddleware.create(),
      reset: () => SellingMiddleware.resetList(),
      search: (data) => SellingMiddleware.list(data),
      update: (data) => SellingMiddleware.update(data),
      delete: (id, search) => SellingMiddleware.beforeDelete(id, search),
      getHelperFull: (id, search) =>
        SellingMiddleware.getHelperFull(id, search),
    },
    dispatch
  );
};
class SellingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.cancelItem = this.cancelItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  cancelItem(id, value, search) {
    this.props.cancel(id, value, search);
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Selling List")}
          </title>
        </Helmet>
      <Selling
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        cancelItem={this.cancelItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SellingContainer);

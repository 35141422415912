import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Navigate } from "react-router-dom";
import TakingGoodsMiddleware from "../../../../middleware/modules/warehouse/taking-goods";
import Form from "../../../../components/modules/warehouse/taking-goods/form";
import { Button, Modal } from "react-bootstrap";
import StoresMiddleware from "../../../../middleware/modules/master/store";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import FormReturn from "../../../../components/modules/warehouse/taking-goods/formReturn";
import Swal from "sweetalert2";
import FormView from "../../../../components/modules/warehouse/taking-goods/formView";

const mapStateToProps = ({ auth, takingGoods }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: takingGoods.isProcessing,
  isError: takingGoods.isError,
  errorMessage: takingGoods.errorMessage,
  dataView: takingGoods.single,
  isSuccess: takingGoods.isSuccess,
  isReset: takingGoods.isReset,
  isNewRecord: takingGoods.isNewRecord,
  onSubmit: takingGoods.onSubmit,
  helperTakingGoods: takingGoods.takingGoodsHelper,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelperFull: () => TakingGoodsMiddleware.getHelperFull(),
      getStore: () => StoresMiddleware.getHelper(),
      closeModal: () => TakingGoodsMiddleware.reset(),
      saveReturn: (data, search) => TakingGoodsMiddleware.beforeSaveReturn(data, search),
      
    },
    dispatch
  );
};
class FormViewContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: {},
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.getData = this.getData.bind(this);
    // this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.id);
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  close() {
    this.props.closeModal();
  }

  render() {
    const {
      isNewRecord,
      onSubmit,
      showModal,
      titleModal,
      isReset,
      dataView,
      isProcessing,
      isError,
      errorMessage,
    } = this.props;
    return (
      <>
        <Modal
          show={showModal}
          fullscreen={true}
        >
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <FormView
             {...this.props}
              data={dataView}
              
              ref={this.form}
              
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            {/* <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormViewContainer);

import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import DatePickerCustom from '../../../utilities/Form/datepicker';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                date:'',
                name:'',
                status:1,
            },
            date:new Date(),
            isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
   
    runSubmit(){
        this.state.form.date=moment(this.state.date).format('YYYY-MM-DD')
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }
    render(){
        const {dataUpdate,isNewRecord,allowanceList}=this.props
        this.state.allowanceList=allowanceList 
       if(dataUpdate !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form.description=dataUpdate.description,
                this.state.form.date=dataUpdate.date,
                this.state.date=new Date(dataUpdate.date)
                this.state.form.status=dataUpdate.status
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                <DatePickerCustom
                    name={"date"}
                    title={i18n.t("Holiday Date")}
                    required={true}
                    selected={this.state.date}
                    onSelect={async (e)=>{
                            await this.handlerDateTime(e,'date');
                        }
                    }
                    onChange={(e)=>{}}
                    startDay={0}
                    autoComplete={"off"}
                    dateFormat="dd MMM yyyy"
                    maxDate={new Date()}
                    className="form-control form-control-sm"
                />
                <Input
                    type={"input"}
                    name={"description"}
                    title={i18n.t("Description ")}
                    required
                    value={this.state.form.description}
                    handler={this.handler}
                    error={this.renderError('description')}
                />
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.form.status}
                    handler={this.handler}
                />
            </form>
        );
    }
}

export default Form;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Fade, Collapse, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faTrash,
  faCheckDouble,
  faPrint,
  faCheck,
  faTimes,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import SwitchButton from "../../../utilities/Form/SwitchButton";
import DatePickerCustom from "../../../utilities/Form/datepicker";
// import DatePickerCustom from './Form/datepicker';

class TableMemoOut extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    data: PropTypes.any,
    searchData: PropTypes.object,
    expandAble: PropTypes.bool,
    checkAble: PropTypes.bool,
    numberAble: PropTypes.bool,
  };

  static defaultProps = {
    isProcessing: false,
    data: [],
    searchData: {},
    expandAble: false,
    checkAble: true,
    numberAble: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      link: null,
      detail: this.props.detailData,
      columns: this.props.column,
      columnsDetail: this.props.columnDetail,
      tools: this.props.tools,
      filterSearch: this.props.filterSearch,
      search: {},
      page: null,
      expandTr: [],
      currentPage: 1,
      totalData: 0,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  tdAction(data, i, column) {
    return (
      <td key={i.toString()} className="text-end">
        <Link className="btn btn-info" to={"print/" + data.id} target="_blank">
          <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>{" "}
        </Link>
      </td>
    );
  }

  tdActionFull(data, i, column) {
    return (
      <td key={i.toString()} className="text-end">
        <Dropdown id="dropdown-btnaction">
          <Dropdown.Toggle
            variant="none"
            className="btn btn-sm btn-light btn-active-light-primary"
            id="dropdown-basic"
          >
            {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
            {column.buttons.map((button, i) => {
              let show = button.visible.function(
                data[button.visible.key],
                button.visible.params
              );
              let isLink = button.btnLink != null ? true : false;
              let result = button.key != null ? data[column.key] : data;
              return show == true ? (
                <div className="menu-item px-3" key={"btn-action" + i}>
                  {isLink == true ? (
                    <Dropdown.Item
                      href={button.btnLink.url + "/" + data[column.key]}
                      className="menu-link px-3"
                      target={button.btnLink.target}
                    >
                      {button.btnText}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      href="#"
                      className="menu-link px-3"
                      onClick={(e) => {
                        button.btnFunction(result, column);
                      }}
                    >
                      {button.btnText}
                    </Dropdown.Item>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    );
  }

  tdActive(data, i) {
    switch (data) {
      case "Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center ">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </td>
        );
      case "Non Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </td>
        );
        case "Waiting":
          return (
            <td key={i.toString()}>
              <div className="badge badge-light-warning">{i18n.t("Waiting")}</div>
            </td>
          );
        case "Approved":
          return (
            <td key={i.toString()}>
              <div className="badge badge-light-success">{i18n.t("Approved")}</div>
            </td>
          );
        case "Rejected":
          return (
            <td key={i.toString()}>
              <div className="badge badge-light-danger">{i18n.t("Rejected")}</div>
            </td>
          );
      }
  }

  tdBalance(data, i) {
    switch (data) {
      case "Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center ">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </td>
        );
      case "Non Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </td>
        );
      case "Waiting":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-warning">Waiting</div>
          </td>
        );
      case "Approved":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-success">Approved</div>
          </td>
        );
      case "Rejected":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-danger">Rejected</div>
          </td>
        );
    }
  }

  tdCheckData(data, i) {
    switch (data) {
      case "Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center ">
              <SwitchButton />
            </div>
          </td>
        );
      case "Void":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center">
              <SwitchButton />
            </div>
          </td>
        );
    }
  }

  tdCustom(data, i, type) {
    switch (type) {
      case "createdAt":
        return (
          <td key={i.toString()} className="text-center">
            <div className="badge badge-light-success">{data.created_by}</div>{" "}
            <br />
            <div className="badge badge-light-info">{data.created_at}</div>
          </td>
        );
        break;
      case "updatedAt":
        return (
          <td key={i.toString()} className="text-center">
            <div className="badge badge-light-success">{data.updated_by}</div>{" "}
            <br />
            <div className="badge badge-light-info">{data.updated_at}</div>
          </td>
        );
        break;
      default:
        <td key={i.toString()} className="text-center"></td>;
        break;
    }
  }
  tdNumber(dataRow, i) {
    const { data } = this.props;
    let page = 1;
    let limit = 10;
    if (data.current_page != undefined) {
      page = data.current_page;
    }
    if (this.state.search.limit != undefined) {
      if (this.state.search.limit != -1) {
        limit = this.state.search.limit;
      } else {
        limit = 1;
      }
    }
    let plus = 0;
    if (page > 1) {
      plus = limit * (page - 1);
    }
    return <td key={i.toString()}>{i + 1 + plus}</td>;
  }
  renderTd(column, data, i = 0) {
    var value = data[column.key];
    var type = column.type;
    switch (type) {
      case "text":
        return <td key={i.toString()}>{value}</td>;
      case "text-link":
        return (
          <td>
            <Link to={"/accounting/MemoOut/detail/" + data[column.key2]}>
              {value}
            </Link>
          </td>
        );
      case "action":
        return this.tdAction(data, i, column);
      case "actionFull":
        return this.tdActionFull(data, i, column);
      case "active":
        return this.tdActive(value, i);
      case "balance":
        return this.tdActive(value, i);
      case "check":
        return this.tdCheck(value, i);
      case "numberTd":
        return this.tdNumber(value, i);
      case "expand":
        return this.tdExpand(data, i);
      case "status":
        return this.tdCheckData(value, i);
      case "custom":
        return this.tdCustom(data, i, column.key);
      case "component":
        return this.tdComponent(data, column, i);
      default:
        return <td key={i.toString()}>{value}</td>;
    }
  }

  tdComponent(data, column, i) {
    return <td key={i.toString()}>{column.component(data, column)}</td>;
  }
  tdCheck(data, i) {
    return (
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            onChange={(e) => {
              console.log(data);
            }}
            className="form-check-input"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
    );
  }
  tdExpand(i) {
    // this.state.expandTr[i]=false
    return (
      <td className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={(e) => this.setExpand(i)}
            aria-controls={"table" + i}
            aria-expanded={this.state.expandTr[i]}
          >
            {" "}
            {this.state.expandTr[i] ? "-" : "+"}
          </Button>
        </div>
      </td>
    );
  }
  setExpand(i) {
    this.setState({
      ...this.state,
      expandTr: {
        ...this.state.expandTr,
        [i]: !this.state.expandTr[i],
      },
    });
  }
  updateSearch(value, key) {
    if (value == "") {
      value = null;
    }
    this.state.search[key] = value;
  }

  _onBlur() {
    this.state.search["page"] = 1;
    this.runSearch();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.runSearch();
    }
  }
  changePage(event, url) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = urlParams.get("page");
      this.runSearch();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    this.runSearch();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    this.runSearch();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.state[key] = value;
    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.runSearch();
  }
  runSearch() {
    this.props.runSearch(this.state.search);
  }
  renderFilter(column, filter) {
    if (filter.active) {
      switch (filter.type) {
        case "Text":
          return (
            <input
              onKeyPress={this._onEnter}
              onBlur={this._onBlur}
              onChange={(e) => {
                this.updateSearch(e.target.value, column.key);
              }}
              type="text"
              className="form-control form-control-solid form-control-sm"
              placeholder={column.title}
              name={column.key}
            />
          );
          break;
        case "Select":
          return (
            <select
              onChange={(e) => this.changeSelect(e, column.key)}
              className="form-select form-select-sm form-select-solid"
            >
              {filter.data.map((list, i) => (
                <option key={column.key + i} value={list.key}>
                  {list.label}
                </option>
              ))}
            </select>
          );
          break;
        case "Date":
          // this.state[column.key]='';
          return (
            <DatePickerCustom
              name={column.key}
              title={column.title}
              required={false}
              selected={this.state[column.key]}
              onChange={(e) => this.handlerDateTime(e, column.key)}
              startDay={0}
              isClearable={this.state[column.key] != null ? true : false}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm form-control-solid"
              labelfield={"false"}
            />
          );
          break;
      }
    } else {
      return "";
    }
  }
  renderCheck() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <input
            onChange={(e) => {
              console.log("check All");
            }}
            className="form-check-input"
            type="checkbox"
            value="1"
          />
        </div>
      </th>
    );
  }
  renderNumber() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          #
        </div>
      </th>
    );
  }
  renderExpand() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          +
        </div>
      </th>
    );
  }
  renderTrExpand(data, column, totalColumn) {
    const { checkAble, numberAble, expandAble } = this.props;
    const dataExpand = data[column.key];
    const columnExpand = column.columns;

    if (dataExpand != undefined) {
      return (
        <tr
          key={"expand" + data.id}
          className={!this.state.expandTr[data["id"]] ? "d-none" : ""}
        >
          {numberAble ? <td></td> : ""}
          <td colSpan={totalColumn + 1 + (checkAble ? 1 : 0)}>
            <Collapse
              key={"Collapse" + data["id"]}
              in={this.state.expandTr[data["id"]]}
            >
              {/* <Fade key={"Fade"+data['id']} in={this.state.expandTr[data['id']]}> */}
              <div id={"table" + data["id"]}>
                <table className="table table-responsive table-row-dashed table-row-gray-500 gy-5 gs-5 mb-0">
                  <thead>
                    <tr className="fw-semibold fs-6 text-gray-800 bg-aquamarine">
                      {columnExpand.map((header, i) => (
                        <th key={"expandHeader" + i} scope="col">
                          {header.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataExpand.map((data, i) => (
                      <tr key={"expandBody" + i}>
                        {columnExpand.map((fill, i) => (
                          <td key={"expandFill" + i} scope="row">
                            {data[fill.key]}
                          </td>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      {columnExpand.map((fill) => (
                        <td scope="row">
                          <strong>{data[fill.key2]}</strong>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* </Fade> */}
            </Collapse>
          </td>
        </tr>
      );
    }
  }
  render() {
    const {
      theExpand,
      isProcessing,
      data,
      searchData,
      checkAble,
      numberAble,
      expandAble,
    } = this.props;
    if (data != undefined) {
      this.state.link = data.links;
      this.state.data = data.data;
      this.state.totalData = data.total;
      this.state.search = searchData;
    }

    return (
      <div className={isProcessing ? " table-loading" : ""}>
        {isProcessing ? (
          <div className="table-loading-message">
            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
            <div className="text-center">Loading</div>
          </div>
        ) : (
          ""
        )}

        <table className="table align-middle table-row-dashed fs-6 gy-5">
          <thead>
            <tr
              key={"head"}
              className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
            >
              {numberAble ? this.renderNumber() : ""}
              {checkAble ? this.renderCheck() : ""}
              {expandAble ? this.renderExpand() : ""}
              {this.state.columns.map((column, i) => (
                <th key={i.toString()} className={column.tHclass}>
                  {column.title}
                </th>
              ))}
            </tr>
            {this.state.filterSearch ? (
              <tr key={"filter"}>
                {numberAble ? <th></th> : ""}
                {checkAble ? <th></th> : ""}
                {expandAble ? <th></th> : ""}
                {this.state.columns.map((column, i) => (
                  <th key={i.toString()} className={column.tHclass}>
                    {this.renderFilter(column, column.filter)}
                  </th>
                ))}
              </tr>
            ) : (
              ""
            )}
          </thead>
          <tbody className="fw-semibold text-gray-600">
            {this.state.data != undefined && this.state.data.length > 0 ? (
              this.state.data.map((data, p) => (
                <React.Fragment key={"empty" + p}>
                  <tr key={p}>
                    {numberAble
                      ? this.renderTd({ type: "numberTd" }, data["id"], p)
                      : ""}
                    {checkAble
                      ? this.renderTd({ type: "check" }, data["id"], p)
                      : ""}
                    {expandAble && data != undefined
                      ? this.renderTd({ type: "expand" }, data["id"], p)
                      : ""}
                    {this.state.columns.map((column, i) =>
                      this.renderTd(column, data, i)
                    )}
                  </tr>

                  {expandAble && data != undefined
                    ? this.renderTrExpand(
                        data,
                        theExpand,
                        this.state.columns.length
                      )
                    : ""}
                </React.Fragment>
              ))
            ) : (
              <tr key={"notfound"}>
                <td
                  className="text-center"
                  colSpan={
                    this.state.columns.length > 0
                      ? this.state.columns.length +
                        (expandAble ? 1 : 0) +
                        (numberAble ? 1 : 0) +
                        (checkAble ? 1 : 0)
                      : 1
                  }
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="dataTables_length">
              <label>
                <select
                  onChange={(e) => this.changeLimit(e)}
                  name="kt_customers_table_length"
                  aria-controls="kt_customers_table"
                  className="form-select form-select-sm form-select-solid"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="-1">All</option>
                </select>
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="kt_customers_table_paginate"
            >
              <ul className="pagination">
                {this.state.link != null
                  ? this.state.link.map((data, i) => (
                      <li
                        className={
                          "paginate_button page-item" +
                          (i == 0 ? " previous" : "") +
                          (i == this.state.link.length - 1 ? " next" : "") +
                          (data.url == null ? " disabled" : "") +
                          (data.active ? " active" : "")
                        }
                        key={i}
                      >
                        <button
                          key={i}
                          onClick={(e) => this.changePage(e, data.url)}
                          aria-controls="kt_customers_table"
                          data-dt-idx={i}
                          tabIndex="0"
                          className="page-link"
                        >
                          {i == 0 ? <i className="previous"></i> : ""}
                          {i != 0 && i != this.state.link.length - 1
                            ? data["label"]
                            : ""}
                          {i == this.state.link.length - 1 ? (
                            <i className="next"></i>
                          ) : (
                            ""
                          )}
                        </button>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableMemoOut;

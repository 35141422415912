import ListPaymentActions from "./actions";

const INITIAL_STATE = {
  data: [],
  listPaymentSupplier: {},
  listPaymentCustomer: {},
  isProcessing: false,
  isNewRecord: false,
  showModal: false,
  showModalChange: false,
  onSubmit: false,
  isError: false,
  errorMessage: [],
  errorInternal: false,
  helperListPayment: null,
  formHelper: null,
  formHelperChange: null,
  userAction: null,
  viewData: null,
};

function ListPaymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ListPaymentActions.AFTER_SAVE_UPDATE:
      return {
        ...state,
        isError: false,
        onSubmit: false,
        errorMessage: [],
        isProcessing: false,
        showModal: false,
        viewData: null
      };
    case ListPaymentActions.BEFORE_SAVE_UPDATE:
      return {
        ...state,
        isError: false,
        onSubmit: true,
        errorMessage: [],
        isProcessing: true
      };
    case ListPaymentActions.AFTER_SAVE_CHANGE:
      return {
        ...state,
        isError: false,
        onSubmit: false,
        errorMessage: [],
        isProcessing: false,
        showModalChange: false,
        viewData: null
      };
    case ListPaymentActions.BEFORE_SAVE_CHANGE:
      return {
        ...state,
        isError: false,
        onSubmit: true,
        errorMessage: [],
        isProcessing: true
      };
    case ListPaymentActions.SHOW_DATA_SUPPLIER:
      return {
        ...state,
        listPaymentSupplier: action.payload,
        isError: true,
        onSubmit: false,
        errorMessage: [],
        isProcessing: false
      };
    case ListPaymentActions.SHOW_DATA_CUSTOMER:
      return {
        ...state,
        listPaymentCustomer: action.payload,
        isError: true,
        onSubmit: false,
        errorMessage: [],
        isProcessing: false
      };
    case ListPaymentActions.SHOW_DATA:
      return {
        ...state,
        data: action.payload,
        isError: true,
        onSubmit: false,
        errorMessage: [],
        isProcessing: false
      };
    case ListPaymentActions.ERROR_DATA:
      return {
        ...state,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false
      };
    case ListPaymentActions.RESET_LIST:
      return {
        data: null,
        isProcessing: true,
        isNewRecord: false,
        showModal: false,
        onSubmit: false,
        isError: false,
        errorMessage: [],
        errorInternal: false,
        helperListPayment: null,
        formHelper: null,
        formHelperChange: null,

      };
    case ListPaymentActions.PROCESS_DATA:
      return {
        ...state,
        isProcessing: true,
      }
    case ListPaymentActions.FORM_HELPER:
      return {
        ...state,
        isProcessing: false,
        showModal: true,
        formHelper: action.payload,
        onSubmit: false,
      }
    case ListPaymentActions.FORM_HELPER_CHANGE:
      return {
        ...state,
        isProcessing: false,
        showModalChange: true,
        formHelperChange: action.payload,
        onSubmit: false,
      }
    case ListPaymentActions.NEW_DATA:
      return {
        ...state,
        userAction: 'create',
        isProcessing: true,
        formHelper: null,
        isNewRecord: true
      }
    case ListPaymentActions.CHANGE_DATA:
      return {
        ...state,
        // userAction:'create',
        isProcessing: true,
        formHelperChange: null,
        isNewRecord: true,
        showModalChange: true,
        showModal: false
      }
    case ListPaymentActions.VIEW_DATA:
      return {
        ...state,
        userAction: 'view',
        isProcessing: false,
        formHelper: null,
        formHelperChange: null,
        showModal: true,
        viewData: action.payload
      }
    case ListPaymentActions.UPDATE_DATA:
      return {
        ...state,
        userAction: 'update',
        isProcessing: false,
        showModal: true,
      }
    case ListPaymentActions.DELETE_DATA:
      return {
        ...state,
        userAction: null,
        isProcessing: false,
        showModal: false,
        viewData: null,
      }
    case ListPaymentActions.CLOSE_MODAL:
      return {
        ...state,
        isProcessing: false,
        formHelper: null,
        formHelperChange: null,
        showModal: false,
        showModalChange: false,
        userAction: null,
        viewData: null,
      }
    case ListPaymentActions.GET_HELPER_VISITSCHEDULE:
      return {
        ...state,
        isProcessing: false,
        helperListPayment: action.payload
      };
    case ListPaymentActions.FETCH_SCHEDULE:
      return {
        ...state,
        isProcessing: false,
        data: action.payload,
        showModal: false,
        onSubmit: false,
      }
    case ListPaymentActions.FETCH_CHANGE:
      return {
        ...state,
        isProcessing: false,
        data: action.payload,
        showModalChange: false,
        onSubmit: false,
      }
    case ListPaymentActions.ON_SUBMIT:
      return {
        ...state,
        isProcessing: true,
        onSubmit: true
      }
    case ListPaymentActions.VOID_DATA_SUPPLIER:
      return { 
        ...state, 
        errorInternal: false, 
        onSubmit: false, 
        isProcessing: false,
      }
    case ListPaymentActions.VOID_DATA_CUSTOMER:
      return { 
        ...state, 
        errorInternal: false, 
        onSubmit: false, 
        isProcessing: false,
      }
    default:
      return state;
  }
}

export default ListPaymentReducer;

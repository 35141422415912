import PurchaseRequestActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  printPr:null,
  helperPurchaseRequest:null,
  PurchaseRequestHelperFull:null,
  requestPrintPr:false,
};

function PurchaseRequestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PurchaseRequestActions.RESET_LIST:
      return INITIAL_STATE;
    case PurchaseRequestActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case PurchaseRequestActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case PurchaseRequestActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PurchaseRequestActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseRequestActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseRequestActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case PurchaseRequestActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PurchaseRequestActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PurchaseRequestActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case PurchaseRequestActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PurchaseRequestActions.GET_HELPER_PURCHASEREQUEST:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperPurchaseRequest:action.payload};
    case PurchaseRequestActions.GET_HELPER_PURCHASEREQUEST_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,purchaserequestHelperFull:action.payload};
    case PurchaseRequestActions.BEFORE_PRINT_PR:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,isProcessing:true,printPr:null};
    case PurchaseRequestActions.PRINT_PR:
      return { ...state,errorInternal:false ,isProcessing:false,  isError: false,isSuccess:false,onSubmit:false,printPr:action.payload};
      case PurchaseRequestActions.ON_LIMIT_PRINT:
      return { ...state,errorInternal:false, requestPrintPr:false, isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    case PurchaseRequestActions.ON_SAVE_REQUEST_PRINT:
      return { ...state,errorInternal:false,requestPrintPr:true,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    default:
      return state;
    }
}


export default PurchaseRequestReducer;

export default class MemoOutActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static CHANGE_STATUS='CHANGE_STATUS';
    static VIEW_DATA='VIEW_DATA';
    static CANCEL_DATA='CANCEL_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_MEMO_OUT='GET_HELPER_MEMO_OUT';
    static GET_HELPERFULL_MEMO_OUT='GET_HELPERFULL_MEMO_OUT';
    static SUCCESS_DATA='SUCCESS_DATA';
    static AFTER_DATA = 'AFTER_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_MEMO_OUT='PRINT_MEMO_OUT';
    static BEFORE_PRINT_MEMO_OUT='BEFORE_PRINT_MEMO_OUT';
    static REJECT_MEMOOUT='REJECT_MEMOOUT';
    static APPROVE_MEMOOUT = 'APPROVE_MEMOOUT';
  
    static submit(){
      return {
        type: MemoOutActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: MemoOutActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: MemoOutActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: MemoOutActions.VIEW_DATA,
        payload: data
      };
    }

    static approve() {
      return {
        type: MemoOutActions.APPROVE_MEMOOUT,
      };
    }

    static reject() {
      return {
        type: MemoOutActions.REJECT_MEMOOUT,
      };
    }
  
    static cancel(data) {
      return {
        type: MemoOutActions.CANCEL_DATA
      };
    }

    static change(data) {
      return {
        type: MemoOutActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: MemoOutActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: MemoOutActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: MemoOutActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: MemoOutActions.PROCESS_DATA,
      };
    }
    static resetList(){
      return {
        type: MemoOutActions.RESET_LIST,
      };
    }
    static reset(){
      return {
        type: MemoOutActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: MemoOutActions.GET_HELPER_MEMO_OUT,
        payload:data
      };
    }
    static helperFull(data){
      return {
        type: MemoOutActions.GET_HELPERFULL_MEMO_OUT,
        payload:data
      };
    }
  
    static errorInternal(error){
      return {
        type: MemoOutActions.ERROR_SERVER,
        payload: error
      };
    }
    
    static afterData(data){
      return {
        type: MemoOutActions.AFTER_DATA,
        payload: data
      };
    }

    static beforePrint(){
      return {
        type: MemoOutActions.BEFORE_PRINT_MEMO_OUT,
      };
    }
    static print(data){
      return {
        type: MemoOutActions.PRINT_MEMO_OUT,
        payload: data
      };
    }
  }
import { useParams } from "react-router-dom";
import React from "react";
import MemoInContainer from '../../../containers/Print/default/Memo-In'
export const PrintMemoIn = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <MemoInContainer id={id}/>
    )
}

export default PrintMemoIn;
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toLower } from "lodash";
import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import InputNumber from "../../../utilities/Form/InputNumber";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      customerTypeList: [],
      customerType:null,
      customerTypeValue: null,
      customerTypeText: i18n.t("Select Customer Type"),
      idType:null,
      status: 1,
      valueData:'',
      isUpdate: 0,
      suffixAll:[],
      arrayForm:[],
      arrayUpdate:[],
      suffixTop:'',
      suffixInvoice:'',
      suffixCredit:'',
      change:0,
    };
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerCurrency = this.handlerCurrency.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handlerSelect(e, currentState) {
    this.setState({ [currentState]: e });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
        [key]: value,
    });
  }

  runSubmit() {
    const { isNewRecord, dataUpdate, search ,helper} = this.props;
    let dataDetail = []
    var arr = this.state.arrayForm
    for(var i=0;i<arr.length;i++){
      dataDetail.push(arr[i]);
      if(dataDetail[i].valueItem == null){
        dataDetail[i].valueItem = 0
      }
    }
    
    this.state.form = {
      idType: (this.state.customerTypeValue)?this.state.customerTypeValue.value:'',
      status:this.state.status,
      top:this.state.top,
      creditLimit:this.state.creditLimit,
      invoiceLimit:this.state.invoiceLimit,
      count:this.state.count,
      detail:dataDetail
       
    };
    return this.state.form
  }


  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  
  handlerCurrencyArray(value, name, i, arrayname) {
    var key = name;
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[key] = value;
    this.setState(arr);
  }

  handlerCurrency(value, name) {
    var key = name;
    var value = value;
    this.setState({
      [key]: value,
    });
  }

  changeData(keyFill,type=null,key='',array=null,arraykey=null){
    let params=null
    switch(type){
        case 'getLabel':
            var url=process.env.REST_URL +'helper/get-label'
            var id =
          this.state.customerTypeValue != undefined
            ? this.state.customerTypeValue.value
            : null;
        params = {
          idType: id,
            }
        break;
    }

    if(type !=null){
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            url:url,
            responseType: 'json',
            params:params
          })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              if (array == null) {
                var arr = [];
                if (key == "") {
                  arr = response.data.result;
                  this.state.suffixAll = response.data.result;
                  this.arrayFill(arr)
                }
              }
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }

}
arrayFill(arr){
  const {helper,dataUpdate} = this.props
  this.state.arrayForm = helper != null ? helper.hierarchy:[];
    if(arr.length > 0){
      let arrFinal=[];
      for(var i=0;i<this.state.arrayForm.length;i++){
        const arrP = [...this.state['arrayForm']];
        const objectss = Object.assign(arrP[i],{int:arr[i].value})
        arrFinal.push(objectss)
      }
      this.setState({arrayForm:arrFinal})
    }else{
      let arrFinal=[];
      for(var i=0;i<this.state.arrayForm.length;i++){   
        const arrP = [...this.state['arrayForm']];
        const objectss = Object.assign(arrP[i],{int:''})
        arrFinal.push(objectss)
      }
      this.setState({arrayForm:arrFinal})
  }

  }
  

  render() {
    const { dataUpdate, isNewRecord, helper } = this.props;
    this.state.customerTypeList = helper != null ? helper.type : [];
    
    if (dataUpdate != null && !isNewRecord) {
   
      if (this.state.isUpdate == 0) {
        this.state.customerTypeValue = (dataUpdate != null)? {value:dataUpdate.type_id,label:dataUpdate.type}:''
        this.changeData('suffixAll','getLabel',"");
      }
      this.state.isUpdate=1;
     
    }

    return (
      <form>
     
        <Select2
          isClearable={true}
          name={"customerTypeValue"}
          title={i18n.t("Customer Type")}
          required={true}
          className={""}
          size={4}
          onChange={async (e) => {
            await this.handlerSelect(e, "customerTypeValue");
            await this.changeData('suffixAll','getLabel',"");
          }}
          options={this.state.customerTypeList}
          value={this.state.customerTypeValue}
          placeholder={this.state.customerTypeText}
          
        />
        <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("idType")}
              </div>
            
            {( this.state.customerTypeValue!= null )?
            <React.Fragment>{this.state.arrayForm.map((item,i) => <InputNumber
                  id={toLower(item.label)}
                  name={toLower(item.label)}
                  title={i18n.t(item.label)}
                  labelfield={"true"}
                  value={item.int}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrencyArray((value=='')?0:value,"int",i,"arrayForm")
                  }
                  suffix={(item.helper=='Rp.')?'':' '+item.helper}
                  prefix={(item.helper=='Rp.')?item.helper+' ':''}
                  className="form-control  form-control-sm text-end"
                />
                
         )}
         <Radio
                    list={{1:i18n.t('Active'),0:i18n.t('Non Active')}}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.status}
                    handler={this.handler}
                    
                />
          </React.Fragment>
         :''
//       
        }
         
        
      </form>
    );
  }
}

export default Form;

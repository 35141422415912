import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../utilities/table';
import Breadcrumbs from '../../../utilities/breadcrumbs';

export class City extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:{
                show:false,
                title:'hello',
                content:'',
                backdrop:'static',//static(cant close)|| false (close)
                size:'sm',// boostrap modal style
                fullscreen:false,  
            },
            page: null,
            search:{
                limit:10
            },
            name:'',
            tools:true,
            listData:{},
            breadcrumbs:[
                {link:process.env.APP_URL,title:'Home',class:' text-gray-600'},
                {link:null,title:'Master',class:' text-gray-600'},
                {link:null,title:'City',class:' text-gray-500'},
            ],
            column:[
                {key:'name',filter:{active:true,type:'Text',data:null},title:'Name City',type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'province',filter:{active:true,type:'Text',data:null},title:'Name Province',type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'active',filter:{active:true,type:'Select',data:[{key:1,label:'Active'},{key:0,label:'Non Active'}]},title:'Status',type:'active',tHclass:'min-w-125px',tdClass:''},
                {key:'id',filter:{active:false,type:'Text',data:null},title:'Action',type:'action',tHclass:'text-end min-w-70px',tdClass:''},
            ]
        };
        this.runSearch=this.runSearch.bind(this)
    }

    runSearch(value){
        this.props.search(value);
    }

    showModal(status,title='',params=null){

        const modalState={...this.state.modal,show:status}
        const updateModal={...this.state, modal: modalState}
        this.setState({
            ...this.state, 
            modal: {
                ...this.state.modal,
                show:status,
                title:title,
                content:<Form/>
            } 
        })
    }
    render() {
        this.state.listData=this.props.data
        return (
            <div id="city">
                <Breadcrumbs 
                    url={this.state.breadcrumbs}
                    tools={false}
                />
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end border-0 pt-6">
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                        <button type="button" className="btn btn-primary" onClick={(e)=>this.showModal(true,'Add City')}>Add City</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <Table 
                                    data={this.state.listData}
                                    tools={false}
                                    filterSearch={true}
                                    column={this.state.column}
                                    searchData={this.state.search}
                                    runSearch={this.runSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modal.show?
                    <Modal
                        setting={this.state.modal}
                        hide={this.showModal}
                    ></Modal>
                :''}
            </div>
        );
    }
}

export default City;

export default class LogApprovalActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_LOGAPPROVAL='GET_HELPER_LOGAPPROVAL';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static APPROVE='APPROVE';
    static REJECT='REJECT';
    static submit(){
      return {
        type: LogApprovalActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: LogApprovalActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: LogApprovalActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: LogApprovalActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: LogApprovalActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: LogApprovalActions.SHOW_DATA,
        payload: data
      };
    }
    static resetList() {
      return {
        type: LogApprovalActions.RESET_LIST
      };
    }
    static error(error){
      return {
        type: LogApprovalActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: LogApprovalActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: LogApprovalActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: LogApprovalActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: LogApprovalActions.GET_HELPER_LOGAPPROVAL,
        payload:data
      };
    }
    static errorInternal(error){
      return {
        type: LogApprovalActions.ERROR_SERVER,
        payload: error
      };
    }
    static approve() {
      return {
        type: LogApprovalActions.APPROVE
      };
    }
    static reject() {
      return {
        type: LogApprovalActions.REJECT
      };
    }
  }
import React, { Component, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/accounting/journal/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPencil,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import TableDetailJournal from "./table-detail";
import { Link } from "react-router-dom";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
export class DetailJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: "",
      listData: [],
      detailData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Accounting"), class: " text-gray-600" },
        { link: null, title: i18n.t("Detail Journal"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "no_journal",
          key2: "id",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Journal No."),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Journal Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "description",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Description"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "debt_format",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Debt"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "credit_format",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Credit"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
      ],
      columnDetail: [
        {
          key: "name_coa3",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Ledger"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_coa_help",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Helper Book"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "debt_format",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Debt"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "credit_format",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Credit"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "description",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Description"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
      ],
      columnTotal: [
        // {
        //   key: "total_debt_format",
        //   title: i18n.t("Debt"),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
        // {
        //   key: "total_credit_format",
        //   title: i18n.t("Credit"),
        //   type: "text",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        // },
      ],
    };
    this.updateData = this.updateData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.createData = this.createData.bind(this);
  }

  createData() {
    this.state.titleModal = i18n.t("Add Journal");
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Journal");
    this.props.updateItem(id);
  }
  cancelData(id) {
    const html = (
      <>
        <div>{i18n.t("You will void this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Void Data"),
              html: i18n.t("Please Wait ...."),
              icon: i18n.t("info"),
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Accept")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: i18n.t("question"),
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  addJournal(data) {
    this.props.payment(data, "multiple");
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal, dataDetail } = this.props;
    
    this.state.listData = dataDetail;
    this.state.detailData = dataDetail;

    return (
      <React.Fragment>
        <div id="customer-type">
          <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-fluid"
          >
            <div className="content flex-row-fluid" id="kt_content">
              <div className="card">
                <div className="card-header d-flex justify-content-end border-0 pt-6">
                  <div className="card-toolbar">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-customer-table-toolbar="base"
                    >
                      <Link
                        className="btn btn-info m-1"
                        to={"/accounting/journal"}
                        onClick={() => changeLocation("/accounting/journal")}
                      >
                        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>{" "}
                        {i18n.t("Back")}
                      </Link>
                      {data.is_system != 1 ? (
                        <React.Fragment>
                          <Button
                            type="button"
                            className="btn btn-success m-1"
                            onClick={(e) => this.updateData(this.props.id)}
                          >
                            <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>{" "}
                            {i18n.t("Edit")}
                          </Button>
                          <Button
                            type="button"
                            className="btn btn-danger m-1"
                            onClick={(e) => this.cancelData(this.props.id)}
                          >
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>{" "}
                            {i18n.t("Void")}
                          </Button>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <TableDetailJournal
                    numberAble={true}
                    data={this.state.listData}
                    detailData={this.state.detailData}
                    tools={false}
                    column={this.state.column}
                    columnDetail={this.state.columnDetail}
                    columnTotal={this.state.columnTotal}
                    cancelData={this.cancelData}
                    updateData={this.updateData}
                    isProcessing={isProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
          <FormContainer
            showModal={showModal}
            titleModal={this.state.titleModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DetailJournal;

export default class Coa4Actions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';

    static submit(){
      return {
        type: Coa4Actions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: Coa4Actions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: Coa4Actions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: Coa4Actions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: Coa4Actions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: Coa4Actions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: Coa4Actions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: Coa4Actions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: Coa4Actions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: Coa4Actions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: Coa4Actions.RESET_LIST,
      };
    }
//   static getHelper(){
//     return{
//       type : Coa4Actions.GET_HELPER_Coa4,
//     }

//   }
    static errorInternal(error){
      return {
        type: Coa4Actions.ERROR_SERVER,
        payload: error
      };
    }
  }
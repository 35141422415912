import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Marketing from '../../../../components/modules/marketing/marketing';
import MarketingMiddleware from '../../../../middleware/modules/marketing/marketing';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,marketing }) => ({
  isProcessing: marketing.isProcessing,
  isError: marketing.isError,
  errorMessage: marketing.errorMessage,
  datalist:marketing.data,
  showModal:marketing.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>MarketingMiddleware.create(),
    reset: () =>MarketingMiddleware.resetList(),
    search: data =>MarketingMiddleware.list(data),
    update: data =>MarketingMiddleware.update(data),
    delete: (id,search) =>MarketingMiddleware.beforeDelete(id,search),
    getHelper: (id, search) =>MarketingMiddleware.getHelper(id, search),
    view: data =>MarketingMiddleware.getOne(data),
  
  }, dispatch);

};
class MarketingContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
    this.viewItem=this.viewItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }
  viewItem(id){
    this.props.view(id)
    this.props.getHelper()
  }
  render() {
    const {isProcessing, search , datalist,showModal,helperMarketing} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Marketing List")}
          </title>
        </Helmet>
      <Marketing
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        // helperMarketing={helperMarketing}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        viewItem={this.viewItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingContainer);

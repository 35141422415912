export default class Coa1Actions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static GET_HELPER_COA1 ='GET_HELPER_COA1';
    static RESET_LIST ='RESET_LIST';

    static submit(){
      return {
        type: Coa1Actions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: Coa1Actions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: Coa1Actions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: Coa1Actions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: Coa1Actions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: Coa1Actions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: Coa1Actions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: Coa1Actions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: Coa1Actions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: Coa1Actions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: Coa1Actions.RESET_LIST,
      };
    }
    static errorInternal(error){
      return {
        type: Coa1Actions.ERROR_SERVER,
        payload: error
      };
    }
    static getHelper(data){
      return{
        type : Coa1Actions.GET_HELPER_COA1,
        payload: data
      }
  
    }
  }
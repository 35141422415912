import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import BalanceSheetMiddleware from "../../../middleware/modules/report/balance-sheet";
import PrintBalanceSheet from "../../../components/utilities/print/default/balance-sheet";

const mapStateToProps = ({ auth, balanceSheet }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: balanceSheet.isProcessing,
  dataPrint: balanceSheet.printBalanceSheet,
  requestPrint:balanceSheet.requestPrintcashFlow
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (data) => BalanceSheetMiddleware.getPrint(data),
    },
    dispatch
  );
};

class BalanceSheetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.data);
  }

  render() {
   
    return <PrintBalanceSheet {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetContainer);

import React, { Component } from "react";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import { StatusForm } from "../../../utilities/Const/StatusActive";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        type: "Cash",
        coaAccount: "",
        idBranch: "",
        idStore: "",
        name: "",
        status: 1,
      },
      branchCurrent: null,
      branchText: "Select Branch",
      branchList: [],
      storeCurrent: null,
      storeText: "Select Store",
      storeList: [],
      coaCurrent: null,
      coaText: "Select Ledger",
      coaList: [],
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    let coa3 = this.state.coaCurrent != null ? this.state.coaCurrent.value : "";
    this.state.form.coaAccount = coa3;
    return this.state.form;
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return value;
  }
  changeData(keyFill, type = null, key, array = null, arraykey = null) {
    const token = cookieManager.getUserToken();

    let params = null;
    switch (type) {
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    const { dataUpdate, isNewRecord, helperFull } = this.props;
    if (dataUpdate != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.form.name = dataUpdate.name),
          (this.state.form.coaAccount = dataUpdate.coaAccount_id),
          (this.state.form.status = dataUpdate.status);
        this.state.form.type = dataUpdate.type;
        this.state.form.idBranch = dataUpdate.branch_id;
        this.state.form.idStore = dataUpdate.store_id;
        this.state.isUpdate = 1;
        this.state.coaCurrent = {
          value: dataUpdate.coaAccount_id,
          label:
            dataUpdate.coaAccount != ""
              ? dataUpdate.coaAccount
              : this.state.coaText,
        };
        this.state.branchCurrent = {
          value: dataUpdate.branch_id,
          label:
            dataUpdate.branch != "" ? dataUpdate.branch : this.state.branchText,
        };
        this.state.storeCurrent = {
          value: dataUpdate.store_id,
          label:
            dataUpdate.store != "" ? dataUpdate.store : this.state.storeText,
        };
        if (dataUpdate.branch != "") {
          this.changeData("storeList", "getStore", "");
        }
      }
    }
    if (helperFull != null) {
      this.state.coaList = helperFull.coa[this.state.form.type];
      this.state.branchList = helperFull.branch;
    }
    return (
      <form>
        <div className="mb-3">
          <Select2
            name={"idBranch"}
            required={true}
            onChange={async (e) => {
              if (e != this.state.branchCurrent) {
                await this.setState({ storeCurrent: null, storeList: [] });
              }
              await this.handlerSelect(e, "idBranch", "branchCurrent");
              await this.changeData("storeList", "getStore", "");
            }}
            options={this.state.branchList}
            title={i18n.t("Branch")}
            value={this.state.branchCurrent}
            placeholder={this.state.branchText}
            error={this.renderError("idBranch")}
          />
        </div>
        <div className="mb-3">
          <Select2
            name={"idStore"}
            required={false}
            isClearable
            onChange={(e) => this.handlerSelect(e, "idStore", "storeCurrent")}
            options={this.state.storeList}
            title={i18n.t("Store")}
            value={this.state.storeCurrent}
            placeholder={this.state.storeText}
          />
        </div>
        <div className="mb-3">
          <Radio
            list={{ Cash: i18n.t("CASH"), Bank: i18n.t("BANK") }}
            name={"type"}
            title={i18n.t("Type")}
            inline={false}
            value={this.state.form.type}
            handler={async (e) => {
              await this.handler(e);
              await this.setState({
                coaList: helperFull.coa[e.target.value],
                coaCurrent: null,
              });
            }}
            error={this.renderError("type")}
          />
        </div>
        <div className="mb-3">
          <Select2
            name={"coaAccount"}
            required={true}
            onChange={(e) => this.handlerSelect(e, "coaAccount", "coaCurrent")}
            options={this.state.coaList}
            title={i18n.t("Ledger")}
            value={this.state.coaCurrent}
            placeholder={this.state.coaText}
            error={this.renderError("coaAccount")}
          />
        </div>
        <div className="mb-3">
          <Input
            type={"input"}
            name={"name"}
            title={i18n.t("Name")}
            required
            value={this.state.form.name}
            handler={this.handler}
            error={this.renderError("name")}
          />
        </div>

        <div className="mb-3">
          <Radio
            list={StatusForm}
            name={"status"}
            title={i18n.t("Status")}
            inline={false}
            value={this.state.form.status}
            handler={this.handler}
            error={this.renderError("status")}
          />
        </div>
      </form>
    );
  }
}

export default Form;

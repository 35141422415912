import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import PurchasingReportMiddleware from "../../../../middleware/modules/report/purchasing-report";
import { Helmet } from "react-helmet";
import FormPurchasingSupplierReport from "../../../../components/modules/report/purchasing-report/purchasing-supplier";

const mapStateToProps = ({ auth, purchasingReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: purchasingReport.isProcessing,
  isError: purchasingReport.isError,
  errorMessage: purchasingReport.errorMessage,
  datalist: purchasingReport.dataSupp,
  datalistDetail: purchasingReport.dataSuppDetail,
  datalistInvoiceDetail: purchasingReport.dataInvoiceDetail,
  isSuccess: purchasingReport.isSuccess,
  isReset: purchasingReport.isReset,
  isNewRecord: purchasingReport.isNewRecord,
  onSubmit: purchasingReport.onSubmit,
  helperPurchasingReport: purchasingReport.helperPurchasingReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDataSupplier: (data) => PurchasingReportMiddleware.listSupplier(data),
      setSupplierDetail: (data) => PurchasingReportMiddleware.listSupplierDetail(data),
      setInvoiceDetail: (data) => PurchasingReportMiddleware.listInvoiceDetail(data),
    },
    dispatch
  );
};
class PurchasingSupplierReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    datalist: PropTypes.any,
    datalistDetail: PropTypes.any,
    datalistInvoiceDetail: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.setDataSupplier = this.setDataSupplier.bind(this);
    this.setSupplierDetail = this.setSupplierDetail.bind(this);
    this.setInvoiceDetail = this.setInvoiceDetail.bind(this);

  }

  setDataSupplier() {
    this.props.setDataSupplier();
  }

  setSupplierDetail() {
    this.props.setSupplierDetail();
  }

  setInvoiceDetail() {
    this.props.setInvoiceDetail();
  }


  render() {
    const { show, datalist, datalistDetail, datalistInvoiceDetail, dataSupp } = this.props
    return (
      <Fragment>
        <FormPurchasingSupplierReport
          {...this.props}
          dataSupp={datalist}
          dataSuppDetail={datalistDetail}
          dataInvoiceDetail={datalistInvoiceDetail}
        />
      </Fragment>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingSupplierReport);

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Input from '../../../utilities/Form/input';
import Select2 from '../../../utilities/Form/Select2';
import Select from 'react-select';
import TextArea from '../../../utilities/Form/TextArea';
import InputNumber from '../../../utilities/Form/InputNumber';
import InputCustom from '../../../utilities/Form/InputCustom';
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';

export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
            },
            poNo:'',
            fromRequest:false,
            datePo:new Date(),
            dateDelivery:new Date(),
            idSupplier:'',
            idBranch:'',
            idStore:'',
            supplierCurrent:'',
            supplierList:null,
            supplierText:i18n.t('Select Supplier'),
            branchCurrent:'',
            branchList:null,
            branchText:i18n.t('Select Branch'),
            storeCurrent:'',
            storeList:null,
            storeText:i18n.t('Select Store'),
            typeItemList:[],
            typeItemValue: null,
            typeItemText: i18n.t("Select Item Type"),
            discountTotal:0,
            taxTotal:0,
            subTotal:0,
            grandTotal:0,
            tax:false,
            taxValue:10,
            arrayDetail:[
                {
                    itemValue:null,
                    description:'',
                    qty:0,
                    unitValue:null,
                    price:0,
                    discountPercent:0,
                    discountPrice:0,
                    priceTax:0,
                    priceNett:0,
                    priceTotal:0,
                    isPr:false,
                    idPr:'',
                }
            ],
            show:false,
            unitList:null,
            unitText:i18n.t('Select Unit'),
            itemList:null,
            itemText:i18n.t('Select Item'),
            isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
        this.showModal=this.showModal.bind(this)
        this.discountChange=this.discountChange.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    showModal(){
        this.setState({ 
            show:!this.state.show
        })
    }
    calculateRow(i,arrayname){
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        let price= parseFloat(this.state.arrayDetail[i].price)||0
        let qty=parseFloat(this.state.arrayDetail[i].qty)||0
        let taxValue=parseFloat(this.state.taxValue)||0
        let discount= parseFloat(this.state.arrayDetail[i].discountPrice) || 0
        let priceTax=0;
        if(this.state.tax){
            priceTax= (price- discount) * (taxValue/100)
        }
        let priceNett=(price +priceTax - discount)
        let priceTotal=priceNett * qty
        objectss["priceNett"] =priceNett.toFixed(2)
        objectss["priceTax"] =priceTax.toFixed(2)
        objectss["priceTotal"] =priceTotal.toFixed(2)
        this.setState(arr)
        
        this.calculateAll()
    }
    calculateAll(){
        //total
        let subTotal=0
        let grandTotal=0
        let taxTotal=0
        let discountTotal=0
        const detail=this.state.arrayDetail
        
        for(let i=0;i<detail.length;i++){
            let price=parseFloat(detail[i].price)||0
            let qty=parseFloat(detail[i].qty)||0
            let discount=parseFloat(detail[i].discountPrice)||0
            let tax=parseFloat(detail[i].priceTax) || 0
            subTotal +=price * qty
            discountTotal += discount *qty
            taxTotal += tax * qty
            grandTotal += (price - discount+ tax)* qty
        }

        this.setState({
            subTotal:subTotal.toFixed(2),
            discountTotal:discountTotal.toFixed(2),
            taxTotal:taxTotal.toFixed(2),
            grandTotal:grandTotal.toFixed(2),
        })
    }
    runSubmit(){
        const PurchaseOrder={
            poNo:this.state.poNo,
            date:moment(this.state.datePo).format('YYYY-MM-DD'),
            deliveryDate:moment(this.state.dateDelivery).format('YYYY-MM-DD'),
            idSupplier:this.state.idSupplier,
            idBranch:this.state.idBranch,
            idItemType:this.state.typeItemValue != null ? this.state.typeItemValue.value : null,
            idStore:this.state.idStore,
            description:this.state.description,
            subTotal:this.state.subTotal,
            tax:(this.state.tax)?this.state.taxValue:null,
            total:this.state.grandTotal,
            
        }
        const detail=this.state.arrayDetail
        let idItem=[]
        let idUnit=[]
        let idPR=[]
        let qty=[]
        let idSupplier=[]
        let unitPrice=[]
        let discount=[]
        let discountRate=[]
        let tax=[]
        let taxPrice=[]
        let nettPrice=[]
        let total=[]
        let description=[]
        for(var i=0;i<detail.length;i++){
            idItem.push((detail[i].itemValue==null)?'':detail[i].itemValue.value);
            idPR.push(detail[i].idPr);
            qty.push(detail[i].qty);
            idSupplier.push(this.state.idSupplier);
            idUnit.push((detail[i].unitValue==null)?'':detail[i].unitValue.value);
            unitPrice.push(detail[i].price);
            discount.push(detail[i].discountPercent);
            discountRate.push(detail[i].discountPrice);
            tax.push(this.state.taxValue);
            taxPrice.push(detail[i].taxPrice);
            nettPrice.push(detail[i].priceNett);
            total.push(detail[i].priceTotal);
            description.push(detail[i].description);
        }
        this.state.form.PurchaseOrder=PurchaseOrder
        this.state.form.PurchaseOrderDetail={
            idPR:idPR,
            idItem:idItem,
            idUnit:idUnit,
            qty:qty,
            idSupplier:idSupplier,
            unitPrice:unitPrice,
            discount:discount,
            discountRate:discountRate,
            tax:tax,
            taxPrice:taxPrice,
            nettPrice:nettPrice,
            total:total,
            description:description
        }
        return this.state.form
    }
    discountChange(e,i,arrayname,sender='Price'){
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        let price=parseFloat(arr[i].price) || 0
        var value=parseFloat(e)||0
        
        let percent=0
        let valuePrice=0
        if(sender=='Price'){
            percent=(value/price) *100
            valuePrice=value
            if(value > price){
                valuePrice=price
                percent=100
            }
        }
        if(sender=='Percent'){
            percent=value
            valuePrice=price * (value/100)
            if(percent > 100){
                valuePrice=price
                percent=100
            }
            
        }

        objectss['discountPrice'] = valuePrice.toFixed(2);
        objectss['discountPercent'] = percent.toFixed(2);
        this.setState(arr)
        this.calculateRow(i,arrayname)
    }

    handlerSelectArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            objectss[name] = null;
        }else{
            objectss[name] = e;
        }
        this.setState(arr)
    }
    handlerInputArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        var value=e.target.value
        objectss[name] = value;
        this.setState(arr)
        this.calculateRow(i,arrayname)
        this.discountChange(objectss.discountPercent ,i,arrayname,'percent')
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return value;
    }
    handlerCurrencyArray(e,name,i,arrayname) {
        
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        objectss[name] = e;
        this.setState(arr)
        this.calculateRow(i,arrayname)
    }
    renderDetail(detail,i){
        const {data,unitList}=this.props    
        return <React.Fragment key={"detail"+i}>
                    <tr>
                    <td>
                        {(!this.state.fromRequest)?
                        <Select
                            isClearable={true}
                            name={'itemId'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                await this.handlerSelectArray(e,"itemValue",i,"arrayDetail")
                                await this.changeData({
                                    keyFill: "price",
                                    type: "getOffering",
                                    key: "arrayDetail",
                                    array: "arrayDetail",
                                    arraykey : i
                                    
                                  })
                                await this.calculateRow(i,'arrayDetail')
                            }}
                            options={this.state.itemList}
                            value={detail.itemValue}
                            placeholder={this.state.itemText}
                        />: <Input
                                type={"input"}
                                name={"itemId"}
                                title={i18n.t("Item")}
                                value={(detail.itemValue != null)?detail.itemValue.label:null}
                                handler={(e)=>{}}
                                labelfield={"false"}
                                disabled
                            />
                        }
                        <div className="fv-plugins-message-container invalid-feedback">{this.renderError('PurchaseOrderDetail.idItem.'+i)}</div>
                    </td>
                    {/* <td><textarea
                            className='form-control  form-control-sm'
                            name={"description"}
                            title={"Note"}
                            value={detail.description}
                            onChange={(e)=>this.handlerInputArray(e,'description',i,"arrayDetail")}
                        ></textarea>
                    </td> */}
                    <td>
                        <InputNumber
                            id={"qty"+i}
                            name="qty"
                            placeholder={i18n.t("Qty")}
                            defaultValue={0}
                            value={detail.qty}
                            decimalsLimit={2}
                            disabled={(!this.state.fromRequest)?false:true}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"qty",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            error={this.renderError('PurchaseOrderDetail.qty.'+i)}
                            labelfield={"false"}
                        />
                    
                    </td>
                    <td>
                        {(!this.state.fromRequest)?
                            <Select
                                isClearable={true}
                                name={'unitId'}
                                required={true}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                    await this.handlerSelectArray(e,"unitValue",i,"arrayDetail")
                                    await this.changeData({
                                        keyFill: "price",
                                        type: "getOffering",
                                        key: "arrayDetail",
                                        array: "arrayDetail",
                                        arraykey : i
                                        
                                      })
                                    await this.calculateRow(i,'arrayDetail')
                                }}
                                options={this.state.unitList}
                                value={detail.unitValue}
                                placeholder={this.state.unitText}
                                
                            />:<Input
                                type={"input"}
                                name={"itemId"}
                                title={i18n.t("Item")}
                                value={(detail.unitValue != null)?detail.unitValue.label:null}
                                handler={(e)=>{}}
                                labelfield={"false"}
                                disabled
                        />
                        }
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </td>
                    <td>
                        <InputNumber
                            id={"qty"+i}
                            name="price"
                            placeholder={i18n.t("Price")}
                            defaultValue={0}
                            value={detail.price}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"price",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            labelfield={"false"}
                            error={this.renderError('PurchaseOrderDetail.unitPrice.'+i)}
                        />
                    </td>
                    <td>
                        <InputNumber
                            id={"discountPercent"+i}
                            name="discountPercent"
                            placeholder={i18n.t("Discount Percent")}
                            defaultValue={0}
                            value={detail.discountPercent}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Percent')}
                            className='form-control  form-control-sm text-end'
                            suffix=' %'
                            labelfield={"false"}
                        />
                     </td>
                    <td>
                        <InputNumber
                            id={"discountPrice"+i}
                            name="discountPrice"
                            placeholder={i18n.t("Discount Price")}
                            defaultValue={0}
                            value={detail.discountPrice}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Price')}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            labelfield={"false"}
                        /></td>
                    <td>
                        <InputNumber
                            id={"priceTax"+i}
                            name="priceTax"
                            placeholder={i18n.t("Tax")}
                            defaultValue={0}
                            value={detail.priceTax}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"priceTax",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            disabled
                            labelfield={"false"}
                        />
                    </td>
                    <td>
                        <InputNumber
                            id={"priceNett"+i}
                            name="priceNett"
                            placeholder={i18n.t("Total Nett")}
                            defaultValue={0}
                            value={detail.priceNett}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"priceNett",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            disabled
                            labelfield={"false"}
                        />
                    </td>
                    <td>
                        <InputNumber
                            id={"priceTotal"+i}
                            name="priceTotal"
                            placeholder={i18n.t("Total Price")}
                            defaultValue={0}
                            value={detail.priceTotal}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"priceTotal",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            disabled
                            labelfield={"false"}
                        />
                        </td>
                    {(!this.state.fromRequest)?
                        <td>
                            <Button variant='danger' className='btn btn-sm' onClick={(e)=>this.removeArray(i,'arrayDetail')}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                        </td>
                    :''}
                    
                </tr>
            </React.Fragment>
    }
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    addArray(value,key){
        const newRow=value
        const arr=[...this.state[key],newRow]
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    removeArray(i,key){
        const arr = this.state[key].filter( (obj,index) => {
            return index !== i;
          })
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        const {data,isNewRecord}=this.props
        let params=null
        switch(type){
            case 'getCode':
                var url=process.env.REST_URL +'helper/get-po-code'
                var date=((this.state.datePo !=undefined)?moment(this.state.datePo).format('YYYY-MM-DD') :null)
                var branch=((this.state.branchCurrent !=undefined)?this.state.branchCurrent.value:null)
                var store=((this.state.storeCurrent !=undefined)?this.state.storeCurrent.value:null)
                params={
                    date:date,
                    branch:branch,
                    store:store
                }
                if(data !=null && !isNewRecord){
                    if(moment(this.state.datePo).format('YYYY-MM') == moment(data.date).format('YYYY-MM')){
                        this.setState({poNo:data.poNo})    
                        type=null
                    }
                }
            break;
            case 'getStore':
                var url=process.env.REST_URL +'helper/get-store'
                var branch=((this.state.branchCurrent !=undefined)?this.state.branchCurrent.value:null)
                params={
                    id:branch,
                }
            break;
            case 'getOffering':
                var url=process.env.REST_URL +'helper/get-offering-po'
                var idSupplier=((this.state.supplierCurrent !=undefined)?this.state.supplierCurrent.value:null)
                var date=((this.state.datePo !=undefined)?moment(this.state.datePo).format('YYYY-MM-DD') :null)
                var itemId=(this.state[array][arraykey].itemValue!=undefined)?this.state[array][arraykey].itemValue.value:null
                var unitId=(this.state[array][arraykey].unitValue!=undefined)?this.state[array][arraykey].unitValue.value:null
                params={
                    supplier:idSupplier,
                    date:date,
                    item:itemId,
                    unit:unitId,
                }
            break
            case 'getTax':
                var url=process.env.REST_URL +'helper/get-tax-effective'
                var date=((this.state.datePo !=undefined)?moment(this.state.datePo).format('YYYY-MM-DD') :null)
                params={
                    date:date,
                    tax:'PPN'
                }
            break;
            case "getItem":
                var url=process.env.REST_URL + "helper/get-item-pr";
                var value=this.state.typeItemValue != undefined 
                ? this.state.typeItemValue.value 
                :null;
                    params = {
                    type: value
                    }
            break;
            case "getItemDetail":
                var url=process.env.REST_URL + "helper/get-item-pr";
                var value=this.state[array][arraykey].typeItemValue
                if(value !=null){
                    params = {
                    type: (value !=null)?value.value:null,
                    };
                }else{
                    type=null
                }
            break;
        }
      
        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
      
      }

    // changeData(keyFill,type=null,key,array=null,arraykey=null){
    //     const token=cookieManager.getUserToken()
    //     const {data,isNewRecord}=this.props
    //     let params=null
    //     switch(type){
            
    //     }

    //     if(type !=null){
    //         axios({
    //             method: 'GET',
    //             headers: {
    //               'Content-Type': 'application/json',
    //               Authorization: `Bearer ${token}`
    //             },
    //             url:url,
    //             responseType: 'json',
    //             params:params
    //           })
    //           .then((response) => {
    //             if (response && response.status === 200 && response.data) {
    //                 if(array!=null){
    //                     var arr = [...this.state[array]];
    //                     const objectss = arr[arraykey]
    //                     if(key==''){
    //                         objectss[keyFill] = response.data.result;
    //                     }else{
    //                         objectss[keyFill] = response.data.result[key];
    //                     }
    //                 }else{
    //                     if(key==''){
    //                        var arr= {[keyFill]:response.data.result}
    //                     }else{
    //                         var arr= {[keyFill]:response.data.result[key]}
    //                     }
    //                 }
    //                 this.setState(arr,async ()=>{
    //                    await this.reCalculate()
    //                 })
    //             }
    //           })
    //           .catch((error) => {
    //             console.log(error)
    //           });
    //     }

    // }
    reCalculate(){
        this.state.arrayDetail.map(async (detail,po)=>{
          this.calculateRow(po,'arrayDetail');
         })
    }
    render(){
        const {data,isNewRecord,helperPO}=this.props
        // this.state.itemList=((helperPO !=null)?helperPO.item:null)
        this.state.typeItemList=((helperPO !=null)?helperPO.itemType:null)
        this.state.unitList=((helperPO !=null)?helperPO.unit:null)
        this.state.supplierList=((helperPO !=null)?helperPO.supplier:null)
        this.state.branchList=((helperPO !=null)?helperPO.branch:null)
       
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){

                this.state.poNo=data.poNo,
                this.state.description=data.description,
                this.state.datePo=new Date(data.date)
                this.state.dateDelivery=new Date(data.deliveryDate)
                this.state.idSupplier=data.id_supplier
                this.state.supplierCurrent={value:data.id_supplier, label:data.name_supplier}
                this.state.typeItemValue={value:data.id_type_item, label:data.name_type_item}
                if(data.id_type_item !=null){
                     this.changeData({
                        keyFill: "itemList",
                        type: "getItem",
                        key: "item",
                      })
                }
                this.state.idBranch=data.id_branch
                this.state.idStore=data.id_store
                
                if(data.id_branch !=null){
                    this.changeData({
                        keyFill: "storeList",
                        type: "getStore",
                        key: "",
                      })
                }
                this.state.branchCurrent=(data.id_branch !=null)?{value:data.id_branch, label:data.name_branch}:null
                this.state.storeCurrent=(data.id_store !=null)?{value:data.id_store, label:data.name_store}:null
                this.state.taxValue=parseFloat(data.tax)||0
                this.state.tax=((data.tax !=null && this.state.taxValue > 0)?true:false)
                this.state.arrayDetail=[]
                for(var po=0;po<data.po_detail.length;po++){
                    if(data.po_detail[po].id_pr!=null && data.po_detail[po].id_pr!=0){
                        this.state.fromRequest=true
                    }
                    this.state.arrayDetail.push(
                        {
                            itemValue:{value:data.po_detail[po].id_item, label:data.po_detail[po].item_name},
                            description:data.po_detail[po].description,
                            qty:data.po_detail[po].qty,
                            unitValue:{value:data.po_detail[po].id_unit, label:data.po_detail[po].unit_name},
                            price:parseFloat(data.po_detail[po].unitPrice)||0,
                            discountPercent:parseFloat(data.po_detail[po].discount)||0,
                            discountPrice:parseFloat(data.po_detail[po].discount_rate)||0,
                            priceTax:parseFloat(data.po_detail[po].tax_price)||0,
                            priceNett:parseFloat(data.po_detail[po].nett_price)||0,
                            priceTotal:parseFloat(data.po_detail[po].total)||0,
                            isPr:false,
                            idPr:data.po_detail[po].id_pr,
                        }
                    )
                    this.calculateRow(po,'arrayDetail')
                }
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                <div className='row'>
                    <div className="col-3">
                        <Input
                            type={"input"}
                            name={"poNo"}
                            title={i18n.t("PO No")}
                            required
                            disabled
                            value={this.state.poNo}
                            handler={this.handler}
                            error={this.renderError('PurchaseOrder.poNo')}
                        />
                    </div>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"datePo"}
                            title={i18n.t("Date PO")}
                            required={true}
                            selected={this.state.datePo}
                            onChange={async (e)=>{
                                await this.handlerDateTime(e,'datePo'); 
                                await this.changeData({
                                    keyFill: "poNo",
                                    type: "getCode",
                                    key: "code",
                                  })
                            }}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            maxDate={new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"dateDelivery"}
                            title={i18n.t("Date Delivery")}
                            required={false}
                            selected={this.state.dateDelivery}
                            onChange={(e)=>this.handlerDateTime(e,'dateDelivery')}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            endDate={new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                   
                    </div>
                    <div className="row">
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idBranch'}
                            required={true}
                            size={4}
                            onChange={async (e)=>{
                                if(e!=this.state.branchCurrent){
                                    await this.setState({storeCurrent:null,storeList:[]});
                                }
                                await this.handlerSelect(e,"idBranch","branchCurrent");
                                 await this.changeData({
                                    keyFill: "poNo",
                                    type: "getCode",
                                    key: "code",
                                  })
                                 this.changeData({
                                    keyFill: "storeList",
                                    type: "getStore",
                                    key: "",
                                  })
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            error={this.renderError('PurchaseOrder.idBranch')}
                        />
                    </div>
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idStore'}
                            size={4}
                            onChange={(e)=>this.handlerSelect(e,"idStore","storeCurrent")}
                            options={this.state.storeList}
                            title={i18n.t("Store")}
                            value={this.state.storeCurrent}
                            placeholder={this.state.storeText}
                            error={this.renderError('PurchaseOrder.idStore')}
                        />
                    </div>
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idSupplier'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={(e)=>this.handlerSelect(e,"idSupplier","supplierCurrent")}
                            options={this.state.supplierList}
                            title={i18n.t("Supplier")}
                            value={this.state.supplierCurrent}
                            placeholder={this.state.supplierText}
                            error={this.renderError('PurchaseOrder.idSupplier')}
                        />
                    </div>
                    <div className="col-1 text-center">
                        <InputCustom
                            type={"checkbox"}
                            className={"form-check-input form-check form-check-custom form-check-solid m-zero-auto"}
                            name={"tax"}
                            title={i18n.t("Tax")}
                            value={this.state.tax}
                            checked={this.state.tax}
                            onChange={async (e)=>{
                                await this.changeData({
                                    keyFill: "taxValue",
                                    type: "getTax",
                                    key: "value",
                                  })
                                await this.setState({tax:!this.state.tax}, () => {this.reCalculate()})
                            }}
                            error={this.renderError('PurchaseOrder.tax')}
                        />
                    </div>
                    <div className='row'>
                    <div className="col-md-3">
                        <Select2
                            isClearable={true}
                            name={"typeItem"}
                            required={true}
                            onChange={async (e) =>{
                                await this.handlerSelect(e, "idItemType", "typeItemValue");
                                await this.changeData({
                                    keyFill: "itemList",
                                    type: "getItem",
                                    key: "item",
                                  })
                            }  
                            }
                            options={this.state.typeItemList}
                            title={i18n.t("Type Item")}
                            value={this.state.typeItemValue}
                            placeholder={this.state.typeItemText}
                            isDisabled = {isNewRecord?false:true}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError("PurchaseOrder.idItemType")}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <TextArea
                                name={"description"}
                                title={i18n.t("Note")}
                                value={this.state.description}
                                handler={this.handler}
                                error={this.renderError('PurchaseOrder.description')}
                            />
                        </div>
                    </div>
                </div>
                
                <br />
                <hr />
                <div className='row'>
                    <div className="col-12">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th >{i18n.t("Item")}</th>
                                    {/* <th className='text-center'>Note</th> */}
                                    <th className='text-center'>{i18n.t("Qty")}</th>
                                    <th className='text-center'>{i18n.t("Unit")}</th>
                                    <th className='text-center'>{i18n.t("Price")}</th>
                                    <th className='text-center'>{i18n.t("Discount Percent")}</th>
                                    <th className='text-center'>{i18n.t("Discount Price")}</th>
                                    <th className='text-center'>{i18n.t("Tax")}</th>
                                    <th className='text-center'>{i18n.t("Price Nett")}</th>
                                    <th className='text-center'>{i18n.t("Price Total")}</th>
                                    {
                                        (!this.state.fromRequest)?<th className='text-right'></th>:''
                                    }
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                        {(!this.state.fromRequest)?<Button variant='success' onClick={ (e)=>
                            this.addArray(
                                {
                                    itemId:'',
                                    unitId:'',
                                    itemValue:null,
                                    description:'',
                                    qty:0,
                                    unitValue:null,
                                    price:0,
                                    discountPercent:0,
                                    discountPrice:0,
                                    taxPrice:0,
                                    priceNett:0,
                                    priceTotal:0,
                                    isPr:false
                                },
                                'arrayDetail'
                            )
                            }
                        >
                            {i18n.t("Add Item")}
                        </Button>:''}
                        
                    </div>
                </div>
                <div className='row'>
                    <div className="d-flex flex-end">
                        <div className='col-4'>
                            <Table borderless responsive="sm">
                                <tbody>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Total Price")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"subTotal"}
                                                name="subTotal"
                                                placeholder={i18n.t("Sub Total")}
                                                defaultValue={0}
                                                value={this.state.subTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Total Discount")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"discountTotal"}
                                                name="discountTotal"
                                                placeholder={i18n.t("Discount Total")}
                                                defaultValue={0}
                                                value={this.state.discountTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Total Tax")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"taxTotal"}
                                                name="taxTotal"
                                                placeholder={i18n.t("Tax Total")}
                                                defaultValue={0}
                                                value={this.state.taxTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Grand Total")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"grandTotal"}
                                                name="grandTotal"
                                                placeholder={i18n.t("Grand Total")}
                                                defaultValue={0}
                                                value={this.state.grandTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </form>
           
        );
    }
}

export default Form;
export default class ConfigurationActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_CONFIG='GET_HELPER_CONFIG';
    // static GET_HELPER_Configuration='GET_HELPER_Configuration';
    // static GET_HELPERFULL_Configuration='GET_HELPERFULL_Configuration';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
  
    static submit(){
      return {
        type: ConfigurationActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: ConfigurationActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: ConfigurationActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: ConfigurationActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: ConfigurationActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: ConfigurationActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: ConfigurationActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: ConfigurationActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: ConfigurationActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: ConfigurationActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: ConfigurationActions.RESET_LIST,
      };
    }
    static helper(data){
      return {
        type: ConfigurationActions.GET_HELPER_CONFIG,
        payload:data
      };
    }
    // static helperFull(data){
    //   return {
    //     type: ConfigurationActions.GET_HELPERFULL_Configuration,
    //     payload:data
    //   };
    // }
  
    static errorInternal(error){
      return {
        type: ConfigurationActions.ERROR_SERVER,
        payload: error
      };
    }
  }
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import SalaryCuts from '../../../../components/modules/hr/salary-cuts';
import SalaryCutsMiddleware from '../../../../middleware/modules/hr/salary-cuts';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,salaryCuts }) => ({
  isProcessing: salaryCuts.isProcessing,
  isError: salaryCuts.isError,
  errorMessage: salaryCuts.errorMessage,
  datalist:salaryCuts.data,
  showModal:salaryCuts.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>SalaryCutsMiddleware.create(),
    reset: () =>SalaryCutsMiddleware.resetList(),
    search: data =>SalaryCutsMiddleware.list(data),
    update: data =>SalaryCutsMiddleware.update(data),
    delete: (id,search) =>SalaryCutsMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class AllowanceContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
   this.props.search(search);
  
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Salary Cuts List")}
          </title>
        </Helmet>
      <SalaryCuts
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceContainer);

import DistrictActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: {},
  helperCity: null,
};

function DistrictReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DistrictActions.NEW_DATA:
      return { ...state, isProcessing: true, isError: false };
    case DistrictActions.SHOW_DATA:
      return {
        ...state,
        isProcessing: false,
        isAuthenticated: true,
        isError: false,
        data: action.payload,
        errorMessage: {},
      };
    case DistrictActions.GET_HELPER_DISTRICT:
      return {
        ...state,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperDistrict: action.payload,
      };
    default:
      return state;
  }
}

export default DistrictReducer;

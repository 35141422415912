import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import VisitSchedule from '../../../../components/modules/sales/visit-schedule';
import VisitScheduleMiddelware from '../../../../middleware/modules/sales/visit-schedule';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
import Loading from '../../../../components/utilities/Loading';
// middlewares
 

const mapStateToProps = ({ auth,visitSchedule }) => ({
  isProcessing: visitSchedule.isProcessing,
  isError: visitSchedule.isError,
  errorMessage: visitSchedule.errorMessage,
  showModal:visitSchedule.showModal,
  helper:visitSchedule.helperVisitSchedule,
  data:visitSchedule.data,
  userAction:visitSchedule.userAction
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>VisitScheduleMiddelware.getHelper(),
    reset: () =>VisitScheduleMiddelware.resetList(),
    show: (data) =>VisitScheduleMiddelware.show(data),
    newEvent:(data)=>VisitScheduleMiddelware.create(data),
    view:(id)=>VisitScheduleMiddelware.view(id)
  }, dispatch);
};
class VisitScheduleContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    helper:PropTypes.any,
    userAction:PropTypes.string
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    helper:null,
    userAction:null
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    this.props.reset();
    this.props.getHelper();
  }

  render() {
    const {isProcessing,data}=this.props
    
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Visit Schedule")}
          </title>
        </Helmet>
      <VisitSchedule
        {...this.props}
      />
      {isProcessing?<Loading></Loading>:null}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitScheduleContainer);

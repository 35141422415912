import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Item from '../../../../components/modules/master/item';
import ItemMiddleware from '../../../../middleware/modules/master/item';
import UnitMiddleware from '../../../../middleware/modules/master/unit';
import ItemTypeMiddleware from '../../../../middleware/modules/master/itemtype';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import ItemBrandMiddleware from '../../../../middleware/modules/master/item-brand';
import ItemCategoryMiddleware from '../../../../middleware/modules/master/item-category';
// middlewares


const mapStateToProps = ({ auth,item }) => ({
  isProcessing: item.isProcessing,
  isError: item.isError,
  errorMessage: item.errorMessage,
  datalist:item.data,
  showModal:item.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    create: () =>ItemMiddleware.create(),
    reset: () =>ItemMiddleware.resetList(),
    getUnit: () =>UnitMiddleware.getHelper(),
    getType: () =>ItemTypeMiddleware.getHelperType(),
    getBrand: () =>ItemBrandMiddleware.getHelper(),
    getCategory: () =>ItemCategoryMiddleware.getHelper(),
    getParent: () =>ItemMiddleware.getHelper(),
    search: data =>ItemMiddleware.list(data),
    update: data =>ItemMiddleware.update(data),
    delete: (id,search) =>ItemMiddleware.beforeDelete(id,search),
  }, dispatch);
};
class ItemContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    
    this.props.getUnit()
    this.props.getType()
    this.props.getBrand()
    this.props.getCategory()
    this.props.getParent()
    this.props.update(id)
  }
  createItem(){
    this.props.create()
    this.props.getUnit()
    this.props.getType()
    this.props.getBrand()
    this.props.getCategory()
    this.props.getParent()
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Item List")}
          </title>
        </Helmet>
      <Item
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);

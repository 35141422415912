export default class PermissionActions {

    static BEFORE_ASSIGN='BEFORE_ASSIGN'
    static AFTER_ASSIGN='AFTER_ASSIGN'
    static BEFORE_REVOKE='BEFORE_REVOKE'
    static AFTER_REVOKE='AFTER_REVOKE'
    static SHOW_ASSIGN='SHOW_ASSIGN'
    static BEFORE_SHOW_ASSIGN='BEFORE_SHOW_ASSIGN'

    static beforeshowAssign(data){
      return {
        type: PermissionActions.BEFORE_SHOW_ASSIGN
      };
    }
    static showAssign(data){
      return {
        type: PermissionActions.SHOW_ASSIGN,
        payload:data
      };
    }

    static beforeAssign(){
      return {
        type: PermissionActions.BEFORE_ASSIGN
      };
    }

    static afterAssign(data){
      return {
        type: PermissionActions.AFTER_ASSIGN,
        payload:data
      };
    }

    static beforeRevoke(){
      return {
        type: PermissionActions.BEFORE_REVOKE
      };
    }

    static afterRevoke(data){
      return {
        type: PermissionActions.AFTER_REVOKE,
        payload:data
      };
    }


  


    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static GET_HELPER='GET_HELPER';




    static submit(){
      return {
        type: PermissionActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: PermissionActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: PermissionActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: PermissionActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: PermissionActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: PermissionActions.SHOW_DATA,
        payload: data
      };
    }
    static resetList() {
      return {
        type: PermissionActions.RESET_LIST
      };
    }
    static error(error){
      return {
        type: PermissionActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: PermissionActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: PermissionActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: PermissionActions.RESET_DATA,
      };
    }
    static errorInternal(error){
      return {
        type: PermissionActions.ERROR_SERVER,
        payload: error
      };
    }
    static helper(data){
        return {
          type: PermissionActions.GET_HELPER,
          payload: data
        };
      }
  }
  
  
import PostalCodeActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: {}
};

function PostalCodeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case PostalCodeActions.NEW_DATA:
    return { ...state, isProcessing: true,  isError: false};
  case PostalCodeActions.SHOW_DATA:
    return { ...state, isProcessing: false, isAuthenticated: true, isError: false, data: action.payload, errorMessage: {} };
  default:
    return state;
  }
}

export default PostalCodeReducer;

export default class ReturnPriceActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_RETURNPRICE='GET_HELPER_RETURNPRICE';
    static GET_HELPER_RETURNPRICE_FULL ='GET_HELPER_RETURNPRICE_FULL';
    static BEFORE_PRINT_RETURNPRICE ='BEFORE_PRINT_RETURNPRICE';
    static PRINT_RETURNPRICE ='PRINT_RETURNPRICE';
    static RESET_LIST="RESET_LIST";
    static APPROVE_RETURNPRICE='APPROVE_RETURNPRICE';
    static REJECT_RETURNPRICE='REJECT_RETURNPRICE';
  
    static submit(){
      return {
        type: ReturnPriceActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: ReturnPriceActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: ReturnPriceActions.UPDATE_DATA,
        payload: data
      };
    }

    static approve() {
      return {
        type: ReturnPriceActions.APPROVE_RETURNPRICE
      };
    }

    static reject() {
      return {
        type: ReturnPriceActions.REJECT_RETURNPRICE
      };
    }
  
    static view(data) {
      return {
        type: ReturnPriceActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: ReturnPriceActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: ReturnPriceActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: ReturnPriceActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: ReturnPriceActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: ReturnPriceActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: ReturnPriceActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: ReturnPriceActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: ReturnPriceActions.GET_HELPER_RETURNPRICE,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  ReturnPriceActions.GET_HELPER_RETURNPRICE_FULL,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: ReturnPriceActions.BEFORE_PRINT_RETURNPRICE,
      };
    }
    static print(data){
      return {
        type: ReturnPriceActions.PRINT_RETURNPRICE,
        payload: data
      };
    }
  }
  
  
import React, { Component, Fragment, useState } from "react";
import { Button, Spinner, Tab, Tabs, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom, faEye, faTrash, faFileExcel, faDisplay, faArrowLeftLong, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";

const MySwal = withReactContent(Swal);
export class FormPurchasingFullReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      typeRf: 2,
      supplierList: [],
      supplierCurrent: null,
      idSupplier: null,
      supplierText: i18n.t('All'),
      branchList: [],
      branchCurrent: null,
      idBranch: null,
      branchText: i18n.t('All'),
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      page: 1,
      order: null,
      supplier: null,
      item: null,
      unit: null,
      dateSearch: new Date(),
      show: 0,
      idPO: null,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      showDetail: 0,
      invoiceDetail: null,
      invoiceNo: null,
      listData: [],
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
  }
  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setDataDetail();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setDataDetail();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  setDataPO() {
    var startDate = this.state.typeRf == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.typeRf == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 10 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var order = (this.state.order == null) ? '' : this.state.order
    var supplier = (this.state.supplier == null) ? '' : this.state.supplier
    var item = (this.state.item == null) ? '' : this.state.item
    var unit = (this.state.order == unit) ? '' : this.state.unit
    var type = this.state.typeRf

    let params = {
      branch:idBranch,
      startDateRf: startDate,
      endDateRf: endDate,
      limit: limit,
      page: page,
      order: order,
      supplier: supplier,
      item: item,
      unit: unit,
      type:type
    }
    this.props.setDataPO(params);
  }

  setDataReceiving() {
    var idPO = (this.state.idPO == null) ? '' : this.state.idPO;
    let params = {
      idPO: idPO,
    }
    this.props.setDataReceiving(params);
  }

  setDataInvoice() {
    var invoice = (this.state.invoiceNo == null) ? '' : this.state.invoiceNo;
    let params = {
      invoice: invoice,
      type: 2
    }
    this.props.setInvoiceDetail(params);
  }

  showReceiving(id) {
    this.setState({ showDetail: 1, page: 1, limit: 10, idPO: id }, () => this.setDataReceiving())
  }

  showInvoice(id) {
    this.setState({ showDetail: 2, page: 1, limit: 10, invoiceNo: id }, () => this.setDataInvoice())
  }

  renderPO() {
    const { datalistPO } = this.props
    let row = datalistPO.data
    let html = [];

    let no = 1;
    let total = 0;

    if (datalistPO != undefined && datalistPO != null && row != null) {
      Swal.close();
      if (row.length > 0) {
        for (var p = 0; p < row.length; p++) {
          let deta = row[p].det.length;
          let sub = 0;

          for (var o = 0; o < deta; o++) {
            sub += row[p].det[o].total;
            let id = row[p].det[o].id_receiving;
            if (o == 0) {
              html.push(
                <tr className="border "  >
                  <td className="text-center">{no++}</td>
                  <td >{row[p].poNo}</td>
                  <td >{row[p].date}</td>
                  <td >{row[p].branch}</td>
                  <td >{row[p].supplier}</td>
                  <td >{row[p].det[o].name_item}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_format + ' ' + row[p].det[o].name_unit}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_convertion} </td>
                
                  <td >{(row[p].det[o].receivingNo == '') ? 'Not Received' :
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => this.showReceiving(id)}
                    >
                      {row[p].det[o].receivingNo}
                    </span>}
                  </td>

                  <td >{row[p].det[o].receiving_date}</td>

                  {row[p].det[o].unit_receive != null&& row[p].det[o].unit_receive.length>0 ?
                    <td style={{ textAlign: 'right' }}>
                      {row[p].det[o].unit_receive.map((item, i) =>
                      item.qty + ' ' + item.label + ',\n '
                    )}</td> : <td>-</td>}
                    <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_convertion} </td>
                    <td>{row[p].det[o].aging + i18n.t(" Days")}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_rec_remaining} </td>
                    
                </tr>)
            } else {
              html.push(
                <tr className="border " >
                  <td colSpan={'5'}></td>
                  <td >{row[p].det[o].name_item}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_format + ' ' + row[p].det[o].name_unit}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_convertion} </td>
                  
                  <td >{(row[p].det[o].receivingNo == '') ? 'Not Received' :
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => this.showReceiving(id)}
                    >
                      {row[p].det[o].receivingNo}
                    </span>}
                  </td>

                  <td >{row[p].det[o].receiving_date}</td>
                  {row[p].det[o].unit_receive != null && row[p].det[o].unit_receive.length>0 ?
                    <td style={{ textAlign: 'right' }}>{row[p].det[o].unit_receive.map((item, i) =>
                     item.qty + ' ' + item.label  + ',\n '
                    )}</td> : <td>-</td>}
                    <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_convertion} </td>
                    <td>{row[p].det[o].aging + i18n.t(" Days")}</td>
                    <td style={{ textAlign: 'right' }}>{row[p].det[o].qty_rec_remaining} </td>

                </tr>)
            }
          }
          total += parseFloat(row[p].total) || 0;
        }
      } else {
        html.push(
          <tr className="border  " >
            <td colSpan={'11'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
      return html;

    } else {
      html.push(
        <tr>
          <td colSpan={'11'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
        </tr>
      );
      return html;
    }
  }

  renderReceiving() {
    const { datalistReceiving } = this.props;
    let row = datalistReceiving
    let no = 1;
    let total = 0;
    let html = [];
    Swal.close();
    if (row != null && row != undefined && row.length > 0) {
      html.push(
        <Table className="table table-responsive table-bordered ">
          <thead style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }} >
            <tr >
              <td >
                {i18n.t('Purchase Order No. : ') + row[0].poNo}<br />
                {i18n.t('Branch : ') + row[0].branch}<br />
                {i18n.t('Supplier : ') + row[0].supplier}<br />
              </td>
              <td></td>
            </tr>
          </thead>
        </Table>
      );
      for (var p = 0; p < row.length; p++) {
        let id = row[p].id
        html.push(
          <React.Fragment>
            <Table className="table table-responsive table-bordered ">
              <thead>
              </thead>
              <tbody>

                <tr style={{ textAlign: 'left', fontSize: '16px', fontWeight: 'bold' }}>
                  <td>
                    {i18n.t('Receiving No. : ') + row[p].receivingNo}<br />
                    {i18n.t('Invoice No. : ')}

                    {row[p].transaction_no != '' ?
                      <span
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        onClick={() => this.showInvoice(id)}
                      >
                        {row[p].transaction_no}
                      </span> : i18n.t('Not Have Invoice')}<br />
                    {i18n.t('Date : ') + row[p].date}<br />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className="table table-responsive table-bordered border">
              <thead>
                <tr className="border " style={{ fontSize: '14px', fontWeight: 'medium' }}>
                  <th width={"3%"} style={{textAlign:'center'}}>{i18n.t('No.')}</th>
                  <th width={"20%"} style={{textAlign:'center'}}>{i18n.t('Warehouse')}</th>
                  <th width={"15%"} style={{textAlign:'center'}}>{i18n.t('Item Name')}</th>
                  <th width={"10%"} style={{textAlign:'center'}}>{i18n.t('Qty')}</th>
                  <th width={"10%"} style={{textAlign:'center'}}>{i18n.t('Unit')}</th>
                  <th width={"15%"} style={{textAlign:'center'}}>{i18n.t('Price')}</th>
                  <th width={"15%"} style={{textAlign:'center'}}>{i18n.t('Total')}</th>
                </tr>
              </thead>
              <tbody>
                {row[p].det.map((data, i) => (
                  <Fragment>
                    <tr className="border " key={'det' + i}>
                      <td style={{ textAlign: 'center' }}>{no++}.</td>
                      <td>{data.warehouse}</td>
                      <td>{data.item}</td>
                      <td style={{ textAlign: 'right' }}>{data.qty_format}</td>
                      <td>{data.unit}</td>
                      <td style={{ textAlign: 'right' }}>{data.price_format}</td>
                      <td style={{ textAlign: 'right' }}>{data.total_format}</td>
                    </tr>

                  </Fragment>
                ))}
                <tr className="border " style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  <td colSpan={'6'} style={{ textAlign: 'center' }}>{i18n.t('Total ')}</td>
                  <td style={{ textAlign: 'right' }}>{row[p].total_format}</td>
                </tr>

              </tbody>
            </Table>
          </React.Fragment>
        );

        html.push(
          <Table className="table table-responsive table-bordered">
          <tbody>
                {row[p].image.length > 0 ? (
                      <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                        <td>{i18n.t("Uploaded Image")}</td>
                        <td>:</td>
                        <td>
                          <PhotoGallery images={row[p].image}></PhotoGallery>
                        </td>
                      </tr>
                    ) : null}
          </tbody>

        </Table>
        );
      }

    } else {

      html.push(
        <Table className="table table-responsive table-bordered border">
          <tbody>
            <tr className="border  " >
              <td style={{ fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
            </tr>
          </tbody>

        </Table>
      );

    }
    return html;
  }

  renderInvoice() {
    const { datalistInvoiceDetail } = this.props;
    let row = datalistInvoiceDetail.detail
    let pay = datalistInvoiceDetail.payment
    let no = 1;
    let total = 0;
    let html = [];
    if (row != null) {
      for (var p = 0; p < row.length; p++) {
        html.push(
          <tr className="border">
            <td style={{ textAlign: 'center' }}>{no++}.</td>
            <td>{row[p].code_item}</td>
            <td>{row[p].name_item}</td>
            <td>{row[p].name_unit}</td>
            <td style={{ textAlign: 'right' }}>{row[p].qty_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].unit_price_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].disc_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].tax_format}</td>
            <td style={{ textAlign: 'right' }}>{row[p].total_format}</td>
          </tr>
        );
        total += parseFloat(row[p].total) || 0;
        // console.log(total)

      }
      let grand = total - datalistInvoiceDetail.discount_header
      html.push(
        <tr className="border " >
          <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Sub Total")}</td>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{total
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        </tr>)
      html.push(
        <tr className="border " >
          <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Discount")}</td>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.discount_format}</td>
        </tr>)
      html.push(
        <tr className="border " >
          <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Total Invoice")}</td>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{grand
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        </tr>
      );

      let no1 = 1;
      if (pay != null && pay.length > 0) {
        html.push(
          <tr className="border text-center" >
            <td className="text-center">{i18n.t('No.')}</td>
            <td colSpan={'3'}>{i18n.t('Payment Date')}</td>
            <td colSpan={'4'}>{i18n.t('Payment No.')}</td>
            <td></td>
          </tr>
        );
        for (var o = 0; o < pay.length; o++) {
          html.push(
            <tr className="border " >
              <td style={{ textAlign: 'center' }}>{no1++}.</td>
              <td colSpan={'3'}>{pay[o].date}</td>
              <td colSpan={'4'}>{pay[o].payNo}</td>
              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{pay[o].total_format}</td>
            </tr>
          );

        }
      }



      html.push(
        <tr className="border " >
          <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Total Payment")}</td>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.total_payment_format}</td>
        </tr>)
      html.push(
        <tr className="border " >
          <td colSpan={'8'} style={{ textAlign: "center", fontWeight: 'bold' }}>{i18n.t("Remaining Payment")}</td>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{datalistInvoiceDetail.remaining_format}</td>
        </tr>)
    }


    return html;
  }

  renderSearch() {
    return (
      <Fragment>
        <div className="row">
          <div className="row col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Order No."
              name={"order"}
              title={"Order No."}
              labelfield={'true'}
              value={this.state.order}
              handler={(e) => this.handler(e)}
            />
          </div>
          {/* <div className="col-sm-2">
         <DatePickerCustom
            name={"dateSearch"}
            title={i18n.t("Date")}
            selected={this.state.dateSearch}
            onSelect={async (e) => {
                      await this.handlerDateTime(e, 'dateSearch');
                    }}
            showYearDropdown
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            peekNextMonth
            showMonthDropdown
            onChange={(e) => { }}
            startDay={0}
            autoComplete={"off"}
            dateFormat="dd MMM yyyy"
            className="form-control form-control-sm"
             ></DatePickerCustom>
        </div> */}
          <div className="row  col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Supplier"
              name={"supplier"}
              title={"Supplier"}
              labelfield={'true'}
              value={this.state.supplier}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="row col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Item Name"
              name={"item"}
              title={"Item Name"}
              labelfield={'true'}
              value={this.state.item}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="row  col-sm-2 ">
            <Input
              type={"text"}
              className="form-control"
              placeholder="Unit"
              name={"unit"}
              title={"Unit"}
              labelfield={'true'}
              value={this.state.unit}
              handler={(e) => this.handler(e)}
            />
          </div>
          <div className="col-sm-2 ">
            <Button
              variant="danger"
              className="btn btn-sm btn btn-info mt-8"
              onClick={async (e) => {
                await this.setState({ order: '', supplier: '', item: '', unit: '' })
              }}
            >
              <FontAwesomeIcon icon={faBroom}></FontAwesomeIcon>
            </Button>
          </div>

        </div>
        <div className="row">
          <div className="col-sm-2">
            <Button onClick={async (e) => {
              await this.setState({ page: 1 }, () => this.setDataPO())
              await this.setState({ headeTable: true })
            }}
              variant="success" className="btn btn-md btn btn-info mt-4"><FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> Search </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/purchasing-report-supplier/excel",
      method: "GET",
      params: {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        idSupp: (this.state.idSupp == null) ? '' : this.state.idSupp,
        idBranch: (this.state.idBranch == null) ? '' : this.state.idBranch,


      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", 'Laporan Pembelian Per Supplier.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    if (this.state.showDetail == 0) {
      this.setState({
        [key]: value,
        [currentState]: e
      }, () => this.setDataPO()
      )
    }

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (this.state.showDetail == 0) {
      if (event.target.value == 0) {
        this.setState({ page: (get - 1) }, () => this.setDataPO());
      } else if (event.target.value == (count - 1)) {
        this.setState({ page: (get + 1) }, () => this.setDataPO());
      } else {
        this.setState({ page: event.target.value }, () => this.setDataPO())
      }
    }
  }

  renderPagination() {
    const { datalistPO } = this.props;
    var setLimit = datalistPO;

    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '10', label: '10' }
      this.state.show = 1;
    }

    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {setLimit.links != null
                ? setLimit.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == setLimit.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, setLimit.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != setLimit.links.length - 1 ? item["label"] : ""}
                      {i == setLimit.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  render() {
    const { isProcessing, helperPurchasingReport, datalistInvoiceDetail } = this.props;
    this.state.supplierList = helperPurchasingReport.supplier;
    this.state.periodList = helperPurchasingReport.workCalendar;
    this.state.branchList = helperPurchasingReport.branch;
    if (this.state.showDetail == 0) {
      return (
        <Fragment>
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body pt-3">
                  <div className="row mb-4" >
                    <div className="row">
                      <div className="col-mb-3">
                        <Radio
                          list={Period}
                          name={"typeRf"}
                          title={""}
                          inline={false}
                          value={this.state.typeRf}
                          handler={this.handlerRadio}
                        />
                      </div>
                    </div>
                    {this.state.typeRf == 1 ?
                      <Fragment>
                        <div className="col-3 mt-3">
                          <Select2
                            isClearable={true}
                            name={"period"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                              await this.handlerSelect(e, "idPeriod", "periodCurrent");
                            }}
                            options={this.state.periodList}
                            title={i18n.t("Week Period")}
                            value={this.state.periodCurrent}
                            placeholder={this.state.periodText}
                          />
                           <div className="fv-plugins-message-container invalid-feedback">
                        {this.renderError('startDateRf')}
                      </div>
                        </div>
                      </Fragment>
                      :
                      <Fragment>
                        <div className="col-3 mt-3">
                          <DatePickerCustom
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            selectsRange={true}
                            isClearable={false}
                            name={"date"}
                            title={i18n.t("Date Period")}
                            required={true}
                            onChange={(e) => {
                              const [start, end] = e;
                              this.setState({ startDate: start, endDate: end });
                            }}
                            la
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            peekNextMonth
                            showMonthDropdown
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            maxDate={new Date()}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </Fragment>
                    }
                    <div className="col-sm-3 m-3">
                      <Select2
                        isClearable={true}
                        name={"idBranch"}
                        required={true}
                        size={4}
                        onChange={async (e) => {
                          if (e == null) {
                            await this.handlerSelect(e, "idBranch", "branchCurrent");
                          } else {
                            await this.handlerSelect(e, "idBranch", "branchCurrent");
                          }
                        }}
                        options={this.state.branchList}
                        title={i18n.t("Branch")}
                        value={this.state.branchCurrent}
                        placeholder={this.state.branchText}
                        labelfield={"true"}
                      />
                    </div>
                    <div className="col text-end">
                    </div>
                    <div className="row mb-4">
                      <div className="col-sm-2">
                        <Button onClick={async (e) => {
                          await this.setState({ page: 1 }, () => this.setDataPO())
                          await this.setState({ headeTable: true })
                        }}
                          variant="success" className="btn btn-md btn btn-success mt-4"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> Show </Button>
                      </div>
                    </div>
                  </div>
                    {this.state.headeTable == true ?
                      <React.Fragment>
                        {!isProcessing ?
                          <Fragment>
                            <table className="table table-responsive table-bordered  border">
                              <thead>
                                <tr className="text-center fw-semibold fs-6 text-gray-800 bg-aquamarine square border ">
                                  <th width={"3%"} >{i18n.t("No.")}</th>
                                  <th width={"8%"}>{i18n.t("Order No.")}</th>
                                  <th width={"7%"}>{i18n.t("Date")}</th>
                                  <th width={"8%"}>{i18n.t("Branch")}</th>
                                  <th width={"8%"}>{i18n.t("Supplier")}</th>
                                  <th width={"10%"}>{i18n.t("Item")}</th>
                                  <th width={"7%"}>{i18n.t("Qty")}</th>
                                  <th width={"6%"}>{i18n.t("Convert (Pack)")}</th>
                                  <th width={"10%"}>{i18n.t("Rec No.")}</th>
                                  <th width={"7%"}>{i18n.t("RecDate")}</th>
                                  <th width={"8%"}>{i18n.t("Qty Rec")}</th>
                                  <th width={"6%"}>{i18n.t("Convert (Pack)")}</th>
                                  <th width={"6%"}>{i18n.t("Shipping Aging")}</th>
                                  <th width={"6%"}>{i18n.t("Rem (Pack)")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderPO()}
                              </tbody>
                            </table>
                            {this.renderPagination()}
                          </Fragment>
                          : this.renderLoading()
                        }
                        
                      </React.Fragment> :
                      ''}
                  </div>
                </div>
              </div>
            
        </Fragment>
      );
    } else if (this.state.showDetail == 1) {

      return (
        <Fragment>
          <div className="row mb-6">
            <div className="col-sm-2 ">
              {this.state.showDetail ? (
                <Button
                  key={"secondary"}
                  type="button"
                  variant="secondary"
                  onClick={(e) => this.setState({ showDetail: 0 })}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                  ></FontAwesomeIcon>
                </Button>
              ) : ''}
            </div>
          </div>

          {this.state.headeTable == true ?
            <React.Fragment>
              {!isProcessing ?
                this.renderReceiving() :
                this.renderLoading()
              }
            </React.Fragment> : ''}
        </Fragment>
      );

    } else if (this.state.showDetail == 2) {

      let invoiceDetail = datalistInvoiceDetail;
      Swal.close();
      return (
        <Fragment>
          <div>
            {this.state.showDetail ? (
              <Button
                key={"secondary"}
                type="button"
                variant="secondary"
                onClick={(e) =>
                  this.setState({
                    showDetail: 1,
                  })
                }
              >
                <FontAwesomeIcon
                  icon={faArrowLeftLong}
                ></FontAwesomeIcon>
              </Button>
            ) : ''}
          </div>

          <br />
          {this.state.headeTable == true ?
            <Fragment>
              {!isProcessing ?
                <Fragment>
                  {datalistInvoiceDetail.detail != null ?
                    <Fragment>
                      <Table className="table table-responsive table-bordered ">
                        <thead style={{ fontWeight: 'bold', fontSize: '15px' }}>
                          <tr className="">
                            <th>{i18n.t("Supplier Name")} :  <br />
                              {invoiceDetail.name_supplier} </th>
                            <th>{i18n.t("Branch Name")} :  <br />
                              {invoiceDetail.name_branch} </th>
                            <th>{i18n.t("Status")} :  <br />
                              {invoiceDetail.remaining < 0 ?
                                i18n.t('Paid') : i18n.t('Unpaid')
                              } </th>
                          </tr>
                          <tr className="">
                            <th>{i18n.t("Invoice No.")} : <br />
                              {invoiceDetail.no_invoice} </th>
                            <th>{i18n.t("Transaction Date")} : <br />
                              {invoiceDetail.date} </th>
                            <th>{i18n.t("Due Date")} : <br />
                              {invoiceDetail.due_date} </th>
                          </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </Table>
                      <table className="table table-responsive table-bordered  border ">
                        <thead>
                          <tr className="border text-center">
                            <th width={"3%"} className="text-center">{i18n.t("No")}</th>
                            <th width={"15%"} >{i18n.t("Item Code")}</th>
                            <th width={"15%"}>{i18n.t("Item Name")}</th>
                            <th width={"8%"}>{i18n.t("Unit")}</th>
                            <th width={"10%"}>{i18n.t("Qty")}</th>
                            <th width={"10%"}>{i18n.t("Unit Price")}</th>
                            <th width={"7%"}>{i18n.t("Discount")}</th>
                            <th width={"7%"}>{i18n.t("Tax")}</th>
                            <th width={"15%"}>{i18n.t("Total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderInvoice()}
                        </tbody>
                      </table>
                    </Fragment>
                    : <Fragment>
                      <Table className="table table-responsive table-bordered border">
                        <tbody>
                          <tr className="border  " >
                            <td style={{ fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
                          </tr>
                        </tbody>

                      </Table>
                    </Fragment>
                  }
                </Fragment> :
                this.renderLoading()}
            </Fragment>
            :
            ''

          }

        </Fragment>
      );
    }
  }
}

export default FormPurchasingFullReport;

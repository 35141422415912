import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import MutationDepositMiddleware from '../../../../middleware/modules/mutation/mutation-deposit';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import DataSupplier from "../../../../components/modules/mutation/mutation-deposit/index-supplier";
// middlewares
const mapStateToProps = ({ mutationDeposit, }) => ({
  isProcessing: mutationDeposit.isProcessing,
  isError: mutationDeposit.isError,
  errorMessage: mutationDeposit.errorMessage,
  helperList:mutationDeposit.helperMutationDeposit,
  onChoose: mutationDeposit.onChoose,
  data: mutationDeposit.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => MutationDepositMiddleware.getHelper(),
      getData: (data) => MutationDepositMiddleware.getData(data),
    },
    dispatch
  );
};
class DespositSupplierContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.getData = this.getData.bind(this);
  }

  getData() {
    this.props.getData();
  }

  componentWillMount() {
    // this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Mutation Deposit Data")}
          </title>
        </Helmet>
      <DataSupplier
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DespositSupplierContainer);

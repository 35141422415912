import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { SetupHierarchy } from '../../../../components/modules/master/setup-hierarchy';
// middlewares
import SetupHierarchyMiddleware from '../../../../middleware/modules/master/setup-hierarchy';
import Swal from 'sweetalert2';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,setuphierarchy }) => ({
  isProcessing: setuphierarchy.isProcessing,
  datalist:setuphierarchy.data,
  showModal:setuphierarchy.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    update: data =>SetupHierarchyMiddleware.update(data),
    create: () =>SetupHierarchyMiddleware.create(),
    reset: () =>SetupHierarchyMiddleware.resetList(),
    search: data =>SetupHierarchyMiddleware.list(data),
    getHelper: () =>SetupHierarchyMiddleware.getHelper(),
    delete: (idType,search) =>SetupHierarchyMiddleware.beforeDelete(idType,search)
  }, dispatch);
};
class SetupHierarchyContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
  }

  constructor(props){
    super(props)
    this.state={
      count:0
    }
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(idType,search){
    this.props.delete(idType,search)
  }
  updateItem(idType){
    this.props.update(idType)
    this.props.getHelper()
  }
  createItem(){
    this.props.create()
    this.props.getHelper()
  }

  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Setup Hierarchy List")}
          </title>
        </Helmet>
      <SetupHierarchy
        {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupHierarchyContainer);
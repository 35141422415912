import React from 'react'
import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import PermissionActions from '../../../../reducers/modules/user/permission/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button } from 'react-bootstrap';
import { Navigate,useNavigate   } from 'react-router-dom';


const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'user-access/permission'

export default class PermissionMiddleware {

  static beforeAssign(data,id){
    return (dispatch) => {
      dispatch(PermissionActions.beforeAssign());
      PermissionMiddleware.assign(dispatch, data,id);
    };
  }

  static assign(dispatch,data,id){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url:url+'/assign/'+id,
        responseType: 'json',
        data:data
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(PermissionActions.afterAssign(response.data.result));
          PermissionMiddleware.alertHendler(dispatch, response, true, 'Save Data Successfully', 'success')
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }


  static beforeRevoke(data,id){
    return (dispatch) => {
      dispatch(PermissionActions.beforeRevoke());
      PermissionMiddleware.revoke(dispatch, data,id);
    };
  }

  static revoke(dispatch,data,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url:url+'/revoke/'+id,
        responseType: 'json',
        data:data
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(PermissionActions.afterRevoke(response.data.result));
          PermissionMiddleware.alertHendler(dispatch, response, true, 'Save Data Successfully', 'success')
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }

  static showAssign(params){
    return (dispatch) => {
        dispatch(PermissionActions.beforeshowAssign());
        PermissionMiddleware.showCurrentAssign(dispatch, params);
      };
  }

  static showCurrentAssign(dispatch,id){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url:url+'/assign/'+id,
        responseType: 'json',
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(PermissionActions.showAssign(response.data.result));
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }


  static create(){
    return (dispatch) => {
      dispatch(PermissionActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      PermissionMiddleware.showOne(dispatch, params);
      // dispatch(PermissionActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(PermissionActions.resetList());
      };
  }
  static list(params){
      return (dispatch) => {
          dispatch(PermissionActions.process());
          PermissionMiddleware.showlist(dispatch, params);
        };
  }
  static reset(){
    return (dispatch) => {
        dispatch(PermissionActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(PermissionActions.view());
        PermissionMiddleware.showOne(dispatch, params);
      };
  }

  static beforeSaveNew(post,navigate){
    return (dispatch) => {
        dispatch(PermissionActions.submit());
        PermissionMiddleware.saveNew(dispatch, post,navigate);
      };
  }
  static beforeSaveUpdate(params,post,navigate){
    return (dispatch) => {
        dispatch(PermissionActions.submit());
        PermissionMiddleware.saveUpdate(dispatch, params, post,navigate);
      };
  }
  static beforeDelete(id,params){
    return (dispatch) => {
        dispatch(PermissionActions.delete());
        PermissionMiddleware.delete(dispatch,params,id);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(params=null){
    return (dispatch) => {
      PermissionMiddleware.showHelper(dispatch,params);
      };
  }

 
  

  static showlist(dispatch, params){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${PermissionMiddleware.getToken()}`
          },
          url: url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          
          if (response && response.status === 200 && response.data) {
            dispatch(PermissionActions.list(response.data.result));
          }
        })
        .catch((error) => {
          PermissionMiddleware.alertHendler(dispatch,error)
        });
  }

  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(PermissionActions.view(response.data.result));
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }

  static delete(dispatch, params,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url: url +'/'+id,
        responseType: 'json'
      })
      .then((response) => {
       
        if (response && response.status === 200 && response.data) {
          MySwal.close()
          dispatch(PermissionActions.process(),PermissionMiddleware.showlist(dispatch, params));
          PermissionMiddleware.alertHendler(dispatch,response,true,'Delete Data Successfully','success')
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error,true)
      });
  }

  static saveNew(dispatch, post,navigate){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          Swal.fire({
            title:'Success',
            html:"Create Permission Successfully",
            icon:'success',
            showConfirmButton:true,
          })
          .then((result)=>{
            if(result.isConfirmed){
              dispatch(PermissionActions.success());
              dispatch(PermissionActions.reset());
              navigate("/user/admin/permission/assign/"+response.data.result.id)
            }
        })
          
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }

  static saveUpdate(dispatch, params, post, navigate){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${PermissionMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          Swal.fire({
            title:'Success',
            html:"Update Permission Successfully",
            icon:'success',
            showConfirmButton:true,
          })
          .then((result)=>{
            if(result.isConfirmed){
              dispatch(PermissionActions.success());
              dispatch(PermissionActions.reset());
              navigate("/user/admin/permission/assign/"+response.data.result.id)
            }
        })
        }
      })
      .catch((error) => {
        PermissionMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        switch(status){
          
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(PermissionActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(PermissionActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}

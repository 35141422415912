export default class PurchaseOrderActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static CLOSE_DATA='CLOSE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_PO='GET_HELPER_PO';
  static GET_HELPER_POFULL='GET_HELPER_POFULL';
  static SUCCESS_DATA='SUCCESS_DATA';
  static APPROVE_PO='APPROVE_PO'
  static REJECT_PO='REJECT_PO'
  static PRINT_PO='PRINT_PO'
  static BEFORE_PRINT_PO='BEFORE_PRINT_PO'
  static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
  static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'
  static RESET_LIST='RESET_LIST'

  static submit(){
    return {
      type: PurchaseOrderActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: PurchaseOrderActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: PurchaseOrderActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: PurchaseOrderActions.VIEW_DATA,
      payload: data
    };
  }

  static delete() {
    return {
      type: PurchaseOrderActions.DELETE_DATA
    };
  }

  static close() {
    return {
      type: PurchaseOrderActions.CLOSE_DATA
    };
  }

  static approve() {
    return {
      type: PurchaseOrderActions.APPROVE_PO
    };
  }
  static reject() {
    return {
      type: PurchaseOrderActions.REJECT_PO
    };
  }

  static list(data) {
    return {
      type: PurchaseOrderActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: PurchaseOrderActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: PurchaseOrderActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: PurchaseOrderActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: PurchaseOrderActions.RESET_DATA,
    };
  }
  static resetList(){
    return {
      type: PurchaseOrderActions.RESET_LIST,
    };
  }
  static helper(data){
    return {
      type: PurchaseOrderActions.GET_HELPER_PO,
      payload:data
    };
  }
  static helperFull(data){
    return {
      type: PurchaseOrderActions.GET_HELPER_POFULL,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: PurchaseOrderActions.ERROR_SERVER,
      payload: error
    };
  }
  static beforePrint(){
    return {
      type: PurchaseOrderActions.BEFORE_PRINT_PO,
    };
  }
  static print(data){
    return {
      type: PurchaseOrderActions.PRINT_PO,
      payload: data
    };
  }
  static saveRequestPrint(){
    return {
      type: PurchaseOrderActions.ON_SAVE_REQUEST_PRINT,
    };
  }
  static limitPrint(){
    return {
      type: PurchaseOrderActions.ON_LIMIT_PRINT,
    };
  }
}
import React, { Component, Fragment} from "react"
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import cookieManager from "../../../../utils/cookieManager";

export default class FormChange extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form:{
                date:new Date(),
                salesList:[],
                idSales:null,
                type:0,
                salesChangeCurrent:null,
                salesCurrent:null,
                salesText:i18n.t("Select Sales"),
                branchCurrent:null,
                branchList:[],
                branchText:i18n.t("Select Branch"),
                positionCurrent:null,
                positionList:[],
                positionText:i18n.t("Select Position"),
                remark:"",
                loadingSales:false,
                periodStartList:[],
                periodEndList:[],
                periodStartCurrent:null,
                periodEndCurrent:null,

            },
        }
        this.handler=this.handler.bind(this)
        this.handlerSelect=this.handlerSelect.bind(this)
        this.handlerDateTime=this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
    // componentDidMount(){
    //     this.getSales();
    // }

    getSales(){
        const url=process.env.REST_URL +'helper/get-position-sales'
        const token= cookieManager.getUserToken();
        const params={
            branch: this.state.form.branchCurrent !=null?this.state.form.branchCurrent.value:null,
            position: this.state.form.positionCurrent !=null?this.state.form.positionCurrent.value:null,
            type:2,
        }
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            url: url,
            params:params,
            responseType: 'json'
          })
        .then((response) => {
            if (response && response.status === 200 && response.data) {
                let salesList=response.data.result
                // salesList=salesList.filter((a)=>a.value != this.state.data.idSales)
                this.setState({
                    ...this.state,
                    form:{
                        ...this.state.form,
                        salesList: salesList
                    }}
                )
            }
        })
        .catch((error) => {
            console.log('error get sales',error)
        });
    }
     handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value
            }
          
        })
    }

    handlerSelectPeriod(e, name, currentState) {
        var key = name
        var value = null
       
        if (e != null) {
          value = e.value
        }
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value,
                [currentState]:e
            }
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value,
                [currentState]:e
            }
            
        })
    }

     handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value,
            }
            
        })
    }

     renderError(name) {
        const { isError, errorMessage } =  this.props;
        var value = "";
        if (isError) {
          if (errorMessage[name] != undefined) {
            value = errorMessage[name][0];
          }
        }
        return i18n.t(value);
      }

    runSubmit=()=>{
        const post={
            branch: this.state.form.branchCurrent !=null?this.state.form.branchCurrent.value:null,
            position: this.state.form.positionCurrent !=null?this.state.form.positionCurrent.value:null,
            sales:this.state.form.salesCurrent !=null?this.state.form.salesCurrent.value:null,
            salesChange:this.state.form.salesChangeCurrent !=null?this.state.form.salesChangeCurrent.value:null,
            startDate : this.state.form.periodStartCurrent != null?this.state.form.periodStartCurrent.value:null,
            endDate : this.state.form.periodEndCurrent != null?this.state.form.periodEndCurrent.value:null
        }
        return post
    }

    
    render (){
        const {formHelperChange} = this.props
        if(formHelperChange!=null && formHelperChange != undefined){
            this.state.form.branchList = formHelperChange.branch;
            this.state.form.positionList = formHelperChange.position;
            this.state.form.periodStartList = formHelperChange.workCalendar;
            this.state.form.periodEndList = formHelperChange.workCalendar;
        }
        console.log('props',this.props)
        console.log('state',this.state)
        return (
        <>
        <div className="row">
            <h3>Sales</h3>
        </div>
        <hr/>
            <div className="row">
                <div className="col-md-12 mt-3">
                <Select2
                    isClearable={true}
                    name={"idBranch"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                        // await this.setState({:{
                        //     salesList:[],salesCurrent:null,positionCurrent:null}
                        // });
                    }}
                    options={this.state.form.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.form.branchCurrent}
                    placeholder={this.state.form.branchText}
                    error={this.renderError("idBranch")}
                />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-3">
                <Select2
                    isClearable={true}
                    name={"idPosition"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                    await this.handlerSelect(e, "idPosition", "positionCurrent");
                    await this.setState({
                        loadingSales: true, salesList:[],salesCurrent:null
                    },()=>this.getSales());
                    // this.changeData({
                    //     keyFill:"salesList",
                    //     type:"getSales",
                    //     key:"",
                    //     callback: () => { this.setState({ loadingSales: false }) }
                    // })
                    }}
                    options={this.state.form.positionList}
                    title={i18n.t("Position")}
                    value={this.state.form.positionCurrent}
                    placeholder={this.state.form.positionText}
                    error={this.renderError("idPosition")}

                />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-3">
                <Select2
                    isClearable={true}
                    name={"idSales"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                    await this.handlerSelect(e, "idSales", "salesCurrent");
                    }}
                    options={this.state.form.salesList}
                    title={i18n.t("Sales Name Before")}
                    value={this.state.form.salesCurrent}
                    placeholder={this.state.form.salesText}
                    isLoading = {this.state.form.loadingSales}
                    // error={this.renderError("SalesTarget.idBranch")}
                />
                </div>
            </div>   
            <div className="row">
                <Fragment>
                    <div className="col-sm-5 mt-3">
                        <Select2
                        isClearable={true}
                        name={"periodStartCurrent"}
                        required={true}
                        size={4}
                        onChange={async (e) => {
                            // await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                            await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
                        }}
                        options={this.state.form.periodStartList}
                        title={i18n.t("Week Period")}
                        value={this.state.form.periodStartCurrent}
                        placeholder={this.state.form.periodText}
                        />
                    </div>
                    <div style={{width:'35px'}} className="col-sm-1 mt-12">
                        {i18n.t("To")}
                    </div>
                    <div className="col-sm-5 mt-3">
                    <Select2
                        isClearable={true}
                        name={"periodEndCurrent"}
                        required={true}
                        size={4}
                        onChange={async (e) => {
                            await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
                        }}
                        options={this.state.form.periodEndList}
                        title={i18n.t(" ")}
                        value={this.state.form.periodEndCurrent}
                        placeholder={this.state.form.periodText}
                        />
                    </div>
                </Fragment>    
            </div> 
            <div className="row">
                <h3>Change</h3>
            </div>
            <hr/>  
            <div className="row">
                <div className="col-md-12 mt-3">
                <Select2
                    isClearable={true}
                    name={"idSalesChange"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                    await this.handlerSelect(e, "idSalesChange", "salesChangeCurrent");
                    }}
                    options={this.state.form.salesList}
                    title={i18n.t("Sales Name After")}
                    value={this.state.form.salesChangeCurrent}
                    placeholder={this.state.form.salesText}
                    isLoading = {this.state.form.loadingSales}
                    // error={this.renderError("SalesTarget.idBranch")}
                />
                </div>
            </div>   
            
        </>        
        )
    }
  
}
import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import PostalCodeActions from '../../../../reducers/modules/master/postalcode/actions';

export default class PostalCodeMiddleware {

    static list(params){
        return (dispatch) => {
            dispatch(PostalCodeActions.list());
            PostalCodeMiddleware.showlist(dispatch, params);
          };
    }
    static getToken(){
        return cookieManager.getUserToken();
    }

    static showlist(dispatch, params){
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${PostalCodeMiddleware.getToken()}`
            },
            url: process.env.REST_URL +'master/postalcode',
            responseType: 'json',
            params:params
          })
          .then((response) => {
           
            if (response && response.status === 200 && response.data) {
              dispatch(PostalCodeActions.list(response.data.result));
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    
//   // Signin Functions Starts
//   static signin(credentials) {
//     console.log('test ', credentials);
//     return (dispatch) => {
//       dispatch(AuthActions.signin());
//       AuthMiddleware.signinWithUserCredentials(dispatch, credentials);
//     };
//   }

//   static signinWithUserCredentials(dispatch, credentials) {
//     axios({
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       url: process.env.REST_URL +'login',
//       responseType: 'json',
//       data: {
//         username: credentials.username,
//         password: credentials.password
//       }
//     })
//     .then((response) => {
//       if (response && response.status === 200 && response.data) {
//         // self.setState({data: response.data.items})
//         if (credentials) {
//             console.log('Sing IN successfull');
//             var response=response.data;
//             cookieManager.setUserToken(response.result.accessToken);
//             dispatch(AuthActions.signinSuccessful());
//         } else {
//           console.log('Sign IN Error');
//           dispatch(AuthActions.signinRejected('error no credentials'));
//         }
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//     });
//   }
//   // Signin Functions Ends

//   // Logout Functions Starts
//   static logout() {
//     return (dispatch) => {
      
//       dispatch(AuthActions.logout());
//       AuthMiddleware.logoutFromAPI(dispatch);
//     };
//   }

//   static logoutFromAPI(dispatch) {
//     cookieManager.removeUserToken();
//     cookieManager.clearLocalStorage();
//     dispatch(AuthActions.logoutSuccessful());
//   }
//   // Logout Functions Ends

//   // isLoggedIn
//   static isLoggedIn() {
//     return (dispatch) => {
//       const token = cookieManager.getUserToken();
//       if (token) {
//         AuthMiddleware.ensureAuthenticated(dispatch, token);
//       } else {
//         // console.log('not logged in ');
//         dispatch(AuthActions.isLoggedInFailure());
//       }
//     };
//   }

//   // ensureAuthenticated
//   static ensureAuthenticated(dispatch, token) {
//     if (token) {
//       console.log('authentication successfull ');
//       dispatch(AuthActions.isLoggedInSuccess());
//     } else {
//       // never gonna happen
//       console.log('authentication error ');
//       dispatch(AuthActions.signinRejected('no token'));
//     }
//   }
}

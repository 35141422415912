import { useParams } from "react-router-dom";
import React from "react";
import JournalDetailContainer from "../../../containers/modules/accounting/journal/detail";
export const JournalDetail = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <JournalDetailContainer id={id}/>
        
    )
}

export default JournalDetail;
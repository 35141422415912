
import React,{ Component } from 'react';
import { Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import SwitchButton from '../../../utilities/Form/SwitchButton';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import axios from 'axios';
import InputNumber from '../../../utilities/Form/InputNumber';
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import InputCustom from '../../../utilities/Form/InputCustom';
import Swal from 'sweetalert2';

export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
              
            },
            typePay:null,
            type:'multiple',
            idCustomer:'',
            isUpdate:0,
            date:new Date(),
            limitDate:new Date(),
            paymentNo:'',
            branchCurrent:'',
            branchText:i18n.t('Select Branch'),
            branchList:[],
            storeCurrent:'',
            storeText:i18n.t('Select Store'),
            storeList:[],
            subTotal:0,
            totalPayment:0,
            totalRemaining:0,
            totalDP:0,
            payment:0,
            useDP:false,
            saveDP:false,
            tempDP:0,
            count:0,
            paymentTypeList: { 'Cash': i18n.t('Cash'), 'Transfer': i18n.t('Transfer'), 'BG': i18n.t('BG') },
            loadingPaymentType:false,
            paymentType:'',
            payChose:'Cash',
            refund:0,
            bankAccountList:[],
            bankAccountCurrent:null,
            bankAccountText:i18n.t('Cash/Bank Account'),
            BGDate:new Date(),
            downPayment:0,
            checkDetail:0,
            DP:0,
            maxDP:0,
            noBG:'',
            bankAccount:null,
            downpaymentUse:0,
            checkDownpayment:false,
            arrayDetail:[
            ],
        }
        this.handler = this.handler.bind(this)
        // this.handlerPayment = this.handlerPayment.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
    runSubmit(){
        const PaymentConfirmation={
            paymentNo:this.state.paymentNo,
            date:moment(this.state.date).format('YYYY-MM-DD'),
            idCustomer:this.state.idCustomer,
            paymentType:this.state.paymentType,
            BGNo:(this.state.paymentType=='BG')?this.state.noBG:'',
            BGDate:(this.state.paymentType=='BG')?moment(this.state.BGDate).format('YYYY-MM-DD'):'',
            bankAccount:(this.state.paymentType=='BG')?'':this.state.bankAccountCurrent!=null?this.state.bankAccountCurrent.value:'',
            totalPayment: this.state.totalPayment,
            payment: this.state.payment,
            totalDP : this.state.useDP == true?this.state.downPayment:0,
            downPayment: (this.state.useDP == true ? (this.state.subTotal < this.state.downPayment ? this.state.subTotal : this.state.downPayment) : 0),
            refund : (this.state.refund>0)?this.state.refund:0,
            totalCash : (this.state.totalPayment>0)?this.state.totalPayment:0,
            system : false
        }
        const detail=this.state.arrayDetail.filter((item)=>item.choose==true);
        let idInvoice=[]
        let totalInvoice=[]
        let idBranch=[]
        let idStore=[]

        for(var i=0;i<detail.length;i++){
            idInvoice.push(detail[i].invoiceID);
            totalInvoice.push(detail[i].remaining);
            idBranch.push(detail[i].branch_id);
            idStore.push(detail[i].store_id);
        }
        this.state.form.PaymentConfirmation=PaymentConfirmation
        this.state.form.PaymentConfirmationDetail={
            idInvoice:idInvoice,
            totalInvoice:totalInvoice,
            idBranch:idBranch,
            idStore:idStore,
        }
        return this.state.form
    }
    
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }

    handlerPayment(e) {
        // console.log('1')
        var key=e.target.name;
        var value=e.target.value;
        if(value == 'Cash'){
            this.state.payChose = 'Cash'
        }else{
            this.state.payChose = 'Bank'
        }
        this.setState({
            [key]:value 
        }, this.changeData({
            keyFill:"bankAccountList",
            type:"getBank",
            callback:()=>{this.setState({loadingPaymentType:false})}
          }))
    }

    handlerNumber(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        }, () => this.calculateAll())
    }

    handlerNumberDP(e, name) {
        var key = name;
        var value = e == undefined ? 0 : e;
        let DP = 0;
        if(value <= this.state.maxDP){
            DP = value;
        }else{
            this.alertError('Info','Nilai DP Melebihi Total Bill');
            DP =this.state.maxDP;
        }
        this.setState({
            name:key,
            DP:DP
        },() => this.calculateAll())
       
    }
   
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    
    calculateAll(){
        const detail = this.state.arrayDetail.filter((item) => item.choose == true)

        let subTotal = 0;
        let refund = 0;
        let total = 0;
        let payment = parseFloat(this.state.payment) || 0;
        let totalRemaining = parseFloat(this.state.totalRemaining) || 0;
        let totalPayment = parseFloat(this.state.totalPayment) || 0;
        let dp = parseFloat(this.state.downPayment) || 0

        detail.forEach((item, index) => {
            subTotal += parseFloat(item.remaining) || 0
        })

        switch (this.state.useDP) {
            case true:
                if (subTotal <= dp) {
                    totalRemaining = 0;
                    refund = payment;
                    total = subTotal + payment;
                } else {
                    totalRemaining = subTotal - (payment + dp);

                    if (totalRemaining < 0) {
                        totalRemaining = 0;
                        refund = (payment + dp) - subTotal;
                        
                    }
                    total = (payment + dp);
                }
                

                break;
            case false:
                totalRemaining = subTotal - payment;

                if (totalRemaining < 0) {
                    totalRemaining = 0;
                    refund = payment - subTotal;
                }
                total = payment;
                break;
        }

        // switch (this.state.useDP) {
        //     case true:
        //         if (subTotal <= dp) {
        //             totalRemaining = 0;
        //             refund = payment;
        //         } else {
        //             totalRemaining = totalRemaining - (payment + dp);
        //             refund = 0;
        //         }
        //         total = subTotal + payment;

        //         break;
        //     case false:
        //         totalRemaining = subTotal - payment;

        //         if (totalRemaining < 0) {
        //             totalRemaining = 0;
        //             refund = payment - subTotal;
        //         }
        //         total = payment;
        //         break;
        // }


        this.setState({
            subTotal: subTotal,
            refund: refund,
            payment: payment,
            totalPayment: total,
            totalRemaining: totalRemaining,
        })
    }

    useDP(){
        let subTotal=this.state.subTotal
        let refund=this.state.refund
        let totalRemaining=this.state.totalRemaining
        let payment=this.state.totalPayment

        let dp = parseFloat(this.state.downPayment)||0 
        if(this.state.useDP == true){
            payment=(parseFloat(payment)||0) + (parseFloat(dp)||0);
        }else{
            payment=(parseFloat(payment)||0) - (parseFloat(dp)||0);
        }
        totalRemaining=(parseFloat(subTotal)||0) - (parseFloat(payment)||0) ;
        this.setState({
            payment:payment,
            totalPayment:payment,
            totalRemaining:totalRemaining,
            refund:refund
        })
    }
    
    typeCustomer() {
        const type = this.state.typePay
        
        if (type == 'Cash') {
            this.setState({
                paymentTypeList: {
                  Cash: i18n.t("Cash"),
                }, paymentType: 'Cash'
              })
        } else if (type == 'Credit') {
            this.setState({
                paymentTypeList: {
                  Cash: { Cash:  i18n.t("Cash"), Transfer:  i18n.t("Transfer"), BG:  i18n.t("BG") },
                }, paymentType: 'Transfer'
              })
        }
    }

    alertError(title, message, icon, i) {
        Swal.fire({
            title: title,
            html: message,
            icon: icon,
        });
    }

    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    renderDetail(detail,i){
        return <React.Fragment key={"detail"+i}>
                            <tr className={(detail.choose==true)?'custom-opacity-bg':''} >
                                <td align='center'>
                                    {i+1}
                                </td>
                                <td>
                                    {detail.invoiceNo}
                                </td>
                                <td>
                                    {detail.customerName}
                                </td>
                                <td>
                                    {detail.invoiceDateDisp}
                                </td>
                                <td>
                                    {detail.dueDateDist}
                                </td>
                                <td align='right'>
                                    {detail.totalDisp}
                                </td>
                                <td align='right'> 
                                    {detail.paymentDisp}
                                </td>
                                <td align='right'>
                                    {detail.remainingDisp}
                                </td>
                                <td align='right'>
                                    <SwitchButton 
                                        labelfield={'false'} 
                                        onChange={(e)=>this.choose(e,'arrayDetail',i,'choose')}
                                        name={'choosePaymentSelling'}
                                        value={detail.choose}
                                        checked={detail.choose?true:false}
                                        className={'h-20px w-30px'}
                                    ></SwitchButton>
                                </td>
                            </tr>
            </React.Fragment>
    }
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    choose(e,array,index,key){
        const arr = [...this.state[array]];
        const objectss = arr[index]
        // objectss[key] = !objectss[key];
        let subTotal = 0;
        let count = 0;
        let DP = 0;
        let maxDP = 0;
        let remaining = parseFloat(objectss['remaining']) || 0;
        objectss[key] = !objectss[key];
        
        if (objectss[key] == false) {
            count = this.state.checkDetail - 1;
            subTotal = this.state.subTotal - remaining;
        }else {
            count = this.state.checkDetail + 1;
            subTotal = this.state.subTotal + remaining;
        }

        this.setState(arr)
       
        if(count>0){
            if(subTotal >= this.state.downPayment){
                DP =this.state.downPayment;
                maxDP =this.state.downPayment;
            }else{
                DP =subTotal;
                maxDP =subTotal;
            }
        }else{
            this.state.DP = 0; 
            this.state.useDP = false;
            DP = 0;
            maxDP = 0;
        }
        console.log(DP)
        this.setState({
            checkDetail:count,
            DP:DP,
            maxDP:maxDP,
        }, () => this.calculateAll())
        // this.setState(arr,()=>this.calculateAll())
    }

    
    
    setDP(){
        var DP = !this.state.useDP
        switch(DP){
            case true:
                if(this.state.totalPayment == 0){
                   this.state.paymentType = '';
                }
                break;
        }
        this.setState({
            useDP:DP
        },()=>this.calculateAll())
        
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var type =
                    this.state.payChose != null
                        ? this.state.payChose
                        : null;
                params = {
                    branch: branch,
                    type: type,
                }
                break;
            case 'getBranch':
                var url=process.env.REST_URL +'helper/get-branch'
            break;
            case 'getDP':
                var url = process.env.REST_URL + 'helper/get-down-payment'
                var date = ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
                var customer = this.state.idCustomer
                var branch =
                this.state.branchCurrent != null
                    ? this.state.branchCurrent.value
                    : null;
                params = {
                  date: date,
                  branch:branch,
                  customer: customer,
                }
                if (customer == null || date == null) {
                  type = null
                }
                break;
            case 'getStore':
                var url=process.env.REST_URL +'helper/get-store'
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                params={
                    id:branch
                }
            break;
            case 'getCode':
                var url=process.env.REST_URL +'helper/get-payment-code-selling'
                var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                params={
                    date:date,
                    branch:branch,
                }
            break;
            default:
                type=null
                break;
        }

        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }

    filterTagihan(){
        const {data}=this.props
        let branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
        let store=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
        if(branch !=null){
            const arr=data.details.filter((item)=>item.branch_id==branch && item.store_id==store)
            this.setState({arrayDetail:arr},()=>this.calculateAll())
        }else{
            this.setState({arrayDetail:[...data.details]},()=>this.calculateAll())
        }
    }

    render(){
        const {data,isNewRecord,type}=this.props
        console.log('PROPS',this.props)
        console.log('STATE',this.state)
        if(this.state.type != 'multiple' && this.state.count ==0){
            this.state.branchCurrent = {value:data.branch,label:''}
            this.changeData({
                keyFill:"bankAccountList",
                type:"getBank",
                callback:()=>{this.setState({loadingPaymentType:false})}
              })
              this.changeData({
                keyFill:"paymentNo",
                type:"getCode",
                key:'code',
              })
         
            this.state.count = 1;
        }
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.typePay = data.customer_type
                // this.state.paymentType = data.customer_type == 'Cash'? 'Cash':'Transfer'
                this.state.payChose = data.paymentType == 'Cash'? 'Cash':'Bank'
                this.state.type=type
                this.state.date=(data.date!=undefined)?data.date:new Date();
                this.state.limitDate=(data.limitDate!=undefined)?new Date(data.limitDate):new Date();
                this.state.idCustomer=data.customer_id
                this.state.arrayDetail=[...data.details]
             
                this.state.branchCurrent = {value:data.branch_id,label:data.branch_name}
                this.state.storeCurrent = {value:data.store_id,label:data.store_name}

                this.changeData({
                    keyFill:"bankAccountList",
                    type:"getBank",
                    callback:()=>{this.setState({loadingPaymentType:false})}
                  })
                  this.changeData({
                    keyFill:"paymentNo",
                    type:"getCode",
                    key:'code',
                  })
                  this.changeData({
                    keyFill:"downPayment",
                    type:"getDP",
                    key:'totalDP',
                  })
                  this.state.isUpdate=1
                 
            }
        }
        return (
            <React.Fragment>
            <form>
                <div className='row'>
                <div className="col-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Invoice Selling Date")}
                            required={true}
                            selected={this.state.date}
                            onSelect={async (e)=>{
                                    await this.handlerDateTime(e,'date');
                                    await this.changeData('paymentNo','getCode','code');
                                    this.changeData({
                                        keyFill:"downPayment",
                                        type:"getDP",
                                        key:'totalDP',
                                      })
                                }
                            }
                            onChange={(e)=>{}}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMMM yyyy"
                            maxDate={new Date()}
                            minDate={this.state.limitDate}
                            showYearDropdown
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            peekNextMonth
                            showMonthDropdown
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-3">
                        <Input
                            type={"input"}
                            name={"paymentNo"}
                            title={i18n.t("Invoice Selling No")}
                            required
                            value={this.state.paymentNo}
                            handler={this.handler}
                            error={this.renderError('PaymentSelling.paymentNo')}
                        />
                    </div>
                    {(this.state.type=='multiple')?
                    <React.Fragment>
                        <div className="col-3">
                            <Select2 
                                isClearable={true}
                                name={'idBranch'}
                                required={true}
                                size={4}
                                isDisabled={true}
                                onChange={async (e)=>{
                                    if(e !=this.state.branchCurrent){
                                        await this.setState({storeCurrent:null,storeList:[],BGAccountCurrent:null})
                                    }
                                    await this.handlerSelect(e,"idBranch","branchCurrent");
                                    await this.changeData('paymentNo','getCode','code');
                                    await this.changeData('storeList','getStore','');
                                    
                                }} 
                                options={this.state.branchList}
                                title={i18n.t("Branch")}
                                value={this.state.branchCurrent}
                                placeholder={this.state.branchText}
                                error={this.renderError('Invoice.idBranch')}
                            />
                        </div>
                        <div className="col-3">
                            <Select2 
                                isClearable={true}
                                name={'idStore'}
                                size={4}
                                isDisabled={true}

                                onChange={async (e)=>{
                                        if(e !=this.state.storeCurrent){
                                            await this.setState({BGAccountCurrent:null})
                                        }
                                        await this.handlerSelect(e,"idStore","storeCurrent")
                                        // await this.changeData('bankAccountList','getBank','');
                                        // await this.filterTagihan();
                                }}
                                options={this.state.storeList}
                                title={i18n.t("Store")}
                                value={this.state.storeCurrent}
                                placeholder={this.state.storeText}
                                error={this.renderError('Invoice.idStore')}
                            />
                        </div>
                    </React.Fragment>:''}
                </div>
                <br />
                <hr />
                <div className='row'>
                    <div className="col-12">
                        <Table  bordered >
                            <thead>
                                <tr className='bg-secondary'>
                                    <th className='text-center'>{i18n.t("No")}</th>
                                    <th >{i18n.t("Invoice No")}</th>
                                    <th >{i18n.t("Customer Name")}</th>
                                    <th >{i18n.t("Invoice Date")}</th>
                                    <th >{i18n.t("Due Date")}</th>
                                    <th className='text-end'>{i18n.t("Total")}</th>
                                    <th className='text-end'>{i18n.t("Total Payment")}</th>
                                    <th className='text-end'>{i18n.t("Total Remaining")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='row'>
                    <div className="d-flex flex-end">
                        <div className='col-4'>
                            <Table borderless responsive="sm">
                                <tbody>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Total Bill")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"subTotal"}
                                                name="subTotal"
                                                placeholder={i18n.t("Total Bill")}
                                                defaultValue={0}
                                                value={this.state.subTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Payment Type")}</td>
                                        <td>:</td>
                                        <td>
                                            <Radio
                                                list={this.state.paymentTypeList}
                                                name={"paymentType"}
                                                title={i18n.t("Payment Type")}
                                                inline={true}
                                                value={this.state.paymentType}
                                                handler={async(e)=>{
                                                    await this.setState({loadingPaymentType:true,bankAccountCurrent:'',noBG:'',BGDate:new Date()},()=> this.handlerPayment(e))
                                               }}
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                   
                                    {(this.state.paymentType == 'BG') ?
                                        <>
                                            <tr className="align-middle">
                                                <td>{i18n.t("BG Date")}</td>
                                                <td>:</td>
                                                <td>
                                                    <DatePickerCustom
                                                        name={"BGDate"}
                                                        title={"BG Date"}
                                                        required={true}
                                                        selected={this.state.BGDate}
                                                        onSelect={async (e) => {
                                                            await this.handlerDateTime(e, 'BGDate');
                                                        }
                                                        }
                                                        startDay={0}
                                                        autoComplete={"off"}
                                                        dateFormat="dd MMMM yyyy"
                                                        className="form-control form-control-sm"
                                                        labelfield={"false"}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="align-middle">
                                                <td><label className='label-control' htmlFor='BG No.'>{i18n.t("BG No.")}</label></td>
                                                <td>:</td>
                                                <td>
                                                    <Input
                                                        id={"noBG"}
                                                        type={"input"}
                                                        name={"noBG"}
                                                        title={i18n.t("BG No")}
                                                        required
                                                        value={this.state.noBG}
                                                        handler={(e) => {
                                                            this.handler(e)
                                                        }}
                                                        labelfield={"false"}
                                                    />

                                                </td>
                                            </tr>
                                        </>
                                        : null}
                                   
                                    { (this.state.paymentType != 'BG')?
                                    <tr className="align-middle">
                                        <td><label className='label-control required' htmlFor='CashAccount'>{i18n.t("Cash/Bank Account")}</label></td>
                                        <td>:</td>
                                        <td>
                                        <Select2 
                                            isClearable={true}
                                            name={'bankAccount'}
                                            required={true}
                                            className={""}
                                            size={4}
                                            isLoading={this.state.loadingPaymentType}
                                            onChange={async (e)=>{
                                                    await this.handlerSelect(e,"bankAccount","bankAccountCurrent");
                                                }
                                            }
                                            options={this.state.bankAccountList}
                                            title={this.state.bankAccountText}
                                            value={this.state.bankAccountCurrent}
                                            placeholder={this.state.bankAccountText}
                                            labelfield={"false"}
                                        />  
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            {this.renderError("PaymentConfirmation.bankAccount")}
                                        </div>
                                        </td>
                                    </tr>
                                    :null}
                                    <tr className="align-middle">
                                        <td><label className='label-control required' htmlFor='PaymentSelling'>{i18n.t("Payment")}</label></td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"payment"}
                                                name="payment"
                                                placeholder={i18n.t("Payment")}
                                                defaultValue={0}
                                                value={this.state.payment}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>
                                                    this.handlerNumber(value,name)
                                                    // this.setState({totalPayment:value},()=>this.calculateAll())
                                                }
                                                className='p-2 form-control form-control-xs  text-end'
                                                prefix='Rp '
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle mt-5" key={"DP"}>
                                            <td>
                                                <span className='get-inline '>
                                                    <InputCustom
                                                        type={"checkbox"}
                                                        className={
                                                            "form-check-input form-check form-check-custom form-check-solid border-check"
                                                        }
                                                        id={"dp"}
                                                        // value={this.state.useDP}
                                                        disabled={this.state.branchCurrent == null || this.state.branchCurrent == '' ? true : false}
                                                        name={"useDP"}
                                                        labelfield={"false"}
                                                        value={this.state.useDP==undefined?false:this.state.useDP}
                                                        checked={this.state.useDP==undefined?false:this.state.useDP}
                                                        // name={"useDP"}
                                                        // labelfield={"false"}
                                                        onChange={async (e) => {
                                                            if(this.state.checkDetail==0){
                                                                this.alertError('Info','Invoice Belum Dipilih!, Pilih Invoice Terlebih Dahulu');
                                                            }else{
                                                                this.setDP()
                                                            }
                                                        }}
                                                    />
                                                </span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span className='get-inline'>
                                                    <label className='label-control ' htmlFor='useDP'>{i18n.t("Down Payment")}</label>
                                                </span>
                                            </td>
                                            <td>:</td>
                                            <td>
                                                <InputNumber
                                                    id={"downPayment"}
                                                    name="DP"
                                                    placeholder={i18n.t("Down Payment")}
                                                    defaultValue={0}
                                                    value={this.state.checkDetail==0? this.state.downPayment:this.state.DP}
                                                    decimalsLimit={2}
                                                    onValueChange={async (value, name) => {
                                                        await this.handlerNumberDP(value, name)

                                                    }}
                                                    // onValueChange={(value, name) => { }}
                                                    // className='p-0 form-control form-control-xs form-control-flush text-end'
                                                    className='p-2 form-control form-control-xs  text-end'

                                                    disabled={this.state.useDP==undefined?true:this.state.useDP==false?true:false}
                                                    prefix='Rp '
                                                    labelfield={"false"}
                                                />
                                            </td>
                                        </tr>
                                    {/* <tr className="align-middle mt-5" key={"DP"}>
                                        <td>
                                            <span className='get-inline '>
                                                <InputCustom
                                                    type={"checkbox"}
                                                    className={
                                                        "form-check-input form-check form-check-custom form-check-solid border-check"
                                                    }
                                                    id={"dp"}
                                                    value={this.state.useDP}
                                                    // disabled={this.state.branchCurrent==null||this.state.branchCurrent==''?true:false}
                                                    name={"useDP"}
                                                    labelfield={"false"}
                                                    onChange={
                                                        () => this.setDP()
                                                    }
                                                />
                                            </span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className='get-inline'>
                                                <label className='label-control ' htmlFor='useDP'>{i18n.t("Down Payment")}</label>
                                            </span>  
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"downPayment"}
                                                name="downPayment"
                                                placeholder={i18n.t("Down Payment")}
                                                defaultValue={0}
                                                value={this.state.downPayment}
                                                decimalsLimit={2}
                                                onValueChange={(value, name) => { }}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr> */}
                                    <tr className="align-middle">
                                        <td>{i18n.t("Remaining Payment")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"totalRemaining"}
                                                name="totalRemaining"
                                                placeholder={i18n.t("Remaining Payment")}
                                                defaultValue={0}
                                                value={this.state.totalRemaining}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Refund")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"refund"}
                                                name="refund"
                                                placeholder={i18n.t("Refund")}
                                                defaultValue={0}
                                                value={this.state.refund}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </form>
           
           </React.Fragment>
        );
    }
}

export default Form;
export default class PaymentSellingActions {
    static BEFORE_PAYMENTSELLING='BEFORE_PAYMENTSELLING'
    static AFTER_PAYMENTSELLING='AFTER_PAYMENTSELLING'
    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_PAYMENTSELLING='GET_HELPER_PAYMENTSELLING';
    static GET_HELPER_PAYMENTSELLING_FULL ='GET_HELPER_PAYMENTSELLING_FULL';
    static RESET_LIST ='RESET_LIST';
    
    static beforePaymentSelling(dataPaymentSelling){
      return {
        type: PaymentSellingActions.BEFORE_PAYMENTSELLING,
        payload: dataPaymentSelling
      };
    }
    static afterPaymentSelling(dataPaymentSelling){
      return {
        type: PaymentSellingActions.AFTER_PAYMENTSELLING,
        payload: dataPaymentSelling
      };
    }
    static submit(){
      return {
        type: PaymentSellingActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: PaymentSellingActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: PaymentSellingActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: PaymentSellingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: PaymentSellingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: PaymentSellingActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: PaymentSellingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: PaymentSellingActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: PaymentSellingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: PaymentSellingActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: PaymentSellingActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: PaymentSellingActions.GET_HELPER_PAYMENTSELLING,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  PaymentSellingActions.GET_HELPER_PAYMENTSELLING_FULL,
        payload:data
      };
    }
  }
  
  
import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';


export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                name:'',
                status:1,
                code:''
            },
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    runSubmit(){
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){
        const {data,isNewRecord}=this.props        
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form.name=data.name,
                this.state.form.code=data.code,
                this.state.form.status=data.status
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                <Input
                    type={"input"}
                    name={"code"}
                    title={i18n.t("Code")}
                    required
                    value={this.state.form.code}
                    handler={this.handler}
                    error={this.renderError('code')}
                />
                <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.form.name}
                    handler={this.handler}
                    error={this.renderError('name')}
                />
                
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.form.status}
                    handler={this.handler}
                    error={this.renderError('status')}
                />
            </form>
        );
    }
}

export default Form;
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import PrintCashFLow from "../../../components/utilities/print/default/cash-flow";
import CashFlowMiddleware from "../../../middleware/modules/report/cash-flow";

const mapStateToProps = ({ auth, cashFlow }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: cashFlow.isProcessing,
  dataPrint: cashFlow.printCashFlow,
  requestPrint:cashFlow.requestPrintcashFlow
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (data) => CashFlowMiddleware.getPrint(data),
    },
    dispatch
  );
};

class CashFlowContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.data);
  }

  render() {
   
    return <PrintCashFLow {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFlowContainer);

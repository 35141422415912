export default class SalesPerformanceChartActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static SHOW_DATA_TABLE='SHOW_DATA_TABLE';
  static SHOW_DATA_TARGET='SHOW_DATA_TARGET';
  static SHOW_DATA_DISTRIBUTION='SHOW_DATA_DISTRIBUTION';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_SALESPERFORMANCE_CHART='GET_HELPER_SALESPERFORMANCE_CHART';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static VOID_DATA="VOID_DATA";
  static SHOW_DATA_MONITOR="SHOW_DATA_MONITOR";

  static submit(){
    return {
      type: SalesPerformanceChartActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: SalesPerformanceChartActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: SalesPerformanceChartActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: SalesPerformanceChartActions.VIEW_DATA,
      payload: data
    };
  }
  
  static delete(data) {
    return {
      type: SalesPerformanceChartActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: SalesPerformanceChartActions.SHOW_DATA,
      payload: data
    };
  }
  static listTable(dataTable) {
    return {
      type: SalesPerformanceChartActions.SHOW_DATA_TABLE,
      payload: dataTable
    };
  }
  static listTarget(dataTarget) {
    console.log('asdasdad')
    return {
      type: SalesPerformanceChartActions.SHOW_DATA_TARGET,
      payload: dataTarget
    };
  }
  static listDistribution(dataDistribution) {
    return {
      type: SalesPerformanceChartActions.SHOW_DATA_DISTRIBUTION,
      payload: dataDistribution
    };
  }
  static listMonitor(dataMonitor) {
    return {
      type: SalesPerformanceChartActions.SHOW_DATA_MONITOR,
      payload: dataMonitor
    };
  }
  static error(error){
    return {
      type: SalesPerformanceChartActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: SalesPerformanceChartActions.SUCCESS_DATA,
    };
  }
  static void(data) {
    return {
      type: SalesPerformanceChartActions.VOID_DATA,
      payload: data
    };
    
  }
  static process(){
    return {
      type: SalesPerformanceChartActions.PROCESS_DATA,
    };
  }
  static resetList(){
    return {
      type: SalesPerformanceChartActions.RESET_LIST,
    };
  }
  static reset(){
    return {
      type: SalesPerformanceChartActions.RESET_DATA,
    };
  }
  static helper(helperSalesChart){
    return {
      type: SalesPerformanceChartActions.GET_HELPER_SALESPERFORMANCE_CHART,
      payload:helperSalesChart
    };
  }
  static helperFull(data){
    return {
      type: SalesPerformanceChartActions.GET_HELPERFULL_SalesPerformanceChart,
      payload:data
    };
  }

  static errorInternal(error){
    return {
      type: SalesPerformanceChartActions.ERROR_SERVER,
      payload: error
    };
  }
}
export default class MutationActions {
    static BEFORE_CHOOSE='BEFORE_CHOOSE'
    static AFTER_CHOOSE='AFTER_CHOOSE'
    static PROCESS_CHOOSE='PROCESS_CHOOSE'
    static ERROR_CHOOSE='ERROR_CHOOSE'
    static ERROR_DATA='ERROR_DATA'
    static AFTER_CHOOSE_ITEM='AFTER_CHOOSE_ITEM'
    static ERROR_CHOOSE_ITEM='ERROR_CHOOSE_ITEM'
    static PROCESS_CHOOSE_ITEM='PROCESS_CHOOSE_ITEM'
    static BEFORE_CHOOSE_ITEM='BEFORE_CHOOSE_ITEM'
    static GET_HELPER_MUTATION='GET_HELPER_MUTATION';
    static PRINT_STOCK_CARD='PRINT_STOCK_CARD'
    static BEFORE_PRINT_STOCK_CARD='BEFORE_PRINT_STOCK_CARD'
    static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
    static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'
  
    static errorChoose(data){
      return {
        type:MutationActions.ERROR_CHOOSE,
        payload:data
      }
    }
    static error(error){
      return {
        type: MutationActions.ERROR_DATA,
        payload: error
      };
    }
    static beforeChooseItem(){
      return {
        type:  MutationActions.BEFORE_CHOOSE_ITEM,
      };
    }
    static processChooseItem(){
      return {
        type:  MutationActions.PROCESS_CHOOSE_ITEM,
      };
    }
    static afterChoose(data){
      return {
        type:  MutationActions.AFTER_CHOOSE,
        payload:data
      };
    }

    static errorChooseItem(dataItem){
      return {
        type:MutationActions.ERROR_CHOOSE_ITEM,
        payload:dataItem
      }
    }
    static beforeChoose(){
      return {
        type:  MutationActions.BEFORE_CHOOSE,
      };
    }
    static processChoose(){
      return {
        type:  MutationActions.PROCESS_CHOOSE,
      };
    }
    static afterChooseItem(dataItem){
      return {
        type:  MutationActions.AFTER_CHOOSE_ITEM,
        payload:dataItem
      };
    }

    

    static helper(data){
      return {
        type: MutationActions.GET_HELPER_MUTATION,
        payload:data
      };
    }

    static beforePrint(){
      return {
        type: MutationActions.BEFORE_PRINT_STOCK_CARD,
      };
    }
    static print(data){
      return {
        type: MutationActions.PRINT_STOCK_CARD,
        payload: data
      };
    }
    static saveRequestPrint(){
      return {
        type: MutationActions.ON_SAVE_REQUEST_PRINT,
      };
    }
    static limitPrint(){
      return {
        type: MutationActions.ON_LIMIT_PRINT,
      };
    }
  }
  
  
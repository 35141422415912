import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Input from "../../../utilities/Form/input";
import TextArea from "../../../utilities/Form/TextArea";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
export class CreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form:{
        name:'',
        description:''
      },
      isUpdate:0,
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: '/user/admin', title: i18n.t("Admin"), class: " text-gray-600" },
        { link: '/user/admin/role', title: i18n.t("Roles"), class: " text-gray-600" },
        {
          link: null,
          title: i18n.t("Set Assignment"),
          class: " text-gray-500",
        },
      ],
    };
    this.handler=this.handler.bind(this)
  }
  handler(e) {
    var key = e.target.name;
    var value = e.target.value
    this.setState({
        ...this.state,
        form: {
            ...this.state.form,
            [key]: value
        }
    })
}
componentDidMount(){
  const { typeUrl,id } = this.props;
    switch (typeUrl) {
    case 'update':
      this.props.update(id)
      break;
    }
  
}
componentWillUnmount(){
  this.props.reset()
}
runSubmit(){
  const {isNewRecord,id,navigate}=this.props
  let data={...this.state.form}
  if(isNewRecord){
    this.props.saveNew(data,navigate);
  }else{
    this.props.saveUpdate(id,data,navigate);
  }
}
renderError(name) {
  const { isError, errorMessage } = this.props;
  var value = "";
  if (isError) {
    if (errorMessage[name] != undefined) {
      value = errorMessage[name][0];
    }
  }
  return i18n.t(value);
}
  render() {
    const { data ,isNewRecord} = this.props;
    if(Object.keys(data).length >0 && this.state.isUpdate==0 && !isNewRecord){
      this.state.form.name=data.name
      this.state.form.description=data.description
      this.state.isUpdate=1
    }
    return (
      <div id="role-form" className="d-flex flex-column flex-column-fluid">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="d-flex flex-column flex-xl-row">
              <div className="w-100 flex-lg-row-auto w-lg-300px mb-7 me-7 me-lg-10">
                <div className="card card-flush py-4"><div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin"}>
                          <span className="menu-title fw-bold">Assignment</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin/route"}>
                          <span className="menu-title fw-bold">Route</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link"
                          to={"/user/admin/permission"}
                        >
                          <span className="menu-title fw-bold">Permission</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link active"
                          to={"/user/admin/role"}
                        >
                          <span className="menu-title fw-bold">Roles</span>
                        </Link>
                      </div>
                    </div>
                  </div></div>
              </div>
                <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                  <div className="card card-flush py-4">
                    <div className="card-header d-flex justify-content-end border-0 pt-6">
                      <h3>
                      {(isNewRecord)?'Create Role':('Update Role : ' +data.name)}
                      </h3>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row">
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                          />
                          <TextArea
                              name={"description"}
                              title={i18n.t("Description")}
                              value={this.state.form.description}
                              handler={this.handler}
                              error={this.renderError("description")}
                            />
                          <Button 
                            variant={(isNewRecord)?'primary':'success'}
                            className={"btn btn-sm"}
                            onClick={(e)=>{
                              this.runSubmit()
                            }}
                          >{(isNewRecord)?'Save':'Update'}</Button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateForm;

import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import DownPaymentActions from "../../../../reducers/modules/accounting/down-payment/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "accounting/down-payment";

export default class DownPaymentMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(DownPaymentActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(DownPaymentActions.update());
      DownPaymentMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(DownPaymentActions.process());
      DownPaymentMiddleware.showlist(dispatch, params);
    };
  }

  static reset() {
    return (dispatch) => {
      dispatch(DownPaymentActions.reset());
    };
  }

  static resetList() {
    return (dispatch) => {
      dispatch(DownPaymentActions.resetList());
    };
  }

  static getOne(params) {
    return (dispatch) => {
      dispatch(DownPaymentActions.view());
      DownPaymentMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(DownPaymentActions.submit());
      DownPaymentMiddleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(DownPaymentActions.submit());
      DownPaymentMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(DownPaymentActions.delete());
      DownPaymentMiddleware.delete(dispatch, params, id);
    };
  }

  static beforeVoid(id, params) {
    return (dispatch) => {
      dispatch(DownPaymentActions.void());
      DownPaymentMiddleware.voidData(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      DownPaymentMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull() {
    return (dispatch) => {
      DownPaymentMiddleware.showHelperFull(dispatch);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(DownPaymentActions.list(response.data.result));
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error);
      });
  }

  static showHelperFull(dispatch) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-DownPayment-full",
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(DownPaymentActions.helperFull(response.data.result));
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error);
      });
  }

  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-dp",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(DownPaymentActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            DownPaymentActions.process(),
            DownPaymentMiddleware.showlist(dispatch, params)
          );
          DownPaymentMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static voidData(dispatch, params, id, ) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: url + "/void/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            DownPaymentActions.process(),
            DownPaymentMiddleware.showlist(dispatch, params)
          );
          DownPaymentMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            DownPaymentActions.process(),
            DownPaymentMiddleware.showlist(dispatch, search)
          );
          dispatch(DownPaymentActions.success());
          DownPaymentMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Confirm Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${DownPaymentMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            DownPaymentActions.process(),
            DownPaymentMiddleware.showlist(dispatch, search)
          );
          dispatch(DownPaymentActions.success());
          DownPaymentMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        DownPaymentMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              console.log(data)
              MySwal.hideLoading();
              MySwal.update({
                title: "Error",
                html: i18n.t(data.message),
                icon: "error",
                showConfirmButton: true,
              })
              const search = {limit: 10}
                dispatch(
                  DownPaymentActions.process(),
                  DownPaymentMiddleware.showlist(dispatch, search)
                );
          
            } else {
              const data = result.response.data;
              dispatch(DownPaymentActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(DownPaymentActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

import React, { useEffect } from "react";
import { Button, Modal  } from "react-bootstrap";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import Content from "./content";

export default function ModalCustom(props){
    const {showModal,titleModal,onDismiss,loading,dataDetails}=props
    
    const loadingDiv=()=>{
        return(
            <div className="content-center">
                <div className="text-center">
                <div className="spinner-border  text-primary clearfix" style={{width:'3rem',height:'3rem'}} role="status"></div>
                <div className="sr-only">{i18n.t("Loading")}</div>
                </div>
            </div>
        )
    }
    return (
        <Modal show={showModal} size={"lg"}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
            <title>{titleModal}</title>
          </Helmet>
          <Modal.Body>
            {loading && loadingDiv()}
            {!loading && 
                <Content
                    {...dataDetails}
                ></Content>
            }
          </Modal.Body>
          <Modal.Footer className={"justify-content-end"}>
            <Button variant="secondary" onClick={() => onDismiss()}>
                {i18n.t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
    )
}
import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import MutationActions from "../../../../reducers/modules/mutation/mutation/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "mutation";

export default class MutationMiddleware {
  static getToken() {
    return cookieManager.getUserToken();
  }
  static getData(params) {
    return (dispatch) => {
      dispatch(MutationActions.beforeChoose());
      dispatch(MutationActions.processChoose());
      MutationMiddleware.showData(dispatch, params);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(MutationActions.beforePrint());
      MutationMiddleware.showPrint(dispatch, params);
    };
  }

  static getDataItem(params) {
    return (dispatch) => {
      dispatch(MutationActions.beforeChooseItem());
      dispatch(MutationActions.processChooseItem());
      MutationMiddleware.showDataItem(dispatch, params);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      MutationMiddleware.showHelper(dispatch, params);
    };
  }
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MutationMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-mutation',
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(MutationActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        MutationMiddleware.alertHendler(dispatch,error)
      });
  }
  static showData(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationMiddleware.getToken()}`,
      },
      url: url+'/show',
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          console.log('result',response.data.result)
          dispatch(MutationActions.afterChoose(response.data.result));
        }
      })
      .catch((error) => {
        MutationMiddleware.alertHendler(dispatch, error);
      });
  }

  static showDataItem(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationMiddleware.getToken()}`,
      },
      url: url+'/show-item',
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          console.log('result',response.data.result)
          dispatch(MutationActions.afterChooseItem(response.data.result));
        }
      })
      .catch((error) => {
        MutationMiddleware.alertHendler(dispatch, error);
      });
  }

  static showPrint(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MutationMiddleware.getToken()}`,
      },
      url: url + "/stock-card/pdf",
      responseType: "json",
      data:params
      // method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${PurchaseOrderMiddleware.getToken()}`,
      // },
      // url: process.env.REST_URL + "log/print/request",
      // responseType: "json",
      // data:params
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MutationActions.print(response.data.result));
        }
      })
      .catch((error) => {
        const data=error.response.data;
        switch(data.message){
          case 'Limit':
            dispatch(MutationActions.limitPrint());
            const html=<><div>{i18n.t("Enter your message")}</div></>
            MySwal.fire({  
              title:i18n.t("Request to Print"),
              icon:false,
              html:html,
              input: 'textarea',
              inputValue: '',
              showCancelButton: false,  
              showConfirmButton: true,  
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                  if (!value) {
                  return i18n.t("You need to write something!")
                  }
              },
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    const value= result.value
                    MySwal.fire({
                        title:i18n.t('Proccessing Data'),
                        html:i18n.t('Please Wait ....'),
                        icon:'info',
                    })
                    MySwal.showLoading()
                    MutationMiddleware.sendRequest(dispatch,value,params,data.error[1],data.error[2]);
                }
            });   
            return;
          break;
          case 'Reject':
            dispatch(MutationActions.limitPrint());
            const htmlreject=<><div className="text-danger">{i18n.t("Your Request Before is Rejected reason :")} <div dangerouslySetInnerHTML={{__html:data.error[0]}}></div><p className="m-0">{data.error[1]}</p>{i18n.t("Please Create a new Request")}</div><div>{i18n.t("Enter your message")}</div></>
            MySwal.fire({  
              title:i18n.t("Request to Print"),
              icon:false,
              html:htmlreject,
              input: 'textarea',
              inputValue: '',
              showCancelButton: false,  
              showConfirmButton: true,  
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                  if (!value) {
                  return i18n.t("You need to write something!")
                  }
              },
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                    const value= result.value
                    MySwal.fire({
                        title:i18n.t('Proccessing Data'),
                        html:i18n.t('Please Wait ....'),
                        icon:'info',
                    })
                    MySwal.showLoading()
                    MutationMiddleware.sendRequest(dispatch,value,params,data.error[1],data.error[2]);
                }
            });   
            return;
            break;
          case 'Waiting':
            dispatch(MutationActions.saveRequestPrint());
            return;
          case 'Not Found':
              dispatch(MutationActions.limitPrint());
              MySwal.fire({
                title:i18n.t('Data Not Found'),
                html:i18n.t('we cant found your request'),
                icon:'error',
            }).then(()=>{
              window.close()
            })
              return;
            break;
            default:
              MutationMiddleware.alertHendler(dispatch, error);
              break;
        }
        
      });
  }


  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(MutationActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(MutationActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

import SellingActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSelling:null,
  sellingHelperFull:null,
  printSelling:null
};

function SellingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SellingActions.RESET_LIST:
      return INITIAL_STATE;
    case SellingActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SellingActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case SellingActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SellingActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SellingActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SellingActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case SellingActions.CANCEL_DATA:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    case SellingActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SellingActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SellingActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case SellingActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SellingActions.GET_HELPER_SELLING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperSelling:action.payload};
    case SellingActions.GET_HELPER_SELLING_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,SellingHelperFull:action.payload};
    case SellingActions.BEFORE_PRINT_SELLING:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printSelling:null,isProcessing:true};
    case SellingActions.PRINT_SELLING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printSelling:action.payload};
    
    default:
      return state;
    }
}


export default SellingReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { MemoIn } from "../../../../components/modules/accounting/memo-in";
import MemoInMiddleware from "../../../../middleware/modules/accounting/memo-in";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, memoin }) => ({
  isProcessing: memoin.isProcessing,
  isError: memoin.isError,
  errorMessage: memoin.errorMessage,
  datalist: memoin.data,
  getHelper: memoin.getHelper,
  showModal: memoin.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => MemoInMiddleware.create(),
      getHelper: () => MemoInMiddleware.getHelper(),
      changeStatus: (data) => MemoInMiddleware.beforeChangeStatus(data),
      reset: () => MemoInMiddleware.resetList(),
      search: (data) => MemoInMiddleware.list(data),
      update: (data) => MemoInMiddleware.update(data),
      cancel: (id, search) => MemoInMiddleware.beforeCancel(id, search),
      approve: (id, value, search) => MemoInMiddleware.beforeApprove(id, value, search),
      reject: (id, value,type, search) => MemoInMiddleware.beforeReject(id, value,type, search),
    },
    dispatch
  );
};
class MemoInContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.cancelItem = this.cancelItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  cancelItem(id, search) {
    this.props.cancel(id, search);
  }

  updateItem(id) {
    this.props.getHelper();
    this.props.update(id);
  }

  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value,type, search) {
    this.props.reject(id, value,type, search);
  }

  changeItem(data) {
    this.props.changeStatus(data);
  }
  createItem() {
    this.props.getHelper();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Memo In List")}</title>
        </Helmet>
      <MemoIn
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        cancelItem={this.cancelItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        changeItem={this.changeItem}
        approveItem={this.approveItem}
        rejectItem={this.rejectItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoInContainer);

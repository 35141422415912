import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import CustomerPerformanceMiddleware from "../../../../middleware/modules/chart/customer-performance";
import CustomerPerformanceShow from "../../../../components/modules/chart/customer-performance/index-show";
// middlewares

const mapStateToProps = ({ auth, customerPerformance }) => ({
  isProcessing: customerPerformance.isProcessing,
  isError: customerPerformance.isError,
  errorMessage: customerPerformance.errorMessage,
  dataList: customerPerformance.data,
  helperCustomerPerformance: customerPerformance.helperCustomerPerformance,
  afterSave: customerPerformance.afterSave,
  showModal: customerPerformance.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => CustomerPerformanceMiddleware.getHelper(),
      setData: (data) => CustomerPerformanceMiddleware.setData(data),
      reset: () => CustomerPerformanceMiddleware.resetList(),
      search: (data) => CustomerPerformanceMiddleware.list(data),
    },
    dispatch
  );
};
class CustomerPerformanceShowContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,

  };

  static defaultProps = {
    afterSave: false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.setData = this.setData.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper();
  }

  setData(params) {
    this.props.setData(params);
  }

  render() {
    const { isProcessing, helperCustomerPerformance } =
      this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Customert Performance Show")}</title>
        </Helmet>
        <CustomerPerformanceShow
          {...this.props}
          createData={this.createItem}
          voidItem={this.voidItem}
          helperCustomerPerformance={helperCustomerPerformance}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPerformanceShowContainer);

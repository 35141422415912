import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import MemoOutMiddleware from "../../../middleware/modules/accounting/memo-out";
import PrintMemoOut from "../../../components/utilities/print/default/memo-out";

const mapStateToProps = ({ auth, memoout }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  dataPrint: memoout.printMemoOut,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (id) => MemoOutMiddleware.getPrint(id),
    },
    dispatch
  );
};

class MemoOutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.id);
  }

  render() {
    const {
      isAuthenticated,
      isProcessing,
      isRegistered,
      isError,
      errorMessage,
    } = this.props;
    return <PrintMemoOut {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoOutContainer);

import PurchasingReportActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataSupp: {},
  dataSuppDetail:{},
  dataItem:{},
  dataInvoiceDetail:{},
  dataReceiving:{},
  dataPO:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperPurchasingReport:[],
  errorInternal:false,
 
};

function PurchasingReportReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PurchasingReportActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{},dataSupp:{}};
    case PurchasingReportActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case PurchasingReportActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case PurchasingReportActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case PurchasingReportActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchasingReportActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchasingReportActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case PurchasingReportActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PurchasingReportActions.SHOW_DATA_SUPP:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataSupp: action.payload, errorMessage: [],isProcessing:false }; 
    case PurchasingReportActions.SHOW_DATA_SUPP_DETAIL:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataSuppDetail: action.payload, errorMessage: [],isProcessing:false };   
    case PurchasingReportActions.SHOW_DATA_INVOICE_DETAIL:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataInvoiceDetail: action.payload, errorMessage: [],isProcessing:false };   
    case PurchasingReportActions.SHOW_DATA_ITEM:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataItem: action.payload, errorMessage: [],isProcessing:false };   
    case PurchasingReportActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PurchasingReportActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PurchasingReportActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case PurchasingReportActions.GET_HELPER_PURCHASINGREPORT:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperPurchasingReport:action.payload};
    case PurchasingReportActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false, errorMessage: action.payload, isProcessing:false };
    case PurchasingReportActions.SHOW_DATA_FROM_PO:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataPO: action.payload, errorMessage: [],isProcessing:false };
    case PurchasingReportActions.SHOW_DATA_FROM_RECEIVING:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, dataReceiving: action.payload, errorMessage: [],isProcessing:false };    
    default:
      return state;
    }
}

export default PurchasingReportReducer;

import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { Tab, Tabs } from "react-bootstrap";
import DepositSupplier from "../../../../containers/modules/mutation/mutation-deposit/deposit-supplier";
import DepositCustomer from "../../../../containers/modules/mutation/mutation-deposit/deposit-customer";

const MySwal = withReactContent(Swal);
export class MutationDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      listDataItem: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), className: " text-gray-600" },
        { link: null, title: i18n.t("Mutation Deposit"), className: " text-gray-500" },
      ],
      columns: [
      ],
    };
  }

  render() {
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                <Tabs
                  defaultActiveKey="2"
                  id={"tabs"}
                  className="mb-3"
                >
                  <Tab eventKey="1" title={i18n.t("Supplier")}>
                    <DepositSupplier
                      {...this.props}

                    />
                  </Tab>
                  <Tab eventKey="2" title={i18n.t("Customer")}>
                    <DepositCustomer
                      {...this.props}

                    />
                  </Tab>
                </Tabs>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default MutationDeposit;

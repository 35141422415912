import VisitScheduleActions from "./actions";

const INITIAL_STATE = {
  data: [],
  isProcessing: false,
  isNewRecord: false,
  showModal: false,
  showModalChange: false,
  onSubmit: false,
  isError: false,
  errorMessage: [],
  errorInternal: false,
  helperVisitSchedule: null,
  formHelper:null,
  formHelperChange:null,
  userAction:null,
  viewData:null,
};

function VisitScheduleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VisitScheduleActions.AFTER_SAVE_UPDATE:
      return { 
        ...state,
        isError: false,
        onSubmit:false,  
        errorMessage: [],
        isProcessing:false,
        showModal:false,
        viewData:null
       };
    case VisitScheduleActions.BEFORE_SAVE_UPDATE:
        return { 
          ...state,
          isError: false,
          onSubmit:true,  
          errorMessage: [],
          isProcessing:true
         };
    case VisitScheduleActions.AFTER_SAVE_CHANGE:
      return { 
        ...state,
        isError: false,
        onSubmit:false,  
        errorMessage: [],
        isProcessing:false,
        showModalChange:false,
        viewData:null
       };
      case VisitScheduleActions.BEFORE_SAVE_CHANGE:
        return { 
          ...state,
          isError: false,
          onSubmit:true,  
          errorMessage: [],
          isProcessing:true
         };
    case VisitScheduleActions.SHOW_DATA:
      return { 
        ...state,
        data:  action.payload,
        isError: true,
        onSubmit:false,  
        errorMessage: [],
        isProcessing:false
       };
    case VisitScheduleActions.SHOW_DATA:
      return { 
        ...state,
        data:  action.payload,
        isError: true,
        onSubmit:false,  
        errorMessage: [],
        isProcessing:false
       };
    case VisitScheduleActions.SHOW_DATA:
      return { 
        ...state,
        data:  action.payload,
        isError: true,
        onSubmit:false,  
        errorMessage: [],
        isProcessing:false
       };
    case VisitScheduleActions.ERROR_DATA:
      return { 
        ...state,  
        isError: true,
        onSubmit:false,  
        errorMessage: action.payload,
        isProcessing:false
       };
    case VisitScheduleActions.RESET_LIST:
      return {
        data: null,
        isProcessing: true,
        isNewRecord: false,
        showModal: false,
        onSubmit: false,
        isError: false,
        errorMessage: [],
        errorInternal: false,
        helperVisitSchedule: null,
        formHelper:null,
        formHelperChange:null,

      };
    case VisitScheduleActions.PROCESS_DATA:
      return {
        ...state,
        isProcessing: true,
      }
    case VisitScheduleActions.FORM_HELPER:
      return {
        ...state,
        isProcessing: false,
        showModal:true,
        formHelper:action.payload,
        onSubmit:false,
      }
    case VisitScheduleActions.FORM_HELPER_CHANGE:
      return {
        ...state,
        isProcessing: false,
        showModalChange:true,
        formHelperChange:action.payload,
        onSubmit:false,
      }
    case VisitScheduleActions.NEW_DATA:
      return {
        ...state,
        userAction:'create',
        isProcessing: true,
        formHelper:null,
        isNewRecord:true
      }
    case VisitScheduleActions.CHANGE_DATA:
      return {
        ...state,
        // userAction:'create',
        isProcessing: true,
        formHelperChange:null,
        isNewRecord:true,
        showModalChange:true,
        showModal:false
      }
    case VisitScheduleActions.VIEW_DATA:
      return {
        ...state,
        userAction:'view',
        isProcessing: false,
        formHelper:null,
        formHelperChange:null,
        showModal:true,
        viewData:action.payload
      }
    case VisitScheduleActions.UPDATE_DATA:
        return {
          ...state,
          userAction:'update',
          isProcessing: false,
          showModal:true,
        }
    case VisitScheduleActions.DELETE_DATA:
      return {
        ...state,
        userAction:null,
        isProcessing: false,
        showModal:false,
        viewData:null,
      }
    case VisitScheduleActions.CLOSE_MODAL:
      return {
        ...state,
        isProcessing: false,
        formHelper:null,
        formHelperChange:null,
        showModal:false,
        showModalChange:false,
        userAction:null,
        viewData:null,
      }
    case VisitScheduleActions.GET_HELPER_VISITSCHEDULE:
      return { 
        ...state,
        isProcessing: false, 
        helperVisitSchedule: action.payload 
      };
    case VisitScheduleActions.FETCH_SCHEDULE:
      return {
        ...state,
        isProcessing: false,
        data:action.payload,
        showModal:false,
        onSubmit:false,
      }
    case VisitScheduleActions.FETCH_CHANGE:
      return {
        ...state,
        isProcessing: false,
        data:action.payload,
        showModalChange:false,
        onSubmit:false,
      }  
    case VisitScheduleActions.ON_SUBMIT:
      return {
        ...state,
        isProcessing: true,
        onSubmit:true
      }
    default:
      return state;
  }
}

export default VisitScheduleReducer;

import {  Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        marginTop: '10px'
    },
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        // paddingTop: "10px",
        // border:'1px',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
    rowHeader: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        borderBottom: '1px',
        flexDirection: 'row',
    },
    trHeader: {
        backgroundColor: 'rgba(255, 255, 128)',
        // borderBottom: '1px',
        flexDirection: 'row',
    },
    td: {
        // borderRight: '1px',
        borderLeft: '1px',
        flexDirection: 'column',
        flexWrap: "wrap",
        // whiteSpace: "-moz-pre-wrap", /*Firefox */    
        // whiteSpace: "-pre-wrap",     /* Opera <7 */   
        // whiteSpace: "-o-pre-wrap",   /* Opera 7 */    
        // wordWrap: "break-word",       /* CSS3 */   
        overflowWrap: "break-word"

    },
    tdcol: {
        colspan: '2',
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    titleHeader: {
        flexDirection: 'row',
        marginBottom: '10px'
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },

    titleTranx: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'left',
        marginBottom: '5px'
    },
    title: {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        marginBottom: '5px'
    },

    div: {
        width: '100%',
        height: 400,
        border: '1 solid black'
        // fontSize: "15px",
        // fontWeight: 700,
        // fontFamily: 'Times-Bold',
        // textAlign: 'center',
        // marginBottom:'5px'
    },

    tableTitle: {
        borderTop: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        // padding: '5px',
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        border: '2px'
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },
    //Dalam Table
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "12px",
    },

    totalBottom: {
        textAlign: 'right',
        fontFamily: 'Times-Bold',
        paddingRight: '1px',
        paddingTop: '2px',
        fontSize: "8px",
    },


    fillText: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "9px",
        paddingTop: '1px',
        // display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",

        
        

    },

    fillTextType: {
        textAlign: 'center',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fill: {
        textAlign: 'left',
        paddingLeft: '5px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fillNo: {
        textAlign: 'center',
        fontSize: "9px",
    },

    fillNumber: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
    },

    fillNumberBold: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
        fontFamily: 'Times-Bold',
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        top: 100,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class GeneralLedgerPrint extends Component{
    constructor(props){
        super(props)
        this.state={
            title:i18n.t("Laporan Buku Besar"),
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            in:[],
            out:[],
        };
    }
    renderMessage(){
        const {isProcessing, requestPrint}=this.props
        switch(true){
            case isProcessing:
                return (
                <div style={loader}>
                    <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
                    <div style={{fontSize:36}}>Getting Data From Server</div>
                </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{fontSize:36}}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={()=>{this.props.getPrint(this.props.id)}}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderMenu(){
        html.push(
           
        );
        return html;
    }
    render(){
        console.log('PROPS',this.props)
        const {dataPrint}=this.props
        let totalDebt = 0;
        let totalCredit = 0;
        let address=[]
        if(dataPrint!=null){
            if(dataPrint.detail != null){
                let row = dataPrint.detail
                for(var i=0; i<row.length;i++){
                    totalDebt += parseFloat(row[i].debt)||0;
                    totalCredit += parseFloat(row[i].credit)||0;
                }  

            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
        }
    }
        
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title}>
                        {/*DATA AKTIVA */}

                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map((a,i)=>
                                                <Text key={'add'+i} style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col} >
                                            <View style={styles.title}>
                                                <Text>{i18n.t("Laporan Buku Besar")}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.startDate + ' - ' + dataPrint.data.endDate}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.branch}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                 <View style={[styles.titleContainer,{marginTop:'10px'}]}>
                                    <View fixed style={styles.tableTitle}  >
                                        <View style={styles.trHeader}>
                                            <Fragment key={'menuHeader'}>
                                                <View style={[styles.td,{width:'3%'}]}>
                                                    <Text style={styles.menuHeader}>{"No"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.menuHeader}>{"Tanggal"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.menuHeader}>{"No. Jurnal"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'25%'}]}>
                                                    <Text style={styles.menuHeader}>{"Keterangan"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.menuHeader}>{"Debit"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.menuHeader}>{"Kredit"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'16%',borderRight:'1px'}]}>
                                                    <Text style={styles.menuHeader}>{"Saldo"}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>
                               
                               
                                <View style={styles.titleContainer}>
                                    <View  style={styles.tableTitle}>
                                    {dataPrint.detail.map((data,i)=>
                                        <View style={styles.tr}>
                                            <Fragment key={'detail' + i}>
                                                <View style={[styles.td,{width:'3%'}]}>
                                                    <Text style={styles.fillNo}>{i+1}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.fillText}>{data.date}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'20%'}]}>
                                                    <Text style={styles.fillText}>{data.journal_no}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'25%'}]}>
                                                    <Text style={styles.fillText}>{data.remark}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.fillNumber}>{data.debt_format}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.fillNumber}>{data.credit_format}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'16%',borderRight:'1px'}]}>
                                                    <Text style={styles.fillNumberBold}>{data.balance_format}</Text>
                                                </View>
                                            </Fragment>     
                                        </View>
                                       
                                    )}
                                        <View style={styles.tr}>
                                            <Fragment key={'jumlah'}>
                                                <View style={[styles.td,{width:'60%'}]}>
                                                    <Text style={styles.menuHeader}>{"Total"}</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalDebt < 0 ?
                                                                '(' + (totalDebt * (-1)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                                totalDebt.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                            }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'12%'}]}>
                                                    <Text style={styles.fillNumberBold}>{totalCredit < 0 ?
                                                                '(' + (totalCredit * (-1)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                                totalCredit.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                            }</Text>
                                                </View>
                                                <View style={[styles.td,{width:'16%',borderRight:'1px'}]}>
                                                    <Text style={styles.menuHeader}></Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                        </Page>
                    </Document>
            </PDFViewer>
            :
            this.renderMessage()}
            
        </React.Fragment>   
        
        )
    }
}

export default GeneralLedgerPrint;

const loader={
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection:'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from "../../../utilities/Form/Select2";


export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            accountCurrent: null,
            accountText: i18n.t("Select Account"),
            accountList: [],
            form:{
                accountCurrent: null,
                name:'',
                status:1,
                code:''
            },
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state,
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
       this.state.form = {
            ...this.state.form,
            idCOA3 : this.state.accountCurrent != null ? this.state.accountCurrent.value : '',
       } 
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){
        console.log(this.props)
        console.log(this.state)
        const {data,isNewRecord,helperItemType}=this.props        
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.accountCurrent = {
                    value: data.id_coa ?? '' ,
                    label: data.name_coa ?? ''
                }
                this.state.form.name=data.name,
                this.state.form.code=data.code,
                this.state.form.status=data.status
                this.state.isUpdate=1
            }
        }
        if(helperItemType != null){
            this.state.accountList = helperItemType.coa3Item
        }
        return (
            <form>
                <div className='row'>
                    <div className="col-md-12 mt-3">
                        <Input
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                        />        
                    </div>
                    <div className="col-md-12 mt-3">
                        <Select2
                            isClearable={true}
                            name={'idAccount'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idAccount", "accountCurrent");
                            }}
                            isLoading={this.state.loadingAccount}
                            options={this.state.accountList}
                            title={i18n.t('Inventory Account')}
                            value={this.state.accountCurrent}
                            placeholder={this.state.accountText}
                            labelfield={"true"}
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <Radio
                            list={StatusForm}
                            name={"status"}
                            title={i18n.t("Status")}
                            inline={false}
                            value={this.state.form.status}
                            handler={this.handler}
                            error={this.renderError('status')}
                        />
                    </div>
                </div>
               
                
                
                        
            </form>
        );
    }
}

export default Form;
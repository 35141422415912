import ReturnPriceActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperReturnPrice:null,
  ReturnPriceHelperFull:null,
  printReturnPrice:null
};

function ReturnPriceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ReturnPriceActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case ReturnPriceActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ReturnPriceActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case ReturnPriceActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case ReturnPriceActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReturnPriceActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReturnPriceActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case ReturnPriceActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case ReturnPriceActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case ReturnPriceActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case ReturnPriceActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case ReturnPriceActions.GET_HELPER_RETURNPRICE:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperReturnPrice:action.payload};
    case ReturnPriceActions.GET_HELPER_RETURNPRICE_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,ReturnPriceHelperFull:action.payload};
    case ReturnPriceActions.PRINT_RETURNPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printReturnPrice:action.payload};
    case ReturnPriceActions.APPROVE_RETURNPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case ReturnPriceActions.REJECT_RETURNPRICE:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};  
    default:
      return state;
    }
}


export default ReturnPriceReducer;

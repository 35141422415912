import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";
import { format } from 'date-fns';

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400, fontStyle: 'bold' },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900, fontStyle: 'bold' }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        padding: "20px",
    },
    row: {

        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        flexDirection: 'row',
    },
    td: {
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "10px",
        fontFamily: 'Times-Roman'
    },
    total: {
        fontSize: "16px",
        fontFamily: 'Times-Roman'
    },
    titleTranx: {
        fontSize: "17px",
        fontWeight: 700,
        fontFamily: 'Times-Roman',
        textAlign: 'right'
    },
    tableTitle: {
        border: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
    },
    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintSelling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: i18n.t("Sales Invoice No") + ' : ',
            merchant: {
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address: []
            },
            logo: '/assets/media/logos/logo.jpg',
            data: {
                poNo: '',
                po_detail: []
            }
        };
    }
    renderMessage() {
        // console.log('PROPS',this.props);

        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
        }
    }
    render() {
        let FillText = "";
        const { dataPrint,authUser } = this.props
        console.log('PROPS',this.props);
        console.log('Data',dataPrint);
     
        let user = (authUser == undefined)? '' : authUser.username
        let total = 0
        let date = format(new Date(), 'dd/MM/yyyy');
        let totalDiscount = 0
        let totalTax = 0
        let totalAll = 0
        let count = 0
        let address = "";
        if (dataPrint != null) {
            this.state.data = dataPrint.model
            this.state.merchant = dataPrint.merchant

            address = this.state.merchant.address.split(/\r?\n/) + '  '
            total = this.state.data.selling_detail.map(item => item.qty * parseFloat(item.unit_price)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalDiscount = this.state.data.selling_detail.map(item => item.qty * parseFloat((item.discount_price) ? item.discount_price : 0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalTax = this.state.data.selling_detail.map(item => item.qty * parseFloat((item.tax) ? item.tax : 0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalAll = (total - totalDiscount) - totalTax
        }
        return (
            <React.Fragment>{(dataPrint != null) ?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title + ((dataPrint != undefined) ? this.state.data.transaction_no : '')}>
                        <Page wrap size={"A5"} style={styles.page} orientation={"landscape"}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col, { width: '500' }} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            <Text style={styles.address} >{address}</Text>

                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={[styles.col, { width: '600px', paddingTop: '10px' }]} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Invoice ") + this.state.data.payment_type}</Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Invoice No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '200px' }]} >
                                                        <Text>{this.state.data.transaction_no}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>{i18n.t("Customer")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '200px' },]} >
                                                        <Text>{this.state.data.name_customer}</Text>
                                                    </View>
                                                </View>
                                                {/* <View style={styles.tr}>
                                                    
                                                </View> */}
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Invoice Date")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '200px' }]} >
                                                        <Text>{this.state.data.date_format}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>{i18n.t("Address")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '200px' },]} >
                                                        <Text>{this.state.data.address_customer}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Term")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '200px' }]} >
                                                        <Text>{this.state.data.term + ' Hari, ' + this.state.data.due_date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Sales Order No.")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '100px' }]} >
                                                        <Text>{this.state.data.id_so}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Salesman")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '440px' }]}>
                                                        <Text>{this.state.data.name_sales}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Warehouse")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '440px' }]}>
                                                        {/* <Text>{this.state.data.warehouse_name.name}</Text> */}
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableContainer}>
                                    <View style={styles.containerItem}>
                                        <Text style={{ width: '10%' }}>{i18n.t("Code ")}</Text>
                                        <Text style={{ width: '23%' }}>{i18n.t("Item")}</Text>
                                        <Text style={{ width: '13%' }}>{i18n.t("Qty")}</Text>
                                        <Text style={{ width: '10%' }}>{i18n.t("Banderol")}</Text>
                                        <Text style={{ width: '14%' }}>{i18n.t("@Price")}</Text>
                                        <Text style={{ width: '12%' }}>{i18n.t("Disc (Rp)")}</Text>
                                        <Text style={{ width: '18%', textAlign: "right" }}>{i18n.t("Total Price")}</Text>
                                    </View>
                                    <Fragment>{this.state.data.selling_detail.map((item, i) =>
                                        <View style={styles.rowItem} key={i + 'itemPo'}>
                                            <Text style={{ width: '10%', padding: 1 }}>{item.code_item}</Text>
                                            <Text style={{ width: '23%', padding: 1 }}>{item.name_item}</Text>
                                            <Text style={{ width: '13%', padding: 1 }}>{item.qty_format + ' ' + item.name_unit}</Text>
                                            <Text style={{ width: '10%', padding: 1 }}>{item.band_no}</Text>
                                            <Text style={{ width: '14%', padding: 1, textAlign: "right" }}>{'Rp. ' + item.unit_price_format}</Text>
                                            <Text style={{ width: '12%', padding: 1, textAlign: "right" }}>{'Rp. ' + item.discount_price_format}</Text>
                                            <Text style={{ width: '18%', padding: 1, textAlign: "right" }} >{'Rp. ' + item.total_format}</Text>
                                        </View>
                                    )}</Fragment>
                                    <View style={styles.rowItem}>
                                        <Text style={{ width: '10%', padding: 1 }}></Text>
                                        <Text style={{ width: '23%' }}>{i18n.t("#Item : ") + this.state.data.selling_detail.length}</Text>
                                    </View>
                                </View>
                                <View style={styles.tr}>
                                    <View style={[styles.td, { width: '100%', borderTop: '1px', marginTop: '35px' }]} >
                                        <Text></Text>
                                    </View>
                                </View>
                                <View style={styles.tr}>
                                    <View style={[styles.address, { width: '60%', borderRight: '1px', borderBottom: '1px' }]} >
                                        <Text>Barang Telah Diterima Dengan Baik</Text>
                                    </View>
                                </View>
                                <View style={styles.tr}>
                                    <View style={[styles.address, { width: '30%' }]} >
                                        <Text style={{ marginTop: '60px', marginLeft: '40px' }}>
                                            {this.state.data.name_customer}</Text>
                                    </View>
                                    <View style={[styles.address, { width: '30%', borderRight: '1px' }]} >
                                        <Text style={{ marginTop: '60px', marginLeft: '50px' }}>
                                            {this.state.data.created_by}</Text>
                                    </View>
                                    <View style={styles.td, { width: '40%', paddingLeft: '5px', position: 'right' }} >
                                        <View style={styles.tableTotal}>
                                            <View style={styles.tr}>
                                                <View style={[styles.td, { width: '50px' }]} >
                                                    <Text>Sub Total</Text>
                                                </View>
                                                <View style={styles.td} >
                                                    <Text> : Rp. </Text>
                                                </View>
                                                <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                    <Text>{parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tr}>
                                                <View style={[styles.td, { width: '50px' }]} >
                                                    <Text>Discount</Text>
                                                </View>
                                                <View style={styles.td} >
                                                    <Text> : Rp. </Text>
                                                </View>
                                                <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                    <Text>{parseFloat(totalDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tr}>
                                                <View style={[styles.td, { width: '50px' }]} >
                                                    <Text>Tax</Text>
                                                </View>
                                                <View style={styles.td} >
                                                    <Text> : Rp. </Text>
                                                </View>
                                                <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                    <Text>{parseFloat(totalTax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tr}>
                                                <View style={[styles.td, { width: '200px', borderBottom: '1px' }]} >
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.tr}>
                                                <View style={[styles.total, { width: '50px' }]} >
                                                    <Text style={{ fontFamily: 'Times-Bold' }}>Total</Text>
                                                </View>
                                                <View style={styles.total}  >
                                                    <Text style={{ fontFamily: 'Times-Bold' }}> : Rp. </Text>
                                                </View>
                                                <View style={[styles.total, { width: '120px', textAlign: 'right' }]}>
                                                    <Text style={{ fontFamily: 'Times-Bold' }} >{parseFloat(totalAll).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.tr}>
                                    <View style={[styles.address, { width: '100%', borderTop: '1px', textAlign: 'right' }]} >
                                        <Text>{'Cetakan ke- ' + this.state.data.counter_print +', ' + date + ' - '+ user}</Text>
                                    </View>
                                </View>

                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                :
                this.renderMessage()}

            </React.Fragment>

        )
    }
}

export default PrintSelling;

const loader = {
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable";

class CreatableSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    labelfield: PropTypes.string,
  };

  static defaultProps = {
    error: "",
    placeholder: "",
    required: false,
    labelfield: "true",
  };
  constructor(props) {
    super(props);
  }
  renderClass() {
    const { style, size } = this.props;
    var classText = "form-control";
    switch (size) {
      case "sm":
        classText += " form-control-sm";
        break;
      case "md":
        classText += " form-control-md";
        break;
      case "lg":
        classText += " form-control-lg";
        break;
    }

    switch (style) {
      case "solid":
        classText += " form-control-solid";
        break;
      case "flush":
        classText += " form-control-flush";
        break;
    }
    return classText;
  }

  render() {
    const { required, title, name, error, labelfield } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : (
          ""
        )}
        <Creatable {...this.props} />
        <div className="fv-plugins-message-container invalid-feedback">
          {error}
        </div>
      </React.Fragment>
    );
  }
}

export default CreatableSelect;

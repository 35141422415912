import React, { Component, Fragment } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Table from "../../../utilities/table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import FormBGOut from "../../../../containers/modules/accounting/log-bg/form-out";
import { transactionBG } from "../../../utilities/Const/TranscationCode";
import LabelTranscationBG from "../../../utilities/Status/log-bg";

const MySwal = withReactContent(Swal);
export class LogBgOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
        key:'Out'
      },
      titleModal: "",
      modal: 1,
      listData: [],
      column: [
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "date_bg_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("BG Date"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "no_bg",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. BG"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "name_coa4",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "no_transaction",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Transaction"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: transactionBG,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component: (data, column) => {
            return <LabelTranscationBG value={data[column.key]} />;
          },
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 m-0">
                    {/* Edit Button */}
                    {data.status == 1 ? (
                      <>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.confirmBG(data.id)}
                          >
                            {i18n.t("Confirm")}
                          </Dropdown.Item>
                        </div>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.rejectBG(data.id)}
                          >
                            {i18n.t("Reject")}
                          </Dropdown.Item>
                        </div>
                      </>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);

  }

  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  confirmBG(id) {
    this.state.titleModal = i18n.t("BGOut");
    this.props.confirmOut(id);
  }

  rejectBG(id) {
    this.state.titleModal = i18n.t("Reject BG");
    this.props.rejectOut(id);
  }

  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  render() {
    const { isProcessing, datalist, showModalOut } = this.props;
    this.state.listData = datalist;
    return (
      <React.Fragment>
        <div id="customer-type">
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-fluid"
          >

            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  numberAble={true}
                  // expandAble={true}
                  // theExpand={{
                  //   key: "source_from",
                  //   columns: [
                  //     { label: i18n.t("Branch Name"), key: "name_branch" },
                  //     // { label: i18n.t("Item"), key: "name_item" },
                  //     // { label: i18n.t("Unit"), key: "name_unit" },
                  //     // { label: i18n.t("Price"), key: "price_format" },
                  //     // { label: i18n.t("Status"), key: "status_due" },
                  //   ],
                  // }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormBGOut
          showModalOut={showModalOut}
          search={this.state.search}
        />
      </React.Fragment>
    );
  }

}

export default LogBgOut;

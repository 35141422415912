import BankActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperBank:null,
  helperFullBank:null,
  errorInternal:false
};

function BankReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BankActions.RESET_LIST:
      return INITIAL_STATE;
    case BankActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case BankActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case BankActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case BankActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case BankActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case BankActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case BankActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case BankActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case BankActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case BankActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case BankActions.GET_HELPER_BANK:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperBank:action.payload};
    case BankActions.GET_HELPERFULL_BANK:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperFullBank:action.payload};
    case BankActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default BankReducer;

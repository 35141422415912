import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
// import AuthMiddleware from '../../../../reducers/auth/middleware';
import { Navigate } from 'react-router-dom';
import ProvinceMiddleware from '../../../../middleware/modules/master/province';
import Province from '../../../../components/modules/master/province';
import useScript from '../../../../components/utilities/useScript';

const mapStateToProps = ({ auth,province }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist:province.data
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ProvinceList: customer =>ProvinceMiddleware.list(customer)
  }, dispatch);
};
class ProvinceContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.object,
    datalist: PropTypes.object,
    ProvinceList: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {}
  }
  componentWillMount() {
    var host=process.env.APP_URL
    const search = {
      limit: 10,
    };
   this.props.ProvinceList(search);
  }
  render() {
    const { isAuthenticated, isProcessing, isRegistered, isError, errorMessage, ProvinceList ,datalist} = this.props;
    // if(isAuthenticated){
    //   return <Navigate
    //         to='/'
    //       />
    // }
    return (
      <Province 
        search={ProvinceList}
        data={datalist}
        isProcessing={isProcessing}
        isError={isError}
        errorMessage={errorMessage}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceContainer);

import { useParams } from "react-router-dom";
import React from "react";
import MemoOutContainer from '../../../containers/Print/default/Memo-Out'
export const PrintMemoOut = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <MemoOutContainer id={id}/>
    )
}

export default PrintMemoOut;
import React, { Component, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);
export class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: '/user/admin', title: i18n.t("Admin"), class: " text-gray-600" },
        { link: null, title: i18n.t("Roles"), class: " text-gray-600" },
      ],
      column: [
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Role Name"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
                      <div className="menu-item px-3">
                        <Dropdown.Item
                           as={Link}
                           to={"/user/admin/role/assign/" + data.id}
                          className="menu-link px-3"
                        >
                          {i18n.t("Set Assignment")}
                        </Dropdown.Item>
                      </div>
                      <div className="menu-item px-3">
                        <Dropdown.Item
                          as={Link}
                          to={"/user/admin/role/update/" + data.id}
                           className="menu-link px-3"
                        >
                          {i18n.t("Update")}
                        </Dropdown.Item>
                      </div>
                      {(data.id !=='Super User')?
                      <div className="menu-item px-3">
                        <Dropdown.Item
                           as={Button}
                           variant={'none'}
                           className="menu-link px-3"
                           onClick={() => this.deleteData(data.id)}
                        >
                          {i18n.t("Delete")}
                        </Dropdown.Item>
                      </div>:null}
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }
  componentWillUnmount(){
    this.props.reset()
  }
  componentDidMount(){
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Assignment");
    this.props.updateItem(id);
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.delete(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    if(data!=null){
      this.state.listData = data;
    }
    
    return (
      <div id="role-list" className="d-flex flex-column flex-column-fluid">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="d-flex flex-column flex-xl-row">
              <div className="w-100 flex-lg-row-auto w-lg-300px mb-7 me-7 me-lg-10">
                <div className="card card-flush py-4"><div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin"}>
                          <span className="menu-title fw-bold">Assignment</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link className="menu-link" to={"/user/admin/route"}>
                          <span className="menu-title fw-bold">Route</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link"
                          to={"/user/admin/permission"}
                        >
                          <span className="menu-title fw-bold">Permission</span>
                        </Link>
                      </div>
                      <div className="menu-item mb-3">
                        <Link
                          className="menu-link active"
                          to={"/user/admin/role"}
                        >
                          <span className="menu-title fw-bold">Roles</span>
                        </Link>
                      </div>
                    </div>
                  </div></div>
              </div>
              <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                <div className="card card-flush py-4">
                  <div className="card-header d-flex justify-content-end border-0 pt-6">
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-customer-table-toolbar="base"
                      >
                        <Button
                          type="button"
                          className="btn btn-warning m-1"
                          onClick={(e) => this.runSearch(this.state.search)}
                        >
                          <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                        </Button>
                        <Link
                          className="btn btn-primary m-1"
                          to={"/user/admin/role/create"}
                        >
                          {i18n.t("Add Role")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <Table
                      data={this.state.listData}
                      tools={false}
                      filterSearch={true}
                      column={this.state.column}
                      searchData={this.state.search}
                      runSearch={this.runSearch}
                      deleteData={()=>{}}
                      updateData={()=>{}}
                      isProcessing={isProcessing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Role;

export default class PurchaseReturnActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static CANCEL_DATA='CANCEL_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_PR='GET_HELPER_PR';
    static GET_HELPER_PRFULL='GET_HELPER_PRFULL';
    static SUCCESS_DATA='SUCCESS_DATA';
    static PRINT_PR='PRINT_PR'
    static BEFORE_PRINT_PR='BEFORE_PRINT_PR'
    static RESET_LIST='RESET_LIST'
  
    static submit(){
      return {
        type: PurchaseReturnActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: PurchaseReturnActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: PurchaseReturnActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: PurchaseReturnActions.VIEW_DATA,
        payload: data
      };
    }

    static cancel() {
      return {
        type: PurchaseReturnActions.CANCEL_DATA
      };
    }
  
  
    static list(data) {
      return {
        type: PurchaseReturnActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: PurchaseReturnActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: PurchaseReturnActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: PurchaseReturnActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: PurchaseReturnActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: PurchaseReturnActions.RESET_LIST,
      };
    }
    static helper(data){
      return {
        type: PurchaseReturnActions.GET_HELPER_PR,
        payload:data
      };
    }
    static helperFull(data){
      return {
        type: PurchaseReturnActions.GET_HELPER_PRFULL,
        payload:data
      };
    }
    static errorInternal(error){
      return {
        type: PurchaseReturnActions.ERROR_SERVER,
        payload: error
      };
    }
    static beforePrint(){
      return {
        type: PurchaseReturnActions.BEFORE_PRINT_PR,
      };
    }
    static print(data){
      return {
        type: PurchaseReturnActions.PRINT_PR,
        payload: data
      };
    }
  }
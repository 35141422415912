import  WorkSheetActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperWorkSheet:[],
  errorInternal:false,
  printWorkSheet:null
};

function  WorkSheetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case  WorkSheetActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case  WorkSheetActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case  WorkSheetActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case  WorkSheetActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case  WorkSheetActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case  WorkSheetActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case  WorkSheetActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case  WorkSheetActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case  WorkSheetActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case  WorkSheetActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case  WorkSheetActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case  WorkSheetActions.GET_HELPER_WORKSHEET:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperWorkSheet:action.payload};
    case  WorkSheetActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case WorkSheetActions.BEFORE_PRINT_WORKSHEET:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWorkSheet:null,isProcessing:true};
    case WorkSheetActions.PRINT_WORKSHEET:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWorkSheet:action.payload,isProcessing:false};       
    default:
      return state;
    }
}

export default WorkSheetReducer;

import React, { Component } from "react";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import InputNumber from "../../../utilities/Form/InputNumber";
import TextArea from "../../../utilities/Form/TextArea";
import { Button, Table } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import i18n from "../../../../i18n";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Journal: [],
        DetailJournal: [],
      },
      branchValue: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      storeValue: null,
      date: new Date(),
      storeText: i18n.t("Select Store"),
      storeList: [],
      storeListLoading: false,
      codeLoading: false,
      description: null,
      journalNo: '',
      transactionSource: 1,
      idTransaction: 0,
      status: 1,
      isSystem: 0,
      isReconcile: 0,
      totalDebt: 0,
      totalCredit: 0,
      arrayDetail: [
        {
          coa3List: [],
          coa3Text: i18n.t("Select Ledger"),
          coa3Value: null,
          coaHelperList: [],
          coaHelpertext: i18n.t("Select Helper Book"),
          coaHelperValue: null,
          debt: 0,
          credit: 0,
          description: '',
        },
        {
          coa3List: [],
          coa3Text: i18n.t("Select Ledger"),
          coa3Value: null,
          coaHelperList: [],
          coaHelpertext: i18n.t("Select Helper Book"),
          coaHelperValue: null,
          debt: 0,
          credit: 0,
          description: '',
        },
      ],
      check: 1,
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
    this.calculateRow(i, arrayname);
  }

  handlerSelectArray(e, name, i, arrayname, valueCurrent) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[valueCurrent] = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss[valueCurrent] = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let debt = parseFloat(this.state.arrayDetail[i].debt) || 0;
    let credit = parseFloat(this.state.arrayDetail[i].credit) || 0;
    objectss["totalDebt"] = debt.toFixed(2);
    objectss["totalCredit"] = credit.toFixed(2);
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    let TotalDebt = 0;
    let TotalCredit = 0;
    let Total = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let debt = parseFloat(detail[i].debt) || 0;
      let credit = parseFloat(detail[i].credit) || 0;
      TotalDebt += debt;
      TotalCredit += credit;
      Total += TotalDebt + TotalCredit;
    }
    this.setState({
      totalDebt: TotalDebt.toFixed(2),
      totalCredit: TotalCredit.toFixed(2),
    });
  }

  runSubmit() {
    if (this.state.totalDebt == this.state.totalCredit) {
      const Journal = {
        journalNo: this.state.journalNo,
        date: moment(this.state.date).format("YYYY-MM-DD"),
        idBranch: this.state.branchValue == null ? "" : this.state.branchValue.value,
        idStore: this.state.storeValue == null ? "" : this.state.storeValue.value,
        description: this.state.description,
        transactionSource: 1,
        idTransaction: 0,
        status: 1,
        isSystem: 0,
        isReconcile: 0,
        debt: this.state.totalDebt,
        credit: this.state.totalCredit,
        check: this.state.check,
      };
      const detail = this.state.arrayDetail;
      let coa3 = [];
      let coaHelper = [];
      let debt = [];
      let credit = [];
      let description = [];

      for (var i = 0; i < detail.length; i++) {
        coa3.push(detail[i].coa3Value == null ? "" : detail[i].coa3Value.value);
        coaHelper.push(detail[i].coaHelperValue == null ? "" : detail[i].coaHelperValue.value);
        debt.push(detail[i].debt == null ? "" : detail[i].debt);
        credit.push(detail[i].credit == "" ? "" : detail[i].credit);
        description.push((detail[i].description == null) ? '' : detail[i].description);
      }
      this.state.form.Journal = Journal;
      this.state.form.DetailJournal = {
        coa3: coa3,
        coaHelper: coaHelper,
        debt: debt,
        credit: credit,
        description: description,
      };
      this.state.form.check = 1;
      return this.state.form;
    } else {
      this.state.form.check = 0;
      return this.alertError(i18n.t("Info"), i18n.t("Total Between Debt and Credit Must be Same Value"), i18n.t("info"));
    }
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  renderDetail(detail, i) {
    return (
      <>
        <tr>
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"coa3"}
              required
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "coa3",
                  i,
                  "arrayDetail",
                  "coa3Value"
                )
              }
              options={this.state.coa3List}
              title={i18n.t("Coa3")}
              value={detail.coa3Value}
              placeholder={this.state.coa3Text}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.coa3." + i)}
            </div>
          </td>
          <td width={"300px"}>
            <Select
              isClearable={true}
              name={"coaHelper"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(
                  e,
                  "coaHelper",
                  i,
                  "arrayDetail",
                  "coaHelperValue"
                )
              }
              options={this.state.coaHelperList}
              title={i18n.t("CoaHelper")}
              value={detail.coaHelperValue}
              placeholder={this.state.coaHelpertext}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.coaHelper." + i)}
            </div>
          </td>
          <td width={"200px"}>
            <InputNumber
              id={"debt" + i}
              name="debt"
              placeholder="Debt"
              defaultValue={0}
              value={detail.debt}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "debt", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
              disabled={detail.credit > 0 ? true : false}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.debt." + i)}
            </div>
          </td>
          <td width={"200px"}>
            <InputNumber
              id={"credit" + i}
              name="credit"
              placeholder="Credit"
              defaultValue={0}
              value={detail.credit}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                this.handlerCurrencyArray(value, "credit", i, "arrayDetail")
              }
              className="form-control  form-control-sm text-end"
              prefix="Rp "
              labelfield={"false"}
              disabled={detail.debt > 0 ? true : false}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("DetailJournal.credit." + i)}
            </div>
          </td>
          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </>
    );
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(keyFill, type = null, key, array = null, arraykey = null) {
    const token = cookieManager.getUserToken();

    let params = null;
    switch (type) {
      case "getCode":
        this.state.codeLoading = true;
        var url = process.env.REST_URL + "helper/get-journal-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          branch: branch,
          date: date,
        };
        break;
      case "getStore":
        this.state.storeListLoading = true;
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchValue != undefined
            ? this.state.branchValue.value
            : null;
        params = {
          id: branch,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
          switch (type) {
            case "getStore":
              this.state.storeListLoading = false;
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reCalculate() {
    this.state.arrayDetail.map(async (detail, i) => {
      this.calculateRow(i, "arrayDetail");
    });
  }

  render() {
    const { dataUpdate, isNewRecord, helperJournal, data } = this.props;
    if (this.state.arrayDetail.length < 2) {
      this.alertError(i18n.t("Detail"), i18n.t("Detail Must Have 2 Item"), i18n.t("info"));
      this.addArray(
        {
          coa3Value: null,
          coaHelperValue: null,
          debt: 0,
          credit: 0,
          description: '',
        },
        "arrayDetail"
      );
    }
    this.state.branchList = helperJournal != null ? helperJournal.branch : null;
    this.state.coa3List = helperJournal != null ? helperJournal.coa3 : null;
    this.state.coaHelperList = helperJournal != null ? helperJournal.coa4 : null;

    if (dataUpdate != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.journalNo = dataUpdate.no_journal,
          this.state.description = dataUpdate.description,
          this.state.date = new Date(dataUpdate.date);
        this.state.branchValue = {
          value: dataUpdate.id_branch,
          label: dataUpdate.name_branch,
        };
        this.state.storeValue =
          dataUpdate.id_store == null
            ? ""
            : { value: dataUpdate.id_store, label: dataUpdate.name_store };
        this.state.isUpdate = 1;
        this.state.arrayDetail = [];
        for (var po = 0; po < dataUpdate.journal_detail.length; po++) {
          this.state.arrayDetail.push({
            coa3Value: {
              value: dataUpdate.journal_detail[po].id_coa3,
              label: dataUpdate.journal_detail[po].name_coa3,
            },
            coaHelperValue: (dataUpdate.journal_detail[po].id_coa4 == null)?
            {  
              value: "",
              label: "",
            }:
            {  
              value: dataUpdate.journal_detail[po].id_coa4,
              label: dataUpdate.journal_detail[po].name_coa_help,
            },
            description: (dataUpdate.journal_detail == null) ? '' : dataUpdate.journal_detail[po].description,
            debt:
              dataUpdate.journal_detail[po].debt == null
                ? 0
                : dataUpdate.journal_detail[po].debt,
            credit:
              dataUpdate.journal_detail[po].credit == null
                ? 0
                : dataUpdate.journal_detail[po].credit,
          });
          this.calculateRow(po, "arrayDetail");
        }
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-2">
              <Input
                disabled={true}
                type={"input"}
                name={"journalNo"}
                title={i18n.t("Journal No.")}
                value={this.state.journalNo}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.journalNo")}
              </div>
            </div>
            <div className="col-md-2">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Date")}
                required
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("journalNo", "getCode", "code");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                endDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-md-2">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                onChange={async (e) => {
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.setState({ storeValue: null });
                    await this.setState({ journalNo: "" });
                    await this.setState({ storeList: [] });
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchValue");
                    await this.changeData("journalNo", "getCode", "code");
                    await this.changeData("storeList", "getStore", "");
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchValue}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.idBranch")}
              </div>
            </div>
            <div className="col-md-2">
              <Select2
                isClearable={true}
                isLoading={this.state.storeListLoading}
                name={"idStore"}

                onChange={(e) => this.handlerSelect(e, "idStore", "storeValue")}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeValue}
              />
            </div>
            <div className="col-md-4">
              <TextArea
                name={"description"}
                required={true}
                title={i18n.t("Description")}
                value={this.state.description}
                handler={this.handler}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("Journal.description")}
              </div>
            </div>
            <br />
            <hr />
            <div className="row">
              <div className="col-12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{i18n.t("Ledger (Coa Level 3)")}</th>
                      <th className="text-center">{i18n.t("Helper Book (Coa Level 4)")}</th>
                      <th className="text-center">{i18n.t("Debt")}</th>
                      <th className="text-center">{i18n.t("Credit")}</th>
                      <th className="text-center">{i18n.t("Description")}</th>
                      {!this.state.fromRequest ? (
                        <th className="text-right"></th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrayDetail.map((detail, i) => {
                      return this.renderDetail(detail, i);
                    })}
                  </tbody>
                </Table>
                {!this.state.fromRequest ? (
                  <Button
                    variant="success"
                    onClick={(e) =>
                      this.addArray(
                        {
                          coa3Value: null,
                          coaHelperValue: null,
                          debt: 0,
                          credit: 0,
                          description: null,
                        },
                        "arrayDetail"
                      )
                    }
                  >
                    {i18n.t("Add Item")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="d-flex flex-end">
                <div className="col-4">
                  <Table borderless responsive="sm">
                    <tbody>
                      <tr className="align-middle">
                        <td>{i18n.t("Total Debt")}</td>
                        <td>:</td>
                        <td>
                          <InputNumber
                            id={"totalDebt"}
                            name="totalDebt"
                            placeholder="Total Debt"
                            defaultValue={0}
                            value={this.state.totalDebt}
                            decimalsLimit={2}
                            onValueChange={(value, name) => { }}
                            className="p-0 form-control form-control-xs form-control-flush text-end"
                            prefix="Rp "
                            readOnly
                            labelfield={"false"}
                          />
                        </td>
                      </tr>
                      <tr className="align-middle">
                        <td>{i18n.t("Total Credit")}</td>
                        <td>:</td>
                        <td>
                          <InputNumber
                            id={"totalCredit"}
                            name="totalCredit"
                            placeholder="Total Credit"
                            defaultValue={0}
                            value={this.state.totalCredit}
                            decimalsLimit={2}
                            onValueChange={(value, name) => { }}
                            className="p-0 form-control form-control-xs form-control-flush text-end"
                            prefix="Rp "
                            readOnly
                            labelfield={"false"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/*  */}
      </React.Fragment>
    );
  }
}

export default Form;

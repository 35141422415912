import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
import ListPaymentMiddelware from '../../../../middleware/modules/accounting/list-payment';
import ListSupplier from '../../../../components/modules/accounting/list-payment/listSupplier';
// middlewares

const mapStateToProps = ({ auth, listPayment}) => ({
  authUser:auth.authUser,
  isProcessing: listPayment.isProcessing,
  isError: listPayment.isError,
  errorMessage: listPayment.errorMessage,
  data:listPayment?.listPaymentSupplier||[],
  showModal:listPayment.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>ListPaymentMiddelware.resetList(),
    search: data =>ListPaymentMiddelware.listSupplier(data),
    voidItem: (data,search) => ListPaymentMiddelware.beforeVoidSupplier(data, search),
  }, dispatch);
};
class ListSupplierContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    listPaymentSupplier: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.search(search);

  }

  voidItem(data,search) {
    this.props.voidItem(data,search);
  }

  constructor(props){
    super(props);
    this.voidItem = this.voidItem.bind(this);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("List Payment Supplier")}
          </title>
        </Helmet>
        <ListSupplier 
          {...this.props}
        />
        
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSupplierContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import PrintSelling from "../../../components/utilities/print/default/selling";
import SellingMiddleware from "../../../middleware/modules/sales/selling";

const mapStateToProps = ({ auth, selling }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  authUser:auth.authUser,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: selling.isProcessing,
  dataPrint: selling.printSelling,
  requestPrint:selling.requestPrint
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (id) => SellingMiddleware.getPrint(id),
    },
    dispatch
  );
};

class SellingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.id);
  }

  render() {
    return <PrintSelling {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SellingContainer);

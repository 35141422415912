import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { Helmet } from 'react-helmet';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LeftSide from './LeftSide';

class AuthLogin extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
      remember:false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const credentials = {
      username: this.state.username,
      password: this.state.password,
      remember_me: this.state.remember
    };
    this.props.signin(credentials);
  }

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  render() {
    const { isProcessing, isError, errorMessage } = this.props;
   
    return (
    
        <div className="d-flex flex-center w-lg-50 p-10 bg-primary">
        <Helmet
          titleTemplate="%s - Login"
          defaultTitle="Login"
        />
          <div className="card rounded-3 w-md-550px">
            <div className="card-body p-10 p-lg-20">
              <form className="form w-100" id="kt_sign_in_form" onSubmit={this.onSubmit} action="#">
                <div className="text-center mb-11">
                  <h1 className="text-dark fw-bolder mb-3">{i18n.t("Sign In")}</h1>
                </div>
                    <Alert show={isError} variant={"danger"}>
                      {i18n.t(errorMessage[0])}
                    </Alert>
                {/* <div className="row g-3 mb-9">
                  <div className="col-md-6">
                    <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                    <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                    <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
                    <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
                  </div>
                </div> */}
                {/* <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                </div> */}
                <div className="fv-row mb-8">
                  <input
                    value={this.state.username}
                    onChange={e => this.setState({ username: e.target.value })}
                    type="text"
                    placeholder={i18n.t("Username")}
                    required=""
                   name="username" autoComplete="off" className="form-control bg-transparent" />
                </div>
                <div className="fv-row mb-3 input-group custompasswo">
                  <input
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={i18n.t("Password")}
                    required=""
                    name="password" autoComplete="off" className="form-control bg-transparent" />
                    <button type="button" className="bg-none input-group-text" onClick={(e)=>this.togglePassword()}><FontAwesomeIcon icon={this.state.showPassword?faEyeSlash:faEye}/></button>
                </div>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                  <div className="checkbox">
                      <label htmlFor="remember">
                        <input id="remember" type="checkbox" value="1" onChange={e=>this.setState({ remember: !this.state.remember })} />
                        <i className="info" /> {i18n.t("Keep me signed in")}
                      </label>
                   </div>
                  <Link to="/reset-password" className="link-primary">{i18n.t("Forgot Password")} ?</Link>
                </div>
                <div className="d-grid mb-10">
                  <button type="submit" id="kt_sign_in_submit" className="btn btn-primary"
                      disabled={
                        !this.state.username ||
                        !this.state.password ||
                        isProcessing
                      }
                    >
                      {isProcessing ? 
                        <span className="indicator-progress " >{i18n.t("Please wait")}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                        : 
                        <span className="indicator-label">{i18n.t("Sign In")}</span>
                      }
                  </button>
                </div>
                {/* <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
                  <Link to="/signup" className="link-primary">
                    Sign up for free
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
    );
  }
}

export default AuthLogin;

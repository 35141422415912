import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import ApprovalActions from '../../../../reducers/modules/user/approval/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'user-access/user-approval'

export default class ApprovalMiddleware {

  static create(params){
    return (dispatch) => {
      dispatch(ApprovalActions.create());
      ApprovalMiddleware.showHelperFull(dispatch, params);
    };
  }

  static update(params){
    return (dispatch) => {
      ApprovalMiddleware.showOne(dispatch, params);
      dispatch(ApprovalActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(ApprovalActions.resetList());
      };
  }
  static list(params){
      return (dispatch) => {
          dispatch(ApprovalActions.process());
          ApprovalMiddleware.showlist(dispatch, params);
        };
  }
  static reset(){
    return (dispatch) => {
        dispatch(ApprovalActions.reset());
      };
}
  static getData(params){
    return (dispatch) => {
        dispatch(ApprovalActions.process());
        ApprovalMiddleware.show(dispatch, params);
      };
  }

  static beforeSave(post){
    return (dispatch) => {
        dispatch(ApprovalActions.submit());
        ApprovalMiddleware.saveNew(dispatch, post);
    };
  }
  static beforeSaveUpdate(params,post,search){
    return (dispatch) => {
        dispatch(ApprovalActions.submit());
        ApprovalMiddleware.saveUpdate(dispatch, params, post,search);
      };
  }
  static beforeDelete(id,params){
    return (dispatch) => {
        dispatch(ApprovalActions.delete());
        ApprovalMiddleware.delete(dispatch,params,id);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(){
    return (dispatch) => {
      ApprovalMiddleware.showHelper(dispatch);
      };
  }

 
  static showHelper(dispatch){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ApprovalMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-user-approval' ,
        responseType: 'json',
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(ApprovalActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ApprovalMiddleware.alertHendler(dispatch,error)
      });
  }


  static show(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ApprovalMiddleware.getToken()}`
        },
        url: url,
        params:params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ApprovalActions.view(response.data.result));
        }
      })
      .catch((error) => {
        ApprovalMiddleware.alertHendler(dispatch,error)
      });
  }
  static saveNew(dispatch, post,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ApprovalMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ApprovalActions.success(response.data.result));
          ApprovalMiddleware.alertHendler(dispatch,response,true,'Save Successfully','success')
        }
      })
      .catch((error) => {
        ApprovalMiddleware.alertHendler(dispatch,error)
      });
  }

  static saveUpdate(dispatch, params, post, search){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${ApprovalMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ApprovalActions.process(),ApprovalMiddleware.showlist(dispatch,search));
          dispatch(ApprovalActions.success());
          ApprovalMiddleware.alertHendler(dispatch,response,true,'Update Data Successfully','success')
        }
      })
      .catch((error) => {
        ApprovalMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        console.log(status)
        switch(status){
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(ApprovalActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(ApprovalActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}

export default class ReportVisitActions {

    static NEW_DATA = 'NEW_DATA';
    static RESET_DATA = 'RESET_DATA';
    static GET_HELPER_REPORTVISIT = 'GET_HELPER_REPORTVISIT';
    static GET_DATA = 'GET_DATA';
    static SET_TIMELINE = 'SET_TIMELINE';
    static LOADING_DETAILS = 'LOADING_DETAILS';
    static LOADED_DETAILS = 'LOADED_DETAILS';

    static loadingDetails(){
      return {
        type:ReportVisitActions.LOADING_DETAILS
      }
    }

    static loadedDetails(data){
      return {
        type:ReportVisitActions.LOADED_DETAILS,
        payload:data,
      }
    }

    static new(){
      return {
        type:ReportVisitActions.NEW_DATA
      }
    }
    static process(){
      return {
        type:ReportVisitActions.GET_DATA
      }
    }

    static setTimeline(data){
      return {
        type:ReportVisitActions.SET_TIMELINE,
        payload:data
      }
    }

    static reset(){
      return {
        type:ReportVisitActions.RESET_DATA
      }
    }
    static helper(data){
      return {
        type:ReportVisitActions.GET_HELPER_REPORTVISIT,
        payload:data
      }
    }
    
  }
  
  
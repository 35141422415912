import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { Helmet } from 'react-helmet';

class Page403 extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.object,
    signin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
     
      showPassword: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const credentials = {
      username: this.state.username,
      password: this.state.password,
      domain: this.state.domain
    };
    this.props.signin(credentials);
  }

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  render() {
    const { isProcessing, isError, errorMessage } = this.props;
    return (
      <div className="d-flex flex-column flex-center flex-column-fluid">
      
      <div className="d-flex flex-column flex-center text-center p-10">
     
        <div className="card card-flush w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
           
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Accces Denied</h1>
            
            <div className="fw-semibold fs-6 text-gray-500 mb-7">You don't have permission to access this page.</div>
           
            <div className="mb-3">
              <img src="/assets/media/auth/403-forbidden.png" className="mw-100 mh-300px theme-light-show" alt="" />
              <img src="/assets/media/auth/403-forbidden.png" className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
           
            <div className="mb-0">
              <Link className="btn btn-sm btn-primary" to={"/"}>Return Home</Link>
            </div>
           
          </div>
        </div>
       
      </div>
    </div>
    );
  }
}

export default Page403;

export default class CashSalesActions {
  
    //Reconcile
    static SHOW_RECONCILE = "SHOW_RECONCILE";
    static AFTER_SHOW_RECONCILE = "AFTER_SHOW_RECONCILE";
    static SET_HELPER = "SET_HELPER";
    static BEFORE_SAVE="BEFORE_SAVE"
    static AFTER_SAVE="AFTER_SAVE"
    static BEFORE_VOID="BEFORE_VOID"
    static SHOW_DATA="SHOW_DATA"
    static SHOW_DATA="SHOW_DATA"
    static SUCCESS_DATA="SUCCESS_DATA"
    static ERROR_DATA="ERROR_DATA"
    static PROCESS_DATA="PROCESS_DATA"
    static RESET_DATA="RESET_DATA"
    static RESET_LIST="RESET_LIST"
    static GET_HELPER_CASH="GET_HELPER_CASH"

    static beforeVoid(data){
      return {
        type:CashSalesActions.BEFORE_VOID,
        payload:data
      }
    }

    static setHelper(data){
      return {
        type:CashSalesActions.SET_HELPER,
        payload:data
      }
    }

    static showReconcile(data){
      return {
        type:CashSalesActions.SHOW_RECONCILE,
        payload:data
      }
    }

    static afterShowReconcile(data){
      return {
        type:CashSalesActions.AFTER_SHOW_RECONCILE,
        payload:data
      }
    }

    static beforeSave(){
      return {
        type:CashSalesActions.BEFORE_SAVE,
      }
    }
    static afterSave(){
      return {
        type:CashSalesActions.AFTER_SAVE,
      }
    }
  
    static list(data) {
      return {
        type: CashSalesActions.SHOW_DATA,
        payload: data
      };
    }

    
    static error(error){
      return {
        type: CashSalesActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: CashSalesActions.SUCCESS_DATA,
      };
    }

    static process(){
      return {
        type: CashSalesActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: CashSalesActions.RESET_DATA,
      };
    }

    static resetList(){
      return {
        type: CashSalesActions.RESET_LIST,
      };
    }

    static helper(data){
      return {
        type:  CashSalesActions.GET_HELPER_CASH,
        payload:data
      };
    }
  }
  
  
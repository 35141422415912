export default class SalesInvoiceReportActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static SHOW_DATA_CUSTOMER='SHOW_DATA_CUSTOMER';
  static SHOW_DATA_FULL_SELLING='SHOW_DATA_FULL_SELLING';
  static SHOW_DATA_FULL_SO='SHOW_DATA_FULL_SO';
  static SHOW_DATA_CUS_DETAIL='SHOW_DATA_CUS_DETAIL';
  static SHOW_DATA_INVOICE_DETAIL='SHOW_DATA_INVOICE_DETAIL';
  static SHOW_DATA_ITEM='SHOW_DATA_ITEM';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_SALESINVOICEREPORT='GET_HELPER_SALESINVOICEREPORT';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static submit(){
    return {
      type: SalesInvoiceReportActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: SalesInvoiceReportActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: SalesInvoiceReportActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: SalesInvoiceReportActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: SalesInvoiceReportActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA,
      payload: data
    };
  }

  static listCustomer(dataCus) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_CUSTOMER,
      payload: dataCus
    };
  }

  static listSO(dataSO) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_FULL_SO,
      payload: dataSO
    };
  }

  static listSelling(dataSelling) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_FULL_SELLING,
      payload: dataSelling
    };
  }

  static listCustomerDetail(dataCusDetail) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_CUS_DETAIL,
      payload: dataCusDetail
    };
  }
  
  static listInvoiceDetail(dataInvoiceDetail) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_INVOICE_DETAIL,
      payload: dataInvoiceDetail
    };
  }

  static listDataItem(dataItem) {
    return {
      type: SalesInvoiceReportActions.SHOW_DATA_ITEM,
      payload: dataItem
    };
  }

  static resetList() {
    return {
      type: SalesInvoiceReportActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: SalesInvoiceReportActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: SalesInvoiceReportActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: SalesInvoiceReportActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: SalesInvoiceReportActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: SalesInvoiceReportActions.GET_HELPER_SALESINVOICEREPORT,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: SalesInvoiceReportActions.ERROR_SERVER,
      payload: error
    };
  }
}
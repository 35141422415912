
import React,{ Component, Fragment } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from '../../../utilities/Form/InputNumber';
import Radio from '../../../utilities/Form/radio';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputCustom from '../../../utilities/Form/InputCustom';
import SwitchButton from '../../../utilities/Form/SwitchButton';
import AssetType from '.';
import { isUndefined } from 'lodash';

const MySwal = withReactContent(Swal);

export class FormAssetType extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
              AssetType:{},
            },
            name:'',
            isUpdate : 0,
            idBranch:null,
            branchCurrent: null,
            branchText: i18n.t("Select Branch"),
            branchList: [],
            idACC:null,
            accCurrent: null,
            accText: i18n.t("Select Account"),
            accList: [],
            coa3Debt:null,
            coa3DebtCurrent: null,
            coa3DebtText: i18n.t("Select Account"),
            coa3DebtList: [],
            coa3Credit:null,
            coa3CreditCurrent: null,
            coa3CreditText: i18n.t("Select Account"),
            coa3CreditList: [],
            idAccount:null,
            choose:true,
            date:new Date(),
            depreciation:0,
            used:0,
        }
        this.handler = this.handler.bind(this)
        this.handlerRadio = this.handlerRadio.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }

    runSubmit(){
        const AssetType = {
            idBranch:this.state.branchCurrent != null?this.state.branchCurrent.value:null,
            idAcc:this.state.accCurrent != null?this.state.accCurrent.value:null,
            idDebt:this.state.coa3DebtCurrent != null?this.state.coa3DebtCurrent.value:null,
            idCredit:this.state.coa3CreditCurrent != null?this.state.coa3CreditCurrent.value:null,
            choose:this.state.choose,
            name:this.state.name,
            depreciation:this.state.depreciation,
        }
        this.state.form.AssetType = AssetType;
        return this.state.form
    }

    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }

     choose(e){
        const arr = this.state.choose;
        this.setState({choose:!arr})
    }

    
    handlerNumber(e,name) {
    
        var key=name;
        var value=e
        console.log(value)
        this.setState({
            [key]:value 
        })
    }

    handlerRadio(e){
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value,
            supplierCurrent:null,
            customerCurrent:null,
            accountCurrent:null,
            soCurrent:null, poCurrent:null,
           
        })
    }

    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }

    changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;

                // var type =
                //     this.state.typeDP == 'Out' 
                //         ? 'all' : this.state.customerType == 'Cash'
                       

                params = {
                    branch: branch,
                    // type: 'All',
                }
            break;
            }
        if (type != null) {
            axios({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              url: url,
              responseType: "json",
              params: params,
            })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  let dataResponse=null
                  if (array != null) {
                    var arr = [...this.state[array]];
                    
                    const objectss = arr[arraykey];
                    if (key == "") {
                      dataResponse=response.data.result
                      objectss[keyFill] = response.data.result;
                    } else {
                      dataResponse=response.data.result[key]
                      objectss[keyFill] = response.data.result[key];
                    }
                  } else {
                    if (key == "") {
                      dataResponse=response.data.result
                      var arr = { [keyFill]: response.data.result };
                    } else {
                      dataResponse=response.data.result[key]
                      var arr = { [keyFill]: response.data.result[key] };
                    }
                  }
                  this.setState(arr, async () =>callback(dataResponse));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

    }

    render(){
        const {helperAssetType,isNewRecord,dataUpdate}=this.props
        console.log('PROPS',this.props)
        console.log('STATE',this.state)

        if(helperAssetType!=null && helperAssetType!=''){
            this.state.branchList=helperAssetType.branch
            this.state.accList=helperAssetType.account
            this.state.coa3CreditList=helperAssetType.coa3
            this.state.coa3DebtList=helperAssetType.coa3
        }

        if(!isNewRecord && dataUpdate != undefined){
            if(this.state.isUpdate == 0){
                this.state.branchCurrent = {value:dataUpdate.id_branch,label:dataUpdate.branch_name};
                this.state.name = dataUpdate.name
                this.state.accCurrent = {value:dataUpdate.id_account,label:dataUpdate.id_account_name};
                this.state.coa3DebtCurrent = {value:dataUpdate.coa3_debt,label:dataUpdate.coa3_debt_name};
                this.state.coa3CreditCurrent = {value:dataUpdate.coa3_credit,label:dataUpdate.coa3_credit_name};
                this.state.choose = dataUpdate.coa3_credit != null ? true : false;
                this.state.depreciation = dataUpdate.depreciation_format;
                this.state.used = dataUpdate.used;
            }
            this.state.isUpdate = 1;
        }
        
        return (
            <React.Fragment>
            <form>
            {this.state.used > 0 ?
            <Fragment>
               <div className="row">
                    <div className="col-md-10 mt-3">
                        <InputNumber
                            id={"depreciation"}
                            name="depreciation"
                            title={i18n.t("Depreciation")}
                            labelfield={"true"}

                            value={this.state.depreciation}
                            decimalsLimit={2}
                            onValueChange={(value, name) =>
                                this.handlerNumber(value, 'depreciation')
                            }
                            
                            className="form-control  form-control-md text-end"
                            
                        />
                    </div>
                    <div className="col-md-2 mt-8">
                        <label style={{fontWeight:'bold',marginTop:'20px'}}>
                            {i18n.t('Month')}
                        </label>
                    </div>
                </div>
            </Fragment>:<Fragment>
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"idBranch"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.name}
                            handler={this.handler}
                            className={'form-control  form-control-md '}

                            // error={this.renderError("ACC3Name")}
                        />
                    </div>
                </div>        
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"idACC"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "idACC", "accCurrent");
                            }}
                            options={this.state.accList}
                            title={i18n.t("Asset Account")}
                            value={this.state.accCurrent}
                            placeholder={this.state.accText}
                            labelfield={"true"}
                        />
                        {/* <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <label style={{fontWeight:'bold'}}>
                            {i18n.t("Asset Depreciation")}
                        </label>
                        <SwitchButton 
                            onChange={(e)=>this.choose(e)}
                            name={'chooseDeception'}
                            value={this.state.choose}
                            checked={this.state.choose?true:false}
                            className={'h-20px w-30px'}
                            position={'justify-content-left'}
                        ></SwitchButton>
                    </div>
                </div>
                {this.state.choose ? 
                <Fragment>
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"coa3Debt"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "coa3Debt", "coa3DebtCurrent");
                            }}
                            options={this.state.coa3DebtList}
                            title={i18n.t("Debt Account")}
                            value={this.state.coa3DebtCurrent}
                            placeholder={this.state.coa3DebtText}
                            labelfield={"true"}
                        />
                        {/* <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <Select2
                            isClearable={true}
                            name={"coa3Credit"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                await this.handlerSelect(e, "coa3Credit", "coa3CreditCurrent");
                            }}
                            options={this.state.coa3CreditList}
                            title={i18n.t("Credit Account")}
                            value={this.state.coa3CreditCurrent}
                            placeholder={this.state.coa3CreditText}
                            labelfield={"true"}
                        />
                        {/* <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError('idBranch')}
                        </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 mt-3">
                        <InputNumber
                            id={"depreciation"}
                            name="depreciation"
                            title={i18n.t("Depreciation")}
                            labelfield={"true"}

                            value={this.state.depreciation}
                            decimalsLimit={2}
                            onValueChange={(value, name) =>
                                this.handlerNumber(value, 'depreciation')
                            }
                           
                            className="form-control  form-control-md text-end"
                            
                        />
                    </div>
                    <div className="col-md-2 mt-8">
                        <label style={{fontWeight:'bold',marginTop:'20px'}}>
                            {i18n.t('Month')}
                        </label>
                    </div>
                </div>
                </Fragment>
                : null}
                </Fragment>}
            </form>
           </React.Fragment>
        );
    }
}

export default FormAssetType;
export default class CashBankActions {

    static NEW_DATA_IN = 'NEW_DATA_IN';
    static NEW_DATA_BANK = 'NEW_DATA_BANK';
    static PROCESS_DATA_IN ='PROCESS_DATA_IN';
    static PROCESS_DATA_BANK ='PROCESS_DATA_BANK';
    static ON_SUBMIT_IN ='ON_SUBMIT_IN';
    static ON_SUBMIT_BANK ='ON_SUBMIT_BANK';
    static SUCCESS_DATA_IN ='SUCCESS_DATA_IN';
    static SUCCESS_DATA_BANK ='SUCCESS_DATA_BANK';
    static ERROR_DATA_IN ='ERROR_DATA_IN';
    static ERROR_DATA_BANK ='ERROR_DATA_BANK';

    static UPDATE_DATA='UPDATE_DATA';
    static PROCESS_DATA = 'PROCESS_DATA';
    static ERROR_DATA = 'ERROR_DATA';
    static ON_SUBMIT = 'ON_SUBMIT';
    static NEW_DATA = 'NEW_DATA';
    static SHOW_DATA='SHOW_DATA';
    static CHANGE_STATUS='CHANGE_STATUS';
    static VIEW_DATA='VIEW_DATA';
    static CANCEL_DATA='CANCEL_DATA';
    static RESET_DATA='RESET_DATA';
    static GET_HELPER_JOURNAL='GET_HELPER_JOURNAL';
    static GET_HELPERFULL_JOURNAL='GET_HELPERFULL_JOURNAL';
    static GET_DETAIL = 'GET_DETAIL';
    static BEFORE_DETAIL = 'BEFORE_DETAIL';
    static AFTER_DETAIL = 'AFTER_DETAIL';
    static AFTER_DATA = 'AFTER_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_JOURNAL='PRINT_JOURNAL'
    static BEFORE_PRINT_JOURNAL='BEFORE_PRINT_JOURNAL'
  
    static submit(){
      return {
        type: CashBankActions.ON_SUBMIT
      };
    }
  
    static create() {
      return {
        type: CashBankActions.NEW_DATA,
      };
    }

    static createIn() {
      return {
        type: CashBankActions.NEW_DATA_IN,
      };
    }
    static submitIn() {
      return {
        type: CashBankActions.ON_SUBMIT_IN,
      };
    }
    static successIn() {
      return {
        type: CashBankActions.SUCCESS_DATA_IN,
      };
    }
    static errorIn(error) {
      return {
        type: CashBankActions.ERROR_DATA_IN,
        payload: error

      };
    }
    static processIn() {
      return {
        type: CashBankActions.PROCESS_DATA_IN,
      };
    }

    static createBank() {
      return {
        type: CashBankActions.NEW_DATA_BANK,
      };
    }
    static submitBank() {
      return {
        type: CashBankActions.ON_SUBMIT_BANK,
      };
    }
    static successBank() {
      return {
        type: CashBankActions.SUCCESS_DATA_BANK,
      };
    }
    static errorBank(error) {
      return {
        type: CashBankActions.ERROR_DATA_BANK,
        payload: error

      };
    }
    static processBank() {
      return {
        type: CashBankActions.PROCESS_DATA_BANK,
      };
    }
  
    static update(data) {
      return {
        type: CashBankActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: CashBankActions.VIEW_DATA,
        payload: data
      };
    }
  
    static cancel(data) {
      return {
        type: CashBankActions.CANCEL_DATA
      };
    }

    // static change(data) {
    //   return {
    //     type: CashBankActions.DELETE_DATA
    //   };
    // }
  
    static list(data) {
      return {
        type: CashBankActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: CashBankActions.ERROR_DATA,
        payload: error
      };
    }
   
    static process(){
      return {
        type: CashBankActions.PROCESS_DATA,
      };
    }
    static resetList(){
      return {
        type: CashBankActions.RESET_LIST,
      };
    }
    static reset(){
      return {
        type: CashBankActions.RESET_DATA,
      };
    }
    static helper(data){
      return {
        type: CashBankActions.GET_HELPER_JOURNAL,
        payload:data
      };
    }
    static helperFull(data){
      return {
        type: CashBankActions.GET_HELPERFULL_JOURNAL,
        payload:data
      };
    }
  
    static errorInternal(error){
      return {
        type: CashBankActions.ERROR_SERVER,
        payload: error
      };
    }

    static detail(data){
      return {
        type: CashBankActions.GET_DETAIL,
        payload: data
      };
    }

    static beforeDetail(data){
      return {
        type: CashBankActions.BEFORE_DETAIL,
        payload: data
      };
    }

    static afterDetail(data){
      return {
        type: CashBankActions.AFTER_DETAIL,
        payload: data
      };
    }

    static afterData(data){
      return {
        type: CashBankActions.AFTER_DATA,
        payload: data
      };
    }

    static beforePrint(){
      return {
        type: CashBankActions.BEFORE_PRINT_JOURNAL,
      };
    }
    static print(data){
      return {
        type: CashBankActions.PRINT_JOURNAL,
        payload: data
      };
    }
  }
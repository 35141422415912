import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import SalesInvoiceReportActions from '../../../../reducers/modules/report/sales-report/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'report/sales-report'

export default class SalesInvoiceReportMiddleware {

  static create(params){
    return (dispatch) => {
      dispatch(SalesInvoiceReportActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      SalesInvoiceReportMiddleware.showOne(dispatch, params);
      dispatch(SalesInvoiceReportActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.resetList());
      };
  }
  static list(params){
      return (dispatch) => {
          dispatch(SalesInvoiceReportActions.process());
          SalesInvoiceReportMiddleware.showlist(dispatch, params);
        };
  }

  static listCustomer(params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.process());
        SalesInvoiceReportMiddleware.showlistCustomer(dispatch, params);
      };
}

static listSO(params){
  return (dispatch) => {
      dispatch(SalesInvoiceReportActions.process());
      SalesInvoiceReportMiddleware.showlistSO(dispatch, params);
    };
}

static listSelling(params){
  return (dispatch) => {
      dispatch(SalesInvoiceReportActions.process());
      SalesInvoiceReportMiddleware.showlistSelling(dispatch, params);
    };
}

static listCustomerDetail(params){
  return (dispatch) => {
      dispatch(SalesInvoiceReportActions.process());
      SalesInvoiceReportMiddleware.showlistCustomerDetail(dispatch, params);
    };
}

static listInvoiceDetail(params){
  return (dispatch) => {
      dispatch(SalesInvoiceReportActions.process());
      SalesInvoiceReportMiddleware.showlistInvoiceDetail(dispatch, params);
    };
}

static listItem(params){
  return (dispatch) => {
      dispatch(SalesInvoiceReportActions.process());
      SalesInvoiceReportMiddleware.showlistDataItem(dispatch, params);
    };
}

  static showExcel(params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.process());
        SalesInvoiceReportMiddleware.beforeShowExcel(dispatch, params);
      };
}

  static beforeShowExcel(dispatch, params) {
 
  } 

  static listing(params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.process());
        SalesInvoiceReportMiddleware.showlisting(dispatch, params);
      };
}
  static reset(){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.view());
        SalesInvoiceReportMiddleware.showOne(dispatch, params);
      };
  }

  static beforeSaveNew(post,search){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.submit());
        SalesInvoiceReportMiddleware.saveNew(dispatch, post,search);
      };
  }
  static beforeSaveUpdate(post,params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.submit());
        SalesInvoiceReportMiddleware.saveUpdate(dispatch, params, post);
      };
  }
  static beforeDelete(id,params){
    return (dispatch) => {
        dispatch(SalesInvoiceReportActions.delete());
        SalesInvoiceReportMiddleware.delete(dispatch,params,id);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(params=null){
    return (dispatch) => {
      SalesInvoiceReportMiddleware.showHelper(dispatch,params);
      };
  }

 
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-sales-report' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(SalesInvoiceReportActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
  }

  static showlist(dispatch, params){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
          },
          url: url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          
          if (response && response.status === 200 && response.data) {
            dispatch(SalesInvoiceReportActions.list(response.data.result));
          }
        })
        .catch((error) => {
          SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
        });
  }

  static showlistCustomer(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url:process.env.REST_URL +'report/sales-report-customer',
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(SalesInvoiceReportActions.listCustomer(response.data.result));
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
}

static showlistSO(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'report/sales-invoice-so',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(SalesInvoiceReportActions.listSO(response.data.result));
      }
    })
    .catch((error) => {
      SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
    });
}

static showlistSelling(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'report/sales-invoice-selling',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(SalesInvoiceReportActions.listSelling(response.data.result));
      }
    })
    .catch((error) => {
      SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
    });
}

static showlistCustomerDetail(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'report/sales-report-customer-detail',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(SalesInvoiceReportActions.listCustomerDetail(response.data.result));
      }
    })
    .catch((error) => {
      SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
    });
}

static showlistInvoiceDetail(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'report/sales-invoice-report-detail',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(SalesInvoiceReportActions.listInvoiceDetail(response.data.result));
      }
    })
    .catch((error) => {
      SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
    });
}

static showlistDataItem(dispatch, params){
  axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
      },
      url:process.env.REST_URL +'report/sales-report-item',
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        dispatch(SalesInvoiceReportActions.listDataItem(response.data.result));
      }
    })
    .catch((error) => {
      SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
    });
}

  static showlisting(dispatch, params){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url: url,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(SalesInvoiceReportActions.list(response.data.result));
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
}

  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SalesInvoiceReportActions.view(response.data.result));
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
  }

  static delete(dispatch, params,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url: url +'/'+id,
        responseType: 'json'
      })
      .then((response) => {
       
        if (response && response.status === 200 && response.data) {
          MySwal.close()
          dispatch(SalesInvoiceReportActions.process(),SalesInvoiceReportMiddleware.showlist(dispatch, params));
          SalesInvoiceReportMiddleware.alertHendler(dispatch,response,true,'Delete Data Successfully','success')
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error,true)
      });
  }

  static saveNew(dispatch, post,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SalesInvoiceReportActions.process(),SalesInvoiceReportMiddleware.showlist(dispatch,search));
          dispatch(SalesInvoiceReportActions.success());
          SalesInvoiceReportMiddleware.alertHendler(dispatch,response,true,'Create Data Successfully','success')
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
  }

  static saveUpdate(dispatch, params, post){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          // dispatch(SalesInvoiceReportActions.process(),SalesInvoiceReportActions.showlist(dispatch,search));
          // dispatch(SalesInvoiceReportActions.success());
          // SalesInvoiceReportMiddleware.alertHendler(dispatch,response,true,'Update Data Successfully','success')
        }
      })
      .catch((error) => {
        SalesInvoiceReportMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        switch(status){
          
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(SalesInvoiceReportActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(SalesInvoiceReportActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}


import React from "react";
import i18n from "../../../i18n";

const LabelVoid=(props)=>{
    switch(props.value){
        case 1:
            return <div className="badge badge-light-success">{i18n.t("Active")}</div>
        case 0:
            return <div className="badge badge-light-danger">{i18n.t("Void")}</div>
    }
}
export default LabelVoid;
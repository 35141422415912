import AllowanceDetailActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperAllowanceDetail:[],
  errorInternal:false
};

function AllowanceDetailReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AllowanceDetailActions.RESET_LIST:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,data:{}};
    case AllowanceDetailActions.NEW_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false,single:null, errorMessage:[],form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case AllowanceDetailActions.UPDATE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:null,isNewRecord:false};
    case AllowanceDetailActions.VIEW_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, single:action.payload,errorMessage:[],isNewRecord:false,showModal:true};
    case AllowanceDetailActions.RESET_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case AllowanceDetailActions.DELETE_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case AllowanceDetailActions.ON_SUBMIT:
      return { ...state, errorInternal:false,onSubmit:true};
    case AllowanceDetailActions.SHOW_DATA:
      return { ...state, errorInternal:false,onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case AllowanceDetailActions.ERROR_DATA:
      return { ...state, errorInternal:false, isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case AllowanceDetailActions.SUCCESS_DATA:
      return { ...state,errorInternal:false,onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case AllowanceDetailActions.PROCESS_DATA:
      return { ...state, errorInternal:false,onSubmit:false,isProcessing: true, isError: false,isSuccess:false};
    case AllowanceDetailActions.GET_HELPER_ALLOWANCEDETAIL:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperAllowanceDetail:action.payload};
    case AllowanceDetailActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default AllowanceDetailReducer;

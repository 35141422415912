import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Spinner, Tab, Tabs, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import PurchasingSupplierReport from "../../../../containers/modules/report/purchasing-report/purchasing-supplier";
import PurchasingAllReport from "../../../../containers/modules/report/purchasing-report/purchasing";
import PurchasingItemReport from "../../../../containers/modules/report/purchasing-report/purchasing-item";
import PurchasingFullReport from "../../../../containers/modules/report/purchasing-report/purchasing-full";

const MySwal = withReactContent(Swal);
export class PurchasingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      show :null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Purchasing Report"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);

  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  renderTab() {
    const {datalistItem, helperList, datalist, data,dataSupp,datalistDetail,datalistInvoiceDetail,datalistPO,datalistReceiving } = this.props;
    this.state.supplierList = helperList;
    this.state.listData = datalist;
    return (
      <Tabs
        defaultActiveKey="PurchasingFull"
        id={"tabs"}
        className="mb-3"
      >
        <Tab eventKey="PurchasingAll" title={i18n.t("Purchase")}>
          <PurchasingAllReport  
          data= {data}
          />
        </Tab>
        <Tab eventKey="PurchasingSupplier" title={i18n.t("Purchase By Supplier")}>
        <PurchasingSupplierReport 
           dataSupp = {dataSupp}
           datalistDetail = {datalistDetail}
           datalistInvoiceDetail = {datalistInvoiceDetail}
           item = {datalistDetail}
        />
        </Tab>
        <Tab eventKey="PurchasingItem" title={i18n.t("Purchase By Item")}>
          <PurchasingItemReport
            dataItem = {datalistItem}
          />
        </Tab>
        <Tab eventKey="PurchasingFull" title={i18n.t("Purchase Full")}>
          <PurchasingFullReport
           dataPO = {datalistPO}
           dataReceiving={datalistReceiving}
           dataInvoiceDetail = {datalistInvoiceDetail}
          />
        </Tab>
      </Tabs>
    );

  }
  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    this.state.supplierList = helperList;
    this.state.listData = datalist;

    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default PurchasingReport;

import ItemCategoryActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperItemCategory: null,
  errorInternal: false,
};

function ItemCategoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ItemCategoryActions.RESET_LIST:
      return INITIAL_STATE;
    case ItemCategoryActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case ItemCategoryActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case ItemCategoryActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case ItemCategoryActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case ItemCategoryActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case ItemCategoryActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case ItemCategoryActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case ItemCategoryActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case ItemCategoryActions.PROCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: true,
        isError: false,
        isSuccess: false,
      };
    case ItemCategoryActions.ERROR_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case ItemCategoryActions.GET_HELPER_ITEMCATEGORY:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperItemCategory: action.payload,
      };
    case ItemCategoryActions.ERROR_SERVER:
      return {
        ...state,
        onSubmit: false,
        isError: false,
        errorInternal: true,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default ItemCategoryReducer;

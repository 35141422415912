import React, { Component, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import {  Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
const MySwal = withReactContent(Swal);
export class DailyAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList:[],
      branchCurrent:null,
      idBranch:null,
      branchText:i18n.t('Select Branch'),
      absence:[],
      date:new Date(),
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Hr"), class: " text-gray-600" },
        { link: null, title: i18n.t("Daily Absence"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    
  }
  
  checkValue(employeeID){
    const {data}=this.props
    if(data.data !=undefined){
      let employee=data.data.filter((employee)=> employee.employeeID ==employeeID);
      let attendanceID=employee.attendanceID
      return employee[0].attendanceID
    }else{
      return null
    }
  }
  handlerDateTime(e,name) {
    var key=name;
    var value=e
    this.setState({
        [key]:value
    },()=>{
      this.search()
    })
}
handlerSelect(e,name,currentState) {
  var key=name
  var value=null
  if(e!=null){
      value=e.value
  }
  this.setState({
      [key]:value,
      [currentState]:e
  },()=>{
    this.search()
  })
}
  handler(e,id) {
    var value=e.target.value
    this.setState({
        ...this.state, 
        absence: {
            ...this.state.absence,
            [id]:value
        } 
    },()=>{
      let post={
        value:value,
      }
      this.props.updateAbsence(post,id)})
  }
  search(){
    let search={
      date:moment(this.state.date).format('YYYY-MM-DD'),
      branch:this.state.idBranch
    }
    this.props.search(search);
  }
  renderList(){
    const {helperList,data}=this.props
    console.log(this.props)
    if(data !=undefined &&data !=null && helperList.attendance!=undefined){
      var attendance=helperList.attendance
      let attendanceList={}
      for(var p=0;p<attendance.length;p++){
        var value=attendance[p].value;
        var label=attendance[p].label;
        attendanceList= Object.assign(attendanceList,{[value]:label}) 
      }
      let html=[];
      let no=1;
      for(var division in data){
        html.push(
              <tr key={"division"+division} className="fw-semibold fs-4 text-gray-800 bg-lightgray">
                  <td colSpan={6} className="p-3 ">{division}</td>    
              </tr>)
        if(data[division]!=null){
          html.push(data[division].map((row,i)=>
              <tr key={"employee"+no+i}>
                 <td className="text-center">{no++}</td>
                <td>{row.employee}</td>
                <td>{row.nameBranch}</td>
                <td>{row.position}</td>
                <td style={{textAlign:'center'}}>{row.check_in}</td>
                <td className="text-center">
                  <Radio
                      labelfield={"false"}
                      list={attendanceList}
                      name={"Attandance"+row.id}
                      title={i18n.t("Status")}
                      inline={true}
                      value={(this.state.absence[row.id]!=undefined)?this.state.absence[row.id]:null}
                      handler={(e)=>this.handler(e,row.id)}
                      defaultChecked={row.attendanceID}
                  />
                </td>
              </tr>))
        }
      }
      return html;
    }

  }
  render() {
    const { isProcessing,helperList} = this.props;
    this.state.branchList=helperList.branch
    return (
      <div id="salary-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4">
                  <div className="col-sm-3">
                    <DatePickerCustom
                        name={"date"}
                        title={i18n.t("Date")}
                        required={true}
                        selected={this.state.date}
                        onSelect={async (e)=>{
                                await this.handlerDateTime(e,'date');
                            }
                        }
                        onChange={(e)=>{}}
                        startDay={0}
                        autoComplete={"off"}
                        dateFormat="dd MMM yyyy"
                        maxDate={new Date()}
                        className="form-control form-control-sm"
                        ></DatePickerCustom>
                    </div>
                    <div className="col-sm-3">
                      <Select2
                          isClearable={true}
                          name={"idBranch"}
                          required={true}
                          onChange={async (e)=>{
                            if(e==null){
                              this.handlerSelect(e, "idBranch", "branchCurrent")
                            }
                            else{
                              this.handlerSelect(e, "idBranch", "branchCurrent")
                            }
                             
                          }}
                          options={this.state.branchList}
                          title={i18n.t("Branch")}
                          value={this.state.branchCurrent}
                          placeholder={this.state.branchText}
                          error={null}
                        />
                    </div>
                    <div className="col text-end">
                     <Button onClick={(e)=>{
                      this.search();
                     }} variant="warning" className="btn btn-sm btn btn-warning mt-4"><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon></Button>
                    </div>
                </div>
               
                {!isProcessing?<table className="table table-responsive table-bordered table-hover">
                  <thead>
                    <tr className="fw-semibold fs-6 text-gray-800 bg-aquamarine">
                      <th width={"5%"} className="text-center">{i18n.t("No.")}</th>
                      <th width={"20%"}>{i18n.t("Employee")}</th>
                      <th width={"15%"}>{i18n.t("Branch")}</th>
                      <th width={"10%"}>{i18n.t("Position")}</th>
                      <th width={"10%"}>{i18n.t("Check In")}</th>
                      <th width={"40%"} className="text-center">{i18n.t("Attendance")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderList()}
                  </tbody>
                </table>:<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>}
                

              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}

export default DailyAttendance;

import React, { Component, Fragment } from "react";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select2 from "../../../utilities/Form/Select2";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faDisplay, faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
export class FormAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      dateRange: new Date(),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      periodCurrent: null,
      periodText: i18n.t("Select Week"),
      periodList: [],
      brandCurrent: null,
      brandText: i18n.t("All"),
      brandList: [],
      categoryCurrent: null,
      categoryText: i18n.t("All"),
      categoryList: [],
      itemTypeList: [],
      itemTypeText: i18n.t("All"),
      warehouseCurrent: null,
      warehouseList: [],
      warehouseText: i18n.t("All"),
      data: [],
      type: 2,
      show: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.showItem = this.showItem.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props
    var value = ''
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0]
      }
    }
    return value;
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  changeData(stateName, type, result = "") {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;

      default:
        type = null;
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (result == "") {
              var arr = { [stateName]: response.data.result };
            } else {
              var arr = { [stateName]: response.data.result[result] };
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  convertExcel() {
    var idBranch =
      this.state.branchCurrent != null ? this.state.branchCurrent.value : null;
    var idType =
      this.state.itemTypeCurrent != null ? this.state.itemTypeCurrent.value : null;
    var brand =
      this.state.brandCurrent != null ? this.state.brandCurrent.value : null;
    var category =
      this.state.categoryCurrent != null ? this.state.categoryCurrent.value : null;
    var idWarehouse =
      this.state.warehouseCurrent != null
        ? this.state.warehouseCurrent.value
        : null;
    var startDate = this.state.type == 1 ? moment(this.state.periodCurrent.startDate).format("YYYY-MM-DD")
      : moment(this.state.startDate).format("YYYY-MM-DD");
    var endDate = this.state.type == 1 ? moment(this.state.periodCurrent.endDate).format("YYYY-MM-DD")
      : moment(this.state.endDate).format("YYYY-MM-DD");
    let params = {
      branch: idBranch,
      startDate: startDate,
      endDate: endDate,
      idWarehouse: idWarehouse,
      idType: idType,
      category: category,
      brand: brand
    };
    this.props.getDataItem(params);
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  alertHandler(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  async convertExcel1() {
    var idBranch =
      this.state.branchCurrent != null ? this.state.branchCurrent.value : null;
    var idType =
      this.state.itemTypeCurrent != null ? this.state.itemTypeCurrent.value : null;
    var brand =
      this.state.brandCurrent != null ? this.state.brandCurrent.value : null;
    var category =
      this.state.categoryCurrent != null ? this.state.categoryCurrent.value : null;
    var idWarehouse =
      this.state.warehouseCurrent != null
        ? this.state.warehouseCurrent.value
        : null;

    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);
    var week = (this.state.periodCurrent == null ? '' : this.state.periodCurrent.week)
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "mutation/stock-card/excel",
      method: "POST",
      params: {
        branch: idBranch,
        startDate: startDate,
        endDate: endDate,
        idWarehouse: idWarehouse,
        idType: idType,
        category: category,
        brand: brand,
        week: week
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
          moment(startDate).format("D/M/YYYY") + ' - ' +
          moment(endDate).format("D/M/YYYY") + ' ' +
          ' Laporan Persediaan.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        Swal.close();
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  showItem() {
    var idBranch =
      this.state.branchCurrent != null ? this.state.branchCurrent.value : null;
    var idType =
      this.state.itemTypeCurrent != null ? this.state.itemTypeCurrent.value : null;
    var brand =
      this.state.brandCurrent != null ? this.state.brandCurrent.value : null;
    var category =
      this.state.categoryCurrent != null ? this.state.categoryCurrent.value : null;
    var idWarehouse =
      this.state.warehouseCurrent != null
        ? this.state.warehouseCurrent.value
        : null;
    var startDate = this.state.type == 1 ? moment(this.state.periodCurrent.startDate).format("YYYY-MM-DD")
      : moment(this.state.startDate).format("YYYY-MM-DD");
    var endDate = this.state.type == 1 ? moment(this.state.periodCurrent.endDate).format("YYYY-MM-DD")
      : moment(this.state.endDate).format("YYYY-MM-DD");
    let params = {
      branch: idBranch,
      startDate: startDate,
      endDate: endDate,
      idWarehouse: idWarehouse,
      idType: idType,
      category: category,
      brand: brand
    };
    this.props.getDataItem(params);
  }
  render() {
    const { helperList } = this.props;
    this.state.branchList = (helperList.branch != undefined) ? helperList.branch : [];
    this.state.itemList = (helperList.item != undefined) ? helperList.item : [];
    this.state.itemTypeList = (helperList.itemT != undefined) ? helperList.itemT : [];
    this.state.periodList = (helperList.workCalendar != undefined) ? helperList.workCalendar : [];
    this.state.brandList = (helperList.brand != undefined) ? helperList.brand : [];
    this.state.categoryList = (helperList.category != undefined) ? helperList.category : [];
    let params = {
      branch: this.state.branchCurrent != null ? this.state.branchCurrent.value : null,

      idType: this.state.itemTypeCurrent != null ? this.state.itemTypeCurrent.value : null,
    };
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-mb-3">
              <Radio
                list={Period}
                name={"type"}
                title={""}
                inline={false}
                value={this.state.type}
                handler={this.handlerRadio}
              />
            </div>
          </div>
          <div className="row">
            {this.state.type == 1 ?
              <Fragment>
                <div className="col-3 mt-3">
                  <Select2
                    isClearable={true}
                    name={"period"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "idPeriod", "periodCurrent");
                    }}
                    options={this.state.periodList}
                    title={i18n.t("Period")}
                    value={this.state.periodCurrent}
                    placeholder={this.state.periodText}
                  />
                  {/* <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError('idBranch')}</div> */}

                </div>
              </Fragment>
              :
              <Fragment>
                <div className="col-3 mt-3">
                  <DatePickerCustom
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    selectsRange={true}
                    isClearable={true}
                    name={"date"}
                    title={i18n.t("Date Period")}
                    required={true}
                    onChange={(e) => {
                      const [start, end] = e;
                      this.setState({ startDate: start, endDate: end });
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    peekNextMonth
                    showMonthDropdown
                    autoComplete={"off"}
                    dateFormat="dd MMM yyyy"
                    maxDate={new Date()}
                    className="form-control form-control-sm"
                  />
                </div>

              </Fragment>
            }
            <div className="col-3 mt-3">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                size={4}
                onChange={async (e) => {
                  if (e != this.state.branchCurrent) {
                    await this.setState({ storeList: [] }, () => {
                      this.handlerSelect(null, "idStore", "storeCurrent");
                    });
                  }
                  if (e == null) {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.setState({ warehouseList: [], itemTypeCurrent: null, branchCurrent: null, categoryCurrent: null, warehouseCurrent: null })
                  } else {
                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                    await this.changeData("storeList", "getStore", "");
                    await this.changeData("warehouseList", "getWarehouse", "");
                  }
                }}
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError('branch')}</div>

            </div>
            <div className="col-3 mt-3">
              <Select2
                isClearable={true}
                name={"idWarehouse"}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(
                    e,
                    "idWarehouse",
                    "warehouseCurrent"
                  );
                }}
                options={this.state.warehouseList}
                title={i18n.t("Warehouse")}
                value={this.state.warehouseCurrent}
                placeholder={this.state.warehouseText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 mt-3">
              <Select2
                isClearable={true}
                name={"brand"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.setState({ categoryList: [], categoryCurrent: null, itemTypeCurrent: null, itemTypeList: [] })
                  if (e == null) {
                    await this.handlerSelect(e, "brandItem", "brandCurrent");
                  } else {
                    await this.handlerSelect(e, "brandItem", "brandCurrent");
                  }
                }}
                options={this.state.brandList}
                title={i18n.t("Item Brand")}
                value={this.state.brandCurrent}
                placeholder={this.state.brandText}
              />
            </div>
            <div className="col-3 mt-3">
              <Select2
                isClearable={true}
                name={"category"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "categoryItem", "categoryCurrent");
                }}
                options={this.state.categoryList}
                title={i18n.t("Item Category")}
                value={this.state.categoryCurrent}
                placeholder={this.state.categoryText}
              />
            </div>
            {/* <div className="col-3 mt-3">
              <Select2
                isClearable={true}
                name={"itemType"}
                required={true}
                size={4}
                onChange={async (e) => {
                  await this.handlerSelect(e, "itemType", "itemTypeCurrent");
                }}
                options={this.state.itemTypeList}
                title={i18n.t("Item Type")}
                value={this.state.itemTypeCurrent}
                placeholder={this.state.itemTypeText}
              />
            </div> */}
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Convert
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item"
                href={"/mutation/mutation/print/" + (this.state.branchCurrent != null ? this.state.branchCurrent.value : null) + "/" +
                  (this.state.brandCurrent != null ? this.state.brandCurrent.value : 'all') + "/" +
                  (this.state.categoryCurrent != null ? this.state.categoryCurrent.value : 'all') + "/" +
                  (this.state.warehouseCurrent != null ? this.state.warehouseCurrent.value : 'all') + "/" +
                  (this.state.type == 2 ? this.state.startDate.getFullYear() + '-0' + this.state.startDate.getMonth() + '-' + this.state.startDate.getDate()
                    : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate)) + "/" +
                  (this.state.type == 2 ? this.state.endDate.getFullYear() + '-0' + this.state.endDate.getMonth() + '-' + this.state.endDate.getDate()
                    : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate))
                } target="_blank">
                <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  PDF</a>
              <a class="dropdown-item" href="#" onClick={() => this.convertExcel1()}><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>  Excel </a>
            </div>
          </div>
          {/* <Button
            variant="success"
            className="btn btn-sm"
            onClick={async () => {
              await this.showItem();
            }}
          >
            {" "}
            {i18n.t("Show")}
          </Button> */}
          <hr />
        </form>
      </React.Fragment>
    );
  }
}

export default FormAll;

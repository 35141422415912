import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import AssetTypeMiddleware from "../../../../middleware/modules/accounting/asset-type";
import FormView from "../../../../components/modules/accounting/asset-type/view";

const mapStateToProps = ({ auth, assetType }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: assetType.isProcessing,
  isError: assetType.isError,
  errorMessage: assetType.errorMessage,
  dataUpdate: assetType.single,
  isSuccess: assetType.isSuccess,
  isReset: assetType.isReset,
  isNewRecord: assetType.isNewRecord,
  onSubmit: assetType.onSubmit,
  helperAsset:assetType.helperAsset
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => AssetTypeMiddleware.reset(),
      submitData: (data,search) =>AssetTypeMiddleware.beforeSaveNew(data,search),
      cancel: () =>AssetTypeMiddleware.reset(),
      update: (id, type, search) =>
        AssetTypeMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormViewContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      add:0,
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.close = this.close.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  close() {
    this.props.closeModal();
  }

  submitForm() {
    const {isNewRecord,dataUpdate } = this.props;
    const search = {
      limit: 10,
    };
    var form = this.form.current.runSubmit();
    if (isNewRecord) {
      this.props.submitData(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
    // var form = this.form.current.runSubmit();
    //   this.props.submitData(form,search);
  }

  render() {
    const {showModal,titleModal,onSubmit,isProcessing} = this.props
    return (
      <>
        <Modal show={showModal} size="md" enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <FormView
              {...this.props}
              ref={this.form}
              isProcessing={isProcessing}
              // helperAsset={helperAsset}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            {/* <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormViewContainer);

import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        padding: "20px",
    },
    row: {

        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        flexDirection: 'row',
    },
    td: {
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx: {
        fontSize: "17px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center'
    },
    tableTitle: {
        border: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },

    tableRemark: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        fontSize: "12px",
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        borderTop: 1,
        fontFamily: 'Times-Roman',
    },

    tableSign: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontSize: "12px",
        height: '15%',
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        fontFamily: 'Times-Roman',
    },

    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintPO extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: i18n.t("Purchase Order No") + ' : ',
            merchant: {
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address: []
            },
            logo: '/assets/media/logos/logo.jpg',
            data: {
                poNo: '',
                po_detail: []
            }
        };
    }
    renderMessage() {
        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{ fontSize: 36 }}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={() => { this.props.getPrint(this.props.poid) }}>Reload Page</Button></div>
                    </div>)
        }
    }
    render() {
        let FillText = "";
        const { dataPrint } = this.props
        let total = 0
        let totalDiscount = 0
        let totalTax = 0
        let totalAll = 0
        let address = []
        if (dataPrint != null) {
            this.state.data = dataPrint.model
            this.state.merchant = dataPrint.merchant
            address = this.state.merchant.address.split(/\r?\n/)
            total = this.state.data.po_detail.map(item => item.qty * parseFloat(item.unitPrice)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalDiscount = this.state.data.po_detail.map(item => item.qty * parseFloat((item.discount_rate) ? item.discount_rate : 0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalTax = this.state.data.po_detail.map(item => item.qty * parseFloat((item.tax) ? item.tax : 0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue, 0)
            totalAll = (total - totalDiscount) - totalTax
        }
        return (
            <React.Fragment>{(dataPrint != null) ?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title + ((dataPrint != undefined) ? this.state.data.poNo : '')}>
                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col, { width: '90px' }} >
                                            <Image src={(this.state.merchant.logo != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col, { width: '300px' }} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map(a =>
                                                <Text style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={[styles.col, { width: '600px', paddingTop: '20px' }]} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Purchase Order (PO)")}</Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Order No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '100px' }]} >
                                                        <Text>{this.state.data.poNo}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Date Order")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '100px' }]} >
                                                        <Text>{this.state.data.date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Delivery Date")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '100px' }]} >
                                                        <Text>{this.state.data.delivery_date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Supplier")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '440px' }]}>
                                                        <Text>{this.state.data.name_supplier}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '80px' }]} >
                                                        <Text>{i18n.t("Address")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '440px' }]}>
                                                        <Text>{this.state.data.address_supplier}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableContainer}>
                                    <View style={styles.containerItem}>
                                        <Text style={{ width: '5%' }}>{i18n.t("No")}</Text>
                                        <Text style={{ width: '15%' }}>{i18n.t("Item Code")}</Text>
                                        <Text style={{ width: '20%' }}>{i18n.t("Item")}</Text>
                                        <Text style={{ width: '13%' }}>{i18n.t("Qty")}</Text>
                                        <Text style={{ width: '15%' }}>{i18n.t("@Price")}</Text>
                                        <Text style={{ width: '13%' }}>{i18n.t("Discount")}</Text>
                                        <Text style={{ width: '19%' }}>{i18n.t("Total Price")}</Text>
                                    </View>
                                    <Fragment>{this.state.data.po_detail.map((item, i) =>
                                        <View style={styles.rowItem} key={i + 'itemPo'} >
                                            <Text style={{ width: '5%', padding: 1 }}>{i + 1}</Text>
                                            <Text style={{ width: '15%', padding: 1 }}>{item.item_code}</Text>
                                            <Text style={{ width: '20%', padding: 1 }}>{item.item_name}</Text>
                                            <Text style={{ width: '13%', padding: 1 }}>{item.qty_format + ' ' + item.unit_name}</Text>
                                            <Text style={{ width: '15%', padding: 1, textAlign: "right" }}>{item.unitPrice_format}</Text>
                                            <Text style={{ width: '13%', padding: 1, textAlign: "right" }}>{item.discount_rate_format}</Text>
                                            <Text style={{ width: '19%', padding: 1, textAlign: "right" }} >{item.total_format}</Text>
                                        </View>
                                    )}</Fragment>

                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col, { width: '500px', paddingTop: '20px' }} >
                                            <View style={[styles.tableTotal, { textAlign: 'left' }]}>
                                                <Text style={{ fontFamily: 'Times-Bold' }}>Note :</Text>
                                            </View>
                                            <View style={styles.tableTotal} >
                                                <Text>{this.state.data.description}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.col, { width: '300px', paddingTop: '20px', position: 'right' }} >
                                            <View style={styles.tableTotal}>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>Sub Total</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                        <Text>{parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>Discount</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                        <Text>{parseFloat(totalDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>Tax</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '120px', textAlign: 'right' }]} >
                                                        <Text>{parseFloat(totalTax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '200px', borderBottom: '1px' }]} >
                                                        <Text></Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.tr, { fontFamily: 'Times-Bold', fontSize: '16px' }]}>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text>Total</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '120px', textAlign: 'right' }]}>
                                                        <Text>{parseFloat(totalAll).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableRemark}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text>{item.remark}</Text>
                                        </View>
                                    )}
                                        <View style={styles.colBorder} >
                                            <Text>{"Dibuat Oleh"}</Text>
                                        </View>
                                    </Fragment>
                                </View>
                                <View style={styles.tableSign}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text style={[styles.td, { height: '100%' }]}>{ }</Text>
                                        </View>
                                    )}
                                        <View style={styles.colBorder} >
                                            <Text style={[styles.td, { height: '100%' }]}>{ }</Text>
                                        </View>
                                    </Fragment>
                                </View>
                                <View style={styles.tableName}>
                                    <Fragment>{this.state.data.approval.map((item, i) =>
                                        <View style={styles.colBorder} key={i + 'itemPo'}>
                                            <Text>({item.username})</Text>
                                        </View>
                                    )}
                                        <View style={styles.colBorder} >
                                            <Text>({this.state.data.created_by})</Text>
                                        </View>
                                    </Fragment>
                                </View>

                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                :
                this.renderMessage()}

            </React.Fragment>

        )
    }
}

export default PrintPO;

const loader = {
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
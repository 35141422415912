import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
// import AuthMiddleware from '../../../../reducers/auth/middleware';
import { Navigate } from 'react-router-dom';
import SubDistrictMiddleware from '../../../../middleware/modules/master/sub-district';
import SubDistrict from '../../../../components/modules/master/sub-district';
import useScript from '../../../../components/utilities/useScript';

const mapStateToProps = ({ auth,subdistrict }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist:subdistrict.data
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    SubDistrictList: customer =>SubDistrictMiddleware.list(customer)
  }, dispatch);
};
class SubDistrictContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.object,
    datalist: PropTypes.object,
    SubDistrictList: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {}
  }
  componentWillMount() {
    var host=process.env.APP_URL
    const search = {
      limit: 10,
    };
   this.props.SubDistrictList(search);
  }
  render() {
    const { isAuthenticated, isProcessing, isRegistered, isError, errorMessage, SubDistrictList ,datalist} = this.props;
    // if(isAuthenticated){
    //   return <Navigate
    //         to='/'
    //       />
    // }
    return (
      <SubDistrict 
        search={SubDistrictList}
        data={datalist}
        isProcessing={isProcessing}
        isError={isError}
        errorMessage={errorMessage}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubDistrictContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import VisitScheduleMiddleware from "../../../../middleware/modules/sales/visit-schedule";
import ViewForm from "../../../../components/modules/sales/visit-schedule/view";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
const mapStateToProps = ({ auth, visitSchedule }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: visitSchedule.isProcessing,
  isError: visitSchedule.isError,
  errorMessage: visitSchedule.errorMessage,
  isSuccess: visitSchedule.isSuccess,
  isReset: visitSchedule.isReset,
  showModal: visitSchedule.showModal,
  isNewRecord: visitSchedule.isNewRecord,
  onSubmit: visitSchedule.onSubmit,
  helper: visitSchedule.formHelper,
  userAction: visitSchedule.userAction,
  viewData: visitSchedule.viewData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => VisitScheduleMiddleware.closeModal(),
      delete: (id)=>VisitScheduleMiddleware.delete(id)
    },
    dispatch
  );
};
class ViewContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    userAction: PropTypes.string,
    viewData: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
    userAction: null,
    viewData: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.getData = this.getData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.dataID);
  }

  close() {
    this.props.closeModal();
  }
  submitForm() {
    const { isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    if (isNewRecord) {
      this.props.new(form);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
  }

  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t('You will delete this Item')}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.delete(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  render() {
    const { onSubmit, showModal, titleModal,viewData } = this.props;
    return (
      <>
        <Modal show={showModal} size={"md"}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
            <title>{titleModal}</title>
          </Helmet>
          <Modal.Body>
            <ViewForm
              {...this.props}
              ref={this.form}
              changeSelect={this.changeSelect}
            />
          </Modal.Body>
          <Modal.Footer
            className={
              !onSubmit ? "justify-content-between" : "justify-content-center"
            }
          >
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            {viewData.status == 0 ? (
              <div
                style={{ width: "40%" }}
                className="d-flex justify-content-between"
              >
                <Button
                  variant="danger"
                  className="mr-4"
                  onClick={() => this.deleteData(viewData.id)}
                >
                  {i18n.t("Delete")}
                </Button>
                <Button variant="warning" onClick={() => this.update()}>
                  {i18n.t("Update")}
                </Button>
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewContainer);

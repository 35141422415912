export default class SellingActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static CANCEL_DATA='CANCEL_DATA'
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_SELLING='GET_HELPER_SELLING';
    static GET_HELPER_SELLING_FULL ='GET_HELPER_SELLING_FULL';
    static BEFORE_PRINT_SELLING ='BEFORE_PRINT_SELLING';
    static PRINT_SELLING ='PRINT_SELLING';
    static RESET_LIST="RESET_LIST";
  
    static submit(){
      return {
        type: SellingActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: SellingActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: SellingActions.UPDATE_DATA,
        payload: data
      };
      
    }
    static cancel() {
      return {
        type: SellingActions.CANCEL_DATA
      };
    }
  
    static view(data) {
      return {
        type: SellingActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: SellingActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: SellingActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: SellingActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: SellingActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: SellingActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: SellingActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: SellingActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: SellingActions.GET_HELPER_SELLING_FULL,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  SellingActions.GET_HELPER_SELLING,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: SellingActions.BEFORE_PRINT_SELLING,
      };
    }
    static print(data){
      return {
        type: SellingActions.PRINT_SELLING,
        payload: data
      };
    }
  }
  
  
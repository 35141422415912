import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
import Form from '../../../../components/modules/user/menu/form';
import { Button, Modal } from 'react-bootstrap';
import MenuMiddleware from '../../../../middleware/modules/user/menu';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,menu}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: menu.isProcessing,
  isError: menu.isError,
  errorMessage: menu.errorMessage,
  dataUpdate: menu.single,
  isSuccess:menu.isSuccess,
  isReset:menu.isReset,
  isNewRecord:menu.isNewRecord,
  onSubmit:menu.onSubmit,
  helperFull:menu.helperFull,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    closeModal:()=>MenuMiddleware.reset(),
    new: (data,search) =>MenuMiddleware.beforeSaveNew(data,search),
    update: (id,type,search) =>MenuMiddleware.beforeSaveUpdate(id,type,search)
  }, dispatch);
};
class FormContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate:PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit:PropTypes.bool
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess:false,
    isError: false,
    errorMessage: [],
    dataUpdate:null,
    onSubmit:false
  }
  constructor(props) {
    super(props);
    this.state={
      modal:{
        title:'',
        show:false,

      }
    }
    this.form=React.createRef();
    this.getData=this.getData.bind(this)
    this.submitForm=this.submitForm.bind(this)
    this.close=this.close.bind(this)
}
  
  getData(){
    return this.props.showOne(this.props.dataID)
  }

  close(){
      this.props.closeModal()
  }
  submitForm(){
    const {search,isNewRecord,dataUpdate}=this.props
    var form=this.form.current.runSubmit()
    if(isNewRecord){
      this.props.new(form,search)
    } else{
      this.props.update(dataUpdate.id,form,search)
    }
    
  }

  render() {
    const {helperFull,onSubmit,showModal,titleModal,isReset,dataUpdate,isProcessing,isError,errorMessage,isNewRecord} = this.props;
    return (
      <>
        <Modal
          show={showModal}
          size={'md'}
        >
            <Modal.Header >
            <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Helmet>
                <title>{titleModal}</title>
              </Helmet>
            <Modal.Body>
                <Form 
                  {...this.props}
                  isNewRecord={isNewRecord}
                  isReset={isReset}
                  data={dataUpdate}
                  isProcessing={isProcessing}
                  isError={isError}
                  errorMessage={errorMessage}
                  ref={this.form}
                  helperFull={helperFull}
                />
            </Modal.Body>
            <Modal.Footer className={'flex-center'}>
                {onSubmit?'':<Button variant='secondary' onClick={()=>this.close(false)}>{i18n.t("Close")}</Button>}
                    <Button variant='primary' onClick={()=>this.submitForm(true)}>{onSubmit ? 
                        <span className="indicator-progress show" >{i18n.t("Please wait...")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                        : 
                        <span className="indicator-label">{i18n.t("Save")}</span>
                      }</Button>
            </Modal.Footer>
        </Modal>
      
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

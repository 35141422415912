import React, { Component } from "react";
import PropTypes from "prop-types";

class InputCustom extends Component {
  static propTypes = {
    error: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string.isRequired,
    labelfield: PropTypes.string,
  };

  static defaultProps = {
    error: "",
    title: "",
    required: false,
    labelfield: "true",
  };
  constructor(props) {
    super(props);
  }

  render() {
    const { required, title, name, error, labelfield } = this.props;
    return (
      <React.Fragment>
        {labelfield == "true" ? (
          <label
            htmlFor={name + "label"}
            className={"form-label" + (required ? " required" : "")}
          >
            {title}
          </label>
        ) : null}
        <input {...this.props} />
        {error != "" ? (
          <div className="fv-plugins-message-container invalid-feedback">
            {error}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default InputCustom;

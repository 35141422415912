import ReceivingActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperReceiving:null,
  receivingHelperFull:null,
  printReceiving:null,
  requestPrintReceiving:false
};

function ReceivingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ReceivingActions.RESET_LIST:
      return INITIAL_STATE;
    case ReceivingActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ReceivingActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case ReceivingActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case ReceivingActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReceivingActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ReceivingActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case ReceivingActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case ReceivingActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case ReceivingActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case ReceivingActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case ReceivingActions.GET_HELPER_RECEIVING:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperReceiving:action.payload};
    case ReceivingActions.GET_HELPER_RECEIVING_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,receivingHelperFull:action.payload};
    case ReceivingActions.BEFORE_PRINT_RECEIVING:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printReceiving:null,isProcessing:true};
    case ReceivingActions.PRINT_RECEIVING:
      return { ...state,errorInternal:false, requestPrintReceiving:false, isError: false,isSuccess:false,onSubmit:false,printReceiving:action.payload};
      case ReceivingActions.ON_LIMIT_PRINT:
        return { ...state,errorInternal:false, requestPrintReceiving:false, isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
      case ReceivingActions.ON_SAVE_REQUEST_PRINT:
        return { ...state,errorInternal:false,requestPrintReceiving:true,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
     
    default:
      return state;
    }
}


export default ReceivingReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Mutation } from '../../../../components/modules/mutation/mutation';
import MutationMiddleware from '../../../../middleware/modules/mutation/mutation';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
// middlewares
const mapStateToProps = ({ mutasi, }) => ({
  isProcessing: mutasi.isProcessing,
  isError: mutasi.isError,
  errorMessage: mutasi.errorMessage,
  helperList:mutasi.helperMutation,
  onChoose: mutasi.onChoose,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => MutationMiddleware.getHelper(),
      // getData: (data) => MutationMiddleware.getData(data),
      // getDataItem: (data) => MutationMiddleware.getDataItem(data),
    },
    dispatch
  );
};
class MutationContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    // datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Mutation")}
          </title>
        </Helmet>
      <Mutation
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MutationContainer);

import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import CityActions from '../../../../reducers/modules/master/city/actions';

const url=process.env.REST_URL +'master/city'

export default class CityMiddleware {  

  static create(){
    return (dispatch) => {
      dispatch(CityActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      dispatch(CityActions.update());
      CityMiddleware.showOne(dispatch, params);
    };
  }

  static list(params){
      return (dispatch) => {
          dispatch(CityActions.process());
          CityMiddleware.showlist(dispatch, params);
        };
  }
  static reset(){
    return (dispatch) => {
        dispatch(CityActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(CityActions.view());
        CityMiddleware.showOne(dispatch, params);
      };
  }

  static beforeSaveNew(post,search){
    return (dispatch) => {
        dispatch(CityActions.submit());
        CityMiddleware.saveNew(dispatch, post,search);
      };
  }
  static beforeSaveUpdate(params,post,search){
    return (dispatch) => {
        dispatch(CityActions.submit());
        CityMiddleware.saveUpdate(dispatch, params, post,search);
      };
  }
  static beforeDelete(params,id){
    return (dispatch) => {
        dispatch(CityActions.delete());
        CityMiddleware.delete(dispatch,params,id);
      };
  }

  static getHelper(params=null){
    return (dispatch) => {
      CityMiddleware.showHelper(dispatch,params);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static showlist(dispatch, params){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${CityMiddleware.getToken()}`
          },
          url: url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          
          if (response && response.status === 200 && response.data) {
            dispatch(CityActions.list(response.data.result));
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CityMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-city' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(CityActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CityMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CityActions.view(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static delete(dispatch, params,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CityMiddleware.getToken()}`
        },
        url: url +'/'+id,
        responseType: 'json'
      })
      .then((response) => {
       
        if (response && response.status === 200 && response.data) {
          dispatch(CityActions.process(),CityMiddleware.showlist(dispatch, params));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static saveNew(dispatch, post,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CityMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CityActions.process(),CityMiddleware.showlist(dispatch,search));
          dispatch(CityActions.success());
        }
      })
      .catch((error) => {
        dispatch(CityActions.error(error.data.message));
      });
  }

  static saveUpdate(dispatch, params, post, search){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${CityMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CityActions.process(),CityMiddleware.showlist(dispatch,search));
          dispatch(CityActions.success());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

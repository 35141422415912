import CashBankActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataDetail:{},
  afterSaveIn:false,
  afterSaveBank:false,
  isProcessingIn: false,
  isProcessingBank: false,
  isErrorIn: false,
  isErrorBank: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecordIn:true,
  isNewRecordBank:true,
  showModal:false,
  onSubmitIn:false,
  onSubmitBank:false,
  single:null,
  refresh:false,
  helperJournal:null,
  helperFullJournal:null,
  errorInternal:false,
  printJournal:null,
};

function CashBankReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CashBankActions.RESET_LIST:
      return INITIAL_STATE;
    case CashBankActions.NEW_DATA_IN:
      return { ...state,errorInternalIn:false,afterSaveIn:false, onSubmitIn:false, isProcessingIn: false,single:null, isNewRecordIn:true,INITIAL_STATE};
    case CashBankActions.NEW_DATA_BANK:
      return { ...state,errorInternalBank:false,afterSaveBank:false, onSubmitBank:false, isProcessingBank: false,single:null, isNewRecordBank:true}; 
    case CashBankActions.ON_SUBMIT_IN:
      return { ...state,errorInternal:false,onSubmitIn:true,afterSaveIn:false};
    case CashBankActions.ON_SUBMIT_BANK:  
      return { ...state,errorInternal:false,onSubmitBank:true,afterSaveBank:false};
    case CashBankActions.SUCCESS_DATA_IN:
      return { ...state,errorInternal:false, afterSaveIn:true,onSubmitIn:true, isErrorIn: false,};
    case CashBankActions.SUCCESS_DATA_BANK:
      return { ...state,errorInternal:false, afterSaveBank:true,onSubmitBank:true, isErrorBank: false,};
    case CashBankActions.ERROR_DATA_IN:
      return { ...state, onSubmitIn:false, errorInternal:false, afterSaveIn:false, isErrorIn: true,onSubmitIn:false,  errorMessage: action.payload,isProcessingIn:false };
    case CashBankActions.ERROR_DATA_BANK:
      return { ...state, onSubmitBank:false, errorInternal:false, afterSaveBank:false, isErrorBank: true,onSubmitBank:false,  errorMessage: action.payload,isProcessingBank:false };
    case CashBankActions.PROCESS_DATA_IN:
      return { ...state,errorInternal:false, onSubmitIn:false, isProcessingIn: true, isErrorIn: false,isSuccessIn:false};
    case CashBankActions.PROCESS_DATA_BANK:
      return { ...state,errorInternal:false, onSubmitBank:false, isProcessingBank: true, isErrorBank: false,isSuccessBank:false};
      
    case CashBankActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case CashBankActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case CashBankActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CashBankActions.CANCEL_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    // case CashBankActions.ON_SUBMIT:
    //   return { ...state,errorInternal:false,onSubmit:true,afterSave:false};
    case CashBankActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    
    case CashBankActions.GET_HELPER_JOURNAL:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperJournal:action.payload};
    case CashBankActions.GET_HELPERFULL_JOURNAL:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperFullJournal:action.payload};
    case CashBankActions.GET_DETAIL:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case CashBankActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case CashBankActions.BEFORE_DETAIL:
      return { ...state,errorInternal:false, onSubmit:false,data:action.payload,isNewRecord:false};
    case CashBankActions.AFTER_DETAIL:
      return { ...state,errorInternal:false, onSubmit:false, dataDetail:action.payload,isNewRecord:false,isProcessing:false};
    case CashBankActions.AFTER_DATA:
      return { ...state,errorInternal:false, onSubmit:false, data:action.payload,isNewRecord:false}      
    case CashBankActions.BEFORE_PRINT_JOURNAL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printJournal:null};
    case CashBankActions.PRINT_JOURNAL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printJournal:action.payload};
    default:
      return state;
    }
}

export default CashBankReducer;

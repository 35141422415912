import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from "../../../utilities/Form/Select2";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from '../../../utilities/Form/datepicker';
import TextArea from '../../../utilities/Form/TextArea';
import { Button, Table } from 'react-bootstrap';
import Select from "react-select";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputNumber from '../../../utilities/Form/InputNumber';
const token = cookieManager.getUserToken();
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {

            },
            name: null,
            description: null,
            branchList: [],
            branchCurrent: null,
            branchText: i18n.t("Select Branch"),
            startDate: new Date(),
            endDate: new Date(),
            districtList: [],
            districtCurrent: null,
            districtText: i18n.t("Select District"),
            subdistrictList: [],
            unitList:[],
          
            arrayDetail: [
                {
                    itemFreeCurrent: null,
                    itemFreeText: i18n.t("Select Free Item"),
                    programTypeText: i18n.t("Select Program Type"),
                    programTypeCurrent: null,
                    minimumOrder: null,
                    value: null,
                    stock: null,
                    price: null,
                    pricePromo: null,
                    idItemFree: null,
                    idProgramType: null,
                    itemCurrent: null,
                    itemText: i18n.t("Select Item"),
                    unitCurrent:null,
                    unitText:i18n.t("Select Unit"),
                    discountPrice: 0,
                    discountRate: null,
                    qtyFree: 0,
                    qty: 0,
                }
            ],
            itemList: [],
            itemFreeList: [],
            programTypeList: [],
            subdistrictCurrent: null,
            subdistrictText: i18n.t("Select Sub District"),
            customerTypeList: [],
            customerTypeCurrent: null,
            customerTypeText: i18n.t("Select Customer Type"),
            idCity: null,
            grandTotal: 0,
            saleProduct: 0,
            totalPromo: 0,
            totalDiscount: 0,
            isUpdate: 0,
            description: null,
        }
        this.handler = this.handler.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this);
        this.handlerDateTime = this.handlerDateTime.bind(this);

    }

    calculateRow(i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        let price = parseFloat(this.state.arrayDetail[i].price) || 0
        let qty = parseFloat(this.state.arrayDetail[i].qty) || 0
        let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0

        let priceTotal = price * qty
        let dc = qty * discount
        objectss["disc"] = dc.toFixed(2)
        this.setState(arr)
        this.calculateAll()
    }
    calculateAll() {
        let totalDiscount = 0
        let grandTotal = 0
        let totalPromo = 0
        let saleProduct = 0
        const detail = this.state.arrayDetail

        for (let i = 0; i < detail.length; i++) {
            let price = parseFloat(detail[i].price) || 0
            let qty = parseFloat(detail[i].qty) || 0
            let discount = parseFloat(detail[i].discountPrice) || 0
            let qtyFree = parseFloat(detail[i].qtyFree) || 0
            let pricePromo = parseFloat(this.state.arrayDetail[i].pricePromo) || 0
            saleProduct += (price - discount) * qty
            totalPromo += (qtyFree * pricePromo)
            grandTotal += (saleProduct + totalPromo)
        }
        this.setState({
            saleProduct: saleProduct.toFixed(2),
            totalPromo: totalPromo.toFixed(2),
            grandTotal: grandTotal.toFixed(2),

        });
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value;
        this.setState({
            [key]: value,
        });
    }

    handlerSelect(e, name, currentState) {
        var key = name;
        var value = null;
        if (e != null) {
            value = e.value;
        }
        this.setState({
            ...this.state,
            [key]: value,
        });
        this.setState({ [currentState]: e });
    }

    handlerDateTime(e, name) {
        var key = name;
        var value = e;
        this.setState({
            [key]: value,
        });
    }

    handlerSelectArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        if (e == null) {
            objectss[name] = null;
        } else {
            objectss[name] = e;
        }
        this.setState(arr);
    }

    handlerCurrencyArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        objectss[name] = e;
        this.setState(arr);
        this.calculateRow(i, arrayname)
    }

    handlerInputArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        var value = e.target.value;
        objectss[name] = value;
        this.setState(arr);
    }

    runSubmit() {
        let cusData = [];
        let a = (this.state.customerTypeCurrent==null)?0:this.state.customerTypeCurrent.length;
        for(i=0;i<a;i++){
            cusData.push(this.state.customerTypeCurrent[i].value)
        }
        const Marketing = {
            name: (this.state.name == null) ? '' : this.state.name,
            startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
            description: (this.state.description == null)?"":this.state.description,
            idBranch: (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value,
            idDistrict: (this.state.districtCurrent == null) ? '' : this.state.districtCurrent.value,
            idSubDistrict: (this.state.subdistrictCurrent == null) ? '' : this.state.subdistrictCurrent.value,
            idCustomerType: cusData,
            totalPrice: (this.state.grandTotal == null) ? '' : this.state.grandTotal,
            status: 1,
        };
        const detail = this.state.arrayDetail;
        let idItem = [];
        let idUnit = [];
        let idProgramType = [];
        let qty = [];
        let discountPrice = [];
        let idItemFree = [];
        let qtyFree = [];
        let minimumOrder = [];
        let value = [];

        for (var i = 0; i < detail.length; i++) {
            idItem.push(detail[i].idItem == null ? "" : detail[i].idItem.item);
            idUnit.push(detail[i].idUnit == null ? "" : detail[i].idUnit.value);
            idProgramType.push(detail[i].idProgramType == null ? "" : detail[i].idProgramType.value);
            idItemFree.push(detail[i].idItemFree == null ? "" : detail[i].idItemFree.item);
            qtyFree.push(detail[i].qtyFree);
            qty.push(detail[i].qty);
            minimumOrder.push(detail[i].minimumOrder);
            value.push(detail[i].value ==  null)?0:detail[i].value  ;
            discountPrice.push(detail[i].discountPrice);
        }

        this.state.form.Marketing = Marketing;
        this.state.form.MarketingDetail = {
            idItem: idItem,
            idUnit:idUnit,
            idProgramType: idProgramType,
            idItemFree: idItemFree,
            qtyFree: qtyFree,
            qty: qty,
            discountPrice: discountPrice,
            minimumOrder: minimumOrder,
            value: value
        };

        return this.state.form
    }

    renderDetail(detail, i) {
        return (
            <React.Fragment key={"detail" + i}>
                <tr>
                    <td>
                        <Select
                            isClearable={true}
                            name={"idItem"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelectArray(e, 'idItem', i, 'arrayDetail');
                                } else {
                                    await this.handlerSelectArray(e, 'idItem', i, 'arrayDetail');
                                    await this.changeData('price', 'getItemDetail', 'price', 'arrayDetail', i);
                                }

                            }}
                            options={this.state.itemList}
                            title={i18n.t("Item")}
                            value={detail.idItem}
                            placeholder={detail.itemText}
                            labelfield={false}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("MarketingDetail.idItem." + i)}
                        </div>
                        <div>
                            {detail.idItem != null && this.state.branchCurrent != null && detail.price != null
                                ? i18n.t("Price: Rp. ") +
                                (detail.price != 0
                                    ? parseFloat(detail.price) : 0)
                                : ""}
                        </div>
                    </td>
                    <td>
                        <InputNumber
                            id={"minimumOrder" + i}
                            name="minimumOrder"
                            placeholder={i18n.t("Minimum Order")}
                            defaultValue={0}
                            value={detail.minimumOrder}
                            decimalsLimit={2}
                            onValueChange={(value, name) => this.handlerCurrencyArray(value, name, i, "arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            labelfield={"false"}
                        />
                    </td>
                    <td>
                        <Select
                            isClearable={true}
                            name={"idUnit"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelectArray(e, 'idUnit', i, 'arrayDetail');
                                } else {
                                    await this.handlerSelectArray(e, 'idUnit', i, 'arrayDetail');
                                }

                            }}
                            options={this.state.unitList}
                            title={i18n.t("Unit")}
                            value={detail.idUnit}
                            placeholder={detail.unitText}
                            labelfield={false}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("MarketingDetail.idItem." + i)}
                        </div>
                    </td>
                    <td>
                        <Select
                            isClearable={true}
                            name={"idProgramType"}
                            size={4}
                            onChange={(e) => this.handlerSelectArray(e, 'idProgramType', i, 'arrayDetail')}
                            options={this.state.programTypeList}
                            title={i18n.t("Program Type")}
                            value={detail.idProgramType}
                            placeholder={detail.programTypeText}
                            labelfield={false}
                        />
                    </td>
                    <td>
                        <Select
                            isClearable={true}
                            name={"idItemFree"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelectArray(e, 'idItemFree', i, 'arrayDetail');
                                } else {
                                    await this.handlerSelectArray(e, 'idItemFree', i, 'arrayDetail');
                                    await this.changeData('pricePromo', 'getItemDetail', 'price', 'arrayDetail', i);
                                }
                            }}
                            options={this.state.itemFreeList}
                            title={i18n.t("Free Item")}
                            value={detail.idItemFree}
                            placeholder={detail.itemFreeText}
                            labelfield={false}
                        />
                        <div>
                            {detail.idItemFree != null && this.state.branchCurrent != null && detail.pricePromo != null
                                ? i18n.t("Price: Rp. ") +
                                (detail.pricePromo != 0
                                    ? parseFloat(detail.pricePromo) : 0)
                                : ""}
                        </div>
                    </td>
                    <td>
                        <InputNumber
                            id={"value" + i}
                            name="value"
                            placeholder={i18n.t("Value")}
                            defaultValue={0}
                            value={detail.value}
                            decimalsLimit={2}
                            onValueChange={(value, name) => this.handlerCurrencyArray(value, name, i, "arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            labelfield={"false"}
                        />
                    </td>
                    <td>
                        <InputNumber
                            id={"discountPrice" + i}
                            name="discountPrice"
                            placeholder={i18n.t("Discount Price")}
                            defaultValue={0}
                            value={detail.discountPrice}
                            decimalsLimit={2}
                            onValueChange={(value, name) => this.handlerCurrencyArray(value, name, i, "arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            prefix='Rp '
                            labelfield={"false"}
                        />
                    </td>
                    <td>
                        <Button
                            variant="danger"
                            className="btn btn-sm"
                            onClick={(e) => this.removeArray(i, "arrayDetail")}>
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </Button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    handlerDateTimeArray(e, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        if (e == null) {
            objectss[name] = null;
        } else {
            objectss[name] = e;
        }
        this.setState(arr);
    }

    addArray(value, key) {
        const newRow = value;
        const arr = [...this.state[key], newRow];
        this.setState({
            ...this.state,
            [key]: arr,
        });
    }

    removeArray(i, key) {
        const arr = this.state[key].filter((obj, index) => {
            return index !== i;
        });
        this.setState({
            ...this.state,
            [key]: arr,
        });
    }

    changeData(keyFill, type = null, key = "", array = null, arraykey = null, extraFunction = null) {
        const token = cookieManager.getUserToken();
        const { data, isNewRecord } = this.props;
        let params = null;
        switch (type) {
            case "getDistrict":
                var url = process.env.REST_URL + "helper/get-district";
                var city =
                    this.state.idCity != undefined
                        ? this.state.idCity
                        : null;
                params = {
                    city: city,
                };
                break;
            case "getItemPromo":
                var url = process.env.REST_URL + "helper/get-item-promo";
                var branch =
                    this.state.branchCurrent != undefined
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    branch: branch,
                };
                break;
            case "getItemBranch":
                var url = process.env.REST_URL + "helper/get-item-branch";
                var branch =
                    this.state.branchCurrent != undefined
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    branch: branch,
                };
                break;
            case "getItemDetail":
                var url = process.env.REST_URL + "helper/get-item-detail-marketing";
                var a = this.state[array][arraykey].idItem
                var b = this.state[array][arraykey].idItemFree
                if (a != null && b == null) {
                    var id =
                        this.state[array][arraykey].idItem != undefined
                            ? this.state[array][arraykey].idItem.value
                            : null;
                }
                else if (a != null && b != null) {
                    var id =
                        this.state[array][arraykey].idItem != undefined
                            ? this.state[array][arraykey].idItemFree.value
                            : null;
                }
                params = {
                    id: id,
                };
                break;
        }

        if (type != null) {
            axios({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                url: url,
                responseType: "json",
                params: params,
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        if (array != null) {
                            var arr = [...this.state[array]];
                            const objectss = arr[arraykey];
                            if (key == "") {
                                objectss[keyFill] = response.data.result;
                            } else {
                                objectss[keyFill] = response.data.result[key];
                            }
                        } else {
                            if (key == "") {
                                var arr = { [keyFill]: response.data.result };
                            } else {
                                var arr = { [keyFill]: response.data.result[key] };
                            }
                        }
                        if (extraFunction != null) {
                            this.setState(arr, extraFunction);
                        } else {
                            this.setState(arr);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    rese

    render() {
        const { data, isNewRecord, helperMarketing } = this.props
        this.state.branchList = helperMarketing != null ? helperMarketing.branch : [];
        this.state.unitList = helperMarketing != null ? helperMarketing.unit : [];
        this.state.programTypeList = helperMarketing != null ? helperMarketing.programType : [];
        this.state.customerTypeList = helperMarketing != null ? helperMarketing.customerType : [];
        if (data != null && !isNewRecord) {
            if (this.state.isUpdate == 0) {
                this.state.name = data.name,
                    this.state.branchCurrent = { value: data.id_branch, label: (data.name_branch != null) ? data.name_branch : null },
                    this.state.districtCurrent = { value: data.district_id, label: (data.district_name != null) ? data.district_name : null },
                    this.state.subdistrictCurrent = [],
                    this.state.customerTypeCurrent = { value: data.customer_id, label: (data.customer_name != null) ? data.customer_name : null },
                    this.state.itemCurrent = { value: data.id_item, label: (data.name_item != null) ? data.name_item : null },
                    this.state.programTypeCurrent = { value: data.program_id, label: (data.program_name != null) ? data.program_name : null },
                    this.state.idCity = data.city_branch,
                    this.state.minimumOrder = data.minimum_order,
                    this.state.value = data.value,
                    this.state.description = data.description,
                    this.state.isUpdate = 1
            }
        }
        return (
            <form>
                <React.Fragment>
                    <div className="row">
                        <div className="col-5">
                            <Input
                                type={"input"}
                                name={"name"}
                                title={i18n.t("Name")}
                                required
                                value={this.state.name}
                                handler={this.handler}
                                error={this.renderError("Marketing.name")}
                            />
                        </div>
                        <div className="col-5">
                            <Select2
                                isClearable={true}
                                name={"idBranch"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    if (e == null) {
                                        await this.handlerSelect(e, 'idBranch', 'branchCurrent');
                                        await this.setState({ districtCurrent: null, districtList: [] })
                                    } else {
                                        await this.handlerSelect(e, 'idBranch', 'branchCurrent');
                                        await this.setState({ idCity: this.state.branchCurrent.idCity })
                                        await this.changeData('districtList', 'getDistrict', '');
                                        await this.changeData('itemFreeList', 'getItemPromo', '');
                                        await this.changeData('itemList', 'getItemBranch', '');
                                    }
                                }}
                                options={this.state.branchList}
                                title={i18n.t("Branch")}
                                value={this.state.branchCurrent}
                                placeholder={this.state.branchText}
                                error={this.renderError("Marketing.idBranch")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <DatePickerCustom
                                name={"date"}
                                title={"Start Date"}
                                required={true}
                                selected={this.state.startDate}
                                onChange={(e) =>
                                    this.handlerDateTime(e, "startDate")
                                }
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMM yyyy"
                                className="form-control form-control-sm"
                            />
                        </div>
                        <div className="col-5">
                            <DatePickerCustom
                                name={"date"}
                                title={"End Date"}
                                required={true}
                                selected={this.state.endDate}
                                onChange={(e) =>
                                    this.handlerDateTime(e, "endDate")
                                }
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMM yyyy"
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <Select2
                                isClearable={true}
                                name={"idDistrict"}
                                size={4}
                                onChange={(e) => this.handlerSelect(e, 'idDistrict', 'districtCurrent')}
                                options={this.state.districtList}
                                title={i18n.t("District")}
                                value={this.state.districtCurrent}
                                placeholder={this.state.districtText}
                                error={this.renderError("Marketing.idDistrict")}
                            />
                        </div>
                        <div className="col-5">
                            <Select2
                                isClearable={true}
                                name={"idSubDistrict"}
                                size={4}
                                onChange={(e) => this.handlerSelect(e, 'idSubDistrict', 'subdistrictCurrent')}
                                options={this.state.subdistrictList}
                                title={i18n.t("Sub District")}
                                value={this.state.subdistrictCurrent}
                                placeholder={this.state.subdistrictText}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <Select2
                                isClearable={true}
                                name={"idCustomerType"}
                                size={4}
                                isMulti
                                onChange={(e) => this.handlerSelect(e, 'idCustomerType', 'customerTypeCurrent')}
                                options={this.state.customerTypeList}
                                title={i18n.t("Customer Type")}
                                value={this.state.customerTypeCurrent}
                                placeholder={this.state.customerTypeText}
                                error={this.renderError("PurchaseReturn.idBranch")}
                            />
                        </div>
                        <div className='col-8'>
                            <TextArea
                                name={"description"}
                                title={'Description'}
                                onhandler={(e) => this.handler(e)}
                                value={this.state.description}
                            />
                        </div>
                    </div>
                </React.Fragment>
                <br />
                <hr />
                <div className="row">
                    <div className="col-12">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th width="18%" className="text-center">{i18n.t("Item")}</th>
                                    <th width="12%" className="text-center">{i18n.t("Minimum Order")}</th>
                                    <th width="14%" className="text-center">{i18n.t("Unit")}</th>
                                    <th width="16%" className="text-center">{i18n.t("Program Type")}</th>
                                    <th width="16%" className="text-center">{i18n.t("Free Item")}</th>
                                    <th width="12%" className="text-center">{i18n.t("Qty")}</th>
                                    <th width="14%" className="text-center">{i18n.t("Discount Price")}</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail, index) => {
                                    return this.renderDetail(detail, index);
                                })}
                            </tbody>
                        </Table>
                        <Button
                            variant="success"
                            onClick={(e) =>
                                this.addArray(
                                    {
                                        idItem: null, idProgramType: null, idItemFree: null,
                                        minimumOrder: null, value: null,
                                        qty: 0, qtyFree: 0,
                                        discountPrice: null, discountRate: null,
                                    },
                                    "arrayDetail"
                                )
                            }
                        >
                            {i18n.t("Add Item")}
                        </Button>
                    </div>
                </div>

            </form>
        );
    }
}

export default Form;
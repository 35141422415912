import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { ReportVisit } from "../../../../components/modules/sales/report-visit";
import ReportVisitMiddleware from "../../../../middleware/modules/sales/report-visit";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ reportVisit }) => ({
  isProcessing: reportVisit.isProcessing,
  isError: reportVisit.isError,
  errorMessage: reportVisit.errorMessage,
  datalist: reportVisit.data,
  helper:reportVisit.helperReportVisit,
  timeline:reportVisit.timeline,
  dataDetails:reportVisit.details,
  loading:reportVisit.loadingDetails,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper:()=>ReportVisitMiddleware.getHelper(),
      reset: () =>ReportVisitMiddleware.reset(),
      getTimeline:(post)=>ReportVisitMiddleware.timeline(post),
      getData:(type,id)=>ReportVisitMiddleware.getData(type,id)
    },
    dispatch
  );
};
class ReportVisitContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.reset();
    this.props.getHelper();
  }
  
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Report Visit")}
          </title>
        </Helmet>
      <ReportVisit
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportVisitContainer);

import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from '../../../utilities/Form/Select2';
import Radio from '../../../utilities/Form/radio';
import { StatusForm,AllowanceType ,UnitSalary} from '../../../utilities/Const/StatusActive';
import InputNumber from '../../../utilities/Form/InputNumber';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                name:'',
                status:1,
                idCuts:null,
                salaryCutsType:'monthly',
                nominal:0,
                unit:'money'
            },
            salaryCutsList:[],
            salaryCutsCurrent:null,
            salaryCutsText:i18n.t('Select Salary Cuts'),
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerCurrency = this.handlerCurrency.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerCurrency(value, name) {
        var key = name;
        var value = value;
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            [key]: value,
          },
        });
      }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
       return this.state.form
    }
    checkNominal(){
        if(this.state.form.unit=="percentage" & this.state.form.nominal >100){
            this.setState({...this.state, form: {
                ...this.state.form,
                nominal:100
            } })
        }
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){
        const {dataUpdate,isNewRecord,helperList}=this.props
        this.state.salaryCutsList=(helperList.cuts !=undefined)?helperList.cuts:[]
       if(dataUpdate !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form.name=dataUpdate.name,
                this.state.form.status=dataUpdate.status
                this.state.form.nominal=parseFloat(dataUpdate.nominal)||0
                this.state.form.salaryCutsType=dataUpdate.salaryCutsType
                this.state.isUpdate=1
                this.state.form.unit=dataUpdate.unit
                this.state.form.idCuts=dataUpdate.idCuts
                this.state.salaryCutsCurrent={value:dataUpdate.idCuts,label:((dataUpdate.salaryCutsName !='')?dataUpdate.salaryCutsName:this.state.salaryCutsText)}
            }
        }
        if(this.state.form.unit=="percentage" & this.state.form.nominal >100){
            this.state.form.nominal =100
        }
        return (
            <form>
                <div className='mb-4'>
                <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.form.name}
                    handler={this.handler}
                    error={this.renderError('name')}
                />
                </div>
                <div className='mb-4'>
                <Select2
                    isClearable={true}
                    name={"idCuts"}
                    required={true}
                    onChange={(e) =>
                        this.handlerSelect(e, "idCuts", "salaryCutsCurrent")
                    }
                    options={this.state.salaryCutsList}
                    title={i18n.t("Salary Cuts")}
                    value={this.state.salaryCutsCurrent}
                    placeholder={this.state.salaryCutsText}
                    error={this.renderError("idCuts")}
                    />
                </div>
                <div className='mb-4'>
                <Radio
                    list={AllowanceType}
                    name={"salaryCutsType"}
                    title={i18n.t("Salary Cuts Type")}
                    inline={false}
                    value={this.state.form.salaryCutsType}
                    handler={this.handler}
                    error={this.renderError("salaryCutsType")}
                />
                </div>
                <div className='mb-4'>
                <Radio
                    list={UnitSalary}
                    name={"unit"}
                    title={i18n.t("Salary Cuts Unit")}
                    inline={false}
                    value={this.state.form.unit}
                    handler={this.handler}
                    error={this.renderError("unit")}
                />
                </div>
                <div className='mb-4'>
                <InputNumber
                  id={"nominal"}
                  name={"nominal"}
                  title={i18n.t("Nominal")}
                  labelfield={"true"}
                  required={true}
                  value={this.state.form.nominal}
                  decimalsLimit={2}
                  onValueChange={(value, name) =>
                    this.handlerCurrency(value, "nominal")
                  }
                  prefix={(this.state.form.unit=="money")?"Rp ":""}
                  suffix={(this.state.form.unit=="money")?"":" %"}
                  className="form-control  form-control-sm "
                />
                </div>
                <div className='mb-4'>
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.form.status}
                    handler={this.handler}
                />
                </div>
            </form>
        );
    }
}

export default Form;
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { MemoOut } from "../../../../components/modules/accounting/memo-out";
import MemoOutMiddleware from "../../../../middleware/modules/accounting/memo-out";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, memoout }) => ({
  isProcessing: memoout.isProcessing,
  isError: memoout.isError,
  errorMessage: memoout.errorMessage,
  datalist: memoout.data,
  getHelper: memoout.getHelper,
  showModal: memoout.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => MemoOutMiddleware.create(),
      getHelper: () => MemoOutMiddleware.getHelper(),
      changeStatus: (data) => MemoOutMiddleware.beforeChangeStatus(data),
      reset: () => MemoOutMiddleware.resetList(),
      search: (data) => MemoOutMiddleware.list(data),
      update: (data) => MemoOutMiddleware.update(data),
      cancel: (id, search) => MemoOutMiddleware.beforeCancel(id, search),
      approve: (id, value, search) => MemoOutMiddleware.beforeApprove(id, value, search),
      reject: (id, value,type, search) => MemoOutMiddleware.beforeReject(id, value,type, search),
    },
    dispatch
  );
};
class MemoOutContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.cancelItem = this.cancelItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  cancelItem(id, search) {
    this.props.cancel(id, search);
  }

  updateItem(id) {
    this.props.getHelper();
    this.props.update(id);
  }

  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value,type, search) {
    this.props.reject(id, value,type, search);
  }

  changeItem(data) {
    this.props.changeStatus(data);
  }
  createItem() {
    this.props.getHelper();
    this.props.create();
  }


  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Memo Out List")}</title>
        </Helmet>
        <MemoOut
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          cancelItem={this.cancelItem}
          updateItem={this.updateItem}
          createData={this.createItem}
          changeItem={this.changeItem}
          approveItem={this.approveItem}
          rejectItem={this.rejectItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoOutContainer);

const FormatCurrency = (nominal, currency='') => {
  let value =currency +" " +
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(nominal);
  return value;
};

export default FormatCurrency;

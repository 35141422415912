import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
  }
}
filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
          
        })
    }
    return data
  }

  render() {
    const {data}=this.props
    return (
      <form>
       {(data!=null)? <React.Fragment>
        <div className="row">
            <div className="col-12">
              <Table >
                <thead>
                </thead>
                 <tbody>
                    <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                        <td width={'400'}>{i18n.t("Goods Receipt No.")}</td>
                        <td width={'50'}>:</td><td>{(data.receiving_no)?data.receiving_no:'-'}</td></tr>
                    
                    <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                        <td width={'400'}>{i18n.t("Goods Receipt Date")}</td>
                        <td width={'50'}>:</td><td>{(data.date_format1)?data.date_format1:'-'}</td></tr>

                    <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                        <td width={'400'}>{i18n.t("Supplier Name")}</td>
                        <td width={'50'}>:</td><td>{(data.name_supplier)?data.name_supplier:'-'}</td></tr>

                    <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                        <td width={'400'}>{i18n.t("Document No.")}</td>
                        <td width={'50'}>:</td><td>{(data.doNo)?data.doNo:'-'}</td></tr>    

                    <tr style={{fontSize:'15px', fontWeight:'bold'}}>
                        <td width={'400'}>{i18n.t("Delivery Date")}</td>
                        <td width={'50'}>:</td><td>{(data.deliveryDate1)?data.deliveryDate1:'-'}</td></tr>



                        {data.image.length > 0 ? (
                            <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                                <td>{i18n.t("Image")}</td>
                                <td>:</td>
                                <td>
                                <PhotoGallery images={data.image}></PhotoGallery>
                                </td>
                            </tr>
                            ) : null}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <h3 className="text-center">{i18n.t('Detail')}</h3>
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{i18n.t('PO No.')}</th>
                  <th>{i18n.t('Code Item')}</th>
                  <th>{i18n.t('Item')}</th>
                  <th>{i18n.t('Qty')}</th>
                  <th>{i18n.t('Unit')}</th>
                  <th>{i18n.t('Banderol')}</th>
                  <th>{i18n.t('Warehouse')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.receiving_detail.map((log,i)=>{
                    return (
                      <tr key={'a'+i}>
                        <td>{i+1}</td>
                        <td>{log.po_detail}</td>
                        <td>{log.item_code}</td>
                        <td>{log.item_name}</td>
                        <td>{log.qty_format}</td>
                        <td>{log.unit_name}</td>
                        <td>{log.bandNo}</td>
                        <td>{log.name_warehouse +' - ' +log.name_sub_wh}</td>
                        
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            </div>
          <div className="row">
            <div className="d-flex flex-end">
              <div className="col-4">
              </div>
            </div>
          </div>
       </React.Fragment> :''}
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { CustomerType } from '../../../../components/modules/master/customer-type';
// middlewares
import CustomerTypeMiddleware from '../../../../middleware/modules/master/customer-type';
import Swal from 'sweetalert2';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,customerType }) => ({
  isProcessing: customerType.isProcessing,
  datalist:customerType.data,
  showModal:customerType.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    update: data =>CustomerTypeMiddleware.update(data),
    create: () =>CustomerTypeMiddleware.create(),
    reset: () =>CustomerTypeMiddleware.resetList(),
    search: data =>CustomerTypeMiddleware.list(data),
    delete: (id,search) =>CustomerTypeMiddleware.beforeDelete(id,search)
  }, dispatch);
};
class CustomerTypeContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
  }

  constructor(props){
    super(props)
    this.state={
      count:0
    }
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
  }
  createItem(){
    this.props.create()
  }

  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
          <Helmet>
            <title>{i18n.t("Customer Type List")}</title>
          </Helmet>
        <CustomerType
          {...this.props}
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTypeContainer);
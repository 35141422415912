import AccSetupActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperAccSetup: null,
  errorInternal: false,
};

function AccSetupReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AccSetupActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case AccSetupActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case AccSetupActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case AccSetupActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case AccSetupActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case AccSetupActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case AccSetupActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case AccSetupActions.ERROR_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case AccSetupActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case AccSetupActions.PROCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: true,
        isError: false,
        isSuccess: false,
      };
    //  case AccSetupActions.GET_HELPER_COA2:
    //    return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCoa2:action.payload};
    case AccSetupActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default AccSetupReducer;

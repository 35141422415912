import CustomerActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperCustomer:null,
  customerHelperFull:null,
  errorInternal:false
};

function CustomerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CustomerActions.RESET_LIST:
      return { ...state, isProcessing: false,single:null,showModal:false, form:INITIAL_STATE.form,isNewRecord:true,data:{}};
    case CustomerActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case CustomerActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false};
    case CustomerActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case CustomerActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CustomerActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case CustomerActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case CustomerActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case CustomerActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case CustomerActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case CustomerActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case CustomerActions.GET_HELPER_CUSTOMER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperCustomer:action.payload};
    case CustomerActions.GET_HELPER_CUSTOMER_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,customerHelperFull:action.payload};
    case CustomerActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
     
    default:
      return state;
    }
}


export default CustomerReducer;

import React, { Component, Fragment } from "react";
import Table from "./table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Tabs, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import  { FormCash } from "./formCash";
import i18n from "../../../../i18n";
import FormBankContainer from  "../../../../containers/modules/accounting/cash-bank/formBank";
import FormCashContainer from  "../../../../containers/modules/accounting/cash-bank/formCash";

const MySwal = withReactContent(Swal);
export class CashBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      helperData: [],
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Cash / Bank"), class: " text-gray-500" },
      ],
    };
    this.submitFormCash = this.submitFormCash.bind(this);
    this.formCash = React.createRef();
    this.formBank = React.createRef();
  }

  renderTable() {
    return this.state.listData.map((data, i) => (
      <Fragment key={i}>
        <Table></Table>
      </Fragment>
    ));
  }

  submitFormCash() {
    var form = this.formCash.current.runSubmit();
    this.props.submit(form);
   
  }

  submitFormBank() {
    var form = this.formBank.current.runSubmit();
    this.props.submit(form);
    
  }

  renderTab() {
    const { onSubmit, showModal, titleModal } = this.props;
    
    return (
      <Tabs
        unmountOnExit
        defaultActiveKey="out"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="in" className="fw-bold" title={i18n.t("Cash")}>
          <div className="row">
            <h2 style={{textAlign:'center'}}>{i18n.t("Cash Masuk / Keluar")}</h2>
          </div>
          <br />
          <FormCashContainer
          {...this.props}/>
          {/* <FormCash key={"Cash"+Date.now()} {...this.props} ref={this.formCash}/>
          <div className="d-flex flex-end">
            <Button
              className="flex-end"
              variant="primary"
              onClick={() => this.submitFormCash()}
            >
              <span className="indicator-label align-left">{i18n.t("Save")}</span>
            </Button>
          </div> */}
        </Tab>
        <Tab eventKey="out" className="fw-bold" title={i18n.t("Bank")}>
          <div className="row">
            <h2 style={{textAlign:'center'}}>{i18n.t("Bank Masuk / Keluar")}</h2>
          </div>
          <br />
          <FormBankContainer
          {...this.props}/>
          {/* <FormCash key={"Cash"+Date.now()} {...this.props} ref={this.formCash}/>
          <div className="d-flex flex-end">
            <Button
              className="flex-end"
              variant="primary"
              onClick={() => this.submitFormCash()}
            >
              <span className="indicator-label align-left">{i18n.t("Save")}</span>
            </Button>
          </div> */}
        </Tab>
        
        {/* <Tab eventKey="bank" title={i18n.t("Bank")}>
          <div>
            <h2>{i18n.t("Bank")}</h2>
          </div>
          <br />
          <FormBankContainer
          {...this.props}/>
          <div className="d-flex flex-end">
            <Button
              className="flex-end"
              variant="primary"
              onClick={() => this.submitFormBank()}
            >
              <span className="indicator-label">{i18n.t("Save")}</span>
            </Button>
          </div>
        </Tab> */}
      </Tabs>
    );
  }
  render() {
    const { isProcessing, data, onChoose, getHelperData } = this.props;
    this.state.helperData = getHelperData;
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CashBank;

import React, { Component, useState, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/pembelian/purchase-order/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import LabelTranscation from "../../../utilities/Status/transaction";
import { transactionCode, transactionCodePO } from "../../../utilities/Const/TranscationCode";
import i18n from "../../../../i18n";
const MySwal = withReactContent(Swal);
export class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Transaction"), class: " text-gray-600" },
        {
          link: null,
          title: i18n.t("Purchase Order"),
          class: " text-gray-500",
        },
      ],
      column: [
        {
          key: "poNo",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("PO No"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date PO"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_supplier",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Supplier Name"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "total_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Grand Total"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: transactionCodePO,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component: (data, column) => {
            return <LabelTranscation value={data[column.key]} />;
          },
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
                    {/* Edit Button */}
                    {data.status == 4 ? (
                      <>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.updateData(data.id)}
                          >
                            {i18n.t("Edit")}
                          </Dropdown.Item>
                        </div>
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.deleteData(data.id)}
                          >
                            {i18n.t("Delete")}
                          </Dropdown.Item>
                        </div>
                        {(data.useApproval) ?<div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.approveData(data.id)}
                          >
                            {i18n.t("Approve")}
                          </Dropdown.Item>
                        </div>:null}
                        {(data.useApproval)?
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.rejectData(data.id)}
                          >
                            {i18n.t("Reject")}
                          </Dropdown.Item>
                        </div>:null}
                      </>
                    ) : null}
                    {data.status == 5 ? (
                      <Fragment>
                      <div className="menu-item px-3">
                        <Dropdown.Item
                          href={"/pembelian/purchase-order/print/" + data.id}
                          className="menu-link px-3"
                          target={"_blank"}
                        >
                          {i18n.t("Print")}
                        </Dropdown.Item>
                      </div>
                      <div className="menu-item px-3">
                      <Dropdown.Item
                        href="#"
                        className="menu-link px-3"
                        onClick={() => this.closingData(data.id)}
                      >
                        {i18n.t("Close")}
                      </Dropdown.Item>
                    </div>
                    </Fragment>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
    this.closingData = this.closingData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Purchase Order");
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Purchase Order");
    this.props.updateItem(id);
  }
  alertError(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve This PO")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.approveItem(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This PO")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.rejectItem(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  closingData(id) {
    const html = (
      <>
        <div>{i18n.t("You will Closing this PO")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.closingItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Process Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Confirm")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.createData()}
                    >
                      {i18n.t("Add Purchase Order")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  // checkAble={true}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "po_detail",
                    columns: [
                      { label: i18n.t("Item"), key: "item_name" },
                      { label: i18n.t("Qty"), key: "qty_format" },
                      { label: i18n.t("Unit"), key: "unit_name" },
                      { label: i18n.t("Receiving"), key: "receiving_format" },
                      { label: i18n.t("Remaining"), key: "remaining_format" },
                      { label: i18n.t("Nett Price"), key: "nett_price_format" },
                      { label: i18n.t("Total"), key: "total_format" },
                      { label: i18n.t("Description"), key: "description" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default PurchaseOrder;

export default class RoleActions {
  static BEFORE_ASSIGN='BEFORE_ASSIGN'
  static AFTER_ASSIGN='AFTER_ASSIGN'
  static BEFORE_REVOKE='BEFORE_REVOKE'
  static AFTER_REVOKE='AFTER_REVOKE'
  static SHOW_ASSIGN='SHOW_ASSIGN'
  static BEFORE_SHOW_ASSIGN='BEFORE_SHOW_ASSIGN'

  static beforeshowAssign(data){
    return {
      type: RoleActions.BEFORE_SHOW_ASSIGN
    };
  }
  static showAssign(data){
    return {
      type: RoleActions.SHOW_ASSIGN,
      payload:data
    };
  }

  static beforeAssign(){
    return {
      type: RoleActions.BEFORE_ASSIGN
    };
  }

  static afterAssign(data){
    return {
      type: RoleActions.AFTER_ASSIGN,
      payload:data
    };
  }

  static beforeRevoke(){
    return {
      type: RoleActions.BEFORE_REVOKE
    };
  }

  static afterRevoke(data){
    return {
      type: RoleActions.AFTER_REVOKE,
      payload:data
    };
  }


    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static GET_HELPER='GET_HELPER';
  
    static submit(){
      return {
        type: RoleActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: RoleActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: RoleActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: RoleActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: RoleActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: RoleActions.SHOW_DATA,
        payload: data
      };
    }
    static resetList() {
      return {
        type: RoleActions.RESET_LIST
      };
    }
    static error(error){
      return {
        type: RoleActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: RoleActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: RoleActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: RoleActions.RESET_DATA,
      };
    }
    static errorInternal(error){
      return {
        type: RoleActions.ERROR_SERVER,
        payload: error
      };
    }
    static helper(data){
        return {
          type: RoleActions.GET_HELPER,
          payload: data
        };
      }
  }
  
  
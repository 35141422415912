import RoleActions from './actions';

const INITIAL_STATE = {
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  onSubmit:false,
  data:{},
  errorInternal:false,
  helperFull:null
};

function RoleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RoleActions.BEFORE_SHOW_ASSIGN:
      return { ...state,isError: false, isProcessing: true,onSubmit:false,data:{}};
    case RoleActions.SHOW_ASSIGN:
      return { ...state,isError: false, isProcessing: false,onSubmit:false,data:action.payload};
    case RoleActions.BEFORE_ASSIGN:
      return { ...state,isError: false, isProcessing: true,onSubmit:true};
    case RoleActions.AFTER_ASSIGN:
      return { ...state, isProcessing: false,onSubmit:false,data:action.payload};
      case RoleActions.BEFORE_REVOKE:
        return { ...state,isError: false, isProcessing: true,onSubmit:true};
      case RoleActions.AFTER_REVOKE:
        return { ...state, isProcessing: false,onSubmit:false,data:action.payload};
    case RoleActions.RESET_LIST:
      return { ...state, isProcessing: false,data:{}, form:INITIAL_STATE.form,isNewRecord:true};
    case RoleActions.NEW_DATA:
      return { ...state, isProcessing: false,data:{}, form:INITIAL_STATE.form,isNewRecord:true};
    case RoleActions.UPDATE_DATA:
      return { ...state, isProcessing: false, data:{},isNewRecord:false};
    case RoleActions.VIEW_DATA:
      return { ...state, isProcessing: false, data:action.payload,isNewRecord:false};
    case RoleActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case RoleActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form};
    case RoleActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case RoleActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case RoleActions.SUCCESS_DATA:
      return { ...state, isError: false,form:INITIAL_STATE.form , onSubmit:false};
    case RoleActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case RoleActions.GET_HELPER:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperFull:action.payload};  
    case RoleActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case RoleActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
     
    default:
      return state;
    }
}


export default RoleReducer;

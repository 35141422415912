import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = cookieManager.getUserToken();

export class DetailCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      change: [],
      check: 0,
    };
  }

  filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
        })
    }
    return data
  }
  render() {
    const { choose } = this.props;
    if (choose != null && this.state.check == 0) {
      this.state.detail = choose.log_value;
      this.state.change = choose.change_value;
      this.state.check = 1;
    }
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-12">
          <Table rules="cols">
            <thead></thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>DATA ASLI</td>
                <td>DATA YANG DIUBAH</td>
              </tr>
              <tr
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  padding: "5",
                }}
              >
                <td>{i18n.t("Customer Code")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.code ? this.state.detail.code : "-"}
                </td>
                <td>
                  {this.state.detail.code != this.state.change.code
                    ? this.state.change.code
                    : "-"}
                </td>
              </tr>

              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Branch Name")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.branch
                    ? this.state.detail.branch
                    : "-"}
                </td>
                <td>
                  {this.state.detail.branch != this.state.change.branch
                    ? this.state.change.branch
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Store Name")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.store
                    ? this.state.detail.store
                    : "-"}
                </td>
                <td>
                  {this.state.detail.store != this.state.change.store
                    ? this.state.change.store
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Customer Type")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.type ? this.state.detail.type : "-"}
                </td>
                <td>
                  {this.state.detail.type != this.state.change.type
                    ? this.state.change.type
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Payment Type")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.paymentType
                    ? this.state.detail.paymentType
                    : "-"}
                </td>
                <td>
                  {this.state.detail.paymentType !=
                  this.state.change.paymentType
                    ? this.state.change.paymentType
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Name")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.name ? this.state.detail.name : "-"}
                </td>
                <td>
                  {this.state.detail.name != this.state.change.name
                    ? this.state.change.name
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Address")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.address
                    ? this.state.detail.address
                    : "-"}
                </td>
                <td>
                  {this.state.detail.address != this.state.change.address
                    ? this.state.change.address
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Province")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.province
                    ? this.state.detail.province
                    : "-"}
                </td>
                <td>
                  {this.state.detail.province !=
                  this.state.change.province
                    ? this.state.change.province
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("City")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.city ? this.state.detail.city : "-"}
                </td>
                <td>
                  {this.state.detail.city != this.state.change.city
                    ? this.state.change.city
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("District")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.district ? this.state.detail.district : "-"}
                </td>
                <td>
                  {this.state.detail.district != this.state.change.district
                    ? this.state.change.district
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Sub District")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.subDistrict ? this.state.detail.subDistrict : "-"}
                </td>
                <td>
                  {this.state.detail.subDistrict != this.state.change.subDistrict
                    ? this.state.change.subDistrict
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Long Business")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.longBusiness
                    ? this.state.detail.longBusiness
                    : "-"}{" "}
                  {i18n.t(" Tahun")}
                </td>
                <td>
                  {this.state.detail.longBusiness !=
                  this.state.change.longBusiness
                    ? this.state.change.longBusiness
                    : "-"}{" "}
                </td>
              </tr>
              {choose.paymentType == "Credit" ? (
                <Fragment>
                  <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                    <td>{i18n.t("TOP")}</td>
                    <td>:</td>
                    <td>
                      {this.state.detail.top
                        ? this.state.detail.top
                        : "-"}{" "}
                      {i18n.t(" Hari")}
                    </td>
                    <td>
                      {this.state.detail.top != this.state.change.top
                        ? this.state.change.top
                        : "-"}{" "}
                      {i18n.t(" Hari")}
                    </td>
                  </tr>
                  <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                    <td>{i18n.t("Invoice Limit")}</td>
                    <td>:</td>
                    <td>
                      {this.state.detail.invoiceLimit
                        ? this.state.detail.invoiceLimit
                        : "-"}{" "}
                      {i18n.t(" Hari")}
                    </td>
                    <td>
                      {this.state.detail.invoiceLimit !=
                      this.state.change.invoiceLimit
                        ? this.state.change.invoiceLimit
                        : "-"}{" "}
                      {i18n.t(" Nota")}
                    </td>
                  </tr>
                  <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                    <td>{i18n.t("Credit Limit")}</td>
                    <td>:</td>
                    <td>
                      {this.state.detail.creditLimit
                        ? this.state.detail.creditLimit
                        : "-"}{" "}
                      {i18n.t(" Hari")}
                    </td>
                    <td>
                      {this.state.detail.creditLimit !=
                      this.state.change.creditLimit
                        ? this.state.change.creditLimit
                        : "-"}
                    </td>
                  </tr>
                </Fragment>
              ) : (
                ""
              )}
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Phone")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.phone
                    ? this.state.detail.phone
                    : "-"}
                </td>
                <td>
                  {this.state.detail.phone != this.state.change.phone
                    ? this.state.change.phone
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("ID No.")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.idNumber
                    ? this.state.detail.idNumber
                    : "-"}
                </td>
                <td>
                  {this.state.detail.idNumber !=
                  this.state.change.idNumber
                    ? this.state.change.idNumber
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("E-Mail")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.email
                    ? this.state.detail.email
                    : "-"}
                </td>
                <td>
                  {this.state.detail.email != this.state.change.email
                    ? this.state.change.email
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Contact Person")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.contactPerson
                    ? this.state.detail.contactPerson
                    : "-"}
                </td>
                <td>
                  {this.state.detail.contactPerson !=
                  this.state.change.contactPerson
                    ? this.state.change.contactPerson
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Contact Person Phone")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.contactPersonPhone
                    ? this.state.detail.contactPersonPhone
                    : "-"}
                </td>
                <td>
                  {this.state.detail.contactPersonPhone !=
                  this.state.change.contactPersonPhone
                    ? this.state.change.contactPersonPhone
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Status Tax")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.taxStatus
                    ? this.state.detail.taxStatus
                    : "-"}
                </td>
                <td>
                  {this.state.detail.taxStatus !=
                  this.state.change.taxStatus
                    ? this.state.change.taxStatus
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("NPWP")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.npwp ? this.state.detail.npwp : "-"}
                </td>
                <td>
                  {this.state.detail.npwp != this.state.change.npwp
                    ? this.state.change.npwp
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("TOP")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.top != null ? this.state.detail.top + ' ' + this.state.detail.topLabel : '-'}
                </td>
                <td>
                  {this.state.detail.top != this.state.change.top
                    ? this.state.change.top+ ' ' + this.state.detail.topLabel : '-'}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Invoice Limit")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.invoiceLimit != null ? this.state.detail.invoiceLimitFormat : '-'}
                </td>
                <td>
                  {this.state.detail.invoiceLimit != this.state.change.invoiceLimit
                    ? this.state.change.invoiceLimitFormat
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Credit Limit")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.creditLimit != null ? 'Rp. ' + this.state.detail.creditLimitFormat : '-'}
                </td>
                <td>
                  {this.state.detail.creditLimit != this.state.change.creditLimit
                    ? 'Rp. ' + this.state.change.creditLimitFormat
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Status")}</td>
                <td>:</td>
                <td>
                  {this.state.detail.status ? ((this.state.detail.status==1)?i18n.t('Active'):i18n.t('Non Active')) : "-"}
                </td>
                <td>
                  {this.state.detail.status != this.state.change.status
                    ? ((this.state.change.status==1)?i18n.t('Active'):i18n.t('Non Active'))
                    : "-"}
                </td>
              </tr>
              <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                <td>{i18n.t("Files")}</td>
                <td>:</td>
                <td>
                  <PhotoGallery images={this.filterImages(this.state.detail.files,this.state.change.removeFiles)}></PhotoGallery>
                </td>
                <td>
                  <PhotoGallery images={this.filterImages(this.state.change.files,this.state.change.removeFiles)}></PhotoGallery>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row">
      <h3 className="text-center">{i18n.t('Competitor')}</h3>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{i18n.t('Item')}</th>
            <th>{i18n.t('Selling Price')}</th>
            <th>{i18n.t('Turnover/Week')}</th>
            <th>{i18n.t('Top')}</th>
            <th>{i18n.t('Invoice Limit')}</th>
            <th>{i18n.t('Credit Limit')}</th>
            <th>{i18n.t('Note')}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.detail.competitor.map((log,i)=>{
              return (
                <tr key={'a'+i}>
                  <td>{i+1}</td>
                  <td>{log.itemName}</td>
                  <td>{log.sellingPrice}</td>
                  <td>{log.omset}</td>
                  <td>{log.top}</td>
                  <td>{log.invoiceLimit}</td>
                  <td>{log.creditLimit}</td>
                  <td>{log.description}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      <p className="text-center"><FontAwesomeIcon icon={faArrowDown} /></p>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{i18n.t('Item')}</th>
            <th>{i18n.t('Selling Price')}</th>
            <th>{i18n.t('Turnover/Week')}</th>
            <th>{i18n.t('Top')}</th>
            <th>{i18n.t('Invoice Limit')}</th>
            <th>{i18n.t('Credit Limit')}</th>
            <th>{i18n.t('Note')}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.change.competitor.map((log,i)=>{
              return (
                <tr key={'b'+i}>
                  <td>{i+1}</td>
                  <td>{log.itemName}</td>
                  <td>{log.sellingPrice}</td>
                  <td>{log.omset}</td>
                  <td>{log.top}</td>
                  <td>{log.invoiceLimit}</td>
                  <td>{log.creditLimit}</td>
                  <td>{log.description}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      </div>
    </React.Fragment>
    )
  }
}

export default DetailCustomer;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}
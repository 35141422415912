import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import TransactionUpdate from "../../../../components/modules/log/transaction-update";
import TransactionUpdateMiddleware from "../../../../middleware/modules/log/transaction-update";
// import CustomerApproval from "../../../../components/modules/log/customer-approval";
// middlewares
const mapStateToProps = ({ auth, customerApproval }) => ({
  isProcessing: customerApproval.isProcessing,
  isError: customerApproval.isError,
  errorMessage: customerApproval.errorMessage,
  data: customerApproval.data,
  showModal:customerApproval.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      search: (data) => TransactionUpdateMiddleware.list(data),
      reset: () => TransactionUpdateMiddleware.resetList(),
      ApproveData: (data,value,search) => TransactionUpdateMiddleware.beforeApprove(data,value,search),
      RejectData: (data,value, search) => TransactionUpdateMiddleware.beforeReject(data, value,search),
      view: data =>TransactionUpdateMiddleware.getOne(data),
    },
    dispatch
  );
};
class TransactionUpdateContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.viewItem=this.viewItem.bind(this);
  }

  viewItem(id){
    this.props.view(id)
    // this.props.getHelper()
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
     return (
      <React.Fragment>
         <Helmet>
            <title>{i18n.t("Log Customer Approval")}</title>
          </Helmet>
          <TransactionUpdate
           {...this.props}
           showModal={showModal}
           viewItem={this.viewItem}
          
          />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionUpdateContainer);

import React, { Component, Fragment, useState } from "react";
import { addDays } from "date-fns";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Chart  from 'react-apexcharts'
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Radio from "../../../utilities/Form/radio";
import { SalesPerformance } from "../../../utilities/Const/StatusActive";
const MySwal = withReactContent(Swal);
const token=cookieManager.getUserToken()
export class SalesPerformanceShow extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      fillList:0,
      dataSeries : [],
      detail : [],
      range:[],
      year: new Date().getFullYear(),
      branchCurrent:null,
      branchList: [],
      branchText: i18n.t('Select Branch'),
      typeCustomerCurrent: null,
      typeCustomerList: [],
      typeCustomerText: i18n.t('Select Type Customer'),
      customerList: [],
      customerCurrent: null,
      customerText: i18n.t('All'),
      listData: [],
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      periodEndList : [],
      periodStartCurrent: null,
      periodEndCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      periodCurrentExcel: null,
      periodTextExcel: i18n.t("Select Period"),
      periodListExcel: [],
      loadingSales:false,
      loadingCustomer:false,
      page: 1,
      typeChart: 2,
      typeExcel: 2,
      show: 0,
      choose: {
        1: i18n.t("Quantity"),
        2: i18n.t("Revenue"),
      },
      typeDataChart:1,
      monthList: [
        { value: '01', label: i18n.t('January') },
        { value: '02', label: i18n.t('February') },
        { value: '03', label: i18n.t('March') },
        { value: '04', label: i18n.t('April') },
        { value: '05', label: i18n.t('May') },
        { value: '06', label: i18n.t('June') },
        { value: '07', label: i18n.t('July') },
        { value: '08', label: i18n.t('August') },
        { value: '09', label: i18n.t('September') },
        { value: '10', label: i18n.t('October') },
        { value: '11', label: i18n.t('November') },
        { value: '12', label: i18n.t('December') },
      ],
      monthValue: null,
      monthText: i18n.t("Select Month"),
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Account Payable"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
  }

  handlerRadio(e) {
    console.log(e)
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value,
    })
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handlerSelectPeriod(e, name, currentState) {
    var key = name
    var value = null
    let list = [];
    let dataList = [];

    dataList =this.state.periodList;
    if(key == "periodStartCurrent" && e != null){
      var index = dataList.findIndex(item => item.value === e.value)
      for(var i = index; i < index+5;i++){
        list.push(dataList[i])
      }
      this.setState({periodEndList:list});

    }else{
      
    }
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    })
  }

  handler(e, id) {
    var value = e.target.value
    this.setState({
      ...this.state,
      absence: {
        ...this.state.absence,
        [id]: value
      }
    }
    )
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  setData() {
    var startDate = this.state.typeChart == 1 ? moment(this.state.startDate).format("YYYY-MM-DD") : 
                    this.state.typeChart == 2 ? (this.state.periodStartCurrent == null?'':this.state.periodStartCurrent.value) :
                    this.state.type == 3 ? null:null;
    var endDate = this.state.typeChart == 1 ? moment(this.state.endDate).format("YYYY-MM-DD") : 
                  this.state.typeChart == 2 ? (this.state.periodEndCurrent == null?'':this.state.periodEndCurrent.value) :
                  this.state.typeChart == 3 ? null:null;
    // var month = this.state.monthValue != null ? this.state.monthValue.value : '';
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var idCustomer = (this.state.customerCurrent == null) ? '' : this.state.customerCurrent.value;
    var idTypeCustomer = (this.state.typeCustomerCurrent == null) ? '' : this.state.typeCustomerCurrent.value;
    var type = this.state.typeChart;
    // var typeData = this.state.typeDataChart;
    let params = {
      startDate: startDate,
      endDate: endDate,
      idBranch: idBranch,
      idCustomer: idCustomer,
      idTypeCustomer: idTypeCustomer,
      type: type,
      // typeData:typeData,
      // month:month
    }
    this.props.setData(params);
  }

  alertHandler(title, message, icon) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props) {
    let params = null;
    const token=cookieManager.getUserToken()
    switch (type) {
        case "getEmployee":
          var url = process.env.REST_URL + "helper/get-employee";
          if(this.state.branchCurrent==null){
            type=null;
          }
          params = {
            branch: this.state.branchCurrent !=null?this.state.branchCurrent.value:null,
          };
          break;

          case "getCustomer":
            var url = process.env.REST_URL + "helper/get-list-customer";
            if(this.state.branchCurrent==null){
              type=null;
            }
            params = {
              branch: this.state.branchCurrent !=null?this.state.branchCurrent.value:null,
              tipe: this.state.typeCustomerCurrent !=null?this.state.typeCustomerCurrent.value:null,
            };
            break;
     
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  renderList() {
    const { helperList, dataList } = this.props
    let html = [];
    if(dataList != null && dataList != undefined){
      let row = dataList.list;
      if(row.length > 0){
        for (var i = 0; i < row.length ; i++){
                html.push(
                    <tr className="border" key={i}>
                      <td className="search-td">{row[i].name_customer}</td>
                      <td className="search-td">{row[i].district}</td>
                      <td className="search-td">{row[i].week}</td>
                      <td className="search-td">{row[i].month}</td>
                      {row[i].value != null?
                        row[i].value.map((val,i)=>{
                          return (
                            <Fragment>
                              <td className="search-td" style={{textAlign:"right"}}>{val.stock_format}</td>
                              <td className="search-td" style={{textAlign:"right"}}>{val.volume_format}</td>
                              <td className="search-td" style={{textAlign:"right"}}>{i18n.t("Rp. ") + val.total_format}</td>
                            </Fragment>
                          );
                        })
                      :null}
                      
                    </tr>
                );
              }
      }
      
    }
      return html;
  }

  renderTable() {
    const { dataList,isError } = this.props;
    if (dataList != null && dataList != undefined && isError == false) {
      let item = dataList.item;
      let list = dataList.list;
      if(list.length > 0){
      return (
        <div>
          <div id="html-dist"></div>
          <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
            {i18n.t("CUSTOMER PERFORMANCE")}
          </div>
          <div className="row">
            <Fragment>
              <div className="search-table-outter ">
                <br />
                <table className="search-table" style={{ borderBottom: '1px solid #eff2f5' }}>
                  <thead className="search-thead">
                  <tr>
                    <th rowSpan={2} className="search-th">{i18n.t("Name Customer")}</th>
                    <th rowSpan={2} className="search-th">{i18n.t("District")}</th>
                    <th rowSpan={2} className="search-th">{i18n.t("Week")}</th>
                    <th rowSpan={2} className="search-th">{i18n.t("Month")}</th>
                    
                    {item.length > 0 ?
                    item.map((col,i)=>{
                      return (<th colSpan={3} className="search-th">{col.name}</th>);
                    })
                    :null
                  }
                  </tr>
                  <tr>
                  {item.length > 0 ?
                    item.map((col,i)=>{
                      return (
                        <Fragment>
                          <th className="search-th">{i18n.t("Stock")}</th>
                          <th className="search-th">{i18n.t("Volume")}</th>
                          <th className="search-th">{i18n.t("Omset")}</th>
                          </Fragment>
                      );
                    })
                    :null
                  }
                  </tr>
                  </thead>
                  <tbody className="search-body"key={"body"}>
                    {this.renderList()}
                  </tbody>
                </table>
              </div>
            </Fragment>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
          {i18n.t("DATA NOT FOUND")}
        </div>);
    }
  }
  }

  render() {
    const { isProcessing,helperCustomerPerformance,dataList } = this.props;
    console.log('PROPS',this.props)
    console.log('STATE',this.state)
    if(helperCustomerPerformance!= null && this.state.fillList == 0){
      this.state.branchList = helperCustomerPerformance.branch;
      this.state.typeCustomerList = helperCustomerPerformance.tipe;
      this.state.periodList = helperCustomerPerformance.workCalendar;
      this.state.fillList = 1;
    }
    return (
          <React.Fragment >
          <div className="row ">
          {this.state.typeChart == 2 ?
          <Fragment>
          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"period"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.setState({periodEndList:[],periodEndCurrent:'',periodEnd:''})
                await this.handlerSelectPeriod(e, "periodStartCurrent", "periodStartCurrent");
              }}
              options={this.state.periodList}
              title={i18n.t("Week Period")}
              value={this.state.periodStartCurrent}
              placeholder={this.state.periodText}
            />
          </div>
          <div style={{width:'35px'}} className="col-sm-1 mt-11">
            {i18n.t("To")}
          </div>
          <div className="col-sm-2 mt-3">
          <Select2
              isClearable={true}
              name={"periodEnd"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectPeriod(e, "periodEndCurrent", "periodEndCurrent");
              }}
              options={this.state.periodEndList}
              title={i18n.t(" ")}
              value={this.state.periodEndCurrent}
              placeholder={this.state.periodText}
            />
          </div>
            </Fragment>
            : null}

          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idBranch"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idBranch", "branchCurrent");
                if (e == null) {
                  await this.setState({
                    customerList:[],customerCurrent:''
                  })
                
                } else {
                  await this.setState({
                    loadingCustomer:true
                  });
                  this.changeData({
                        keyFill:"customerList",
                        type:"getCustomer",
                        callback:()=>{this.setState({loadingCustomer:false})}
                      })
                }
              }}
              options={this.state.branchList}
              title={i18n.t("Branch")}
              value={this.state.branchCurrent}
              placeholder={this.state.branchText}
              labelfield={"true"}
            />
             <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError('idBranch')}
              </div>
          </div>
          <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idTypeCustomer"}
              required={true}
              size={4}
              onChange={async (e) => {
                await this.handlerSelect(e, "idTypeCustomer", "typeCustomerCurrent");
                if (e == null) {
                  await this.setState({
                    customerList:[],customerCurrent:''
                  })
                
                } else {
                  await this.setState({
                    loadingCustomer:true
                  });
                  this.changeData({
                        keyFill:"customerList",
                        type:"getCustomer",
                        callback:()=>{this.setState({loadingCustomer:false})}
                      })
                }
              }}
              options={this.state.typeCustomerList}
              title={i18n.t("Type Customer")}
              value={this.state.typeCustomerCurrent}
              placeholder={this.state.typeCustomerText}
              labelfield={"true"}
            />
            <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError('idTypeCustomer')}
              </div>
          </div>
          <div className="col-md-2 mt-3">
            <Select2
              isClearable={true}
              name={"idCustomer"}
              required={true}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                } else {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                }
              }}
              options={this.state.customerList}
              title={i18n.t("Customer")}
              value={this.state.customerCurrent}
              placeholder={this.state.customerText}
              isLoading={this.state.loadingCustomer}
              labelfield={"true"}
            />
          </div>
            {/* <div className="col-sm-2 mt-5">
              <Radio
                list={this.state.choose}
                name={"typeDataChart"}
                title={""}
                inline={false}
                value={this.state.typeDataChart}
                handler={this.handlerRadio}
              />
            </div> */}
      {/* </div>
      <div className="row ml-5"> */}
          
          {/* <div className="col-sm-2 mt-3">
            <Select2
              isClearable={true}
              name={"idSales"}
              required={true}
              size={4}
              isLoading={this.state.loadingSales}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                } else {
                  await this.handlerSelect(e, "idSales", "salesCurrent");
                }
              }}
              options={this.state.salesList}
              title={i18n.t("Sales")}
              value={this.state.salesCurrent}
              placeholder={this.state.salesText}
              labelfield={"true"}
            />
          </div> */}
          {/* <div className="col-md-3 mt-3">
            <Select2
              isClearable={true}
              name={"idCustomer"}
              required={true}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                } else {
                  await this.handlerSelect(e, "idCustomer", "customerCurrent");
                }
              }}
              options={this.state.customerCurrent}
              title={i18n.t("Customer")}
              value={this.state.customerCurrent}
              placeholder={this.state.customerText}
              labelfield={"true"}
            />
          </div> */}
          
          </div>
          <div className="row ml-5">
            <div className="col-md-9 mt-3 mb-3">
                <Button onClick={async (e) => {
                  await this.setState({ page: 1 }, ()=>this.setData())
                  await this.setState({ headeTable: true })
                }}
                  variant="success" className="btn btn-md btn btn-success m-1 "><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Generate")} </Button>
            </div>
        </div>
        {this.state.headeTable == true ?
          <React.Fragment>
            <div className={isProcessing ? " table-loading" : ""}>
            {!isProcessing ? (
            <Fragment>
               {this.renderTable()}
            </Fragment>
              ):(
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              )
            }
            </div>
          </React.Fragment> :
          ''}
      </React.Fragment>
          
    );
  }
}

export default SalesPerformanceShow;

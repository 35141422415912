import React, { Component } from "react"
import i18n from "../../../../i18n";
import TextArea from "../../../utilities/Form/TextArea";
import Radio from "../../../utilities/Form/radio";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select2 from "../../../utilities/Form/Select2";
import cookieManager from "../../../../utils/cookieManager";

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form:{
                date:new Date(this.props?.viewData.date),
                salesList:[],
                idSales:null,
                typeList:{
                    0: i18n.t("Re Schedule"),
                    1: i18n.t("Change Sales"),
                },
                type:0,
                salesCurrent:null,
                salesText:i18n.t("Select Sales"),
                remark:"",
            },
            data:this.props?.viewData||null
        }
        this.handler=this.handler.bind(this)
        this.handlerSelect=this.handlerSelect.bind(this)
        this.handlerDateTime=this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
    componentDidMount(){
        this.getSales();
    }

    getSales(){
        const url=process.env.REST_URL +'helper/get-employee-sales'
        const token= cookieManager.getUserToken();
        const params={
            branch:this.state.data?.idBranch||null
        }
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            url: url + '/',
            params:params,
            responseType: 'json'
          })
        .then((response) => {
            if (response && response.status === 200 && response.data) {
                let salesList=response.data.result
                salesList=salesList.filter((a)=>a.value != this.state.data.idSales)
                this.setState({
                    ...this.state,
                    form:{
                        ...this.state.form,
                        salesList: salesList
                    }}
                )
            }
        })
        .catch((error) => {
            console.log('error get sales',error)
        });
    }
     handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value
            }
          
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value,
                [currentState]:e
            }
            
        })
    }

     handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            ...this.state,
            form:{
                ...this.state.form,
                [key]: value,
            }
            
        })
    }

     renderError(name) {
        const { isError, errorMessage } =  this.props;
        var value = "";
        if (isError) {
          if (errorMessage[name] != undefined) {
            value = errorMessage[name][0];
          }
        }
        return i18n.t(value);
      }

    runSubmit=()=>{
        const post={
            id:this.state.data.id,
            type:this.state.form.type,
            remark:this.state.form.remark,
            date:moment(this.state.form.date).format('YYYY-MM-DD'),
            idSales:this.state.form.idSales
        }
        return post
    }
    render (){
        return (
            <div className="row">
                <div className="col-12">
                    <table  className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="font-weight-bold">Customer</th>
                                <td>{this.state.data?.name}</td>
                            </tr>
                            <tr>
                                <th className="font-weight-bold">Branch</th>
                                <td>{this.state.data?.branch}</td>
                            </tr>
                            <tr>
                                <th className="font-weight-bold">Sales</th>
                                <td>{this.state.data?.employee}</td>
                            </tr>
                        </thead>
                    
                    </table>
                </div>
                <Radio
                    list={this.state.form.typeList}
                    name={"type"}
                    title={i18n.t("Change type")}
                    inline={false}
                    value={this.state.form.type}
                    handler={this.handler}
                />
                {this.state.form.type == 0 &&
                     <DatePickerCustom
                        name={"date"}
                        title={i18n.t("Date")}
                        required={true}
                        selected={this.state.form.date}
                        onSelect={async (e)=>{
                                await this.handlerDateTime(e,'date');
                            }
                        }
                        onChange={(e)=>{}}
                        autoComplete={"off"}
                        dateFormat="dd MMM yyyy"
                        minDate={new Date(this.state.data.date)}
                        className="form-control form-control-sm"
                    />
                }
                 {this.state.form.type == 1 &&
                     <Select2 
                        isClearable={false}
                        name={'idSales'}
                        size={4}
                        onChange={async (e)=>{
                                await this.handlerSelect(e,"idSales","salesCurrent")
                        }}
                        options={this.state.form.salesList}
                        title={i18n.t("Sales")}
                        value={this.state.form.salesCurrent}
                        placeholder={this.state.form.salesText}
                        error={this.renderError('idSales')}
                    />
                }
                <TextArea
                  name={"remark"}
                  title={i18n.t("Remark")}
                  value={this.state.form.remark}
                  handler={this.handler}
                  error={this.renderError("remark")}
                />
            </div>
        )
    }
  
}
import {  Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
    { src: 'assets/fonts/times.ttf' , fontWeight: 400},
    { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
    });
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo:{
        width:80,
        height:80,
    },
    body: {
        padding: "20px",
    },
    row: {
        
        flex:1,
        flexDirection: 'row',
    },
    col:{
        flex:1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr:{
        flexDirection: 'row', 
    },
    td:{
        flexDirection: 'column',
    },

    //Header
    titleContainer:{
        flexDirection: 'row',
    },
    header:{
        fontSize:"18px",
        fontFamily: 'Times-Roman'
    },
    address:{
        fontSize:"12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx:{
        fontSize:"17px",
        fontWeight:700,
        fontFamily: 'Times-Roman',
        textAlign:'right'
    },
    tableTitle:{
        border:'1px solid black',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },

    tableTotal:{
        fontSize:"12px",
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
    },
    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
       
    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    //end Table

    //Footer
    footer:{
        position:'absolute',
        bottom:0
    }
    //end Footer
  });
const tableRowsCount = 11;
class PrintInvoice extends Component{
    constructor(props){
        super(props)
        this.state={
            title:i18n.t("Invoice No")+' : ',
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:{
                poNo:'',
                po_detail:[]
            }
        };
    }
    renderMessage(){
        const {isProcessing, requestPrint}=this.props
        switch(true){
            case isProcessing:
                return (
                <div style={loader}>
                    <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/>
                    <div style={{fontSize:36}}>Getting Data From Server</div>
                </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{fontSize:36}}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={()=>{this.props.getPrint(this.props.id)}}>Reload Page</Button></div>
                    </div>)
        }
    }
    render(){
        let FillText="";
        const {dataPrint}=this.props
        let total=0
        let totalDiscount=0
        let totalTax=0
        let totalAll=0
        let address=[]
        if(dataPrint!=null){
            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
            total = this.state.data.details.map(item => item.qty * parseFloat(item.unitPrice)).reduce(
                (accumulator, currentValue) => accumulator + currentValue , 0)
            totalDiscount = this.state.data.details.map(item => item.qty * parseFloat((item.discount_rate)?item.discount_rate:0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue , 0)    
            totalTax = this.state.data.details.map(item => item.qty * parseFloat((item.tax)?item.tax:0)).reduce(
                (accumulator, currentValue) => accumulator + currentValue , 0) 
            totalAll = (total - totalDiscount) - totalTax    
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title+ ((dataPrint !=undefined)?this.state.data.invoice_no:'')}>
                        <Page wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map((a,i)=>
                                                <Text style={styles.address} wrap={false} key={'address'+i}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                    <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={[styles.col,{width:'600px',paddingTop:'20px'}]} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Invoice")}</Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'85px'}]} >
                                                        <Text>{i18n.t("Invoice No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'300px'}]} >
                                                        <Text>{this.state.data.invoice_no}</Text>
                                                    </View>
                                                    {/* BATAS */}
                                                    <View style={[styles.td,{width:'80px'}]} >
                                                        <Text>{i18n.t("Type Paymet")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'100px'}]}>
                                                        <Text>{this.state.data.payment_type}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'80px'}]} >
                                                        <Text>{i18n.t("Branch")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'100px'}]} >
                                                        <Text>{this.state.data.branch_name}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'80px'}]} >
                                                        <Text>{i18n.t("Date")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'100px'}]} >
                                                        <Text>{this.state.data.date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'80px'}]} >
                                                        <Text>{i18n.t("Due Date")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'100px'}]} >
                                                        <Text>{this.state.data.due_date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'80px'}]} >
                                                        <Text>{i18n.t("Supplier")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'440px'}]}>
                                                        <Text>{this.state.data.name_supplier}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                   
                                                </View>
                                            </View>
                                        </View>
                                        </View>
                                </View>
                                <View style={styles.tableContainer}>
                                    <View style={styles.containerItem}>
                                        <Text style={{width:'5%'}}>{i18n.t("No")}</Text>
                                        <Text style={{width:'10%'}}>{i18n.t("Item Code")}</Text>
                                        <Text style={{width:'23%'}}>{i18n.t("Item")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("Qty")}</Text>
                                        <Text style={{width:'15%'}}>{i18n.t("@Price")}</Text>
                                        {/* <Text style={{width:'13%'}}>{i18n.t("Discount")}</Text> */}
                                        <Text style={{width:'19%'}}>{i18n.t("Total Price")}</Text>
                                        {/* <Text style={{width:'19%'}}>{i18n.t("Warehouse")}</Text> */}
                                    </View>
                                    <Fragment>{this.state.data.details.map( (item,i) => 
                                        <View style={styles.rowItem} key={i+'itemPo'}>
                                            <Text style={{width:'5%',padding:1}}>{i+1}</Text>
                                            <Text style={{width:'10%',padding:1}}>{item.code_item}</Text>
                                            <Text style={{width:'25%',padding:1}}>{item.item_name}</Text>
                                            <Text style={{width:'13%',padding:1}}>{item.qty_format +' '+item.unit_name}</Text>
                                            <Text style={{width:'15%',padding:1,textAlign: "right"}}>{'Rp. '+item.nett_price_format}</Text>
                                            {/* <Text style={{width:'13%',padding:1,textAlign: "right"}}>{item.discount_rate_format}</Text> */}
                                            <Text style={{width:'19%',padding:1,textAlign: "right"}} >{'Rp. '+item.total_format}</Text>
                                            {/* <Text style={{width:'24%',padding:1,textAlign: "right"}} >{item.name_warehouse + ' - ' + item.name_sub_warehouse}</Text> */}
                                        </View>
                                    )}</Fragment>
                                    
                                </View>
                            <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'500px',paddingTop:'20px'}} >
                                        <View style={[styles.tableTotal,{textAlign:'left'}]}>
                                                <Text style={{fontFamily:'Times-Roman',fontWeight:'900'}}>Note :</Text>
                                        </View>
                                        <View style={styles.tableTotal} >
                                                <Text>{this.state.data.description}</Text>
                                        </View>
                                        </View>
                                        <View style={styles.col,{width:'300px',paddingTop:'20px',position:'right'}} >
                                            <View style={styles.tableTotal}>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'50px'}]} >
                                                        <Text>Sub Total</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'120px',textAlign:'right'}]} >
                                                        <Text>{ parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                     </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'50px'}]} >
                                                        <Text>Discount</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'120px',textAlign:'right'}]} >
                                                    <Text>{ parseFloat(totalDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                      </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'50px'}]} >
                                                        <Text>Tax</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'120px',textAlign:'right'}]} >
                                                    <Text>{parseFloat(totalTax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                       </View>
                                                </View>
                                                <View style={styles.tr}>
                                                <View style={[styles.td,{width:'200px',borderBottom:'1px'}]} >
                                                        <Text></Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td,{width:'50px'}]} >
                                                        <Text>Total</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : Rp. </Text>
                                                    </View>
                                                    <View style={[styles.td,{width:'120px',textAlign:'right'}]}>
                                                    <Text>{parseFloat(totalAll).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                                                     </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                    <View style={styles.col,{width:'500px',paddingTop:'40px',paddingLeft:'40px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>Disetujui Oleh</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    <View style={styles.col,{width:'200px',paddingTop:'40px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>Dibuat Oleh</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    </View>
                                 </View>
                                 <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                    <View style={styles.col,{width:'500px',paddingTop:'50px',paddingLeft:'55px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'300px'}} >
                                                <Text>({this.state.data.created_by})</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    <View style={styles.col,{width:'180px',paddingTop:'50px'}} >
                                    <View style={styles.tableTotal}>
                                            <View style={styles.tableTotal,{width:'320px'}} >
                                                <Text>({this.state.data.created_by})</Text>
                                            </View>        
                                    </View>
                                    </View>
                                    </View>
                                 </View>
                            </View>
                        </Page>
                    </Document>
            </PDFViewer>
            :
            this.renderMessage()}
            
        </React.Fragment>   
        
        )
    }
}

export default PrintInvoice;

const loader={
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection:'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}
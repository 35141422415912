import WarehouseOutActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  errorInternal:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperWarehouseOut:null,
  WarehouseOutHelperFull:null,
  printWHOut:null
};

function WarehouseOutReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WarehouseOutActions.RESET_LIST:
      return INITIAL_STATE;
    case WarehouseOutActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, errorMessage: [], form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case WarehouseOutActions.UPDATE_DATA:
      return { ...state, isProcessing: false,  errorMessage: [], single:null,isNewRecord:false,showModal:true};
    case WarehouseOutActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case WarehouseOutActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WarehouseOutActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case WarehouseOutActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case WarehouseOutActions.SHOW_DATA:  
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case WarehouseOutActions.CANCEL_DATA:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case WarehouseOutActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case WarehouseOutActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case WarehouseOutActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case WarehouseOutActions.GET_HELPER_WAREHOUSE_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false, WHOutHelperFull:action.payload}; 
    case WarehouseOutActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case WarehouseOutActions.BEFORE_PRINT_WHOUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHOut:null};
    case WarehouseOutActions.PRINT_WHOUT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printWHOut:action.payload};    
    default:
      return state;
    }
}


export default WarehouseOutReducer;

import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';


export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                code:'',
                idBranch:null,
                name:'',
                status:1,
            },
            branchCurrent:null,
            branchText:i18n.t('Select Branch'),
            isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
       return this.state.form
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    render(){
        const {data,isNewRecord,storeHelper}=this.props   
        console.log(this.props)
        console.log(storeHelper)
        if(storeHelper!=null){
        this.state.branchList=storeHelper.branch     
        }
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.form.name=data.name,
                this.state.form.idBranch=data.branch_id,
                this.state.form.status=data.status
                this.state.form.code=data.code
                this.state.isUpdate=1
                this.state.branchCurrent={value:data.branch_id,label:((data.branch !='')?data.branch:this.state.branchText)}
            }
        }
        return (
            <form>
                 <Select2 
                    name={'idBranch'}
                    required={true}
                    onChange={(e)=>this.handlerSelect(e,"idBranch","branchCurrent")}
                    options={this.state.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.branchCurrent}
                    placeholder={this.state.branchText}
                    error={this.renderError('idBranch')}
                />
                <Input
                    type={"input"}
                    name={"code"}
                    title={i18n.t("Code")}
                    required
                    value={this.state.form.code}
                    handler={this.handler}
                    error={this.renderError('code')}
                />
                <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.form.name}
                    handler={this.handler}
                    error={this.renderError('name')}
                />
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.form.status}
                    handler={this.handler}
                    error={this.renderError('status')}
                />
            </form>
        );
    }
}

export default Form;
import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import ListPaymentActions from '../../../../reducers/modules/accounting/list-payment/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'accounting/list-payment'

export default class ListPaymentMiddelware {
  static getToken(){
    return cookieManager.getUserToken();
  }
  static beforeSaveUpdate(id,post,search){
    return (dispatch) => {
      dispatch(ListPaymentActions.beforeUpdate());
      ListPaymentMiddelware.saveUpdate(dispatch, id,post,search);
    };
  }
  static beforeSaveChange(post,search){
    return (dispatch) => {
      dispatch(ListPaymentActions.beforeChange());
      ListPaymentMiddelware.saveChange(dispatch, post,search);
    };
  }

  static update(params){
    return (dispatch) => {
      dispatch(ListPaymentActions.update());
      ListPaymentMiddelware.showOne(dispatch, params);
    };
  }
  static showOne(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url: url + '/' + params,
      responseType: 'json'
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.view(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch, error)
      });
  }


  static resetList() {
    return (dispatch) => {
      dispatch(ListPaymentActions.resetList());
    };
  }

  static listSupplier(params) {
    return (dispatch) => {
      dispatch(ListPaymentActions.process());
      ListPaymentMiddelware.showlistSupplier(dispatch, params);
    };
  }

  static listCustomer(params) {
    return (dispatch) => {
      dispatch(ListPaymentActions.process());
      ListPaymentMiddelware.showlistCustomer(dispatch, params);
    };
  }

  static beforeVoidSupplier(id, params) {
    return (dispatch) => {
      dispatch(ListPaymentActions.voidSupplier());
      ListPaymentMiddelware.voidDataSupplier(dispatch, params, id);
    };
  }

  static beforeVoidCustomer(id, params) {
    return (dispatch) => {
      dispatch(ListPaymentActions.voidCustomer());
      ListPaymentMiddelware.voidDataCustomer(dispatch, params, id);
    };
  }
  
  static list(params) {
    return (dispatch) => {
      dispatch(ListPaymentActions.process());
      ListPaymentMiddelware.showlist(dispatch, params);
    };
  }

  static showlist(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url: url,
      responseType: 'json',
      params: params
    })
      .then((response) => {

        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.list(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch, error)
      });
  }

  static showlistSupplier(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url: url+'/supplier',
      responseType: 'json',
      params: params
    })
      .then((response) => {

        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.listSupplier(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch, error)
      });
  }

  static showlistCustomer(dispatch, params) {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url: url+'/customer',
      responseType: 'json',
      params: params
    })
      .then((response) => {

        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.listCustomer(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch, error)
      });
  }
  

  static closeModal(){
    return (dispatch) => {
      dispatch(ListPaymentActions.closeModal());
      
    };
  }

  static delete(data){
    return (dispatch) => {
      ListPaymentMiddelware.deleteData(dispatch,data);
    };
  }

  static deleteData(dispatch,params){
    axios({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url:url+'/'+params,
      responseType: 'json'
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        dispatch(ListPaymentActions.delete());
        dispatch(ListPaymentActions.fetchSchedule(response.data.result));
        ListPaymentMiddelware.alertHendler(dispatch, response, true, 'Delete Data Successfully', 'success')
      }
    })
    .catch((error) => {
      ListPaymentMiddelware.alertHendler(dispatch,error)
    });
}


  static view(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.process());
      ListPaymentMiddelware.getOne(dispatch,data);
    };
  }

  static getOne(dispatch,params){
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url:url+'/'+params,
      responseType: 'json'
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        let result=response.data.result
       
        dispatch(ListPaymentActions.view(result));
      }
    })
    .catch((error) => {
      ListPaymentMiddelware.alertHendler(dispatch,error)
    });
}

  static create(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.create());
      ListPaymentMiddelware.formHelper(dispatch,data);
    };
  }

  static create(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.create());
      ListPaymentMiddelware.formHelper(dispatch,data);
    };
  }

  static change(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.change());
      ListPaymentMiddelware.formHelperChange(dispatch,data);
    };
  }

  static formHelper(dispatch,params){
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
      },
      url:process.env.REST_URL+'helper/get-helper-visit-schedule-form' ,
      responseType: 'json',
      params:params
    })
    .then((response) => {
      
      if (response && response.status === 200 && response.data) {
        let result=response.data.result
        let data={
            ...params,
            ...result
        }
        dispatch(ListPaymentActions.formHelper(data));
      }else{
        dispatch(ListPaymentActions.formHelper(params));
      }
    })
    .catch((error) => {
      ListPaymentMiddelware.alertHendler(dispatch,error)
    });
}

static voidDataSupplier(dispatch, params, id, ) {
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`,
    },
    url: url + "/supplier/void/" + id,
    responseType: "json",
  })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        dispatch(
          ListPaymentActions.process(),
          ListPaymentMiddelware.showlistSupplier(dispatch, params)
        );
        ListPaymentMiddelware.alertHendler(
          dispatch,
          response,
          true,
          "Void Data Successfully",
          "success"
        );
      }
    })
    .catch((error) => {
      ListPaymentMiddelware.alertHendler(dispatch, error, true);
    });
}

static voidDataCustomer(dispatch, params, id, ) {
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`,
    },
    url: url + "/customer/void/" + id,
    responseType: "json",
  })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        dispatch(
          ListPaymentActions.process(),
          ListPaymentMiddelware.showlistCustomer(dispatch, params)
        );
        ListPaymentMiddelware.alertHendler(
          dispatch,
          response,
          true,
          "Void Data Successfully",
          "success"
        );
      }
    })
    .catch((error) => {
      ListPaymentMiddelware.alertHendler(dispatch, error, true);
    });
}

static formHelperChange(dispatch,params){
  axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
    },
    url:process.env.REST_URL+'helper/get-helper-cashbank-book' ,
    responseType: 'json',
    params:params
  })
  .then((response) => {
    
    if (response && response.status === 200 && response.data) {
      let result=response.data.result
      let data={
          ...params,
          ...result
      }
      dispatch(ListPaymentActions.formHelperChange(data));
    }else{
      dispatch(ListPaymentActions.formHelperChange(params));
    }
  })
  .catch((error) => {
    ListPaymentMiddelware.alertHendler(dispatch,error)
  });
}

  static resetList(){
    return (dispatch) => {
        dispatch(ListPaymentActions.resetList());
      };
  }

  static getHelper(params=null){
    return (dispatch) => {
      ListPaymentMiddelware.showHelper(dispatch,params);
      };
  }
  static showHelper(dispatch,params){
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-visit-schedule' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch,error)
      });
  }

  static show(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.process());
      ListPaymentMiddelware.showSchedule(dispatch,data)
    };
  }

  static showSchedule(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
        },
        url:url+'/show' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.fetchSchedule(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch,error)
      });
  }


  static beforeSaveNew(data){
    return (dispatch) => {
      dispatch(ListPaymentActions.submit());
      ListPaymentMiddelware.saveNew(dispatch,data)
    };
  }

  static saveNew(dispatch,data){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
        },
        url:url ,
        responseType: 'json',
        data:data
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.fetchSchedule(response.data.result));
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch,error)
      });
  }

  static saveUpdate(dispatch,id,data,search){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
        },
        url:url+'/'+id ,
        responseType: 'json',
        data:data
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.afterUpdate(),ListPaymentMiddelware.showlist(dispatch, search));
          ListPaymentMiddelware.alertHendler(dispatch, response, true, 'Update Schedule Successfully', 'success')
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch,error)
      });
  }

  static saveChange(dispatch,data,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ListPaymentMiddelware.getToken()}`
        },
        url:url+'/change-sales' ,
        responseType: 'json',
        data:data
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ListPaymentActions.afterChange(),ListPaymentMiddelware.showlist(dispatch, search));
          ListPaymentMiddelware.alertHendler(dispatch, response, true, 'Change Sales Schedule Successfully', 'success')
        }
      })
      .catch((error) => {
        ListPaymentMiddelware.alertHendler(dispatch,error)
      });
  }


  static alertHendler(dispatch, result, isSwal = false, extraMessage = '', type = 'error') {
    if (type == 'error') {
      if (result.response) {
        const status = result.response.status
        switch (status) {

          case 500:
            console.log('error 500')
            if (isSwal) {
              const data = result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title: 'Delete Error',
                html: data.error,
                icon: 'error',
                showConfirmButton: true,
              })
            } else {
              const data = result.response.data
              dispatch(ListPaymentActions.error(data.error));
            }
            break;
          case 403:
            console.log('Error 403');
            break;
          case 404:
            console.log('Error 404');
            break;
          case 401:
            console.log('error 401')
            const data = result.response.data
            MySwal.fire({
              title: 'Login Invalid',
              html: data.error,
              icon: 'error',
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>
              }
            })
            break;
        }
      } else if (result.request) {
        console.log('error request')
        MySwal.fire({
          title: 'Internal Server Error',
          html: 'Please info your Developer to fix this',
          icon: 'error',
          showConfirmButton: true,
        })
        dispatch(ListPaymentActions.error('Internal Server Error'));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
        dispatch(ListPaymentActions.error('Internal Server Error'));
      }
    } else {
      MySwal.fire({
        title: 'Success',
        html: extraMessage,
        icon: 'success',
        showConfirmButton: true,
      })
    }
  }
}


import React from "react";
import {paymentType} from "../Const/TranscationCode";

const LabelPaymentType=(props)=>{
    const filter=paymentType.filter((item)=>item.key===props.value);
    if(filter.length==1){
        return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
    }   
    return ''
}
export default LabelPaymentType;
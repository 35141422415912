import React, { Component } from 'react';
import i18n from '../../../../i18n';
import Input from '../../../utilities/Form/input';
import Select2 from "../../../utilities/Form/Select2";
import Radio from '../../../utilities/Form/radio';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import TextArea from '../../../utilities/Form/TextArea';
import GoogleMapReact from 'google-map-react';

export class Form extends Component {

    constructor(props) {
        super(props)
        this.state = {

            form: {
                name: '',
                code: '',
                idProvince: null,
                idCity: null,
                idDistrict: null,
                idSubDistrict: null,
                status: 1,
                address: '',
                latitude: '',
                longitude: '',
            },
            map: 0,
            provinceCurrent: null,
            provinceText: "Select Province",
            provinceList: [],
            latitude: '',
            longitude: '',
            cityCurrent: null,
            cityText: "Select City",
            cityList: [],

            isUpdate: 0,

        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
        this.marker = null
    }

    componentDidMount(){
       
    }
    
    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [key]: value
            }
        })
        this.setState({ [currentState]: e })
        this.props.changeSelect(name, value);
    }

    runSubmit() {
        return this.state.form
    }
    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    renderMarkers(map, maps) {
        // const geocoder = new maps.Geo
        let marker = new maps.Marker({
            position: { lat: this.state.latitude, lng: this.state.longitude },
            map,
            //   dragend: (e) => console.log(e),
            draggable: true
        });
        marker.addListener('dragend', (e) => {
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    latitude: e.latLng.lat(),
                    longitude: e.latLng.lng(),
                }
            })
        }
        )
    }

    render() {
        const { data, isNewRecord, helperBranch, cityList,getGeo } = this.props
        console.log(this.props)
        if (this.state.map == 0) {
            this.state.latitude = getGeo.length > 0 ?getGeo[0].latitude:1;
            this.state.longitude =  getGeo.length > 0 ?getGeo[0].longitude:1;
            this.state.map = 1;
        }
        this.state.provinceList = helperBranch != null ? helperBranch.province : [];
        this.state.cityList = cityList != null ? cityList : [];
        if (data != null && !isNewRecord) {
            if (this.state.isUpdate == 0) {
                this.state.form.name = data.name,
                    this.state.form.code = data.code,
                    this.state.form.address = data.address,
                    this.state.latitude = parseFloat((data.latitude!=null?data.latitude:0)),
                    this.state.longitude = parseFloat((data.longitude!=null?data.longitude:0)),
                    this.state.provinceCurrent = {
                        value: data.province_id,
                        label: data.province_name != "" ? data.province_name : this.state.provinceText,
                    };
                this.state.cityCurrent = {
                    value: data.city_id,
                    label: data.city_name != "" ? data.city_name : this.state.cityText,
                };
                this.state.form.idCity = data.city_id,
                    this.state.form.idProvince = data.province_id,
                    this.state.form.status = data.status
                this.state.isUpdate = 1
                this.props.changeSelect("idProvince", data.province_id);
            }
        }

        return (
            <form>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type={"input"}
                            name={"code"}
                            title={i18n.t("Code Branch")}
                            required
                            value={this.state.form.code}
                            handler={this.handler}
                            error={this.renderError('code')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={this.state.form.name}
                            handler={this.handler}
                            error={this.renderError('name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextArea
                            name={"address"}
                            title={i18n.t("Address")}
                            value={this.state.form.address}
                            handler={this.handler}
                            error={this.renderError("address")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Select2
                            isClearable={true}
                            name={"idProvince"}
                            required={true}
                            onChange={async (e) => {
                                await this.setState({ cityList: [], cityCurrent: null })
                                await this.handlerSelect(e, "idProvince", "provinceCurrent")
                            }}
                            options={this.state.provinceList}
                            title={i18n.t("Province")}
                            value={this.state.provinceCurrent}
                            currentState={"provinceCurrent"}
                            placeholder={this.state.provinceText}
                            error={this.renderError("idProvince")}
                        />
                    </div>
                    <div className="col-md-6">
                        <Select2
                            isClearable={true}
                            name={"idCity"}
                            required={false}
                            onChange={(e) => this.handlerSelect(e, "idCity", "cityCurrent")}
                            options={this.state.cityList}
                            title={i18n.t("City")}
                            value={this.state.cityCurrent}
                            currentState={"cityCurrent"}
                            placeholder={this.state.cityText}
                            error={this.renderError("idCity")}
                        />
                    </div>
                </div>
                <div className="row">
                    <Radio
                        list={StatusForm}
                        name={"status"}
                        title={i18n.t("Status")}
                        inline={false}
                        value={this.state.form.status}
                        handler={this.handler}
                    />
                </div>
                <div className="col-md-12" style={{ height: '300px', }}>
                    <GoogleMapReact

                        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
                        defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                        defaultZoom={this.state.latitude == 0?1:18}
                        style={{ position: 'relative', height: '100%', width: '100%' }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    >
                    </GoogleMapReact>
                </div>
            </form>
        );
    }
}

export default Form;
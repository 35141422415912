import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import ItemTypeActions from "../../../../reducers/modules/master/itemtype/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "master/item-type";

export default class ItemTypeMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(ItemTypeActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(ItemTypeActions.update());
      ItemTypeMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(ItemTypeActions.process());
      ItemTypeMiddleware.showlist(dispatch, params);
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(ItemTypeActions.resetList());
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(ItemTypeActions.reset());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(ItemTypeActions.view());
      ItemTypeMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(ItemTypeActions.submit());
      ItemTypeMiddleware.saveNew(dispatch, post, search);
    };
  }
  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(ItemTypeActions.submit());
      ItemTypeMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }
  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(ItemTypeActions.delete());
      ItemTypeMiddleware.delete(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      ItemTypeMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperType(params = null) {
    return (dispatch) => {
      ItemTypeMiddleware.showHelperType(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ItemTypeActions.list(response.data.result));
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-cash-bank",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ItemTypeActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static showHelperType(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-item-type",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ItemTypeActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(ItemTypeActions.view(response.data.result));
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ItemTypeActions.process(),
            ItemTypeMiddleware.showlist(dispatch, params)
          );
          ItemTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ItemTypeActions.process(),
            ItemTypeMiddleware.showlist(dispatch, search)
          );
          dispatch(ItemTypeActions.success());
          ItemTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Create Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${ItemTypeMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            ItemTypeActions.process(),
            ItemTypeMiddleware.showlist(dispatch, search)
          );
          dispatch(ItemTypeActions.success());
          ItemTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        ItemTypeMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(ItemTypeActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(ItemTypeActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

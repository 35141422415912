import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import AccSetup from "../../../../components/modules/accounting/acc-setup";
import AccSetupMiddleware from "../../../../middleware/modules/accounting/acc-setup";
import Coa3Middleware from "../../../../middleware/modules/accounting/coa3";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, accsetup }) => ({
  isProcessing: accsetup.isProcessing,
  isError: accsetup.isError,
  errorMessage: accsetup.errorMessage,
  datalist: accsetup.data,
  showModal: accsetup.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => AccSetupMiddleware.create(),
      search: (data) => AccSetupMiddleware.list(data),
      update: (data) => AccSetupMiddleware.update(data),
      delete: (id, search) => AccSetupMiddleware.beforeDelete(id, search),
      helperCOA3: () => Coa3Middleware.getHelper(),
    },
    dispatch
  );
};
class AccSetupContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.helperCOA3();
    this.props.update(id);
  }
  createItem() {
    this.props.helperCOA3();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;

    return (
      <React.Fragment>
         <Helmet>
            <title>{i18n.t("Acc Setup List")}</title>
          </Helmet>
          <AccSetup
            search={search}
            data={datalist}
            isProcessing={isProcessing}
            showModal={showModal}
            deleteItem={this.deleteItem}
            updateItem={this.updateItem}
            createData={this.createItem}
          />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccSetupContainer);

export default class InvoiceActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_INVOICE='GET_HELPER_INVOICE';
    static GET_HELPER_INVOICE_FULL ='GET_HELPER_INVOICE_FULL';
    static BEFORE_PRINT_INVOICE ='BEFORE_PRINT_INVOICE';
    static PRINT_INVOICE ='PRINT_INVOICE';
    static RESET_LIST="RESET_LIST";
  
    static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
    static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'
    
  
    static submit(){
      return {
        type: InvoiceActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: InvoiceActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: InvoiceActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: InvoiceActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: InvoiceActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: InvoiceActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: InvoiceActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: InvoiceActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: InvoiceActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: InvoiceActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: InvoiceActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: InvoiceActions.GET_HELPER_INVOICE,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  InvoiceActions.GET_HELPER_INVOICE_FULL,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: InvoiceActions.BEFORE_PRINT_INVOICE,
      };
    }
    static print(data){
      return {
        type: InvoiceActions.PRINT_INVOICE,
        payload: data
      };
    }
    static saveRequestPrint(){
      return {
        type: InvoiceActions.ON_SAVE_REQUEST_PRINT,
      };
    }
    static limitPrint(){
      return {
        type: InvoiceActions.ON_LIMIT_PRINT,
      };
    }
  }
  
  
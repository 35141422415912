import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import AssetTypeActions from "../../../../reducers/modules/accounting/asset-type/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "accounting/asset-type";

export default class AssetTypeMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(AssetTypeActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(AssetTypeActions.update());
      AssetTypeMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(AssetTypeActions.process());
      AssetTypeMiddleware.showlist(dispatch, params);
    };
  }

  static reset() {
    return (dispatch) => {
      dispatch(AssetTypeActions.reset());
    };
  }

  static resetList() {
    return (dispatch) => {
      dispatch(AssetTypeActions.resetList());
    };
  }

  static getOne(params) {
    return (dispatch) => {
      dispatch(AssetTypeActions.view());
      AssetTypeMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(AssetTypeActions.submit());
      AssetTypeMiddleware.saveNew(dispatch, post, search);
    };
  }

  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(AssetTypeActions.submit());
      AssetTypeMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(AssetTypeActions.delete());
      AssetTypeMiddleware.delete(dispatch, params, id);
    };
  }

  static beforeVoid(id, params) {
    return (dispatch) => {
      dispatch(AssetTypeActions.void());
      AssetTypeMiddleware.voidData(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      AssetTypeMiddleware.showHelper(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(AssetTypeActions.view(response.data.result));
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(AssetTypeActions.list(response.data.result));
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-asset-type",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(AssetTypeActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            AssetTypeActions.process(),
            AssetTypeMiddleware.showlist(dispatch, params)
          );
          AssetTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static voidData(dispatch, params, id, ) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url + "/void/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            AssetTypeActions.process(),
            AssetTypeMiddleware.showlist(dispatch, params)
          );
          AssetTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            AssetTypeActions.process(),
            AssetTypeMiddleware.showlist(dispatch, search)
          );
          dispatch(AssetTypeActions.success());
          AssetTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Save Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${AssetTypeMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            AssetTypeActions.process(),
            AssetTypeMiddleware.showlist(dispatch, search)
          );
          dispatch(AssetTypeActions.success());
          AssetTypeMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        AssetTypeMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              console.log(data)
              MySwal.hideLoading();
              MySwal.update({
                title: "Error",
                html: i18n.t(data.message),
                icon: "error",
                showConfirmButton: true,
              })
              const search = {limit: 10}
                dispatch(
                  AssetTypeActions.process(),
                  AssetTypeMiddleware.showlist(dispatch, search)
                );
          
            } else {
              const data = result.response.data;
              dispatch(AssetTypeActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(AssetTypeActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

import React, { Component } from "react";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: [],
      },
      data: [],
      coaCurrent: null,
      coaText: i18n.t("Select Config"),
      coaList: [],
      isUpdate: 0,
      name: null,
      multiple: null,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    var hasil = [];
    if(this.state.multiple == 0){
      this.state.form.value.push(this.state.coaCurrent.value)
    }else{
    for (var i = 0; i < this.state.coaCurrent.length; i++) {
      this.state.form.value.push(this.state.coaCurrent[i].value);
    }
  }
    return this.state.form;
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  render() {
    const { dataUpdate, isNewRecord, helperFull, configHelper } = this.props;
    if (dataUpdate != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.name = dataUpdate.name),
          (this.state.multiple = dataUpdate.multiple);
        let remaining = dataUpdate.value;
        let array = remaining.toString().split(",");
        var a = [];
        this.state.isUpdate = 1;
        for (var po = 0; po < array.length; po++) {
          if (dataUpdate.type == "COA1") {
            this.state.coaList = configHelper.coa1;
          } else if (dataUpdate.type == "COA2") {
            this.state.coaList = configHelper.coa2;
          } else if (dataUpdate.type == "COA3") {
            this.state.coaList = configHelper.coa3;
          }
          var labs = [];
          var number = [];
          for (var p = 0; p < this.state.coaList.length; p++) {
            if (this.state.coaList[p].value == array[po]) {
              labs = this.state.coaList[p].label;
              number = this.state.coaList[p].value;
            }
          }
          a.push({ value: number, label: labs });
        }
        this.state.coaCurrent = a;
      }
    }

    return (
      <form>
        <div className="mb-3">
          <Input
            type={"input"}
            name={"name"}
            title={i18n.t("Name")}
            disabled={"true"}
            value={this.state.name}
            handler={this.handler}
          />
        </div>

        {this.state.multiple == 0 ? (
          <div className="mb-3">
            <Select2
              name={"coa"}
              required={true}
              onChange={(e) => this.handlerSelect(e, "coa", "coaCurrent")}
              options={this.state.coaList}
              title={i18n.t("Value")}
              value={this.state.coaCurrent}
              placeholder={this.state.coaText}
            />
          </div>
        ) : (
          <div className="mb-3">
            <Select2
              name={"coa"}
              required={true}
              onChange={(e) => this.handlerSelect(e, "coa", "coaCurrent")}
              options={this.state.coaList}
              title={i18n.t("Value")}
              isMulti
              value={this.state.coaCurrent}
              placeholder={this.state.coaText}
            />
          </div>
        )}
      </form>
    );
  }
}

export default Form;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
import { Navigate } from 'react-router-dom';
import TempCustomerMiddleware from '../../../../middleware/modules/master/customer-temp';
import Form from '../../../../components/modules/master/customer-temp/form';
import { Button, Modal } from 'react-bootstrap';
import CityMiddleware from '../../../../middleware/modules/master/city';
import StoresMiddleware from '../../../../middleware/modules/master/store';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,tempcustomer,city,stores}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: tempcustomer.isProcessing,
  isError: tempcustomer.isError,
  errorMessage: tempcustomer.errorMessage,
  dataUpdate: tempcustomer.single,
  isSuccess:tempcustomer.isSuccess,
  isReset:tempcustomer.isReset,
  isNewRecord:tempcustomer.isNewRecord,
  onSubmit:tempcustomer.onSubmit,
  cityList:city.helperCity,
  storeList:stores.helperStore,
  customerHelperFull:tempcustomer.customerHelperFull
  
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getStore: (id) =>StoresMiddleware.getHelper(id),
    getCity: (id) =>CityMiddleware.getHelper(id),
    closeModal:()=>TempCustomerMiddleware.reset(),
    new: (data,search) =>TempCustomerMiddleware.beforeSaveNew(data,search),
    update: (id,type,search) =>TempCustomerMiddleware.beforeSaveUpdate(id,type,search)
  }, dispatch);
};
class FormContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate:PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit:PropTypes.bool
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess:false,
    isError: false,
    errorMessage: [],
    dataUpdate:null,
    onSubmit:false
  }
  constructor(props) {
    super(props);
    this.state={
      modal:{
        title:'',
        show:false,

      },
      map:0,
      geo:[],
    }
    this.form=React.createRef();
    this.getData=this.getData.bind(this)
    this.submitForm=this.submitForm.bind(this)
    this.changeSelect=this.changeSelect.bind(this)
    this.close=this.close.bind(this)
}
  
  getData(){
    return this.props.showOne(this.props.dataID)
  }

  close(){
      this.props.closeModal()
  }
  submitForm(){
    const {search,isNewRecord,dataUpdate}=this.props
    var form=this.form.current.runSubmit()
    if(isNewRecord){
      this.props.new(form,search)
    } else{
      this.props.update(dataUpdate.id,form,search)
    }
    
  }

  changeSelect(type,value=null){
    const params={id:value}
    switch(type){
      case 'idProvince':
        this.props.getCity(params);
        break;
      case 'idBranch':
        this.props.getStore(params);
        break;
        default:
          break;
    }
  }

  render() {
     if(this.state.map == 0){
      navigator.geolocation.getCurrentPosition((position)=> {
          console.log('masuk')
          this.state.geo.push({longitude:position.coords.longitude,latitude:position.coords.latitude})
      },
      error => console.log(error),
      {
        enableHighAccuracy: false,
        timeout: 20000,
        maximumAge: 10,
        distanceFilter: 0,
      },
      
      );
      this.state.map = 1;
  
    } 
    const {customerHelperFull,onSubmit,showModal,titleModal,isReset,dataUpdate,isProcessing,isError,errorMessage,cityList,storeList,isNewRecord} = this.props;
    return (
      <>
        <Modal
          show={showModal}
        fullscreen
        >
            <Modal.Header >
            <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
              <Helmet>
                <title>{titleModal}</title>
              </Helmet>
            <Modal.Body>
                <Form 
                  isNewRecord={isNewRecord}
                  isReset={isReset}
                  data={dataUpdate}
                  isProcessing={isProcessing}
                  isError={isError}
                  errorMessage={errorMessage}
                  ref={this.form}
                  cityList={cityList}
                  storeList={storeList}
                  customerHelperFull={customerHelperFull}
                  changeSelect={this.changeSelect}
                  getGeo={this.state.geo}
                />
            </Modal.Body>
            <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

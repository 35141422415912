import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import PurchasingReportMiddleware from "../../../../middleware/modules/report/purchasing-report";
import { Helmet } from "react-helmet";
import FormPurchasingFullReport from "../../../../components/modules/report/purchasing-report/purchasing-full";

const mapStateToProps = ({ auth, purchasingReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: purchasingReport.isProcessing,
  isError: purchasingReport.isError,
  errorMessage: purchasingReport.errorMessage,
  datalistPO: purchasingReport.dataPO,
  datalistReceiving: purchasingReport.dataReceiving,
  datalistInvoiceDetail: purchasingReport.dataInvoiceDetail,
  isSuccess: purchasingReport.isSuccess,
  isReset: purchasingReport.isReset,
  isNewRecord: purchasingReport.isNewRecord,
  onSubmit: purchasingReport.onSubmit,
  helperPurchasingReport: purchasingReport.helperPurchasingReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDataPO: (data) => PurchasingReportMiddleware.listDataPO(data),
      setDataReceiving: (data) => PurchasingReportMiddleware.listDataReceiving(data),
      setInvoiceDetail: (data) => PurchasingReportMiddleware.listInvoiceDetail(data),

    },
    dispatch
  );
};
class PurchasingFullReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataPO: PropTypes.any,
    dataReceiving: PropTypes.any,
    dataInvoiceDetail: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.setDataPO = this.setDataPO.bind(this);
    this.setInvoiceDetail = this.setInvoiceDetail.bind(this);
    this.setDataReceiving = this.setDataReceiving.bind(this);

  }

  setDataPO() {
    this.props.setDataPO();
  }

  setDataReceiving() {
    this.props.setDataReceiving();
  }

  setInvoiceDetail() {
    this.props.setInvoiceDetail();
  }


  render() {
    return (
      <Fragment>
        <FormPurchasingFullReport
          {...this.props}
        />
      </Fragment>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingFullReport);

import MutationDepositActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataCustomer:{},
  isProcessing: false,
  isProcessingCustomer: false,
  isError: false,
  errorMessage: [],
  isErrorCustomer: false,
  errorMessageCustomer: [],
  isNewRecord:false,
  onChoose:false,
  onChooseCustomer:false,
  helperMutationDeposit:[],
  printStockCard:null,
  requestPrint:false,
};

function MutationDepositReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MutationDepositActions.BEFORE_CHOOSE:
      return {...state,data:[],isProcessing:false,isError: false,errorMessage: [],onChoose:false};
    case MutationDepositActions.PROCESS_CHOOSE:
      return {...state,data:[],isProcessing:true,isError: false,errorMessage: []};
    case MutationDepositActions.AFTER_CHOOSE:
      return {...state,data:action.payload,isProcessing:false,isError: false,errorMessage: [],onChoose:true};
    case MutationDepositActions.ERROR_CHOOSE:
      return {...state,data:[],isProcessing:false,isError: true,errorMessage: action.payload,onChoose:true};
    case MutationDepositActions.AFTER_CHOOSE_CUSTOMER:
      return {...state,dataCustomer:action.payload,isProcessingCustomer:false,isErrorCustomer: false,errorMessageCustomer: [],onChooseCustomer:true};
    case MutationDepositActions.ERROR_CHOOSE_CUSTOMER:
      return {...state,dataCustomer:[],isProcessingCustomer:false,isErrorCustomer: true,errorMessageCustomer: action.payload,onChooseCustomer:true};
    case MutationDepositActions.BEFORE_CHOOSE_CUSTOMER:
      return {...state,dataCustomer:[],isProcessingCustomer:false,isErrorCustomer: false,errorMessageCustomer: [],onChooseCustomer:false};
    case MutationDepositActions.PROCESS_CHOOSE_CUSTOMER:
      return {...state,dataCustomer:[],isProcessingCustomer:true,isErrorCustomer: false,errorMessageCustomer: []};
    case MutationDepositActions.GET_HELPER_MUTATION:
      return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperMutationDeposit:action.payload};
    case MutationDepositActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false }; 
      case MutationDepositActions.ERROR_DATA_CUSTOMER:
        return { ...state,  isErrorCustomer: true,onSubmit:false,  errorMessageCustomer: action.payload,isProcessingCustomer:false }; 
    case MutationDepositActions.BEFORE_PRINT_STOCK_CARD:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printStockCard:null,isProcessing:true};
    case MutationDepositActions.PRINT_STOCK_CARD:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printStockCard:action.payload,isProcessing:false};
    case MutationDepositActions.ON_LIMIT_PRINT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    case MutationDepositActions.ON_SAVE_REQUEST_PRINT:
      return { ...state,errorInternal:false,requestPrint:true,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    default:
      return state;
    }
}


export default MutationDepositReducer;

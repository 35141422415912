import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import LogPrintMiddleware from "../../../../middleware/modules/log/print";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import LogPrint from "../../../../components/modules/log/print";
// middlewares

const mapStateToProps = ({ auth, logPrint }) => ({
  isProcessing: logPrint.isProcessing,
  isError: logPrint.isError,
  errorMessage: logPrint.errorMessage,
  data: logPrint.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      search: (data) => LogPrintMiddleware.list(data),
      reset: () => LogPrintMiddleware.resetList(),
      ApproveData: (data,value,search) => LogPrintMiddleware.beforeApprove(data,value,search),
      RejectData: (data,value, search) => LogPrintMiddleware.beforeReject(data, value,search),
    },
    dispatch
  );
};
class LogPrintContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  render() {
    return (
      <React.Fragment>
         <Helmet>
            <title>{i18n.t("Log Request Print")}</title>
          </Helmet>
          <LogPrint
           {...this.props}
          
          />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogPrintContainer);

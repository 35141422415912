import ConfigurationActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  configHelper:null,
  helperFullConfiguration:null,
  errorInternal:false
};

function ConfigurationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ConfigurationActions.RESET_LIST:
      return INITIAL_STATE;
    case ConfigurationActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ConfigurationActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case ConfigurationActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case ConfigurationActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ConfigurationActions.DELETE_DATA:
      return { ...state, errorInternal:false, onSubmit:false,isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case ConfigurationActions.ON_SUBMIT:
      return { ...state,errorInternal:false, onSubmit:true};
    case ConfigurationActions.SHOW_DATA:
      return { ...state, errorInternal:false, onSubmit:false, isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case ConfigurationActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case ConfigurationActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case ConfigurationActions.PROCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: true, isError: false,isSuccess:false};
    case ConfigurationActions.GET_HELPER_CONFIG:
      return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,configHelper:action.payload};
    // case ConfigurationActions.GET_HELPERFULL_Configuration:
    //   return { ...state, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperFullConfiguration:action.payload};
    case ConfigurationActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
       
    default:
      return state;
    }
}

export default ConfigurationReducer;

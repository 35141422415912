import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import Coa1 from "../../../../components/modules/accounting/coa1";
import Coa1Middleware from "../../../../middleware/modules/accounting/coa1";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares

const mapStateToProps = ({ auth, coa1 }) => ({
  isProcessing: coa1.isProcessing,
  isError: coa1.isError,
  errorMessage: coa1.errorMessage,
  datalist: coa1.data,
  showModal: coa1.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => Coa1Middleware.create(),
      reset: () => Coa1Middleware.resetList(),
      search: (data) => Coa1Middleware.list(data),
      update: (data) => Coa1Middleware.update(data),
      delete: (id, search) => Coa1Middleware.beforeDelete(id, search),
    },
    dispatch
  );
};
class Coa1Container extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.update(id);
  }
  createItem() {
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Coa 1 List")}</title>
        </Helmet>
        <Coa1
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coa1Container);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Customer } from '../../../../components/modules/master/customer';
// middlewares
import CustomerMiddleware from '../../../../middleware/modules/master/customer';
import CustomerTypeMiddleware from '../../../../middleware/modules/master/customer-type';
import StoresMiddleware from '../../../../middleware/modules/master/store';
import BranchMiddleware from '../../../../middleware/modules/master/branch';
import ProvinceMiddleware from '../../../../middleware/modules/master/province';
import CityMiddleware from '../../../../middleware/modules/master/city';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';

const mapStateToProps = ({ auth,customer }) => ({
  isProcessing: customer.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist:customer.data,
  showModal:customer.showModal,
  customerHelperFull:customer.customerHelperFull
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>CustomerMiddleware.resetList(),
    create: (search) =>CustomerMiddleware.create(search),
    search: data =>CustomerMiddleware.list(data),
    update: data =>CustomerMiddleware.update(data),
    
    view: data =>CustomerMiddleware.getOne(data),
    delete: (id,search) =>CustomerMiddleware.beforeDelete(id,search),
    //helpers
    getHelper: () =>CustomerMiddleware.getHelperFull(),
   
  }, dispatch);
};
class CustomerContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.deleteItem=this.deleteItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
    this.viewItem=this.viewItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  //  this.props.getHelper();
  }

  deleteItem(id,search){
    this.props.delete(id,search)
  }
  updateItem(id){
    this.props.update(id)
    this.props.getHelper()
  }
  createItem(search=null){
    this.props.create(search)
  }
  viewItem(id){
    this.props.view(id)
  }
  render() {
    const {isProcessing, search , datalist,showModal,customerHelperFull} = this.props;
    return (
      <React.Fragment>
         <Helmet>
            <title>{i18n.t("Customer List")}</title>
          </Helmet>
          <Customer
            {...this.props}
            search={search}
            data={datalist}
            isProcessing={isProcessing}
            showModal={showModal}
            deleteItem={this.deleteItem}
            updateItem={this.updateItem}
            createData={this.createItem}
            customerHelperFull={customerHelperFull}
            viewItem={this.viewItem}
          />
      </React.Fragment>
      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);

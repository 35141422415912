export default class AttendanceActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_ATTANDANCE='GET_HELPER_ATTANDANCE';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static submit(){
    return {
      type: AttendanceActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: AttendanceActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: AttendanceActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: AttendanceActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: AttendanceActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: AttendanceActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: AttendanceActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: AttendanceActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: AttendanceActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: AttendanceActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: AttendanceActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: AttendanceActions.GET_HELPER_ATTANDANCE,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: AttendanceActions.ERROR_SERVER,
      payload: error
    };
  }
}
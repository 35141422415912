import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import SalesInvoiceReportMiddleware from "../../../../middleware/modules/report/sales-report";
import { Helmet } from "react-helmet";
import FormSalesInvoiceFullReport from "../../../../components/modules/report/sales-report/sales-full";

const mapStateToProps = ({ auth, salesInvoiceReport }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: salesInvoiceReport.isProcessing,
  isError: salesInvoiceReport.isError,
  errorMessage: salesInvoiceReport.errorMessage,
  dataSO:salesInvoiceReport.dataSO,
  dataSelling:salesInvoiceReport.dataSelling,
  datalistInvoiceDetail:salesInvoiceReport.dataPayment,
  isSuccess: salesInvoiceReport.isSuccess,
  isReset: salesInvoiceReport.isReset,
  isNewRecord: salesInvoiceReport.isNewRecord,
  onSubmit: salesInvoiceReport.onSubmit,
  helperSalesInvoiceReport: salesInvoiceReport.helpersalesInvoiceReport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setDataSO: (data) =>SalesInvoiceReportMiddleware.listSO(data),
      setDataSelling: (data) =>SalesInvoiceReportMiddleware.listSelling(data),
      setInvoiceDetail: (data) =>SalesInvoiceReportMiddleware.datalistInvoiceDetail(data),
    },
    dispatch
  );
};
class SalesInvoiceFullReport extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataSO: PropTypes.any,
    dataSelling: PropTypes.any,
    datalistInvoiceDetail: PropTypes.any,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      show : 0,
    };
    this.setDataSO = this.setDataSO.bind(this);
    this.setDataSelling = this.setDataSelling.bind(this);
    this.setInvoiceDetail = this.setInvoiceDetail.bind(this);

  }

  setDataSO() {
    this.props.setDataSO();
  }

  setDataSelling() {
    this.props.setDataSelling();
  }

  setInvoiceDetail(){
    this.props.setInvoiceDetail();
  }


  render() {
    const {dataSO,dataSelling,datalistInvoiceDetail} = this.props
    return (
      <Fragment>
            <FormSalesInvoiceFullReport
             {...this.props}
             dataSO={dataSO}
             dataSelling={dataSelling}
             dataInvoiceDetail={datalistInvoiceDetail}
            />
         </Fragment>
     
    );
  }
// }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceFullReport);

import React, { Fragment } from "react";
import Page403 from "../403";

const AccessControl = ({name,auth, component:Component}) =>{

    if(auth !==null){
        if(Object.keys(auth).length >0 && auth.userAccess!= undefined){
            let route=auth.userAccess.route
            if(route.includes(name)){
                return (<Component />)
            }
            return (<Page403></Page403>); 
        }
    }
    
    return (<Fragment></Fragment>)
};
  export default AccessControl;
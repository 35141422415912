import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";

import ListPaymentShowContainer from "../../../../containers/modules/chart/customer-performance/index-show";
import ListSupplier from "../../../../containers/modules/accounting/list-payment/listSupplier";
import ListCustomer from "../../../../containers/modules/accounting/list-payment/listCustomer";

const MySwal = withReactContent(Swal);
export class ListPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      show: null,
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Accounting"), class: " text-gray-600" },
        { link: null, title: i18n.t("List Payment"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);

  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  renderTab() {
    // const { datalistItem, helperCustomerInvoiceReport, datalist, data, dataCus, datalistDetail, datalistInvoiceDetail } = this.props;
    return (
      <Tabs
        defaultActiveKey="ListPaymentSupplier"
        id={"tabs"}
        className="mb-3"
        // unmountOnExit
      >
         <Tab eventKey="ListPaymentSupplier" title={i18n.t("List Payment Supplier")}>
          <ListSupplier

          />
        </Tab>

        <Tab eventKey="ListPaymentCustomer" title={i18n.t("List Payment Customer")}>
          <ListCustomer

          />
        </Tab>

        {/* <Tab eventKey="ListPaymentChart" title={i18n.t("Customer Performance Chart")}>
          <ListPaymentChartContainer
          />
        </Tab>
        <Tab eventKey="ListPaymentTable" title={i18n.t("Customer Performance View")}>
          <ListPaymentTableContainer
          />
        </Tab>
        <Tab eventKey="ListPaymentDailyMonitor" title={i18n.t("Customer Performance Daily Monitor")}>
          <ListPaymentMonitorContainer
          />
        </Tab> */}
     
      </Tabs>
    );

  }
  render() {
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">{this.renderTab()}</div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default ListPayment;

import React, { Component } from "react";
import { Table } from "react-bootstrap";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
const token = cookieManager.getUserToken();
const mystyle = {
  height: '100%',
  width: 1,
  backgroundColor: '#909090',
};
export class FormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Return: [],
        Header: {},
      },
      date: new Date(),
      unit: [],
      convert: 0,
      idSales: null,
      idTakingGood: null,
      update: 0,
      show: false,
      status: 1,
      set: 0,
      arrayDetail: [
        {

        },
      ],
      isUpdate: 0,
    };
    this.formConvertion = React.createRef();
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);

  }


  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSelectArray(e, id, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[id.name] = null;
    } else {
      objectss[id.name] = e;
    }
    this.setState(arr);
  }

  runSubmit() {
    const detail = this.state.arrayDetail;
    const Header = {
      date: moment(this.state.datePayment).format('YYYY-MM-DD'),
      idTakingGood: this.state.idTakingGood,
      idSales: this.state.idSales
    }

    let saveData = [];
    let total = 0;
    for (var i = 0; i < detail.length; i++) {
      saveData.push(detail[i]);

      if (detail[i].convertReturn == (detail[i].convert - detail[i].total_sell)) {
        total = 0;
      } else {
        total += 1;
      }
    }

    if (total == 0) {
      this.state.form.Return = saveData;
      this.state.form.Header = Header;
      this.state.form.status = true

    } else {
      this.state.form.status = false
    }
    return this.state.form;
  }


  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    })
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  setList() {
    const { data } = this.props;
    let unit = this.state.unit;
    let html = [];
    let det = [];
    let itemdet = [];
    let no = 1;
    let itemUnit = [];
    for (var a = 0; a < unit.length; a++) {
      itemUnit.push({ id: unit[a].value })
    }

    if (data != null && data != '') {
      this.state.arrayDetail = data
      if (this.state.arrayDetail.length > 0) {
        for (var i = 0; i < this.state.arrayDetail.length; i++) {
          const row = this.state.arrayDetail[i];

          if (this.state.set == 0) {
            this.state.idSales = row.id_sales;
            this.state.idTakingGood = row.id;
            this.state.set = 1;
          }

          det = row.detail;
          itemdet = row.convertion_return;
          let arr = [];
          let arrQty = [];
          let arrDet = [];
          let arrDetQty = [];

          for (var a = 0; a < det.length; a++) {
            arr.push(parseInt(det[a].id))
            arrQty.push(parseInt(det[a].qty))
          }

          for (var a = 0; a < itemdet.length; a++) {
            arrDet.push(parseInt(itemdet[a].id))
            arrDetQty.push(parseInt(itemdet[a].qty))
          }
          console.log(arrDet,arrDetQty)
          html.push(
            <tr key={"detail" + i} className="border text-center">
              <td style={{ textAlign: 'center' }}>{no + '.'}</td>
              <td>{row.name_item}</td>

              {unit.map((nameUnit, p) => {
                let bi = nameUnit.value
                let time = arr.indexOf(bi)
               
                return <td> {arr.indexOf(bi) >= 0 ? arrQty[time] : 0} </td>
              })}
              <td>{row.convert}</td>
              <td>{row.total_sell}</td>

              {unit.map((nameUnit, p) => {
                let bi1 = nameUnit.value
                let time1 = arrDet.indexOf(bi1)
               
                return <td> {arrDet.indexOf(bi1) >= 0 ? arrDetQty[time1] : 0} </td>
              })}
              <td>{row.convertion_total}</td>
            </tr>
          );
          no++;
        }
        this.state.update = 1;
      }
    }
    return html;
  }

  render() {
    const { data, isNewRecord, isProcessing, helperTakingGoods } = this.props;
    console.log('PROPS', this.props);
    console.log('STATE', this.state);
    let unit = [];
    let col = [];
    if (data != null) {
      col = data[0];
    }
    if (helperTakingGoods != null) {
      this.state.branchList =
        helperTakingGoods != null ? helperTakingGoods.branch : [];
      this.state.unit = helperTakingGoods.unit;
      unit = helperTakingGoods.unit;
      if (this.state.show == false) {
        this.state.show = true;
      }
    }


    return (
      <React.Fragment>
        {(!isProcessing) ?
          <form>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
              <div className="flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body " >
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Date')} : {col.date}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Branch')} : {col.name_branch}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Store')} : {col.name_store}
                      </div>
                    </div>
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Taking Goods No.')} : {col.no_tr}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Sales Name')} : {col.name_sales}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Warehouse ')} : {col.name_wh} - {col.name_sub_wh}
                      </div>
                    </div><div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Return Date')} : {col.date_return}
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">
                      </div>
                    </div>
                    <br />
                    <div className="row" >
                      <div className="col-12">
                        <Table striped bordered hover className="border" style={{ fontWeight: 'bold' }}>
                          <thead>
                            <tr className="border" style={{ backgroundColor: 'burlywood' }}>
                              <th width={"3%"} className="text-center pb-10" rowSpan={2}>{i18n.t("No.")}</th>
                              <th width={"15%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Item")}</th>
                              <th width={"30%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Taking")}</th>
                              <th width={"8%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Selling (Pack)")}</th>

                              <th width={"30%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Return")}</th>
                              {/* <th width={"25%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Warehouse")}</th> */}
                            </tr>
                            <tr className="border">

                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"header" + i} width={"5%"} className="text-center " style={{ backgroundColor: 'burlywood' }} >{header.label}</th>
                                })
                                : null}
                              <th key={"header" + 10 + 1} width={"8%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{i18n.t("Convert (Pack)")}</th>


                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"header3" + i} width={"5%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{header.label}</th>
                                })
                                : null}
                              <th key={"header" + 10 + 2} width={"8%"} className="text-center" style={{ backgroundColor: 'burlywood' }}>{i18n.t("Convert (Pack)")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.show == true ? this.setList() : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          :
          <div className="text-center">
            <div className="spinner-border  text-primary text-center  clearfix"></div>
            <div className="text-center">{i18n.t("Loading")}</div>
          </div>}
      </React.Fragment>
    );
  }
}

export default FormView;

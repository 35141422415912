import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import AssetMiddleware from "../../../../middleware/modules/accounting/asset";
import FormAsset from "../../../../components/modules/accounting/asset/form";

const mapStateToProps = ({ auth, asset }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: asset.isProcessing,
  isError: asset.isError,
  errorMessage: asset.errorMessage,
  dataUpdate: asset.single,
  isSuccess: asset.isSuccess,
  isReset: asset.isReset,
  isNewRecord: asset.isNewRecord,
  onSubmit: asset.onSubmit,
  helperAsset:asset.helperAsset
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => AssetMiddleware.reset(),
      submitData: (data,search) =>AssetMiddleware.beforeSaveNew(data,search),
      cancel: () =>AssetMiddleware.reset(),
      update: (id, type, search) =>
        AssetMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormAssetContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      add:0,
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.close = this.close.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  close() {
    this.props.closeModal();
  }

  submitForm() {
    const {isNewRecord,dataUpdate } = this.props;
    const search = {
      limit: 10,
    };
    var form = this.form.current.runSubmit();
    if (isNewRecord) {
      this.props.submitData(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
    // var form = this.form.current.runSubmit();
    //   this.props.submitData(form,search);
  }

  render() {
    const {showModal,titleModal,onSubmit,isProcessing} = this.props
    return (
      <>
        <Modal show={showModal} size="xl" enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
          </Modal.Header>
          <Helmet>
                <title>{titleModal}</title>
              </Helmet>
          <Modal.Body>
            <FormAsset
              {...this.props}
              ref={this.form}
              isProcessing={isProcessing}
              // helperAsset={helperAsset}
            />
          </Modal.Body>
          <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormAssetContainer);

export default class ListPaymentActions {

  static NEW_DATA = 'NEW_DATA';
  static CHANGE_DATA = 'CHANGE_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static SHOW_DATA_SUPPLIER='SHOW_DATA_SUPPLIER';
  static SHOW_DATA_CUSTOMER='SHOW_DATA_CUSTOMER';
  static VIEW_DATA='VIEW_DATA';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_VISITSCHEDULE='GET_HELPER_VISITSCHEDULE';
  static SUCCESS_DATA='SUCCESS_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static RESET_LIST='RESET_LIST';
  static FETCH_SCHEDULE='FETCH_SCHEDULE';
  static FETCH_CHANGE='FETCH_CHANGE';
  static FORM_HELPER='FORM_HELPER';
  static FORM_HELPER_CHANGE='FORM_HELPER_CHANGE';
  static CLOSE_MODAL='CLOSE_MODAL';
  static VOID_DATA_SUPPLIER='VOID_DATA_SUPPLIER';
  static VOID_DATA_CUSTOMER='VOID_DATA_CUSTOMER';

  static BEFORE_SAVE_UPDATE='BEFORE_SAVE_UPDATE';
  static AFTER_SAVE_UPDATE='AFTER_SAVE_UPDATE';

  static BEFORE_SAVE_CHANGE='BEFORE_SAVE_CHANGE';
  static AFTER_SAVE_CHANGE='AFTER_SAVE_CHANGE';
  static beforeUpdate(){
    return {
      type: ListPaymentActions.BEFORE_SAVE_UPDATE,
    };
  }

  static beforeChange(){
    return {
      type: ListPaymentActions.BEFORE_SAVE_CHANGE,
    };
  }

  static afterUpdate(){
    return {
      type: ListPaymentActions.AFTER_SAVE_UPDATE,
    };
  }

  static afterChange(){
    return {
      type: ListPaymentActions.AFTER_SAVE_CHANGE,
    };
  }

  static closeModal(){
    return {
      type: ListPaymentActions.CLOSE_MODAL,
    };
  }

  static fetchSchedule(data){
    return {
      type: ListPaymentActions.FETCH_SCHEDULE,
      payload:data
    };
  }
  static fetchChange(data){
    return {
      type: ListPaymentActions.FETCH_CHANGE,
      payload:data
    };
  }
  static formHelper(data){
    return {
      type: ListPaymentActions.FORM_HELPER,
      payload:data
    };
  }
  static formHelperChange(data){
    return {
      type: ListPaymentActions.FORM_HELPER_CHANGE,
      payload:data
    };
  }

  static submit(){
    return {
      type: ListPaymentActions.ON_SUBMIT
    };
  }

  static create() {
    return {
      type: ListPaymentActions.NEW_DATA
    };
  }

  static change() {
    return {
      type: ListPaymentActions.CHANGE_DATA
    };
  }

  static update() {
    return {
      type: ListPaymentActions.UPDATE_DATA,
    };
  }

  static view(data) {
    return {
      type: ListPaymentActions.VIEW_DATA,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: ListPaymentActions.DELETE_DATA
    };
  }

  static listSupplier(listPaymentSupplier) {
    return {
      type: ListPaymentActions.SHOW_DATA_SUPPLIER,
      payload: listPaymentSupplier
    };
  }

  static listCustomer(listPaymentCustomer) {
    return {
      type: ListPaymentActions.SHOW_DATA_CUSTOMER,
      payload: listPaymentCustomer
    };
  }

  static voidSupplier(data) {
    return {
      type: ListPaymentActions.VOID_DATA_SUPPLIER,
      payload: data
    };
  }

  static voidCustomer(data) {
    return {
      type: ListPaymentActions.VOID_DATA_CUSTOMER,
      payload: data
    };
  }

  static list(data) {
    return {
      type: ListPaymentActions.SHOW_DATA,
      payload: data
    };
  }
  static resetList() {
    return {
      type: ListPaymentActions.RESET_LIST
    };
  }
  static error(error){
    return {
      type: ListPaymentActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: ListPaymentActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: ListPaymentActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: ListPaymentActions.RESET_DATA,
    };
  }
  static helper(data){
    return {
      type: ListPaymentActions.GET_HELPER_VISITSCHEDULE,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: ListPaymentActions.ERROR_SERVER,
      payload: error
    };
  }
}
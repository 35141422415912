import SupplierActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperSupplier:null,
  supplierHelperFull:null
};

function SupplierReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SupplierActions.RESET_LIST:
      return INITIAL_STATE;
    case SupplierActions.NEW_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case SupplierActions.UPDATE_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: false, single:null,isNewRecord:false};
    case SupplierActions.VIEW_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case SupplierActions.RESET_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SupplierActions.DELETE_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case SupplierActions.ON_SUBMIT:
      return { ...state, onSubmit:false, errorInternal:false, onSubmit:true};
    case SupplierActions.SHOW_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case SupplierActions.SUCCESS_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case SupplierActions.PROCESS_DATA:
      return { ...state, onSubmit:false, errorInternal:false, isProcessing: true, isError: false,isSuccess:false};
    case SupplierActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case SupplierActions.GET_HELPER_SUPPLIER:
      return { ...state,onSubmit:false, errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperSupplier:action.payload};
    case SupplierActions.GET_HELPER_SUPPLIER_FULL:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,supplierHelperFull:action.payload};
    
    case SupplierActions.ERROR_SERVER:
      return { ...state, onSubmit:false,  isError: false, errorInternal:true,  errorMessage: action.payload, isProcessing:false };
      
    default:
      return state;
    }
}


export default SupplierReducer;

export default class QuotationRequestActions {
  static NEW_DATA = "NEW_DATA";
  static UPDATE_DATA = "UPDATE_DATA";
  static SHOW_DATA = "SHOW_DATA";
  static VIEW_DATA = "VIEW_DATA";
  static DELETE_DATA = "DELETE_DATA";
  static ON_SUBMIT = "ON_SUBMIT";
  static ERROR_DATA = "ERROR_DATA";
  static PROCESS_DATA = "PROCESS_DATA";
  static RESET_DATA = "RESET_DATA";
  static BEFORE_PRINT_PR = "BEFORE_PRINT_PR";
  static PRINT_PR = "PRINT_PR";
  static GET_HELPER_QUOTATIONREQUEST = "GET_HELPER_QUOTATIONREQUEST";
  static GET_HELPER_QUOTATIONREQUEST_FULL = "GET_HELPER_QUOTATIONREQUEST_FULL";
  static SUCCESS_DATA = "SUCCESS_DATA";
  static ERROR_SERVER = "ERROR_SERVER";
  static RESET_LIST = "RESET_LIST";
  static ON_LIMIT_PRINT='ON_LIMIT_PRINT'
  static ON_SAVE_REQUEST_PRINT='ON_SAVE_REQUEST_PRINT'
  static submit() {
    return {
      type: QuotationRequestActions.ON_SUBMIT,
    };
  }

  static create(data) {
    return {
      type: QuotationRequestActions.NEW_DATA,
      payload: data,
    };
  }

  static update(data) {
    return {
      type: QuotationRequestActions.UPDATE_DATA,
      payload: data,
    };
  }

  static view(data) {
    return {
      type: QuotationRequestActions.VIEW_DATA,
      payload: data,
    };
  }

  static delete(data) {
    return {
      type: QuotationRequestActions.DELETE_DATA,
    };
  }

  static list(data) {
    return {
      type: QuotationRequestActions.SHOW_DATA,
      payload: data,
    };
  }
  static error(error) {
    return {
      type: QuotationRequestActions.ERROR_DATA,
      payload: error,
    };
  }
  static success() {
    return {
      type: QuotationRequestActions.SUCCESS_DATA,
    };
  }
  static process() {
    return {
      type: QuotationRequestActions.PROCESS_DATA,
    };
  }
  static reset() {
    return {
      type: QuotationRequestActions.RESET_DATA,
    };
  }
  static helper(data) {
    return {
      type: QuotationRequestActions.GET_HELPER_QUOTATIONREQUEST,
      payload: data,
    };
  }
  static helperFull(data) {
    return {
      type: QuotationRequestActions.GET_HELPER_QUOTATIONREQUEST_FULL,
      payload: data,
    };
  }

  static resetList() {
    return {
      type: QuotationRequestActions.RESET_LIST,
    };
  }

  static beforePrint() {
    return {
      type: QuotationRequestActions.BEFORE_PRINT_PR,
    };
  }
  
  static print(data) {
    return {
      type: QuotationRequestActions.PRINT_PR,
      payload: data,
    };
  }
  static saveRequestPrint(){
    return {
      type: QuotationRequestActions.ON_SAVE_REQUEST_PRINT,
    };
  }
  static limitPrint(){
    return {
      type: QuotationRequestActions.ON_LIMIT_PRINT,
    };
  }
}

import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import CategoryActions from '../../../../reducers/modules/hr/category/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'hr/category'

export default class CategoryMiddleware {

  static create(params){
    return (dispatch) => {
      dispatch(CategoryActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      CategoryMiddleware.showOne(dispatch, params);
      dispatch(CategoryActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(CategoryActions.resetList());
      };
  }
  static list(params){
      return (dispatch) => {
          dispatch(CategoryActions.process());
          CategoryMiddleware.showlist(dispatch, params);
        };
  }
  static reset(){
    return (dispatch) => {
        dispatch(CategoryActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(CategoryActions.view());
        CategoryMiddleware.showOne(dispatch, params);
      };
  }

  static beforeSaveNew(post,search){
    return (dispatch) => {
        dispatch(CategoryActions.submit());
        CategoryMiddleware.saveNew(dispatch, post,search);
      };
  }
  static beforeSaveUpdate(params,post,search){
    return (dispatch) => {
        dispatch(CategoryActions.submit());
        CategoryMiddleware.saveUpdate(dispatch, params, post,search);
      };
  }
  static beforeDelete(id,params){
    return (dispatch) => {
        dispatch(CategoryActions.delete());
        CategoryMiddleware.delete(dispatch,params,id);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(params=null){
    return (dispatch) => {
      CategoryMiddleware.showHelper(dispatch,params);
      };
  }

 
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CategoryMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-position' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(CategoryActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        CategoryMiddleware.alertHendler(dispatch,error)
      });
  }

  static showlist(dispatch, params){
      axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${CategoryMiddleware.getToken()}`
          },
          url: url,
          responseType: 'json',
          params:params
        })
        .then((response) => {
          
          if (response && response.status === 200 && response.data) {
            dispatch(CategoryActions.list(response.data.result));
          }
        })
        .catch((error) => {
          CategoryMiddleware.alertHendler(dispatch,error)
        });
  }

  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CategoryMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CategoryActions.view(response.data.result));
        }
      })
      .catch((error) => {
        CategoryMiddleware.alertHendler(dispatch,error)
      });
  }

  static delete(dispatch, params,id){
    axios({
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CategoryMiddleware.getToken()}`
        },
        url: url +'/'+id,
        responseType: 'json'
      })
      .then((response) => {
       
        if (response && response.status === 200 && response.data) {
          MySwal.close()
          dispatch(CategoryActions.process(),CategoryMiddleware.showlist(dispatch, params));
          CategoryMiddleware.alertHendler(dispatch,response,true,'Delete Data Successfully','success')
        }
      })
      .catch((error) => {
        CategoryMiddleware.alertHendler(dispatch,error,true)
      });
  }

  static saveNew(dispatch, post,search){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${CategoryMiddleware.getToken()}`
        },
        url: url,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CategoryActions.process(),CategoryMiddleware.showlist(dispatch,search));
          dispatch(CategoryActions.success());
          CategoryMiddleware.alertHendler(dispatch,response,true,'Create Data Successfully','success')
        }
      })
      .catch((error) => {
        CategoryMiddleware.alertHendler(dispatch,error)
      });
  }

  static saveUpdate(dispatch, params, post, search){
    axios({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${CategoryMiddleware.getToken()}`
        },
        url: url+'/'+params,
        data:post,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(CategoryActions.process(),CategoryMiddleware.showlist(dispatch,search));
          dispatch(CategoryActions.success());
          CategoryMiddleware.alertHendler(dispatch,response,true,'Update Data Successfully','success')
        }
      })
      .catch((error) => {
        CategoryMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        switch(status){
          
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(CategoryActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(CategoryActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}

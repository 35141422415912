export default class AdminActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static GET_HELPER='GET_HELPER';
  
    static submit(){
      return {
        type: AdminActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: AdminActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: AdminActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: AdminActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: AdminActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: AdminActions.SHOW_DATA,
        payload: data
      };
    }
    static resetList() {
      return {
        type: AdminActions.RESET_LIST
      };
    }
    static error(error){
      return {
        type: AdminActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: AdminActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: AdminActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: AdminActions.RESET_DATA,
      };
    }
    static errorInternal(error){
      return {
        type: AdminActions.ERROR_SERVER,
        payload: error
      };
    }
    static helper(data){
        return {
          type: AdminActions.GET_HELPER,
          payload: data
        };
      }
  }
  
  
import React,{ Component } from 'react';
import i18n from '../../../../i18n';
import InputNumber from '../../../utilities/Form/InputNumber';
import Select2 from '../../../utilities/Form/Select2';
import DatePickerCustom from '../../../utilities/Form/datepicker';
export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                durationRestUp:0,
                durationRestDown:0,
                toleranceRest:0,
                toleranceCheckin:0,
                toleranceCheckout:0,
                toleranceDistance:0,
                checkInTime:new Date(),
                checkOutTime:new Date(),
            },
            timezoneCurrent:null,
            timezoneText:i18n.t("Timezone Branch"),
            timezoneList:[
                {
                    label:'WIB',
                    value:'Asia/Jakarta'
                },
                {
                    label:'WITA',
                    value:'Asia/Makassar'
                },
                {
                    label:'WIT',
                    value:'Asia/Jayapura'
                },
            ],
          isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            ...this.state, 
            form: {
                ...this.state.form,
                [key]:value
            } 
        })
    }
    runSubmit(){
        const data={
            ...this.state.form,
            checkInTime:moment(this.state.form.checkInTime).format('H:m:s'),
            checkOutTime:moment(this.state.form.checkOutTime).format('H:m:s'),
            timezone:this.state.timezoneCurrent?.value||null
        }
        return data
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    handlerSelect(e, currentState, extra = () => {}) {
        var value = null;
        if (e != null) {
          value = e.value;
        }
        this.setState({ [currentState]: e }, extra);
      }
    
    render(){
        const {data,isNewRecord}=this.props        
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                console.log(moment(data.check_out_time))
                this.state.form.durationRestUp=data.duration_rest_up
                this.state.form.durationRestDown=data.duration_rest_down
                this.state.form.toleranceCheckin=data.tolerance_checkin
                this.state.form.toleranceRest=data.tolerance_rest
                this.state.form.toleranceCheckout=data.tolerance_checkout
                this.state.form.toleranceDistance=data.tolerance_distance
                this.state.form.checkOutTime=new Date(data.check_out_time)
                this.state.form.checkInTime=new Date(data.check_in_time)
                this.state.timezoneCurrent=(data?.timezone)?{value:data.timezone,label:data.timezone_label}:null
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                 <InputNumber
                    name={"durationRestUp"}
                    title={i18n.t("Duration Rest Up") +" ("+i18n.t("Minute")+")"}
                    required
                    value={this.state.form.durationRestUp}
                    onChange={this.handler}
                    error={this.renderError('durationRestUp')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <InputNumber
                    name={"durationRestDown"}
                    title={i18n.t("Duration Rest Down") +" ("+i18n.t("Minute")+")"}
                    value={this.state.form.durationRestDown}
                    onChange={this.handler}
                    error={this.renderError('durationRestDown')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <InputNumber
                    name={"toleranceRest"}
                    title={i18n.t("Tolerance Rest") +" ("+i18n.t("Minute")+")"}
                    value={this.state.form.toleranceRest}
                    onChange={this.handler}
                    error={this.renderError('toleranceRest')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <InputNumber
                    name={"toleranceCheckin"}
                    title={i18n.t("Tolerance Checkin") +" ("+i18n.t("Minute")+")"}
                    value={this.state.form.toleranceCheckin}
                    onChange={this.handler}
                    error={this.renderError('toleranceCheckin')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <InputNumber
                    name={"toleranceCheckout"}
                    title={i18n.t("Tolerance Checkout") +" ("+i18n.t("Minute")+")"}
                    value={this.state.form.toleranceCheckout}
                    onChange={this.handler}
                    error={this.renderError('toleranceCheckout')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <InputNumber
                    name={"toleranceDistance"}
                    title={i18n.t("Tolerance Distance") +" ("+i18n.t("Meter")+")"}
                    value={this.state.form.toleranceDistance}
                    onChange={this.handler}
                    error={this.renderError('toleranceDistance')}
                    className="form-control form-control-md"
                    decimalsLimit={0}
                />
                <DatePickerCustom
                    name={"checkInTime"}
                    title={i18n.t("Check In Time")}
                    error={this.renderError('checkInTime')}
                    className="form-control form-control-md"
                    selected={this.state.form.checkInTime}
                    onChange={(date) => 
                            this.setState({...this.state,
                                            form:{
                                                ...this.state.form,
                                                checkInTime:date
                                            }})
                            }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption="Time"
                   dateFormat="HH:mm"
                   timeFormat="HH:mm"
                ></DatePickerCustom>
                <DatePickerCustom
                    name={"checkOutTime"}
                    title={i18n.t("Check Out Time")}
                    error={this.renderError('checkOutTime')}
                    className="form-control form-control-md"
                    selected={this.state.form.checkOutTime}
                    onChange={(date) => 
                        this.setState({...this.state,
                                        form:{
                                            ...this.state.form,
                                            checkOutTime:date
                                        }})
                        }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                ></DatePickerCustom>
                <Select2
                    isClearable={true}
                    name={"timezone"}
                    required={true}
                    onChange={(e) => {
                        this.handlerSelect(e, "timezoneCurrent", () => {
                        });
                    }}
                    options={this.state.timezoneList}
                    title={i18n.t("Timezone")}
                    value={this.state.timezoneCurrent}
                    placeholder={this.state.timezoneText}
                    error={this.renderError("timezone")}
                    />
            </form>
        );
    }
}

export default Form;
import React, { Component, Fragment } from "react";
import { useState } from 'react';
import { Button, Table, Collapse } from "react-bootstrap";
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMagnifyingGlass, faBroom, faDisplay, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import { keyBy } from "lodash";

const MySwal = withReactContent(Swal);
export class FormSalesInvoiceFullReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: [],
      customerCurrent: null,
      idCustomer: null,
      customerText: i18n.t('All'),
      branchList: [],
      branchCurrent: null,
      idBranch: null,
      branchText: i18n.t('All'),
      listData: [],
      expand: [],
      expandSearch: 0,
      arrayCollapse: [],
      arrayPayment: [],
      arrDet: null,
      open: false,
      click: 0,
      first: 0,
      sort: null,
      limitCurrent: null,
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      page: 1,
      invoiceNo: null,
      customer: null,
      soNo:null,
      sales:null,
      show: 0,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      showDetail: 0,
      invoiceDetail: null,
      invoiceNo: null,
      listData: [],
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);


  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setDataDetail();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setDataDetail();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }

  setExpand(i) {
    this.setState({
      ...this.state,
      expand: {
        ...this.state.expand,
        [i]: !this.state.expand[i],
      },
    })
  }

  setExpandSearch() {
    var search = this.state.expandSearch;
    if(search == 0){
      this.setState({
          open: true,
          expandSearch : 1
      })
    }else if(search == 1){
      this.setState({
        expandSearch : 0,
            open:false

    })
    }
    
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  setDataSO(type, search) {
    var startDate = moment(this.state.startDateExcel).format("YYYY-MM-DD");
    var endDate = moment(this.state.endDateExcel).format("YYYY-MM-DD");
    var idCustomer = (this.state.idCustomer == null) ? '' : this.state.idCustomer;
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var invoiceNo  =  (this.state.invoiceNo == null) ? '' : this.state.invoiceNo;
    var sales  =  (this.state.sales == null) ? '' : this.state.sales;
    // var soNo  =  (this.state.soNo == null) ? '' : this.state.soNo;
    var sort = (type == null) ? '' : type;
    var key = (search == null) ? '' : search;

    let params = {
      startDate: startDate,
      endDate: endDate,
      idCustomer: idCustomer,
      idBranch: idBranch,
      limit: limit,
      page: page,
      sort: sort,
      key: key,
      invoiceNo:invoiceNo,
      sales:sales,
      // soNo:soNo,

    }


    this.props.setDataSO(params);
  }

  setDataSelling(i) {
    var idInvoice = i;

    let params = {
      idInvoice: idInvoice,
    }
    this.props.setDataSelling(params);
  }

  setDataInvoice() {
    var invoice = (this.state.invoiceNo == null) ? '' : this.state.invoiceNo;

    let params = {
      invoice: invoice
    }
    this.props.setInvoiceDetail(params);
  }

  renderSearch(){
    return(
      <Fragment>
      <div className="row">
        <div className="row col-sm-2 ">
          <Input
            type={"text"}
            className="form-control"
            placeholder="Invoice No."
            name={"invoiceNo"}
            title={"Invoice no."}
            labelfield={'true'}
            value={this.state.invoiceNo}
            handler = {(e)=>this.handler(e)}
          />
          </div>
          {/* <div className="row  col-sm-2 ">
          <Input
            type={"text"}
            className="form-control"
            placeholder="Customer"
            name={"customer"}
            title={"Customer"}
            labelfield={'true'}
            value={this.state.customer}
            handler = {(e)=>this.handler(e)}
          />
          </div> */}
          <div className="row col-sm-2 ">
          <Input
            type={"text"}
            className="form-control"
            placeholder="Sales"
            name={"sales"}
            title={"Sales Name"}
            labelfield={'true'}
            value={this.state.sales}
            handler = {(e)=>this.handler(e)}
          />
          </div>
          {/* <div className="row  col-sm-2 ">
          <Input
            type={"text"}
            className="form-control"
            placeholder="Sales Order No."
            name={"soNo"}
            title={"Sales Order No."}
            labelfield={'true'}
            value={this.state.soNo}
            handler = {(e)=>this.handler(e)}
          />
          </div> */}
          <div className="col-sm">
       <Button 
          onClick={async (e) => {
          await this.setState({ page: 1,sort:null }, () => this.setDataSO())
          await this.setState({ headeTable: true })
          }}
          variant="success" className="btn btn-sm btn btn-info mt-8">
          <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> </Button>
        <Button
          variant="danger"
          className="btn btn-sm btn btn-info mt-8 ml-5"
          onClick={async(e) =>{
            await this.setState({invoiceNo:'',sales:''})
          }}
          >
          <FontAwesomeIcon icon={faBroom}></FontAwesomeIcon>
        </Button>
        </div>
        <div className="col-sm ">
         
          </div>
    </div>
    <div className="row">
   
    </div>
    </Fragment>
    );
  }

  renderSelling(key) {
    let html = [];
    let data = this.state.arrayCollapse;
    for (var i = 0; i < data.length; i++) {
      if (key == data[i].page) {
        html.push(
          <tr className="text-center fw-semibold fs-6 text-gray-800 square border">
            <td>{data[i].name_item}</td>
            <td style={{ textAlign: 'right' }}>{data[i].qty}</td>
            <td>{data[i].name_unit}</td>
            <td style={{ textAlign: 'right' }}>{data[i].price}</td>
            <td style={{ textAlign: 'right' }}>{data[i].total}</td>
          </tr>
        );
      }
    }
    return html;
  }

  renderPayment(key) {
    let html = [];
    let cl = this.state.click;
    let data = this.state.arrayPayment;
    for (var i = 0; i < data.length; i++) {
      if (key == data[i].page) {
        html.push(
          <tr className="text-center fw-semibold fs-6 text-gray-800 square border">
            <td>{data[i].date}</td>
            <td>{data[i].payment_no}</td>
            <td>{data[i].payment_type}</td>
            <td style={{ textAlign: 'right' }}>{data[i].total}</td>
            <td style={{ textAlign: 'right' }}>{data[i].total_payment}</td>
          </tr>
        );
      }
    }
    return html;
  }

  renderSO() {
    const { dataSO } = this.props
    let row = dataSO.data
    let html = [];
    let no = 1;
    let show = this.state.click;
    if (dataSO != undefined && dataSO != null) {
      Swal.close();
      if (row.length > 0) {
        for (var p = 0; p < row.length; p++) {
          let id = row[p].id;
          html.push(
            <Fragment>
              <tr className="border " key={p} style={{ fontWeight: 'bold' }}>
                <td className="text-center">{no++}</td>
                <td>{row[p].date}</td>
                <td>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    key={p}
                    onClick={
                      async (e) => {
                        await this.changeData('arrayPayment', 'getPayment', id, 'payment', null, null);
                        await this.changeData('arrayCollapse', 'getDetail', id, 'detail', null, null, () => this.setExpand(id));
                      }}
                    aria-controls={"table"}
                    aria-expanded={this.state.expand[id]}
                  > {this.state.expand[id] ?
                    '-  ' : '+  '}

                  </span>
                  {row[p].invoiceNo}
                </td>
                <td>{row[p].branch}</td>
                <td>{row[p].customer}</td>
                <td>{row[p].sales}</td>
                <td>{row[p].soNo}</td>
                <td style={{ textAlign: 'right' }}>{row[p].total_format}</td>
                <td style={{ textAlign: 'right' }}>{row[p].payment_format}</td>
                <td style={{ textAlign: 'right' }}>{row[p].remaining_format}</td>
                <td><span
                  style={{
                    cursor: "pointer",
                  }}
                  className={row[p].status == 1 ? "badge badge-light-success" : "badge badge-light-danger"}

                >
                  {row[p].status == 1 ? i18n.t('Paid') : i18n.t('Unpaid')}
                </span>
                </td>
              </tr>
              {this.state.expand[id] ?
                <tr>
                  <td colSpan={'11'}>
                    <Collapse in={this.state.expand[id]}>
                      <div id="example-collapse-text">
                        <React.Fragment>
                          <div style={{ fontSize: '14', fontWeight: 'bold', textAlign: 'center' }}>
                            {i18n.t('DETAIL ')}
                          </div>
                          <table className="table table-responsive table-bordered  border ">
                            <thead>
                              <tr className="text-center fw-semibold fs-6 text-gray-800 square border ">
                                <td>{i18n.t('Item Name')}</td>
                                <td>{i18n.t('Qty')}</td>
                                <td>{i18n.t('Unit')}</td>
                                <td>{i18n.t('Price')}</td>
                                <td>{i18n.t('Total')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderSelling(id)}
                            </tbody>
                          </table>
                          <div style={{ fontSize: '14', fontWeight: 'bold', textAlign: 'center' }}>
                            {i18n.t('PAYMENT ')}
                          </div>
                          <table className="table table-responsive table-bordered  border ">
                            <thead>
                              <tr className="text-center fw-semibold fs-6 text-gray-800 square border ">
                                <td>{i18n.t('Date')}</td>
                                <td>{i18n.t('Payment No.')}</td>
                                <td>{i18n.t('Payment Type')}</td>
                                <td>{i18n.t('Total Invoice')}</td>
                                <td>{i18n.t('Total Payment')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              {show == 0 ? this.renderPayment(id) :
                                <tr>
                                  <td colSpan={'5'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('NOT HAVE PAYMENT')}</td>
                                </tr>}
                            </tbody>
                          </table>
                        </React.Fragment>
                      </div>
                    </Collapse>
                  </td>
                </tr>
                : ''}
            </Fragment>
          );
        }

      } else {
        html.push(
          <tr className="border  " >
            <td colSpan={'11'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
      return html;
    } else {
      html.push(
        <tr>
          <td>
            {i18n.t("Data Not Found")}
          </td>
        </tr>
      );
      return html;
    }
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/purchasing-report-customer/excel",
      method: "GET",
      params: {
        startDate: this.state.startDateExcel,
        endDate: this.state.endDateExcel,
        idSupp: (this.state.idSupp == null) ? '' : this.state.idSupp,
        idBranch: (this.state.idBranch == null) ? '' : this.state.idBranch,


      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download", 'Laporan Pembelian Per Customer.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    if (this.state.showDetail == 0) {
      this.setState({
        [key]: value,
        [currentState]: e
      }, () => this.setDataSO()
      )
    }

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (this.state.showDetail == 0) {
      if (event.target.value == 0) {
        this.setState({ page: (get - 1) }, () => this.setDataSO());
      } else if (event.target.value == (count - 1)) {
        this.setState({ page: (get + 1) }, () => this.setDataSO());
      } else {
        this.setState({ page: event.target.value }, () => this.setDataSO())
      }
    }
  }

  renderPagination() {
    const { dataSO } = this.props;
    var setLimit = dataSO;

    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '1', label: 'All' }
      this.state.show = 1;
    }

    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {setLimit.links != null
                ? setLimit.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == setLimit.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, setLimit.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != setLimit.links.length - 1 ? item["label"] : ""}
                      {i == setLimit.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  changeData(
    keyFill,
    type = null,
    key = "",
    cons = '',
    array = null,
    arraykey = null,
    extraFunction = null
  ) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCustomer":
        var url = process.env.REST_URL + "helper/get-customer";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: branch,
          store: store,
        };
        break;

      case "getDetail":
        var url = process.env.REST_URL + "report/sales-invoice-selling";
        var idInvoice = key;
        params = {
          idInvoice: idInvoice,
        };
        break;

      case "getPayment":
        var url = process.env.REST_URL + "report/sales-invoice-payment";
        var idInvoice = key;
        params = {
          idInvoice: idInvoice,
        };
        break;
    }
    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              }
              else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                if (cons == 'detail') {
                  var array = this.state.arrayCollapse;
                  var data = response.data.result
                  for (var i = 0; i < array.length; i++) {
                    if (key == array[i].page) {
                      array.splice(i, data.length)
                    }
                  }
                  this.setState({
                    arrayCollapse:
                      [...array, ...data]

                  })
                } else if (cons == 'payment') {
                  var array = this.state.arrayPayment;
                  var data = response.data.result
                  if (data != null && data.length > 0) {
                    this.setState({
                      click: 0,
                    });
                  } else {
                    this.setState({
                      click: 1,
                    });
                  }
                  for (var i = 0; i < array.length; i++) {
                    if (key == array[i].page) {
                      array.splice(i, data.length)
                    }
                  }
                  this.setState({
                    arrayPayment:
                      [...array, ...data]

                  })
                }
              }
            }
            if (extraFunction != null) {
              this.setState(arr, extraFunction);
            } else {
              this.setState(arr);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  sortColumn(key) {
    let sortValue = this.state.sort
    if (sortValue != null && sortValue.hasOwnProperty(key)) {
      switch (sortValue[key]) {
        case 'asc':
          this.setState({ sort: { [key]: 'desc' } }, () => {
            this.setDataSO(key, 'asc');
          })
          break;
        case 'desc':
          this.setState({ sort: { [key]: 'asc' } }, () => {
            this.setDataSO(key, 'desc');
          })
          break;
      }
    } else {
      this.setState({ sort: { [key]: 'desc' } }, () => {
        this.setDataSO(key, 'asc');
      })
    }
  }

  rendersortIcon(key) {
    let sortValue = this.state.sort
    if (sortValue != null && sortValue.hasOwnProperty(key)) {
      return (
        <Fragment>
          <span style={{ textAlign: 'right' }}>
            {(sortValue[key] == 'asc') ? <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon> : <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>}
          </span>
        </Fragment>
      )
    }
  }

  render() {
    const { isProcessing, helperSalesInvoiceReport, datalist, data, datalistInvoiceDetail, helper } = this.props;
    if (this.state.first == 0) {
      this.state.sort = 'asc';
      this.state.first = 1
    }
    this.state.branchList = helper.branch;
    if (this.state.showDetail == 0) {
      return (
        <Fragment>
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body pt-3">
                    <div className="row mb-4" >
                      <div className="col-sm-4" style={{ fontSize: '20px' }}>
                        Period Date:
                      </div>
                      <div className="col-sm-2" style={{ fontSize: '20px' }}>
                        Branch :
                      </div>
                      <div className="col-sm-2" style={{ fontSize: '20px' }}>
                        Customer :
                      </div>
                    </div>
                    <div className="row mb-12">
                      <div className="col-sm-2">
                        <DatePickerCustom
                          name={"startDateExcel"}
                          title={i18n.t("")}
                          selected={this.state.startDateExcel}
                          onSelect={async (e) => {
                            await this.handlerDateTime(e, 'startDateExcel');
                          }
                          }
                          showYearDropdown
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          peekNextMonth
                          showMonthDropdown
                          onChange={(e) => { }}
                          startDay={0}
                          autoComplete={"off"}
                          dateFormat="dd MMM yyyy"
                          className="form-control form-control-sm"
                        >

                        </DatePickerCustom>
                      </div>
                      {i18n.t("To")}
                      <div className="col-sm-2">
                        <DatePickerCustom
                          name={"endDateExcel"}
                          title={i18n.t("")}
                          selected={this.state.endDateExcel}
                          onSelect={async (e) => {
                            await this.handlerDateTime(e, 'endDateExcel');
                          }
                          }
                          showYearDropdown
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          peekNextMonth
                          showMonthDropdown
                          onChange={(e) => { }}
                          autoComplete={"off"}
                          dateFormat="dd MMM yyyy"
                          className="form-control form-control-sm"
                        ></DatePickerCustom>
                      </div>
                      <div className="col-sm-2">
                        <Select2
                          isClearable={true}
                          name={"idBranch"}
                          required={true}
                          size={4}
                          onChange={async (e) => {
                            this.setState({ customerCurrent: null, customerList: [] });
                            if (e == null) {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                              await this.setState({ customerCurrent: null, customerList: [] });
                            } else {
                              await this.handlerSelect(e, "idBranch", "branchCurrent");
                              await this.changeData('customerList', 'getCustomer', '');
                            }
                          }}
                          options={this.state.branchList}
                          title={i18n.t("Branch")}
                          value={this.state.branchCurrent}
                          placeholder={this.state.branchText}
                          labelfield={"false"}
                        />
                      </div>
                      <div className="col-sm-2">
                        <Select2
                          isClearable={true}
                          name={"idCustomer"}
                          labelfield={"false"}
                          onChange={async (e) => {
                            if (e == null) {
                              this.handlerSelect(e, "idCustomer", "customerCurrent")
                            }
                            else {
                              this.handlerSelect(e, "idCustomer", "customerCurrent")
                            }
                          }}
                          options={this.state.customerList}
                          title={i18n.t("")}
                          value={this.state.customerCurrent}
                          placeholder={this.state.customerText}
                          error={null}
                        />
                      </div>
                      <div className="col-sm-2">
                          <Button onClick={async (e) => {
                            await this.setState({ page: 1 ,sort:null}, () => this.setDataSO())
                            await this.setState({ headeTable: true })
                          }}
                          variant="success" className="btn btn-md btn btn-success"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} </Button>
                      </div>
                      {this.state.headeTable == true ?
                       <div className="col-sm-2">
                          <Button onClick={()=>this.setExpandSearch()}
                            variant="info" className="btn btn-sm btn btn-info"><FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon> Search </Button>
                        </div> :'' }
                    </div>
                    {this.state.headeTable == true ?
                      <React.Fragment>
                        {this.state.open==true?
                        <Collapse in={this.state.expandSearch}>
                          <div id="example-collapse-text">
                            {this.renderSearch()}
                          </div>
                        </Collapse>
                        :''}
                        {!isProcessing ?
                          <table className="table table-responsive table-bordered  border">
                            <thead>
                              <tr className="text-center fw-semibold fs-6 text-gray-800 bg-aquamarine square border">
                                <th width={"4%"} >
                                  {<Fragment>{i18n.t("No.  ")}</Fragment>}
                                </th>
                                <th width={"9%"} key={'date'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('date')}>
                                  {<Fragment>{i18n.t("Date  ")}{this.rendersortIcon('date')}</Fragment>}
                                </th>
                                <th width={"12%"} key={'t_sellings.transactionNo'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('t_sellings.transactionNo')}>
                                  {<Fragment>{i18n.t("Invoice No.  ")}{this.rendersortIcon('t_sellings.transactionNo')}</Fragment>}
                                </th>
                                <th width={"13%"} key={'bname'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('bname')}>
                                  {<Fragment>{i18n.t("Branch  ")}{this.rendersortIcon('bname')}</Fragment>}
                                </th>
                                <th width={"13%"} key={'cname'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('cname')}>
                                  {<Fragment>{i18n.t("Customer  ")}{this.rendersortIcon('cname')}</Fragment>}
                                </th>
                                <th width={"9%"} key={'sname'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('sname')}>
                                  {<Fragment>{i18n.t("Sales  ")}{this.rendersortIcon('sname')}</Fragment>}
                                </th>
                                <th width={"10%"}>
                                  {<Fragment>{i18n.t("Sales Order No.  ")}</Fragment>}
                                </th>
                                <th width={"8%"} key={'total'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('total')}>
                                  {<Fragment>{i18n.t("Total Invoice  ")}{this.rendersortIcon('total')}</Fragment>}
                                </th>
                                <th width={"8%"} key={'totalPayment'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('totalPayment')}>
                                  {<Fragment>{i18n.t("Payment  ")}{this.rendersortIcon('totalPayment')}</Fragment>}
                                </th>
                                <th width={"8%"} key={'remainingPayment'} className={''} style={{ cursor: 'pointer' }} onClick={(e) => this.sortColumn('remainingPayment')}>
                                  {<Fragment>{i18n.t("Remaining ")}{this.rendersortIcon('remainingPayment')}</Fragment>}
                                </th>
                                <th width={"8%"} >
                                  {<Fragment>{i18n.t("Status ")}</Fragment>}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderSO()}
                            </tbody>
                          </table>
                          :
                          this.renderLoading()

                        }
                        {this.renderPagination()}
                      </React.Fragment> :
                      ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default FormSalesInvoiceFullReport;

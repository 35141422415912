
import React from "react";
import {transactionSales} from "../Const/TranscationCode";

const LabelSales=(props)=>{
    const filter=transactionSales.filter((item)=>item.key===props.value);
    if(filter.length==1){
        return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
    }   
    return ''
}
export default LabelSales;
import React, { Component } from "react";
import i18n from "../../../../i18n";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ACC2ID: "",
      ACC2Name: "",
      parentACC1ID: null,
      coa1List: null,
      parentCurrent: null,
      parentText:i18n.t("Select COA 1"),

      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,

      [key]: value,
    });
  }
  handler2(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,

      [key]: value,
    });
  }
  runSubmit() {
    return this.state;
  }

  handlerSelect(e, name, currentState) {
    var key = name;
    var value = "";
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,

      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  render() {
    const { data, isNewRecord, coa1List } = this.props;
    this.state.coa1List = coa1List;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.ACC2Name = data.name),
          (this.state.ACC2ID = data.code),
          (this.state.parentCurrent = {
            value: data.parent_id,
            label: data.parent,
          });
        this.state.isUpdate = 2;
      }
    }
    return (
      <form>
        <div className="row"></div>
        <div className="col-md-6">
          <Input
            type={"input"}
            name={"ACC2ID"}
            title={i18n.t("ACC2 ID")}
            required
            value={this.state.ACC2ID}
            handler={this.handler}
            error={this.renderError("ACC2ID")}
          />
        </div>
        <div className="col-md-12">
          <Input
            type={"input"}
            name={"ACC2Name"}
            title={i18n.t("ACC2 Name")}
            required
            value={this.state.ACC2Name}
            handler={this.handler}
            error={this.renderError("ACC2Name")}
          />
        </div>
        <div className="col-md-6">
          <Select2
            isClearable={true}
            name={"parentACC1ID"}
            title={i18n.t("ID COA 1")}
            required={true}
            onChange={(e) =>
              this.handlerSelect(e, "parentACC1ID", "parentCurrent")
            }
            options={this.state.coa1List}
            value={this.state.parentCurrent}
            placeholder={this.state.parentText}
            error={this.renderError("parentACC1ID")}
          />
        </div>
      </form>
    );
  }
}

export default Form;


import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from '../../../utilities/Form/InputNumber';

const MySwal = withReactContent(Swal);

export class BeginningBalanceForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                BeginningBalance: {},
            },
            dataDetail: [],
            idBranch: null,
            branchCurrent: null,
            branchText: i18n.t("Select Branch"),
            branchList: [],
            coa3: null,
            coa3Current: null,
            coa3Text: i18n.t("Select COA 3"),
            coa3List: [],
            coa4: null,
            coa4Current: null,
            coa4Text: i18n.t("Select COA 4"),
            coa4List: [],
            date: new Date(),
            showDetail: false,
            loadingAccount: false,
            loadingCode: false,
            loadingCustomer: false,
            loadingSO: false,
            loadingPO: false,
            nominal: 0,
            loadingCode: false,
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
    }

    runSubmit() {
        const BeginningBalance = {
            idBranch: this.state.branchCurrent != null ? this.state.branchCurrent.value : null,
            coa3: this.state.coa3Current != null ? this.state.coa3Current.value : null,
            coa4: this.state.coa4Current != null ? this.state.coa4Current.value : null,
            balance: this.state.nominal,
            transactionNo: this.state.transactionNo,
            date: moment(this.state.date).format('YYYY-MM-DD'),
        }

        this.state.form.BeginningBalance = BeginningBalance;
        return this.state.form
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            [key]: value
        })
    }


    handlerCurrency(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            [key]: value
        })
        this.setState({ [currentState]: e })
    }

    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }


    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        })
    }

    changeData({ keyFill, type = null, key = "", array = null, arraykey = null, callback = () => { } } = props) {
        const token = cookieManager.getUserToken()
        let params = null
        switch (type) {
            case 'getBank':
                var url = process.env.REST_URL + 'helper/get-bank'
                var branch =
                    this.state.idBranch != null
                        ? this.state.idBranch
                        : null;
                params = {
                    branch: branch,
                }
                break;
        }

        if (type != null) {
            axios({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                url: url,
                responseType: "json",
                params: params,
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        let dataResponse = null
                        if (array != null) {
                            var arr = [...this.state[array]];

                            const objectss = arr[arraykey];
                            if (key == "") {
                                dataResponse = response.data.result
                                objectss[keyFill] = response.data.result;
                            } else {
                                dataResponse = response.data.result[key]
                                objectss[keyFill] = response.data.result[key];
                            }
                        } else {
                            if (key == "") {
                                dataResponse = response.data.result
                                var arr = { [keyFill]: response.data.result };
                            } else {
                                dataResponse = response.data.result[key]
                                var arr = { [keyFill]: response.data.result[key] };
                            }
                        }
                        this.setState(arr, async () => callback(dataResponse));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    render() {
        const { helperBeginningBalance } = this.props

        if (helperBeginningBalance != null && helperBeginningBalance != '') {
            this.state.branchList = helperBeginningBalance.branch
            this.state.coa3List = helperBeginningBalance.coa3
            this.state.coa4List = helperBeginningBalance.coa4
        }

        return (
            <React.Fragment>
                <form>
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <DatePickerCustom
                                name={"date"}
                                title={i18n.t("Date")}
                                required={true}
                                selected={this.state.date}
                                onSelect={async (e) => {
                                    await this.handlerDateTime(e, 'date');
                                    // this.changeData({
                                    //     keyFill:"transactionNo",
                                    //     type:"getCode",
                                    //     key:"code"
                                    //   })
                                }
                                }
                                disabled={this.state.isUpdate == 1 ? true : false}
                                onChange={(e) => { }}
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                // maxDate={new Date()}
                                // minDate={this.state.limitDate}
                                className="form-control form-control-md"
                            />
                        </div>
                        <div className="col-md-4 mt-3">
                            <Select2
                                isClearable={true}
                                name={"idBranch"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "idBranch", "branchCurrent");

                                }}
                                options={this.state.branchList}
                                title={i18n.t("Branch")}
                                value={this.state.branchCurrent}
                                placeholder={this.state.branchText}
                                labelfield={"true"}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('BeginningBalance.idBranch')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <Select2
                                isClearable={true}
                                name={"coa3"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "coa3", "coa3Current");

                                }}
                                options={this.state.coa3List}
                                title={i18n.t("COA 3")}
                                value={this.state.coa3Current}
                                placeholder={this.state.coa3Text}
                                labelfield={"true"}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('BeginningBalance.coa3')}
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <Select2
                                isClearable={true}
                                name={"coa4"}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "coa4", "coa4Current");

                                }}
                                options={this.state.coa4List}
                                title={i18n.t("COA 4")}
                                value={this.state.coa4Current}
                                placeholder={this.state.coa4Text}
                                labelfield={"true"}
                            />

                        </div>
                        <div className="col-md-4 mt-3">
                            <InputNumber
                                id={"nominal"}
                                name="nominal"
                                placeholder={i18n.t("Nominal")}
                                title={i18n.t("Balance")}
                                defaultValue={0}
                                value={this.state.nominal}
                                decimalsLimit={2}
                                onValueChange={(value, name) =>
                                    this.handlerCurrency(value, name)
                                }
                                disabled={(this.state.showDetail == true) ? true : false}
                                className="form-control  form-control-md text-end"
                                prefix="Rp "
                                labelfield={"true"}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError("nominal")}
                            </div>
                        </div>
                    </div>
                    <hr />
                </form>
            </React.Fragment>
        );
    }
}

export default BeginningBalanceForm;
import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import MemoInActions from "../../../../reducers/modules/accounting/memo-in/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "accounting/memo-in";

export default class MemoInMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(MemoInActions.create());
    };
  }

  static beforeApprove(id, value, search) {
    const post = {
      id: id,
      value: value,
    };
    return (dispatch) => {
      dispatch(MemoInActions.approve());
      MemoInMiddleware.saveApprove(dispatch, post, search);
    };
  }

  static beforeReject(id, value,type, search) {
    const post = {
      id: id,
      value: value,
      type:type
    };
    return (dispatch) => {
      dispatch(MemoInActions.reject());
      MemoInMiddleware.saveReject(dispatch, post, search);
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(MemoInActions.update());
      MemoInMiddleware.showOne(dispatch, params);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(MemoInActions.process());
      MemoInMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(MemoInActions.reset());
    };
  }
  static resetList() {
    return (dispatch) => {
      dispatch(MemoInActions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(MemoInActions.view());
      MemoInMiddleware.showOne(dispatch, params);
    };
  }
  static getPrint(params) {
    return (dispatch) => {
      dispatch(MemoInActions.beforePrint());
      MemoInMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeCancel(id, params) {
    return (dispatch) => {
      dispatch(MemoInActions.cancel());
      MemoInMiddleware.cancel(dispatch, params, id);
    };
  }
  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(MemoInActions.submit());
      MemoInMiddleware.saveNew(dispatch, post, search);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(MemoInActions.beforePrint());
      MemoInMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeChangeStatus(params, post) {
    return (dispatch) => {
      dispatch(MemoInActions.submit());
      MemoInMiddleware.saveChangeStatus(dispatch, params, post);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      MemoInMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull() {
    return (dispatch) => {
      MemoInMiddleware.showHelperFull(dispatch);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static saveApprove(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/approve/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MemoInActions.process(),
            MemoInMiddleware.showlist(dispatch, search)
          );
          dispatch(MemoInActions.success());
          MemoInMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Approve Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveReject(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/reject/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MemoInActions.process(),
            MemoInMiddleware.showlist(dispatch, search)
          );
          dispatch(MemoInActions.success());
          MemoInMiddleware.alertHendler(
            dispatch,
            response,
            true,
            post.type=='reject'?"Reject Data Successfully":"Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MemoInActions.list(response.data.result));
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelperFull(dispatch) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-journal",
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MemoInActions.helperFull(response.data.result));
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-journal",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MemoInActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }
  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MemoInActions.view(response.data.result));
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }

  static showPrint(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/print/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(MemoInActions.print(response.data.result));
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }

  static cancel(dispatch, params, id) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/cancel/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MemoInActions.process(),
            MemoInMiddleware.showlist(dispatch, params)
          );
          MemoInMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Cancel Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MemoInActions.process(),
            MemoInMiddleware.showlist(dispatch, search)
          );
          dispatch(MemoInActions.success());
          MemoInMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Create Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveChangeStatus(dispatch, params, post) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MemoInMiddleware.getToken()}`,
      },
      url: url + "/change/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            MemoInActions.process(),
            MemoInMiddleware.showList(dispatch, search)
          );
          dispatch(MemoInActions.success());
          MemoInMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        MemoInMiddleware.alertHendler(dispatch, error);
      });
  }

  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(MemoInActions.error(data.error));
            }
            break;
          case 403:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Tidak Ada Hak Akses, Hubungi Super User Untuk Melanjutkan",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(MemoInActions.error(data.error));
            }
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(MemoInActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}

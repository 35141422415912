
import React from "react";
import i18n from "../../../i18n";

const LabelSchedule=(props)=>{
    switch(props.value){
        case 1:
            return <div className="badge badge-light-primary">{i18n.t("Check In")}</div>
        case 0:
            return <div className="badge badge-light-info">{i18n.t("New Schedule")}</div>
        case 2:
            return <div className="badge badge-light-success">{i18n.t("Done")}</div>
        case 3:
            return <div className="badge badge-light-warning">{i18n.t("Change Schedule")}</div>
        case 4:
            return <div className="badge badge-light-warning">{i18n.t("Change Sales")}</div>
    }
}
export default LabelSchedule;
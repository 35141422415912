import {
  faBoxArchive,
  faBoxOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, FormLabel, Modal, Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import Select from "react-select";
import TextArea from "../../../utilities/Form/TextArea";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import cookieManager from "../../../../utils/cookieManager";
import FormConvertion from "./formConvertion";
import DropzoneCustom from "../../../utilities/Form/DropzoneCustom";
import i18n from "../../../../i18n";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Receiving: {},
        ReceivingDetail: [],
      },
      maxUploadFiles:3,
      receivingNo: "",
      modalDetail: false,
      titleModal: i18n.t("Convertion"),
      itemChoose: null,
      date: new Date(),
      shippingDate: new Date(),
      doNo: "",
      arrayDetail: [
        {
          itemValue: [],
          description: "",
          qty: 0,
          batchNo: "",
          expiredDate: null,
          unit: "",
          poValue: null,
          warehouseValue: null,
          qtyAvailable: 0,
          itemList: null,
          bandNo: "",
          suffixqty: "",
          convertionDetail: [],
        },
      ],
      show: false,
      poText: i18n.t("Select PO"),
      itemText: i18n.t("Select Item"),
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      unitText: i18n.t("Select Unit"),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      storeCurrent: null,
      storeText: i18n.t("Select Store"),
      storeList: [],
      supplierCurrent: null,
      supplierList: [],
      supplierText: i18n.t("Select Supplier"),
      isUpdate: 0,
      images: [],
    };
    this.formConvertion = React.createRef();
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.updateDropzone = this.updateDropzone.bind(this);
  }

  updateDropzone(files) {
    this.setState({ images: files });
  }
  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }
  showModal() {
    this.setState({
      show: !this.state.show,
    });
  }
  calculateRow(i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    let price = parseFloat(this.state.arrayDetail[i].price) || 0;
    let qty = parseFloat(this.state.arrayDetail[i].qty) || 0;
    let taxValue = parseFloat(this.state.taxValue) || 0;
    let discount = parseFloat(this.state.arrayDetail[i].discountPrice) || 0;
    let priceTax = 0;
    if (this.state.tax) {
      priceTax = (price - discount) * (taxValue / 100);
    }

    let priceNett = price + priceTax - discount;
    let priceTotal = priceNett * qty;
    objectss["priceNett"] = priceNett;
    objectss["priceTax"] = priceTax;
    objectss["priceTotal"] = priceTotal;
    this.setState(arr);

    this.calculateAll();
  }
  calculateAll() {
    //total
    let subTotal = 0;
    let grandTotal = 0;
    let taxTotal = 0;
    let discountTotal = 0;
    const detail = this.state.arrayDetail;

    for (let i = 0; i < detail.length; i++) {
      let price = parseFloat(detail[i].price) || 0;
      let qty = parseFloat(detail[i].qty) || 0;
      let discount = parseFloat(detail[i].discountPrice) || 0;
      let tax = parseFloat(detail[i].priceTax) || 0;
      subTotal += price * qty;
      discountTotal += discount * qty;
      taxTotal += tax * qty;
      grandTotal += (price - discount + tax) * qty;
    }

    this.setState({
      subTotal: subTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: grandTotal,
    });
  }
  runSubmit() {
    const images = this.state.images;
    const Receiving = {
      receivingNo: this.state.receivingNo,
      date: moment(this.state.date).format("YYYY-MM-DD"),
      idSupplier:
        this.state.supplierCurrent != null
          ? this.state.supplierCurrent.value
          : "",
      idBranch:
        this.state.branchCurrent != null ? this.state.branchCurrent.value : "",
      idStore:
        this.state.storeCurrent != null ? this.state.storeCurrent.value : "",
      images: images.length > 0 ? images : null,
      doNo: this.state.doNo,
      shippingDate: moment(this.state.shippingDate).format("YYYY-MM-DD"),
    };
    const detail = this.state.arrayDetail;
    let idPO = [];
    let idDetail = [];
    let idWarehouse = [];
    let batchNo = [];
    let expDate = [];
    let qty = [];
    let description = [];
    let bandNo = [];
    let convertionList = [];

    for (var i = 0; i < detail.length; i++) {
      idPO.push(detail[i].poValue == null ? "" : detail[i].poValue.value);
      idDetail.push(
        detail[i].itemValue == null ? "" : detail[i].itemValue.value
      );
      idWarehouse.push(
        detail[i].warehouseValue == null ? "" : detail[i].warehouseValue.value
      );
      batchNo.push(detail[i].batchNo);
      expDate.push(
        detail[i].expiredDate != "" && detail[i].expiredDate != null
          ? moment(detail[i].expiredDate).format("YYYY-MM-DD")
          : ""
      );
      qty.push(detail[i].qty);
      description.push(detail[i].description);
      bandNo.push(detail[i].bandNo);
      convertionList.push(detail[i].convertionDetail);
    }
    this.state.form.Receiving = Receiving;
    this.state.form.ReceivingDetail = {
      idPO: idPO,
      idPODetail: idDetail,
      idWarehouse: idWarehouse,
      batchNo: batchNo,
      expDate: expDate,
      qty: qty,
      description: description,
      bandNo: bandNo,
      convertionDetail: convertionList,
    };
    return this.state.form;
  }

  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      // objectss.value = null;
      objectss[name] = null;
    } else {
      // var value=e.value
      // objectss.value = e;
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;

    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  setConvertion(detail, index, array, key) {
    let choose = {
      unitList: detail.unit,
      qty: detail.qtyAvailable,
      convertionDetail: detail.convertionDetail,
      index: index,
      key: key,
      array: array,
    };
    this.setState({ itemChoose: choose, modalDetail: true });
  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }
  renderDetail(detail, i) {
    const { data, unitList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td>
            <Select
              isClearable={true}
              name={"idPO"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                if (e == null) {
                  await this.handlerCurrencyArray(
                    0,
                    "qtyAvailable",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(
                    [],
                    "convertionDetail",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(0, "qty", i, "arrayDetail");
                  await this.handlerCurrencyArray(
                    "",
                    "suffixqty",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerDateTimeArray(
                    null,
                    "expiredDate",
                    i,
                    "arrayDetail"
                  );
                }
                await this.handlerSelectArray(e, "poValue", i, "arrayDetail");
                await this.handlerSelectArray(
                  null,
                  "itemValue",
                  i,
                  "arrayDetail"
                );
                await this.changeData(
                  "itemList",
                  "getItem",
                  "",
                  "arrayDetail",
                  i
                );
              }}
              options={this.state.poList}
              value={detail.poValue}
              placeholder={this.state.poText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReceivingDetail.idPO." + i)}
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idWarehouse"}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectArray(
                  e,
                  "warehouseValue",
                  i,
                  "arrayDetail"
                );
              }}
              options={this.state.warehouseList}
              value={detail.warehouseValue}
              placeholder={this.state.warehouseText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReceivingDetail.idWarehouse." + i)}
            </div>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"idPODetail"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                await this.handlerSelectArray(e, "itemValue", i, "arrayDetail");
                if (e != null) {
                  await this.changeData(
                    "unit",
                    "getUnitReceiving",
                    "",
                    "arrayDetail",
                    i
                  );
                  let remaining = e != null ? e.helper : 0;
                  let array = remaining.toString().split(" ");
                  await this.handlerCurrencyArray(
                    parseFloat(array[0]),
                    "qtyAvailable",
                    i,
                    "arrayDetail"
                  );
                  if (array.length > 1) {
                    await this.handlerCurrencyArray(
                      " " + array[1],
                      "suffixqty",
                      i,
                      "arrayDetail"
                    );
                  }
                } else {
                  await this.handlerCurrencyArray(
                    0,
                    "qtyAvailable",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(
                    [],
                    "convertionDetail",
                    i,
                    "arrayDetail"
                  );
                  await this.handlerCurrencyArray(0, "qty", i, "arrayDetail");
                  await this.handlerCurrencyArray(
                    "",
                    "suffixqty",
                    i,
                    "arrayDetail"
                  );
                }
              }}
              options={detail.itemList}
              value={detail.itemValue}
              placeholder={this.state.itemText}
            />
            <div className="fv-plugins-message-container invalid-feedback">
              {this.renderError("ReceivingDetail.idItem." + i)}
            </div>
          </td>
          <td>
            <Input
              type={"input"}
              name={"band"}
              title={i18n.t("Band No")}
              value={detail.bandNo}
              handler={(e) =>
                this.handlerInputArray(e, "bandNo", i, "arrayDetail")
              }
              labelfield={"false"}
              error={this.renderError("ReceivingDetail.bandNo." + i)}
            />
          </td>
          <td>
            <DatePickerCustom
              name={"expiredDate"}
              title={"Expired Date"}
              required={true}
              selected={detail.expiredDate != null ? detail.expiredDate : null}
              onChange={async (e) => {
                await this.handlerDateTimeArray(
                  e,
                  "expiredDate",
                  i,
                  "arrayDetail"
                );
              }}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm"
              labelfield={"false"}
              error={this.renderError("ReceivingDetail.expDate." + i)}
            />
          </td>
          
          <td>
            <Input
              type={"input"}
              name={"batchNo"}
              title={i18n.t("Batch No")}
              value={detail.batchNo}
              handler={(e) =>
                this.handlerInputArray(e, "batchNo", i, "arrayDetail")
              }
              error={this.renderError("ReceivingDetail.batchNo." + i)}
              labelfield={"false"}
            />
          </td>
          <td>
            <CurrencyInput
              id={"qtyAvailable" + i}
              name="qtyAvailable"
              placeholder={i18n.t("Qty Available")}
              defaultValue={0}
              value={detail.qtyAvailable}
              decimalsLimit={2}
              onValueChange={(value, name) => {}}
              className="form-control  form-control-sm text-end"
              disabled
              suffix={detail.suffixqty}
            />
          </td>
          <td>
            <div className="row">
              <div className="col-9 p-0">
                <CurrencyInput
                  id={"qty" + i}
                  name="qty"
                  placeholder={i18n.t("Qty Receive")}
                  defaultValue={0}
                  value={detail.qty}
                  decimalsLimit={2}
                  disabled
                  onValueChange={(value, name) => {
                    // let available=this.state.arrayDetail[i].qtyAvailable
                    // if(value > available){
                    //     value=available;
                    // }
                    // this.handlerCurrencyArray(value,"qty",i,"arrayDetail")
                  }}
                  className="form-control  form-control-sm text-end"
                  // suffix={detail.suffixqty}
                />
              </div>
              {detail.itemValue != null ? (
                <div className="col p-0">
                  <Button
                    variant={
                      detail.convertionDetail.length > 0 ? "warning" : "info"
                    }
                    className="btn p-2"
                    onClick={(e) => {
                      this.setConvertion(detail, i, "arrayDetail", "qty");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        detail.convertionDetail.length > 0
                          ? faBoxOpen
                          : faBoxArchive
                      }
                    />
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="fv-plugins-message-container invalid-feedback">
                {this.renderError("ReceivingDetail.qty." + i)}
              </div>
            </div>
          </td>
          <td>
            <textarea
              className="form-control  form-control-sm"
              name={"description"}
              title={i18n.t("Note")}
              value={detail.description}
              onChange={(e) =>
                this.handlerInputArray(e, "description", i, "arrayDetail")
              }
            ></textarea>
          </td>
          <td>
            <Button
              variant="danger"
              className="btn btn-sm"
              onClick={(e) => this.removeArray(i, "arrayDetail")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }
  handlerDateTimeArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }

  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }

  changeData(keyFill, type = null, key = "", array = null, arraykey = null) {
    const token = cookieManager.getUserToken();
    let params = null;
    switch (type) {
      case "getCode":
        var url = process.env.REST_URL + "helper/get-receiving-code";
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var store =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          date: date,
          branch: branch,
          store: store,
        };
        break;
      case "getStore":
        var url = process.env.REST_URL + "helper/get-store";
        var branch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        params = {
          id: branch,
        };
        break;
      case "getUnitReceiving":
        var url = process.env.REST_URL + "helper/get-unit-receiving";
        var item =
          this.state[array][arraykey].itemValue != null
            ? this.state[array][arraykey].itemValue.value
            : null;
        params = {
          id: item,
        };
        break;
      case "getPo":
        this.state.arrayDetail = [
          {
            itemValue: null,
            description: "",
            qty: 0,
            batchNo: "",
            expiredDate: null,
            unit: "",
            poValue: null,
            warehouseValue: null,
            qtyAvailable: 0,
            itemList: null,
            bandNo: "",
            suffixqty: "",
            convertionDetail: [],
          },
        ];
        var url = process.env.REST_URL + "helper/get-po-list";
        var idSupplier =
          this.state.supplierCurrent != null
            ? this.state.supplierCurrent.value
            : null;
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        var date =
          this.state.date != undefined
            ? moment(this.state.date).format("YYYY-MM-DD")
            : null;
        params = {
          supplier: idSupplier,
          date: date,
          branch: idBranch,
          store: idStore,
        };
        break;
      case "getItem":
        var url = process.env.REST_URL + "helper/get-po-items";
        var idPO =
          this.state[array][arraykey].poValue != undefined
            ? this.state[array][arraykey].poValue.value
            : null;
        params = { id: idPO };
        break;
      case "getWarehouse":
        var url = process.env.REST_URL + "helper/get-setup-warehouse";
        var idBranch =
          this.state.branchCurrent != null
            ? this.state.branchCurrent.value
            : null;
        var idStore =
          this.state.storeCurrent != null
            ? this.state.storeCurrent.value
            : null;
        params = {
          branch: idBranch,
          store: idStore,
        };
        break;
    }

    if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            if (array != null) {
              var arr = [...this.state[array]];
              const objectss = arr[arraykey];
              if (key == "") {
                objectss[keyFill] = response.data.result;
              } else {
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                var arr = { [keyFill]: response.data.result };
              } else {
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    const { data, isNewRecord, helperReceiving } = this.props;
    this.state.branchList =
      helperReceiving != null ? helperReceiving.branch : [];
    this.state.supplierList =
      helperReceiving != null ? helperReceiving.supplier : [];
    console.log(this.state)
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        (this.state.poNo = data.poNo),
          (this.state.description = data.description),
          (this.state.datePo = new Date(data.date));
        this.state.dateDelivery = new Date(data.deliveryDate);
        this.state.idSupplier = data.id_supplier;
        this.state.supplierCurrent = {
          value: data.id_supplier,
          label: data.name_supplier,
        };
        this.state.arrayDetail = [];
        for (var po = 0; po < data.po_detail.length; po++) {
          this.state.arrayDetail.push({
            itemValue: {
              value: data.po_detail[po].id_item,
              label: data.po_detail[po].item_name,
            },
            description: data.po_detail[po].description,
            qty: data.po_detail[po].qty,
            unitValue: {
              value: data.po_detail[po].id_unit,
              label: data.po_detail[po].unit_name,
            },
            price: parseFloat(data.po_detail[po].unitPrice) || 0,
            discountPercent: parseFloat(data.po_detail[po].discount) || 0,
            discountPrice: parseFloat(data.po_detail[po].discount_rate) || 0,
            priceTax: parseFloat(data.po_detail[po].tax_price) || 0,
            priceNett: parseFloat(data.po_detail[po].nett_price) || 0,
            priceTotal: parseFloat(data.po_detail[po].total) || 0,
            isPr: false,
            idPr: "",
          });
        }
        this.state.isUpdate = 1;
      }
    }
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-3">
              <Input
                type={"input"}
                name={"receivingNo"}
                title={i18n.t("Receiving No")}
                required
                disabled
                value={this.state.receivingNo}
                handler={this.handler}
                error={this.renderError("Receiving.receivingNo")}
              />
            </div>
            <div className="col-3">
              <DatePickerCustom
                name={"date"}
                title={i18n.t("Receiving Date")}
                required={true}
                selected={this.state.date}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "date");
                  await this.changeData("receivingNo", "getCode", "code");
                  await this.changeData("poList", "getPo");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
            <div className="col-3">
              <Input
                type={"input"}
                name={"doNo"}
                title={i18n.t("Document No")}
                required
                value={this.state.doNo}
                handler={this.handler}
                error={this.renderError("Receiving.doNo")}
              />
            </div>
            <div className="col-3">
              <DatePickerCustom
                name={"shippingDate"}
                title={i18n.t("Delivery Date")}
                // required={true}
                selected={this.state.shippingDate}
                onChange={async (e) => {
                  await this.handlerDateTime(e, "shippingDate");
                }}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <Select2
                    isClearable={true}
                    name={"idSupplier"}
                    required={true}
                    className={""}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(
                        e,
                        "idSupplier",
                        "supplierCurrent"
                      );
                      await this.changeData("poList", "getPo");
                    }}
                    options={this.state.supplierList}
                    title={i18n.t("Supplier")}
                    value={this.state.supplierCurrent}
                    placeholder={this.state.supplierText}
                    error={this.renderError("Receiving.idSupplier")}
                  />
                </div>
                <div className="col-6">
                  <Select2
                    isClearable={true}
                    name={"idBranch"}
                    required={true}
                    size={4}
                    onChange={async (e) => {
                      if (e != this.state.branchCurrent) {
                        await this.setState({
                          storeCurrent: null,
                          storeList: [],
                        });
                      }
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                      await this.changeData("receivingNo", "getCode", "code");
                      await this.changeData("storeList", "getStore", "");
                      await this.changeData("poList", "getPo");
                      await this.changeData("warehouseList", "getWarehouse");
                    }}
                    options={this.state.branchList}
                    title={i18n.t("Branch")}
                    value={this.state.branchCurrent}
                    placeholder={this.state.branchText}
                    error={this.renderError("Receiving.idBranch")}
                  />
                </div>
                <div className="col-6">
                  <Select2
                    isClearable={true}
                    name={"idStore"}
                    size={4}
                    onChange={async (e) => {
                      await this.handlerSelect(e, "idStore", "storeCurrent");
                      await this.changeData("poList", "getPo");
                      await this.changeData("warehouseList", "getWarehouse");
                    }}
                    options={this.state.storeList}
                    title={i18n.t("Store")}
                    value={this.state.storeCurrent}
                    placeholder={this.state.storeText}
                    error={this.renderError("Receiving.idStore")}
                  />
                </div>
                <div className="col-6">
                  <TextArea
                    name={"description"}
                    title={i18n.t("Note")}
                    value={this.state.description}
                    handler={this.handler}
                    error={this.renderError("Receiving.description")}
                    rows="5"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <FormLabel> {i18n.t("Upload Files")} ( {i18n.t("max")} {this.state.maxUploadFiles} {i18n.t("items")})</FormLabel>
              <DropzoneCustom
                maxFiles={this.state.maxUploadFiles}
                acceptFiles={{
                  "image/jpeg": [],
                  "image/png": [],
                  "application/pdf":[],
                }}
                maxSize={1024000}
                changeFiles={this.updateDropzone}
              ></DropzoneCustom>
            </div>
          </div>
          <br />
          <hr />
          <div className="row">
            <div className="col-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{i18n.t("No PO")}</th>
                    <th className="text-center">{i18n.t("Warehouse")}</th>
                    <th className="text-center">{i18n.t("Item")}</th>
                    <th className="text-center">{i18n.t("Band No")}</th>
                    <th className="text-center">{i18n.t("Expired Date")}</th>
                    <th className="text-center">{i18n.t("Batch No")}</th>
                    <th className="text-center">{i18n.t("Remaining Qty")}</th>
                    <th className="text-center">{i18n.t("Qty Received")}</th>
                    <th className="text-center">{i18n.t("Note")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.arrayDetail.map((detail, index) => {
                    return this.renderDetail(detail, index);
                  })}
                </tbody>
              </Table>
              <Button
                variant="success"
                onClick={(e) =>
                  this.addArray(
                    {
                      itemValue: null,
                      description: "",
                      qty: 0,
                      batchNo: "",
                      expiredDate: null,
                      unit: "",
                      poValue: null,
                      warehouseValue: null,
                      qtyAvailable: 0,
                      itemList: [],
                      bandNo: "",
                      suffixqty: "",
                      convertionDetail: [],
                      unitList: [],
                    },
                    "arrayDetail"
                  )
                }
              >
                {i18n.t("Add Item")}
              </Button>
            </div>
          </div>
        </form>

        <Modal show={this.state.modalDetail} backdropClassName="next1" centered>
          <Modal.Header>
            <Modal.Title>{this.state.titleModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormConvertion
              data={this.state.itemChoose}
              isNewRecord={false}
              ref={this.formConvertion}
            />
          </Modal.Body>
          <Modal.Footer className={"flex-center"}>
            <Button
              variant="secondary"
              onClick={(e) => this.saveConvertion(false)}
            >
              {i18n.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.saveConvertion(true)}
            >
              {i18n.t("Convertion")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form;

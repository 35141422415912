import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Billing } from "../../../../components/modules/sales/billing";
import BillingMiddleware from "../../../../middleware/modules/sales/billing";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
import BillingIndex from "../../../../components/modules/sales/billing/form-index";
// middlewares
const mapStateToProps = ({ auth, billing }) => ({
  isProcessing: billing.isProcessing,
  isError: billing.isError,
  errorMessage: billing.errorMessage,
  datalist: billing.data,
  dataBilling: billing.dataBilling,
  helperBilling: billing.helperBilling,
  showModal: billing.showModal,
  showModalBilling: billing.showModalBilling,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => BillingMiddleware.create(),
      reset: () => BillingMiddleware.resetList(),
      search: (data) => BillingMiddleware.list(data),
      closingItem: (data,search) => BillingMiddleware.beforeClose(data, search),
      update: (data) => BillingMiddleware.update(data),
      payment: (data) => BillingMiddleware.payment(data),
      getHelper:()=>BillingMiddleware.getHelper(),
      billingSales: (id, type) => BillingMiddleware.getSalesBilling(id, type),
      repaymentData:(data) => BillingMiddleware.getRepaymentData(data),
    },
    dispatch
  );
};
class BillingIndexContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    dataBilling : PropTypes.any,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.payment = this.payment.bind(this);
    this.closingItem = this.closingItem.bind(this);
  }

  update(data) {
    this.props.update(data);
  }

  payment(data) {
    this.props.payment(data);
  }

  closingItem(data,search) {
    this.props.closingItem(data,search);
  }

  create() {
    this.props.create();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    // this.props.search(search);
    this.props.getHelper();

  }
 
  render() {
    const { isProcessing,datalist } = this.props;
    
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Billing List")}
          </title>
        </Helmet>
      <BillingIndex
        {...this.props}
        data={datalist}
        isProcessing={isProcessing}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingIndexContainer);

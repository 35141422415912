import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
import SalesPerformanceChartMiddleware from "../../../../middleware/modules/chart/sales-performance";
import SalesPerformanceChart from "../../../../components/modules/chart/sales-performance";
import SalesPerformanceTarget from "../../../../components/modules/chart/sales-performance/index-target";
// middlewares

const mapStateToProps = ({ auth, salesPerformanceChart }) => ({
  isProcessing: salesPerformanceChart.isProcessing,
  isError: salesPerformanceChart.isError,
  errorMessage: salesPerformanceChart.errorMessage,
  dataTarget: salesPerformanceChart.dataTarget,
  helperSalesChart: salesPerformanceChart.helperSalesChart,
  afterSave: salesPerformanceChart.afterSave,
  showModal: salesPerformanceChart.showModal
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => SalesPerformanceChartMiddleware.getHelper(),
      setDataTarget: (data) => SalesPerformanceChartMiddleware.setDataTarget(data),
      reset: () => SalesPerformanceChartMiddleware.resetList(),
      search: (data) => SalesPerformanceChartMiddleware.table(data),
    },
    dispatch
  );
};
class SalesPerformanceTargetContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
    afterSave: PropTypes.bool,

  };

  static defaultProps = {
    afterSave: false,
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.setDataTarget = this.setDataTarget.bind(this);
  }

  // componentWillMount() {
  //   const search = {
  //     limit: 10,
  //   };
  //   this.props.reset();
  //   // this.props.getHelper();
  // }

  setDataTarget(params) {
    console.log('ini dia')
    this.props.setDataTarget(params);
  }

  render() {
    const { helperSalesChart } =
      this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Sales Performance List")}</title>
        </Helmet>
        <SalesPerformanceTarget
          {...this.props}
          createData={this.createItem}
          voidItem={this.voidItem}
          helperSalesChart={helperSalesChart}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformanceTargetContainer);

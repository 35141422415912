import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { PurchaseOrder } from "../../../../components/modules/pembelian/purchace-order";
import PurchaseOrderMiddleware from "../../../../middleware/modules/pembelian/purchase-order";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// middlewares
const mapStateToProps = ({ auth, purchaseOrder }) => ({
  isProcessing: purchaseOrder.isProcessing,
  isError: purchaseOrder.isError,
  errorMessage: purchaseOrder.errorMessage,
  datalist: purchaseOrder.data,
  showModal: purchaseOrder.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => PurchaseOrderMiddleware.create(),
      reset: () => PurchaseOrderMiddleware.resetList(),
      approve: (id, value, search) =>
        PurchaseOrderMiddleware.beforeApprove(id, value, search),
      reject: (id, value, search) =>
        PurchaseOrderMiddleware.beforeReject(id, value, search),
      search: (data) => PurchaseOrderMiddleware.list(data),
      update: (data) => PurchaseOrderMiddleware.update(data),
      delete: (id, search) => PurchaseOrderMiddleware.beforeDelete(id, search),
      closing: (id, search) => PurchaseOrderMiddleware.beforeClose(id, search),
      getHelperFull: (id, search) =>
        PurchaseOrderMiddleware.getHelperFull(id, search),
    },
    dispatch
  );
};
class PurchaseOrderContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
    this.closingItem = this.closingItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value, search) {
    this.props.reject(id, value, search);
  }
  closingItem(id, value, search) {
    this.props.closing(id, value, search);
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Purchase Order List")}
          </title>
        </Helmet>
      <PurchaseOrder
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        approveItem={this.approveItem}
        rejectItem={this.rejectItem}
        closingItem={this.closingItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderContainer);

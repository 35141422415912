import {  Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import i18n from "../../../../i18n";

// Font.register({ family: 'Inter', src: source });
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo:{
        width:80,
        height:80,
    },
    body: {
        padding: "20px",
    },
    row: {
        flex:1,
        flexDirection: 'row',
    },
    col:{
        flex:1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr:{
        flexDirection: 'row', 
    },
    td:{
        flexDirection: 'column',
    },

    //Header
    titleContainer:{
        flexDirection: 'row',
    },
    header:{
        fontSize:"18px",
        fontFamily: 'Times-Roman'
    },
    address:{
        fontSize:"12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx:{
        fontSize:"17px",
        fontWeight:700,
        fontFamily: 'Times-Roman',
        textAlign:'right'
    },
    tableTitle:{
        border:'1px solid black',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
        padding:'5px',
        marginTop:'2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize:"12px",
        fontWeight:400,
        fontFamily: 'Times-Roman',
    },
    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
       
    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'left',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },
    //end Table

    //Footer
    footer:{
        position:'absolute',
        bottom:0
    }
    //end Footer
  });
const tableRowsCount = 11;
class PrintMemoIn extends Component{
    constructor(props){
        super(props)
        this.state={
            title:'Journal No : ',
            merchant:{
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address:[]
            },
            logo:'/assets/media/logos/logo.jpg',
            data:{
                journalNo:'',
                journal_detail:[]
            }
        };
    }
    render(){
        let FillText="";
        const {dataPrint}=this.props
        let total=0
        let address=[]
        if(dataPrint!=null){
            this.state.data=dataPrint.model
            this.state.merchant=dataPrint.merchant
            address=this.state.merchant.address.split(/\r?\n/)
            total = this.state.data.journal_detail.map(item => parseFloat(item.debt) + parseFloat(item.credit)).reduce((accumulator, currentValue) => accumulator + currentValue , 0)
        
        }
        return(
            <React.Fragment>{(dataPrint !=null)?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title+ ((dataPrint !=undefined)?this.state.data.no_journal:'')}>
                        <Page wrap orientation={"landscape"} size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'90px'}} >
                                            <Image src={(this.state.merchant.logo!=null)?this.state.merchant.logo:"/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col,{width:'300px'}} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map(a=>
                                                <Text style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col,{width:'600px',paddingTop:'20px'}} >
                                            <View style={styles.titleTranx}>
                                                <Text>{i18n.t("Journal")}</Text>
                                            </View>
                                            <View style={styles.tableTitle}>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'75px'}} >
                                                        <Text>{i18n.t("Journal No.")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.no_journal}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'75px'}} >
                                                        <Text>{i18n.t("Journal Date")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'100px'}} >
                                                        <Text>{this.state.data.date_format}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'75px'}} >
                                                        <Text>{i18n.t("Branch")} </Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'440px'}} >
                                                        <Text>{this.state.data.name_branch}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tr}>
                                                    <View style={styles.td,{width:'75px'}} >
                                                        <Text>{i18n.t("Total")}</Text>
                                                    </View>
                                                    <View style={styles.td} >
                                                        <Text> : </Text>
                                                    </View>
                                                    <View style={styles.td,{width:'440px'}}>
                                                        <Text>{this.state.data.debt_format}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        </View>
                                </View>
                                <View style={styles.tableContainer}>
                                    <View style={styles.containerItem}>
                                        <Text style={{width:'5%'}}>{i18n.t("No")}</Text>
                                        <Text style={{width:'32%'}}>{i18n.t("Account No")}</Text>
                                        <Text style={{width:'20%'}}>{i18n.t("Debt")}</Text>
                                        <Text style={{width:'20%'}}>{i18n.t("Credit")}</Text>
                                        <Text style={{width:'23%'}}>{i18n.t("Description")}</Text>
                                        
                                    </View>
                                    <Fragment>{this.state.data.journal_detail.map( (item,i) => 
                                        <View style={styles.rowItem} key={i+'itemPo'}>
                                            <Text style={{width:'5%',padding:1}}>{i+1}</Text>
                                            <Text style={{width:'32%',padding:1,textAlign: "left"}}>{item.full_coa3}</Text>
                                            <Text style={{width:'18%',padding:1,textAlign: "right"}}>{item.debt_format}</Text>
                                            <Text style={{width:'18%',padding:1,textAlign: "right"}}>{item.credit_format}</Text>
                                            <Text style={{width:'27%',padding:1,textAlign: "left"}}>{item.description}</Text>
                                            
                                        </View>
                                    )}</Fragment>
                                    <View style={styles.rowItem}>
                                        <Text style={{width:'5%',padding:1}}></Text>
                                       <Text style={{width:'32%',padding:1,textAlign: "left",fontWeight: "heavy"}}>{i18n.t("TOTAL")}</Text>
                                       <Text style={{width:'18%',padding:1,textAlign: "right"}}>{this.state.data.total_debt_format}</Text>
                                       <Text style={{width:'18%',padding:1,textAlign: "right"}}>{this.state.data.total_credit_format}</Text>
                                       <Text style={{width:'27%',padding:1,textAlign: "left"}}></Text>
                                     
                                  
                                    </View>
                                    
                                </View>
                             
                            </View>
                        </Page>
                    </Document>
            </PDFViewer>:<div>{i18n.t("Please Wait Rendering PDF")}</div>}
        </React.Fragment>   
        )
    }
}

export default PrintMemoIn;


import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import CashFlow from '../../../../components/modules/report/cash-flow';
import CashFlowMiddleware from '../../../../middleware/modules/report/cash-flow';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,cashFlow }) => ({
  isProcessing: cashFlow.isProcessing,
  isError: cashFlow.isError,
  errorMessage: cashFlow.errorMessage,
  dataList:cashFlow.data,
  showModal:cashFlow.showModal,
  helperList:cashFlow.helperCashFlow
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>CashFlowMiddleware.getHelper(),
    reset: () =>CashFlowMiddleware.resetList(),
    setData: (data) =>CashFlowMiddleware.list(data),
    excel: (data) =>CashFlowMiddleware.showExcel(data),
  }, dispatch);
};
class CashFlowContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Cash Flow Report")}
          </title>
        </Helmet>
      <CashFlow
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFlowContainer);

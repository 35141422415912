import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import AccountReceiveable from '../../../../components/modules/report/account-receiveable';
import AccountReceiveableMiddleware from '../../../../middleware/modules/report/account-receiveable';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';
// middlewares
 

const mapStateToProps = ({ auth,accountReceiveable }) => ({
  isProcessing: accountReceiveable.isProcessing,
  isError: accountReceiveable.isError,
  errorMessage: accountReceiveable.errorMessage,
  datalist:accountReceiveable.data,
  showModal:accountReceiveable.showModal,
  helperList:accountReceiveable.helperAccountReceiveable
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getHelper:()=>AccountReceiveableMiddleware.getHelper(),
    reset: () =>AccountReceiveableMiddleware.resetList(),
    // search: data =>AccountReceiveableMiddleware.list(data),
    setData: (data) =>AccountReceiveableMiddleware.list(data),
    excel: (data) =>AccountReceiveableMiddleware.showExcel(data),
  }, dispatch);
};
class AccountReceiveableContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.any,
    // search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  submitItem() {
    this.props.setData();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.getHelper()
    // this.props.search(search);
  
  }

  render() {
    const {datalist} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Account Receiveable List")}
          </title>
        </Helmet>
      <AccountReceiveable
        {...this.props}
        data={datalist}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReceiveableContainer);

import {  faClockFour, faPersonPraying, faStore, faUserCheck, faWalking } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React,{ useState } from "react"
import ModalCustom from "./modal"
import { Button } from "react-bootstrap"
import TypeLogTx from "../../../utilities/Const/typeLogTx"

export default function Timeline(data){
  const [ ModalOptions,setOptions]=useState({
    showModal:false,type:null,titleModal:""
  })
    return (
      <div className="timeline timeline-border-dashed">
        {/* Checkin Employee */}   
        {data?.check_in &&            
        <div className="timeline-item">
          <div className="timeline-line"></div>
            <div className="timeline-icon"> 
              <FontAwesomeIcon icon={faUserCheck} className="fs-2 text-gray-500"></FontAwesomeIcon>
            </div>
          
         
          
          <div className="timeline-content mb-5 mt-2">
            <div className="pe-3 mb-5">
              <div className="fs-5 fw-semibold mb-2">Employee Check In</div>
              <div className="d-flex align-items-center mt-1 fs-6">
                <div className="text-muted me-2 fs-7">Added at {data?.check_in||''} by</div>
                <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                  <img src={data?.data_sales.avatar || "/assets/media/avatars/admin.jpg"} alt="img" />
                </div>
              </div>
            </div>
            
            
            {/* <div className="overflow-auto pb-5">
              
              <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                
                <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Meeting with customer</a>
                
                
                <div className="min-w-175px pe-2">
                  <span className="badge badge-light text-muted">Application Design</span>
                </div>
                
                
                <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                  
                  <div className="symbol symbol-circle symbol-25px">
                    <img src="assets/media/avatars/300-2.jpg" alt="img" />
                  </div>
                  
                  
                  <div className="symbol symbol-circle symbol-25px">
                    <img src="assets/media/avatars/300-14.jpg" alt="img" />
                  </div>
                  
                  
                  <div className="symbol symbol-circle symbol-25px">
                    <div className="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">A</div>
                  </div>
                  
                </div>
                
                
                <div className="min-w-125px pe-2">
                  <span className="badge badge-light-primary">In Progress</span>
                </div>
                
                
                <a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a>
                
              </div>
              
              
              <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                
                <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Project Delivery Preparation</a>
                
                
                <div className="min-w-175px">
                  <span className="badge badge-light text-muted">CRM System Development</span>
                </div>
                
                
                <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                  
                  <div className="symbol symbol-circle symbol-25px">
                    <img src="assets/media/avatars/300-20.jpg" alt="img" />
                  </div>
                  
                  
                  <div className="symbol symbol-circle symbol-25px">
                    <div className="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">B</div>
                  </div>
                  
                </div>
                
                
                <div className="min-w-125px">
                  <span className="badge badge-light-success">Completed</span>
                </div>
                
                
                <a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a>
                
              </div>
              
            </div> */}
          </div>
        </div>
        }    
        {/* Visit to Customer */}

        {data?.details.map((customer,index)=>
            {
              switch(customer.type){
                case 'start rest':
                  return (<div className="timeline-item">
                    <div className="timeline-line"></div>
                      <div className="timeline-icon"> 
                        <FontAwesomeIcon icon={faPersonPraying} className="fs-2 text-gray-500"></FontAwesomeIcon>
                      </div>
                    <div className="timeline-content mb-5 mt-2">
                      <div className="pe-3 mb-5">
                        <div className="fs-5 fw-semibold mb-2">Employee Rest</div>
                        <div className="d-flex align-items-center mt-1 fs-6">
                          <div className="text-muted me-2 fs-7">Added at {customer?.checkin||''} by</div>
                          <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                            <img src={data?.data_sales.avatar || "/assets/media/avatars/admin.jpg"} alt="img" />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>)
                  break;
                case 'end rest':
                  return (<div className="timeline-item">
                    <div className="timeline-line"></div>
                      <div className="timeline-icon"> 
                        <FontAwesomeIcon icon={faWalking} className="fs-2 text-gray-500"></FontAwesomeIcon>
                      </div>
                    <div className="timeline-content mb-5 mt-2">
                      <div className="pe-3 mb-5">
                        <div className="fs-5 fw-semibold mb-2">Employee Rest Done</div>
                        <div className="d-flex align-items-center mt-1 fs-6">
                          <div className="text-muted me-2 fs-7">Added at {customer?.checkin||''} by</div>
                          <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                            <img src={data?.data_sales.avatar || "/assets/media/avatars/admin.jpg"} alt="img" />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>)
                  break;

                case 'visit':
                  return (
                  <div className="timeline-item"> 
                    <div className="timeline-line"></div>
                    <div className="timeline-icon me-4">
                      <FontAwesomeIcon icon={faStore} className="fs-2 text-gray-500"></FontAwesomeIcon>
                    </div>
                    <div className="timeline-content mb-5 mt-2">
                      <div className="overflow-auto pe-3">
                        <div className="fs-5 fw-semibold mb-2">Schedule visit to customer : {customer?.customer_name}</div>
                      </div>
                      {customer.status !=0 &&
                      <div className="overflow-auto pb-5">
                        {(customer?.trouble && customer?.trouble.length >0) && 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Trouble</a>
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-danger text-white">Trouble</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            
                          </div>
                          <div className="min-w-125px pe-2">
                          </div>
                          <div className="min-w-60px pe-2">
                            <Button
                              className="btn btn-sm btn-light btn-active-light-primary"
                              onClick={()=>{
                                setOptions({
                                  showModal:true,
                                  type:'Trouble',
                                  titleModal:"Trouble, Customer :"+ customer?.customer_name
                                })
                                data.getData(TypeLogTx.TROUBLE_EMPLOYEE,customer?.idVisit)
                              }}>
                                View
                            </Button>
                          </div>
                        </div>}
                        {(customer?.checkin && customer?.status !=5)&& 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Check In</a>
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-success">Check In</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            
                          </div>
                          <div className="min-w-125px pe-2">
                            <span className="badge badge-light-primary">Added at <br></br>{customer?.checkin}</span>
                          </div>
                          <div className="min-w-60px pe-2">
                            <Button
                              className="btn btn-sm btn-light btn-active-light-primary"
                              onClick={()=>{
                                setOptions({
                                  showModal:true,
                                  type:'Check in',
                                  titleModal:"Check in :"+ customer?.customer_name
                                })
                                data.getData(TypeLogTx.CHECKIN_CUSTOMER,customer?.idVisit)
                              }}>
                                View
                            </Button>
                          </div>
                          
                        </div>}
                        {(customer?.stock && customer?.stock.length >0) && 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Stock</a>
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-primary">Stock</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            
                          </div>
                          <div className="min-w-125px pe-2">
                          </div>
                          <div className="min-w-60px pe-2">
                            <Button
                              className="btn btn-sm btn-light btn-active-light-primary"
                              onClick={()=>{
                                setOptions({
                                  showModal:true,
                                  type:'Record Stock',
                                  titleModal:"Record Stock :"+ customer?.customer_name
                                })
                                data.getData(TypeLogTx.RECORD_STOCK_CUSTOMER,customer?.idVisit)
                              }}>
                                View
                            </Button>
                          </div>
                        </div>}
      
                        
                        {(customer?.billing && customer.billing.length > 0) && 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Billing</a>
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-primary">Billing</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            
                          </div>
                          <div className="min-w-125px pe-2">
                          </div>
                          <div className="min-w-60px pe-2">
                          <Button
                              className="btn btn-sm btn-light btn-active-light-primary"
                              onClick={()=>{
                                setOptions({
                                  showModal:true,
                                  type:'Billing',
                                  titleModal:"Billing :"+ customer?.customer_name
                                })
                                data.getData(TypeLogTx.BILLING_CUSTOMER,customer?.idVisit)
                              }}>
                                View
                            </Button>
                          </div>
                        </div>}
      
                        {(customer?.selling && customer.selling.length > 0) && 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">Selling</a>
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-primary">Selling</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            
                          </div>
                          <div className="min-w-125px pe-2">
                          </div>
                          <div className="min-w-60px pe-2">
                              <Button
                                  className="btn btn-sm btn-light btn-active-light-primary"
                                  onClick={()=>{
                                    setOptions({
                                      showModal:true,
                                      type:'Selling',
                                      titleModal:"Selling :"+ customer?.customer_name
                                    })
                                    data.getData(TypeLogTx.SELLING_CUSTOMER,customer?.idVisit)
                                  }}>
                                    View
                                </Button>
                          </div>
                        </div>}
      
                        {customer?.checkout&& 
                          <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px">{customer.status==5?'Customer Closed':'Check Out'}</a>
                          <div className="min-w-175px pe-2">
                            <span className={"badge badge-danger"}>{customer.status==5?'Customer Closed':'Check Out'}</span>
                          </div>
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                          </div>
                          <div className="min-w-125px pe-2">
                            <span className="badge badge-light-primary">Added at <br></br>{customer?.checkout}</span>
                          </div>
                          <div className="min-w-60px pe-2">
                            {(customer.status==5)&&<Button
                                className="btn btn-sm btn-light btn-active-light-primary"
                                onClick={()=>{
                                  setOptions({
                                    showModal:true,
                                    type:'Closed',
                                    titleModal:"Customer Closed :"+ customer?.customer_name
                                  })
                                  data.getData(TypeLogTx.CLOSED_CUSTOMER,customer?.idVisit)
                                }}>
                                  View
                              </Button>}
                          </div>
                          
                        </div>}
                      </div>}
                      
                    </div>
                  </div>)
                  break;

              }
           }
        )}

        {/* Checkout Employee */}    
        {data?.check_out &&
        <div className="timeline-item">
          <div className="timeline-line"></div>
          <div className="timeline-icon">
            <FontAwesomeIcon icon={faClockFour} className="fs-2 text-gray-500"></FontAwesomeIcon>
          </div>
          
          <div className="timeline-content mb-5 mt-2">
            <div className="pe-3 mb-5">
              <div className="fs-5 fw-semibold mb-2">Employee Check Out</div>
              <div className="d-flex align-items-center mt-1 fs-6">
                <div className="text-muted me-2 fs-7">Added at {data?.check_out||''} by</div>
                <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                  <img src="assets/media/avatars/300-14.jpg" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        <ModalCustom
          {...ModalOptions}
          {...data}
          onDismiss={()=>{
            setOptions({...ModalOptions,showModal:false})
          }}
        />
      </div>
    )
}
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
import NewPassword from '../../../components/auth/NewPassword';
// middlewares
import AuthMiddleware from './../../../reducers/auth/middleware';
import { useParams } from 'react-router-dom';



const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isErrorNewPassword,
  errorMessage: auth.errorMessage,
  tokenValid:auth.tokenValid,
  successNewPassword:auth.successNewPassword
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials),
    verificationForgot: token => AuthMiddleware.beforeVerivication(token),
    setPassword:data=>AuthMiddleware.beforeSetPassword(data),
  }, dispatch);
};

export const NewPasswordContainer = (props)=>{
  const { verificationForgot } = props;
  let params=useParams();
  let token=params.code;
  document.getElementById("root").setAttribute("style","overflow:hidden");
  return (
    <NewPassword
      {...props}
      token={token}
    />
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordContainer);

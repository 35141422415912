import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";
import { StatusForm } from "../../../utilities/Const/StatusActive";
import Input from "../../../utilities/Form/input";
import Radio from "../../../utilities/Form/radio";
import Select2 from "../../../utilities/Form/Select2";
import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Item: {},
        Unit: [],
      },
      name: "",
      code: "",
      minStock: "",
      status: 1,
      arrayUnit: [
        {
          isParent: 1,
          idUnit: null,
          qty: 1,
          weight: 0,
          lengths: 0,
          width: 0,
          height: 0,
          value: null,
        },
      ],
      unitList: null,
      unitText: i18n.t("Select Unit"),
      itemTypeList: [],
      itemParentList:[],
      itemBrandList: [],
      itemCategoryList: [],
      itemTypeText: i18n.t("Select Type"),
      itemParentText: i18n.t("Select Parent"),
      itemTypeCurrent: null,
      itemParentCurrent: null,
      itemBrandText: i18n.t("Select Family"),
      itemBrandCurrent: null,
      itemCategoryText: i18n.t("Select Category"),
      itemCategoryCurrent: null,
      isUpdate: 0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, currentState, extra = () => {}) {
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({ [currentState]: e }, extra);
  }

  getCode() {
    const { isNewRecord } = this.props;
    const token = cookieManager.getUserToken();
    let params = {
      brand:
        this.state.itemBrandCurrent != null
          ? this.state.itemBrandCurrent.value
          : null,
      category:
        this.state.itemCategoryCurrent != null
          ? this.state.itemCategoryCurrent.value
          : null,
      type:
        this.state.itemTypeCurrent != null
          ? this.state.itemTypeCurrent.value
          : null,
      item: !isNewRecord ? this.props.dataUpdate.id : null,
    };
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "helper/get-item-code",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          this.setState({ code: response.data.result });
        }
      })
      .catch((error) => {
        ItemBrandMiddleware.alertHendler(dispatch, error);
      });
  }

  runSubmit() {
    const Item = {
      name: this.state.name,
      parent:this.state.itemParentCurrent != null
      ? this.state.itemParentCurrent.value
      : null,
      idType:
        this.state.itemTypeCurrent != null
          ? this.state.itemTypeCurrent.value
          : null,
      idCategory:
        this.state.itemCategoryCurrent != null
          ? this.state.itemCategoryCurrent.value
          : null,
      idBrand:
        this.state.itemBrandCurrent != null
          ? this.state.itemBrandCurrent.value
          : null,
      code: this.state.code,
      minStock: this.state.minStock,
      status: this.state.status,
    };
    const Unit = this.state.arrayUnit;
    let idUnit = [];
    let isParent = [];
    let lengths = [];
    let width = [];
    let height = [];
    let weight = [];
    let qty = [];

    for (var i = 0; i < Unit.length; i++) {
      idUnit.push(Unit[i].idUnit);
      isParent.push(Unit[i].isParent);
      lengths.push(Unit[i].lengths);
      width.push(Unit[i].width);
      height.push(Unit[i].height);
      weight.push(Unit[i].weight);
      qty.push(Unit[i].qty);
    }
    this.state.form.Item = Item;
    this.state.form.Unit = {
      idUnit: idUnit,
      isParent: isParent,
      length: lengths,
      width: width,
      height: height,
      qty: qty,
      weight: weight,
    };
    return this.state.form;
  }
  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss.value = null;
      objectss[name] = null;
    } else {
      var value = e.value;
      objectss.value = e;
      objectss[name] = value;
    }
    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  renderUnit(unit, i) {
    const { data, unitList } = this.props;
    return (
      <>
        <div className="row">
          
          <div className="col-md-6">
            <Select2
              isClearable={i == 0 ? false : true}
              name={"idUnit"}
              required={true}
              onChange={(e) =>
                this.handlerSelectArray(e, "idUnit", i, "arrayUnit")
              }
              options={this.state.unitList}
              title={i18n.t("Unit")}
              value={unit.value}
              placeholder={this.state.unitText}
              error={this.renderError("Unit.idUnit." + i)}
            />
          </div>
          <div className="col-md-4">
            <Input
              type={"input"}
              name={"qty"}
              title={i18n.t("Qty")}
              required
              disabled={unit.isParent ? true : false}
              value={unit.qty}
              handler={(e) => this.handlerInputArray(e, "qty", i, "arrayUnit")}
              error={this.renderError("Unit.qty." + i)}
            />
          </div>
          <div className="col-md-2">
            {unit.isParent != 1 ? (
              <Button
                variant="danger"
                onClick={(e) => this.removeArray(i, "arrayUnit")}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Input
              type={"input"}
              name={"lengths"}
              title={i18n.t("Length")}
              value={unit.lengths}
              handler={(e) =>
                this.handlerInputArray(e, "lengths", i, "arrayUnit")
              }
              error={this.renderError("Unit.lengths." + i)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type={"input"}
              name={"width"}
              title={i18n.t("Width")}
              value={unit.width}
              handler={(e) =>
                this.handlerInputArray(e, "width", i, "arrayUnit")
              }
              error={this.renderError("Unit.width." + i)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type={"input"}
              name={"height"}
              title={i18n.t("Height")}
              value={unit.height}
              handler={(e) =>
                this.handlerInputArray(e, "height", i, "arrayUnit")
              }
              error={this.renderError("Unit.height." + i)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type={"input"}
              name={"weight"}
              title={i18n.t("Weight")}
              value={unit.weight}
              handler={(e) =>
                this.handlerInputArray(e, "weight", i, "arrayUnit")
              }
              error={this.renderError("Unit.weight." + i)}
            />
          </div>
          <hr />
        </div>
      </>
    );
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  render() {
    const {
      data,
      isNewRecord,
      unitList,
      itemTypeList,
      itemBrandList,
      itemCategoryList,
      itemParentList
    } = this.props;
    console.log(this.props)

    this.state.unitList = unitList;
    this.state.itemTypeList = itemTypeList;
    this.state.itemParentList = itemParentList;
    this.state.itemBrandList = itemBrandList;
    this.state.itemCategoryList = itemCategoryList;
    if (data != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.itemTypeCurrent = { value: data.type_id, label: data.type };
        this.state.itemBrandCurrent = {
          value: data.brand_id,
          label: data.brand,
        };
        this.state.itemCategoryCurrent = {
          value: data.category_id,
          label: data.category,
        };
        this.state.itemParentCurrent = {
          value: data.parent_id,
          label: data.parent_name,
        };
        (this.state.name = data.name),
          (this.state.code = data.code),
          (this.state.minStock = data.min_stock),
          (this.state.status = data.status);
        this.state.arrayUnit = [];
        for (var po = 0; po < data.units.length; po++) {
          this.state.arrayUnit.push({
            isParent: data.units[po].isParent,
            qty: data.units[po].qty,
            lengths: data.units[po].lengths,
            idUnit: data.units[po].unitId,
            weight: data.units[po].weight,
            width: data.units[po].width,
            height: data.units[po].height,
            value: {
              value: data.units[po].unitId,
              label: data.units[po].unitName,
            },
          });
        }
        this.state.isUpdate = 1;
      }
    }
    return (
      <form>
         <Select2
          isClearable={true}
          name={"parent"}
          required={true}
          onChange={(e) => {
            this.handlerSelect(e, "itemParentCurrent", () => {
              this.getCode();
            });
          }}
          options={this.state.itemParentList}
          title={i18n.t("Item Parent")}
          value={this.state.itemParentCurrent}
          placeholder={this.state.itemParentText}
          error={this.renderError("Item.parent")}
        />
        <Select2
          isClearable={true}
          name={"idBrand"}
          required={true}
          onChange={(e) => {
            this.handlerSelect(e, "itemBrandCurrent", () => {
              this.getCode();
            });
          }}
          options={this.state.itemBrandList}
          title={i18n.t("Item Family")}
          value={this.state.itemBrandCurrent}
          placeholder={this.state.itemBrandText}
          error={this.renderError("Item.idBrand")}
        />
        <Select2
          isClearable={true}
          name={"idCategory"}
          required={true}
          onChange={(e) =>
            this.handlerSelect(e, "itemCategoryCurrent", () => {
              this.getCode();
            })
          }
          options={this.state.itemCategoryList}
          title={i18n.t("Item Category")}
          value={this.state.itemCategoryCurrent}
          placeholder={this.state.itemCategoryText}
          error={this.renderError("Item.idCategory")}
        />
        <Select2
          isClearable={true}
          name={"idType"}
          required={true}
          onChange={(e) =>
            this.handlerSelect(e, "itemTypeCurrent", () => {
              this.getCode();
            })
          }
          options={this.state.itemTypeList}
          title={i18n.t("Item Type")}
          value={this.state.itemTypeCurrent}
          placeholder={this.state.itemTypeText}
          error={this.renderError("Item.idType")}
        />
        <Input
          type={"input"}
          name={"code"}
          title={i18n.t("Code")}
          required
          value={this.state.code}
          handler={this.handler}
          error={this.renderError("Item.code")}
        />
        <Input
          type={"input"}
          name={"name"}
          title={i18n.t("Name")}
          required
          value={this.state.name}
          handler={this.handler}
          error={this.renderError("Item.name")}
        />

        <Input
          type={"input"}
          name={"minStock"}
          title={i18n.t("Min Stock")}
          required
          value={this.state.minStock}
          handler={this.handler}
          error={this.renderError("Item.minStock")}
        />
        <Radio
          list={StatusForm}
          name={"status"}
          title={i18n.t("Status")}
          inline={false}
          value={this.state.status}
          handler={this.handler}
          error={this.renderError("Item.status")}
        />
        {this.state.arrayUnit.map((unit, index) => {
          return this.renderUnit(unit, index);
        })}
        <Button
          variant="success"
          onClick={(e) =>
            this.addArray(
              {
                isParent: 0,
                idUnit: null,
                qty: 0,
                weight: 0,
                lengths: 0,
                width: 0,
                height: 0,
                value: null,
                disabled: false,
              },
              "arrayUnit"
            )
          }
        >
          {i18n.t("Add Unit")}
        </Button>
      </form>
    );
  }
}

export default Form;

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import Select from 'react-select';
import i18n from '../../../../i18n';
import { StatusForm } from '../../../utilities/Const/StatusActive';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Input from '../../../utilities/Form/input';
import InputNumber from '../../../utilities/Form/InputNumber';
import Radio from '../../../utilities/Form/radio';


export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                Tax:{},
                TaxDetail:[]
            },
            name:'',
            status:1,
            arrayDetail:[
                {
                    name:'',
                    persentage:0,
                    accSetup:'',
                    accValue:null,
                    effectiveDate:new Date(),
                    status:true
                }
            ],
            accText:i18n.t('Select Account COA'),
            isUpdate:0
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerCurrencyArray = this.handlerCurrencyArray.bind(this)
        this.handlerInputArray = this.handlerInputArray.bind(this)
        this.handlerSelectArray = this.handlerSelectArray.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    runSubmit(){
        const Tax={
            name:this.state.name,
            status:this.state.status,
        }
        const detail=this.state.arrayDetail
        let name=[]
        let persentage=[]
        let accSetup=[]
        let effectiveDate=[]
        let status=[]

        for(var i=0;i<detail.length;i++){
            status.push(detail[i].status);
            name.push(detail[i].name);
            persentage.push(detail[i].persentage);
            accSetup.push((detail[i].accValue !==null)?detail[i].accValue.value:'');
            effectiveDate.push(moment(detail[i].effectiveDate).format('YYYY-MM-DD'));
        }
        this.state.form.Tax=Tax
        this.state.form.TaxDetail={
            name:name,
            status:status,
            persentage:persentage,
            accSetup:accSetup,
            effectiveDate:effectiveDate
        }
        return this.state.form
    }
    handlerSelectArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            objectss[name] = null;
        }else{
            objectss[name] = e;
        }
        this.setState(arr)
    }
    handlerInputArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        var value=e.target.value
        objectss[name] = value;
        this.setState(arr)
    }
    handlerCurrencyArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        objectss[name] = e;
        this.setState(arr)
    }
    handlerDateTime(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            objectss[name] = null;
        }else{
            objectss[name] = e;
        }
        this.setState(arr)
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    renderDetail(detail,i){
        return <tr key={i}>
                    <td>
                        <Input
                            type={"input"}
                            name={"name"}
                            title={i18n.t("Name")}
                            required
                            value={detail.name}
                            handler={(e)=>this.handlerInputArray(e,"name",i,'arrayDetail')}
                            error={this.renderError('TaxDetail.name.'+i)}
                            labelfield={"false"}
                        />
                    </td>
                    <td >
                        <InputNumber
                            id={"persentage"+i}
                            name="persentage"
                            placeholder={i18n.t("Qty")}
                            defaultValue={0}
                            value={detail.persentage}
                            decimalsLimit={2}
                            onValueChange={(value,name)=>this.handlerCurrencyArray(value,"persentage",i,"arrayDetail")}
                            className='form-control  form-control-sm text-end'
                            error={this.renderError('TaxDetail.persentage.'+i)}
                            suffix=" %"
                            labelfield={"false"}
                        />
                    </td>
                    <td >
                        <Select
                            isClearable={true}
                            name={'accValue'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                await this.handlerSelectArray(e,"accValue",i,"arrayDetail")
                            }}
                            options={this.state.accList}
                            value={detail.accValue}
                            placeholder={this.state.accText}
                            
                        />
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </td>
                    <td >
                        <DatePickerCustom
                            name={"effectiveDate"}
                            title={i18n.t("Effective Date")}
                            required={true}
                            selected={detail.effectiveDate}
                            onChange={async (e)=>{
                                await this.handlerDateTime(e,"effectiveDate",i,"arrayDetail"); 
                            }}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            endDate={new Date()}
                            className="form-control form-control-sm"
                            labelfield={"false"}
                            error={this.renderError('TaxDetail.effectiveDate.'+i)}
                        />
                    </td>
                    <td>
                        <Button variant='danger' className='btn btn-sm' onClick={(e)=>this.removeArray(i,'arrayDetail')}>
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </Button>
                    </td>
            </tr>
    }
    addArray(value,key){
        const newRow=value
        const arr=[...this.state[key],newRow]
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    removeArray(i,key){
        const arr = this.state[key].filter( (obj,index) => {
            return index !== i;
          })
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    render(){
        const {data,isNewRecord,coa3List}=this.props     
        this.state.accList=coa3List
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.status=data.status
                this.state.name=data.name
                this.state.arrayDetail=[]
                for(var po=0;po<data.details.length;po++){
                    this.state.arrayDetail.push(
                        {
                            name:data.details[po].name,
                            persentage:data.details[po].persentage,
                            effectiveDate:new Date(data.details[po].effective_date),
                            status:data.details[po].status,
                            accValue:((data.details[po].acc_setup_id !=null)?{value:data.details[po].acc_setup_id,label:data.details[po].acc_setup}:null)
                        }
                    )
                }
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                <Input
                    type={"input"}
                    name={"name"}
                    title={i18n.t("Name")}
                    required
                    value={this.state.name}
                    handler={this.handler}
                    error={this.renderError('Tax.name')}
                />
                <Radio
                    list={StatusForm}
                    name={"status"}
                    title={i18n.t("Status")}
                    inline={false}
                    value={this.state.status}
                    handler={this.handler}
                    error={this.renderError('Tax.status')}
                />
                <div className='row'>
                    <div className="col-12">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th >{i18n.t("Name")}</th>
                                    {/* <th className='text-center'>Note</th> */}
                                    <th className='text-center'>{i18n.t("Persentage")}</th>
                                    <th className='text-center'>{i18n.t("accSetup")}</th>
                                    <th className='text-center'>{i18n.t("Effective Date")}</th>
                                    <th className='text-center'>{i18n.t("Status")}</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                       
                        <Button variant='success' onClick={
                        (e)=>this.addArray(
                            {
                                name:'',
                                persentage:0,
                                accSetup:'',
                                accValue:null,
                                effectiveDate:new Date(),
                                status:true
                            },
                            'arrayDetail'
                        )}>{i18n.t("Add Tax")}</Button>
                    </div>
                </div>
                
            </form>
        );
    }
}

export default Form;
import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/warehouse/taking-goods/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { customerApproval, takingGoods } from '../../../utilities/Const/TranscationCode';
import LabelApprove from "../../../utilities/Status/approve-customer";
import FormReturn from "../../../../containers/modules/warehouse/taking-goods/form-return";
import LabelTakingGoods from "../../../utilities/Status/taking-goods";
import FormView from "../../../../containers/modules/warehouse/taking-goods/form-view";
const MySwal = withReactContent(Swal);
export default class TakingGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      modal:1,
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Transaction"), class: " text-gray-600" },
        { link: null, title: i18n.t("Taking Goods"), class: " text-gray-500" },
      ],

      column: [
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "transaction_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Transaction No."),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_sales",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Sales"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "status",
          filter:{
              active:true,
              type:'Select',
              data:takingGoods
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelTakingGoods value={data[column.key]} />
          }
        },
       
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            let show=[3]
            return (
              
              <Fragment>
                {!show.includes(data.status) ?<Dropdown id="dropdown-btnaction">
                  <Dropdown.Toggle
                    variant="none"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    id="dropdown-basic"
                  >
                    {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 m-0">
                    {/* Edit Button */}
                    {data.status == 1 ? (
                      <>
                        {/* <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.updateData(data.id)}
                          >
                            {i18n.t("Edit")}
                          </Dropdown.Item>
                        </div> */}
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.deleteData(data.id)}
                          >
                            {i18n.t("Delete")}
                          </Dropdown.Item>
                        </div>
                        {(data.useApproval.status== data.useApproval.type==1) ?<div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.approveData(data.id)}
                          >
                            {i18n.t("Approve")}
                          </Dropdown.Item>
                        </div>:null}
                        {(data.useApproval.status== data.useApproval.type==1)?
                        <div className="menu-item px-3">
                          <Dropdown.Item
                            href="#"
                            className="menu-link px-3"
                            onClick={() => this.rejectData(data.id)}
                          >
                            {i18n.t("Reject")}
                          </Dropdown.Item>
                        </div>:null}
                      </>
                    ) : null}
                     {data.status == 2 ? (
                       <div className="menu-item px-3">
                       <Dropdown.Item
                         href="#"
                         className="menu-link px-3"
                         onClick={() => this.returnData(data.id)}
                       >
                         {i18n.t("Return")}
                       </Dropdown.Item>
                     </div>
                     ):null}
                     {data.status == 4 ? (
                       <div className="menu-item px-3">
                       <Dropdown.Item
                         href="#"
                         className="menu-link px-3"
                         onClick={() => this.viewItem(data.id)}
                       >
                         {i18n.t("View")}
                       </Dropdown.Item>
                     </div>
                     ):null}
                     
                  </Dropdown.Menu>
                </Dropdown> :null}
                
              </Fragment>
            );
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Taking Goods");
    this.props.createItem();
    this.state.modal = 1;
  }
  updateData(id) {
    this.state.titleModal = i18n.t("Edit Taking Goods");
    this.props.updateItem(id);
  }
  returnData(id) {
    this.state.titleModal = i18n.t("Return Taking Goods");
    this.props.returnItem(id);
    this.state.modal = 2;

  }

  viewItem(id) {
    this.state.titleModal = i18n.t("View");
    this.props.viewItem(id);
    this.state.modal = 3;

  }
  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve This Taking Good")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.approve(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This Taking Good")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.reject(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  deleteData(id) {
    const html = (
      <>
        <div>{i18n.t("You will delete this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    console.log('APPS',this.props)
    this.state.listData = data;
    return (
      <div id="WarehouseOut-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-WarehouseOuts-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.createData()}
                    >
                      {i18n.t("Add Taking Goods")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  checkAble={false}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "details",
                    columns: [
                      { label: i18n.t("Item Code"), key: "code_item" },
                      { label: i18n.t("Item Name"), key: "name_item" },
                      { label: i18n.t("Banderol"), key: "bandNo" },
                      { label: i18n.t("Qty"), key: "qty_format" },
                      { label: i18n.t("Unit"), key: "name_unit" },
                      { label: i18n.t("Qty Return"), key: "qty_return_format" },
                  
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.modal == 1?
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
        :this.state.modal == 2?
        <FormReturn
        showModal={showModal}
        search={this.state.search}
        titleModal={this.state.titleModal}
      />:
      <FormView
        showModal={showModal}
        search={this.state.search}
        titleModal={this.state.titleModal}
      />
      }
      </div>
    );
  }
}

import { useParams } from "react-router-dom";
import React from "react";
import WHInContainer from '../../../containers/Print/default/Warehouse-In'
export const PrintWHIn = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <WHInContainer id={id}/>
    )
}

export default PrintWHIn;
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import {
  FormCash,
} from "../../../../components/modules/accounting/cash-bank/formCash";
import CashBankMiddleware from "../../../../middleware/modules/accounting/cash-bank";
import FormBank from "../../../../components/modules/accounting/cash-bank/formBank";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, cashbank, journal }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: cashbank.isProcessingBank,
  isError: cashbank.isErrorBank,
  errorMessage: cashbank.errorMessage,
  dataUpdate: cashbank.single,
  isSuccess: cashbank.isSuccessBank,
  isReset: cashbank.isResetBank,
  isNewRecord: cashbank.isNewRecordBank,
  onSubmit: cashbank.onSubmitBank,
  helperJournal: journal.helperJournal,
  afterSave: cashbank.afterSaveBank

});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => CashBankMiddleware.reset(),
      new: (data, search) => CashBankMiddleware.beforeSaveNewBank(data, search),
      update: (id, type, search) =>
        CashBankMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormBankContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
    afterSave:PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
    afterSave:false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.FormBank = React.createRef();
    this.getData = this.getData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
    this.reset = this.reset.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.dataID);
  }

  close() {
    this.props.closeModal();
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    // this.props.reset();
    // this.props.getHelper();
    // this.props.search(search);
  }

  reset(){
    this.props.reset();

  }

  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    console.log(this.props)
    var form = this.FormBank.current.runSubmit();
    if (isNewRecord != false) {
      this.props.new(form, search);
    }
    //  else {
    //   this.props.update(dataUpdate.id, form, search);
    // }
  }

  render() {
    const { onSubmitBank, showModal, titleModal } = this.props;
    return (
      <Fragment>
        <FormBank ref={this.FormBank} {...this.props} />
          <div className="d-flex flex-end">
            <Button variant="primary" onClick={() => ((onSubmitBank)?false:this.submitForm(true))}>
              {onSubmitBank ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
            {/* <Button
              className="flex-end"
              variant="primary"
              onClick={() => this.submitForm()}
            >
              <span className="indicator-label align-left">{i18n.t("Save")}</span>
            </Button> */}
        </div> 
      </Fragment>
  );
  }
}
//   static propTypes = {
//     isAuthenticated: PropTypes.bool,
//     isProcessing: PropTypes.bool,
//     isRegistered: PropTypes.bool,
//     isError: PropTypes.bool,
//     isSuccess: PropTypes.bool,
//     dataUpdate: PropTypes.object,
//     errorMessage: PropTypes.any,
//     onSubmit: PropTypes.bool,
//   };

//   static defaultProps = {
//     isAuthenticated: false,
//     isProcessing: false,
//     isRegistered: false,
//     isSuccess: false,
//     isError: false,
//     errorMessage: [],
//     dataUpdate: null,
//     onSubmit: false,
//   };
//   constructor(props) {
//     super(props);
//     this.state = {
//       modal: {
//         title: "",
//         show: false,
//       },
//     };
//     this.FormBank = React.createRef();
//     this.getData = this.getData.bind(this);
//     this.submitForm = this.submitForm.bind(this);
//     this.close = this.close.bind(this);
//   }

//   getData() {
//     return this.props.showOne(this.props.dataID);
//   }

//   close() {
//     this.props.closeModal();
//   }
//   submitForm() {
//     const { search, isNewRecordBank, dataUpdate } = this.props;
//     var form = this.FormBank.current.runSubmit();
//     if (isNewRecordBank) {
//       this.props.new(form, search);
     
//     } else {
//       this.props.update(dataUpdate.id, form, search);
//     }
//   }

//   render() {
//     const { onSubmitBank } = this.props;
//     <Fragment>
//        <FormCash ref={this.formCash} {...this.props} />
//        <FormBank ref={this.FormBank} {...this.props} />
//           <div className="d-flex flex-end">
//             <Button variant="primary" onClick={() => ((onSubmitBank)?false:this.submitForm(true))}>
//               {onSubmitBank ? (
//                 <span className="indicator-progress show">
//                   {i18n.t("Please wait...")}
//                   <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
//                 </span>
//               ) : (
//                 <span className="indicator-label">{i18n.t("Save")}</span>
//               )}
//             </Button>
//             {/* <Button
//               className="flex-end"
//               variant="primary"
//               onClick={() => this.submitForm()}
//             >
//               <span className="indicator-label align-left">{i18n.t("Save")}</span>
//             </Button> */}
//         </div> 
//       </Fragment>
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(FormBankContainer);

import PaymentActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataPayment:{},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperPayment:null,
  paymentHelperFull:null
};

function PaymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PaymentActions.BEFORE_PAYMENT:
      return { ...state, isProcessing: false,showModal:false,type:action.payload,dataPayment:{},errorMessage: []};
    case PaymentActions.AFTER_PAYMENT:
      return { ...state, isProcessing: false,showModal:true,dataPayment:action.payload,isNewRecord:false,onSubmit:false,errorMessage: []};
    
    case PaymentActions.RESET_LIST:
      return INITIAL_STATE;
    case PaymentActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true,errorMessage: []};
    case PaymentActions.UPDATE_DATA:
      return { ...state, isProcessing: false, single:null,isNewRecord:false,showModal:true};
    case PaymentActions.VIEW_DATA:
      return { ...state, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PaymentActions.RESET_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PaymentActions.DELETE_DATA:
      return { ...state, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PaymentActions.ON_SUBMIT:
      return { ...state, onSubmit:true};
    case PaymentActions.SHOW_DATA:
      return { ...state,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PaymentActions.SUCCESS_DATA:
      return { ...state, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PaymentActions.PROCESS_DATA:
      return { ...state, isProcessing: true, isError: false,isSuccess:false};
    case PaymentActions.ERROR_DATA:
      return { ...state,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PaymentActions.GET_HELPER_PAYMENT:
      return { ...state, isError: false,isSuccess:false,onSubmit:false,helperPayment:action.payload};
     case PaymentActions.GET_HELPER_PAYMENT_FULL:
        return { ...state, isError: false,isSuccess:false,onSubmit:false,paymentHelperFull:action.payload};
      default:
      return state;
    }
}


export default PaymentReducer;

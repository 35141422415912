import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
// middlewares
import { Navigate } from 'react-router-dom';
import TempCustomerMiddleware from '../../../../middleware/modules/master/customer';
import Form from '../../../../components/modules/master/customer/form-view';
import { Button, Modal } from 'react-bootstrap';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,customer}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: customer.isProcessing,
  isError: customer.isError,
  errorMessage: customer.errorMessage,
  dataUpdate: customer.single,
  isSuccess:customer.isSuccess,
  isReset:customer.isReset,
  isNewRecord:customer.isNewRecord,
  onSubmit:customer.onSubmit,
  customerHelperFull:customer.customerHelperFull
  
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getStore: (id) =>StoresMiddleware.getHelper(id),
    getCity: (id) =>CityMiddleware.getHelper(id),
    closeModal:()=>TempCustomerMiddleware.reset(),
    new: (data,search) =>TempCustomerMiddleware.beforeSaveNew(data,search),
    update: (id,type,search) =>TempCustomerMiddleware.beforeSaveUpdate(id,type,search)
  }, dispatch);
};
class FormContainerView extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate:PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit:PropTypes.bool
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess:false,
    isError: false,
    errorMessage: [],
    dataUpdate:null,
    onSubmit:false
  }
  constructor(props) {
    super(props);
    this.state={
      modal:{
        title:'',
        show:false,

      }
    }
    this.form=React.createRef();
    this.getData=this.getData.bind(this)
    this.submitForm=this.submitForm.bind(this)
    this.changeSelect=this.changeSelect.bind(this)
    this.close=this.close.bind(this)
}
  
  getData(){
    return this.props.showOne(this.props.dataID)
  }

  close(){
      this.props.closeModal()
  }
  submitForm(){
    const {search,isNewRecord,dataUpdate}=this.props
    var form=this.form.current.runSubmit()
    if(isNewRecord){
      this.props.new(form,search)
    } else{
      this.props.update(dataUpdate.id,form,search)
    }
    
  }

  changeSelect(type,value=null){
    const params={id:value}
    switch(type){
      case 'idProvince':
        this.props.getCity(params);
        break;
      case 'idBranch':
        this.props.getStore(params);
        break;
        default:
          break;
    }
  }

  render() {
    const {customerHelperFull,onSubmit,showModal,titleModal,isReset,dataUpdate,isProcessing,isError,errorMessage,cityList,storeList,isNewRecord} = this.props;
    return (
      <>
        <Modal
          show={showModal}
          size={'lg'}
        >
            <Modal.Header >
            <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Helmet>
                <title>{titleModal}</title>
              </Helmet>
            <Modal.Body>
                <Form 
                  isNewRecord={isNewRecord}
                  isReset={isReset}
                  data={dataUpdate}
                  isProcessing={isProcessing}
                  isError={isError}
                  errorMessage={errorMessage}
                  ref={this.form}
                  cityList={cityList}
                  storeList={storeList}
                  customerHelperFull={customerHelperFull}
                  changeSelect={this.changeSelect}
                />
            </Modal.Body>
            <Modal.Footer className={'flex-center'}>
                <Button variant='secondary' onClick={()=>this.close(false)}>{i18n.t("Close")}</Button>
            </Modal.Footer>
        </Modal>
      
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainerView);

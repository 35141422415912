import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Select2 from "../../../utilities/Form/Select2";
import InputNumber from "../../../utilities/Form/InputNumber";

import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import DatePickerCustom from "../../../utilities/Form/datepicker";
const token = cookieManager.getUserToken();
const mystyle = {
  height: '100%',
  width: 1,
  backgroundColor: '#909090',
};

export class FormReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        Return: [],
        Retur: [],
        ItemRetur:[],
        Header: {},
      },
      date: new Date(),
      dateReturn: new Date(),
      dataRetur : [],
      unit: [],
      convert: 0,
      idSales: null,
      idTakingGood: null,
      update: 0,
      add:0,
      updateRetur: 0,
      show: false,
      status: 1,
      set: 0,
      totalItem :0,
      arrayDetail: [],
      arrayRetur: [],
      arrayTotal :[],
      isUpdate: 0,
    };
    this.formConvertion = React.createRef();
    this.handler = this.handler.bind(this);
    this.runSubmit = this.runSubmit.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    // this.handlerInputArray = this.handlerInputArray.bind(this);

  }


  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSelectArray(e, id, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[id.name] = null;
    } else {
      objectss[id.name] = e;
    }
    this.setState(arr);
  }

  runSubmit() {
    const detail = this.state.arrayDetail;
    const retur = this.state.arrayRetur;
    const data = this.state.dataRetur;

    const Header = {
      date: moment(this.state.date).format('YYYY-MM-DD'),
      returnDate: moment(this.state.dateReturn).format('YYYY-MM-DD'),
      idTakingGood: this.state.idTakingGood,
      idSales: this.state.idSales
    }

    let saveData = [];
    let saveRetur = [];
    let saveItemRetur = [];
    let total = 0;

    for (var i = 0; i < retur.length; i++) {
      saveRetur.push(retur[i]);
    }

    for (var i = 0; i < data.length; i++) {
      saveItemRetur.push(data[i]);
    }

    for (var i = 0; i < detail.length; i++) {
      saveData.push(detail[i]);
      if (detail[i].convertReturn == (detail[i].convert - detail[i].total_sell)) {
        total = 0;
      } else {
        total += 1;
      }
    }

    if (total == 0) {
      this.state.form.Return = saveData;
      this.state.form.Retur = saveRetur;
      this.state.form.ItemRetur = saveItemRetur;
      this.state.form.Header = Header;
      this.state.form.status = true

    } else {
      this.state.form.status = false
    }
    return this.state.form;
  }

  //CONVERTION
  setConvertion(detail, index, array, key) {
    if (detail.itemValue != null) {
      let choose = {
        unitList: detail.itemValue.unit,
        qty: detail.itemValue.lastStok,
        convertionDetail: detail.convertionDetail,
        index: index,
        key: key,
        array: array,
      };
      this.setState({ itemChoose: choose, modalDetail: true });
    }

  }
  saveConvertion(status) {
    if (status) {
      let result = this.formConvertion.current.runSubmit();
      if (result != null) {
        const arr = [...this.state[this.state.itemChoose.array]];
        const objectss = arr[this.state.itemChoose.index];
        objectss[this.state.itemChoose.key] = result.qtyUse;
        objectss["convertionDetail"] = result.detail;
        this.setState(arr);
        this.setState({ modalDetail: false });
      }
    } else {
      this.setState({ modalDetail: false });
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    })
  }

  handlerInputArray(e, name, i, arrayname, qty, p, id) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = parseInt(e);
    console.log('arr',arr)
    objectss['cons'][p] = value * parseInt(qty);
    objectss['return'][p][name] = value;
    objectss['return'][p]["qty"] = value;
    objectss['return'][p]['id'] = id;
    this.calculate(i, name, value, p);

  }

  handlerInputArrayRetur(e, name, i, arrayname, qty, p, id) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = parseInt(e);

    objectss['cons'][p] = value * parseInt(qty);
    objectss['retur'][p][name] = value;
    objectss['retur'][p]["qty"] = value;
    objectss['retur'][p]['id'] = id;
    this.calculateRetur(i, name, value, p);

  }
  

  calculate(i, name, value, p) {
    let total = 0;

    const detail = this.state.arrayDetail[i];
    const arrTotal = this.state.arrayTotal;

    const data = detail.cons
    if (data != null && data != '') {
      for (var a = 0; a < data.length; a++) {
        total += data[a] == null ? 0 : data[a];
      }
    }

    if (total <= (detail['total_item'] - detail['total_sell'])) {
      detail['convertReturn'] = total;
    } else {
      this.alertError('INFO', 'Total Kembali Harus Sama Dengan Total (Ambil - Penjualan)', 'info');
      detail['return'][p][name] = 0;
      detail['return'][p]["qty"] = 0;
      detail['convertReturn'] = 0;
    }
    this.setState(detail);
  }

  calculateRetur(i, name, value, p) {
    let total = 0;

    const detail = this.state.arrayRetur[i];
    
    const data = detail.cons
    if (data != null && data != '') {
      for (var a = 0; a < data.length; a++) {
        total += data[a] == null ? 0 : data[a];
      }
    }
    if (total <= (detail['convert'])) {
      detail['convertRetur'] = total;
    } else {
      this.alertError('INFO', 'Total Barang Retur Tidak Boleh Melebihi', 'info');
      detail['retur'][p][name] = 0;
      detail['retur'][p]["qty"] = 0;
      detail['convertRetur'] = 0;
    }
    this.setState(detail,()=>this.calculateAll());
  }

  calculateAll(){
    let totalDetail = 0;
    let totalRetur = 0;
    const retur = this.state.arrayRetur;
    const detail = this.state.arrayDetail;
    let array = [];

    for (var a = 0; a < detail.length; a++) {
      array.push({item:detail[a].id_item,qty:detail[a].cons})
    }
    
    // for (var a = 0; a < dataRetur.length; a++) {
    //   totalRetur += dataRetur[a] == null ? 0 : dataRetur[a];
    // }
    console.log('ARR',array)
  }

  handlerCurrencyArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    objectss[name] = e;
    this.setState(arr);
  }

  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  
  alertError(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }


  setList() {
    const { data } = this.props;
    let unit = this.state.unit;
    let html = [];
    let det = [];
    let itemdet = [];
    let no = 1;
    let itemUnit = [];
    for (var a = 0; a < unit.length; a++) {
      itemUnit.push({ id: unit[a].value })
    }
    if (data != null && data != '') {
      this.state.arrayDetail = data.return
      if (this.state.arrayDetail.length > 0) {
        for (var i = 0; i < this.state.arrayDetail.length; i++) {
          const row = this.state.arrayDetail[i];
          if (this.state.update == 0) {
            row['index'] = i;
            row['convertReturn'] = 0;
            row['return'] = [
              { Bal: 0, id: ''},
              { Karton: 0, id: '' },
              { Slop: 0, id: '' },
              { Pack: 0, id: '' },
              { Unit: 0, id: '' },
            ]
            row["cons"] = [];
            row["warehouse"] = [];
          }

          if (this.state.set == 0) {
            this.state.idSales = row.id_sales;
            this.state.idTakingGood = row.id;
            this.state.set = 1;
          }

          det = row.detail;
          itemdet = row.item_detail;
          let arr = [];
          let arrQty = [];
          let arrDet = [];
          let arrDetQty = [];
          let optionWH = [];

          for (var a = 0; a < det.length; a++) {
            arr.push(parseInt(det[a].id))
            arrQty.push(parseInt(det[a].qty))
          }

          for (var a = 0; a < row.list_wh.length; a++) {
            optionWH.push({ value: row.list_wh[a].value, label: row.list_wh[a].label, idWH: row.list_wh[a].helper, idSubWH: row.list_wh[a].helper2 })
          }

          for (var a = 0; a < itemdet.length; a++) {
            arrDet.push(parseInt(itemdet[a].idUnit))
            arrDetQty.push(parseInt(itemdet[a].qty))
          }

          html.push(
            <tr key={"detail" + i} className="border text-center">
              <td style={{ textAlign: 'center' }}>{no + '.'}</td>
              <td>{row.name_item}</td>

              {unit.map((nameUnit, p) => {
                let bi = nameUnit.value
                let time = arr.indexOf(bi)
                return <td key={'unitTaking'+p}> {arr.indexOf(bi) >= 0 ? arrQty[time] : 0} </td>
              })}
              <td>{row.convert}</td>
              <td>{row.total_sell}</td>

              {unit.map((nameUnit, p) => {
                let bi = nameUnit.label
                let bit = nameUnit.value
                let time = arrDet.indexOf(bit)
                return <td key={'unitItem'+p}>
                  <div className="row">
                    <div className="pl-2">
                      <InputNumber
                        id={bi}
                        
                        name={bi}
                        labelfield={"false"}
                        disabled={arrDet.indexOf(bit) >= 0 ? false : true}
                        defaultValue={0}
                        value={row['return'][p][bi]}
                        onValueChange={async (value, name) => {
                          if (value == null || value == '') {
                            value = 0
                          }
                          await this.handlerInputArray(value, name, row['index'], 'arrayDetail', arrDetQty[time], p, bit);
                        }}
                        className="form-control  form-control-sm text-end"
                      />
                    </div>
                  </div>
                </td>
              })}
              <td >
                <div className="row">
                  <div className="pl-2">
                    <InputNumber
                      id={"convertReturn"}
                      name={"convertReturn"}
                      labelfield={"false"}
                      defaultValue={0}
                      value={row['convertReturn']}
                      decimalsLimit={2}
                      disabled={true}
                      onValueChange={(value, name) => { }}
                      className="form-control  form-control-sm text-end"
                    />
                  </div>
                </div>
              </td>
              <td>
                <Select2
                  isClearable={true}
                  name={'warehouse'}
                  required={true}
                  className={""}
                  size={4}
                  onChange={async (e, name) => {
                    await this.handlerSelectArray(e, name, row['index'], "arrayDetail");
                  }
                  }
                  options={optionWH}
                  title={i18n.t('Warehouse')}
                  value={row['warehouse']}
                  placeholder={i18n.t('Warehouse')}
                  labelfield={"false"}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError(row['index'] + ".warehouse")}
                </div>
              </td>
            </tr>
          );
          no++;
        }
        this.state.update = 1;
      }
    }
    return html;
  }

  setRetur(){
    const { data } = this.props;
    let html = [];
    let itemdet = [];

    let no = 1;
    let unit = this.state.unit;
    let itemUnit = [];
    for (var a = 0; a < unit.length; a++) {
      itemUnit.push({ id: unit[a].value })
    }

    if (data != null && data != '') {
      this.state.arrayRetur = data.retur
      this.state.dataRetur = data.item_retur

      if (this.state.arrayRetur.length > 0) {
        for (var i = 0; i < this.state.arrayRetur.length; i++) {
          const row = this.state.arrayRetur[i];
          if (this.state.updateRetur == 0) {
            row['index'] = i;
            row['convertRetur'] = 0;
            row['retur'] = [
            { Bal: 0, id: '' },
            { Karton: 0, id: '' },
            { Slop: 0, id: '' },
            { Pack: 0, id: '' },
            { Unit: 0, id: '' },
            ]
            row["cons"] = [];
            row["warehouse"] = [];
          }

          // if (this.state.set == 0) {
          //   this.state.idSales = row.id_sales;
          //   this.state.idTakingGood = row.id;
          //   this.state.set = 1;
          // }

          // det = row.detail;
          itemdet = row.item_detail;
          // let arr = [];
          // let arrQty = [];
          let arrDet = [];
          let arrDetQty = [];
          let optionWH = [];

          // for (var a = 0; a < det.length; a++) {
          //   arr.push(parseInt(det[a].id))
          //   arrQty.push(parseInt(det[a].qty))
          // }

          for (var a = 0; a < row.list_wh.length; a++) {
            optionWH.push({ value: row.list_wh[a].value, label: row.list_wh[a].label, idWH: row.list_wh[a].helper, idSubWH: row.list_wh[a].helper2 })
          }

          for (var a = 0; a < itemdet.length; a++) {
            arrDet.push(parseInt(itemdet[a].idUnit))
            arrDetQty.push(parseInt(itemdet[a].qty))
          }
      
      // if(row.length > 0){
      //   for(var i=0; i< row.length; i++){
      //     let optionWH = [];
      //     if (this.state.updateRetur == 0) {
      //       row[i]['index'] = i;
      //       row[i]['convertReturn'] = 0;
      //       row[i]['retur'] = [{
      //         Bal: 0, id: ''
      //       },
      //       { Karton: 0, id: '' },
      //       { Slop: 0, id: '' },
      //       { Pack: 0, id: '' },
      //       { Unit: 0, id: '' },
      //       ]
      //       row[i]["cons"] = [];
      //       row[i]["warehouse"] = [];
      //     }
      //     for (var a = 0; a < row[i].list_wh.length; a++) {
      //       optionWH.push({ 
      //         value: row[i].list_wh[a].value, 
      //         label: row[i].list_wh[a].label, 
      //         idWH: row[i].list_wh[a].helper, 
      //         idSubWH: row[i].list_wh[a].helper2 })
      //     }

      //     itemdet = row[i].item_detail;
      //     let arrDet = [];
      //     for (var a = 0; a < itemdet.length; a++) {
      //       arrDet.push(parseInt(itemdet[a].idUnit))
      //       // arrDetQty.push(parseInt(itemdet[a].qty))
      //     }

          html.push(
            <tr key={"detail-retur" + i} className="border text-center">
              <td style={{ textAlign: 'center' }}>{no + '.'}</td>
              <td>{row.name_item}</td>
              <td style={{textAlign:'right',paddingRight:'10px'}}>{row.qty_good}</td>
              <td style={{textAlign:'right',paddingRight:'10px'}}>{row.qty_bad}</td>
              {unit.map((nameUnit, p) => {
                let bi = nameUnit.label
                let bit = nameUnit.value
                let time = arrDet.indexOf(bit)
                return <td key={'unitItem'+p}>
                  <div className="row">
                    <div className="pl-2">
                      <InputNumber
                        id={bi}
                        
                        name={bi}
                        labelfield={"false"}
                        disabled={arrDet.indexOf(bit) >= 0 ? false : true}
                        defaultValue={0}
                        value={row['retur'][p][bi]}
                        onValueChange={async (value, name) => {
                          if (value == null || value == '') {
                            value = 0
                          }
                          await this.handlerInputArrayRetur(value, name, row['index'], 'arrayRetur', arrDetQty[time], p, bit);
                        }}
                        className="form-control  form-control-sm text-end"
                      />
                    </div>
                  </div>
                </td>
              })}
              <td >
                <div className="row">
                  <div className="pl-2">
                    <InputNumber
                      id={"convertRetur"}
                      name={"convertRetur"}
                      labelfield={"false"}
                      defaultValue={0}
                      value={row['convertRetur']}
                      decimalsLimit={2}
                      disabled={true}
                      onValueChange={(value, name) => { }}
                      className="form-control  form-control-sm text-end"
                    />
                  </div>
                </div>
              </td>
             <td>
                <Select2
                  isClearable={true}
                  name={'warehouse'}
                  required={true}
                  className={""}
                  size={4}
                  onChange={async (e, name) => {
                    await this.handlerSelectArray(e, name, row['index'], "arrayRetur");
                  }
                  }
                  options={optionWH}
                  title={i18n.t('Warehouse')}
                  value={row['warehouse']}
                  placeholder={i18n.t('Warehouse')}
                  labelfield={"false"}
                />
                <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError(row['index'] + ".warehouse")}
                </div>
              </td>
            </tr>
          );
        }
      }
      this.state.updateRetur =1;
    }
    return html;
  }

  render() {
    const { data, isNewRecord, isProcessing, helperTakingGoods } = this.props;
    console.log('PROPS', this.props);
    console.log('STATE', this.state);
    let unit = [];
    let col = [];

    if (data != null) {
      let total = 0;
      col = data.return[0];
      if(data.return != null && this.state.add == 0){
        for(var i=0; i<data.return.length;i++){
          this.state.arrayTotal.push({
            id:data.return[i].id_item,qty:data.return[i].convert})
        }
      }

      // if(data.retur != null && this.state.add == 0){
      //   for(var i=0; i<data.retur.length;i++){
      //     let index =  this.state.arrayTotal.findIndex((item) => item.id === data.retur[i].id_item);
      //     console.log(index)
      //     if(index > -1){
      //       const arrQty =  this.state.arrayTotal[index];
      //       total = arrQty['qty'] + data.retur[i].convert
      //       arrQty['qty'] = total
      //     }else{
      //     this.state.arrayTotal.push({
      //       id:data.retur[i].id_item,qty:data.retur[i].convert})
      //     }
      //   }
      // }
      // this.state.add = 1;
    }

    if (helperTakingGoods != null) {
      this.state.branchList = helperTakingGoods != null ? helperTakingGoods.branch : [];
      this.state.unit = helperTakingGoods.unit;
      unit = helperTakingGoods.unit;
      if (this.state.show == false) {
        this.state.show = true;
      }
    }

    return (
      <React.Fragment>
        {(!isProcessing) ?
          <form>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
              <div className="flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-body " >
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Date')} : {col.date}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Branch')} : {col.name_branch}
                      </div>
                      <div className="col-3 mt-3">
                        {i18n.t('Store')} : {col.name_store}
                      </div>
                    </div>
                    <div className="row" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      <div className="col-3 mt-3">

                        {i18n.t('Taking Goods No.')} : {col.no_tr}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Sales Name')} : {col.name_sales}
                      </div>
                      <div className="col-3 mt-3">

                        {i18n.t('Warehouse Origin')} : {col.name_wh} - {col.name_sub_wh}
                      </div>
                    </div>
                    <hr />
                    <div className="row" >
                      <div className="col-12" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {i18n.t("Return Date")}
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-3 mt-3" >
                        <DatePickerCustom
                            name={"dateReturn"}
                            title={i18n.t("")}
                            required={false}
                            selected={this.state.dateReturn}
                            onSelect={async (e)=>{
                                    await this.handlerDateTime(e,'dateReturn');
                                }
                            }
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMMM yyyy"
                            maxDate={new Date()}
                            className="form-control form-control-sm font-weight-bold"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row" >
                      <div className="col-12">
                        <Table striped bordered hover className="border" style={{ fontWeight: 'bold' }}>
                          <thead>
                            <tr className="border" style={{ backgroundColor: '#68829E' , color:'white' }}>
                              <th width={"3%"} className="text-center pb-10" rowSpan={2}>{i18n.t("No.")}</th>
                              <th width={"15%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Item")}</th>
                              <th width={"30%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Taking")}</th>
                              <th width={"8%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Selling (Pack)")}</th>

                              <th width={"32%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Return")}</th>
                              <th width={"25%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Warehouse")}</th>
                            </tr>
                            <tr className="border">

                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"header" + i} width={"5%"} className="text-center " style={{ backgroundColor: '#68829E' , color:'white'}} >{header.label}</th>
                                })
                                : null}
                              <th key={"headerConvert"} width={"5%"} className="text-center" style={{ backgroundColor: '#68829E' , color:'white'}}>{i18n.t("Convert (Pack)")}</th>


                              {(unit.length > 0) ?
                                unit.map((header, i) => {
                                  return <th key={"headerReturn" + i} width={"5%"} className="text-center" style={{ backgroundColor: '#68829E', color:'white' }}>{header.label}</th>
                                })
                                : null}
                              <th key={"headerConvert1"} width={"8%"} className="text-center" style={{ backgroundColor: '#68829E', color:'white' }}>{i18n.t("Convert (Pack)")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.show == true ? this.setList() : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-12" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {i18n.t("Item Retur")}
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-12" >
                        <Table striped bordered hover className="border" style={{ fontWeight: 'bold' }}>
                          <thead>
                          <tr className="border" style={{ backgroundColor: '#68829E' , color:'white' }}>
                              <th width={"3%"} className="text-center pb-10" rowSpan={2}>{i18n.t("No.")}</th>
                              <th width={"20%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Item")}</th>
                              <th width={"10%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Good Stock (Pack)")}</th>
                              <th width={"10%"} className="text-center pb-10" rowSpan={2}>{i18n.t("Bad Stock (Pack)")}</th>
                              <th width={"32%"} className="text-center" colSpan={(unit.length + 1)}>{i18n.t("Return (Good Stock)")}</th>
                              <th width={"30  %"} className="text-center pb-10" rowSpan={2}>{i18n.t("Warehouse")}</th>
                            </tr>
                            <tr className="border">
                                {(unit.length > 0) ?
                                  unit.map((header, i) => {
                                    return <th key={"headerReturn" + i} width={"5%"} className="text-center" style={{ backgroundColor: '#68829E', color:'white' }}>{header.label}</th>
                                  })
                                  : null}
                                <th key={"headerConvert1"} width={"8%"} className="text-center" style={{ backgroundColor: '#68829E' , color:'white' }}>{i18n.t("Convert (Pack)")}</th>
                                </tr>
                          </thead>
                          <tbody>
                            {this.state.show == true ? this.setRetur() : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          :
          <div className="text-center">
            <div className="spinner-border  text-primary text-center  clearfix"></div>
            <div className="text-center">{i18n.t("Loading")}</div>
          </div>}
      </React.Fragment>
    );
  }
}

export default FormReturn;

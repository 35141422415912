import React, { Component, Fragment } from "react";
import Table from "./table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormSupplier from "./deposit-supplier";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
export class DataSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      columns: [
        {
          key: "date",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "beginningBalance",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Beginning Balance"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "debt",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Debt"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "credit",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Credit"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "endingBalance",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Ending Balance"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "remark",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Remark"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        
      ],
    };
  }

  renderTable() {
    if(this.state.listData != null && this.state.listData != [] && this.state.listData != undefined){
      return this.state.listData.map((data, i) => (
        <Fragment key={i}>
          <Table {...this.props} data={data} columns={this.state.columns}></Table>
        </Fragment>
      ));
    }
  }
  
  render() {
    const { isProcessing, data, onChoose } = this.props;
    if (onChoose && this.state.click == 0) {
      this.state.listData = data;
      this.state.click = 1;
    }
    if (!onChoose && this.state.click > 0) {
      this.state.click = 0;
    }
    return (
      <div id="invoice-page">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                  <FormSupplier
                    {...this.props}
                    data={data}
                  />
                 {isProcessing && onChoose == false?<div className="text-center">
                  <div className="spinner-border  text-primary text-center  clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                  </div>:null}
              </div>
             
            </div>
            {onChoose ? this.renderTable() : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default DataSupplier;

import i18n from "../../../i18n";

const StatusActive = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 0,
    label: i18n.t("Non Active"),
  },
  {
    key: 1,
    label: i18n.t("Active"),
  },
];

const StatusTarget = [
  {
    key: "",
    label: i18n.t("Select Target"),
  },
  {
    key: 0,
    label: i18n.t("Non Active"),
  },
  {
    key: 1,
    label: i18n.t("Active"),
  },
];


const StatusLogRequest = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 0,
    label: i18n.t("New Request"),
  },
  {
    key: 1,
    label: i18n.t("Approve"),
  },
  {
    key: 2,
    label: i18n.t("Reject"),
  },
  {
    key: 3,
    label: i18n.t("Approve On Use"),
  },
];

const StatusForm = {
  0: i18n.t("Non Active"),
  1: i18n.t("Active"),
};

const StatusFormApprove = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 0,
    label: i18n.t("Rejected"),
  },
  {
    key: 0,
    label: i18n.t("Approve"),
  },
];

const StatusCB = {
  1: i18n.t("INCOMING"),
  2: i18n.t("OUT"),
};

const Period = {
  2: i18n.t("Date"),
  1: i18n.t("Week Period"),
};

const SalesPerformance = {
  1: i18n.t("Daily"),
  2: i18n.t("Weekly"),
  3: i18n.t("Monthly"),
};

const SalesPerformanceWeek = {
  2: i18n.t("Weekly"),
  3: i18n.t("Monthly"),
};

const PaymentBill = {
  2: i18n.t("Details"),
  1: i18n.t("All"),
};

const StatusCOA = {
  1: i18n.t("Balance Sheet"),
  2: i18n.t("Income Statement"),
};

const ChoseCOA = {
  Debt: i18n.t("Debt"),
  Credit: i18n.t("Credit"),
};

const AllowanceType={
  monthly:i18n.t('Monthly'),
  daily:i18n.t('Daily'),
}

const UnitSalary={
  money:i18n.t('Value'),
  percentage:i18n.t('Percentage'),
}
const InventoryType={
  inventory:i18n.t('Inventory'),
  asset:i18n.t('Asset'),
}

const StatusCheckIn = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 0,
    label: i18n.t("New Schedule"),
  },
  {
    key: 1,
    label: i18n.t("Check In"),
  },
  {
    key: 2,
    label: i18n.t("Done"),
  },
  {
    key: 3,
    label: i18n.t("Change Schedule"),
  },
  {
    key: 4,
    label: i18n.t("Change Sales"),
  },
];

export {
  StatusCheckIn,
  StatusTarget,
  PaymentBill,
  InventoryType,
  Period, 
  StatusActive, 
  StatusForm, 
  StatusCB, 
  StatusCOA, 
  ChoseCOA,
  StatusLogRequest,
  AllowanceType,
  UnitSalary,
  StatusFormApprove,
  SalesPerformance,
  SalesPerformanceWeek};


import React from "react";
import i18n from "../../../i18n";

const LogRequest=(props)=>{
    switch(props.value){
        case 1:
            return <div className="badge badge-light-success">{i18n.t("Approve")}</div>
        case 2:
            return <div className="badge badge-light-danger">{i18n.t("Reject")}</div>
        case 3:
            return <div className="badge badge-light-info">{i18n.t("Approve on Use")}</div>
        case 0:
            return <div className="badge badge-light-warning">{i18n.t("Non Request")}</div>
    }
}
export default LogRequest;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Approval } from '../../../../components/modules/user/approval';
// middlewares
import ApprovalMiddleware from '../../../../middleware/modules/user/approval'
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,approval }) => ({
  isProcessing: approval.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist:approval.data,
  showModal:approval.showModal,
  helperList:approval.helperApproval,
  data:approval.data
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    save:(data)=>ApprovalMiddleware.beforeSave(data),
    getData:(params)=>ApprovalMiddleware.getData(params),
    getHelper: () =>ApprovalMiddleware.getHelper(),
    update:()=> ApprovalMiddleware.beforeUpdate(),
  }, dispatch);
};
class ApprovalContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    this.props.getHelper();
  }

  
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Approval List")}
          </title>
        </Helmet>
      <Approval
       {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalContainer);

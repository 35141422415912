import React from 'react';
import i18n from '../../i18n';

const url=process.env.APP_URL
export default function LeftSide(){
    return (
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10 bg-logo">
          <div className="d-flex flex-center flex-lg-start flex-column">
            <img alt='logo' src={url+'assets/media/logos/logo.png'} className='logo'/>
            <h2 className="text-black fw-normal mt-10">{i18n.t("Distribution Information System")}</h2>
          </div>
        </div>
    )
}
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import StockCardMiddleware from '../../../../middleware/modules/mutation/stock-card';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import DataStock from "../../../../components/modules/mutation/stock-card/form-stock";
// middlewares
const mapStateToProps = ({ stockCard, }) => ({
  isProcessing: stockCard.isProcessing,
  isError: stockCard.isError,
  errorMessage: stockCard.errorMessage,
  helperList:stockCard.helperStockCard,
  data: stockCard.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => StockCardMiddleware.getHelper(),
      getData: (data) => StockCardMiddleware.getData(data),
    },
    dispatch
  );
};
class FormStockContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.getData = this.getData.bind(this);
  }

  getData() {
    this.props.getData();
  }

  componentWillMount() {
    // this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <DataStock
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormStockContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import { Navigate } from 'react-router-dom';
import UnitMiddleware from '../../../../middleware/modules/master/unit';
import Form from '../../../../components/modules/master/unit/form';
import { Button, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import i18n from '../../../../i18n';

const mapStateToProps = ({ auth, unit }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: unit.isProcessing,
  isError: unit.isError,
  errorMessage: unit.errorMessage,
  dataUpdate: unit.single,
  isSuccess: unit.isSuccess,
  isReset: unit.isReset,
  isNewRecord: unit.isNewRecord,
  onSubmit: unit.onSubmit,
  unitList: unit.helperUnit,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => UnitMiddleware.reset(),
      new: (data, search) => UnitMiddleware.beforeSaveNew(data, search),
      update: (id, type, search) =>
        UnitMiddleware.beforeSaveUpdate(id, type, search),
    },
    dispatch
  );
};
class FormContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.object,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: {},
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
    this.getData = this.getData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  getData() {
    return this.props.showOne(this.props.dataID);
  }

  close() {
    this.props.closeModal();
  }
  submitForm() {
    const { search, isNewRecord, dataUpdate } = this.props;
    var form = this.form.current.runSubmit();
    if (isNewRecord) {
      this.props.new(form, search);
    } else {
      this.props.update(dataUpdate.id, form, search);
    }
  }

  render() {
    const {
      onSubmit,
      showModal,
      titleModal,
      isReset,
      dataUpdate,
      isProcessing,
      isError,
      errorMessage,
      unitList,
    } = this.props;
    return (
      <>
        <Modal show={showModal} size={"md"}>
          <Modal.Header>
            <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Helmet>
                <title>{titleModal}</title>
              </Helmet>
            <Modal.Body>
                <Form 
                  isReset={isReset}
                  data={dataUpdate}
                  isProcessing={isProcessing}
                  isError={isError}
                  errorMessage={errorMessage}
                  ref={this.form}
                  unitList={unitList}
                />
            </Modal.Body>
            <Modal.Footer className={(!onSubmit)?"justify-content-between":"justify-content-center"}>
            {onSubmit ? (
              ""
            ) : (
              <Button variant="secondary" onClick={() => this.close(false)}>
                {i18n.t("Close")}
              </Button>
            )}
            <Button variant="primary" onClick={() => ((onSubmit)?false:this.submitForm(true))}>
              {onSubmit ? (
                <span className="indicator-progress show">
                  {i18n.t("Please wait...")}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">{i18n.t("Save")}</span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

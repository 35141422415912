export default class DistrictActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ERROR_DATA='ERROR_DATA';
    static GET_HELPER_DISTRICT='GET_HELPER_DISTRICT';


    static create() {
      return {
        type: DistrictActions.NEW_DATA
      };
    }
  
    static update(data) {
      return {
        type: DistrictActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: DistrictActions.UPDATE_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: DistrictActions.DELETE_DATA,
        payload: data
      };
    }
  
    static list(data) {
      return {
        type: DistrictActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: DistrictActions.ERROR_DATA,
        payload: data
      };
    }
    static helper(data){
      return {
        type: DistrictActions.GET_HELPER_DISTRICT,
        payload:data
      };
    }

  }


import ReportVisitActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  helperReportVisit:null,
  timeline:null,
  details:null,
  loadingDetails:false
};

function ReportVisitReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ReportVisitActions.RESET_LIST:
      return INITIAL_STATE;
    case ReportVisitActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case ReportVisitActions.GET_HELPER_REPORTVISIT:
        return { 
          ...state,
          isProcessing: false, 
          helperReportVisit: action.payload 
        };
      case ReportVisitActions.GET_DATA:
        return { 
          ...state,
          isProcessing: true, 
      };
      case ReportVisitActions.SET_TIMELINE:
        return { 
          ...state,
          isProcessing: false, 
          timeline: action.payload 
        };
      case ReportVisitActions.LOADED_DETAILS:
        return { 
          ...state,
          details: action.payload, 
          loadingDetails: false
        };
      case ReportVisitActions.LOADING_DETAILS:
        return { 
          ...state,
          details: null, 
          loadingDetails: true
        };
    default:
      return state;
    }
}


export default ReportVisitReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import AuthLogin from '../components/auth/AuthLogin';
// middlewares
import AuthMiddleware from '../reducers/auth/middleware';
import { Navigate } from 'react-router-dom';

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials)
  }, dispatch);
};

class LogoutContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: []
  }
  
  render() {
    const { isAuthenticated, isProcessing, isRegistered, isError, errorMessage, signin } = this.props;
    return (
      <div>
        {!isAuthenticated?<Navigate to={'/login'} replace={true}/>:<Navigate to={'/' } replace={true}/>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);

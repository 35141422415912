import { useParams  } from "react-router-dom";
import React from "react";
import BalanceSheetContainer from "../../../containers/Print/default/Balance-Sheet";
export const PrintBalanceSheet = (props)=>{
    let params=useParams();
    let data=params;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <BalanceSheetContainer data={data}/>
    )
}

export default PrintBalanceSheet;
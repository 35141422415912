export default class SalesTargetActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static SHOW_DATA_ASSIGN='SHOW_DATA_ASSIGN';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static GET_HELPER_SALESTARGET='GET_HELPER_SALESTARGET';
    static GET_HELPER_SALESTARGET_FULL ='GET_HELPER_SALESTARGET_FULL';
    static BEFORE_PRINT_SALESTARGET ='BEFORE_PRINT_SALESTARGET';
    static PRINT_SALESTARGET ='PRINT_SALESTARGET';
    static RESET_LIST="RESET_LIST";
    static NEW_DATA_TARGET="NEW_DATA_TARGET";
    static UPDATE_DATA_TARGET="UPDATE_DATA_TARGET";
    static VIEW_DATA_TARGET="VIEW_DATA_TARGET";
  
    static submit(){
      return {
        type: SalesTargetActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: SalesTargetActions.NEW_DATA,
        payload: data
      };
    }
    static createTarget(data) {
      return {
        type: SalesTargetActions.NEW_DATA_TARGET,
        payload: data
      };
    }
  
  
    static update(data) {
      return {
        type: SalesTargetActions.UPDATE_DATA,
        payload: data
      };
    }

    static updateTarget(data) {
      return {
        type: SalesTargetActions.UPDATE_DATA_TARGET,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: SalesTargetActions.VIEW_DATA,
        payload: data
      };
    }

    static viewTarget(data) {
      return {
        type: SalesTargetActions.VIEW_DATA_TARGET,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: SalesTargetActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: SalesTargetActions.SHOW_DATA,
        payload: data
      };
    }
    static listAssign(dataAssign) {
      return {
        type: SalesTargetActions.SHOW_DATA_ASSIGN,
        payload: dataAssign
      };
    }
    static error(error){
      return {
        type: SalesTargetActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: SalesTargetActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: SalesTargetActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: SalesTargetActions.RESET_DATA,
      };
    }
    static resetList(){
      return {
        type: SalesTargetActions.RESET_LIST,
      };
    }
  
    static helper(data){
      return {
        type: SalesTargetActions.GET_HELPER_SALESTARGET_FULL,
        payload:data
      };
    }

    static helperFull(data){
      return {
        type:  SalesTargetActions.GET_HELPER_SALESTARGET,
        payload:data
      };
    }
    static beforePrint(){
      return {
        type: SalesTargetActions.BEFORE_PRINT_SALESTARGET,
      };
    }
    static print(data){
      return {
        type: SalesTargetActions.PRINT_SALESTARGET,
        payload: data
      };
    }
  }
  
  
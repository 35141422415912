import PurchaseOrderActions from './actions';

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  onSubmit:false,
  single:null,
  helperPO:null,
  errorInternal:false,
  helperPOFull:null,
  printPo:null,
  requestPrint:false,
};

function PurchaseOrderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PurchaseOrderActions.RESET_LIST:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false,data:{}};
    case PurchaseOrderActions.NEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case PurchaseOrderActions.UPDATE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:null,isNewRecord:false};
    case PurchaseOrderActions.VIEW_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, single:action.payload,isNewRecord:false,showModal:true};
    case PurchaseOrderActions.RESET_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseOrderActions.DELETE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseOrderActions.CLOSE_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isProcessing: false, form:INITIAL_STATE.form,showModal:false};
    case PurchaseOrderActions.ON_SUBMIT:
      return { ...state,errorInternal:false,  onSubmit:true};
    case PurchaseOrderActions.SHOW_DATA:
      return { ...state,errorInternal:false, onSubmit:false,  isError: false, data: action.payload, errorMessage: [],isProcessing:false };
    case PurchaseOrderActions.SUCCESS_DATA:
      return { ...state,errorInternal:false, onSubmit:false, isError: false, showModal:false,form:INITIAL_STATE.form , onSubmit:false};
    case PurchaseOrderActions.PROCESS_DATA:
      return { ...state, isProcessing: true,onSubmit:false, isError: false,isSuccess:false};
    case PurchaseOrderActions.ERROR_DATA:
      return { ...state, onSubmit:false, errorInternal:false,  isError: true,onSubmit:false,  errorMessage: action.payload,isProcessing:false };
    case PurchaseOrderActions.ERROR_SERVER:
      return { ...state,  isError: false, errorInternal:true, onSubmit:false,  errorMessage: action.payload, isProcessing:false };
    case PurchaseOrderActions.GET_HELPER_PO:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,helperPO:action.payload};
    case PurchaseOrderActions.GET_HELPER_POFULL:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,helperPOFull:action.payload};
    case PurchaseOrderActions.APPROVE_PO:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case PurchaseOrderActions.REJECT_PO:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,};
    case PurchaseOrderActions.BEFORE_PRINT_PO:
      return { ...state,errorInternal:false,requestPrint:false,  isError: false,isSuccess:false,onSubmit:false,printPo:null,isProcessing:true};
    case PurchaseOrderActions.PRINT_PO:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,printPo:action.payload,isProcessing:false};
    case PurchaseOrderActions.ON_LIMIT_PRINT:
      return { ...state,errorInternal:false,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
    case PurchaseOrderActions.ON_SAVE_REQUEST_PRINT:
      return { ...state,errorInternal:false,requestPrint:true,  isError: false,isSuccess:false,onSubmit:false,isProcessing:false};
      default:
      return state;
    }
}

export default PurchaseOrderReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import AuthMiddleware from "../../../reducers/auth/middleware";
import WarehouseOutMiddleware from "../../../middleware/modules/warehouse/warehouse-out";
import PrintWHOut from "../../../components/utilities/print/default/warehouse-out";

const mapStateToProps = ({ auth, warehouseout }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  dataPrint: warehouseout.printWHOut,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (id) => WarehouseOutMiddleware.getPrint(id),
    },
    dispatch
  );
};

class WHOutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.id);
  }

  render() {
    const {
      isAuthenticated,
      isProcessing,
      isRegistered,
      isError,
      errorMessage,
    } = this.props;
    return <PrintWHOut {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WHOutContainer);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { Assignment } from '../../../../components/modules/user/assignment';
// middlewares
import AssignmentMiddleware from '../../../../middleware/modules/user/assignment';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,assignment }) => ({
  isProcessing: assignment.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  data:assignment.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>AssignmentMiddleware.resetList(),
    assign: (data,id) =>AssignmentMiddleware.beforeAssign(data,id),
    getData: data =>AssignmentMiddleware.update(data),
    revoke : (data,id) =>AssignmentMiddleware.beforeRevoke(data,id),
  }, dispatch);
};
class AssignmentContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
  }

  componentWillMount() {
    
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Assignment List")}
          </title>
        </Helmet>
      <Assignment
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentContainer);

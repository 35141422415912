import React, { Component, Fragment, useState } from "react";
import { Button, Spinner, Tab, Tabs, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRefresh, faSync, faFileExcel, faDisplay } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import Input from "../../../utilities/Form/input";
import { Period } from "../../../utilities/Const/StatusActive";
import Radio from "../../../utilities/Form/radio";
const MySwal = withReactContent(Swal);
export class FormPurchasingAllReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      periodCurrent: null,
      periodText: i18n.t("Select Period"),
      periodList: [],
      type: 2,
      branchList: [],
      branchCurrent: null,
      idBranch: null,
      branchText: i18n.t('All'),
      listData: [],
      search: null,
      limitCurrent: null,
      startDate: new Date(),
      endDate: new Date(),
      page: 1,
      show: 0,
      error: false,
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handler = this.handler.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.handlerRadio = this.handlerRadio.bind(this);
  }
  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setData();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setData();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }
  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }

  setData() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idSupplier = (this.state.idSupplier == null) ? '' : this.state.idSupplier;
    var idBranch = (this.state.idBranch == null) ? '' : this.state.idBranch;
    var limit = (this.state.limitCurrent == null) ? 1 : this.state.limitCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var search = (this.state.search == null) ? '' : this.state.search;
    var type = this.state.type;

    let params = {
      startDate: startDate,
      endDate: endDate,
      idSupplier: idSupplier,
      limit: limit,
      idBranch: idBranch,
      page: page,
      search: search,
      type: type,
    }
    this.props.setData(params);
  }

  renderList() {
    const { data } = this.props
    this.state.listData = data;
    let row = data.data
    let html = [];
    let no = 1;
    let total = 0;
    let totalDisc = 0;
    let grandTotal = 0;
    if (data != undefined && data != null  && row != null) {
      Swal.close();
      if (row.length > 0) {
        for (var p = 0; p < row.length; p++) {
          html.push(
            <tr className="border  " key={p}>
              <td width={"3%"} className="text-center">{no++}</td>
              <td width={"9%"}>{row[p].date}</td>
              <td width={"10%"}>{row[p].invoice_no}</td>
              <td width={"8%"}>{row[p].supplier_name}</td>
              <td width={"8%"}>{row[p].item_name}</td>
              <td width={"6%"} style={{ textAlign: 'right' }}>{row[p].qty}</td>
              <td width={"7%"}>{row[p].unit_name}</td>
              <td width={"8%"} style={{ textAlign: 'right' }}>{row[p].unit_price}</td>
              <td width={"7%"} style={{ textAlign: 'right' }}>{row[p].discount_price}</td>
              <td width={"7%"} style={{ textAlign: 'right' }}> {row[p].tax_price}</td>
              <td width={"9%"} style={{ textAlign: 'right' }}> {(parseFloat(row[p].total).toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}</td>
              <td width={"9%"} style={{ textAlign: 'right' }}> {row[p].discount_header_format}</td>
              <td width={"9%"} style={{ textAlign: 'right' }}> {row[p].grand_total_format}</td>
            </tr>)
          total += parseFloat(row[p].total) || 0;
          totalDisc += parseFloat(row[p].discount_header) || 0;
          grandTotal += parseFloat(row[p].grand_total) || 0;
        }
        html.push(
          <tr className="border  " >
            <td colSpan={'10'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(total)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(totalDisc)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(grandTotal)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
          </tr>)

      } else {
        html.push(
          <tr className="border  " >
            <td colSpan={'12'} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i18n.t('DATA NOT FOUND')}</td>
          </tr>)
      }
      return html;
    }

  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
  renderLoading() {
    Swal.fire({
      title: 'Loading',
      width: "200px",
      heightAuto: true
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var nameFile = (this.state.branchCurrent == null) ? 'All' : this.state.branchCurrent.label;
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    
    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/purchasing-report/excel",
      method: "GET",
      params: {
        startDate:startDate ,
        endDate:endDate,
        type:this.state.type,
        idBranch: this.state.idBranch,
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        Swal.close();
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
      
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
        startDate + ' / ' +endDate + ' ' +nameFile +' Laporan Pembelian.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
      }
    });
  }


  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }, () => this.setData()
    )

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (event.target.value == 0) {
      this.setState({ page: (get - 1) }, () => this.setData());
    } else if (event.target.value == (count - 1)) {
      this.setState({ page: (get + 1) }, () => this.setData());
    } else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }

  renderPagination() {
    const { data } = this.props;
    if (this.state.show == 0) {
      this.state.limitCurrent = { value: '10', label: '10' }
      this.state.show = 1;
    }
    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"] : ""}
                      {i == data.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        Swal.close();
    }
    return value;
}

  render() {
    const { isProcessing, helperPurchasingReport, datalist, data } = this.props;
    this.state.branchList = helperPurchasingReport.branch;
    this.state.periodList = helperPurchasingReport.workCalendar;
    this.state.listData = datalist;
    return (
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card">
          <div className="card-body pt-3">
            <div className="row mb-4" >
              <div className="row">
                <div className="col-mb-3">
                  <Radio
                    list={Period}
                    name={"type"}
                    title={""}
                    inline={false}
                    value={this.state.type}
                    handler={this.handlerRadio}
                  />
                </div>
              </div>
              {this.state.type == 1 ?
                <Fragment>
                  <div className="col-3 mt-3">
                    <Select2
                      isClearable={true}
                      name={"period"}
                      required={true}
                      size={4}
                      onChange={async (e) => {
                        await this.handlerSelect(e, "idPeriod", "periodCurrent");
                      }}
                      options={this.state.periodList}
                      title={i18n.t("Week Period")}
                      value={this.state.periodCurrent}
                      placeholder={this.state.periodText}
                      
                    />
                     <div className="fv-plugins-message-container invalid-feedback">
                        {this.renderError('startDate')}
                      </div>
                  </div>
                </Fragment>
                :
                <Fragment>

                  <div className="col-3 mt-3">
                    <DatePickerCustom
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      selectsRange={true}
                      isClearable={false}
                      name={"date"}
                      title={i18n.t("Date Period")}
                      required={true}
                      onChange={(e) => {
                        const [start, end] = e;
                        this.setState({ startDate: start, endDate: end });
                      }}
                      la
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      peekNextMonth
                      showMonthDropdown
                      autoComplete={"off"}
                      dateFormat="dd MMM yyyy"
                      maxDate={new Date()}
                      className="form-control form-control-sm"
                    />
                  </div>

                </Fragment>
              }
              <div className="col-sm-3 m-3">
                <Select2
                  isClearable={true}
                  name={"idBranch"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                    if (e == null) {
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                    } else {
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                    }
                  }}
                  options={this.state.branchList}
                  title={i18n.t("Branch")}
                  value={this.state.branchCurrent}
                  placeholder={this.state.branchText}
                  labelfield={"true"}
                />
              </div>
              <div className="col text-end">
              </div>
              <div className="row mb-4">
                <div className="col-sm-2">
                  <Button onClick={async (e) => {
                    await this.setState({ page: 1 }, () => this.setData())
                    await this.setState({ headeTable: true })
                  }}
                    variant="success" className="btn btn-md btn btn-success mt-4"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> Show </Button>
                </div>
                <div className="col-sm-2">
                  <Button onClick={async (e) => {
                    await this.convertExcel()
                  }}
                    variant="success" className="btn btn-md btn btn-success mt-4"> <FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon> Excel </Button>
                </div>
              </div>
            </div>

            {this.state.headeTable == true ?
              <React.Fragment>
                {!isProcessing ?
                  <table className="table table-responsive table-bordered table-hover border  ">
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800 bg-aquamarine square border text-center ">
                        <th width={"3%"}>{i18n.t("No.")}</th>
                        <th width={"9%"}>{i18n.t("Date")}</th>
                        <th width={"10%"}>{i18n.t("Invoice No.")}</th>
                        {/* <th width={"9%"}>{i18n.t("Supplier Inv No.")}</th> */}
                        <th width={"8%"} >{i18n.t("Supplier")}</th>
                        <th width={"8%"}>{i18n.t("Item Name")}</th>
                        <th width={"7%"}>{i18n.t("Qty")}</th>
                        <th width={"6%"}>{i18n.t("Unit ")}</th>
                        <th width={"8%"}>{i18n.t("Unit Price")}</th>
                        <th width={"7%"}>{i18n.t("Disc (Rp.)")}</th>
                        <th width={"7%"}>{i18n.t("Tax (Rp.)")}</th>
                        <th width={"9%"}>{i18n.t("Total")}</th>
                        <th width={"9%"}>{i18n.t("Discount")}</th>
                        <th width={"9%"}>{i18n.t("Grand Total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.renderList()}
                    </tbody>
                  </table>

                  : this.renderLoading()
                }
                {this.renderPagination()}
              </React.Fragment> :
              ''}
          </div>
        </div>
      </div>

    );
  }
}

export default FormPurchasingAllReport;
